var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"vf-carousel-wrapper",attrs:{"aria-roledescription":"carousel","aria-label":_vm.carouselTitle,"role":"region"}},[(_vm.title)?_c('VfHeading',{staticClass:"vf-carousel__title",class:[_vm.titleStyles.titleClassModifiers],attrs:{"data-id":_vm.$attrs['data-id'],"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id']),"title":_vm.title,"title-modifier":_vm.titleStyles.titleStyle,"level":_vm.titleStyles.level,"styles":_vm.titleStyles}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"vf-carousel",class:_vm.classes,style:({
      '--vf-carousel-full-height': _vm.carouselFullHeight + 'px',
      '--vf-carousel-pagination-position': _vm.sliderPaginationPositionStyle,
      '--vf-carousel-pagination-top': _vm.sliderPaginationTopStyle,
    }),attrs:{"data-testid":"theme-smart-carousel","data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id'])}},[(_vm.portraitOnly)?_c('div',{staticClass:"vf-carousel__portrait-overlay"},[_c('div',{staticClass:"vf-carousel__portrait-overlay-content"},[_c('VfIcon',{staticClass:"vf-carousel__portrait-overlay-icon",attrs:{"icon":"rotate","alt":_vm.translations.iconAltText}}),_vm._v(" "),_c('VfText',{attrs:{"content":_vm.translations.ctaText,"modifier":"title-5","text-color":"var(--c-primary)"}})],1)]):_c('div'),_vm._v(" "),_c('UiSwiper',{class:[
        _vm.isFullHeight
          ? ("swiper-pagination--" + _vm.controlsPosition)
          : 'swiper-pagination--bottom' ],style:(_vm.currentBreakpointStyles),attrs:{"show-arrows":_vm.showArrows,"pagination":_vm.shouldShowPagination,"autoplay":_vm.autoplay,"scroll-props":{
        loop: _vm.loop,
        scrollBy: 1,
        gap: _vm.currentBreakpointStyles['--slider-gap'],
      }},on:{"change":function($event){return _vm.updateDotNav()},"resize":function($event){return _vm.updateDotNav()}},nativeOn:{"customsSlideImageLoaded":function($event){return _vm.updateCustomsSlider()}},scopedSlots:_vm._u([{key:"prev",fn:function(ref){
      var active = ref.active;
      var action = ref.action;
return [(_vm.showArrows && active)?_c('button',{staticClass:"vf-carousel__button vf-carousel__button--prev",attrs:{"data-testid":"vf-carousel__button-left","aria-label":_vm.translations.accessibility.carouselNavPreviousSlideLabel},on:{"click":function($event){_vm.pushClickDataLayer('Left Arrow');
            action();}}},[_c('VfIcon',{staticClass:"vf-carousel__icon",attrs:{"icon":"chevron_left","data-testid":"vf-carousel__button-left"}})],1):_vm._e()]}},{key:"next",fn:function(ref){
            var active = ref.active;
            var action = ref.action;
return [(_vm.showArrows && active)?_c('button',{staticClass:"vf-carousel__button vf-carousel__button--next",attrs:{"data-testid":"vf-carousel__button-right","aria-label":_vm.translations.accessibility.carouselNavNextSlideLabel},on:{"click":function($event){_vm.pushClickDataLayer('Right Arrow');
            action();}}},[_c('VfIcon',{staticClass:"vf-carousel__icon",attrs:{"icon":"chevron_right","data-testid":"vf-carousel__button-right"}})],1):_vm._e()]}}])},[_vm._v(" "),_vm._t("default")],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }