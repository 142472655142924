








































































































































































































































































































































































































import type { PropType } from 'vue';
import {
  computed,
  defineComponent,
  onMounted,
  provide,
  ref,
  reactive,
  watch,
} from '@vue/composition-api';
import type { CartLineItem } from '@vf/api-client';
import type { FlashError } from '@vf/api-client/src/types';
import type { CartProductsTranslations } from '@vf/api-contract';
import { Context, FavoritesContext, FlashErrorType } from '@vf/api-contract';
import {
  useAccount,
  useAuthentication,
  useCart,
  useCheckout,
  useFavorites,
  useFindInStore,
  useNotification,
  useProduct,
  useProductInventory,
  useGiftOption,
  useShipmentStore,
  useSaveForLater,
  useUrl,
  useGtm,
  useSignInToStore,
  useI18n,
  ROUTES,
} from '@vf/composables';
import { errorMessages } from '@vf/composables/src/utils/errorMessages';
import { productVariantsToQueryString } from '@vf/composables/src/utils/productVariantsToQueryString';
import GiftOptionModal from '@/components/modals/GiftOptionModal.vue';
import ShipmentStoreModal from '@/components/modals/ShipmentStoreModal.vue';
import NotAddedItems from '@/components/checkout/NotAddedItems.vue';
import { focus } from '@vf/shared/src/utils/directives';
import debounce from '@vf/shared/src/utils/helpers/debounce';
import {
  getCartProductListThemeConfig,
  mapMessage,
  getMaxQuantity,
  getOverrideImage,
  getAddToFavoritesProduct,
  getGtmPayloadforCartUpdate,
  getPickupLabels,
  getStoreDistanceOptionList,
} from '@/helpers';
import { getGiftOptionItem } from '@vf/shared/src/utils/helpers';
import { getEventFromTemplate } from '@vf/composables/src/useGtm/helpers';
import useRootInstance from '@/shared/useRootInstance';
import useModal from '@/shared/useModal';
import useLoader from '@/shared/useLoader';
import { PageTypeName } from '@vf/composables/src/useCms/types';
import CartProduct from '@/components/checkout/CartProduct.vue';
import SavedForLaterProducts from '@/components/checkout/SavedForLaterProducts.vue';
import { useUserStore } from '@vf/composables/src/store/user';
import { storeToRefs } from 'pinia';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

export default defineComponent({
  name: 'CartProductList',
  components: {
    NotAddedItems,
    CartProduct,
    SavedForLaterProducts,
    GiftOptionModal,
    ShipmentStoreModal,
  },
  directives: { focus },
  props: {
    translations: {
      type: Object as PropType<CartProductsTranslations>,
      required: true,
    },
    /** Maximum quantity of products that can be added to the cart (custom products page - how many items will be there in Quantity select box) */
    maxQuantity: {
      type: Number,
      default: 10,
    },
    /** Flag to determine that we should render component only for errors  */
    showOnlyErrorInfo: {
      type: Boolean,
      default: false,
    },
    /** Flag to determine if image and title of products are redirecting to PDP page */
    allowRedirectToPdp: {
      type: Boolean,
      default: true,
    },
    /** Show/Hide Edit button */
    showEditButton: {
      type: Boolean,
      default: true,
    },
    /** Show/Hide Remove button */
    showRemoveButton: {
      type: Boolean,
      default: true,
    },
    /** Show/Hide Save for later button */
    showSaveForLaterButton: {
      type: Boolean,
      default: true,
    },
    /** Show/Hide Add to favorites button */
    showSaveToFavoritesButton: {
      type: Boolean,
      default: true,
    },
    showAddGiftOptionCta: {
      type: Boolean,
      default: false,
    },
    /** QuickShop link page to be open in modal */
    quickShopLink: {
      type: [String, Object],
      default: '',
    },
    /** Favorites link for logged in user */
    favoritesLoggedInLink: {
      type: [String, Object],
      default: '',
    },
    /** Favorites link for not logged in user */
    favoritesLoggedOutLink: {
      type: [String, Object],
      default: '',
    },
    /* 'dropdown' or 'controller' */
    quantityPicker: {
      type: String as PropType<'dropdown' | 'controller'>,
      default: 'dropdown',
    },
    /** Toggle 'edit modal' opening while clicking title or image */
    openEditProductModal: {
      type: Boolean,
      default: false,
    },
    /** Distance unit used on store pickup selection modal */
    storeDistanceUnit: {
      type: String as PropType<string>,
      default: 'km',
    },
    /** List of distance options for store pickup selection modal */
    storeDistanceOptions: {
      type: Array as PropType<number[]>,
      default: () => [5, 10, 15, 25, 50, 100],
    },
    /** Add variation id to favorites instead of product id */
    variationAddToFavorites: {
      type: Boolean,
      default: false,
    },
    /** Show tooltip after adding the product to favorites list */
    showFavoritesTooltip: {
      type: Boolean,
      default: false,
    },
    /** Show different messages for guest and logged in users in favorites notification */
    differentFavoritesMessages: {
      type: Boolean,
      default: false,
    },
    /** Show notification icons */
    showNotificationIcons: {
      type: Boolean,
      default: true,
    },
    unitOfMeasure: {
      type: String as PropType<string>,
      default: 'km',
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { openModal, closeModal } = useModal();
    const { showSpinner, hideSpinner } = useLoader();
    const { saveShippingAddress } = useCheckout(root);
    const theme = getCartProductListThemeConfig(root.$themeConfig);
    const {
      isBopisEnabled,
      isPricingStrikethroughEnabled,
    } = useFeatureFlagsStore();

    const ROW_NOTIFICATION_CLOSE_TIMEOUT = 5000;
    const FAVORITES_TOOLTIP_CLOSE_TIMEOUT = 10000;

    const cartProductBindings = computed(() => ({
      translations: props.translations,
      imageWidth: theme.imageWidth,
      imageHeight: theme.imageHeight,
      quantityPicker: props.quantityPicker,
      allowRedirectToPdp: props.allowRedirectToPdp,
      showEditButton: props.showEditButton,
      showRemoveButton: props.showRemoveButton,
      showSaveForLaterButton: props.showSaveForLaterButton,
      showAddGiftOptionCta: props.showAddGiftOptionCta,
      showPriceOverrideBox: employeeConnected.value,
      isPricingStrikethroughEnabled,
      openEditProductModal: props.openEditProductModal,
      bopisModalPristine: bopisModalPristine.value,
      stores: usedFindInStore.storesForPickup.value,
      showOnlyErrorInfo: props.showOnlyErrorInfo,
      cartError: cartErrorObject.value,
    }));

    const { localePath, getStaticTranslation } = useI18n(root);
    const {
      cart,
      cartItems,
      updateItem,
      deleteItem,
      customCartProducts,
      standardCartProductsWithDeletedItems,
      notAddedItems,
      updateStandardCartProductsList,
      deletedFromStandardCartProductsList,
      getProductRelatedFlashMessages,
      getCartErrorDetail: recentCartError,
      overridePrice,
      resetPrice,
      updatePrice,
      isPriceAdjusted,
      checkAndRemoveOutOfStockItems,
      outOfStockList,
      outOfStockFlashErrors,
      hasFlashErrorsToDisplay,
      cartLineItemCustomerNotifications,
    } = useCart(root);

    const {
      addSaveForLater,
      removeSaveForLater,
      isSaveForLaterProductToUpdate,
    } = useSaveForLater(root);

    const { getINV408ErrorMessageById } = errorMessages(root);

    const { product, ...usedProduct } = useProduct(root, Context.QuickShop);
    const { getProductInventory } = useProductInventory(
      root,
      Context.QuickShop
    );
    const {
      isFavorite,
      addToFavorites,
      favoriteId,
      showFavoritesTooltip,
    } = useFavorites(root);

    const {
      selectedItem,
      isShipmentModalOpen,
      showGiftOptionWarning,
    } = useShipmentStore(root);
    const { modalVisible, setGiftOptionItem } = useGiftOption(root);
    const editGiftOption = (item) => {
      setGiftOptionItem(item);
      modalVisible.value = true;
    };
    const removeGiftOption = async (item) => {
      return updateItem(getGiftOptionItem(item, false));
    };

    const { isIpaProsumer, isWranxProsumer } = useAuthentication(root);
    const { getProductUrl } = useUrl(root);
    const { dispatchEvent } = useGtm(root);
    const { employeeConnected } = useSignInToStore(root);
    const usedFindInStore = useFindInStore(root);
    const {
      addNotification,
      clearNotifications,
      setItemRowNotification,
      findRowNotification,
    } = useNotification(root);
    const { favoriteStoreId, setFavoriteStoreId } = useAccount(root);

    const userStore = useUserStore(root);
    const { loggedIn } = storeToRefs(userStore);

    const favoritesMessage = ref('');
    const currentRoutePath = ref('');
    const priceOverrideSuccessMessage = ref('');
    const bopisModalPristine = ref(true);
    const isActionInProgress = reactive({
      remove: false,
      saveForLater: false,
    });

    const finalRegularProducts = computed(() =>
      sortProductWithErrors(standardCartProductsWithDeletedItems.value)
    );

    const finalCustomProducts = computed(() =>
      sortProductWithErrors(customCartProducts.value, 'custom')
    );

    const shouldRenderShipmentStoreModal = computed(
      () =>
        cartItems?.value.every((item) => item.shippingOptions) && isBopisEnabled
    );

    onMounted(() => {
      /**
       * Assign currentRoutePath when cart page (or checkout pages) is reached.
       * Prevents dissapearing cart items or flash messages when PDP is clicked and root.$route.path is already changed, but new page is not loaded yet.
       */
      currentRoutePath.value = root.$route.path;
    });

    const isProductHidden = (deleted, item) => {
      return [
        deleted,
        outOfStockList.value.find(({ id }) => id === item.id) &&
          theme.showErrorNotification, // hidden because visible in CartNotification component
      ].some(Boolean);
    };

    // Products actions
    const editProduct = async (item, isSaveForLaterProduct = false) => {
      usedProduct.setOldProduct(item);
      // check if edited product is Save For Later type
      isSaveForLaterProductToUpdate.value = isSaveForLaterProduct;
      // if customizer or Custom PDP product
      if (item.recipeId) {
        root.$router.push(item.pdpUrl);
      } else {
        // open quickshop
        const defaultVariants = item.variants.reduce(
          (acc, variant) => ({
            ...acc,
            [variant.code]: variant.id,
          }),
          {}
        );

        await usedProduct.toggleQuickShop(
          item[theme.productIdPropertyName],
          defaultVariants
        );
        openModal({
          type: 'page',
          path: props.quickShopLink,
          contextKey: Context.QuickShop,
        });
        await getProductInventory(item[theme.productIdPropertyName]);
      }
    };
    const updateItemAction = async (data: {
      product: CartLineItem;
      quantity?: number;
      storeId?: string;
      onFinishCall?: (status?: boolean) => void;
    }) => {
      const isShippingOptionChange: boolean = data.storeId !== undefined;
      let query = '';
      if (data.quantity === undefined) {
        data.quantity = data.product.qty;
      }
      if (isBopisEnabled && isShippingOptionChange) {
        if (data.storeId && favoriteStoreId.value !== data.storeId) {
          setFavoriteStoreId(data.storeId);
        }
        query = `action=pickup${
          // we can extract and reuse query builder from useUrl/handlers/urlHelpers.ts
          favoriteStoreId.value ? '&favStoreId=' + favoriteStoreId.value : ''
        }`;
      }
      // skip quantity related GTM events for shipping option switch or no quantity change
      const triggerGTM = !(
        data.quantity === data.product.qty || isShippingOptionChange
      );
      if (!triggerGTM) {
        data.onFinishCall?.(true);
      }
      const success = await updateItem(
        {
          productId: data.product.productId,
          recipeId: data.product.recipeId,
          itemId: data.product.id,
          qty: data.quantity,
          maxQty: data.product.maxQty,
          pdpUrl: data.product.pdpUrl,
          productImageURL: data.product.productImageURL,
          ...(isBopisEnabled && // can be moved to useCart composable
          isShippingOptionChange
            ? {
                storeId: data.storeId,
              }
            : {}),
        },
        !theme.showErrorNotification,
        query
      );

      if (triggerGTM && success) {
        data.onFinishCall?.(true);
        const gtmPayload = getGtmPayloadforCartUpdate(data as any);
        dispatchEvent(gtmPayload);
        dispatchEvent(getEventFromTemplate('cart:update', {}));
      } else {
        // restore qty
        data.onFinishCall?.(false);
      }
    };

    const updateItemShippingOption = (item: CartLineItem, storeId = '') => {
      const payload = {
        product: item,
        quantity: item.qty,
        storeId,
      };

      updateItemAction(payload).then(() => {
        if (!storeId) {
          const { address } =
            cart.value.shippingMethods.find(
              ({ shippingId }) => item.shippingId === shippingId
            ) || {};
          saveShippingAddress(address);
        }
      });
    };

    const removeProduct = async (item: CartLineItem, index: number) => {
      if (isActionInProgress.remove) return;
      isActionInProgress.remove = true;
      clearNotifications();
      showSpinner();
      await deleteItem(item.id).finally(() => hideSpinner());
      isActionInProgress.remove = false;
      dispatchEvent({
        ...getEventFromTemplate('cart:remove', {}),
        composablesContexts: { useProduct: 'quickShop' },
        overrideAttributes: {
          product: item,
          quantity: item.qty,
        },
      });
      dispatchEvent(getEventFromTemplate('cart:update', {}));

      updateStandardCartProductsList({ index, product: item });
      if (theme.showSuccessMessagePerRow) {
        setItemRowNotification({
          id: item.id,
          message: props.translations.removedFromCartMessage,
        });
      }
      setTimeout(() => {
        deletedFromStandardCartProductsList.value = [];
      }, ROW_NOTIFICATION_CLOSE_TIMEOUT);
    };

    // Save for later actions
    const addSaveForLaterAction = async (item: CartLineItem) => {
      if (isActionInProgress.saveForLater) return;
      isActionInProgress.saveForLater = true;
      clearNotifications();
      const success = await addSaveForLater(item);
      isActionInProgress.saveForLater = false;
      if ([success, !theme.showSuccessMessagePerRow].every(Boolean)) {
        addNotification({
          message: props.translations.savedForLaterNotification,
          type: 'info',
        });
      }
    };

    // Favorites actions
    const moveToFavorites = async (
      item,
      addContext: FavoritesContext = FavoritesContext.Cart
    ) => {
      clearNotifications();
      const productUrlQueryObject = {};
      let pdpUrl = '';
      productUrlQueryObject['recipe'] =
        item.recipe || item.recipeId || item.customsRecipeID;
      if (props.variationAddToFavorites) {
        if (root.$themeConfig.productAddToCart.pdpUrlWithAttributes) {
          pdpUrl = `${
            getProductUrl(item).split('?')[0]
          }?${productVariantsToQueryString(item.variants)}`;
        } else {
          productUrlQueryObject['variant'] = item.productId;
        }
      }
      pdpUrl = pdpUrl || getProductUrl(item, productUrlQueryObject);

      const success = await addToFavorites(
        getAddToFavoritesProduct(item, {
          useVariation: props.variationAddToFavorites,
          favoritesId: favoriteId.value,
          pdpUrl,
        })
      );
      if (success) {
        if (addContext === FavoritesContext.Cart) {
          // deletes item from cart if moved from cart
          await deleteItem(item.id);
        } else if (addContext === FavoritesContext.SaveForLater) {
          // deletes item from  save for later if moved from sfl list
          await removeSaveForLater(item.itemId);
        }

        favoritesMessage.value = ([
          props.differentFavoritesMessages,
          loggedIn.value,
        ].every(Boolean)
          ? props.translations.moveToFavoritesNotificationAuthorised
          : props.translations.moveToFavoritesNotification
        ).replace(
          '{0}',
          loggedIn.value
            ? props.favoritesLoggedInLink
            : props.favoritesLoggedOutLink
        );

        if (props.showFavoritesTooltip) {
          showFavoritesTooltip(FAVORITES_TOOLTIP_CLOSE_TIMEOUT);
        } else {
          addNotification({
            message: favoritesMessage.value,
            type: 'success',
            persistent: !props.showNotificationIcons,
            modifiers: theme.notificationClass,
          });
        }
        dispatchEvent(
          getEventFromTemplate('favorite:add', {
            eventCategory: PageTypeName.CART,
            eventLabel: `${item.masterId} - ${item.colorDescription}`,
          })
        );
      }
    };

    // Override price actions
    const overrideProductPrice = async (payload) => {
      const data = {
        discount: {
          type: 'fixed_price',
          value: parseFloat(payload.price),
        },
        item_id: payload.productId,
        reasonCode: payload.comment,
        level: 'product',
      };
      await overridePrice(cart.value.id, data);
      if (isPriceAdjusted) {
        priceOverrideSuccessMessage.value =
          props.translations.priceOverride.overrideSuccessfulMessage;
      }
    };

    const resetProductPrice = async (priceAdjustmentId) => {
      await resetPrice(cart.value.id, priceAdjustmentId);
      priceOverrideSuccessMessage.value =
        props.translations.priceOverride.resetSuccessfulMessage;
    };

    const updateProductPrice = async (payload) => {
      const data = {
        applied_discount: {
          type: 'fixed_price',
          amount: parseFloat(payload.price),
        },
      };
      await updatePrice(cart.value.id, payload.priceAdjustmentId, data);
      priceOverrideSuccessMessage.value =
        props.translations.priceOverride.overrideSuccessfulMessage;
    };

    const onOpenShipmentStoreModal = (
      product: CartLineItem,
      isGiftAdded: string
    ) => {
      selectedItem.value = product as CartLineItem;
      showGiftOptionWarning.value = !!isGiftAdded;

      isShipmentModalOpen.value = true;
    };

    // BOPIS Actions
    provide('pickupOptions', {
      pickupLabelsMapping: getPickupLabels(props.translations), // injected in: Organism.ShipmentSelector
      showStaticTextInsteadOfShippingLabel:
        theme.showStaticTextInsteadOfShippingLabel, // injected in: Organism.ShipmentSelector
      storeDistanceOptionList: getStoreDistanceOptionList(
        props.storeDistanceOptions,
        props.storeDistanceUnit,
        props.translations.storeAvailabilityModal
      ), // injected in: Organism.ProductAvailabilityModal
      unitOfMeasure: props.unitOfMeasure, // injected in: Organism.ProductAvailabilityModal
      storeDistanceUnit: props.storeDistanceUnit, // injected in: Organism.ProductAvailabilityModal
    });

    const getBopisStores = debounce(
      async ({ postalCode, distanceValue, uom, productVariantId }) => {
        await usedFindInStore.getStoresByPostalCode(
          postalCode,
          distanceValue,
          uom,
          productVariantId
        );
        bopisModalPristine.value = false;
      },
      1000
    );

    const resetBopisStores = () => {
      bopisModalPristine.value = true;
      usedFindInStore.resetData();
    };

    /**
     *
     * First error from error details list mapped from useCart composable.
     *
     * Redundant computed needs to be refactored
     */
    type ErrorDetailObject = {
      message: string;
      type: string;
      persistent: boolean;
      errorMessageId: string;
      additionalInfo: any;
      productId?: string;
    };
    // TODO: GLOBAL15-38458
    const cartErrorObject = computed<ErrorDetailObject | any>(() => {
      if (!recentCartError.value) return null;
      return {
        productId: recentCartError.value.productId,
        message: getINV408ErrorMessageById(
          recentCartError.value,
          getStaticTranslation('muleSoftErrors')[
            recentCartError.value.errorMessageId
          ]
        ),
      };
    });
    /**
     *
     * Reactive reference holding product related flash messages from cart response object.
     */
    const productRelatedFlashMessages = ref<FlashError[]>(
      getProductRelatedFlashMessages()
    );
    /**
     *
     * Determines if general error notification with 'productItemHasErrorMessage' code should be displayed on top of products list.
     * It should be used interchengably with flash / customer notifications product row inline.
     */
    // TODO: GLOBAL15-38458
    const showGeneralErrorNotification = computed<boolean>(() => {
      return (
        [cartErrorObject.value].every(Boolean) ||
        [
          productRelatedFlashMessages.value.length,
          outOfStockList.value.length,
          cartLineItemCustomerNotifications.value.length,
        ].some(Boolean)
      );
    });
    /**
     *
     * Checks if flash errors related to product contains some FlashErrorType which indicatest that product is no longer available (OOS or Offline)
     */
    const isProductNoLongerAvailable = computed(() => {
      return productRelatedFlashMessages.value.some((flash) =>
        [
          FlashErrorType.FullInventoryMissing,
          FlashErrorType.NoLongerAvailable,
        ].includes(flash.code as any)
      );
    });
    /**
     *
     * Picks related flash errors from cart object response base on index in path.
     */
    type InRowProductMessage = { code: string; message: string };
    const getRelatedFlashErrors = (
      item: CartLineItem
    ): InRowProductMessage[] => {
      return ((item && productRelatedFlashMessages.value) ?? [])
        .filter((err) =>
          [item.productId, item.id].includes(err?.details?.productId)
        )
        .map((err) => mapMessage(err, props.translations.flashMessages));
    };
    /**
     *
     * Get flash errors for given product.
     * Returns object with error code and translated message.
     * It's displayed above in row product customer notifications.
     */
    const getProductFlashErrors = (item): InRowProductMessage[] => {
      return getRelatedFlashErrors(
        cartItems.value.find((p) => p.id === item.id) || item
      );
    };
    /**
     *
     * Get customer notification for given product.
     * Returns object with error code and translated message.
     * It's displayed below in row product flash errors.
     */
    const getProductCustomerNotifications = (item): InRowProductMessage[] => {
      if (!item) return [];
      return cartLineItemCustomerNotifications.value
        .filter((err) => err?.details?.uuid === item.id)
        .map((err) => mapMessage(err, props.translations.flashMessages))
        .reduce((acc, err) => {
          const exist = acc.find(({ message }) => err.message === message);

          if (!exist) {
            acc.push(err);
          }

          return acc;
        }, []);
    };
    /**
     *
     * Based on route change - clears out of stock products list and customer notifiactions.
     * Sets reactive reference product related flasg messages and removes oos items.
     */
    // TODO: GLOBAL15-38458
    const getProductFlashMessagesAndRemoveOOSItems = () => {
      const isPageChanged = currentRoutePath.value !== root.$route.path;
      if (isPageChanged) {
        outOfStockList.value = [];
        // leave notifications about transition to ship to home instead of clearing them all
        // for cases when customer is redirected after transition performed on order attempt
        cartLineItemCustomerNotifications.value = cartLineItemCustomerNotifications.value.filter(
          (notification) => notification?.type?.endsWith('ToSthTransition')
        );
      }
      productRelatedFlashMessages.value = getProductRelatedFlashMessages();
      checkAndRemoveOutOfStockItems(isPageChanged);
    };

    const hasInRowProductError = (item) => {
      return [
        getProductFlashErrors(item).length,
        getProductCustomerNotifications(item).length,
      ].some(Boolean);
    };
    const sortProductWithErrors = (
      products,
      productsType: 'standard' | 'custom' = 'standard'
    ) => {
      if (
        [theme.sortProductsByFlashErrors, productsType === 'standard'].every(
          Boolean
        )
      ) {
        return [
          ...outOfStockList.value,
          ...products.filter(hasInRowProductError),
          ...products.filter((item) => !hasInRowProductError(item)),
        ];
      }
      if (productsType === 'standard') {
        return [...outOfStockList.value, ...products];
      }
      return products;
    };

    //We have two watchers instead of one because test is failing for some reason when watcher is used with array of source
    watch(
      outOfStockFlashErrors,
      () => {
        getProductFlashMessagesAndRemoveOOSItems();
      },
      { immediate: true }
    );

    watch(
      cart,
      () => {
        getProductFlashMessagesAndRemoveOOSItems();
      },
      { immediate: true }
    );

    watch(
      () => usedProduct.isQuickShopOpen.value,
      (val) => {
        !val && closeModal();
      }
    );

    const showCartProductList = computed(() => {
      const isCartPage = currentRoutePath.value === localePath(ROUTES.CART());
      const hasErrors = [
        hasFlashErrorsToDisplay.value,
        outOfStockList.value.length > 0,
        cartLineItemCustomerNotifications.value.length > 0,
      ].some(Boolean);
      const showErrors = [props.showOnlyErrorInfo, hasErrors].every(Boolean);
      return [isCartPage, showErrors].some(Boolean);
    });

    return {
      showCartProductList,
      collapsed: ref(false),
      cartProductListAccordion: ref(true),
      theme,
      cartProductBindings,
      cart,
      finalRegularProducts,
      finalCustomProducts,
      product,
      isProductHidden,
      getOverrideImage,
      getMaxQuantity,
      isActionInProgress, // exposed for unit tests
      editProduct,
      removeProduct,
      updateItemAction,
      updateItemShippingOption,
      addSaveForLaterAction,
      FavoritesContext,
      moveToFavorites,
      favoritesMessage,
      isFavorite,
      editGiftOption,
      removeGiftOption,
      showGeneralErrorNotification,
      getRelatedFlashErrors,
      getProductFlashErrors,
      productRelatedFlashMessages,
      getProductCustomerNotifications,
      findRowNotification,
      overrideProductPrice,
      resetProductPrice,
      updateProductPrice,
      isPriceAdjusted,
      priceOverrideSuccessMessage,
      selectedShipmentStoreModalItem: selectedItem,
      isIpaProsumer,
      isWranxProsumer,
      onOpenShipmentStoreModal,
      shouldRenderShipmentStoreModal,
      getBopisStores,
      resetBopisStores,
      notAddedItems,
      hasInRowProductError, // exposed for presence in vm for tests
      sortProductWithErrors, // exposed for presence in vm for tests
      isProductNoLongerAvailable,
      cartErrorObject,
    };
  },
});
