











import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'CheckoutRewardXPLRPass',
  props: {
    header: {
      type: String,
      default: '',
    },
  },
});
