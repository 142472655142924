import {
  ComponentInstance,
  useCart,
  useCustoms,
  useGtm,
  useProduct,
} from '@vf/composables';
import { CustomsAddToCartPayloadItem } from '@vf/composables/src/useCart';
import { getAddToCartOverrideAttributes } from '@vf/composables/src/useGtm/eventPropsHandlers/helpers';
import { getEventFromTemplate } from '@vf/composables/src/useGtm/helpers';
import { Product } from '@vf/api-client/src/types';
import { sendToCustoms, toggleHeader } from './index';

type CustomsAddToCartEventBody = {
  orders: CustomsAddToCartPayloadItem[];
  model: string;
  preCreatedCustomCode: string;
  '2dImageView'?: {
    view: string;
    size: string;
  };
};

type CustomsAddToCartEvent = CustomsAddToCartEventBody & {
  body?: CustomsAddToCartEventBody;
};

type CustomsAddToCartConfig = {
  componentIntegration: boolean;
  toggleHeaderOnSuccess?: boolean;
  includePreCreatedCustomsCode?: boolean;
};

const getProductData = async (
  instance: ComponentInstance,
  productId: string,
  size: string
): Promise<Product> => {
  const { configure, getProductDetails, product } = useProduct(instance);
  await getProductDetails(productId);
  configure({ size });
  const productData = product.value;

  if (!productData.color) {
    productData.color =
      productData.colors.find((color) => color.slug === productId) || null;
  }

  return productData;
};

const setAddToCartEE = (
  instance: ComponentInstance,
  product: Product
): void => {
  const { dispatchEvent } = useGtm(instance);

  dispatchEvent({
    ...getEventFromTemplate('cart:add', {}),
    composablesContexts: { useProduct: 'page-content' },
    overrideAttributes: getAddToCartOverrideAttributes(
      product,
      instance.$themeConfig
    ),
  });
  dispatchEvent(getEventFromTemplate('cart:update', {}));
};

const addToCart = async (
  instance: ComponentInstance,
  {
    componentIntegration,
    toggleHeaderOnSuccess = false,
    includePreCreatedCustomsCode = false,
  }: CustomsAddToCartConfig,
  event: CustomsAddToCartEvent
): Promise<void> => {
  try {
    const {
      orders: products,
      model: currentModel,
      '2dImageView': imageDetails,
      preCreatedCustomCode: preCreatedCustomCode,
    } = event.body || event;
    let productData: Product = null;

    products.forEach((item) => {
      item.isCustoms = true;
      item.model = currentModel;
      item.imageView = imageDetails?.view;
      item.imageSize = imageDetails?.size;
      item.precreatedCustomsCode =
        preCreatedCustomCode || item.productId.split(':').slice(0, 3).join('');
    });

    let preCreatedCustomCodes;
    const { addItem, setMiniCart, totalItems } = useCart(instance);

    const isAddedItem = await addItem(products, { isTemporary: false });
    if (includePreCreatedCustomsCode) {
      const { getCustomsCodeForRecipe } = useCustoms(instance);

      preCreatedCustomCodes = await Promise.all(
        products.map((product) =>
          getCustomsCodeForRecipe({
            recipe: product.recipe,
          })
        )
      );
    }

    if (isAddedItem) {
      const productId = products[0]?.productId.split(':').slice(0, 3).join('');
      const size = products[0]?.size;
      productData = await getProductData(instance, productId, size);
      // assign the actual recipe instead of the one fetched feom SFCC
      productData.customsRecipeID = products[0]?.recipe;
      productData.precreatedCustomsCode = products[0]?.precreatedCustomsCode;
    }
    sendToCustoms(
      instance,
      {
        command: componentIntegration ? 'parentAddToCart' : 'addToCart',
        body: {
          status: 'success',
          message: `Successfully added product(s) to cart`,
          preCreatedCustomCodes,
          totalItems: totalItems.value,
        },
      },
      { componentIntegration }
    );

    if (toggleHeaderOnSuccess && instance.$viewport?.isSmall) {
      // toggle header to display success tooltip only for mobile
      const openEvent = {
        body: {
          visible: true,
        },
      };
      toggleHeader(openEvent);
      setTimeout(() => {
        const closeEvent = {
          body: {
            visible: false,
          },
        };
        toggleHeader(closeEvent);
      }, 6000);
    }

    if (isAddedItem) {
      setAddToCartEE(instance, productData);
      setMiniCart(true, instance.$viewport?.isSmall && 6000);
    }
  } catch (error) {
    instance.$log.error('Failed adding customs item to cart', { error });
    sendToCustoms(
      instance,
      {
        command: componentIntegration ? 'parentAddToCart' : 'addToCart',
        body: {
          status: 'error',
          message: `Error in adding to cart with ${error}`,
        },
      },
      { componentIntegration }
    );
  }
};

export default addToCart;
