



































import type { PropType } from 'vue';
import {
  defineComponent,
  ref,
  onMounted,
  onUnmounted,
  computed,
} from '@vue/composition-api';
import type { BackToTopButtonTranslations } from '@vf/api-contract';
import useRootInstance from '@/shared/useRootInstance';
import { getCacheKeyFromProps, scrollTo } from '@vf/shared/src/utils/helpers';
import debounce from '@vf/shared/src/utils/helpers/debounce';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import { useHeaderAndFooterStore } from '@vf/composables/src/store/headerAndFooter';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'BackToTopButton',
  serverCacheKey: getCacheKeyFromProps,
  props: {
    translations: {
      type: Object as PropType<BackToTopButtonTranslations>,
      required: true,
    },
    /** Button type for backToButton variant */
    variant: {
      type: String,
      default: 'floating',
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    // TODO: Remove in GLOBAL15-62475
    const { isFiltersUiUpdateEnabled } = useFeatureFlagsStore();
    const headerAndFooterStore = useHeaderAndFooterStore();
    const { showSticky: showStickyFooter } = storeToRefs(headerAndFooterStore);

    let isVisible = ref(false);
    const setButtonVisibility = () => {
      isVisible.value = window?.scrollY > window?.innerHeight;
    };
    if (props.variant !== 'link') {
      onMounted(() => {
        setButtonVisibility();
        window?.addEventListener('scroll', setButtonVisibility);
      });
      onUnmounted(() => {
        window?.removeEventListener('scroll', setButtonVisibility);
      });
    }

    const color = computed(
      () => root.$themeConfig.backToTopButton?.color || 'secondary'
    );

    const icon = computed(
      () => root.$themeConfig.backToTopButton?.icon || 'chevron_up'
    );

    const icon_size = computed(() =>
      // TODO: Remove in GLOBAL15-62475
      isFiltersUiUpdateEnabled
        ? root.$themeConfig.backToTopButton.icon_size_redesign
        : root.$themeConfig.backToTopButton.icon_size
    );

    const goToFirstFocusableElement = () => {
      const focusableElements = document.querySelectorAll<HTMLElement>(
        'a[href], button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
      );
      for (let i = 0; i < focusableElements.length; i++) {
        // skip hidden element, disable and aria-hidden
        if (
          focusableElements[i].hasAttribute('disabled') ||
          focusableElements[i].getAttribute('aria-hidden') ||
          focusableElements[i].offsetParent === null
        ) {
          continue;
        }
        focusableElements[i].focus();
        return;
      }
    };

    const scrollToTop = () => {
      const afterFinishScroll = debounce(() => {
        window.removeEventListener('scroll', afterFinishScroll);
        goToFirstFocusableElement();
      }, 200);

      window.addEventListener('scroll', afterFinishScroll);
      scrollTo();
    };

    return {
      scrollToTop,
      isVisible,
      icon,
      color,
      icon_size,
      isFiltersUiUpdateEnabled,
      showStickyFooter,
    };
  },
});
