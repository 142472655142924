//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MegaMenu from './Organism.MegaMenu';
import { computed } from '@vue/composition-api';
import { defineComponent, inject } from '@vue/composition-api';
export default defineComponent({
  name: 'VfSimplifiedHeader',
  components: {
    MegaMenu,
  },
  props: {
    logo: {
      type: [String, Object],
      default: '',
    },
    logoHeight: {
      type: Number,
      default: null,
    },
    logoWidth: {
      type: Number,
      default: null,
    },
    title: {
      type: String,
      default: '',
    },
    linkTag: {
      type: String,
      default: 'a',
    },
    steps: {
      type: Array,
      default: () => [],
    },
    triggerStickyOnElementId: {
      type: String,
      default: '',
    },
    triggerStickyOnDefinedNumber: {
      type: Number,
      default: null,
    },
    currentStepProp: {
      type: String,
      default: '',
    },
    /** @vf/api-contract Object: ClearSession  */
    clearSession: Object,
  },
  setup(props, { root, emit }) {
    // TODO: GLOBAL15-61059 remove after redesign core
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');
    // TODO: Remove it after GLOBAL15-56318
    const currentStep = computed(() => {
      if (props.currentStepProp) return props.currentStepProp;
      const currentStepObject = props.steps.find(
        (step) => step.link === root?.$route?.path
      );
      return currentStepObject ? currentStepObject.id : 1;
    });

    const onClearSession = (link) => emit('clear-session', link);

    const headerClasses = computed(() => ({
      'vf-header': true,
      'simplified-header': true,
      'simplified-header--left-align': props.steps.length,
    }));

    return {
      isCoreRedesignEnabled,
      bindUrlAttribute: (link) => {
        return props.linkTag === 'a' ? { href: link } : { to: link };
      },
      getComponentType: (step) => {
        return step.link && step.id <= currentStep.value ? 'VfLink' : 'VfText';
      },
      currentStep,
      onClearSession,
      headerClasses,
    };
  },
});
