














import { computed, defineComponent } from '@vue/composition-api';
import useRootInstance from '@/shared/useRootInstance';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

import { pluralize } from '@/helpers/pluralize';

export default defineComponent({
  name: 'CartTopSummary',
  props: {
    totalItems: Number,
    showTotals: Boolean,
  },
  setup(props) {
    const { root } = useRootInstance();
    // TODO: GLOBAL15-61059 remove after redesign core
    const { isCoreRedesignEnabled } = useFeatureFlagsStore();

    const itemsText = computed(() => {
      return `${props.totalItems} ${pluralize(
        root.$t('cartTopSummary.item') as string,
        props.totalItems
      )}`;
    });

    return {
      isCoreRedesignEnabled,
      itemsText,
    };
  },
});
