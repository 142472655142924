





































































import type { PropType } from 'vue';
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from '@vue/composition-api';
import {
  ROUTES,
  useAccount,
  useGoogleAutocomplete,
  useI18n,
  useLoyalty,
  useNotification,
  useUtilities,
} from '@vf/composables';
import type {
  BasicInformationTranslations,
  FormValidationConfig,
  ConsumerAddress,
  ComponentWithVariants,
} from '@vf/api-contract';
import useRootInstance from '@/shared/useRootInstance';
import { mapPhoneOnUSCAAddress } from '@vf/shared/src/utils/helpers/address';
import type { Interest } from '@vf/composables/src/useCms/types';
import type { PhoneInputCountry } from '@vf/composables/src/useUtilities';
import { useUserStore } from '@vf/composables/src/store/user';
import { storeToRefs } from 'pinia';
import XplrPass from '@/components/loyalty/XplrPass.vue';
import XPLRWidget from '@/components/XPLRWidget.vue';

const phoneNumberRegex =
  '^(\\+\\d{1,2}\\s)?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$';
const birthdayRegex =
  '(0[1-9]|1[012])[- \\/.](0[1-9]|[12][0-9]|3[01])[- \\/.](19|20)\\d\\d';

export default defineComponent({
  name: 'BasicInformationForm',
  components: {
    XplrPass,
    XPLRWidget,
  },
  props: {
    translations: {
      type: Object as PropType<BasicInformationTranslations>,
      required: true,
    },
    validationConfig: {
      type: Object as PropType<FormValidationConfig>,
      default: () => ({
        phoneNumberRegex,
        birthdayRegex,
        phoneNumberFormat: '(555-555-5555)',
        zipCodeFormat: '(55555 or 55555-5555)',
        zipCodeRegex: '^[0-9]{5}(?:-[0-9]{4})?$',
        birthdayFormat: '(MM-DD-YYYY)',
      }),
    },
    componentVariant: {
      type: String as PropType<ComponentWithVariants>,
      default: 'VARIANT_A',
    },
    contextName: {
      type: String,
      default: 'basic-information-form',
    },
    showEnrollmentFooter: {
      type: Boolean,
      default: false,
    },
    showTwoColumns: {
      type: Boolean,
      default: true,
    },
    showSingleColumn: {
      type: Boolean,
      default: false,
    },
    showXplrPass: {
      type: Boolean,
      default: false,
    },
    interests: {
      type: Array as PropType<Interest[]>,
      required: true,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const theme = root.$themeConfig?.basicInformation;
    const isTermsConfirmed = ref(!props.showXplrPass);

    const {
      setBasicInformation,
      basicInformation,
      getAddresses,
      addAddress,
      updateAddress,
    } = useAccount(root);
    const { getLoyaltyToken, memberId } = useLoyalty(root);
    const userStore = useUserStore(root);
    const { loyaltyEnrolled } = storeToRefs(userStore);
    const {
      addNotification,
      clearNotifications,
      setChangePasswordNotification,
    } = useNotification(root);
    const {
      getProvinceList,
      getCountry,
      getPhoneInputCountryList,
    } = useUtilities(root);
    const { getIetfLocale, localePath } = useI18n(root);
    const {
      loadGoogleApisScript,
      unloadGoogleApisScript,
      setupGoogleAutocomplete,
      isValidContext,
      isGoogleAutocompleteEnabled,
      googleAutocompleteCountries,
    } = useGoogleAutocomplete(root);

    const locale = getIetfLocale();
    const countryCode = getCountry().toUpperCase();
    const provinces = ref([]);
    const isSaving = ref(false);

    const loyaltyAddress = ref({
      id: null,
      addressLine1: null,
      addressLine2: null,
      city: null,
      province: null,
      postalCode: null,
    });

    const setLoyaltyAddresses = (data) => {
      const [address] = data || [];
      if (!address) return;

      loyaltyAddress.value.id = address.id;
      loyaltyAddress.value.addressLine1 = address.addressLine1;
      loyaltyAddress.value.addressLine2 = address.addressLine2;
      loyaltyAddress.value.city = address.city;
      loyaltyAddress.value.postalCode = address.postalCode;
      loyaltyAddress.value.province = address.province;
    };

    watch(getAddresses('L'), setLoyaltyAddresses, { immediate: true });

    const basicInformationComponent = computed(() => {
      return () =>
        import(
          `@vf/ui/components/Organism.BasicInformation.${props.componentVariant}.vue` /* webpackChunkName: "uiBase" */
        );
    });

    const phoneInputCountries = ref<PhoneInputCountry[]>([]);

    onMounted(async () => {
      if (loyaltyEnrolled.value) {
        getLoyaltyToken();
      }

      const data = await getProvinceList(countryCode.toLowerCase(), locale);
      provinces.value = data?.provinces;
      if (
        isValidContext(props.contextName) &&
        isGoogleAutocompleteEnabled.value
      ) {
        await loadGoogleApisScript();

        setupGoogleAutocomplete(
          props.translations.streetLabel,
          props.translations.apartmentLabel,
          loyaltyAddress.value,
          googleAutocompleteCountries
        );
      }

      const allPhoneInputCountries = await getPhoneInputCountryList(locale);
      phoneInputCountries.value = allPhoneInputCountries.filter(
        (item) => item.countryCode === countryCode
      );

      const accountCreatedNotification = sessionStorage.getItem(
        'accountCreated'
      );

      if (accountCreatedNotification) {
        addNotification({
          message: accountCreatedNotification,
          type: 'success',
        });
        sessionStorage.removeItem('accountCreated');
      }
    });

    onUnmounted(async () => {
      if (isGoogleAutocompleteEnabled.value) {
        await unloadGoogleApisScript();
      }
    });

    const updateBasicInformation = (payload) => {
      let setBasicValues = {
        ...mapPhoneOnUSCAAddress(
          {
            ...payload,
            country: countryCode,
          },
          true
        ),
      };

      if (payload.subscriptions) {
        setBasicValues = {
          ...setBasicValues,
          subscriptions: basicInformation.value.subscriptions,
        };
      }
      return setBasicInformation({ ...setBasicValues });
    };

    const updateLoyaltyAddress = (loyaltyAddressId: string, value) => {
      // Create/update address only for loyalty enrolled users
      const address = value.address as ConsumerAddress;
      const newAddress = {
        ...address,
        approachType: 'L',
        country: countryCode,
        email: basicInformation.value.email,
        phone: basicInformation.value.phone,
        firstName: value.firstName,
        lastName: value.lastName,
      };

      if (loyaltyAddressId) {
        return updateAddress(loyaltyAddress.value.id, newAddress);
      }

      return addAddress(newAddress);
    };

    const showSuccessMessage = () =>
      addNotification({
        message: props.translations.setBasicInformationSuccess,
        type: 'success',
      });

    const showErrorMessage = () =>
      addNotification({
        message: props.translations.setBasicInformationError,
        type: 'danger',
      });

    const handleLoyaltyFormSubmit = async (payload, updateAddress: boolean) => {
      if (isSaving.value) {
        return;
      }
      setChangePasswordNotification(false);
      clearNotifications();
      isSaving.value = true;

      try {
        if (loyaltyEnrolled.value) {
          if (updateAddress) {
            await updateLoyaltyAddress(loyaltyAddress.value?.id, payload);
          }

          await updateBasicInformation(payload);
        } else {
          const { firstName, lastName } = payload;
          await updateBasicInformation({ firstName, lastName });
        }

        showSuccessMessage();
      } catch (e) {
        showErrorMessage();
      } finally {
        isSaving.value = false;
      }
    };

    const handleEnrollLoyalty = async () => {
      if (isSaving.value) {
        return;
      }
      setChangePasswordNotification(false);
      clearNotifications();
      isSaving.value = true;

      try {
        await setBasicInformation({
          enrollments: [{ type: 'Loyalty', country: countryCode }],
        });

        await root.$router.push(localePath(ROUTES.ACCOUNT_FAMILY()));
      } catch (e) {
        showErrorMessage();
      } finally {
        isSaving.value = false;
      }
    };

    const handleFormSubmit = async (payload) => {
      if (isSaving.value) {
        return;
      }
      setChangePasswordNotification(false);
      clearNotifications();
      isSaving.value = true;

      try {
        const response = await updateBasicInformation(payload);

        if (response.status === 200) {
          showSuccessMessage();
        }
      } catch (e) {
        showErrorMessage();
      } finally {
        isSaving.value = false;
      }
    };

    return {
      theme,
      minRegisterAge: root.$config.MINIMUM_REGISTER_AGE,
      locale,
      provinces,
      isTermsConfirmed,
      loyaltyEnrolled,
      isSaving,
      basicInformation,
      basicInformationComponent,
      loyaltyAddress,
      memberId,
      handleEnrollLoyalty,
      handleLoyaltyFormSubmit,
      handleFormSubmit,
      phoneInputCountries,
      countryCode,
    };
  },
});
