//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'VfHeading',
  props: {
    level: {
      type: [Number, String],
      default: 2,
    },
    html: {
      type: Boolean,
      default: false,
    },
    hasParentTag: {
      type: Boolean,
      default: false,
    },
    title: {
      type: [String, Boolean],
      default: '',
    },
    subtitle: {
      type: [String, Boolean],
      default: '',
    },
    titleModifier: {
      type: String,
      default: 'title-1',
    },
    subtitleModifier: {
      type: String,
      default: '',
    },
    textAlign: {
      type: Object,
      default: () => ({
        small: '',
        medium: '',
        large: '',
      }),
    },
    styles: {
      type: Object,
      default: () => ({
        titleClassModifiers: '',
        subtitleClassModifiers: '',
        classModifiers: '',
        titleColor: '',
        subtitleColor: '',
        titleBackgroundColor: '',
        subtitleBackgroundColor: '',
        titleFontSize: '',
        titleFontWeight: '',
        subtitleFontSize: '',
        subtitleFontWeight: '',
        titleFontFamily: '',
        subtitleFontFamily: '',
        titleTextAlign: '',
        subtitleTextAlign: '',
        titleFontStyle: '',
      }),
    },
    titleInQuotes: {
      type: Boolean,
      default: false,
    },
    vfTextAriaRole: {
      type: String,
    },
    vfTextAriaLive: {
      type: String,
    },
  },
  computed: {
    hasSubtitle() {
      return this.subtitle && this.subtitle.trim()?.length;
    },
    hasTitle() {
      return this.title && this.title.trim()?.length;
    },
    titleTagLevel() {
      return `h${this.level ? this.level : 2}`;
    },
    titleLineHeight() {
      return this.styles.titleFontSize ? 'normal' : '';
    },
    subtitleLineHeight() {
      return this.styles.subtitleFontSize ? 'normal' : '';
    },
  },
};
