import { PropType } from '@vue/composition-api';
import { ProductColorsTranslations } from '@vf/api-contract';

const CmsProps = {
  /** How many items to display on small viewports */
  itemsCountSmall: {
    type: Number,
    default: null,
  },
  /** How many items to display on medium viewports */
  itemsCountMedium: {
    type: Number,
    default: null,
  },
  /** How many items to display on large viewports */
  itemsCountLarge: {
    type: Number,
    default: null,
  },
  /** Flag to determine if FE should show all colors on the screen or collapse it under Show all colors button */
  showAllColors: {
    type: Boolean,
    default: false,
  },
  /** Flag to determine that all colors has to be showing and hide an ability to collapse them */
  showAlwaysAllColors: {
    type: Boolean,
    default: false,
  },
  /** How FE should render color blocks (swatches / pictures) */
  displayAs: {
    type: String,
    default: 'pictures',
    validator: (value: string): boolean => {
      return ['swatches', 'pictures'].includes(value);
    },
  },
  /** Translation strings */
  translations: {
    type: Object as PropType<ProductColorsTranslations>,
    default: (): Record<string, unknown> => ({}),
  },
  /** Show numeration flag */
  showNumeration: {
    type: Boolean,
  },
  showDivider: {
    type: Boolean,
    default: false,
  },
  hideColorName: {
    type: Boolean,
    default: false,
  },
  showFullLabel: {
    type: Boolean,
    default: false,
  },
  showSolidColors: {
    type: Boolean,
    default: false,
  },
  saveVariationOnColorChange: {
    type: Boolean,
    default: false,
  },
  historyReplaceOnColorChange: {
    type: Boolean,
    default: false,
  },
  /** Flag for splitting color swatches if colors have different prices */
  splitColorsWatchForDiscount: {
    type: Boolean,
    default: false,
  },
};

export default CmsProps;
