






















































































































































































































































import type { PropType } from 'vue';
import {
  defineComponent,
  computed,
  Ref,
  ComputedRef,
  inject,
  ref,
  onMounted,
} from '@vue/composition-api';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';
import {
  TeaserGridItemTranslations,
  Image,
  ContentAlignProperties,
  MediaSeoAttributes,
  VideoObject,
} from '@vf/api-contract/src';
import type {
  CTAButtons,
  IconPosition,
  Icons,
  RegularButtonSize,
  RegularButtonStyle,
} from '@vf/api-contract';
import useRootInstance from '../../theme/shared/useRootInstance';
import { generateDimensionsSettings } from '@vf/shared/src/utils/helpers/generateDimensionsSettings';

type ShowOverlayProp = {
  small: boolean;
  medium: boolean;
  large: boolean;
};

type OverlaySettingsProp = {
  hOffset: number;
  vOffset: number;
  width: string;
};

type StylesProp = {
  titleColor: string;
  headingLevel: string | number;
  titleClassModifiers: string;
  textColor: string;
  textClassModifiers: string;
  titleFontSize: string;
  teaserClass: string;
  titleFontFamily: string;
  titleBackgroundColor: string;
};

export default defineComponent({
  name: 'VfTeaserTile',
  serverCacheKey: getCacheKeyFromProps,
  props: {
    translations: {
      type: Object as PropType<TeaserGridItemTranslations>,
      default: () => ({
        readMore: '',
        showLess: '',
      }),
    },
    /** Image src object */
    image: {
      type: [String, Object] as PropType<string | Image>,
      default: '',
    },
    /** Teaser image height */
    imageHeight: {
      type: [String, Number, Object],
      default: 276,
    },
    /** Teaser image width */
    imageWidth: {
      type: [String, Number, Object],
      default: 460,
    },
    video: {
      type: Object as PropType<{ small: VideoObject }>,
      default: () => ({
        small: {
          sources: [],
          seo: {
            title: '',
            date: '',
            description: '',
          },
          tracks: [],
          poster: '',
          options: {
            preload: 'metadata',
            autoplay: false,
            hideControls: false,
            muted: true,
            loop: false,
            showPlayButton: false,
            showSubtitles: false,
          },
        },
      }),
    },
    /** Teaser tile title */
    title: {
      type: String as PropType<string>,
      default: '',
    },
    /** Teaser subtitle */
    subtitle: {
      type: String as PropType<string>,
      default: '',
    },
    /** Default Read More Button Text */
    defaultReadMoreButtonText: {
      type: String as PropType<string>,
      default: 'Read more',
    },
    /** Link to be applied to image/video */
    imageLink: {
      type: String as PropType<string>,
      default: '',
    },
    /** Link to be applied to button */
    link: {
      type: [String, Object],
      default: '',
    },
    /** Button Link Text */
    linkCta: {
      type: String as PropType<string>,
      default: null,
    },
    /** Teaser grid item text */
    text: {
      type: String as PropType<string>,
      default: '',
    },
    /** Either tile content is HTML markup or not */
    useRichText: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    /** Ignore text box sizing for Vans if this component is embedded in another **/
    useTextClamping: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    /** Tile Tag (used for articles) **/
    tag: {
      type: String as PropType<string>,
      default: '',
    },
    /** Styling modifiers for text and title */
    styles: {
      type: Object as PropType<StylesProp>,
      default: () => ({
        titleColor: '',
        headingLevel: '',
        titleClassModifiers: '',
        textColor: '',
        textClassModifiers: '',
        titleFontSize: '',
        teaserClass: '',
        titleFontFamily: '',
        titleBackgroundColor: '',
      }),
    },
    /** Styling modifiers for overlayed container */
    showOverlay: {
      type: Object as PropType<ShowOverlayProp>,
      default: () => generateDimensionsSettings(() => true),
    },
    seo: {
      type: Object as PropType<MediaSeoAttributes>,
      default: () => ({
        title: '',
        description: '',
        date: '',
      }),
    },
    buttonStyle: {
      type: String as PropType<RegularButtonStyle>,
      default: 'text',
    },
    buttonSize: {
      type: String as PropType<RegularButtonSize>,
      default: 'small',
    },
    buttonIcon: {
      type: String as PropType<Icons>,
      default: '',
    },
    buttonIconPosition: {
      type: String as PropType<IconPosition>,
      default: '',
    },
    /** Flag to determine if first button text should be underlined */
    buttonUnderline: {
      type: Boolean,
      default: false,
    },
    textAlign: {
      type: Object as PropType<ContentAlignProperties>,
      default: () => generateDimensionsSettings(() => 'left'),
    },
    /** Either link should be opened in new modal window or not */
    openInNewModal: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    /** Either link should be opened in new tab in the browser or not */
    openInNewTab: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    /** (automatic) Data extracted from specified target for analytics purposes */
    dataNavigation: {
      type: String as PropType<string>,
      default: null,
    },
    /** (automatic) Data extracted from specified location for analytics purposes */
    dataDomLocation: {
      type: String as PropType<string>,
      default: null,
    },
    /** Number of content lines to be rendered on small screens */
    maxLinesSmall: {
      type: [String, Number] as PropType<string | number>,
      default: 2,
    },
    /** Number of content lines to be rendered on medium screens */
    maxLinesMedium: {
      type: [String, Number] as PropType<string | number>,
      default: 2,
    },
    /** Number of content lines to be rendered on large screens */
    maxLinesLarge: {
      type: [String, Number] as PropType<string | number>,
      default: 2,
    },
    /** Positioning modifiers for overlayed container on small screens */
    overlaySettingsSmall: {
      type: Object as PropType<OverlaySettingsProp>,
      default: () => ({
        hOffset: null,
        vOffset: null,
        width: '',
      }),
    },
    /** Positioning modifiers for overlayed container on medium screens */
    overlaySettingsMedium: {
      type: Object as PropType<OverlaySettingsProp>,
      default: () => ({
        hOffset: null,
        vOffset: null,
        width: '',
      }),
    },
    /** Positioning modifiers for overlayed container on large screens */
    overlaySettingsLarge: {
      type: Object as PropType<OverlaySettingsProp>,
      default: () => ({
        hOffset: null,
        vOffset: null,
        width: '',
      }),
    },
    teaserClass: {
      type: String,
      default: '',
    },
    showButtonsBelowArticle: {
      type: Object,
      default: () => generateDimensionsSettings(() => false),
    },
    dataDate: {
      type: String,
      default: null,
    },
    showTagBelowImage: {
      type: Boolean,
      default: false,
    },
    squareImages: {
      type: Boolean,
      default: false,
    },
    ctaButtons: {
      type: Array as PropType<CTAButtons[]>,
      default: () => [
        {
          buttonText: '',
          buttonStyle: '',
          buttonSize: '',
          buttonIcon: '',
          buttonIconPosition: '',
          buttonLink: '',
          buttonUnderline: '',
          buttonTargetComponent: '',
        },
      ],
    },
    buttonTargetComponent: {
      type: String,
      default: '',
    },
    lazyLoadVideo: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const { root } = useRootInstance();
    const config = root.$themeConfig.teaserTile;
    // TODO: GLOBAL15-61059 remove after redesign core
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');

    const textClamp = getTextClampClass(config.restrictArticleLines);

    const to = computed(() => props.imageLink || props.link);

    const isVideo: ComputedRef<boolean> = computed(
      () => props.video?.small?.sources?.length > 0
    );

    const linkTargetAttribute = computed(() =>
      props.openInNewTab ? '_blank' : ''
    );

    const isExpanded: Ref<boolean> = ref(!!props.link);
    const toggleVisibility = () => {
      if (props.link) return;
      isExpanded.value = !isExpanded.value;
    };

    const callButtonActions = () => {
      toggleVisibility();
      emitCustomModal(props.buttonTargetComponent);
    };

    const emitCustomModal = (target: string) => {
      if (props.openInNewModal && target === 'CMVideo') {
        emit('custom-modal-styles');
      }
    };

    const textScrollHeight: Ref<number> = ref(0);
    const textClientHeight: Ref<number> = ref(0);

    onMounted(() =>
      onMountedCallback(root, props, textClientHeight, textScrollHeight)
    );

    const buttonText: ComputedRef<string> = computed(() => {
      if (props.link) {
        return props.linkCta !== null
          ? props.linkCta || props.defaultReadMoreButtonText
          : '';
      }

      if (textScrollHeight.value > textClientHeight.value) {
        return isExpanded.value
          ? props.translations.showLess
          : props.translations.readMore;
      }

      return '';
    });

    const customButtonIcon = getCustomButtonIcon(
      props.buttonIcon,
      props.styles.teaserClass
    );

    const customButtonIconSize = getCustomButtonIconSize(
      props.styles.teaserClass,
      config.iconSize
    );

    const parentHeadingLevel = inject('headingLevel', null);

    const headingLevel = computed(() => {
      return parentHeadingLevel
        ? Number(parentHeadingLevel) + 1
        : props.styles.headingLevel || 2;
    });

    const maxNumberOfTextLinesVariables = computed(() => {
      if (config.restrictArticleLines && props.useTextClamping) {
        return generateDimensionsSettings((dimension) => {
          // make these values usable for CSS - replaced "none" for no value to the CSS default height of "auto"
          if (
            !props[`maxLines${dimension}`] ||
            props[`maxLines${dimension}`] === 0
          ) {
            return 'auto';
          } else {
            return (
              props[`maxLines${dimension}`] *
                config.remMultiplier[root.$viewport.size] +
              'rem'
            );
          }
        }, '--vf-teaser-tile-line-clamp');
      } else {
        // these values have been ignored up until now 12/7/2022 but keep it in the event we ever want to do a
        // "show more" or "show less and actually use these CSS values this way"
        return generateDimensionsSettings((dimension) => {
          // make these values usable for CSS - replaced "none" for no value to the CSS default height of "auto"
          return isExpanded.value ? 'auto' : props[`maxLines${dimension}`];
        }, '--vf-teaser-tile-line-clamp');
      }
    });

    const contentPositionSettingsStyles: ComputedRef = computed(() => {
      return {
        ...generateDimensionsSettings((dimension) => {
          const offset = props[`overlaySettings${dimension}`].hOffset;
          return offset && `${offset}px`;
        }, '--article-tile-content-position-left'),
        ...generateDimensionsSettings((dimension) => {
          const offset = props[`overlaySettings${dimension}`].vOffset;
          return offset && `${offset}px`;
        }, `--article-tile-content-position-top`),
      };
    });

    const contentWidthStyles: ComputedRef = computed(() => {
      return generateDimensionsSettings((dimension) => {
        return props[`overlaySettings${dimension}`].width || null;
      }, '--article-tile-content-width');
    });

    const alt = computed(() => getImageAlt(props.image));

    return {
      isCoreRedesignEnabled,
      isVideo,
      isExpanded,
      to,
      buttonText,
      customButtonIcon,
      customButtonIconSize,
      linkTargetAttribute,
      headingLevel,
      toggleVisibility,
      callButtonActions,
      emitCustomModal,
      maxNumberOfTextLinesVariables,
      contentPositionSettingsStyles,
      contentWidthStyles,
      alt,
      textClamp,
    };
  },
});

function getImageAlt(image) {
  return typeof image === 'object' ? image.alt : '';
}

function getTextClampClass(restrictArticleLines) {
  return restrictArticleLines ? 'article-tile__text--clamped' : '';
}

function onMountedCallback(root, props, textClientHeight, textScrollHeight) {
  if (props.link) return;
  const articleText = root.$el.querySelector('.article-tile__text');
  if (articleText === null) return;
  textScrollHeight.value = articleText.scrollHeight;
  textClientHeight.value = articleText.clientHeight;
}

function getCustomButtonIcon(buttonIcon: string, teaserClass: string): string {
  let customIcon = buttonIcon === 'no-icon' ? '' : buttonIcon;
  if (!customIcon && teaserClass) {
    customIcon = 'arrow_right';
  }
  return customIcon;
}

function getCustomButtonIconSize(
  teaserClass: string,
  iconSize: string
): string {
  return teaserClass ? '20px' : iconSize ?? '';
}
