





























import { computed, defineComponent } from '@vue/composition-api';
import { replaceAll } from '@/helpers/replaceAll';
import { usePaymentMethods, useRewardCycleTranslations } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import PaymentMethodSummary from '@/components/static/checkout/PaymentMethodSummary.vue';
import AddressPreview from '@/components/static/AddressPreview.vue';
import type { PropType } from 'vue';
import type { Address } from '@vf/api-client/src/types';
import type { CardType, CreditCard } from '@vf/api-contract';
import type { PaymentInstruments } from '@vf/api-client';
import { PaymentMethodCode } from '@vf/api-client';

export default defineComponent({
  name: 'PaymentReview',
  components: {
    PaymentMethodSummary,
    AddressPreview,
  },
  props: {
    paymentMethod: String as PropType<PaymentMethodCode>,
    card: Object as PropType<CreditCard>,
    appliedGiftCards: Array as PropType<PaymentInstruments[]>,
    appliedRewardCards: Array as PropType<PaymentInstruments[]>,
    appliedRewardCodes: Array as PropType<PaymentInstruments[]>,
    appliedPointRewards: Array as PropType<PaymentInstruments[]>,
    appliedAthleteCredits: Array as PropType<PaymentInstruments[]>,
    billingAddress: Object as PropType<Address>,
    isZeroOrder: Boolean,
  },
  setup(props) {
    const { root } = useRootInstance();
    const { getPaymentMethodHandler } = usePaymentMethods(root);

    const { paymentMethod, card } = props;

    const replaceTranslation = (key: string, replacement) =>
      replaceAll(root.$t(`paymentReview.${key}`) as string, replacement);

    const getLabelFormatter = (key: string) => (instrument) =>
      replaceTranslation(key, {
        ...instrument,
        ...((instrument.c_gcCardNumber || instrument.gcCardNumber) && {
          cardNumber: (
            instrument.c_gcCardNumber || instrument.gcCardNumber
          ).slice(-4),
        }),
      });

    const getVansFamilyLabel = (instrument) =>
      replaceTranslation('vansFamilyReward', {
        discount: root.$formatPrice(instrument.amount),
        points: parseInt(instrument.loyaltyPoints).toString(),
      });

    const t = (key: string) =>
      root.$t(`orderSummarySidebar.success.${key}`) as string;

    const { getRewardCycleText } = useRewardCycleTranslations({
      summer: t('issuedSummer'),
      winter: t('issuedWinter'),
      spring: t('issuedSpring'),
    });

    const paymentInstruments = computed(() => {
      const paymentInstrumentsHandlers = [
        {
          items: props.appliedGiftCards,
          labelParseFn: getLabelFormatter('giftCardEnding'),
        },
        {
          items: props.appliedRewardCards,
          labelParseFn: getLabelFormatter('rewardCardEnding'),
        },
        {
          items: props.appliedRewardCodes,
          labelParseFn: (instrument) =>
            getLabelFormatter('rewardCode')({
              rewardLabel: getRewardCycleText(instrument),
            }),
        },
        {
          items: props.appliedPointRewards,
          icon: {
            width: 52,
            height: 36,
            name: 'vansFamily',
          },
          labelParseFn: (instrument) => getVansFamilyLabel(instrument),
        },
        {
          items: props.appliedAthleteCredits,
          labelParseFn: () => root.$t('paymentReview.athleteDiscount'),
        },
      ];

      return paymentInstrumentsHandlers
        .map(({ items, icon, labelParseFn }) => {
          return items?.map((instrument) => {
            return {
              icon: icon || {
                name: 'card',
                width: 35,
                height: 23,
              },
              label: labelParseFn(instrument),
            };
          });
        })
        .filter((instruments) => instruments && instruments.length);
    });

    const selectedPaymentMethod = computed(() => {
      if (paymentMethod === PaymentMethodCode.CREDIT_CARD) {
        const supportedTypes: CardType[] = [
          'visa',
          'mastercard',
          'amex',
          'diners',
          'discover',
        ];
        const cardType = card.cardType?.toLowerCase() as CardType;
        const iconName = supportedTypes.includes(cardType) ? cardType : 'card';

        return {
          icon: {
            name: iconName,
            width: 57,
            height: 32,
          },
          label:
            replaceAll(root.$t('paymentReview.creditCardEnding') as string, {
              cardNumber: card.maskedNumber.slice(-4),
            }) +
            (card.expirationMonth && card.expirationYear
              ? replaceAll(
                  root.$t('paymentReview.creditCardExpiryDate') as string,
                  {
                    dateExpiry: `${
                      card.expirationMonth
                    }/${card.expirationYear.toString().slice(-2)}`,
                  }
                )
              : ''),
        };
      }

      return {
        icon: getPaymentMethodHandler(paymentMethod)?.icon,
        label: '',
      };
    });

    return {
      paymentInstruments,
      selectedPaymentMethod,
    };
  },
});
