


















import type { PropType } from 'vue';
import { defineComponent, watch } from '@vue/composition-api';
import type { ProductFindInStoreTranslations } from '@vf/api-contract';
import { useProduct, useFindInStore } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';

const ProductFindInStore = () =>
  import(
    /* webpackChunkName: "ProductFindInStore" */
    /* webpackMode: "lazy" */
    /* webpackPrefetch: true */
    /* webpackPreload: true */
    '../pdp/ProductFindInStore.vue'
  );

export default defineComponent({
  name: 'QuickshopFindInStore',
  components: { ProductFindInStore },
  props: {
    translations: {
      type: Object as PropType<ProductFindInStoreTranslations>,
      required: true,
    },
    /** Config for unit of measure */
    unitOfMeasure: {
      type: String,
      default: 'km',
    },
    contextKey: {
      type: String,
      default: 'quickshop',
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { findInStoreQuickShopVisible } = useProduct(root, props.contextKey);
    const { stores, scrollToFindInStoreResults } = useFindInStore(root);

    watch(stores, scrollToFindInStoreResults);

    return {
      findInStoreQuickShopVisible,
    };
  },
});
