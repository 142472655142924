
















































































































import { PropType } from 'vue';
import { Product, CartLineItem } from '@vf/api-client';
import {
  CheckoutSidebarTranslations,
  LocaleDataObject,
} from '@vf/api-contract';
import { computed, defineComponent, ComputedRef } from '@vue/composition-api';
import {
  getCacheKeyFromProps,
  getDeliveryDateLabel,
  getProductImage,
} from '@vf/shared/src/utils/helpers';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'CartOrderSummaryProductList',
  serverCacheKey: getCacheKeyFromProps,
  props: {
    translations: {
      type: Object as PropType<CheckoutSidebarTranslations['productList']>,
      default: () => ({}),
    },
    products: {
      type: Array as PropType<Product[]>,
      default: () => [],
    },
    locale: {
      type: String,
      default: '',
    },
    /** Flag to determine if EDD should be displayed */
    showEstimatedDate: {
      type: Boolean,
      default: false,
    },
    mergeNarvarAndPickupInfo: {
      type: Boolean,
      default: false,
    },
    localeData: {
      type: Object as PropType<LocaleDataObject>,
      default: () => ({
        localeCode: '',
        localeMapping: '',
      }),
    },
    totalItems: {
      type: Number,
      default: 1,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const customProducts: ComputedRef<Product[]> = computed(() =>
      props.products.filter((p: Product) => p.recipeId)
    );
    const standardProducts: ComputedRef<Product[]> = computed(() =>
      props.products.filter((p: Product) => !p.recipeId)
    );

    const getGiftBoxPriceLabel = (product: CartLineItem): string => {
      if (!product.gift || !product.isGiftBoxSelected) return '';
      const price = root.$formatPrice(
        product.giftBoxPrice,
        product.price?.currency
      );
      return props.translations.giftBoxPrice
        .replace('{{quantity}}', String(product.qty))
        .replace('{{price}}', String(price));
    };

    const getPickupLabel = (product: CartLineItem): string => {
      const pickupOption = product?.shippingOptions?.find(
        (option) => option.available && option.selected && option.storeInfo
      );
      return pickupOption
        ? props.translations.inStorePickup?.replace(
            '{{storeName}}',
            pickupOption.storeInfo.name
          )
        : undefined;
    };

    const getCommonNarvarAndPickupLabel = (
      product: CartLineItem
    ): string | null => {
      const pickupOption = product?.shippingOptions?.find(
        (option) => option.available && option.selected && option.storeInfo
      );
      if (!pickupOption || !props.showEstimatedDate) {
        return null;
      }
      if (pickupOption.shippingMethod?.code === 'pickup') {
        return getPickupLabel(product);
      }
      return getDeliveryDateLabel(
        product.deliveryTime,
        props.translations.commonNarvarAndPickupLabel?.replace(
          '{{storeName}}',
          pickupOption.storeInfo.name
        ),
        true,
        true,
        props.localeData
      );
    };

    const productsListHeader = computed<string>(() => {
      const singular = props.totalItems === 1;
      return `${props.totalItems} ${
        singular
          ? props.translations.itemsCountSingular
          : props.translations.itemsCountPlural
      }`;
    });

    return {
      customProducts,
      standardProducts,
      getProductImage,
      getGiftBoxPriceLabel,
      getDeliveryDateLabel,
      getPickupLabel,
      getCommonNarvarAndPickupLabel,
      productsListHeader,
    };
  },
});
