





































































import { PropType } from 'vue';
import { VueMaskDirective } from 'v-mask';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import {
  FormValidationConfig,
  OrderSmsSubscriptionTranslations,
} from '@vf/api-contract';
import {
  defineComponent,
  ref,
  computed,
  onMounted,
} from '@vue/composition-api';
import useRootInstance from '@/shared/useRootInstance';
import { useAccount, useValidation } from '@vf/composables';
import {
  checkPhone,
  stripPhoneAndPrependUSCAAreaCode,
} from '@vf/shared/src/utils/helpers';
import { errorMessages } from '@vf/composables/src/utils/errorMessages';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@vf/composables/src/store/user';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

export default defineComponent({
  name: 'OrderSmsSubscription',
  directives: { mask: VueMaskDirective },
  mixins: [validationMixin],
  props: {
    translations: {
      type: Object as PropType<OrderSmsSubscriptionTranslations>,
      required: true,
    },
    validations: {
      type: Object as PropType<
        Pick<
          FormValidationConfig,
          'phoneNumberRegex' | 'phoneNumberFormat' | 'phoneNumberMask'
        >
      >,
      required: true,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const userStore = useUserStore(root);
    const { loggedIn } = storeToRefs(userStore);
    const {
      getBasicInformation,
      basicInformation,
      setSmsSubscription,
      setGuestSmsSubscription,
      smsSubscription,
    } = useAccount(root);
    const { displayErrorMessages } = errorMessages(root);
    const { setValidation, $v } = useValidation(root, 'ORDER_SMS_SUBSCRIPTION');
    const { isSmsPreferencesOrderConfirmationEnabled } = useFeatureFlagsStore();

    const { hideOnFullySubscribed } = root.$themeConfig.orderSmsSubscription;

    const phone = ref('');
    const areTermsConditionsAccepted = ref(false);
    const hasSubmittedPhoneNumber = ref(false);
    const isProcessing = ref(false);

    const isSubscribed = computed(
      () => smsSubscription.value?.optin || smsSubscription.value?.doubleOptin
    );

    const hideSmsComponent = computed(() => {
      return (
        (hideOnFullySubscribed && smsSubscription.value?.doubleOptin) ||
        !isSmsPreferencesOrderConfirmationEnabled
      );
    });

    const hideSmsForm = computed(() => {
      return hasSubmittedPhoneNumber.value || isSubscribed.value;
    });

    const showPreferencesText = computed(() => {
      return (
        (hasSubmittedPhoneNumber.value || isSubscribed.value) && loggedIn.value
      );
    });

    const subtitle = computed(() => {
      const { subtitle } = props.translations;

      if (hasSubmittedPhoneNumber.value) {
        return subtitle.optedIn;
      } else if (isSubscribed.value) {
        return subtitle.confirmed;
      } else {
        return subtitle.unsubscribed;
      }
    });

    const subscribe = async () => {
      $v.value.phone.$touch();
      $v.value.areTermsConditionsAccepted.$touch();

      if ($v.value.phone.$invalid || !areTermsConditionsAccepted.value) {
        return;
      }

      isProcessing.value = true;

      const sendPhoneNumber = loggedIn.value
        ? setSmsSubscription
        : setGuestSmsSubscription;

      try {
        await sendPhoneNumber(
          stripPhoneAndPrependUSCAAreaCode(phone.value),
          true
        );

        hasSubmittedPhoneNumber.value = true;

        if (loggedIn.value) {
          await getBasicInformation();
        }
      } catch (error) {
        displayErrorMessages(error);
      } finally {
        isProcessing.value = false;
      }
    };

    onMounted(async () => {
      if (isSmsPreferencesOrderConfirmationEnabled && loggedIn.value) {
        await getBasicInformation();
      }
    });

    return {
      phone,
      isSubscribed,
      hasSubmittedPhoneNumber,
      hideSmsForm,
      showPreferencesText,
      isProcessing,
      areTermsConditionsAccepted,
      setValidation,
      subtitle,
      subscribe,
      loggedIn,
      hideSmsComponent,
      basicInformation,
    };
  },
  mounted() {
    this.setValidation(this.$v);
  },
  validations: {
    phone: {
      required,
      checkPhone: (phone, vm) =>
        checkPhone(
          phone,
          vm.basicInformation.preferences.countryCode || undefined
        ),
    },
    areTermsConditionsAccepted: {
      required,
      sameAs: (val) => val === true,
    },
  },
});
