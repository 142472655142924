var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._b({staticClass:"article-tile",class:[
    ("article-tile--" + (_vm.textAlign.small) + "-sm"),
    ("article-tile--" + (_vm.textAlign.medium) + "-md"),
    ("article-tile--" + (_vm.textAlign.large) + "-lg"),
    _vm.styles.teaserClass,
    { 'article-tile--redesign-core': _vm.isCoreRedesignEnabled } ],style:([_vm.maxNumberOfTextLinesVariables]),attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id'] || _vm.$attrs['data-id'])}},'div',_vm.$attrs,false),[(_vm.image && _vm.image.small)?_c('UiLink',{staticClass:"article-tile--teaser-tile",class:{
      'article-tile--square-images': _vm.squareImages,
      'article-tile--pointer-none': !_vm.link && !_vm.imageLink,
    },attrs:{"to":_vm.to,"target":_vm.linkTargetAttribute,"open-in-new-modal":_vm.openInNewModal,"data-navigation":_vm.dataNavigation,"data-dom-location":_vm.dataDomLocation,"data-cm-metadata":_vm.$previewMetaDataLocation(
        _vm.$attrs['data-id'] || _vm.$attrs['data-id'],
        'teaserTargets'
      ),"tag":"div"},on:{"!click":function($event){return _vm.$emit('click-anchor', { event: $event, link: _vm.to })}}},[_c('VfPicture',{attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(
          _vm.$attrs['data-id'] || _vm.$attrs['data-id'],
          'pictures[0]'
        ),"src":_vm.image,"width":_vm.imageWidth,"height":_vm.imageHeight,"alt":_vm.alt,"seo":_vm.seo}})],1):_vm._e(),_vm._v(" "),(_vm.isVideo)?_c('UiLink',{attrs:{"tag":"div","to":_vm.to,"open-in-new-tab":_vm.openInNewTab,"open-in-new-modal":_vm.openInNewModal,"data-navigation":_vm.dataNavigation,"data-dom-location":_vm.dataDomLocation,"data-cm-metadata":_vm.$previewMetaDataLocation(
        _vm.$attrs['data-id'] || _vm.$attrs['data-id'],
        'teaserTargets'
      )}},[_c('VfVideo',{attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(
          _vm.$attrs['data-id'] || _vm.$attrs['data-id'],
          'videos'
        ),"video":_vm.video,"heading":_vm.text,"lazy-load":_vm.lazyLoadVideo},on:{"video-event":function($event){return _vm.$emit('video-event', $event)}}})],1):_vm._e(),_vm._v(" "),(_vm.tag && _vm.showTagBelowImage)?_c('VfText',{staticClass:"article-tile__tag",attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id'] || _vm.$attrs['data-id'], 'tag'),"modifier":"article-tag-rounded","content":_vm.tag}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"article-tile__content",class:{
      'article-tile__content--hidden-sm': !_vm.showOverlay.small,
      'article-tile__content--hidden-md': !_vm.showOverlay.medium,
      'article-tile__content--hidden-lg': !_vm.showOverlay.large,
    },style:([_vm.contentPositionSettingsStyles, _vm.contentWidthStyles])},[(_vm.title)?_c('UiLink',{staticClass:"article-tile__heading",class:{
        'article-tile__heading--redesign-core': _vm.isCoreRedesignEnabled,
      },attrs:{"tag":"div","data-cm-metadata":_vm.$previewMetaDataLocation(
          _vm.$attrs['data-id'] || _vm.$attrs['data-id'],
          'teaserTargets'
        ),"to":_vm.to,"target":_vm.linkTargetAttribute,"open-in-new-modal":_vm.openInNewModal,"data-navigation":_vm.dataNavigation,"data-dom-location":_vm.dataDomLocation}},[_c('VfHeading',{class:[_vm.styles.titleClassModifiers, _vm.styles.titleFontFamily],attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(
            _vm.$attrs['data-id'] || _vm.$attrs['data-id'],
            'teaserTitle'
          ),"styles":_vm.styles,"title":_vm.title,"text-align":_vm.textAlign,"level":_vm.headingLevel,"title-modifier":_vm.styles.titleHeadingStyle || 'title-3',"subtitle-modifier":_vm.styles.subtitleHeadingStyle,"subtitle":_vm.subtitle}})],1):_vm._e(),_vm._v(" "),(_vm.dataDate)?_c('VfDate',{staticClass:"vf-order-card-sidebar__date vf-date-article",attrs:{"date":_vm.dataDate,"format":"intl-long"}}):_vm._e(),_vm._v(" "),(_vm.text)?_c('VfText',{staticClass:"article-tile__text",class:[_vm.styles.textClassModifiers, _vm.textClamp],attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(
          _vm.$attrs['data-id'] || _vm.$attrs['data-id'],
          'teaserRichTextObject'
        ),"background-color":_vm.styles.textBackgroundColor,"text-color":_vm.styles.textColor,"content":_vm.text,"text-align":_vm.textAlign,"html":_vm.useRichText,"font-sizes":['base']}}):_vm._e(),_vm._v(" "),(_vm.tag && !_vm.showTagBelowImage)?_c('VfText',{staticClass:"article-tile__tag",attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(
          _vm.$attrs['data-id'] || _vm.$attrs['data-id'],
          'tag'
        ),"modifier":"article-tag","content":_vm.tag}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"article-tile__action-buttons",class:[
        ("article-tile__action-buttons--" + (_vm.textAlign.small) + "-sm"),
        ("article-tile__action-buttons--" + (_vm.textAlign.medium) + "-md"),
        ("article-tile__action-buttons--" + (_vm.textAlign.large) + "-lg"),
        {
          'article-tile__action-buttons--text-button':
            _vm.buttonStyle === 'text' && !_vm.ctaButtons.length,
        } ]},[_c('VfButton',{directives:[{name:"show",rawName:"v-show",value:(!!_vm.buttonText),expression:"!!buttonText"}],staticClass:"article-tile__button",class:( _obj = {}, _obj[("vf-button--" + _vm.buttonStyle)] = _vm.buttonStyle, _obj[("vf-button--" + _vm.buttonSize)] = _vm.buttonSize, _obj[("vf-button--icon-" + _vm.buttonIconPosition)] = _vm.buttonIconPosition, _obj['vf-button--underline'] =  _vm.buttonUnderline, _obj['vf-button--no-underline'] =  !_vm.buttonUnderline, _obj ),attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(
            _vm.$attrs['data-id'] || _vm.$attrs['data-id'],
            'teaserTargets'
          ),"link":_vm.link,"icon":_vm.customButtonIcon,"icon-size":_vm.customButtonIconSize,"open-in-new-tab":_vm.openInNewTab,"open-in-new-modal":_vm.openInNewModal,"data-navigation":_vm.dataNavigation,"data-dom-location":_vm.dataDomLocation},on:{"click":_vm.callButtonActions}},[_vm._v("\n        "+_vm._s(_vm.buttonText)+"\n      ")]),_vm._v(" "),_vm._l((_vm.ctaButtons),function(button,index){
          var _obj;
return _c('VfButton',{directives:[{name:"show",rawName:"v-show",value:(!!button.buttonText),expression:"!!button.buttonText"}],key:index,class:[
          'article-tile__button',
          ("article-tile__button--" + (_vm.textAlign.small) + "-sm"),
          ("article-tile__button--" + (_vm.textAlign.medium) + "-md"),
          ("article-tile__button--" + (_vm.textAlign.large) + "-lg"),
          ( _obj = {}, _obj[("vf-button--" + (button.buttonStyle))] = button.buttonStyle, _obj[("vf-button--" + (button.buttonSize))] = button.buttonSize, _obj[("vf-button--icon-" + (button.buttonIconPosition))] = button.buttonIconPosition, _obj['vf-button--underline'] =  button.buttonUnderline, _obj['vf-button--no-underline'] =  !button.buttonUnderline, _obj ) ],attrs:{"data-navigation":_vm.dataNavigation,"data-dom-location":_vm.dataDomLocation,"open-in-new-tab":_vm.openInNewTab,"open-in-new-modal":_vm.openInNewModal,"icon":button.buttonIcon === 'no-icon' ? '' : button.buttonIcon,"icon-size":_vm.customButtonIconSize,"link":button.buttonLink,"data-cm-metadata":_vm.$previewMetaDataLocation(
            _vm.$attrs['data-id'] || _vm.$attrs['data-id'],
            'teaserTargets'
          )},on:{"click":function($event){return _vm.emitCustomModal(button.buttonTargetComponent)}}},[_vm._v("\n        "+_vm._s(button.buttonText)+"\n      ")])})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }