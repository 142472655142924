//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  watch,
  reactive,
  defineComponent,
  computed,
  ref,
} from '@vue/composition-api';
import useRootInstance from '@/shared/useRootInstance';
import { useI18n } from '@vf/composables';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

export default defineComponent({
  name: 'PriceRangeInput',
  props: {
    translations: {
      type: Object,
      default: () => ({
        maxPriceInputLabel: 'Max',
        maxPriceRangeLabel: 'Maximum Price Range Slider',
        maximumDisplayPrice: 300,
        minPriceInputLabel: 'Min',
        minPriceRangeLabel: 'Minimum Price Range Slider',
      }),
    },
    selectedFilter: {
      type: Object,
      default: null,
    },
    rangeFilter: {
      type: Function,
      required: true,
    },
    code: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { currencySymbol: currency } = useI18n(root);
    // TODO: isCoreRedesignEnabled - GLOBAL15-61059 remove after core redesign
    const { isCoreRedesignEnabled } = useFeatureFlagsStore();

    const getPreviouslySelectedValues = () => {
      if (!props.selectedFilter) {
        return { min: false, max: false };
      }

      const parts = props.selectedFilter.value.slice(1, -1).split(' ');
      const min = parts[0];
      const max = parts[2] === '*' ? false : parts[2];

      return {
        min,
        max,
      };
    };

    let selected = getPreviouslySelectedValues();

    const limits = {
      min: 0,
      max: props.translations.maximumDisplayPrice,
    };

    const range = reactive({
      min: selected.min || limits.min,
      max: selected.max || limits.max,
    });

    watch(
      () => range.min,
      (oldMin, newMin) => {
        if (oldMin === newMin) return;

        range.min = parseInt(range.min) || 0;
        range.max = parseInt(range.max) || 0;

        if (range.min > limits.max) range.min = limits.max;
        if (range.min < limits.min) range.min = limits.min;
        if (range.min > range.max) range.max = range.min;
      }
    );

    watch(
      () => range.max,
      (oldMax, newMax) => {
        if (oldMax === newMax) return;

        range.min = parseInt(range.min) || 0;
        range.max = parseInt(range.max) || 0;

        if (range.max > limits.max) range.max = limits.max;
        if (range.max < limits.min) range.max = limits.min + 1;
        if (range.max < range.min) range.min = range.max;
      }
    );

    const track = computed(() => ({
      left: `${((range.min - limits.min) / (limits.max - limits.min)) * 100}%`,
      right: `${((range.max - limits.min) / (limits.max - limits.min)) * 100}%`,
    }));

    watch(
      () => props.selectedFilter,
      () => {
        selected = getPreviouslySelectedValues();
        range.min = selected.min || limits.min;
        range.max = selected.max || limits.max;
      }
    );

    let timeout;
    const focusedRange = ref(null);
    const setFocusedRange = (e) => (focusedRange.value = e.target.name);
    const isInputDisabled = computed(() => !root.$viewport.isSmall);

    const filter = () => {
      clearTimeout(timeout);

      if (focusedRange.value) return;

      timeout = setTimeout(
        () => props.rangeFilter(range, props.code),
        root.$viewport.isSmall ? 0 : 3000
      );
    };

    watch(focusedRange, filter);

    const handleRangeStart = (e) => {
      focusedRange.value = e.target.name;
    };

    const handleRangeEnd = () => {
      focusedRange.value = null;
    };

    const focusedInput = ref(null);

    const handleInputStart = (e) => {
      focusedInput.value = e.target.name;
    };

    const handleInputEnd = (e) => {
      focusedInput.value = null;
      range[e.target.name] = e.target.value;
      filter();
    };

    return {
      range,
      limits,
      filter,
      track,
      isInputDisabled,
      focusedRange,
      setFocusedRange,
      handleRangeStart,
      handleRangeEnd,
      isCoreRedesignEnabled,
      focusedInput,
      handleInputStart,
      handleInputEnd,
      currency,
    };
  },
});
