var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"grid",class:{
    'vf-custom-grid-top-right': true,
    'vf-custom-grid-top-right--full-height-small': _vm.fullHeightSmall,
  },style:(_vm.backgroundStyle)},[(_vm.smallBackgroundVideo)?_c('VfVideo',{staticClass:"vf-custom-grid-top-right__video-background",attrs:{"video":{ small: _vm.smallBackgroundVideo, medium: null, large: null }},on:{"video-event":function($event){return _vm.dispatchVideoEvent($event)}}}):_vm._e(),_vm._v(" "),_c('div',{ref:"content",staticClass:"vf-custom-grid-top-right__content"},[_c('VfImage',{staticClass:"vf-custom-grid-top-right__logo",attrs:{"alt":_vm.logo.alt,"src":_vm.logo.src,"height":_vm.logo.height,"width":_vm.logo.width}}),_vm._v(" "),_c('VfText',{staticClass:"vf-custom-grid-top-right__rich-text",class:_vm.richTextBackgroundColor === 'transparent'
          ? ''
          : 'vf-text--custom-highlighted',style:({
        '--vf-text-custom-highlighted-background': _vm.richTextBackgroundColor,
      }),attrs:{"html":"","content":_vm.richText,"text-color":_vm.richTextColor}}),_vm._v(" "),_c('div',{staticClass:"vf-custom-grid-top-right__buttons"},[(_vm.customCTAS.length)?_vm._l((_vm.customCTAS),function(button,index){return _c('CustomsCta',{key:((button.text) + "-" + index),class:_vm.buttonSize,attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id']),"data-object":button.dataObject,"button-style":button.style,"icon":button.icon,"underline":button.underline,"data-model":button.dataObject,"experience":button.experience,"experience-type":button.experienceType,"button-text":button.text,"gtm-event-label":button.text},on:{"btnClick":_vm.logEvent}})}):_vm._e(),_vm._v(" "),(_vm.buttonsCTA.length)?_vm._l((_vm.buttonsCTA),function(cta){return _c('ThemeButton',{key:cta.scrollToButtonId,staticClass:"cta-button",attrs:{"id":cta.scrollToButtonId,"color":"primary","size":"md"},on:{"click":function($event){_vm.scrollToElement(cta.linkToScroll);
            _vm.logEvent(cta.text);}}},[_vm._v("\n          "+_vm._s(cta.text)+"\n          "),_c('VfIcon',{attrs:{"icon":cta.icon,"size":"1rem"}})],1)}):_vm._e(),_vm._v(" "),(_vm.customContentInfo.length)?_vm._l((_vm.customContentInfo),function(singleCustomContent,index){return _c('CustomContent',{key:((singleCustomContent.customContentFile) + "-" + index),attrs:{"custom-content-file":singleCustomContent.customContentFile,"custom-content-path":singleCustomContent.customContentPath}})}):_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"vf-custom-grid-top-right__custom-link"},[_c('VfText',{staticClass:"vf-text",attrs:{"text-color":_vm.textBelowCtaButtonsColor,"content":_vm.textBelowCtaButton}}),_vm._v(" "),_c('CustomsCta',{attrs:{"button-text":_vm.clickHereText,"button-style":"text","styles":{
          textColor: _vm.textBelowCtaButtonsColor,
          fontFamily: 'urw-franklin-gothic-font-family',
        },"data-object":_vm.secondDataObject,"experience":_vm.secondExperience,"experience-type":_vm.secondExperienceType,"gtm-event-label":_vm.clickHereGtmEventLabel,"underline":""},on:{"btnClick":_vm.logEvent}})],1),_vm._v(" "),(_vm.bottomInspirationLink.link)?_c('UiLink',{staticClass:"scroll-down-btn",on:{"click":function($event){_vm.scrollToElement(_vm.bottomInspirationLink.link);
        _vm.logEvent(_vm.bottomInspirationLinkGtmEventLabel);}}},[_vm._v("\n      "+_vm._s(_vm.bottomInspirationLink.text)+"\n      "),_c('VfIcon',{attrs:{"icon":"chevron_down","size":"1rem"}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }