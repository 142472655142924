



























































































































































































































import type { PropType } from 'vue';
import type { SignInToStoreTranslations } from '@vf/api-contract';

import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';

import { onBeforeMount, defineComponent, computed } from '@vue/composition-api';
import {
  useI18n,
  useRouting,
  useSignInToStore,
  ROUTES,
  useCart,
  useNotification,
} from '@vf/composables';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import useRootInstance from '@/shared/useRootInstance';

type GetStoreEmployeesPayload = {
  storeId: string;
};

export default defineComponent({
  name: 'SignInToStore',
  mixins: [validationMixin],
  props: {
    translations: {
      type: Object as PropType<SignInToStoreTranslations>,
      default: () => ({}),
    },
  },
  setup() {
    const { root } = useRootInstance();

    const { previousRoute } = useRouting(root);
    const { initCart } = useCart(root);
    const { localePath } = useI18n(root);
    const {
      stores,
      storeEmployees,
      signIn,
      isSigningIn: buttonDisabled,
      fetchStores,
      fetchStoreEmployees,
      fetchingStores,
      fetchingStoreEmployees,
      errorData,
    } = useSignInToStore(root);
    const { addNotification, clearNotifications } = useNotification(root);

    // TODO: remove after GLOBAL15-56318 checkout redesign
    const { isCheckoutRedesignEnabled } = useFeatureFlagsStore();

    const loading = computed(
      () => fetchingStores.value && fetchingStoreEmployees.value
    );

    const validStores = computed(() => {
      return stores.value.filter((store) => store.id);
    });

    const validEmployees = computed(() => {
      return storeEmployees.value.filter(
        (employee) => employee.id && employee.name
      );
    });

    const getStoreEmployees = async (data: GetStoreEmployeesPayload) => {
      await fetchStoreEmployees(data.storeId);
    };

    const handleSignIn = async (data) => {
      clearNotifications();
      const result = await signIn(data);
      if (!result) {
        return addNotification({
          message: getErrorMessage(errorData.value),
          type: 'danger',
        });
      }
      await initCart({
        storeId: data.storeId,
        employeeId: data.employeeId,
        login: data.userId,
      });
      root.$router.push({
        path: previousRoute.value?.fullPath ?? localePath(ROUTES.HOME()),
        query: {
          ...root.$route.query,
          utm_source: 'pos',
          utm_medium: 'instore',
          utm_campaign: `store-${data.storeId}`,
        },
      });
    };

    const getErrorMessage = (error) => {
      if (navigator.onLine) {
        return [400, 401].includes(error.response?.status)
          ? (root.$t('signInToStore.wrongCredentialsInfo') as string)
          : (root.$t('signInToStore.generalErrorMessage') as string);
      }
      return root.$t('signInToStore.networkErrorMessage') as string;
    };

    onBeforeMount(async () => await fetchStores());

    return {
      validStores,
      validEmployees,
      loading,
      buttonDisabled,
      handleSignIn,
      stores,
      storeEmployees,
      errorData,
      getStoreEmployees,
      getErrorMessage,
      isCheckoutRedesignEnabled,
    };
  },
  data() {
    return {
      userId: '',
      password: '',
      storeId: '',
      employeeId: '',
    };
  },
  methods: {
    signIn(data) {
      this.$v.$touch();
      if (this.buttonDisabled || this.$v.$invalid) return;
      this.handleSignIn(data);
    },
    async handleStoreChange() {
      this.$v.employeeId.$reset();
      await this.getStoreEmployees({ storeId: this.storeId });
      this.employeeId = '';
    },
  },
  validations: {
    userId: {
      required,
      email: (eml) => email(eml ? eml.toLowerCase() : eml),
    },
    password: {
      required,
    },
    storeId: {
      required,
    },
    employeeId: {
      required,
    },
  },
});
