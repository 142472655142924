













import type { PropType } from 'vue';
import { defineComponent } from '@vue/composition-api';
import type { HeadingTeaserStyles } from '@vf/api-contract';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';

export default defineComponent({
  name: 'Heading',
  serverCacheKey: getCacheKeyFromProps,
  props: {
    /** Header level 1-6 */
    level: {
      type: [String, Number],
      default: '2',
    },
    /** Class modifier for title styling TBD */
    titleModifier: {
      type: [String],
      default: '',
    },
    /** Class modifier for title styling TBD */
    subtitleModifier: {
      type: [String],
      default: '',
    },
    /** Title text */
    title: {
      type: String,
      default: '',
    },
    /** Subtitle text */
    subtitle: {
      type: String,
      default: '',
    },
    /** Heading Teaser Styles Customizations */
    styles: {
      type: Object as PropType<HeadingTeaserStyles>,
      default: () => ({}),
    },
  },
  setup(props, context) {
    const id = context.attrs;
    return {
      id,
    };
  },
});
