







































import type { PropType } from 'vue';
import { computed, defineComponent } from '@vue/composition-api';
import type {
  CategoryTeaserItem,
  ContentAlignProperties,
} from '@vf/api-contract';
import { AnalyticsDataDomLocation } from '@/types';
import useRootInstance from '@/shared/useRootInstance';
import useModal from '@/shared/useModal';
import { extractImageSizes } from '@/helpers/extractImageSizes';

const defaultProps = (value: boolean) => ({
  small: value,
  medium: value,
  large: value,
});

export default defineComponent({
  name: 'ImageWithOverlay',
  props: {
    category: {
      type: Object as PropType<CategoryTeaserItem>,
      default: () => ({}),
    },
    /** Flag to determine if overlay should be visible */
    showOverlay: {
      type: Object,
      default: () => defaultProps(true),
    },
    /** Flag to determine if overlay should be transparent */
    transparentOverlay: {
      type: Object,
      default: () => defaultProps(false),
    },
    /** Flag to determine if overlay should be visible */
    alwaysShowOverlay: {
      type: Object,
      default: () => defaultProps(false),
    },
    /** Flag to determine if content should be displayed below banner image */
    showBelowArticle: {
      type: Object,
      default: () => defaultProps(false),
    },
    /** Flag to determine if just the buttons should be displayed below banner image */
    showButtonsBelowArticle: {
      type: Object,
      default: () => defaultProps(false),
    },
    overlaySettingsSmall: {
      type: Object,
      default: () => ({
        hOffset: null,
        vOffset: null,
        width: '',
      }),
    },
    overlaySettingsMedium: {
      type: Object,
      default: () => ({
        hOffset: null,
        vOffset: null,
        width: '',
      }),
    },
    overlaySettingsLarge: {
      type: Object,
      default: () => ({
        hOffset: null,
        vOffset: null,
        width: '',
      }),
    },
    openInNewTab: {
      type: Boolean,
      default: false,
    },
    openInNewModal: {
      type: Boolean,
      default: false,
    },
    firstButtonStyles: {
      type: Object,
      default: () => ({
        buttonStyleSmall: 'text',
        buttonStyleLarge: 'primary',
        buttonSize: '',
        buttonIcon: '',
        buttontargetComponent: '',
      }),
    },
    contentPosition: {
      type: Object as PropType<ContentAlignProperties>,
      default: () => ({
        small: 'middle_center',
        medium: 'middle_center',
        large: 'middle_center',
      }),
    },
    textAlign: {
      type: Object as PropType<ContentAlignProperties>,
      default: () => ({
        small: 'left',
        medium: 'center',
        large: 'center',
      }),
    },
    /** (automatic) Data extracted from specified target for analytics purposes */
    dataNavigation: {
      type: String,
      default: null,
    },
    /** CTA buttons */
    ctaButtons: {
      type: Array,
      default: () => [
        {
          buttonText: '',
          buttonStyle: '',
          buttonSize: '',
          buttonIcon: '',
          buttonIconPosition: '',
          buttonLink: '',
          buttonUnderline: '',
          buttonTargetComponent: '',
        },
      ],
    },
    urlParams: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { openModal, getModalSettingsFromLink, setModalProps } = useModal();
    // Determines if is displayed as Article IF showBelowArticle is set OR isSmall/isMobileDevice and not alwaysShowOverlay is set
    const visibilityClasses = computed(() => {
      const teaserTileClasses = [];
      const imageTileClasses = [];

      for (let breakpoint in props.showBelowArticle) {
        props.showBelowArticle[breakpoint]
          ? imageTileClasses.push(`hide-on-${breakpoint}`)
          : teaserTileClasses.push(`hide-on-${breakpoint}`);
      }

      return {
        teaserTile: teaserTileClasses.join(' '),
        imageTile: imageTileClasses.join(' '),
      };
    });

    const imageSize = computed(() => {
      return {
        width: extractImageSizes(props.category?.image, 'width'),
        height: extractImageSizes(props.category?.image, 'height'),
      };
    });

    const onClickAnchor = ({
      event,
      link,
    }: {
      event: MouseEvent;
      link?: string;
    }) => {
      if (link?.startsWith('[data-id')) {
        if (!props.openInNewModal) {
          return;
        }
        event.preventDefault();
        openModal(getModalSettingsFromLink(link));
      }
    };

    const addCustomStylesInVideoModal = () => {
      setModalProps({ class: 'custom-video-modal' });
    };

    const buttonAttributes = computed(() =>
      props.openInNewTab ? '_blank' : ''
    );

    const tileProps = computed(() => ({
      image: props.category.image,
      title: props.category.title,
      subtitle: props.category.subtitle,
      text: props.category.image.detailRichText || props.category.text,
      useRichText: props.category.useRichText,
      textAlign: props.textAlign,
      buttonSize: props.firstButtonStyles.buttonSize,
      buttonIcon: props.firstButtonStyles.buttonIcon,
      buttonIconPosition: props.firstButtonStyles.buttonIconPosition,
      link: props.category.link
        ? `${props.category.link}${props.urlParams}`
        : '',
      imageLink: `${props.category.imageLink}${props.urlParams}`,
      dataNavigation: props.dataNavigation,
      showOverlay: props.showOverlay,
      showButtonsBelowImage:
        props.showButtonsBelowArticle[root.$viewport?.size || 'small'],
      overlaySettingsSmall: props.overlaySettingsSmall,
      overlaySettingsMedium: props.overlaySettingsMedium,
      overlaySettingsLarge: props.overlaySettingsLarge,
      dataDomLocation: AnalyticsDataDomLocation.FeaturedImageWithOverlay,
      imageWidth: imageSize.value.width,
      imageHeight: imageSize.value.height,
    }));
    return {
      buttonAttributes,
      visibilityClasses,
      tileProps,
      imageSize,
      onClickAnchor,
      addCustomStylesInVideoModal,
    };
  },
  computed: {
    actionsPlace() {
      return `action-buttons${
        this.showButtonsBelowArticle[this.$root.$viewport?.size || 'small']
          ? '-bottom'
          : ''
      }`;
    },
  },
});
