









































































































import { PropType } from 'vue';
import { VueMaskDirective } from 'v-mask';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import {
  FormValidationConfig,
  ProfileSmsSubscriptionTranslations,
} from '@vf/api-contract';
import {
  defineComponent,
  ref,
  computed,
  onMounted,
  watch,
} from '@vue/composition-api';
import useRootInstance from '@/shared/useRootInstance';
import { useAccount, useNotification, useValidation } from '@vf/composables';
import {
  checkPhone,
  maskUSCAPhone,
  stripPhone,
  stripPhoneAndPrependUSCAAreaCode,
} from '@vf/shared/src/utils/helpers';
import { errorMessages } from '@vf/composables/src/utils/errorMessages';
import { CountryCode } from 'libphonenumber-js/min';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

export default defineComponent({
  name: 'ProfileSmsSubscription',
  directives: { mask: VueMaskDirective },
  mixins: [validationMixin],
  props: {
    translations: {
      type: Object as PropType<ProfileSmsSubscriptionTranslations>,
      required: true,
    },
    validationConfig: {
      type: Object as PropType<
        Pick<
          FormValidationConfig,
          'phoneNumberRegex' | 'phoneNumberFormat' | 'phoneNumberMask'
        >
      >,
      required: true,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const {
      getBasicInformation,
      basicInformation,
      setSmsSubscription,
      smsSubscription,
    } = useAccount(root);
    const {
      addNotification,
      clearNotifications,
      setChangePasswordNotification,
    } = useNotification(root);
    const { displayErrorMessages } = errorMessages(root);
    const {
      isSmsPreferencesMyAccountEnabled,
      isCoreRedesignEnabled,
    } = useFeatureFlagsStore();
    const { setValidation, $v } = useValidation(
      root,
      'PROFILE_SMS_SUBSCRIPTION'
    );

    const theme = {
      ...root.$themeConfig.profileSmsSubscription,
      ...(isCoreRedesignEnabled && {
        buttonsVariant: 'primary',
      }),
    };

    const isProcessing = ref(false);
    const phone = ref('');

    const isFullySubscribed = computed(
      () => smsSubscription.value?.doubleOptin
    );
    const isOptIn = computed(() => smsSubscription.value?.optin);
    const areTermsConditionsAccepted = ref(false);
    const subscriptionPhoneNumber = computed(
      () => smsSubscription.value?.recipient
    );

    const wrapRequest = async (request) => {
      setChangePasswordNotification(false);
      clearNotifications();

      isProcessing.value = true;
      try {
        await request(getBasicInformation);
      } catch (error) {
        displayErrorMessages(error);
      } finally {
        isProcessing.value = false;
      }
    };

    const subscribe = async () => {
      $v.value.phone.$touch();
      $v.value.areTermsConditionsAccepted.$touch();

      if ($v.value.phone.$invalid || !areTermsConditionsAccepted.value) {
        return;
      }

      await wrapRequest(async (callback) => {
        if (smsSubscription.value?.optin && subscriptionPhoneNumber.value) {
          await setSmsSubscription(
            stripPhoneAndPrependUSCAAreaCode(subscriptionPhoneNumber.value),
            false
          );
        }

        await setSmsSubscription(
          stripPhoneAndPrependUSCAAreaCode(phone.value),
          true
        );

        await callback();

        if (!isFullySubscribed.value) {
          addNotification({
            message: props.translations.confirmationTooltip,
            type: 'info',
          });
        }
      });
    };

    const unsubscribe = async () => {
      await wrapRequest(async (callback) => {
        await setSmsSubscription(
          stripPhoneAndPrependUSCAAreaCode(subscriptionPhoneNumber.value),
          false
        );

        await callback();

        addNotification({
          message: props.translations.notifications.unsubscribe,
          type: 'success',
        });
      });
    };

    const subtitleContent = computed(() => {
      if (isOptIn.value && !isFullySubscribed.value) {
        return;
      }
      return isOptIn.value
        ? props.translations.subtitle.subscribed
        : props.translations.unsubscribed;
    });

    onMounted(async () => {
      if (isSmsPreferencesMyAccountEnabled) {
        await getBasicInformation();
      }
    });

    watch(
      smsSubscription,
      (subscription) => {
        if (
          !subscription ||
          (!subscription.optin && !subscription.doubleOptin)
        ) {
          phone.value = '';
          areTermsConditionsAccepted.value = false;
          $v.value?.$reset();
          return;
        }
        phone.value = maskUSCAPhone(stripPhone(subscription.recipient));
        areTermsConditionsAccepted.value =
          subscription.optin || subscription.doubleOptin;
      },
      {
        immediate: true,
      }
    );

    watch(
      basicInformation,
      (value) => {
        if (
          value &&
          !phone.value &&
          subscriptionPhoneNumber.value &&
          isFullySubscribed.value
        ) {
          phone.value = stripPhone(subscriptionPhoneNumber.value);
          $v.value?.phone.$touch();
        }
      },
      {
        immediate: true,
      }
    );

    const noPhoneChange = computed(
      () =>
        phone.value?.replace(/-/g, '') ===
        stripPhone(subscriptionPhoneNumber.value)
    );

    return {
      theme,
      phone,
      noPhoneChange,
      isFullySubscribed,
      isOptIn,
      isProcessing,
      basicInformation,
      areTermsConditionsAccepted,
      setValidation,
      subtitleContent,
      subscribe,
      unsubscribe,
      isSmsPreferencesMyAccountEnabled,
      isCoreRedesignEnabled,
    };
  },
  mounted() {
    this.setValidation(this.$v);
  },
  validations: {
    phone: {
      required,
      checkPhone: (phone, vm) =>
        checkPhone(
          phone,
          vm.basicInformation.preferences.countryCode as CountryCode
        ),
    },
    areTermsConditionsAccepted: {
      required,
      sameAs: (val) => val === true,
    },
  },
});
