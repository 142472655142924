










































































































































































































































































































































































































































































import type { PropType } from 'vue';
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from '@vue/composition-api';
import {
  useSearch,
  useShippingFilter,
  useCategory,
  useRouting,
  useGridConfig,
  useFilters,
} from '@vf/composables';
import { isClient } from '@vf/shared/src/utils/helpers';
import { scrollTo as scrollToTop } from '@vf/shared/src/utils/helpers';
import { getFilterType } from '@vf/shared/src/utils/helpers/facetDisplayType';
import * as urlDecoder from '@vf/composables/src/utils/urlDecoder';
import Filters from '@/components/Filters.vue';
import { useCmsRefStore } from '@vf/composables/src/store/cmsRef';
import type { CategoryFiltersTranslations } from '@vf/api-contract';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import useRootInstance from '@/shared/useRootInstance';
import throttle from '@vf/shared/src/utils/helpers/throttle';

const watchIsSidebarOpen = (
  isSidebarOpen,
  hasSelectedFilterByCode,
  appliedFilters
) => {
  if (isSidebarOpen) {
    // check if has Selected filter only when sidebar is open
    hasSelectedFilterByCode.value = {};
    appliedFilters.value.forEach((filter) => {
      hasSelectedFilterByCode.value[filter.code] = true;
    });
  }
};

export default defineComponent({
  name: 'CategoryFiltersPanel',
  components: {
    Filters,
    VfCollapsibleChips: () =>
      import('@vf/ui/components/Atom.CollapsibleChips.vue'),
    VfShippingFilter: () =>
      import('@/components/static/plp/ShippingFilter.vue'),
  },
  props: {
    translations: {
      type: Object as PropType<CategoryFiltersTranslations>,
      required: true,
    },
    /** Prop to decide whether accordion should be opened or closed on initial load, can be different for small, medium and large breakpoints  */
    accordionsOpen: {
      type: Object,
      default: () => ({ small: true, medium: true, large: true }),
    },
    /** Flag to define if filters panel is sticky or not  */
    sticky: {
      type: Boolean,
      default: true,
    },
    /** Custom order of facets */
    facetsOrder: {
      type: Array as PropType<{ id: string }[]>,
      default: () => [],
    },
    /** List of expanded by default accordions (on mobile) */
    expandedAccordions: {
      type: Array,
      default: () => [],
    },
    /** Display variant for size filters (text|chips) */
    sizeFiltersVariant: {
      type: String,
      default: 'text',
      validator: (value: string) => {
        return ['text', 'chips'].includes(value);
      },
    },
    overridenCategories: {
      type: Object,
      default: () => ({
        chips: [],
        checkboxes: [],
      }),
    },
    defaultSort: {
      type: String,
      default: '',
    },
    contextKey: {
      type: String,
      default: '',
    },
    isCategory: {
      type: Boolean,
      default: true,
    },
    /** How many facets to show in accordion on small screens */
    filterNumberOfFacetsToShowSmall: {
      type: Number,
      default: 3,
    },
    /** How many facets to show in accordion on medium screens */
    filterNumberOfFacetsToShowMedium: {
      type: Number,
      default: 7,
    },
    /** How many facets to show in accordion on large screens */
    filterNumberOfFacetsToShowLarge: {
      type: Number,
      default: 7,
    },
    /** How many facets to show in accordion */
    filterNumberOfFacetsToShow: {
      type: Number,
      default: null,
    },
    /** Flag to define if items count should be displayed next to filter item or not  */
    displayCount: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { isFiltersUiUpdateEnabled } = useFeatureFlagsStore();
    const {
      title,
      pageMetaTitle,
      customPageDescription,
      queryString,
      getMetaDescription,
      sortedFilters,
      updateCatalog,
      categoryId,
      pagination,
      selectedFilters,
      resetFilters,
      removeFilter,
      isFilterSidebarOpen,
      setFilterSidebarOpen,
      filteringOptions,
      resetFilter,
      selectFilter,
      appliedFilters,
      mapOrderFilters,
      sortingOptions,
      selectedSortingOptionId,
      changeSort,
      writeFiltersDataToUrl,
      updateSearch,
      selectedSortingChanged,
      meta,
    } = (props.isCategory
      ? useCategory(root, props.contextKey)
      : useSearch(root)) as ReturnType<typeof useCategory> &
      ReturnType<typeof useSearch>;

    const { currentQueryParams, didFiltersOrSortingOrStoreChange } = useRouting(
      root
    );
    const { isEnableShippingFilter } = useShippingFilter(root);
    const {
      getFiltersOptions,
      isViewAllVisible,
      getButtonText,
      toggleVisibilityAllFacets,
      visibleAllFacets,
      setFacetLimits,
      getFilterItemValue,
    } = useFilters(root);
    const cmsRefStore = useCmsRefStore(root.$pinia);

    const status = ref(null);

    const { currentConfig, setGridConfig } = useGridConfig(root);
    const gridSizes = computed(
      () => root.$themeConfig?.productsGrid?.gridSizes
    );

    const productsQty = computed(() => {
      const quantity = pagination.value.total;
      const text =
        quantity === 1
          ? props.translations.productsQuantitySingle
          : props.translations.productsQuantityPlural;

      return text.replace('{{quantity}}', quantity.toString());
    });

    const pageMetaDescription = computed(() => {
      if (props.isCategory) {
        return (
          customPageDescription.value ||
          getMetaDescription(
            title.value,
            sortedFilters.value,
            cmsRefStore.cmsSiteConfiguration?.commerceConfig.brand,
            props.translations.shopAt
          )
        );
      }
      return null;
    });

    const filterPanel = ref(null);
    const lastScrollPosition = ref(0);
    const currentScrollPosition = ref(0);

    let header, headerPromoBar, positionTopStyle;

    const updateShadow = (isStickyThresholdReached) => {
      const classList = filterPanel.value.$el.classList;
      classList.toggle('filter-panel--shadow', isStickyThresholdReached);
      root.$eventBus.$emit('filter-panel-sticky', isStickyThresholdReached);
    };

    const hideHeader = () => {
      filterPanel.value.$el.style.top = '0';
      filterPanel.value.$el.style.transitionDelay = '0s';
      root.$eventBus.$emit('hide-header', true);
    };

    const showHeader = () => {
      filterPanel.value.$el.style.top = positionTopStyle;
      filterPanel.value.$el.style.transitionDelay = '0.05s';
      root.$eventBus.$emit('hide-header', false);
    };

    // react to header height changes
    let active = false;
    const isStickyThresholdReached = ref(false);
    const delta = 5;
    let scrollHeight = null;
    let hideHeaderThreshold = null;
    let isCalledOnce = true;
    const updateTop = () => {
      requestAnimationFrame(() => {
        if (!header || !filterPanel.value) return;
        const headerHeight = Math.floor(header.getBoundingClientRect().height);
        const headerPromoHeight = headerPromoBar
          ? Math.floor(headerPromoBar.getBoundingClientRect().height)
          : 0;
        const filterPanelTop = Math.floor(
          filterPanel.value.$el.getBoundingClientRect().top
        );
        const positionTop = headerHeight + headerPromoHeight;
        positionTopStyle = `${positionTop}px`;

        currentScrollPosition.value = Math.round(
          window.scrollY || document.documentElement.scrollTop
        );

        isStickyThresholdReached.value = filterPanelTop <= positionTop;
        if (filterPanel.value.$el.style.top !== positionTopStyle) {
          filterPanel.value.$el.style.top = positionTopStyle;
        }
        // just in case scroll ended, but header updated after
        updateShadow(isStickyThresholdReached.value);
        // if header height still changing, continue to update
        if (active) updateTop();
      });
    };

    // TODO: Cleanup in GLOBAL15-62475
    const updateTopRedesign = throttle(() => {
      const productsColumn = document.querySelector('.products-grid')
        ?.parentElement?.parentElement;
      requestAnimationFrame(() => {
        if (!header || !filterPanel.value || !productsColumn) return;
        const headerHeight = Math.floor(header.getBoundingClientRect().height);
        const headerPromoHeight = headerPromoBar
          ? Math.floor(headerPromoBar.getBoundingClientRect().height)
          : 0;
        const filterPanelTop = Math.floor(
          filterPanel.value.$el.getBoundingClientRect().top
        );
        const filterPanelHeight = Math.floor(
          filterPanel.value.$el.getBoundingClientRect().height
        );
        const productsColumnBottom = Math.floor(
          productsColumn.getBoundingClientRect().bottom
        );
        const positionTop = headerHeight + headerPromoHeight;
        positionTopStyle = `${positionTop}px`;

        currentScrollPosition.value = Math.round(
          window.scrollY || document.documentElement.scrollTop
        );

        // fix for panel jump after filter chips 'See more' button click
        if (
          scrollHeight === null ||
          (scrollHeight !== document.documentElement.scrollHeight &&
            document.documentElement.scrollTop !== 0 &&
            !isCalledOnce)
        ) {
          scrollHeight = document.documentElement.scrollHeight;
          lastScrollPosition.value = currentScrollPosition.value;
          return;
        }

        if (
          Math.abs(lastScrollPosition.value - currentScrollPosition.value) <
            delta &&
          !isCalledOnce
        )
          return;

        isCalledOnce = false;

        isStickyThresholdReached.value =
          filterPanelTop <= positionTop ||
          (currentScrollPosition.value <= lastScrollPosition.value &&
            currentScrollPosition.value > hideHeaderThreshold);

        if (!isStickyThresholdReached.value) {
          hideHeaderThreshold = Math.floor(
            filterPanel.value.$el.getBoundingClientRect().top + window.scrollY
          );
        }

        if (
          productsColumnBottom > filterPanelHeight &&
          currentScrollPosition.value >= lastScrollPosition.value &&
          currentScrollPosition.value > (hideHeaderThreshold ?? 0)
        ) {
          hideHeader();
        } else {
          showHeader();
        }
        lastScrollPosition.value = currentScrollPosition.value;

        // just in case scroll ended, but header updated after
        updateShadow(isStickyThresholdReached.value);
        // if header height still changing, continue to update
        if (active) updateTopRedesign();
      });
    }, 200);

    const listenToHeaderHeight = (transitioning) => {
      active = transitioning;
      isCalledOnce = true;
      // TODO: Cleanup in GLOBAL15-62475
      isFiltersUiUpdateEnabled ? updateTopRedesign() : updateTop();
    };

    if (isClient && props.sticky) {
      header = document.querySelector('.vf-header__animated');
      headerPromoBar = document.querySelector('.vf-header__promo-bar');
      if (isFiltersUiUpdateEnabled) {
        watch(
          () => root.$route.path,
          (currentVal, prevVal) => {
            if (currentVal !== prevVal) {
              hideHeaderThreshold = null;
            }
          }
        );
      }

      onMounted(() => {
        // TODO: Cleanup in GLOBAL15-62475
        if (isFiltersUiUpdateEnabled) {
          updateTopRedesign();
          window.addEventListener('scroll', updateTopRedesign, {
            passive: true,
          });
        } else {
          updateTop();
          window.addEventListener('scroll', updateTop, { passive: true });
        }

        root.$eventBus.$on('header-collapse', listenToHeaderHeight);

        setFacetLimits({
          filterNumberOfFacetsToShow: props.filterNumberOfFacetsToShow,
          filterNumberOfFacetsToShowLarge:
            props.filterNumberOfFacetsToShowLarge,
          filterNumberOfFacetsToShowMedium:
            props.filterNumberOfFacetsToShowMedium,
          filterNumberOfFacetsToShowSmall:
            props.filterNumberOfFacetsToShowSmall,
        });
      });

      onUnmounted(() => {
        root.$eventBus.$off('header-collapse', listenToHeaderHeight);
        root.$eventBus.$emit('filter-panel-sticky', false);
        // TODO: Cleanup in GLOBAL15-62475
        if (isFiltersUiUpdateEnabled) {
          window.removeEventListener('scroll', updateTopRedesign);
        } else {
          window.removeEventListener('scroll', updateTop);
        }
      });
    }

    watch(currentQueryParams, async () => {
      if (didFiltersOrSortingOrStoreChange()) {
        scrollToTop();
        props.isCategory ? updateCatalog() : await updateSearch();
      }
    });

    const isColorFilter = (filter) => {
      const colorFilterCodes = ['color', 'couleur'];
      return colorFilterCodes.includes(filter.toLowerCase()); // Hotfix for https://digital.vfc.com/jira/browse/GLOBAL15-31624
    };

    const isFacetOverriden = (prop = []) => {
      return prop.some(
        (item) =>
          item.rootId === categoryId.value ||
          item.children.includes(categoryId.value)
      );
    };

    const isChipOverriden = computed(() =>
      isFacetOverriden(props.overridenCategories.chips)
    );

    const isCheckboxOverriden = computed(() =>
      isFacetOverriden(props.overridenCategories.checkboxes)
    );

    const applyFilterDisabled = computed(
      () => !getSelectionChanged() && !selectedSortingChanged.value
    );

    const onClickSelect = (item) => {
      selectFilter(item, false);
    };

    const onClickRemove = (item) => {
      removeFilter(item, false);
    };

    const getFacetLink = (filter) => {
      return urlDecoder.getFilterQueryLink(root, appliedFilters.value, filter);
    };

    const filters = computed(() => {
      if (props.facetsOrder) {
        const order = props.facetsOrder.map((item) => item.id);
        return mapOrderFilters(filteringOptions.value, order, 'code');
      }
      return filteringOptions.value;
    });

    /** Sorting */
    const translatedSortingOptions = computed(() =>
      sortingOptions.value.map((item) => ({
        value: item.value,
        label: props.translations.sortOptions[item.value],
      }))
    );

    const selectedSortingOptionLabel = computed(() => {
      const option = sortingOptions.value.find(
        (opt) => opt.value === selectedSortingOptionId.value
      );
      if (option) {
        return props.translations.sortOptions[option.value];
      }
      return '';
    });

    const sortDropdownOpen = ref(false);
    const sortDropdownLabel = computed(
      () =>
        props.translations.sortLabel +
        (selectedSortingOptionLabel.value
          ? ': ' + selectedSortingOptionLabel.value
          : '')
    );

    const changeActiveSortOption = (sortOptionId, update = true) => {
      changeSort(sortOptionId);
      // since there no changed filters, will call to updateCatalog
      update && getResults();
    };

    const getResults = () => {
      writeFiltersDataToUrl(true);
      setFilterSidebarOpen(false);
    };

    const resetResults = () => {
      changeSort(props.defaultSort, false);
      handleResetAll();
      setFilterSidebarOpen(false);
    };

    const getSelectionChanged = () => {
      const prepareFilters = (filters) => {
        const sortedFilters = filters
          .sort((a, b) => b.value - a.value)
          .map(({ code, value }) => ({ code, value }));

        return sortedFilters;
      };

      return (
        JSON.stringify(prepareFilters(appliedFilters.value)) !==
        JSON.stringify(prepareFilters(selectedFilters.value))
      );
    };

    const resetFilterAndSearch = (code) => {
      resetFilter(code, false);
      getResults();
    };

    const defaultFacetType = computed(
      () => root.$themeConfig?.facetDisplayType?.defaultFacetDisplayType
    );

    const hideWhenNoResults =
      root.$themeConfig?.categoryFilters?.hideWhenNoResults ?? false;

    const shouldDisplayCategoryFilters = computed(() => {
      // already has some filters applied
      if (appliedFilters.value.length > 0) {
        return true;
      }

      return hideWhenNoResults ? !!pagination.value.total : true;
    });

    const filtersOptions = computed(() => getFiltersOptions(filteringOptions));

    const headTitle = computed(() => pageMetaTitle.value);

    const hasSelectedFilterByCode = ref({});
    watch(isFilterSidebarOpen, (isOpen) => {
      watchIsSidebarOpen(isOpen, hasSelectedFilterByCode, appliedFilters);
    });

    const handleResetFilter = (filter, update) => {
      removeFilter(filter, update);
      status.value = props.translations.filterRemoved.replace(
        '{{filterName}}',
        filter.text
      );
    };

    const handleResetAll = () => {
      resetFilters();
      status.value = props.translations.allFiltersRemoved;
    };

    return {
      categoryId,
      isFiltersUiUpdateEnabled,
      isStickyThresholdReached,
      handleResetFilter,
      handleResetAll,
      status,
      filterPanel,
      isEnableShippingFilter,
      isFilterSidebarOpen,
      setFilterSidebarOpen,
      hasSelectedFilterByCode,
      productsQty,
      selectedFilters,
      resetFilters,
      removeFilter,
      gridSizes,
      currentConfig,
      setGridConfig,
      headTitle,
      queryString,
      pageMetaDescription,
      title,
      filters,
      appliedFilters,
      resetFilterAndSearch,
      selectFilter,
      getFacetLink,
      getFilterType,
      getResults,
      resetResults,
      sortDropdownOpen,
      sortDropdownLabel,
      translatedSortingOptions,
      selectedSortingOptionId,
      changeActiveSortOption,
      onClickSelect,
      onClickRemove,
      isColorFilter,
      isChipOverriden,
      isCheckboxOverriden,
      facetConfiguration: cmsRefStore.facetConfiguration,
      defaultFacetType,
      shouldDisplayCategoryFilters,
      isFacetOverriden,
      filtersOptions,
      isViewAllVisible,
      getButtonText,
      toggleVisibilityAllFacets,
      visibleAllFacets,
      applyFilterDisabled,
      getFilterItemValue,
      meta,
    };
  },
  head() {
    return this.isCategory
      ? {
          title: this.headTitle,
          meta: [
            {
              hid: 'description',
              name: 'description',
              content: this.pageMetaDescription,
            },
          ],
        }
      : null;
  },
});
