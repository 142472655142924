






import type { PropType } from 'vue';
import { defineComponent } from '@nuxtjs/composition-api';
import type { OrderBrandAddressTranslation } from '@vf/api-contract';

export default defineComponent({
  name: 'OrderBrandAddress',
  props: {
    translations: {
      type: Object as PropType<OrderBrandAddressTranslation>,
      required: true,
    },
  },
});
