import { render, staticRenderFns } from "./PromotionMessage.vue?vue&type=template&id=1354b871&scoped=true&"
import script from "./PromotionMessage.vue?vue&type=script&lang=ts&"
export * from "./PromotionMessage.vue?vue&type=script&lang=ts&"
import style0 from "./PromotionMessage.vue?vue&type=style&index=0&id=1354b871&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1354b871",
  null
  
)

export default component.exports