
































import type { PropType } from 'vue';
import { computed, defineComponent, inject } from '@vue/composition-api';
import type { SignInFormTranslations } from '@vf/api-contract';
import {
  ROUTES,
  useAccount,
  useAuthentication,
  useCart,
  useFavorites,
  useI18n,
  useNotification,
  useSaveForLater,
  useGtm,
} from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import useAuthenticationEvents, {
  AuthenticationType,
} from '@/shared/useAuthenticationEvents';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import { useCheckoutStore } from '@vf/composables/src/store/checkoutStore';

export default defineComponent({
  name: 'LoyaltyLogin',
  props: {
    translations: {
      type: Object as PropType<SignInFormTranslations>,
      required: true,
    },
    /** Link to forgot password page */
    forgotPasswordLink: {
      type: String,
      default: '',
    },
    showHeading: {
      type: Boolean,
      default: true,
    },
    showSubheading: {
      type: Boolean,
      default: true,
    },
    /** Show link to register form **/
    showEnrollNow: {
      type: Boolean,
      default: true,
    },
    stlHash: {
      type: String as PropType<string>,
      default: null,
    },
    contextKey: {
      type: String,
      required: true,
    },
    initialMail: {
      type: String,
      default: '',
    },
    showPasswordToggler: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['enroll-now', 'login'],
  setup(props, { emit }) {
    const { root } = useRootInstance();
    const { getFormLocation } = useGtm(root);
    const formLocation = getFormLocation(props.contextKey);
    const {
      signIn,
      saveForLaterId,
      processPendingActionForLoyaltyUser,
    } = useAuthentication(root);
    const { createSignIn, loading } = useAuthenticationEvents(formLocation, {
      useLoadEvent: true,
      type: AuthenticationType.Login,
    });
    const { getSaveForLaterItems } = useSaveForLater(root);
    const { localePath } = useI18n(root);
    const { cartId, saveGuestCart, isCartMerged } = useCart(root);
    const { getBasicInformation } = useAccount(root);
    const { favoriteId } = useFavorites(root);
    const { clearNotifications } = useNotification(root);
    const { isSimplifiedEnrollmentEnabled } = useFeatureFlagsStore();

    // TODO: GLOBAL15-61059 remove after redesign core
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');

    const isCartPage = computed(() => root.$route.path.endsWith(ROUTES.CART()));

    const boundWithGtmEventsSignIn = createSignIn(
      async <T extends { username: string; password: string }>(data: T) => {
        saveGuestCart();
        const checkout = useCheckoutStore();
        return signIn({
          ...data,
          ...(checkout.order && {
            action: 'postLogin',
            st: checkout.order.st,
            orderNo: checkout.order.orderNumber,
          }),
          guestObjects: {
            basketId: cartId.value,
            saveForLaterId: saveForLaterId.value,
            favoriteId: favoriteId.value,
          },
          formLocation,
          errorMessage: props.translations.loginError, // TODO: custom error message for login error
        }).catch((err) => {
          root.$log.error(
            `[@/components/smart/loyalty/LoyaltyLogin.vue::boundWithGtmEventsSignIn] do sign in call in error`,
            err.message
          );
          return false;
        });
      }
    );

    const doSignIn = async (data) => {
      clearNotifications();
      const signInStatus = await boundWithGtmEventsSignIn(data);
      if (signInStatus) {
        await processPendingActionForLoyaltyUser();
        isCartMerged.value = true;
        if (isSimplifiedEnrollmentEnabled) {
          await getBasicInformation();
          emit('login');
        } else {
          emit('login');
          getBasicInformation();
        }

        if (saveForLaterId.value && isCartPage.value) {
          getSaveForLaterItems();
        }
      }
    };

    const formProps = computed(() => ({
      headingLevel: 4,
      heading: props.showHeading && props.translations.heading,
      subheading: props.showSubheading && props.translations.subheading,
      emailLabel: props.translations.emailLabel,
      passwordLabel: props.translations.passwordLabel,
      emailErrorMessage: props.translations.validationMessages.email,
      requiredErrorMessage: props.translations.validationMessages.required,
      signInButtonText: props.translations.signInButtonText,
      forgotPasswordButtonText: props.translations.forgotPasswordButtonText,
      forgotPasswordLink:
        props.forgotPasswordLink || localePath(ROUTES.FORGOT_PASSWORD()),
      createAccountButtonText: props.translations.createAccountButtonText,
      createAccountButtonLabel: props.translations.createAccountButtonLabel,
      showSocialLinks: false,
      buttonDisabled: loading.value,
      showCreateAccountLink: props.showEnrollNow,
    }));

    return {
      formProps,
      doSignIn,
      isCoreRedesignEnabled,
    };
  },
});
