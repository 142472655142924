









import { computed, defineComponent } from '@vue/composition-api';
import { storeToRefs } from 'pinia';
import { useCartStore } from '@vf/composables/src/store/cartStore';
import useRootInstance from '@/shared/useRootInstance';
import { pluralize } from '@/helpers/pluralize';
import { replaceAll } from '@/helpers/replaceAll';

export default defineComponent({
  name: 'CheckoutPickupTime',
  setup() {
    const { root } = useRootInstance();
    const { pickupItems, stsItems, pickupOptions } = storeToRefs(
      useCartStore()
    );

    const itemCount = computed(() => {
      return {
        sts: stsItems.value.length,
        pickup: pickupItems.value.length,
      };
    });

    const pickupTimes = computed(() =>
      pickupOptions.value.map(({ code, label, deliveryTime }) => {
        const computedLabel = root.$t(
          `pickupTime.${code}.pickupDeliveryDate`
        ) as string;
        const countLabel = root.$t(
          pluralize('pickupTime.itemCount', itemCount.value[code])
        ) as string;
        return {
          code,
          label: replaceAll(computedLabel, { label, deliveryTime }),
          count: replaceAll(countLabel, { count: itemCount.value[code] }),
        };
      })
    );

    const mixPickup = computed(
      () => itemCount.value.pickup > 0 && itemCount.value.sts > 0
    );

    return {
      pickupTimes,
      mixPickup,
    };
  },
});
