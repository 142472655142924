
































































































import type { PropType } from 'vue';
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  watch,
  ref,
} from '@vue/composition-api';
import {
  Context,
  ProductRecommendationsTranslations,
  MonetateEventType,
} from '@vf/api-contract';
import {
  useI18n,
  useMonetate,
  useNotification,
  useProduct,
  useProductInventory,
  useGtm,
  useUrl,
} from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import useModal from '@/shared/useModal';
import QuickShopTile from '@/components/product/QuickShopTile.vue';
import monetateProductsPlaceholder from './PlaceholderMonetateProducts.json';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

export default defineComponent({
  name: 'MonetateProductRecommendations',
  components: {
    QuickShopTile,
  },
  props: {
    experienceId: {
      type: String,
      default: null,
    },
    /** Number of products to display in initial load of carousel for small screen */
    displayedProductsSmall: {
      type: Number,
      default: 2,
    },
    /** Number of products to display in initial load of carousel for medium screen */
    displayedProductsMedium: {
      type: Number,
      default: 4,
    },
    /** Number of products to display in initial load of carousel for large screen */
    displayedProductsLarge: {
      type: Number,
      default: 6,
    },
    /** Static data for product recommendations carousel */
    translations: {
      type: Object as PropType<ProductRecommendationsTranslations>,
      default: () => ({}),
    },
    /** Heading level number value */
    headingLevel: {
      type: Number,
      default: 2,
    },
    /** What is the alignment of the heading */
    headerTextAlign: {
      type: String,
      default: 'left',
    },
    /** Font wight of the heading */
    headerFontWeight: {
      type: String,
      default: '200',
    },
    /** Flag to determine if FE should show add to favorites button */
    showAddToFavorites: {
      type: Boolean,
      default: true,
    },
    /** Flag to determine if the mobile carousel is shown on 2 rows */
    showMobileOnTwoRows: {
      type: Boolean,
      default: false,
    },
    /** Flag to determine if the quick shop cta is shown */
    showQuickShopCta: {
      type: Boolean,
      default: true,
    },
    /** Flag to determine if it's a carousel or a simple grid */
    isCarousel: {
      type: Boolean,
      default: true,
    },
    /** Flag to determine if suggested products are randomized */
    isRandomized: {
      type: Boolean,
      default: false,
    },
    /** Flag to determine if on arrow click a full row has to be slided */
    slideFullRow: {
      type: Boolean,
      default: false,
    },
    /** Flag to determine if the carousel has autoplay */
    autoplay: {
      type: Boolean,
      default: false,
    },
    /** Flag to determine if after the end of the slider it goes back to the beginning */
    loop: {
      type: Boolean,
      default: false,
    },
    /** Number to determine the slide duration in milliseconds */
    slideDuration: {
      type: Number,
      default: 2000,
    },
    /** Flag to show the price underneath the product tile */
    showPrice: {
      type: Boolean,
      default: true,
    },
    /** QuickShop link page to be open in modal */
    quickShopLink: {
      type: [String, Object],
      default: '',
    },
    /** Space between images in px */
    spaceBetween: {
      type: Number,
      default: 10,
    },
    showAsPeek: {
      type: Object,
      default: () => ({
        small: false,
        medium: false,
        large: false,
      }),
    },
    modals: {
      type: Object,
      default: () => ({
        signInToBuy: null,
        loyaltyEnrollment: null,
      }),
    },
  },
  setup(props) {
    let unwatch = () => undefined;
    const { root } = useRootInstance();
    const { defaultCurrency } = useI18n(root);
    const { getRelativeUrl } = useUrl(root);
    const {
      extractRecommendedItems,
      loading,
      hasLoaded,
      sendRecImpressionsEvent,
      sendMonetateEvents,
      extractRecommendedAction,
      monetateCartCustomVars,
    } = useMonetate(root);
    // TODO: Cleanup in GLOBAL15-63799
    const { isVansPdpRedesignEnabled } = useFeatureFlagsStore();
    const { openModal } = useModal();
    const { addNotification } = useNotification(root);
    const {
      toggleQuickShop,
      findInStoreQuickShopVisible,
      isQuickShopOpen,
      quickShopOpenProductId,
      closeQuickShop,
      getColorByCode,
      setInitialColor,
    } = useProduct(root, Context.QuickShop);
    const { getProductInventory } = useProductInventory(
      root,
      Context.QuickShop
    );
    const { dispatchEvent } = useGtm(root);
    const themeProductGrid = root.$themeConfig.productsGrid;

    const isSmallViewport = computed(() => root.$viewport?.isSmall);

    const plpPlaceholder = root.$mediaUrlGenerator({
      shotType: 'placeholder',
      preset: root.$env.IMAGES_PLP_PRESET,
    });
    const mapRecommendedItems = (items) => {
      let index = 0;
      return items.map((product) => ({
        id: product.itemGroupId,
        title: product.title,
        images: {
          src: product.imageLink || plpPlaceholder,
        },
        colorCode: product.id.split(':')[2],
        link: product.link ? getRelativeUrl(product.link) : '',
        price: {
          original: product.price,
          current: product.salePrice,
          /** MISSING_CURRENCY: Monetate does not contain Currency code in their response, we need to take it from CMS configuration */
          currency: defaultCurrency.value,
        },
        recToken: product.recToken,
        index: index++,
      }));
    };

    const products = ref(mapRecommendedItems(monetateProductsPlaceholder));

    const openProductQuickShop = async (product, $event) => {
      findInStoreQuickShopVisible.value = false;
      const {
        shouldOpenModalOnRecommendationQuickShop,
      } = root.$themeConfig.monetate;
      try {
        if (
          product.id === quickShopOpenProductId.value &&
          $event &&
          !$event.isOpen
        ) {
          closeQuickShop();
        }
        if (!$event || $event.isOpen) {
          await toggleQuickShop(
            product.id,
            $event?.productConfiguration,
            root.$themeSettings.MonetateProductRecommendations
              .shouldSaveOnlyColor
          );

          if (
            shouldOpenModalOnRecommendationQuickShop ||
            isSmallViewport.value
          ) {
            const isColorInOptions = getColorByCode(
              $event?.productConfiguration.color
            );
            if (!isColorInOptions) {
              setInitialColor();
            }

            openModal({
              type: 'page',
              path: props.quickShopLink,
              contextKey: Context.QuickShop,
              additionalData: {
                experienceId: props.experienceId,
                listType: 'Recommendation Product Carousel',
              },
            });
          }
          await getProductInventory(product.id);

          const eventAction = shouldOpenModalOnRecommendationQuickShop
            ? 'Open Quick View'
            : 'Open Quick Shop';

          dispatchProductRecEvent(product, eventAction);
        }
      } catch (e) {
        addNotification({
          message: '',
          type: 'danger',
        });
      }
    };

    const size = computed(() => {
      try {
        const { width, height } = themeProductGrid.imageSizes[
          root.$viewport.size
        ];
        const aspectRatio = height / width;

        return {
          width,
          height,
          aspectRatio,
        };
      } catch {
        return {};
      }
    });

    const contextKey = Context.QuickShop;

    const getProductCardDataId = (product) => {
      const recipe =
        product.recipe || product.recipeId || product.customsRecipeID;
      const recipeSegment = recipe ? `_${recipe}` : '';

      return `${product.id}${recipeSegment}`;
    };

    const dispatchProductRecEvent = (product, action) => {
      const experience = extractRecommendedAction(props.experienceId);
      const listType = 'Product Recommendation Carousel';
      if (action === 'Click View Details') {
        monetateCartCustomVars.value = {
          experience,
          list_type: listType,
        };
      }
      dispatchEvent({
        eventName: 'productRecClick',
        overrideAttributes: {
          product,
          list: `${listType}: ${props.translations.heading}`,
          action,
          experience,
          list_type: listType,
        },
      });
    };

    const onShowDetails = (product) => {
      if (!product) return;
      dispatchProductRecEvent(product, 'Click View Details');
    };

    const areSameRecommendedItems = (items1, items2) => {
      if (items1.length !== items2.length) return false;
      for (let i = 0; i < items1.length; i++) {
        if (items1[i].id !== items2[i].id) return false;
      }
      return true;
    };
    onMounted(() => {
      root.$eventBus.$on('quickshop-modal-view-details', (productId) => {
        const foundProduct = products.value.find(
          (item) => item.id === productId
        );
        onShowDetails(foundProduct);
      });

      unwatch = watch(
        hasLoaded,
        () => {
          if (hasLoaded.value) {
            const newRecommendedItems = mapRecommendedItems(
              extractRecommendedItems(props.experienceId)
            );
            if (
              (newRecommendedItems.length ||
                products.value.find(({ id }) => id === 'LOADING')) &&
              !areSameRecommendedItems(products.value, newRecommendedItems)
            ) {
              products.value = newRecommendedItems;
            }
          }
        },
        { immediate: true }
      );
    });

    onBeforeUnmount(() => {
      root.$eventBus.$off('quickshop-modal-view-details');
      unwatch();
    });

    return {
      contextKey,
      loading,
      products,
      openProductQuickShop,
      size,
      extractRecommendedItems,
      onShowDetails,
      sendRecImpressionsEvent,
      sendMonetateEvents,
      dispatchProductRecEvent,
      extractRecommendedAction,
      getProductCardDataId,
      isSmallViewport,
      isQuickShopOpen,
      quickShopOpenProductId,
      isVansPdpRedesignEnabled,
    };
  },

  data() {
    return {
      swiper: null,
      autoplay_: false,
      isOneLoopCompleted: false,
      hasChangedSlide: false,
      isLoaded: false,
    };
  },

  computed: {
    sortedProductsList() {
      let afterRandomization = this.isRandomized
        ? [...this.products].sort(() => Math.random() - 0.5)
        : this.products;
      let lengthToSlice =
        this.showMobileOnTwoRows && this.$root?.$viewport?.size === 'small'
          ? this.itemCount * 2
          : this.itemCount;
      return this.isCarousel
        ? afterRandomization
        : afterRandomization.slice(0, lengthToSlice);
    },
    styles() {
      return {
        '--slider-gap': this.gap,
        '--slider-items-count': this.itemCount,
      };
    },
    itemCount() {
      return {
        small: this.showAsPeek.small
          ? this.displayedProductsSmall + 0.5
          : this.displayedProductsSmall,
        medium: this.showAsPeek.medium
          ? this.displayedProductsMedium + 0.5
          : this.displayedProductsMedium,
        large: this.showAsPeek.large
          ? this.displayedProductsLarge + 0.5
          : this.displayedProductsLarge,
      }[this.$root.$viewport?.size];
    },
    gap() {
      return `${this.spaceBetween}px`;
    },
    duration() {
      if (this.slideDuration) {
        return this.autoplay_ && this.slideDuration;
      }

      return this.autoplay_;
    },
    showAsCarousel() {
      return this.isCarousel && this.sortedProductsList.length > this.itemCount;
    },
    stylesSettings() {
      return {
        titleFontWeight: this.headerFontWeight,
      };
    },
    textAlignSettings() {
      return {
        small: this.headerTextAlign,
        medium: this.headerTextAlign,
        large: this.headerTextAlign,
      };
    },
    boundCarouselProps() {
      return {
        scrollProps: {
          gap: this.gap,
          loop: this.loop,
          scrollBy: this.slideFullRow ? Math.floor(this.itemCount) : 1,
          classContainer:
            this.showMobileOnTwoRows && this.products?.length > 2
              ? 'mobileTwoRows'
              : 'loop',
        },
        style: this.styles,
        autoplay: this.duration,
        classControls:
          this.$root.$viewport?.size === 'small' && this.showAsPeek.small
            ? 'vf-swiper-control-hide'
            : '',
        pagination:
          this.$root.$viewport?.size === 'small' && this.showAsPeek.small,
      };
    },
  },
  watch: {
    async loading() {
      if (this.loading === 'completed' && !this.isLoaded) {
        this.isLoaded = true;
        this.swiper?.scroller.onScrollDebounce();

        await this.$nextTick();
        this.sendRecImpressionsEvent(
          this.products,
          0,
          this.itemCount,
          this.experienceId,
          this.translations.heading,
          'Product Recommendation Carousel'
        );
      }
    },
  },
  created() {
    this.autoplay_ = this.autoplay;
  },
  methods: {
    async initSwiper(swiperInstance) {
      if (swiperInstance?.scroller) {
        this.swiper = swiperInstance;
      }
    },
    changeSwiper(slide) {
      if (!this.isLoaded) {
        return false;
      }
      if (slide.activeItem !== 0) {
        this.hasChangedSlide = true;
      }
      if (!this.hasChangedSlide && slide.activeItem === 0) {
        return false;
      }
      !this.isOneLoopCompleted &&
        this.sendRecImpressionsEvent(
          this.products,
          slide.activeItem + this.itemCount - 1,
          this.slideFullRow
            ? slide.activeItem + 2 * this.itemCount
            : slide.activeItem + this.itemCount,
          this.experienceId,
          this.translations.heading,
          'Product Recommendation Carousel'
        );
      if (!slide.hasNext && slide.activeItem) {
        this.isOneLoopCompleted = true;
        !this.loop && (this.autoplay_ = false);
      }
      return true;
    },
    clickProductTile(product) {
      this.sendMonetateEvents([
        {
          eventType: MonetateEventType.RecClicks,
          recClicks: [product.recToken],
        },
      ]);

      this.dispatchProductRecEvent(product, 'Navigate To PDP');

      // reset activeItem and isOneLoopCompleted
      this.swiper.scroller.activeItem = 0;
      this.isOneLoopCompleted = false;
      this.isLoaded = false;
      this.hasChangedSlide = false;
    },
  },
});
