//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'LoyaltyRewardsProgressBar',
  props: {
    summary: {
      type: String,
      default: '',
    },
    vouchers: {
      type: Array,
      default: () => [],
    },
    full: {
      type: Boolean,
      default: false,
    },
  },
};
