




















































import { computed, defineComponent } from '@vue/composition-api';
import {
  useAccount,
  useGtm,
  useNotification,
  useUtilities,
} from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import { useCmsRefStore } from '@vf/composables/src/store/cmsRef';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@vf/composables/src/store/user';

enum SubmitButtonType {
  Text = 'submit-text',
  Icon = 'submit-arrow',
}
export default defineComponent({
  name: 'EmailCaptureForm',
  props: {
    title: {
      type: String,
      default: '',
    },
    summaryText: {
      type: String,
      default: '',
    },
    campaignName: {
      type: String,
      default: '',
    },
    ctaSubmitType: {
      type: String,
      default: SubmitButtonType.Text,
    },
    backgroundColor: {
      type: String,
      default: 'transparent',
    },
    textColor: {
      type: String,
      default: 'var(--c-black)',
    },
    titleFontSize: {
      type: String,
      default: '',
    },
    textAlignment: {
      type: String,
      default: 'center',
    },
    validationMessages: {
      type: Object,
      default: () => ({
        requiredError: '',
        emailError: '',
        successMessage: '',
        errorMessage: '',
      }),
    },
    translations: {
      type: Object,
      default: () => ({
        checkboxText: '',
        emailLabel: '',
        emailPlaceholder: '',
        sendButtonText: '',
        checkboxValidationMessage: '',
      }),
    },
  },
  setup(props) {
    const cmsRefStore = useCmsRefStore();
    const { root } = useRootInstance();
    const {
      updateProfile,
      getBasicInformation,
      setBasicInformation,
      basicInformation,
    } = useAccount(root);
    const userStore = useUserStore(root);
    const { loggedIn } = storeToRefs(userStore);
    const { getCountry } = useUtilities(root);
    const { dispatchEvent } = useGtm(root);
    const {
      changeNewsletterNotification,
      newsletterNotification,
    } = useNotification(root);

    const { cmsSiteConfiguration } = storeToRefs(cmsRefStore);
    const userCountry = computed(() => getCountry().toUpperCase());

    const userCurrency =
      cmsSiteConfiguration.value?.commerceConfig?.currency || '';
    const currentLocale = (
      cmsSiteConfiguration.value?.commerceConfig?.locale || ''
    )
      .replace('-', '_')
      .split('_');

    const getOverrideAttributes = (data?) => {
      return {
        eventCategory: 'Newsletter',
        eventAction: 'Newsletter Sign Up',
        eventLabel: 'Submit',
        nonInteractive: false,
        customMetrics: {},
        _clear: true,
        ...data,
      };
    };

    const basicInformationData = computed(() => {
      return {
        email: basicInformation.value.email,
        firstName: basicInformation.value.firstName,
        lastName: basicInformation.value.lastName,
        gender: basicInformation.value.gender,
        birthDate: basicInformation.value.birthDate,
      };
    });

    const handleSubscription = async (email) => {
      dispatchEvent({
        eventName: 'loadEventData',
        overrideAttributes: getOverrideAttributes(),
      });
      let response;
      try {
        if (loggedIn.value) {
          await getBasicInformation();
          response = await setBasicInformation({
            ...basicInformationData.value,
            subscriptions: [
              {
                type: 'Newsletter',
                optin: true,
                country: userCountry.value,
                optinSource: root.$env.API_SOURCE,
                region: root.$env.API_REGION,
              },
            ],
          });
        } else {
          response = await updateProfile({
            email: email,
            currency: userCurrency,
            language: currentLocale.length
              ? currentLocale[0].toUpperCase()
              : 'EN',
          });
        }
        const successStatusCodes = [200, 204];
        const isSuccess = successStatusCodes.includes(response.status);
        changeNewsletterNotification({
          showNotification: true,
          isSuccess,
        });
        if (isSuccess) {
          dispatchEvent({
            eventName: 'loadEventData',
            overrideAttributes: getOverrideAttributes({
              eventLabel: 'Confirmed',
              customMetrics: {
                elementConversion: 1,
              },
            }),
          });
        }
      } catch (error) {
        changeNewsletterNotification({
          showNotification: true,
          isSuccess: false,
        });
      }
    };

    const handleSubscribeShown = () => {
      dispatchEvent({
        eventName: 'loadEventData',
        overrideAttributes: getOverrideAttributes({
          eventLabel: 'Impression',
          nonInteractive: true,
          customMetrics: {
            elementView: 1,
          },
        }),
      });
    };

    const clearNotifications = () => {
      changeNewsletterNotification({
        showNotification: false,
        isSuccess: null,
      });
    };

    clearNotifications();

    const useIconForButton = computed(() => {
      return props.ctaSubmitType === SubmitButtonType.Icon;
    });

    const submitButtonText = computed(() => {
      return props.ctaSubmitType === SubmitButtonType.Text
        ? props.translations.ctaSubmitText
        : '';
    });

    return {
      newsletterNotification,
      handleSubscription,
      handleSubscribeShown,
      clearNotifications,
      useIconForButton,
      submitButtonText,
    };
  },
});
