

















import useRootInstance from '@/shared/useRootInstance';
import { storeToRefs } from 'pinia';
import { defineComponent, computed, ref } from '@nuxtjs/composition-api';
import { useDiscountStore } from '@vf/composables/src/store/discountStore';
import { usePromotionMessageStore } from '@vf/composables/src/store/promotionMessageStore';
import { resolveTwoConditions } from '@/helpers';
import { useCartStore } from '@vf/composables/src/store/cartStore';
import CartTopPromoNotificationsCmsLink from '@/components/static/cart/CartTopPromoNotificationsCmsLink.vue';

type TopPromoMessage = {
  description: string;
  id: string;
  isBonus: boolean;
};

export default defineComponent({
  name: 'CartTopPromoNotifications',
  components: {
    CartTopPromoNotificationsCmsLink,
  },
  setup() {
    const { root } = useRootInstance();
    const theme = root.$themeConfig.staticCart;
    const { cartItems, outOfStockProducts } = storeToRefs(useCartStore());
    const { appliedPromotions } = storeToRefs(useDiscountStore());
    const { approachingDiscounts } = storeToRefs(usePromotionMessageStore());

    const mapApproachingDiscountToMessage = ({
      distanceFromConditionThreshold,
      id,
      discount,
      earned,
    }): TopPromoMessage => {
      const key = resolveTwoConditions(
        id === 'bounceBackVouchers',
        earned,
        'promoNotifications.VBBAppliedOnCart',
        'promoNotifications.approachingDiscountVBB',
        'promoNotifications.GWPApplied',
        resolveTwoConditions(
          id.includes('GUEST'),
          id.includes('REGISTERED'),
          '',
          'promoNotifications.approachingDiscountGWPGuest',
          'promoNotifications.approachingDiscountGWPRegistered',
          'promoNotifications.approachingDiscountGWP'
        )
      );

      const message = root.$t(key, {
        remainingAmount: root.$formatPrice(distanceFromConditionThreshold),
        discount,
      });
      return {
        description: (message as unknown) as string,
        id,
        isBonus: false,
      };
    };

    const onlyOutOfStock = computed(
      () => !cartItems.value.length && !!outOfStockProducts.value.length
    );
    const appliedPromotionsMessages = computed<TopPromoMessage[]>(() => {
      return appliedPromotions.value
        .filter(
          ({ type, isBonus, isGWP, isOrderAuto }) =>
            isOrderAuto || ((type === 'cart-promotion' || isBonus) && !isGWP)
        )
        .map(({ description, id, isBonus, calloutMsg }) => ({
          description: calloutMsg || description,
          id,
          isBonus,
        }));
    });

    const approachingDiscountsMessages = computed<TopPromoMessage[]>(() => {
      return approachingDiscounts.value
        .filter(
          ({ promotionId, promotionClass }) =>
            (promotionId !== 'bounceBackVouchers' || !onlyOutOfStock.value) &&
            promotionClass !== 'SHIPPING'
        )
        .map(
          ({
            distanceFromConditionThreshold,
            promotionId: id,
            discount,
            earned,
          }) =>
            mapApproachingDiscountToMessage({
              distanceFromConditionThreshold,
              id,
              discount,
              earned,
            })
        );
    });

    const orderLevelNotificationMessages = computed<TopPromoMessage[]>(() => [
      ...appliedPromotionsMessages.value,
      ...approachingDiscountsMessages.value,
    ]);

    const hiddenNotifications = ref([]);
    const hideNotification = (id) => hiddenNotifications.value.push(id);

    const isNotificationVisible = ({ id, isBonus }) => {
      return (
        !hiddenNotifications.value.includes(id) &&
        (!isBonus || (isBonus && theme.hasGiftTopNotification))
      );
    };

    return {
      orderLevelNotificationMessages,
      theme,
      isNotificationVisible,
      hideNotification,
    };
  },
});
