






























import { computed, defineComponent, PropType } from '@vue/composition-api';
import { QuickShopProductCtasTranslations } from '@vf/api-contract/src';
import { useFavoritesWithDataLayer } from '@/shared/useFavoritesWithDataLayer';
import { PageTypeName } from '@vf/composables/src/useCms/types';
import {
  useNotification,
  useProduct,
  useUrl,
  useSignInToStore,
} from '@vf/composables';
import ProductAddToCart from '@/components/smart/shared/ProductAddToCart.vue';
import useRootInstance from '@/shared/useRootInstance';
import { Context } from '@vf/api-contract/src/cmsPropsEntities';

export default defineComponent({
  name: 'QuickshopProductCtas',
  components: {
    ProductAddToCart,
  },
  props: {
    modals: {
      type: Object,
      default: () => ({
        signInToBuy: null,
        loyaltyEnrollment: null,
      }),
    },
    translations: {
      type: Object as PropType<QuickShopProductCtasTranslations>,
      required: true,
    },
    contextKey: {
      type: String,
      default: 'quickshop',
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { product } = useProduct(root, props.contextKey);
    const { getProductUrl } = useUrl(root);
    const { clearNotifications, addNotification } = useNotification(root);
    const { isFavorite, toggleFavorites, loading } = useFavoritesWithDataLayer(
      PageTypeName.PDP
    );
    const { employeeConnected } = useSignInToStore(root);

    const handleFavouriteToggle = async () => {
      clearNotifications();

      const isAdded = await toggleFavorites(product.value);

      if (isAdded) {
        addNotification({
          modifiers: 'scrollToNotification',
          errorMessageId: null,
          message: props.translations.addedToFavoritesNotification,
          type: 'success',
        });
      }
    };

    const handleDetailsClick = () => {
      root.$eventBus.$emit('quickshop-modal-view-details', product.value.id);
    };

    const favouriteIcon = computed(() => {
      return isFavorite(product.value.id) ? 'heart__fill' : 'heart';
    });

    const productDetailsLink = computed(() => {
      const recipe =
        product.value.recipe ||
        product.value.recipeId ||
        product.value.customsRecipeID;

      return getProductUrl(product.value, { recipe });
    });

    // Do not show if employee is connected and config is true.
    const showAddToFavourites = computed(
      () =>
        !(
          root.$themeConfig?.saveToFavorites?.hideFavouriteCtaForEmployees &&
          employeeConnected.value
        )
    );

    return {
      handleFavouriteToggle,
      handleDetailsClick,
      addToFavoritesLoading: loading,
      favouriteIcon,
      product,
      productDetailsLink,
      Context,
      showAddToFavourites,
    };
  },
});
