var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isVisible)?_c('div',{staticClass:"vf-widget-recommendations"},[(_vm.showRelatedSearches)?_c('div',{staticClass:"vf-widget-recommendations__categories",class:{
      'vf-widget-recommendations__categories--full-width':
        _vm.showRelatedSearches && !_vm.showRelatedProducts,
    }},[(_vm.relatedSearchesDisplayMode === 'merged')?[_c('h2',{staticClass:"vf-widget-recommendations__title"},[_vm._v("\n        "+_vm._s(_vm.translations.relatedSearches)+"\n      ")]),_vm._v(" "),_c('ul',{staticClass:"vf-widget-recommendations__list"},_vm._l((_vm.relatedSearches),function(item,index){return _c('li',{key:item.url + '-search-' + index,staticClass:"vf-widget-recommendations__list-item"},[_c('ThemeLink',{staticClass:"vf-button--text vf-widget-recommendations__link",attrs:{"to":item.url}},[_vm._v("\n            "+_vm._s(item.anchor)+"\n          ")])],1)}),0)]:[(_vm.hasRelatedItems)?[_c('h2',{staticClass:"vf-widget-recommendations__title"},[_vm._v("\n          "+_vm._s(_vm.translations.relatedItems)+"\n        ")]),_vm._v(" "),_c('ul',{staticClass:"vf-widget-recommendations__list"},_vm._l((_vm.currentRecommendation.recommendationData
              .relatedItems),function(item,index){return _c('li',{key:item.url + '-item-' + index,staticClass:"vf-widget-recommendations__list-item"},[_c('ThemeLink',{staticClass:"vf-button--text vf-widget-recommendations__link",attrs:{"to":item.url}},[_vm._v("\n              "+_vm._s(item.anchor)+"\n            ")])],1)}),0)]:_vm._e(),_vm._v(" "),(_vm.hasRelatedCategories)?[(_vm.theme.showDivider && _vm.hasRelatedItems)?_c('VfDivider'):_vm._e(),_vm._v(" "),_c('h2',{staticClass:"vf-widget-recommendations__title"},[_vm._v("\n          "+_vm._s(_vm.translations.relatedCategories)+"\n        ")]),_vm._v(" "),_c('ul',{staticClass:"vf-widget-recommendations__list"},_vm._l((_vm.currentRecommendation.recommendationData
              .relatedCategories),function(item,index){return _c('li',{key:item.url + '-category-' + index,staticClass:"vf-widget-recommendations__list-item"},[_c('ThemeLink',{staticClass:"vf-widget-recommendations__link",attrs:{"to":item.url}},[_vm._v("\n              "+_vm._s(item.anchor)+"\n            ")])],1)}),0)]:_vm._e()]],2):_vm._e(),_vm._v(" "),(_vm.showRelatedProducts)?_c('div',{staticClass:"vf-widget-recommendations__products",class:{
      'vf-widget-recommendations__products--full-with':
        _vm.showRelatedProducts && !_vm.showRelatedSearches,
    }},[_c('h2',{staticClass:"vf-widget-recommendations__title"},[_vm._v("\n      "+_vm._s(_vm.translations.relatedProducts)+"\n    ")]),_vm._v(" "),_c('UiSwiper',{attrs:{"scroll-props":_vm.swiperOptions}},_vm._l((_vm.currentRecommendation.recommendationData
          .relatedProducts),function(product,index){return _c('div',{key:product[_vm.theme.titleKey] + '-swiper-slide-' + index,staticClass:"swiper-slide"},[_c('div',{staticClass:"vf-widget-product"},[(_vm.relatedProductsDisplayMode === 'titleImgDescriptionCta')?_c('ThemeLink',{staticClass:"vf-widget-product__title",attrs:{"to":product.url}},[_vm._v("\n            "+_vm._s(product[_vm.theme.titleKey])+"\n          ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"vf-widget-product__image"},[_c('ThemeLink',{attrs:{"to":product.url}},[_c('VfImage',{attrs:{"src":product.image,"seo":_vm.seoObject[index]}})],1)],1),_vm._v(" "),(_vm.relatedProductsDisplayMode === 'imgTitleDescriptionCta')?_c('div',{staticClass:"vf-widget-product__title"},[_vm._v("\n            "+_vm._s(product[_vm.theme.titleKey])+"\n          ")]):_vm._e(),_vm._v(" "),_c('VfText',{staticClass:"vf-widget-product__description",attrs:{"html":"","content":_vm.getProductDescription(product)}}),_vm._v(" "),(_vm.theme.showQuickshopModal && _vm.quickShopLink)?[_c('div',{staticClass:"vf-widget-product__qv vf-link",on:{"click":function($event){return _vm.showQuickShop(product)}}},[_vm._v("\n              "+_vm._s(_vm.translations[_vm.theme.actionLinkTranslationKey])+"\n            ")])]:[_c('ThemeLink',{staticClass:"vf-widget-product__qv",attrs:{"to":product.url}},[_vm._v("\n              "+_vm._s(_vm.translations[_vm.theme.actionLinkTranslationKey])+"\n            ")])]],2)])}),0)],1):_vm._e()]):_c('div')}
var staticRenderFns = []

export { render, staticRenderFns }