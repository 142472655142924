import phoneCodes from './phoneCodes.json';

/**
 * @param fullPhone - phone number that may or may not include the country code
 * @returns a tuplet with the format [country code, phone number]. Country code defaults to 1 if not found
 */
export const parse = (fullPhone: string): [string, string] => {
  // IMPORTANT: there are some libraries that solves this but range from 150KB to 500KB. Probably too much ATM
  // https://github.com/google/libphonenumber
  // https://gitlab.com/catamphetamine/libphonenumber-js#readme

  // remove all non numeric characters
  fullPhone = (fullPhone || '').replace(/\D/g, ''); // though typed as 'string', fullPhone can be undefined when data comes from the API

  // If more than 11 digits, we assume that the last 10 digits are the phone number
  if (fullPhone.length > 11) {
    // get the last 10 digits which is supposedly the phone number
    const phone = fullPhone.slice(fullPhone.length - 10);
    // get the country code by taking the first digits before the last 10 digits
    const phoneCode = fullPhone.slice(0, fullPhone.length - 10);
    return [phoneCode, phone];
  }

  // rule out the most probably use cases, phones from US and CA
  if (fullPhone.length === 11 && fullPhone.charAt(0) === '1')
    return [fullPhone.slice(0, 1), fullPhone.slice(1)];

  // if fullPhone is just 10 digits, assume the phone wasn't saved with country code but is from CA or US
  // this would be the case if the phone was ingested without asking for the country prefix.
  // it could be also a valid phone from other country, but in case of ambiguity we return the most probable one
  if (fullPhone.length === 10) return ['1', fullPhone];

  // try to find a suitable country code from the begining of the phone
  // if we found matches, return the first one => need better criteria for this probably, please suggest
  const matches = phoneCodes
    .map((country) => country.dialCode)
    .filter((dialCode) => fullPhone.startsWith(dialCode));
  if (matches.length)
    return [matches[0], fullPhone.substring(matches[0].length)];

  return ['1', fullPhone];
};

/**
 * @param phone - phone number that may or may not include the country code
 * @param phoneCode - the country phone code
 * @returns a phone number in e164 format: +{phone code}{phone number}
 */

export const format = (phone: string, phoneCode?: string): string => {
  // if phone starts with a +, phone should be already in e164 format
  if (phone.startsWith('+')) return phone;

  // we may receive a complete full phone (code+phone), just without the + sign
  // try to split the phone received: if phone is a full phone, it should be equal to _code+_phone
  const [_code, _phone] = parse(phone);
  if (phone === `${_code}${_phone}`) return `+${phone}`;

  // if we have both phone and code, return them together appending the + sign
  if (phoneCode) return `+${phoneCode}${phone}`;

  // we should never reach this part: no phoneCode and phone isn't a full or e164 phone
  // we return the best guess, obtained from the parse function
  return `+${_code}${_phone}`;
};
