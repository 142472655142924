





















import type { PropType } from 'vue';
import { defineComponent, computed } from '@vue/composition-api';
import { useFavorites, useI18n, ROUTES } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import useModal from '@/shared/useModal';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@vf/composables/src/store/user';
import type { FavoritesButtonTranslations } from '@vf/api-contract';

export default defineComponent({
  name: 'FavoritesTopButton',
  props: {
    signInLink: {
      type: String,
      default: '',
    },
    backToShoppingLink: {
      type: String,
      default: '',
    },
    translations: {
      type: Object as PropType<FavoritesButtonTranslations>,
      default: () => ({
        backToShoppingLabel: 'Go back to shopping',
        signInText: 'Sign in to save',
        shareFavorites: 'Share your favorites',
      }),
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const userStore = useUserStore(root);
    const { loggedIn } = storeToRefs(userStore);
    const { favoriteId, favoritesCount, setShareLink } = useFavorites(root);
    const { openModal } = useModal();
    const { localePath } = useI18n(root);

    const handleFavoritesShare = async () => {
      await setShareLink();
      openModal({ type: 'fragment', resourceId: 'share-my-favorites-modal' });
    };

    const signIn = (event: Event) => {
      const isDataLink = props.signInLink.includes('data-id');

      if (isDataLink) {
        event.preventDefault();
        const resourceId = props.signInLink.replace(/\D/g, '');
        openModal({ type: 'lazyFragment', resourceId });
      }
    };

    const isShared = computed(
      () => root.$route.path === localePath(ROUTES.FAVORITES())
    );

    return {
      favoriteId,
      favoritesCount,
      loggedIn,
      openModal,
      handleFavoritesShare,
      isShared,
      signIn,
      props: {
        class: 'favorites-top-button',
        color: 'secondary',
        variant: 'border',
        size: 'md',
      },
    };
  },
});
