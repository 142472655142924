



















































import { PropType, computed, inject } from '@vue/composition-api';
import { ROUTES } from '@vf/composables';
import useModal from '@/shared/useModal';
import useRootInstance from '@/shared/useRootInstance';

export default {
  name: 'RichtextCtas',
  props: {
    title: {
      type: String as PropType<string>,
      default: '',
    },
    subtitle: {
      type: String as PropType<string>,
      default: '',
    },
    content: {
      type: String as PropType<string>,
      default: '',
    },
    links: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    ctaAlign: {
      type: String as PropType<'left' | 'center' | 'right'>,
      default: 'left',
    },
  },
  setup() {
    const { root } = useRootInstance();
    const { closeModal } = useModal();

    // TODO: GLOBAL15-61059 remove after redesign core
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');

    // TODO: remove this condition after GLOBAL15-29459
    const showButtons = computed(() => {
      return !root.$route.fullPath.includes(ROUTES.CHECKOUT_SHIPPING());
    });

    return {
      closeModal,
      showButtons,
      isCoreRedesignEnabled,
    };
  },
};
