






































































import type { PropType } from 'vue';
import {
  reactive,
  ref,
  onMounted,
  defineComponent,
} from '@vue/composition-api';
import { useCart, useAuthentication, useNotification } from '@vf/composables';
import type { ShareMyCartModalWindowTranslations } from '@vf/api-contract';
import useRootInstance from '@/shared/useRootInstance';
import useModal from '@/shared/useModal';

export default defineComponent({
  name: 'ShareMyCart',
  props: {
    translations: {
      type: Object as PropType<ShareMyCartModalWindowTranslations>,
      default: () => ({}),
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { closeModal } = useModal();
    const link = ref('');
    let notification = reactive({
      type: 'success',
      message: '',
      visible: false,
    });
    const { getShareLink, isSomeItemCustom } = useCart(root);
    const { getConsumerId } = useAuthentication(root);
    const { addNotification } = useNotification(root);

    const clipboardSuccessHandler = () => {
      /**
       * Mock error test
       */
      if (root.$route.query.copyError) {
        clipboardErrorHandler();
        return;
      }
      notification.type = 'success';
      notification.message = props.translations.copySuccessNotificationText;
      notification.visible = true;
    };

    const clipboardErrorHandler = () => {
      notification.type = 'danger';
      notification.message = props.translations.copyFailedNotificationText;
      notification.visible = true;
    };

    const onCloseModal = () => {
      closeModal();
    };

    onMounted(async () => {
      try {
        const response = await getShareLink(getConsumerId.value);
        link.value = response.data.cartShareUrl || '';
      } catch (error) {
        addNotification({
          message: 'Error',
          type: 'danger',
        });
      }
    });

    return {
      clipboardSuccessHandler,
      clipboardErrorHandler,
      notification,
      onCloseModal,
      link,
      isSomeItemCustom,
    };
  },
});
