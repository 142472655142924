



















































































































































































import {
  computed,
  defineComponent,
  ref,
  Ref,
  PropType,
} from '@vue/composition-api';
import type { BrandifyStoreInfo } from '@vf/api-client';
import { required } from 'vuelidate/lib/validators';
import useRootInstance from '@/shared/useRootInstance';
import { useFindInStore, useValidation } from '@vf/composables';
import { useCartStore } from '@vf/composables/src/store/cartStore';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

export default defineComponent({
  name: 'VfShippingDestinationsStoreSearch',
  components: {
    Toast: () => import('@/components/static/Toast.vue'),
  },
  props: {
    enableToast: {
      type: Boolean,
      default: true,
    },
    extraSearchParams: {
      type: Object,
      default: () => ({}),
    },
    productVariantId: String,
    searchInput: String,
    selectedStore: Object as PropType<BrandifyStoreInfo>,
    showAvailabilityCheckbox: {
      type: Boolean,
      default: true,
    },
    preselectStore: {
      type: Boolean,
      default: true,
    },
    showStoreAddress: {
      type: Boolean,
      default: true,
    },
    translations: {
      type: Object,
      default: () => ({
        noStores: '',
      }),
    },
    storesFilter: {
      type: Function as PropType<(store: BrandifyStoreInfo) => boolean>,
    },
  },
  emits: [
    'update:search-input',
    'set-store',
    'set-stores',
    'error-update-stores',
    'change-availability',
  ],
  setup(props, { emit }) {
    const { root } = useRootInstance();
    const { setValidation, $v } = useValidation(root, 'SEARCH_STORE_FORM');
    const {
      BRAND_ID_FIELD,
      errorResponseDetails,
      getProductAvailabilities,
      getStores,
      requestPendingFlag,
      resetData,
      stores: _,
    } = useFindInStore(root);
    const cart = useCartStore();
    const { isCoreRedesignEnabled } = useFeatureFlagsStore();

    const QTY_THRESHOLD = 10;
    const LIMIT_OF_VISIBLE_STORES = 5;
    const RANGE_DISTANCE = '200';
    const apiNoStores = ref('');
    const availableToday = ref(false);
    const refSearchInput = ref<{ $el: HTMLDivElement }>(null);
    const search = computed({
      get: () => {
        return props.searchInput;
      },
      set: (value) => {
        emit('update:search-input', value);
      },
    });
    const searchApiErrorMessage = ref('');
    const showToast = ref(false);
    const storeDetailsId = ref(null);
    const storeId = ref<null | string>(null);
    const stores: Ref<BrandifyStoreInfo[]> = ref([]);

    const setErrorMessage = (response: null | unknown) => {
      const responseCode =
        errorResponseDetails.value?.response.data.errorDetails[0]?.code || 0;
      if (response === null && responseCode === 8009) {
        searchApiErrorMessage.value = root.$t(
          'shippingDestinationsStoreSearch.locationNotRecognized'
        ) as string;
        $v.value.$touch();
      } else {
        apiNoStores.value = (
          props.translations.noStores ||
          root.$t('shippingDestinationsStoreSearch.noStores')
        ).replace('{distance}', RANGE_DISTANCE);
      }
    };

    const resetErrorMessage = () => {
      searchApiErrorMessage.value = '';
      apiNoStores.value = '';
    };

    const isSTS = ({ sts_enabled }: BrandifyStoreInfo) => sts_enabled === '1';
    const isBopis = ({ bopis_enabled, has_product }: BrandifyStoreInfo) =>
      has_product && bopis_enabled === '1';

    const checkProductAvailable = (store) => {
      if (props.storesFilter) {
        return props.storesFilter(store);
      }
      return isBopis(store) || isSTS(store);
    };

    const setStores = () => {
      if (availableToday.value) {
        const bopisStores = [];
        const stsStores = [];
        for (const item of _.value) {
          if (isBopis(item)) bopisStores.push(item);
          else if (isSTS(item)) stsStores.push(item);

          if (bopisStores.length === LIMIT_OF_VISIBLE_STORES) {
            stores.value = bopisStores;
            return;
          }
        }
        stores.value = bopisStores.concat(
          stsStores.slice(0, LIMIT_OF_VISIBLE_STORES - bopisStores.length)
        );
      } else {
        stores.value = _.value
          .filter(checkProductAvailable)
          .slice(0, LIMIT_OF_VISIBLE_STORES);
      }
      emit('set-stores', stores.value);
    };

    /**
     * return default Store if is present in stores
     * return first available store is we don't have default Store
     */
    const getPresetStore = () => {
      let findIndex = 0;
      if (props.selectedStore) {
        findIndex = stores.value.findIndex(
          (store) =>
            store[BRAND_ID_FIELD] === props.selectedStore[BRAND_ID_FIELD]
        );
      }
      return stores.value[findIndex];
    };

    const updateStores = async (params: Record<string, unknown>) => {
      storeDetailsId.value = null;
      cart.setCartLoading(true);
      const response = await getStores({
        ...params,
        ...props.extraSearchParams,
        productId: props.productVariantId,
        enhancedStoreSearch: true,
        distance: RANGE_DISTANCE,
        unitOfMeasure: 'mile',
        sortByAvailability: availableToday.value,
        resultSetSize: LIMIT_OF_VISIBLE_STORES,
      });
      setStores();
      if (stores.value.length) {
        if (props.preselectStore) setStore(getPresetStore(), true);
      } else {
        emit('error-update-stores');
        setErrorMessage(response);
      }

      cart.setCartLoading(false);
    };

    const setStore = (store, background = false) => {
      if (!store || storeId.value === store[BRAND_ID_FIELD]) return;
      storeId.value = store[BRAND_ID_FIELD];
      if (!background && props.enableToast) showToast.value = true;
      emit('set-store', store);
    };

    const focusOnInput = () => {
      refSearchInput.value.$el.querySelector('input').focus();
    };

    const searchByPC = async () => {
      $v.value.$touch();
      resetErrorMessage();

      if ($v.value.$invalid) {
        resetData();
        focusOnInput();
        return;
      }
      await updateStores({ postalCode: search.value });
    };

    const searchByGeolocation = ({
      onComplete,
      onError,
    }: {
      onComplete: (selectedStore?: BrandifyStoreInfo) => void;
      onError: () => void;
    }) => {
      cart.setCartLoading(true);
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          resetErrorMessage();
          await updateStores({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });

          cart.setCartLoading(false);
          onComplete();
        },
        () => {
          cart.setCartLoading(false);
          onError();
        }
      );
    };

    const changeAvailability = async () => {
      if (_.value.length === 0) return;
      try {
        cart.setCartLoading(true);
        const availabilityResponse = await getProductAvailabilities(
          _.value,
          props.productVariantId,
          {
            sortByAvailability: availableToday.value,
            resultSetSize: LIMIT_OF_VISIBLE_STORES,
          }
        );
        (availabilityResponse.data.storeInventory || []).forEach(
          ({ storeId, quantity = 0 }) => {
            const store = _.value.find(
              (item) => item[BRAND_ID_FIELD] === storeId
            );
            if (!store) return;
            store.has_product = quantity > 0;
            store.quantity = quantity;
          }
        );
        setStores();
        emit('change-availability', props.selectedStore);
      } catch (err) {
        root.$log.error(
          `[[@theme/components/static/pdp/ShippingDestinationsStoreSearch::changeAvailability]: Failed getProductAvailabilities of productId: ${props.productVariantId}`,
          err
        );
      } finally {
        cart.setCartLoading(false);
      }
    };

    const apiValidator = () => searchApiErrorMessage.value === '';

    const init = (preset: { stores: BrandifyStoreInfo[] }) => {
      stores.value = preset.stores;
      storeId.value = props.selectedStore?.[BRAND_ID_FIELD] || null;
    };

    return {
      apiNoStores,
      apiValidator,
      availableToday,
      BRAND_ID_FIELD,
      cart,
      changeAvailability,
      focusOnInput,
      init,
      isBopis,
      isCoreRedesignEnabled,
      isSTS,
      LIMIT_OF_VISIBLE_STORES,
      QTY_THRESHOLD,
      RANGE_DISTANCE,
      refSearchInput,
      requestPendingFlag,
      search,
      searchApiErrorMessage,
      searchByGeolocation,
      searchByPC,
      setStore,
      setValidation,
      showToast,
      storeDetailsId,
      storeId,
      stores,
    };
  },
  mounted() {
    this.setValidation(this.$v);
  },
  validations() {
    return {
      search: {
        required,
        apiValidator: this.apiValidator,
      },
    };
  },
});
