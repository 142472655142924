



























import type { PropType } from 'vue';
import type { BrandifyStoreInfo, CartLineItem } from '@vf/api-client';
import StockLabel from '@/components/static/checkout/pickup/storeselector/StockLabel.vue';
import { replaceAll } from '@/helpers/replaceAll';
import { defineComponent } from '@vue/composition-api';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'StoreOption',
  components: { StockLabel },
  props: {
    store: {
      type: Object as PropType<BrandifyStoreInfo>,
      required: true,
    },
    pickupItem: {
      type: Object as PropType<CartLineItem>,
      required: true,
    },
    value: String,
  },
  emits: ['input'],
  setup(props) {
    const { root } = useRootInstance();

    const formatDistance = (distance) =>
      replaceAll(root.$t('storeFinder.distanceLabel') as string, {
        distance,
      });

    const formatStock = (inventory: number, isStsEnabled: string) => {
      if (
        inventory >= props.pickupItem.qty ||
        (inventory === 0 && isStsEnabled === '1')
      ) {
        return {
          green: true,
          message: root.$t('storeFinder.inStock') as string,
        };
      } else if (inventory === 0) {
        return {
          green: false,
          message: root.$t('storeFinder.outOfStock') as string,
        };
      } else {
        return {
          green: false,
          message: replaceAll(root.$t('storeFinder.partialStock') as string, {
            stockItems: '' + inventory,
            allItems: '' + props.pickupItem.qty,
          }),
        };
      }
    };

    return {
      formatDistance,
      formatStock,
    };
  },
});
