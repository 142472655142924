var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"order-sidebar"},[(_vm.order)?_c('div',{staticClass:"order-sidebar__content-holder"},[_c('VfHeading',{staticClass:"order-sidebar__heading",attrs:{"level":5,"title":_vm.translations.orderSummaryLabel,"title-modifier":"title-5"}}),_vm._v(" "),_c('VfProperty',{staticClass:"order-sidebar__property",attrs:{"name":_vm.translations.itemSubtotalLabel.replace('{count}', _vm.itemsCount),"value":_vm.$formatPrice(_vm.order.totals.itemTotal, _vm.order.totals.currency)}}),_vm._v(" "),(_vm.loyaltyRewardsVouchers.length)?_vm._l((_vm.loyaltyRewardsVouchers),function(voucher,i){return _c('VfProperty',{key:voucher.additionalData.payment_instrument_id + i,staticClass:"order-sidebar__property",attrs:{"name":_vm.translations.appliedVouchersLabel,"value":'-' +
          _vm.$formatPrice(voucher.additionalData.amount, _vm.order.totals.currency)}})}):_vm._e(),_vm._v(" "),_vm._l((_vm.appliedPromotions),function(promotion,index){return _c('VfProperty',{key:promotion.id,staticClass:"order-sidebar__property order-sidebar__promotion",attrs:{"name":index === 0 ? _vm.translations.promotionLabel : '',"value":promotion.price,"description":promotion.description}})}),_vm._v(" "),(_vm.giftCards.length)?_vm._l((_vm.giftCards),function(giftCard){return _c('VfProperty',{key:giftCard.additionalData.payment_instrument_id,staticClass:"order-sidebar__property order-sidebar__gift-card",attrs:{"name":_vm.translations.giftCardLabel,"value":("-" + (_vm.$formatPrice(
          giftCard.additionalData.amount,
          _vm.order.totals.currency
        ))),"description":_vm.maskGiftCardNumber(giftCard.additionalData.gcCardNumber)}})}):_vm._e(),_vm._v(" "),(_vm.rewards.length)?_vm._l((_vm.rewards),function(reward){return _c('VfProperty',{key:reward.additionalData.payment_instrument_id,staticClass:"order-sidebar__property order-sidebar__reward-card",attrs:{"name":_vm.getRewardCycleText(reward),"value":("-" + (_vm.$formatPrice(
          reward.additionalData.amount,
          _vm.order.totals.currency
        ))),"description":_vm.areRewardCodesEnabled
            ? undefined
            : _vm.maskGiftCardNumber(reward.c_gcCardNumber)}})}):_vm._e(),_vm._v(" "),(_vm.athleteDiscount.length)?[_c('VfProperty',{staticClass:"order-sidebar__property order-sidebar__athlete-discount",attrs:{"name":_vm.translations.athleteDiscount,"value":("-" + (_vm.$formatPrice(
          _vm.athleteDiscount[0].additionalData.amount,
          _vm.order.totals.currency
        )))}})]:_vm._e(),_vm._v(" "),_vm._l((_vm.orderSummaryShipments),function(shipment){return _c('VfProperty',{key:shipment.shipmentId,staticClass:"order-sidebar__property order-sidebar__shipment",attrs:{"name":_vm.isPickupOrSts(shipment.code)
          ? _vm.translations.pickup
          : _vm.translations.shippingLabel,"value":_vm.getShippingCost({
          amount: shipment.price.amount,
          currency: shipment.price.currency,
        }),"description":!_vm.isPickupOrSts(shipment.code)
          ? _vm.theme.formatShippingMethodText({
              label: shipment.label,
              deliveryTime: shipment.deliveryTime,
            })
          : '',"tooltip":_vm.shippingTooltip[shipment.code] || _vm.translations.shippingTooltip}})}),_vm._v(" "),(_vm.theme.showDivider)?_c('VfDivider',{staticClass:"order-sidebar__divider"}):_vm._e(),_vm._v(" "),_c('VfProperty',{staticClass:"order-sidebar__property order-sidebar__tax",attrs:{"name":_vm.translations.taxLabel,"value":_vm.$formatPrice(_vm.order.totals.tax, _vm.order.totals.currency),"tooltip":_vm.translations.taxTooltip}}),_vm._v(" "),(_vm.theme.showDivider)?_c('VfDivider',{staticClass:"order-sidebar__divider"}):_vm._e(),_vm._v(" "),(!!_vm.enforcedShippingFeeValue)?_c('VfProperty',{staticClass:"order-sidebar__property order-sidebar__enforced-shipping-fee",attrs:{"name":_vm.enforcedShippingFeeLabels.label,"info":_vm.enforcedShippingFeeLabels.description,"value":_vm.enforcedShippingFee,"translations":_vm.translations,"tooltip":_vm.enforcedShippingFeeLabels.tooltip,"is-tooltip-rich-text":true}}):_vm._e(),_vm._v(" "),(_vm.enforcedShippingFeeValue && _vm.theme.showDivider)?_c('VfDivider',{staticClass:"order-sidebar__divider"}):_vm._e(),_vm._v(" "),_c('VfProperty',{staticClass:"order-sidebar__property order-sidebar__order-total",attrs:{"name":_vm.translations.orderTotalLabel,"value":_vm.$formatPrice(_vm.orderTotal, _vm.order.totals.currency)}}),_vm._v(" "),_c('div',{staticClass:"order-sidebar__buttons"},[_c('VfButton',{staticClass:"order-sidebar__print-button vf-button--fullWidth",attrs:{"aria-label":_vm.translations.printButtonLabel},on:{"click":_vm.handlePrint}},[_vm._v("\n        "+_vm._s(_vm.translations.printButtonLabel)+"\n      ")]),_vm._v(" "),_c('VfButton',{staticClass:"order-sidebar__continue-shopping-button vf-button--text vf-button--fullWidth",attrs:{"aria-label":_vm.translations.continueShoppingLabel},on:{"click":_vm.continueShopping}},[_vm._v("\n        "+_vm._s(_vm.translations.continueShoppingLabel)+"\n      ")])],1)],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }