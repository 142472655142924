

























































import type { PropType } from 'vue';
import { defineComponent } from '@vue/composition-api';
import type { PaymentMethod } from '@vf/api-contract';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';

export default defineComponent({
  name: 'PaymentMethod',
  serverCacheKey: getCacheKeyFromProps,
  props: {
    /** Payment method type object */
    method: {
      type: Object as PropType<PaymentMethod>,
      required: true,
    },
    /** Payment method radio button value  */
    value: {
      type: [Number, String],
      default: '',
    },
    /** Payment method icon  */
    icon: {
      type: String,
      default: '',
    },
    /** Payment method image  */
    image: {
      type: String,
      default: '',
    },
    hasTooltip: {
      type: Boolean,
      default: false,
    },
    tooltipData: {
      type: String,
      default: '',
    },
    tooltipAriaLabel: {
      type: String,
      default: '',
    },
    disabled: Boolean,
  },
  data() {
    return {
      showTooltip: false,
    };
  },
  methods: {
    toogleTooltip() {
      return (this.showTooltip = !this.showTooltip);
    },
  },
});
