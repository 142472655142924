


































































































import type { PropType } from 'vue';
import { defineComponent } from '@vue/composition-api';
import type {
  ShopByGridGridBlocks,
  ShopByGridGridTitleTeaserStyles,
} from '@vf/api-contract';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';
import { AnalyticsDataDomLocation } from '@/types';
import useModal from '@/shared/useModal';

export default defineComponent({
  name: 'ShopByGrid',
  serverCacheKey: getCacheKeyFromProps,
  props: {
    title: {
      type: String,
      default: '',
    },
    /** Shop by grid elements */
    blocks: {
      type: Array as PropType<ShopByGridGridBlocks>,
      default: () => [],
    },
    /** Shop by grid heading teaser style */
    titleStyles: {
      type: Object as PropType<ShopByGridGridTitleTeaserStyles>,
      default: () => ({
        titleColor: '',
        titleFontSize: '',
        titleFontWeight: '',
        titleFontFamily: '',
        titleBackgroundColor: '',
        headingLevel: '',
        titleClassModifiers: '',
      }),
    },
  },
  setup() {
    return useModal();
  },
  data() {
    return { AnalyticsDataDomLocation };
  },
  methods: {
    getBackgroundImage(block) {
      return !this.$isServer && this.$viewport.size === 'small'
        ? ''
        : block.backgroundUrl.medium;
    },
    handleLinkClick(data, opts) {
      if (opts.openInNewModal) {
        this.openModal(this.getModalSettingsFromLink(data.link));
      } else if (opts.openInNewTab) {
        window.open(data.link, '_blank');
      } else {
        this.$root.$router.push(data.link);
      }
    },
  },
});
