






































import { defineComponent, inject } from '@vue/composition-api';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'KlarnaModal',
  serverCacheKey: getCacheKeyFromProps,
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    translations: {
      type: Object,
      required: true,
    },
    installment: {
      type: String,
      required: true,
    },
    isInThreshold: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { root } = useRootInstance();

    const onCloseModal = () => {
      emit('close');
    };

    const {
      payments,
    }: {
      payments: number;
    } = root.$getEnvValueByCurrentLocale('KLARNA_CONFIG');

    const klarnaTextWithIcon = props.translations.modal.listItem?.map(
      (item) => {
        return item
          .replace(
            '{{klarnaIconRed}}',
            '<div class="klarnaIconRed" role="img" aria-label="Klarna"></div>'
          )
          .replace('{{payments}}', payments)
          .replace('{{remaining}}', payments - 1);
      }
    );

    const modalTitle = (
      (props.isInThreshold
        ? props.translations.modal?.title?.inThresholdLabel
        : props.translations.modal?.title?.belowThresholdLabel) ?? ''
    )
      .replace('{{payments}}', payments)
      .replace('{{amount}}', props.installment);

    const modalSubtitle = props.translations.modal?.subtitle?.replace(
      '{{payments}}',
      props.translations.payments
    );

    const disclaimer = props.translations.modal.disclaimerText?.replace(
      '{{payments}}',
      payments
    );

    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');

    return {
      onCloseModal,
      klarnaTextWithIcon,
      modalTitle,
      modalSubtitle,
      disclaimer,
      isCoreRedesignEnabled,
    };
  },
});
