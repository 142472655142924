
















































































import { defineComponent } from '@vue/composition-api';
import { useSectionNumeration, useProductInventory } from '@vf/composables';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import useRootInstance from '@/shared/useRootInstance';
import CmsProps from './CmsProps';
import { setColorLabel } from './utils';

const extractValue = (props, type: 'label' | 'value', separator = ' - ') => {
  const { colorLabel } = props.translations;
  return props[type]
    ? `${colorLabel}${separator}${setColorLabel(
        props[type],
        props.showFullLabel
      )}`
    : colorLabel;
};

export default defineComponent({
  name: 'ProductColors',
  props: {
    value: {
      type: String,
      required: true,
    },
    selectedValueLoading: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    colors: {
      type: Array,
      required: true,
    },
    split: Boolean,
    lazy: {
      type: Boolean,
      default: false,
    },
    /** Will show a skeleton loading animation while it's true */
    loading: {
      type: Boolean,
      default: false,
    },
    /** Flag to determine if product is displayed in swap cart item modal */
    isSwapCartItemModal: {
      type: Boolean,
      default: false,
    },
    ...CmsProps,
  },
  emits: ['input'],
  setup() {
    const { root } = useRootInstance();
    const { getSectionNumber } = useSectionNumeration(root);
    const { inventoryLoading } = useProductInventory(root);
    const { isVansPdpRedesignEnabled } = useFeatureFlagsStore(); // TODO: GLOBAL15-63801 clean up

    return {
      getSectionNumber,
      inventoryLoading,
      isVansPdpRedesignEnabled,
    };
  },
  computed: {
    color() {
      return {
        value: extractValue(this.$props, 'value'),
        label: extractValue(
          this.$props,
          'label',
          this.isVansPdpRedesignEnabled ? ': ' : undefined
        ),
      };
    },
    title() {
      const title = this.color.label;
      if (this.isVansPdpRedesignEnabled) {
        return title;
      }

      const prefixLabel = this.getSectionNumber(
        this.$attrs['data-id'] as string
      );

      return this.$props.showNumeration && prefixLabel
        ? `${prefixLabel}. ${title}`
        : title;
    },
    heading() {
      if (this.$props.isSwapCartItemModal) {
        return this.color.value;
      }

      return this.$props.translations.chooseColor;
    },
    placholderAspectRatio() {
      return {
        'aspect-ratio': `${this.thumbnailWidth} / ${this.thumbnailHeight}`,
      };
    },
    swatchCmsProps() {
      return {
        showAllColors: this.$props.showAllColors,
        showAlwaysAllColors: this.$props.showAlwaysAllColors,
        showSolidColors: this.$props.showSolidColors,
        itemsCountSmall: this.$props.itemsCountSmall,
        itemsCountMedium: this.$props.itemsCountMedium,
        itemsCountLarge: this.$props.itemsCountLarge,
        showDivider: this.$props.showDivider,
      };
    },
    theme() {
      // TODO: GLOBAL15-63801 clean up and change on config
      return this.isVansPdpRedesignEnabled
        ? {
            ...this.$root.$themeConfig.productColors,
            thumbnailWidth: this.$viewport.isSmall ? 80 : 57,
            thumbnailHeight: this.$viewport.isSmall ? 101 : 72,
          }
        : this.$root.$themeConfig.productColors;
    },
    headingModifier() {
      return this.theme?.headingModifier || 'subtitle-6';
    },
    thumbnailHeight() {
      return this.theme?.thumbnailHeight || 50;
    },
    thumbnailWidth() {
      return this.theme?.thumbnailWidth || 50;
    },
  },
  methods: {
    getFormattedPrice(price, colors, original = false) {
      if (!price || !colors || !colors.length) {
        return;
      }

      const color = colors[0];

      if (!original) {
        return this.$root.$formatPrice(price, color.price.currency);
      }

      if (color && color.price.original !== color.price.current) {
        return this.$root.$formatPrice(
          color.price.original,
          color.price.currency
        );
      }
    },
  },
});
