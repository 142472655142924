





























import type { PropType } from 'vue';
import { defineComponent, computed } from '@vue/composition-api';
import type {
  HeadingTeaserStyles,
  ReturnHeadingTranslations,
} from '@vf/api-contract';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';
import { useReturns } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'ReturnHeading',
  serverCacheKey: getCacheKeyFromProps,
  props: {
    /** Class modifier for title styling TBD */
    titleModifier: {
      type: [String],
      default: '',
    },
    /** Class modifier for title styling TBD */
    subtitleModifier: {
      type: [String],
      default: '',
    },
    /** Heading Teaser Styles Customizations */
    styles: {
      type: Object as PropType<HeadingTeaserStyles>,
      default: () => ({}),
    },
    translations: {
      type: Object as PropType<ReturnHeadingTranslations>,
      default: () => ({}),
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { returnStep } = useReturns(root);
    const title = computed(() => props.translations.titles[returnStep.value]);
    return {
      title,
      returnStep,
    };
  },
});
