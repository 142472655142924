















































import type { PropType } from 'vue';
import { defineComponent, ref } from '@vue/composition-api';
import type { Reward } from '@vf/composables/src/store/rewards';
import type { CheckoutRewardCardTranslations } from '@vf/api-contract';
import useRootInstance from '@/shared/useRootInstance';
import { getDaysBetweenCurrentUTCDate } from '@vf/shared/src/utils/helpers';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import { useRewardCycleTranslations } from '@vf/composables';

export default defineComponent({
  name: 'CheckoutRewardNew',
  props: {
    reward: {
      type: Object as PropType<Reward>,
      required: true,
    },
    translations: {
      type: Object as PropType<CheckoutRewardCardTranslations>,
      required: true,
    },
  },
  setup(props) {
    const { isCheckoutRedesignEnabled } = useFeatureFlagsStore();
    const { root } = useRootInstance();

    // TODO: GLOBAL15-56318 remove this conditional
    // rename variable to translation because now we can't have the same name as in prop
    const translation = isCheckoutRedesignEnabled
      ? {
          issuedSummer: root.$t('checkoutReward.issuedSummer') as string,
          issuedWinter: root.$t('checkoutReward.issuedWinter') as string,
          issuedSpring: root.$t('checkoutReward.issuedSpring') as string,
          legacyIssuedText: root.$t(
            'checkoutReward.legacyIssuedText'
          ) as string,
          expires: root.$t('checkoutReward.expires') as string,
        }
      : props.translations;

    const showTooltip = ref(false);

    const { getRewardCycleText } = useRewardCycleTranslations({
      summer: translation.issuedSummer,
      winter: translation.issuedWinter,
      spring: translation.issuedSpring,
    });

    const formatExpirationDate = (reward: Reward) => {
      const locale = root.$i18n.locale;
      const daysBetween = getDaysBetweenCurrentUTCDate(reward.expireDateTime);
      const maxDaysForShortDate = 30;

      if (daysBetween <= maxDaysForShortDate) {
        const rtf = new Intl.RelativeTimeFormat(locale, {
          numeric: 'auto',
        });
        return `${translation.expires} ${rtf.format(daysBetween, 'day')}`;
      } else {
        const formattedDate = Intl.DateTimeFormat(locale).format(
          new Date(reward.expireDateTime)
        );
        return `${translation.expires} ${formattedDate}`;
      }
    };

    return {
      props,
      translation,
      getRewardCycleText,
      formatExpirationDate,
      showTooltip,
    };
  },
});
