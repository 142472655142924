


























import { defineComponent, ref } from '@vue/composition-api';
import useModal from '@/shared/useModal';
import useRootInstance from '@/shared/useRootInstance';
import { cmsCommonConfigRoutes } from '@vf/composables/src/utils/cmsCommonConfigRoutes';

export default defineComponent({
  name: 'LoyaltyGuest',
  setup() {
    const { root } = useRootInstance();
    const { openModal } = useModal();
    const theme = root.$themeConfig.loyaltyOrderRegister;

    const { signInLink } = cmsCommonConfigRoutes(root);

    const signUpVisible = ref(true);

    const openLoginModal = () =>
      openModal({
        type: 'lazyFragment',
        resourceId: signInLink.id,
      });

    return {
      openLoginModal,
      signUpVisible,
      openModal,
      theme,
    };
  },
});
