import {
  CheckoutSidebarDividersConfigType,
  CheckoutSidebarDividersConfigSetType,
} from '../../theme/types/CheckoutSidebar';

export const defaultCheckoutSidebarDividers: CheckoutSidebarDividersConfigType = {
  orderSummaryBottom: true,
};

export const getCheckoutSidebarDividersConfig = (
  componentContext: 'cart' | 'shipping' | 'payment',
  themeDevidersConfig?: CheckoutSidebarDividersConfigSetType
): CheckoutSidebarDividersConfigType => {
  if (themeDevidersConfig === undefined) {
    return defaultCheckoutSidebarDividers;
  }

  if (
    themeDevidersConfig === null ||
    themeDevidersConfig?.[componentContext] === null
  ) {
    return {};
  }

  return (
    themeDevidersConfig[componentContext] || themeDevidersConfig.default || {}
  );
};
