























import type { PropType } from 'vue';
import {
  computed,
  ref,
  onMounted,
  defineComponent,
  onUnmounted,
} from '@vue/composition-api';
import type { ForgotPasswordTranslations } from '@vf/api-contract';
import {
  ROUTES,
  useAccount,
  useI18n,
  useNotification,
  useReCaptcha,
} from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'ForgotPassword',
  props: {
    translations: {
      type: Object as PropType<ForgotPasswordTranslations>,
      default: () => ({}),
    },
    /** Component variant to display */
    componentVariant: {
      type: String,
      default: 'VARIANT_A',
    },
  },
  setup(props, { emit }) {
    const { root } = useRootInstance();

    const account = useAccount(root);
    const {
      addNotification,
      clearNotifications,
      showChangePassLinkExpiredNotification,
      setChangePassLinkExpiredNotification,
    } = useNotification(root);
    const { localePath } = useI18n(root);
    const reCaptcha = useReCaptcha(root);
    const getComponentVariant = computed(() => {
      return () =>
        import(
          `@vf/ui/components/ForgotPassword/Organism.ForgotPassword_${props.componentVariant}.vue`
        );
    });
    const resetButtonDisabled = ref(false);

    const forgotPassword = async (data) => {
      clearNotifications();
      resetButtonDisabled.value = true;
      try {
        const recaptchaToken = await reCaptcha.executeRecaptcha(
          'resetPassword'
        );
        const response = await account.forgotPassword({
          login: data.email,
          recaptchaToken,
        });
        if (response) {
          addNotification({
            message: props.translations.successReset,
            type: 'success',
          });
          root.$emit('clear-forgot-password-email');
        }
      } catch (error) {
        console.error(error);
        addNotification({
          message: props.translations.errorReset,
          type: 'danger',
        });
      } finally {
        emit('close-modal');
        resetButtonDisabled.value = false;
      }
    };

    onMounted(() => {
      reCaptcha.showBadge();

      if (!showChangePassLinkExpiredNotification.value) return;

      addNotification({
        message: props.translations.expiredLinkMessage,
        type: 'danger',
      });
      setChangePassLinkExpiredNotification(false);
    });

    onUnmounted(() => reCaptcha.hideBadge());

    return {
      getComponentVariant,
      forgotPassword,
      resetButtonDisabled,
      signInLink: localePath(ROUTES.SIGN_IN()),
    };
  },
});
