import { ref, watch } from '@vue/composition-api';
import { scrollTo } from '@vf/shared/src/utils/helpers';

/**
 * DO NOT forget to call setCmsLinksRef method to register component's ref
 * DO NOT forget to call unsetCmsLinks on umMounted hook
 *
 */

const addScrollTo = (targetEl) => (e) => {
  e.stopImmediatePropagation();
  e.preventDefault();
  e.stopPropagation();

  scrollTo({ top: targetEl.offsetTop });
};

export const useCmsLinks = (instance, modalInstance) => {
  const cmsLinksRef = ref(null);
  const cmsLinks = ref([]);

  const openModalOnClick = (target) => (e) => {
    e.stopImmediatePropagation();
    e.preventDefault();
    const resourceId = target?.split('/').pop();
    resourceId &&
      modalInstance.openModal({
        type: 'lazyFragment',
        resourceId,
      });
  };

  const prepareCmsLinks = () => {
    if (!cmsLinksRef.value?.$el) return;

    // Create array from StaticNodeList because it is not iterable
    try {
      cmsLinks.value = Array.from(cmsLinksRef.value.$el.querySelectorAll('a'))
        .map((el: HTMLElement) => {
          // Open as Modal
          if (el.getAttribute('data-show') === 'modal') {
            const target = el.getAttribute('data-href');
            if (target) {
              !el.getAttribute('href') && el.setAttribute('href', '#'); // href attribute is required to be accessible
              return [el, openModalOnClick(target)];
            }
            return;
          }
          // Scroll to component
          const target = el.getAttribute('href');
          if (!target || !target.startsWith('[data-id=')) return undefined;
          return [el, addScrollTo(el)];
        })
        .filter((el) => !!el);
    } catch (error) {
      instance.$log.error(
        `[@composables/useCmsLinks/index.ts::prepareCmsLinks] Cannot map over mixin_powerLinksRef a elements`,
        { error }
      );
    }
  };

  const setCmsLinksRef = (value) => {
    cmsLinksRef.value = value;
  };

  const unsetCmsLinks = () => {
    cmsLinks.value.forEach(([link, listener]) => {
      link.removeEventListener('click', listener);
    });
  };

  const setCmsLinks = () => {
    cmsLinks.value.forEach(([link, listener]) => {
      link.addEventListener('click', listener);
    });
  };

  watch(cmsLinksRef, () => {
    unsetCmsLinks();
    prepareCmsLinks();
    setCmsLinks();
  });

  return {
    setCmsLinksRef,
    unsetCmsLinks,
  };
};
