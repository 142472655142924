
import {
  computed,
  defineComponent,
  onMounted,
  watch,
} from '@vue/composition-api';
import { ssrRef, useAsync } from '@nuxtjs/composition-api';
import useRootInstance from '@/shared/useRootInstance';
import { finalContentRenderer } from '@/components/cms/cmsUtils';
import { useCms, useProduct } from '@vf/composables';
import { PageTypeName } from '@vf/composables/src/useCms/types';
import { extractProperty } from '@vf/composables/src/useCms/mappings/utils';
import { useCmsRefStore } from '@vf/composables/src/store/cmsRef';

interface PriorityAttribute {
  attribute: string;
  componentId: string;
}

export default defineComponent({
  name: 'VfPdpAttribute',
  props: {
    contextKey: {
      type: String,
      default: 'page-content',
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { product } = useProduct(root, props.contextKey);
    const { getFragmentAndSanitize, headerConfig, setup } = useCms(
      root,
      props.contextKey
    );
    const cmsRefStore = useCmsRefStore();
    const contentAttributes = ssrRef<Record<string, any[]>>(
      {},
      'content-pdp-attributes'
    );
    const priorityAttributes: PriorityAttribute[] = extractProperty(
      cmsRefStore.cmsSiteConfiguration.commonConfig,
      'pdp-attributes.attributes',
      []
    );

    const getPriorityComponents = (techAttrs: string[] = []) => {
      const limit = 2;
      const priorityComponentId: string[] = [];
      const quickFindTechAttrs: Record<string, boolean> = {};

      techAttrs.forEach((techAttr) => (quickFindTechAttrs[techAttr] = true));

      for (let i = 0; i < priorityAttributes.length; i++) {
        if (quickFindTechAttrs[priorityAttributes[i].attribute]) {
          priorityComponentId.push(priorityAttributes[i].componentId);
        }
        if (priorityComponentId.length >= limit) break;
      }

      return priorityComponentId;
    };

    const priorityComponentId = computed(() =>
      product.value?.technology
        ? getPriorityComponents(product.value.technology)
        : []
    );

    const priorityKeys = computed(() => priorityComponentId.value.join('_'));

    const loadContents = async (contentsId: string[]) => {
      if (contentsId.length === 0) return [];
      if (contentAttributes.value[priorityKeys.value]) {
        return contentAttributes.value[priorityKeys.value];
      }
      try {
        await setup({ forcePreview: root.$isPreview });
        contentAttributes.value = {
          [priorityKeys.value]: await Promise.all(
            contentsId.map((contentId) =>
              getFragmentAndSanitize(contentId, PageTypeName.CONTENT)
            )
          ),
        };
        return contentAttributes.value[priorityKeys.value];
      } catch (err) {
        root.$log.error(
          '[@ContentAttributes.vue::loadContent] failed retrieve content information',
          err
        );
      }
      return [];
    };

    onMounted(() => {
      watch(priorityComponentId, () => loadContents(priorityComponentId.value));
    });

    useAsync(async () => {
      if (priorityComponentId.value) {
        await loadContents(priorityComponentId.value);
      }
    });

    return {
      priorityKeys,
      contentAttributes,
      headerConfig,
    };
  },

  render(createElement) {
    if (this.contentAttributes[this.priorityKeys]?.length) {
      return createElement(
        'div',
        { class: 'vf-pdp-attribute' },
        this.contentAttributes[this.priorityKeys].map((content) =>
          finalContentRenderer(
            createElement,
            'dynamic-content',
            this.contextKey,
            [content.data],
            content.errors,
            this.$root,
            [],
            this.headerConfig
          )
        )
      );
    }

    return null;
  },
});
