
















import type { PropType } from 'vue';
import { defineComponent } from '@vue/composition-api';
import {
  ApplePayTranslations,
  Context,
  MinicartTranslations,
} from '@vf/api-contract';
import FloatingCartButton from './FloatingCartButton.vue';
import MinicartSidebar from './MinicartSidebar.vue';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

export default defineComponent({
  components: {
    FloatingCartButton,
    MinicartSidebar,
  },
  props: {
    translations: {
      type: Object as PropType<MinicartTranslations>,
      required: true,
    },
    bottomImage: {
      type: Object,
      default: null,
    },
    applePayTranslations: {
      type: Object as PropType<ApplePayTranslations>,
      required: true,
    },
    showApplePayComponent: {
      type: Boolean,
      default: false,
    },
    showExpressCheckoutButtons: {
      type: Boolean,
      default: true,
    },
    contextKey: {
      type: String as PropType<Context>,
    },
  },
  setup() {
    const { isCheckoutRedesignEnabled } = useFeatureFlagsStore();
    return { isCheckoutRedesignEnabled };
  },
  data() {
    return {
      showCartButton: true,
    };
  },
  mounted() {
    this.showCartButton = this.contextKey !== Context.Modal;
  },
});
