var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vf-promo-code"},[_c('VfCollapsible',{staticClass:"vf-promo-code__accordion",attrs:{"header":_vm.$t('promoCodeSidebar.header')}},[_c('div',{staticClass:"vf-promo-code-wrapper"},[_c('VfInput',{staticClass:"vf-promo-code__input",attrs:{"label":_vm.$t('promoCodeSidebar.label'),"name":"promo-code","error-message":_vm.errorMessage,"valid":!_vm.errorMessage},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addCouponCode(_vm.promoCode)}},model:{value:(_vm.promoCode),callback:function ($$v) {_vm.promoCode=$$v},expression:"promoCode"}}),_vm._v(" "),(_vm.filteredPromotions.applied.length)?_c('div',{staticClass:"vf-promo-code__chip_container"},_vm._l((_vm.filteredPromotions.applied),function(ref){
var code = ref.code;
var couponItemId = ref.couponItemId;
return _c('button',{key:couponItemId,staticClass:"vf-promo-code__chip",attrs:{"aria-label":_vm.$t('promoCodeSidebar.close'),"disabled":_vm.isApiCallInProgress},on:{"click":function($event){return _vm.removeCouponCode(couponItemId)}}},[_c('span',[_vm._v(_vm._s(code))]),_vm._v(" "),_c('span',{staticClass:"vf-promo-code__chip_remove"},[_c('VfIcon',{attrs:{"size":"12px","icon":"closeOutline"}})],1)])}),0):_vm._e(),_vm._v(" "),(_vm.filteredPromotions.others.length)?_c('div',[_c('p',{staticClass:"vf-promo-code__chip_header"},[_vm._v("\n          "+_vm._s(_vm.$t(
              _vm.pluralize(
                'promoCodeSidebar.notAppliedCoupon',
                _vm.filteredPromotions.others.length
              )
            ))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"vf-promo-code__chip_container"},_vm._l((_vm.filteredPromotions.others),function(ref){
            var code = ref.code;
            var couponItemId = ref.couponItemId;
return _c('button',{key:couponItemId,staticClass:"vf-promo-code__chip",attrs:{"aria-label":_vm.$t('promoCodeSidebar.close'),"disabled":_vm.isApiCallInProgress},on:{"click":function($event){return _vm.removeCouponCode(couponItemId)}}},[_c('span',[_vm._v(_vm._s(code))]),_vm._v(" "),_c('span',{staticClass:"vf-promo-code__chip_remove"},[_c('VfIcon',{attrs:{"size":"12px","icon":"closeOutline"}})],1)])}),0)]):_vm._e(),_vm._v(" "),_c('ThemeButton',{staticClass:"vf-promo-code__button",attrs:{"size":"full","disabled":!_vm.promoCode || _vm.isApiCallInProgress,"color":"primary","aria-label":_vm.$t('promoCodeSidebar.apply')},on:{"click":function($event){return _vm.addCouponCode(_vm.promoCode)}}},[_vm._v("\n        "+_vm._s(_vm.$t('promoCodeSidebar.apply'))+"\n      ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }