








import { defineComponent } from '@vue/composition-api';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'Campaign',
  props: {
    /**
     * Collection of campaigns to choose from based on campaign ID
     */
    campaigns: {
      type: Object,
      default: () => ({}),
    },
    /**
     * Campaign ID Query Param Name
     */
    campaignIdQueryParamName: {
      type: String,
      default: 'loyaltyCampaignId',
    },
    /**
     * Default Campaign ID if nothing is passed as querystring
     */
    campaignId: {
      type: String,
      default: 'default',
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const campaignId =
      (root.$route.query[props.campaignIdQueryParamName] as string) ??
      props.campaignId;

    const campaign = props.campaigns[campaignId];

    if (campaign) {
      const componentToRender = () =>
        import(`@vf/theme/components/smart/${campaign.component}`);

      return {
        componentToRender,
        componentProps: campaign.props,
        componentAttrs: campaign.attrs,
      };
    } else {
      console.error('No matching component with the campaign Id', campaignId);
      return {
        componentToRender: null,
        componentProps: {},
        componentAttrs: null,
      };
    }
  },
});
