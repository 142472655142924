





























































import type { PropType } from 'vue';
import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import type {
  CheckoutRewardCardTranslations,
  RewardCard,
} from '@vf/api-contract';
import {
  useRewardStore,
  useCart,
  useCheckout,
  useNotification,
  useGiftCards,
} from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import { storeToRefs } from 'pinia';
import CheckoutRewardOld from './variants/CheckoutRewardOld.vue';

export default defineComponent({
  name: 'CheckoutRewardCard',
  components: { CheckoutRewardOld },
  props: {
    translations: {
      type: Object as PropType<CheckoutRewardCardTranslations>,
      required: true,
    },
    /** Maximum gift/reward cards possible to add per order */
    maxGiftCards: {
      type: Number,
      default: 2,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const rewardStore = useRewardStore(root);
    const { appliedRewards, appliedGiftCards, hasItems } = useCart(root);
    const { isZeroOrder } = useCheckout(root);
    const {
      addNotification,
      clearNotifications,
      notifications,
    } = useNotification(root);
    const {
      removeGiftCard,
      applyRewardCard,
      appliedRewardCards: appliedRewardCardsStore,
      applyRewardCardNumber: applyRewardCardStore,
      removeRewardCard,
    } = useGiftCards(root);
    const { rewards } = storeToRefs(rewardStore);

    const isAccordionOpened = ref(true);

    const isVisible = computed(
      () => hasItems.value && !isZeroOrder.value && rewards.value.length > 0
    );

    const isMaxCardsAppliedMessageVisible = computed(
      () =>
        appliedRewards.value.length + appliedGiftCards.value.length >=
        props.maxGiftCards
    );

    const appliedGiftAndRewardCards = computed(() => [
      ...appliedGiftCards.value,
      ...appliedRewards.value,
    ]);

    const onClickApplyRewardCard = async (card: RewardCard) => {
      const response = await applyRewardCard(card);

      applyRewardCardStore(card);

      if ([200, 201].includes(response?.status)) {
        clearNotifications();
        addNotification({
          message: isMaxCardsAppliedMessageVisible.value
            ? props.translations.maxCardsApplied
            : props.translations.applySuccessMessage,
          type: 'success',
          context: 'checkout-reward-card',
        });
      }
    };

    const onClickRemoveRewardCard = async (reward) => {
      const data = {
        paymentId: findAppliedReward(reward.id).payment_instrument_id,
        method: reward.paymentMethodId,
      };
      const response = await removeGiftCard(data);

      removeRewardCard(reward);

      if ([200, 201].includes(response?.status)) {
        clearNotifications();
        addNotification({
          message: props.translations.removeSuccessMessage,
          type: 'success',
          context: 'checkout-reward-card',
        });
      }
    };

    const findAppliedReward = (rewardId: string) => {
      return appliedRewards.value.find((appliedCard) =>
        [
          appliedCard.customerPaymentInstrumentId,
          appliedCard.c_gcCardNumber,
        ].includes(rewardId)
      );
    };

    const isRewardApplied = (reward) => !!findAppliedReward(reward.id);

    const isApplyCardBtnDisabled = (reward): boolean =>
      isRewardApplied(reward) ||
      appliedGiftAndRewardCards.value.length >= props.maxGiftCards ||
      !reward.balance;

    const rewardCardNotification = computed(() =>
      notifications.value.find((n) => n.context === 'checkout-reward-card')
    );

    watch(rewardCardNotification, () => {
      isAccordionOpened.value = true;
    });

    return {
      appliedRewardCardsStore,
      isVisible,
      isAccordionOpened,
      isMaxCardsAppliedMessageVisible,
      rewards,
      rewardCardNotification,
      clearNotifications,
      onClickApplyRewardCard,
      onClickRemoveRewardCard,
      isRewardApplied,
      isApplyCardBtnDisabled,
    };
  },
});
