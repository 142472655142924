






























































import { computed, defineComponent, PropType } from '@vue/composition-api';
import useModal from '@/shared/useModal';
import useRootInstance from '@/shared/useRootInstance';
import { useUserStore } from '@vf/composables/src/store/user';
import { useCheckoutStore } from '@vf/composables/src/store/checkoutStore';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import { storeToRefs } from 'pinia';

const POINTS_PER_DOLLAR = 1;

type ExperienceSettings = {
  title: string;
  subtitle: string;
  banner: { src: string; seo: any } | null;
  ctaText: string;
  ctaTarget: string;
  ptsExplanation?: string;
};

export default defineComponent({
  name: 'LoyaltyOrderRegister',
  props: {
    guest: {
      type: Object as PropType<ExperienceSettings>,
      required: true,
    },
    legacy: {
      type: Object as PropType<ExperienceSettings>,
      required: true,
    },
    member: {
      type: Object as PropType<ExperienceSettings>,
      required: true,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const userStore = useUserStore(root);
    const { loyaltyEnrolled, loggedIn } = storeToRefs(userStore);
    const { order, rewards } = storeToRefs(useCheckoutStore());
    const { openModal } = useModal();
    const { isCheckoutSuccessRedesignEnabled } = useFeatureFlagsStore();
    const theme = root.$themeConfig.loyaltyOrderRegister;

    const hideRoot = computed(
      () =>
        isCheckoutSuccessRedesignEnabled &&
        loyaltyEnrolled.value &&
        theme.hideAccruedPoints
    );

    const points = computed(() => {
      if (!order.value) {
        return 0;
      }

      const rewardsAmount =
        rewards.value.reduce((sum, reward) => sum + reward.amount, 0) || 0;
      // points are not given for the amount covered with rewards
      const pointsBase = order.value.totals.totalWithoutTax - rewardsAmount;
      return Math.max(Math.round(pointsBase * POINTS_PER_DOLLAR), 0);
    });

    const data = computed<ExperienceSettings>(() => {
      if (loyaltyEnrolled.value) {
        const name = order.value?.billingAddress?.firstName || '';
        let title = props.member.title.replace('{{name}}', name || '');
        let subtitle = props.member.subtitle
          .replace('{{name}}', name || '')
          .replace('{{amount}}', points.value.toString());

        return { ...props.member, title, subtitle };
      }

      if (
        isCheckoutSuccessRedesignEnabled &&
        !loggedIn.value &&
        order.value?.existingCustomer
      ) {
        return props.guest;
      }

      if (!loggedIn.value) {
        return isCheckoutSuccessRedesignEnabled ? props.legacy : props.guest;
      }

      return props.legacy;
    });

    const shouldOpenInNewModal = (link: string) => {
      return link.includes('data-id');
    };

    const wrapperClasses = {
      'vf-loyalty-order-register-wrapper': true,
      'vf-loyalty-order-register-wrapper--redesign': isCheckoutSuccessRedesignEnabled,
    };

    return {
      data,
      shouldOpenInNewModal,
      openModal,
      theme,
      hideRoot,
      wrapperClasses,
      isCheckoutSuccessRedesignEnabled,
    };
  },
});
