





























































import { storeToRefs } from 'pinia';
import type { PropType } from 'vue';
import { computed, defineComponent, onMounted } from '@vue/composition-api';
import type { OrderSuccessTranslation } from '@vf/api-contract';
import { ROUTES, useI18n, useLoyalty } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import useModal from '@/shared/useModal';
import OrderPickupDetails from '@/components/order/OrderPickupDetails.vue';
import { useCheckoutStore } from '@vf/composables/src/store/checkoutStore';
import { useUserStore } from '@vf/composables/src/store/user';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

export default defineComponent({
  name: 'OrderSuccess',
  components: { OrderPickupDetails },
  props: {
    translations: {
      type: Object as PropType<OrderSuccessTranslation>,
      required: true,
    },
    showNotification: Boolean,
  },
  setup() {
    const { root } = useRootInstance();
    const pickupDetailsAtTop =
      root.$themeConfig.orderSuccess.pickupDetailsAtTop;

    const { openModal } = useModal();
    const userStore = useUserStore(root);
    const { loggedIn, loyaltyEnrolled } = storeToRefs(userStore);
    const { getLoyaltyVouchers } = useLoyalty(root);
    const {
      isPointsToCurrencyEnabled,
      isCoreRedesignEnabled,
    } = useFeatureFlagsStore();
    const wasLoggedInOnLoad = loggedIn.value;

    const { order, orderShipmentsSortedByDelivery } = storeToRefs(
      useCheckoutStore()
    );

    const addOrderToAccount = () =>
      openModal({
        type: 'fragment',
        resourceId: 'sign-in-confirmation-modal',
      });

    const hasPickupItems = computed(() => {
      return orderShipmentsSortedByDelivery.value.some(
        (method) => method.storeName
      );
    });

    const { localePath } = useI18n(root);
    const routeToOrderDetails = computed(() =>
      localePath(ROUTES.ORDER_DETAILS(order.value?.orderNumber))
    );

    onMounted(() => {
      if (loyaltyEnrolled.value && isPointsToCurrencyEnabled) {
        getLoyaltyVouchers();
      }
    });

    return {
      pickupDetailsAtTop,
      order,
      addOrderToAccount,
      openModal,
      loggedIn,
      hasPickupItems,
      routeToOrderDetails,
      wasLoggedInOnLoad,
      isCoreRedesignEnabled,
    };
  },
});
