










































import Vue from 'vue';
import type { PropType } from 'vue';
import {
  onMounted,
  ref,
  onBeforeUnmount,
  defineComponent,
} from '@vue/composition-api';
import {
  CustomsCTAExperiences,
  CustomsCTAExperienceTypes,
  CustomsCTAExperienceComponentNames,
  CTAExperience,
  RegularButtonCustomStyles,
  IconPosition,
  Icons,
  RegularButtonSize,
  RegularButtonStyle,
} from '@vf/api-contract';
import { useCart } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import {
  addToCart,
  closeIframe,
  ctaLifecycle,
  handleCustomHtmlTrigger as handleCustomHtmlTriggerAction,
  openCustomsExperience,
} from './actions';

const getComponentName = (experience: CTAExperience) =>
  CustomsCTAExperienceComponentNames[experience];

export default defineComponent({
  name: 'CustomsCta',
  props: {
    /** Object containing fields to pass to Iframe. Type string for authoring JSON purposes */
    dataObject: {
      type: String,
      default: '',
    },
    /** Button text for CTA */
    buttonText: {
      type: String,
      default: '',
    },
    /** Button text for CTA */
    gtmEventLabel: {
      type: String,
      default: '',
    },
    /** ID of button element */
    id: {
      type: String,
      default: '',
    },
    /** customId of button element */
    customId: {
      type: String,
      default: '',
    },
    /** Type of UMD experience that will be opened  */
    experience: {
      type: String as PropType<CTAExperience>,
      default: '',
      validator: (value: CTAExperience): boolean =>
        !value || CustomsCTAExperiences.includes(value),
    },
    /** Type of Customs integration that will be used  */
    experienceType: {
      type: String,
      default: CustomsCTAExperienceTypes.IFrame,
      validator: (value: string): boolean =>
        !value || Object.values(CustomsCTAExperienceTypes).includes(value),
    },
    buttonStyle: {
      type: String as PropType<RegularButtonStyle>,
      default: '',
    },
    buttonSize: {
      type: String as PropType<RegularButtonSize>,
      default: '',
    },
    icon: {
      type: String as PropType<Icons>,
      default: '',
    },
    iconPosition: {
      type: String as PropType<IconPosition>,
      default: '',
    },
    /** Flag to determine if button text should be underline */
    underline: {
      type: Boolean,
      default: false,
    },
    /** Button custom styles */
    styles: {
      type: Object as PropType<RegularButtonCustomStyles>,
      default: (): object => ({
        fontSize: '',
        fontWeight: '',
        fontFamily: '',
        textColor: '',
        backgroundColor: '',
        customClasses: '',
      }),
    },
  },
  setup(props, context) {
    const { emit } = context;
    const { root } = useRootInstance();
    const rootOptions = root.$options as Vue;

    const { setMiniCart } = useCart(root);

    const contentLoaded = ref(false);
    const styleSelectorMounted = ref(false);
    const disableCta = ref(false);
    const expContainer = ref(null);
    const expUnique = ref(false);
    const useModal = ref(false);
    const expClass = `umd-tool-${props.experience}`;
    const componentName = getComponentName(props.experience);

    const openCustomsExperienceConfig = ref({
      emit,
      componentIntegration: false,
      experience: props.experience,
      dataObject: props.dataObject,
      gtmEventLabel: props.gtmEventLabel,
    });

    const openCustomsExperienceOnLoad = () => {
      if (root.$route.query.customsFlow === props.customId) {
        openCustomsExperience(root, openCustomsExperienceConfig.value);
      }
    };

    const {
      iframeLoaded,
      setStyleSelectorOpened,
      setStyleSelectorMounted,
    } = ctaLifecycle({
      $eventBus: root.$eventBus,
      experience: props.experience,
      useModal,
      disableCta,
      contentLoaded,
      styleSelectorMounted,
      openCustomsExperienceOnLoad,
    });

    const handleCustomHtmlTrigger = handleCustomHtmlTriggerAction.bind(
      null,
      root,
      openCustomsExperienceConfig
    );

    let randomizerAddToCart;
    let closeCustomsVueComponentsIframe;

    onMounted(() => {
      const componentAvailabile = !!(Vue as any).options.components[
        componentName
      ]; // eslint-disable-line @typescript-eslint/no-explicit-any

      useModal.value =
        props.experienceType === CustomsCTAExperienceTypes.Modal &&
        componentAvailabile;

      openCustomsExperienceConfig.value.componentIntegration = useModal.value;

      closeCustomsVueComponentsIframe = closeIframe.bind(this, {
        contentLoaded,
      });

      rootOptions.$eventBus.$on(
        'styleSelectorMounted',
        setStyleSelectorMounted
      );
      rootOptions.$eventBus.$on(
        `closeCustomsIframe`,
        closeCustomsVueComponentsIframe
      );
      rootOptions.$eventBus.$on('customsToolsMounted', iframeLoaded);

      expUnique.value =
        !CustomsCTAExperiences.includes(props.experience) ||
        document.querySelector(`.${expClass}`) === expContainer.value;

      if (props.experience === CTAExperience.Randomizer && expUnique.value) {
        randomizerAddToCart = addToCart.bind(this, root, {
          includePreCreatedCustomsCode: true,
          componentIntegration: useModal.value,
        });

        if (useModal.value) {
          root.$eventBus.$on('customsAddToCart', randomizerAddToCart);
        } else {
          rootOptions.$eventBus.$on('addToCart', randomizerAddToCart);
        }
      }

      if (useModal.value) {
        // We need to wait for the custom components modal to be rendered
        // and mounted, otherwise the event emitted by root.$modal.show
        // is not yet being listened to
        root.$nextTick(() => {
          openCustomsExperienceOnLoad();
        });
      }

      document.removeEventListener(
        'styleSelectorOpened',
        setStyleSelectorOpened
      );
      document.addEventListener('styleSelectorOpened', setStyleSelectorOpened);

      if (props.experience === CTAExperience.UGCEntrypoint) {
        document.removeEventListener('openDynamicUGC', handleCustomHtmlTrigger);
        document.addEventListener('openDynamicUGC', handleCustomHtmlTrigger);
      }
    });

    onBeforeUnmount(() => {
      root.$eventBus.$off('customsAddToCart', randomizerAddToCart);
      rootOptions.$eventBus.$off(
        'styleSelectorMounted',
        setStyleSelectorMounted
      );
      rootOptions.$eventBus.$off(
        `closeCustomsIframe`,
        closeCustomsVueComponentsIframe
      );
      rootOptions.$eventBus.$off('customsToolsMounted', iframeLoaded);
      rootOptions.$eventBus.$off('addToCart', randomizerAddToCart);

      document.removeEventListener('openDynamicUGC', handleCustomHtmlTrigger);
      document.removeEventListener(
        'styleSelectorOpened',
        setStyleSelectorOpened
      );

      setMiniCart(false);
    });

    return {
      componentName,
      contentLoaded,
      disableCta,
      expClass,
      expContainer,
      useModal,
      expUnique,
      openCustomsExperience: (experience) =>
        openCustomsExperience(root, {
          ...openCustomsExperienceConfig.value,
          experience,
        }),
    };
  },
});
