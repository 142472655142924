



























import { computed, defineComponent } from '@vue/composition-api';
import useRootInstance from '@/shared/useRootInstance';
import { ProductAttributeCodes } from '@vf/api-client';
import { useProduct, useProductInventory, useMonetate } from '@vf/composables';
import { getId } from '@vf/composables/src/useUrl/handlers/parseUrl';
import { useUserData } from '../../cms/cmsUtils';
import { Context } from '@vf/api-contract';

export default defineComponent({
  name: 'VfProductExtendedSizes',
  props: {
    contextKey: {
      type: String,
      default: 'page-content',
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { product, getProductDetails } = useProduct(root, props.contextKey);
    const { getProductInventory } = useProductInventory(root, props.contextKey);
    const { getExperienceDecision, addCustomVars } = useMonetate(root);

    const fitTypeAttr = computed(() =>
      product.value.attributes.find(
        (attr) => attr.code === ProductAttributeCodes.Fit
      )
    );

    const fitTypes = computed(() =>
      fitTypeAttr.value
        ? fitTypeAttr.value.options.filter(({ available }) => available)
        : []
    );

    const fullTitle = computed(
      () => `${fitTypeAttr.value?.label} - ${currentFitType.value.label}`
    );

    const currentFitType = computed(
      () =>
        fitTypes.value.find(
          (type) =>
            getId(type.pageURL)?.toLowerCase() ===
              product.value.id.toLowerCase() && type.available
        ) ?? { label: '', value: '' }
    );

    const onFitTypeChange = async (fitType) => {
      if (props.contextKey === Context.QuickShop) {
        const productId = getId(fitType.pageURL);
        await getProductDetails(productId, {
          isBackgroundRequest: false,
          loadImages: true,
          saveVariation: false,
          saveOnlyColor: true,
          configuredColor: product.value.color?.value,
        });
        await getProductInventory(productId);
        const userData = useUserData(root);
        addCustomVars(userData);
        getExperienceDecision(true);
      } else {
        root.$router.push({
          path: fitType.pageURL,
          query: {
            color: product.value.color?.value,
          },
        });
      }
    };

    return {
      fullTitle,
      fitTypes,
      currentFitType,
      onFitTypeChange,
    };
  },
});
