
































import type { PropType } from 'vue';
import { defineComponent, ref, watch } from '@vue/composition-api';
import useRootInstance from '@/shared/useRootInstance';

type ChangedStoreId = {
  type: 'ChangedStoreId';
  details: {
    level: string;
    uuid: string;
    previousStore: {
      id: string;
      name: string;
    };
    currentStore: {
      id: string;
      name: string;
    };
  };
};

export default defineComponent({
  name: 'StoreSelector',
  props: {
    currentStore: Object as PropType<ChangedStoreId>,
  },
  setup(props, { emit }) {
    const { root } = useRootInstance();

    const popupRefContainer = ref();
    const storeName = ref();

    const unwatchStoreChange = watch(
      () => props.currentStore,
      (store) => {
        if (store) {
          storeName.value = store.details.currentStore.name;
          showStoreChangedNotification();
          emit('notification-displayed');
          unwatchStoreChange();
        }
      }
    );

    const showStoreChangedNotification = () => {
      if (root.$viewport.isSmall) popupRefContainer.value.show();
    };
    const hideStoreChangedNotification = () => {
      if (root.$viewport.isSmall) popupRefContainer.value.hide();
      storeName.value = null;
    };

    return {
      popupRefContainer,
      storeName,
      hideStoreChangedNotification,
    };
  },
});
