




import { computed } from '@vue/composition-api';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ImageTape',
  props: {
    /** Background image src **/
    background: {
      type: String,
      default: '',
    },
    /** Image tape height **/
    height: {
      type: Number,
      default: null,
    },
  },
  setup(props) {
    const style = computed(() => {
      const s = {
        '--vf-image-tape-background-image': `url(${props.background})`,
      };
      if (props.height > 0) {
        s['--vf-image-tape-height'] = `${props.height}px`;
      }
      return s;
    });
    return {
      style,
    };
  },
});
