






































































































import type { PropType } from 'vue';
import { computed, defineComponent, ref } from '@vue/composition-api';
import { useCms, useI18n } from '@vf/composables';
import { Context } from '@vf/api-contract';
import type { SiteSelectorTranslations } from '@vf/api-contract';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import useRootInstance from '@/shared/useRootInstance';

type SiteLocale = {
  currency: string;
  link: string;
  locale: string;
  localeName: string;
};

type Site = {
  displayName: string;
  locales: SiteLocale[];
};

export default defineComponent({
  name: 'SiteSelector',
  mixins: [validationMixin],
  validations: {
    deliveryLocation: {
      required,
    },
  },
  props: {
    logo: {
      type: [String, Object],
      default: '',
    },
    locales: {
      type: Array as PropType<Site[]>,
      default: () => [],
    },
    translations: {
      type: Object as PropType<SiteSelectorTranslations>,
      default: () => ({
        heading: 'Welcome',
        deliveryLocationLabel: 'Please select your delivery location',
        deliveryLocationPlaceHolder: 'Select Location',
        languageSelectorLabel: 'Select a language',
        buttonText: 'Go',
        helperText:
          'Please note that by changing location all items in your shopping cart will be removed',
        currencyText: 'Currency',
        languageSelectorPlaceHolder: 'Please select language',
        languageSelectedLabel: 'Language',
        locationSelectionRequired: 'Location is required',
      }),
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { configureApiInternalization, localeCode } = useI18n(root);
    const { changeLocale, setup } = useCms(root, Context.PageContent);

    const loadingNewLocale = ref(false);
    const selectedLocale = ref<SiteLocale>({} as SiteLocale);
    const selectedLocaleName = computed(
      () => selectedLocale.value.localeName || ''
    );
    const selectedSite = ref<Site>({} as Site);

    const selectSite = (countryName: string) => {
      selectedSite.value = props.locales.find(
        (site) => site.displayName === countryName
      );

      if (selectedSite.value.locales.length === 1) {
        selectLocale(selectedSite.value.locales[0].locale);
        return;
      }
      selectLocale('');
    };

    const selectLocale = (localeCode: string) => {
      selectedLocale.value = localeCode
        ? selectedSite.value.locales.find((loc) => loc.locale === localeCode)
        : ({} as SiteLocale);
    };

    const goToLocale = async () => {
      try {
        loadingNewLocale.value = true;
        if (selectedLocale.value.locale === localeCode()) {
          history.length > 2
            ? window.history.back()
            : window.location.replace(`/${localeCode()}`);
          return;
        }

        await setup({ forcePreview: root.$isPreview });
        changeLocale(selectedLocale.value.locale);

        configureApiInternalization();
      } catch (err) {
        root.$log.error(
          `[@theme/components/smart/SiteSelector.vue] Error: ${err.message}`
        );
      } finally {
        loadingNewLocale.value = false;
      }
    };

    return {
      goToLocale,
      loadingNewLocale,
      selectedLocale,
      selectedLocaleName,
      selectedSite,
      selectLocale,
      selectSite,
    };
  },
});
