import { render, staticRenderFns } from "./FaqCollection.vue?vue&type=template&id=303f1862&scoped=true&"
import script from "./FaqCollection.vue?vue&type=script&lang=ts&"
export * from "./FaqCollection.vue?vue&type=script&lang=ts&"
import style0 from "./FaqCollection.vue?vue&type=style&index=0&id=303f1862&lang=scss&scoped=true&"
import style1 from "./FaqCollection.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "303f1862",
  null
  
)

export default component.exports