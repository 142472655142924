import { scrollTo } from '@vf/shared/src/utils/helpers';

/**
 * Scroll to the element taking into account header height.
 * @param selector
 */
export const scrollToElement = (selector: string): void => {
  const node = document.querySelector(selector);
  if (!node) {
    console.debug(
      `Could not find element ${selector} in the DOM to perform scroll.`
    );
    return;
  }
  const headerNode = document.querySelector(
    '.vf-header__container'
  ) as HTMLElement;
  const headerHeight = headerNode.offsetHeight;
  const nodeScrollY = window.scrollY + node.getBoundingClientRect().top;
  const top = nodeScrollY - headerHeight;
  scrollTo({
    top,
  });
};
