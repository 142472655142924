






























import { defineComponent } from '@vue/composition-api';
import { useAccount, useNotification } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import useModal from '@/shared/useModal';

export default defineComponent({
  name: 'EmployeeDiscountModal',
  props: {
    teaserTitle: {
      type: String,
      default: '',
    },
    richText: {
      type: String,
      default: '',
    },
    accept: {
      type: String,
      default: '',
    },
    doNotAccept: {
      type: String,
      default: '',
    },
    successMessage: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { closeModal } = useModal();
    const { addNotification, clearNotifications } = useNotification(root);
    const { updateAcceptance, updateProfile } = useAccount(root);

    const acceptTC = async () => {
      clearNotifications();
      try {
        await updateAcceptance({ isEmployeeDiscountTCAccepted: true });
        addNotification({
          message: props.successMessage,
          type: 'success',
        });
      } catch (e) {
        root.$log.error('[EmployeeDiscountModal.vue::acceptTC]', e);
      }
      closeModal();
    };

    const dismissTC = async () => {
      await updateAcceptance({ isEmployeeDiscountTCAccepted: false });
      closeModal();
    };

    return {
      acceptTC,
      dismissTC,
      updateAcceptance,
      updateProfile,
    };
  },
});
