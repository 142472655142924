









import { computed, defineComponent } from '@vue/composition-api';
import { storeToRefs } from 'pinia';
import { useCartStore } from '@vf/composables/src/store/cartStore';
import useRootInstance from '@/shared/useRootInstance';
import { pluralize } from '@/helpers/pluralize';
import { replaceAll } from '@/helpers/replaceAll';

export default defineComponent({
  name: 'CheckoutDeliveryTime',
  setup() {
    const { root } = useRootInstance();
    const { shippingItems, shippingOptions } = storeToRefs(useCartStore());

    const itemCount = computed(() => ({
      customs: shippingItems.value.filter((item) => item.isCustoms).length,
      me: shippingItems.value.filter((item) => !item.isCustoms).length,
    }));

    const deliveryTimes = shippingOptions.value.map(
      ({ code, deliveryTime }) => {
        const label = root.$getEDDLabel(root, deliveryTime);

        const countLabel = root.$t(
          pluralize(
            'deliveryTime.itemCount',
            itemCount.value[code] || itemCount.value.me
          )
        ) as string;

        return {
          code,
          label,
          count: replaceAll(countLabel, {
            count: itemCount.value[code] || itemCount.value.me,
          }),
        };
      }
    );

    const mixDelivery = computed(() =>
      Object.values(itemCount.value).every((item) => item > 0)
    );

    return {
      deliveryTimes,
      mixDelivery,
    };
  },
});
