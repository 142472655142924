var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"customs-tools-wrapper"},[_c('div',{staticClass:"customs-tools"},[_c('div',{ref:"expContainer",class:_vm.expClass},[_c('client-only',[(_vm.useModal && _vm.expUnique)?_c(_vm.componentName,{tag:"component"}):_vm._e()],1)],1)]),_vm._v(" "),_c('VfButton',_vm._b({class:[
      _vm.styles.customClasses,
      _vm.buttonStyle ? ("vf-button--" + _vm.buttonStyle) : '',
      _vm.buttonSize ? ("vf-button--" + _vm.buttonSize) : '',
      _vm.iconPosition ? ("vf-button--icon-" + _vm.iconPosition) : '',
      _vm.styles.fontFamily,
      {
        'vf-button--underline': _vm.underline,
        'vf-button--no-underline': !_vm.underline,
      } ],attrs:{"id":_vm.id,"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id']),"text-color":_vm.styles.textColor,"font-size":_vm.styles.fontSize,"font-weight":_vm.styles.fontWeight,"background-color":_vm.styles.backgroundColor,"icon":_vm.icon === 'no-icon' ? '' : _vm.icon,"disabled":_vm.disableCta,"data-model":_vm.dataObject,"custom-id":_vm.customId,"experience":_vm.experience,"aria-label":_vm.buttonText || _vm.experience},on:{"click":function($event){return _vm.openCustomsExperience(_vm.experience)}}},'VfButton',_vm.$attrs,false),[_vm._v("\n    "+_vm._s(_vm.buttonText)+"\n    "),_vm._t("default")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }