

















































































































































































import { defineComponent, computed, PropType } from '@vue/composition-api';
import { useGtm, useProduct } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import { Product } from '@vf/api-client';

export default defineComponent({
  name: 'CustomsHpSlide',
  props: {
    /** Image object to display as a background */
    banner: {
      type: Object,
      default: () => ({}),
    },
    /** Product image overlay (PNG) **/
    productImages: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    /** Slide Text  */
    text: {
      type: String,
      default: '',
    },
    /* Is the Slide Text Rich Text? */
    useRichText: {
      type: Boolean,
      default: false,
    },
    /** Button text */
    buttonText: {
      type: String,
      default: '',
    },
    /** Button link */
    buttonLink: {
      type: String,
      default: '',
    },
    /** Product SKUs */
    productSkus: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    /** Link target for banner image **/
    imageTarget: {
      type: String,
      default: '',
    },
    contextKey: {
      type: String,
      default: '',
    },
    translations: {
      type: Object,
      default: () => ({
        buyNowCTA: 'Buy now',
        customizeCTA: 'Customize',
      }),
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { dispatchEvent } = useGtm(root);
    const { contextProducts } = useProduct(root, props.contextKey);

    const products = computed(() =>
      props.productSkus.map((sku, index) => {
        const product = contextProducts.value?.[sku] as Product;
        const pdpUrl = product?.product_slug ? product?.pageUrl ?? '' : '';
        const customizerUrl = product?.customsExternalurl ?? '';
        const productImage = props.productImages?.[index] || {};
        return { pdpUrl, customizerUrl, productImage };
      })
    );

    const dispatchHtmlEvent = () => {
      const img = document.querySelector('.vf-customs-hp-slide');
      if (img) {
        img.dispatchEvent(
          new CustomEvent('customsSlideImageLoaded', {
            bubbles: true,
            cancelable: false,
          })
        );
      }
    };

    const logEvent = (data) => {
      dispatchEvent({
        eventName: 'loadEventData',
        overrideAttributes: {
          eventAction: 'Main Page',
          eventLabel: data,
        },
      });
    };
    return {
      logEvent,
      products,
      dispatchHtmlEvent,
    };
  },
});
