import { computed } from '@vue/composition-api';
import { CartCustomerNotificationType } from '@vf/api-contract';
import {
  ComponentInstance,
  useAccount,
  useAuthentication,
  useCart,
} from '@vf/composables';
import { useUserStore } from '../store/user';
import { storeToRefs } from 'pinia';

enum Discounts {
  EMPLOYEE = 'employee',
  ATHLETE = 'athlete',
  IPA = 'ipa',
  WRANX = 'wranx',
}

const useDiscount = (instance: ComponentInstance) => {
  const userStore = useUserStore(instance);
  const { isEmployeeDiscountTCAccepted } = storeToRefs(userStore);
  const { basicInformation } = useAccount(instance);
  const { isWranxProsumer, isIpaProsumer } = useAuthentication(instance);
  const { cart } = useCart(instance);

  const isAthlete = computed(() => basicInformation.value?.athlete?.athlete);
  const isProsumer = computed(() => basicInformation.value?.prosumer?.prosumer);

  const discountsStates = computed(() => ({
    [Discounts.ATHLETE]: isAthlete.value,
    [Discounts.IPA]: isProsumer.value && isIpaProsumer.value,
    [Discounts.WRANX]: isProsumer.value && isWranxProsumer.value,
    [Discounts.EMPLOYEE]: basicInformation.value?.employee?.employee,
  }));

  const activeDiscountName = computed(() => {
    return Object.entries(discountsStates.value).find((entry) => entry[1])?.[0];
  });

  const discount = computed(() => {
    if (isAthlete.value) return basicInformation.value?.athlete;
    if (isProsumer.value) return basicInformation.value?.prosumer;
    return basicInformation.value?.employee;
  });

  const consumerReachedSeasonLimit = computed(
    () =>
      cart.value?.customerNotifications?.some(
        ({ type }) =>
          type ===
          CartCustomerNotificationType.CustomerSeasonDiscountLimitReached
      ) ?? false
  );

  const isLimitSpent = computed(
    () => discount.value?.currentSpend >= discount.value?.annualCap
  );

  const displayStrategy = {
    [Discounts.EMPLOYEE]: () =>
      activeDiscountName.value && isEmployeeDiscountTCAccepted.value,
    [Discounts.IPA]: () => isProsumer.value && isIpaProsumer.value,
    [Discounts.WRANX]: () => isProsumer.value && isWranxProsumer.value,
  };

  const isVisible = computed(
    () =>
      displayStrategy[activeDiscountName.value]?.() ??
      !!activeDiscountName.value
  );

  const temporaryVisibleDiscount = [Discounts.EMPLOYEE as string];

  const maybeTemporary = computed(() =>
    temporaryVisibleDiscount.includes(activeDiscountName.value)
  );

  return {
    maybeTemporary,
    activeDiscountName,
    isLimitSpent,
    discount,
    consumerReachedSeasonLimit,
    isVisible,
  };
};

export default useDiscount;
