















































































































































import type { PropType } from 'vue';
import { computed, defineComponent, ref } from '@vue/composition-api';
import type {
  OrderDetailsTranslations,
  OrderItemDetailed,
  Shipment,
} from '@vf/api-contract';
import {
  getCacheKeyFromProps,
  OrderStatus,
  buildStartNarvarReturnUrl,
} from '@vf/shared/src/utils/helpers';
import { ROUTES, useI18n } from '@vf/composables';
import { ReturnStepsType } from '@vf/composables/src/useReturns';
import { useUserStore } from '@vf/composables/src/store/user';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import useRootInstance from '@/shared/useRootInstance';
import OrderReturnsCTA from '@vf/theme/components/order/OrderReturnsCTA.vue';
import ShipmentTrackReturnModal from '@/components/modals/ShipmentTrackReturnModal.vue';

type ReturnStep = 'summary' | 'details';
type Context = 'order';

interface SummaryShipment {
  shipmentId: string;
  storeName: string;
  orderReleaseNumber: string;
  billingAddressEmail: string;
  shippingMethod: {
    trackingNumber: string;
    trackingUrl: string[];
  };
  hasReturnableItems: boolean;
  orderItems: OrderItemDetailed[];
  returnedProductsCount: number;
  shipmentProductsByStatus: {
    [status: string]: OrderItemDetailed[];
  };
}

export default defineComponent({
  name: 'OrderSummaryProductList',
  serverCacheKey: getCacheKeyFromProps,
  components: {
    OrderReturnsCTA,
    ShipmentTrackReturnModal,
  },
  props: {
    translations: {
      type: Object as PropType<OrderDetailsTranslations>,
      required: true,
    },
    /** Order Products */
    products: {
      type: Array as PropType<OrderItemDetailed[]>,
      required: true,
    },
    /** Order Shipments */
    shipments: {
      type: Array as PropType<SummaryShipment[]>,
      required: true,
    },
    /** Render Context */
    contextKey: {
      type: String as PropType<Context>,
      default: 'order',
    },
    /** Determine if Order Product notification is displayed */
    showNotificationText: {
      type: Boolean,
      default: false,
    },
    /** Translated Product Statuses Array: e.g. 'all', 'cancelled' */
    productStatuses: {
      type: Array as PropType<string[]>,
      required: true,
    },
    /** Determine if it's returns context */
    isReturns: {
      type: Boolean,
      default: false,
    },
    /** Return Step Context */
    returnsContext: {
      type: String as PropType<ReturnStep>,
      default: '',
    },
    /** Returns Products */
    returnsItems: {
      type: Array as PropType<Record<string, any>[]>,
      required: true,
    },
    /** Order statuses for returns */
    returnStatuses: {
      type: Array as PropType<string[]>,
      required: true,
    },
    /** Display Returned Items Details CTA Button */
    displayReturnedItemsDetailsCTA: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const theme = root.$themeConfig.orderDetails;

    const {
      localePath,
      localeCode,
      localeMapping,
      getStaticTranslation,
    } = useI18n(root);
    const localeData = {
      localeCode: localeCode(),
      localeMapping: localeMapping(),
    };

    const narvarTranslations = getStaticTranslation('narvar');

    const {
      areOnlineReturnsEnabled,
      areNarvarReturnsEnabled,
      isPricingStrikethroughEnabled,
    } = useFeatureFlagsStore();
    const userStore = useUserStore(root);

    const showCancelledItems = computed<boolean>(() => {
      return (
        cancelledItems.value.length &&
        !['summary', 'confirmation'].includes(props.returnsContext)
      );
    });

    const cancelledItems = computed(() => {
      const cancelledStatuses = [
        OrderStatus.cancelled,
        OrderStatus.dyoCancelled,
      ];

      return props.products.filter((product) =>
        cancelledStatuses.includes(product.status as OrderStatus)
      );
    });

    // Translated order summary status object
    const orderSummaryStatusObject = {
      [OrderStatus.processing]: props.translations.processingLabel,
      [OrderStatus.shipped]: props.translations.shippedLabel,
      [OrderStatus.returnCreated]: props.translations.returnCreatedLabel,
      [OrderStatus.returnReceived]: props.translations.returnReceivedLabel,
      [OrderStatus.cancelled]: props.translations.cancelledLabel,
      [OrderStatus.dyoCancelled]: props.translations.cancelledLabel,
      [OrderStatus.picked]: props.translations.pickedLabel,
      [OrderStatus.readyPick]: props.translations.readyPickLabel,
      [OrderStatus.shippedToStore]: props.translations.shippedToStoreLabel,
      [OrderStatus.pickExpired]: props.translations.pickExpiredLabel,
    };

    // Translate label for each shipping
    const getShippingDurationTranslation = (
      shippingMethod,
      productId: string
    ) => {
      let method = {
        ...shippingMethod,
        // Get Expected Delivery Time if provided
        deliveryTime:
          props.products.find((item) => item.sku === productId)
            ?.extnEstimatedDeliveryDate ?? null,
      };
      for (const el in props.translations.shippingDurationInfo) {
        if (method.label && method.label.toLowerCase().includes(el)) {
          method.label = props.translations.shippingDurationInfo[el].line1;
          break;
        }
      }
      return method;
    };

    const getShippingMethods = (shipment: Shipment) => {
      /**
       * TODO: check if services array is still valid in shipping method.
       * there is a difference between shipping method in order details and order success response
       */
      const methods = shipment.shippingMethod?.services?.length
        ? shipment.shippingMethod?.services
        : [shipment.shippingMethod];
      return methods.map((method) => ({
        ...shipment.shippingMethod,
        ...getShippingDurationTranslation(
          method,
          shipment.shipmentLine?.[0]?.itemId
        ),
        ...(shipment.extnEstimatedDeliveryDate !== undefined && {
          deliveryTime: shipment.extnEstimatedDeliveryDate,
        }),
      }));
    };

    const getShippingStatusText = (shipment, number: string): string => {
      if (!shipment || !theme.showDeliveryAsText) return '';

      // is pickup shipment
      if (shipment.storeName || shipment.fulfillmentType === 'PIS') {
        return `${props.translations.pickupFromLabel}: `;
      }

      const trackingNumbers = shipment.shippingMethod.trackingNumber;
      if (!theme.showEddAndTrackingInSeparateLines && trackingNumbers.length) {
        return `${props.translations.trackPackageLabel2} {{numbers}}`.replace(
          '{{numbers}}',
          trackingNumbers.join(', ')
        );
      }

      return props.translations.shipmentSummaryArrival.replace(
        '{{number}}',
        number
      );
    };

    const getShipmentTranslations = (shipment) => ({
      deliveryDateLabel:
        /** use  deliveryDateLabel translation in case shipment is not available (e.g. cancelled items) */
        shipment?.storeName || narvarTranslations.deliveryDateLabel,
      free: props.translations.free,
    });

    const returnTrackableItems = ref<OrderItemDetailed[]>([]);

    const availableShipments = computed(() =>
      props.contextKey === 'order' || props.returnsContext === 'details'
        ? props.shipments
            .filter(
              (item) => Object.keys(item.shipmentProductsByStatus).length > 0
            )
            .map((shipment) => {
              const hasReturnableItems = shipment.orderItems.some(
                (shipmentItem) =>
                  shipment.hasReturnableItems &&
                  shipmentItem.quantity.returnable !== 0
              );
              const trackableItems = shipment.orderItems.reduce(
                (items, shipmentItem) => {
                  (shipmentItem.extnReturns ?? []).forEach((extn) => {
                    const trackableItemExists = items.some(
                      (item) =>
                        item.extnReturnTrackingURL ===
                        extn.extnReturnTrackingURL
                    );
                    if (trackableItemExists) {
                      return items;
                    }
                    items.push({
                      ...shipmentItem,
                      extnReturnTrackingURL: extn.extnReturnTrackingURL,
                      qty: extn.extnReturnQty,
                      price: {
                        currency: shipmentItem.price.currency,
                        original: extn.extnReturnTotal / extn.extnReturnQty,
                        priceAfterItemDiscount: extn.extnReturnTotal,
                      },
                    });
                  });
                  return items;
                },
                []
              );

              return {
                ...shipment,
                startReturnURL: hasReturnableItems
                  ? buildStartNarvarReturnUrl(
                      root,
                      shipment.orderReleaseNumber,
                      shipment.billingAddressEmail
                    )
                  : '',
                extnReturnTrackingURL: trackableItems[0]?.extnReturnTrackingURL
                  ? trackableItems[0].extnReturnTrackingURL
                  : '',
                trackableItems,
              };
            })
        : []
    );

    const showReturnHistoryLink = computed(() => {
      if (!userStore.loggedIn) return false;
      return areOnlineReturnsEnabled || areNarvarReturnsEnabled;
    });

    return {
      theme,
      localeData,
      showCancelledItems,
      cancelledItems,
      productSummaryBindings: computed(() => ({
        translations: props.translations,
        showItemPrice: theme.showItemPrice,
        isPricingStrikethroughEnabled,
        showSmallDescription: theme.showSmallDescription,
        showGiftOption: theme.showGiftOption,
        showNotificationText: props.showNotificationText,
      })),
      orderSummaryStatusObject,
      getShippingMethods,
      getShippingStatusText,
      getShipmentTranslations,
      getReturnedItemsTranslations: ({ returnedProductsCount: quantity }) => {
        // now pointing to return history we will, to improve during redesign
        return props.translations.returnedItemsText.replace(
          '{{quantity}}',
          quantity
        );
      },
      ReturnStepsType,
      showReturnHistoryLink,
      returnedItemsPath: localePath(ROUTES.RETURN_HISTORY()),
      isReturnStatus: (status) => {
        return props.returnStatuses.includes(status.toLowerCase());
      },
      returnTrackableItems,
      isCancelledStatus: (status) => status === OrderStatus.cancelled,
      availableShipments,
    };
  },
});
