
export default {
  name: 'VfSelectOption',
  functional: true,
  props: {
    disabled: Boolean,
  },
  render(h, { data, children, props }) {
    return h(
      'option',
      {
        ...data,
        attrs: {
          ...data.attrs,
          ...props,
        },
        class: 'vf-select__option',
      },
      children?.length ? children : data.attrs.placeholder
    );
  },
};
