import { render, staticRenderFns } from "./LottieLoader.vue?vue&type=template&id=470e7d2b&scoped=true&"
import script from "./LottieLoader.vue?vue&type=script&lang=ts&"
export * from "./LottieLoader.vue?vue&type=script&lang=ts&"
import style0 from "./LottieLoader.vue?vue&type=style&index=0&id=470e7d2b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "470e7d2b",
  null
  
)

export default component.exports