import { ref, Ref } from '@vue/composition-api';
import { ComponentInstance, ComposablesStorage } from '../types';
import initStorage from '../utils/storage';
import { CartLineItem } from '@vf/api-client';

type useShipmentStore = {
  selectedItem: Ref<CartLineItem | null>;
  isShipmentModalOpen: Ref<boolean>;
  showGiftOptionWarning: Ref<boolean>;
};

const useShipmentStore = (instance: ComponentInstance) => {
  const storage: ComposablesStorage<useShipmentStore> = initStorage<useShipmentStore>(
    instance,
    'useShipmentStore'
  );

  const selectedItem =
    storage.get('selectedItem') ??
    storage.save('selectedItem', ref<CartLineItem | null>(null));

  const isShipmentModalOpen =
    storage.get('isShipmentModalOpen') ??
    storage.save('isShipmentModalOpen', ref(false));

  const showGiftOptionWarning =
    storage.get('showGiftOptionWarning') ??
    storage.save('showGiftOptionWarning', ref(false));

  return {
    selectedItem,
    isShipmentModalOpen,
    showGiftOptionWarning,
  };
};

export default useShipmentStore;
