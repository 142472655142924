







import { defineComponent, PropType, computed } from '@vue/composition-api';
import { useProduct } from '@vf/composables';
import KlarnaInfo from '../../payment/KlarnaInfo.vue';
import useRootInstance from '@/shared/useRootInstance';
import { KlarnaIntegration } from '@vf/api-contract';

export default defineComponent({
  name: 'KlarnaIntegration',
  components: {
    KlarnaInfo,
  },
  props: {
    translations: {
      type: Object as PropType<KlarnaIntegration>,
      required: true,
    },
    contextKey: {
      type: String,
      default: 'product',
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { priceData } = useProduct(root, props.contextKey);

    const price = computed(() => ({
      currentPrice: priceData.value.specialPrice,
      currency: priceData.value.currency,
    }));

    return {
      price,
    };
  },
});
