































import { defineComponent } from '@vue/composition-api';
import { useArticles } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'SearchArticlesTaxonomy',
  props: {
    /** Delimiter character (TBD if necessary)  */
    delimiter: {
      type: String,
      default: '/',
    },
    contextKey: {
      type: String,
      default: 'page-content',
    },
  },
  setup(props) {
    const { root } = useRootInstance();

    const {
      filtersOptions,
      selectedFilters,
      isSelected,
      toggleSelection,
      resetFilters,
      noArticlesFound,
      setTransitionFromProducts,
    } = useArticles(root, props.contextKey);

    const handleToggleSelection = (item) => {
      if (
        selectedFilters.value.length &&
        item.id !== selectedFilters.value[0].id
      ) {
        resetFilters();
      }
      toggleSelection(item);
      setTransitionFromProducts(false);
    };

    return {
      filtersOptions,
      selectedFilters,
      isSelected,
      toggleSelection,
      handleToggleSelection,
      noArticlesFound,
    };
  },
});
