import { render, staticRenderFns } from "./ProductRating.vue?vue&type=template&id=7083ac71&"
import script from "./ProductRating.vue?vue&type=script&lang=ts&"
export * from "./ProductRating.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports