









import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'PageDescription',
  props: {
    content: {
      type: String,
      default: '',
    },
  },
});
