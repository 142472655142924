



















































































import type { BrandifyStoreInfo } from '@vf/api-client';
import type { PropType } from 'vue';
import type { CartLineItem } from '@vf/api-client';
import type { Ref } from '@nuxtjs/composition-api';
import {
  computed,
  defineComponent,
  inject,
  onMounted,
  ref,
  watch,
} from '@vue/composition-api';
import useRootInstance from '@/shared/useRootInstance';
import useValidators from '@vf/composables/src/useValidators';
import {
  validateMaxLength,
  validateRequired,
} from '@vf/composables/src/useValidators/validators';
import StoreOption from './StoreOption.vue';
import ls from '@vf/composables/src/utils/localStorage';

export default defineComponent({
  name: 'StoreFinder',
  components: { StoreOption },
  props: {
    stores: {
      type: Array as PropType<BrandifyStoreInfo[]>,
    },
    pickupItem: {
      type: Object as PropType<CartLineItem>,
      required: true,
    },
    preSelectedStoreId: String,
  },
  emits: ['submit', 'close', 'find-stores', 'error'],
  setup(props, { emit }) {
    const { root } = useRootInstance();
    const isCoreRedesignEnabled = inject<Ref<boolean>>('isCoreRedesignEnabled');

    const distanceOptions = ['5', '10', '15', '25', '50', '100'].map(
      (value) => ({
        label: `${value} ${root.$t('storeFinder.distanceUnit')}`,
        value,
      })
    );

    const getLocation = async () => {
      if (!navigator.geolocation) {
        emit('error', 'geoLocationErrorNotification');
      } else {
        return new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });
      }
    };

    const findByLocation = async () => {
      try {
        const location: any = await getLocation();
        emit('find-stores', {
          lat: location.coords.latitude,
          lng: location.coords.longitude,
          distance: '50',
        });
      } catch (e) {
        emit('error', 'geoLocationErrorNotification');
      }
    };

    const findByAddress = async () => {
      if (validate()) {
        emit('find-stores', {
          postalCode: form.value.center,
          distance: form.value.distanceValue,
        });
      }
    };

    const form = ref({
      distanceValue: distanceOptions[0].value,
      center: '',
    });
    const selectedStoreId = ref('');

    const { validate, validationFields } = useValidators(
      form.value,
      ref({
        center: [
          validateRequired(root.$t('pickerInfo.validation.required') as string),
          validateMaxLength(
            256,
            root.$t('pickerInfo.validation.maxLength') as string
          ),
        ],
      })
    );

    // TODO: GLOBAL15-61059 remove (and move to theme config) after core redesign
    const buttonConfig = computed(() => {
      if (root.$config.theme === 'vans') {
        if (isCoreRedesignEnabled.value) {
          return { color: 'secondary' };
        } else {
          return { color: 'inverse', variant: 'border' };
        }
      } else {
        return { color: 'primary', variant: 'border' };
      }
    });

    watch(
      props,
      (newProps) => (selectedStoreId.value = newProps.preSelectedStoreId)
    );

    onMounted(() => {
      const defaultStoreString = ls.getItem('defaultStore');
      if (!defaultStoreString) return;

      try {
        const { city, address1, address2, postalcode } = JSON.parse(
          defaultStoreString
        );
        form.value.center = [city, address1, address2, postalcode]
          .filter(Boolean)
          .join(', ');
        findByAddress();
      } catch (e) {
        root.$log.warn("LS corrupted: can't read default store");
      }
    });

    return {
      distanceOptions,
      form,
      validate,
      validationFields,
      findByLocation,
      findByAddress,
      selectedStoreId,
      buttonConfig,
    };
  },
});
