


















































































import type { PickupPerson } from '@vf/api-client';
import { storeToRefs } from 'pinia';
import { computed, defineComponent, ref } from '@vue/composition-api';
import { useAccount, useCheckout } from '@vf/composables';
import { useUserStore } from '@vf/composables/src/store/user';
import { useCartStore } from '@vf/composables/src/store/cartStore';
import { scrollToFirstError } from '@vf/shared/src/utils/helpers';
import useRootInstance from '@/shared/useRootInstance';

import CheckoutPickupTime from './CheckoutPickupTime.vue';
import CheckoutPickerInfo from './CheckoutPickerInfo.vue';
import StoreSelector from './storeselector/StoreSelector.vue';
import AddressPreview from '@/components/static/AddressPreview.vue';

enum PickupPersonType {
  Self = 'self',
  Else = 'else',
}

export default defineComponent({
  name: 'CheckoutPickup',
  components: {
    StoreSelector,
    CheckoutPickupTime,
    CheckoutPickerInfo,
    AddressPreview,
  },
  setup() {
    const { root } = useRootInstance();
    const { loggedIn } = storeToRefs(useUserStore(root));
    const {
      selectedPickupStore,
      pickupAddress,
      pickupPerson: storedPickupPerson,
      inStoreItems,
    } = storeToRefs(useCartStore());
    const { basicInformation } = useAccount(root);
    const { setPickupAddress } = useCheckout(root);

    const pickupPerson = ref<PickupPerson>(storedPickupPerson.value);

    const selectSelf = () => {
      pickupPerson.value.firstName = basicInformation.value.firstName;
      pickupPerson.value.lastName = basicInformation.value.lastName;
      pickupPerson.value.email = basicInformation.value.email;
    };

    const selectElse = () => {
      pickupPerson.value.firstName = '';
      pickupPerson.value.lastName = '';
      pickupPerson.value.email = '';
    };

    const validate = () => {
      if (
        areRadiosAvailable.value &&
        selectedRadio.value === PickupPersonType.Self
      ) {
        return true;
      } else {
        const isValid = pickerRef.value.validate();
        return isValid || scrollToFirstError();
      }
    };

    const submit = async () => {
      if (!validate()) return false;

      storedPickupPerson.value = pickupPerson.value;
      await setPickupAddress();
      return true;
    };

    const pickerRef = ref();

    const areRadiosAvailable = computed(() => {
      const { firstName, lastName } = basicInformation.value;
      return loggedIn.value && firstName && lastName;
    });

    const samePerson = computed(() => {
      return (
        areRadiosAvailable.value &&
        (!pickupPerson.value?.firstName ||
          basicInformation.value.firstName === pickupPerson.value.firstName) &&
        (!pickupPerson.value?.lastName ||
          basicInformation.value.lastName === pickupPerson.value.lastName) &&
        (!pickupPerson.value?.email ||
          basicInformation.value.email === pickupPerson.value.email)
      );
    });

    const selectedRadio = ref(
      samePerson.value ? PickupPersonType.Self : PickupPersonType.Else
    );
    if (selectedRadio.value === PickupPersonType.Self) {
      pickupPerson.value = {
        firstName: basicInformation.value.firstName,
        lastName: basicInformation.value.lastName,
        email: basicInformation.value.email,
      };
    }

    const isStoreSelectorOpen = ref(false);

    return {
      selectedPickupStore,
      pickupAddress,
      pickupPerson,
      selectedRadio,
      basicInformation,
      areRadiosAvailable,
      selectSelf,
      selectElse,
      isStoreSelectorOpen,
      pickerRef,
      validate,
      submit,
      inStoreItems,
      PickupPersonType,
    };
  },
});
