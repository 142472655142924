














































































































import { defineComponent } from '@vue/composition-api';
import { lazyImage } from '@vf/shared/src/utils/directives';
import useRootInstance from '@/shared/useRootInstance';
import { useGtm } from '@vf/composables';

export default defineComponent({
  name: 'CustomsContentSlide',
  directives: { lazyImage },
  inject: ['isFullHeight'],
  props: {
    id: { type: String, default: '' },
    type: { type: String, default: 'customs-image-slide' },
    image: { type: Object, default: () => ({}) },
    video: { type: Object, default: null },
    text: { type: String, default: '' },
    useRichText: { type: Boolean, default: false },
    headerTitle: { type: String, default: '' },
    headerSubtitle: { type: String, default: '' },
    headerImage: { type: Object, default: () => ({}) },
    ctaButtonLink: { type: String, default: '' },
    ctaButtonText: { type: String, default: '' },
    ctaButtonPosition: { type: String, default: 'middle_center' },
    /** Accessibility description*/
    carouselSlideRoleDescription: { type: String, default: '$Content Slide.$' },
    isFullHeight: { type: Boolean, default: true },
    customizer: { type: Object, default: null },
    /** Where do the three dots show up (Bottom | Between Image and Text) **/
    controlsPosition: {
      type: String,
      default: 'betweenImageAndText',
    },
    /** Type of CTA Overlay (slot|link) **/
    ctaType: {
      type: String,
      default: '',
    },
    isClickableImage: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { dispatchEvent } = useGtm(root);
    const config = root.$themeConfig?.carousel || false;

    const carouselSlideDescription = props.carouselSlideRoleDescription
      .replace('{{teaserTitle}}', props.headerTitle)
      .replace('{{teaserSubTitle}}', props.headerSubtitle)
      .trim();

    const slideButtonClick = () => {
      if (!config.dispatchGtmEvents) {
        return;
      }
      dispatchEvent({
        eventName: 'loadEventData',
        overrideAttributes: {
          eventCategory: 'Customs',
          eventAction: 'Add Your Touch',
          eventLabel: 'Start Designing',
          nonInteractive: false,
          customMetrics: {},
          customVariables: {},
          _clear: true,
        },
      });
    };

    const getMainImage = (image) => {
      if (root.$viewport?.size) {
        switch (root.$viewport.size) {
          case 'small':
            return image.small || image.medium || image.large || image || '';
          case 'medium':
            return image.medium || image.small || image.large || image || '';
          case 'large':
            return image.large || image.medium || image.small || image || '';
          default:
            return image.small || image.medium || image.large || image || '';
        }
      }
      return image.small || image.medium || image.large || image || '';
    };

    const dispatchHtmlEvent = () => {
      const img = document.querySelector(
        '.vf-customs-product-slide__media--image'
      );
      if (img) {
        img.dispatchEvent(
          new CustomEvent('customsSlideImageLoaded', {
            bubbles: true,
            cancelable: false,
          })
        );
      }
    };

    return {
      getMainImage,
      dispatchHtmlEvent,
      headerTitleComponent:
        props.headerTitle && Object.keys(props.headerImage).length > 0
          ? 'h3'
          : 'h1',
      slideClickHandler: (event) => {
        if (props.isClickableImage && props.ctaButtonLink) {
          event.stopPropagation();
          root.$router.push(props.ctaButtonLink);
        }
      },
      slideButtonClick,
      carouselSlideDescription,
    };
  },
  mounted() {
    this.$on('click-button', this.slideButtonClick);
  },
  beforeDestroy() {
    this.$off('click-button', this.slideButtonClick);
  },
});
