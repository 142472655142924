













import {
  computed,
  ref,
  defineComponent,
  onMounted,
  onBeforeUnmount,
} from '@vue/composition-api';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'Iframe',
  props: {
    src: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: '',
    },
    width: {
      type: [Number, String],
      default: null,
    },
    height: {
      type: [String, Number],
      default: '',
    },
    allow: {
      type: String,
      default: '',
    },
    allowFullScreen: {
      type: Boolean,
      default: true,
    },
    iframeType: {
      type: String,
      default: '',
    },
    lazy: {
      type: Boolean,
      default: true,
    },
    /** Whether the height should be auto  */
    autoHeight: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const iframeSrc = ref('');
    const { customize } = root.$route.query;
    const isCustomsIframe = computed(() => props.iframeType === 'customs');

    if (isCustomsIframe.value) {
      const caid = root.$customsCAID;
      const queryCharacter = props.src.indexOf('?') === -1 ? '?' : '&';

      iframeSrc.value = customize
        ? `${props.src}${queryCharacter}customize=${customize}&caid=${caid}`
        : `${props.src}${queryCharacter}caid=${caid}`;
    } else {
      iframeSrc.value = props.src;
    }

    const dataLayerPush = (event) => {
      const dataLayerData =
        'body' in event ? event.body.dataLayerData : event.dataLayerData;
      root.$gtm.push(dataLayerData);
    };
    onMounted(() => {
      root.$eventBus.$on('dataLayerPush', dataLayerPush);
    });

    onBeforeUnmount(() => {
      root.$eventBus.$off('dataLayerPush');
    });

    return {
      iframeSrc,
      isCustomsIframe,
      dataLayerPush,
    };
  },
});
