











import type { PropType } from 'vue';
import { computed, defineComponent } from '@vue/composition-api';
import {
  ROUTES,
  useAuthentication,
  useCart,
  useFavorites,
  useGtm,
  useI18n,
} from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import useAuthenticationEvents, {
  AuthenticationType,
} from '@/shared/useAuthenticationEvents';
import { storeToRefs } from 'pinia';
import { useCheckoutStore } from '@vf/composables/src/store/checkoutStore';
import { useUserStore } from '@vf/composables/src/store/user';
import type { OrderSummaryRegisterTranslations } from '@vf/api-contract';

export default defineComponent({
  props: {
    translations: {
      type: Object as PropType<OrderSummaryRegisterTranslations>,
      required: true,
    },
    mainContent: {
      type: String,
      default: '',
    },
    contextKey: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { getFormLocation } = useGtm(root);
    const formLocation = getFormLocation(props.contextKey);
    const userStore = useUserStore(root);
    const { loggedIn } = storeToRefs(userStore);
    const { signUp, signIn, saveForLaterId } = useAuthentication(root);
    const { createSignUp, createSignIn, loading } = useAuthenticationEvents(
      formLocation,
      {
        useLoadEvent: true,
        type: AuthenticationType.Registration,
      }
    );
    const { cartId } = useCart(root);
    const { localePath } = useI18n(root);
    const { favoriteId } = useFavorites(root);

    const { order } = storeToRefs(useCheckoutStore());

    const existingCustomer = computed(() => {
      if (
        order.value === null ||
        typeof order.value.existingCustomer === 'undefined'
      ) {
        return true;
      }
      return order.value.existingCustomer;
    });

    const boundWithGtmEventsSignIn = createSignIn(signIn, {
      isAutomaticLogin: true,
    });

    const boundWithGtmEventsSignUp = createSignUp(
      async <T extends Record<string, string>>(data: T) => {
        const {
          email,
          firstName,
          lastName,
          phone,
          country,
          postalCode,
        } = order.value.billingAddress;

        const signUpPayload = {
          country,
          phone,
          email,
          firstName,
          lastName,
          postalCode,
          isSubscribed: false,
          password: data.password,
        };

        await signUp(signUpPayload);

        const signInPayload = {
          username: signUpPayload.email,
          password: signUpPayload.password,
          st: order.value.st,
          orderNo: order.value.orderNumber,
          action: 'postLogin',
          guestObjects: {
            basketId: cartId.value,
            saveForLaterId: saveForLaterId.value,
            favoriteId: favoriteId.value,
          },
        };

        const signInResult = await boundWithGtmEventsSignIn(signInPayload);

        if (signInResult) {
          root.$router.push(localePath(ROUTES.ACCOUNT()));
        }
      }
    );

    const handleRegister = async (data) => {
      try {
        await boundWithGtmEventsSignUp(data);
      } catch (err) {
        console.error(err);
      }
    };

    return {
      existingCustomer,
      handleRegister,
      isButtonDisabled: loading,
      loggedIn,
    };
  },
});
