


























































import type { PropType } from 'vue';
import { defineComponent, ref } from '@vue/composition-api';
import type { ImageAddCaptionTranslations } from '@vf/api-contract';

export default defineComponent({
  name: 'VfImageAddCaption',
  props: {
    errorMsg: {
      type: String,
      default: '',
    },
    imageName: {
      type: String,
      default: '',
    },
    imageSrc: {
      type: String,
      default: '',
    },
    loading: Boolean,
    translations: {
      type: Object as PropType<ImageAddCaptionTranslations>,
      required: true,
    },
  },
  setup(props) {
    const caption = ref('');
    const captionName = props.translations.addCaption
      .toLowerCase()
      .replace(' ', '-');

    return { caption, captionName };
  },
});
