













import { PropType } from 'vue';
import {
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from '@vue/composition-api';
import { ApproachingDiscountDetails } from '@vf/api-contract';
import { useCmsLinks } from '@vf/composables';
import useModal from '@/shared/useModal';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'ApproachingDiscount',
  props: {
    discountNotification: {
      type: Object as PropType<ApproachingDiscountDetails>,
      required: true,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { setCmsLinksRef, unsetCmsLinks } = useCmsLinks(root, useModal());
    const discountNotificationRef = ref(null);

    onMounted(() => setCmsLinksRef(discountNotificationRef.value));
    watch(
      () => props.discountNotification,
      () => setCmsLinksRef(discountNotificationRef.value)
    );
    onUnmounted(() => unsetCmsLinks());

    return { discountNotificationRef };
  },
});
