//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@vue/composition-api';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'StoreContent',
  props: {
    /**
     * Full title
     */
    fullTitle: {
      type: String,
      default: '',
    },
    /**
     * iframe url
     */
    iframeUrl: {
      type: String,
      default: '',
    },
    /**
     * Object contains store data
     */
    storeCollection: {
      type: Object,
      default: () => {
        /**/
      },
    },
    /**
     * store carries
     */
    storeCarries: {
      type: Array,
      default: () => [],
    },
    /** map url */
    gMapUrl: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      default: 'en-us',
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const data = root.$themeConfig.storeData[props.locale].store;
    const appData = data.appData;
    const weekDays = data.daysOfWeek;
    const storeData = data.store;

    const date = new Date();
    const dayIndex = date.getDay() - 1;

    return {
      appData,
      weekDays,
      storeData,
      dayIndex,
    };
  },
});
