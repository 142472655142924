































import type { PropType } from 'vue';
import { defineComponent, onMounted, ref } from '@vue/composition-api';
import type { CreateAccountFormTranslations } from '@vf/api-contract';
import {
  ROUTES,
  useAuthentication,
  useCart,
  useFavorites,
  useI18n,
  useGtm,
  useUtilities,
} from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import useAuthenticationEvents, {
  AuthenticationType,
} from '@/shared/useAuthenticationEvents';
import type { PhoneInputCountry } from '@vf/composables/src/useUtilities';

export default defineComponent({
  name: 'CreateAccountForm',
  props: {
    translations: {
      type: Object as PropType<CreateAccountFormTranslations>,
      required: true,
    },
    /** Form heading level 1-6 */
    headingLevel: {
      type: Number,
      default: 4,
    },
    /** Link to redirect after succesfull sign up */
    link: {
      type: String,
      default: '',
    },
    /** Initial value for newsletter consent checkbox */
    newsletterSignUpAutoCheck: {
      type: Boolean,
      default: false,
    },
    contextKey: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { signUp, signIn, saveForLaterId } = useAuthentication(root);
    const { getFormLocation } = useGtm(root);
    const formLocation = getFormLocation(props.contextKey);
    const { createSignUp, createSignIn } = useAuthenticationEvents(
      formLocation,
      {
        useLoadEvent: true,
        type: AuthenticationType.Registration,
      }
    );
    const buttonDisabled = ref(false);
    const { localePath, getIetfLocale } = useI18n(root);
    const redirectLink = props.link || localePath(ROUTES.ACCOUNT('profile'));
    const locale = getIetfLocale();
    const { getCountry, getPhoneInputCountryList } = useUtilities(root);
    const countryCode = getCountry().toUpperCase();

    const boundWithGtmEventsSignUp = createSignUp(signUp);
    const boundWithGtmEventsSignIn = createSignIn(signIn, {
      isAutomaticLogin: true,
    });

    const theme = root.$themeConfig.createAccountForm;

    const phoneInputCountries = ref<PhoneInputCountry[]>([]);

    onMounted(async () => {
      const allPhoneInputCountries = await getPhoneInputCountryList(locale);
      phoneInputCountries.value = allPhoneInputCountries.filter(
        (item) => item.countryCode === countryCode
      );
    });

    async function handleSignUp(data) {
      buttonDisabled.value = true;
      try {
        await boundWithGtmEventsSignUp(data);

        const { cartId } = useCart(root);
        const { favoriteId } = useFavorites(root);

        const signInResponse = await boundWithGtmEventsSignIn(
          {
            password: data.password,
            username: data.email,
            formLocation,
            guestObjects: {
              basketId: cartId.value,
              saveForLaterId: saveForLaterId.value,
              favoriteId: favoriteId.value,
            },
          },
          false
        );

        if (signInResponse) {
          root.$router.push(redirectLink);
          sessionStorage.setItem(
            'accountCreated',
            root.$t('account.accountCreated') as string
          );
        }
      } catch (e) {
        console.error(e);
      } finally {
        buttonDisabled.value = false;
      }
    }

    return {
      handleSignUp,
      buttonDisabled,
      locale,
      phoneInputCountries,
      phoneInputDefaultCountryCode: countryCode,
      showPhoneInputCountry: theme.showPhoneInputCountry,
    };
  },
  computed: {
    prefillData() {
      return {
        isSubscribed: this.newsletterSignUpAutoCheck,
      };
    },
  },
});
