

















































































































import type { PropType } from 'vue';
import { defineComponent } from '@vue/composition-api';
import type { CarouselTranslations } from '@vf/api-contract';
import { useGtm } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';

interface ResponsiveOptions {
  small?: string;
  medium?: string;
  large?: string;
}

type Breakpoint = 'small' | 'medium' | 'large';

const DEFAULT_SLIDERS_PER_VIEW = 1;
const DEFAULT_SPACE_BETWEEN = '0px';

export default defineComponent({
  name: 'CustomsCarousel',
  provide() {
    return {
      headingLevel: this.titleStyles.level,
      isFullHeight: this.isFullHeight,
    };
  },
  props: {
    /** Autoplay slides **/
    autoplay: {
      type: Boolean,
      default: false,
    },
    /** Where do the three dots show up (bottom | betweenImageAndText) **/
    controlsPosition: {
      type: String as PropType<'bottom' | 'betweenImageAndText'>,
      default: 'bottom',
    },
    controlsType: {
      type: String as PropType<'noControls' | 'dots' | 'thumbnails'>,
      default: 'dots',
    },
    /** Does the carousel display at full height? **/
    isFullHeight: {
      type: Boolean,
      default: false,
    },
    loop: {
      type: Boolean,
      default: false,
    },
    portraitOnly: {
      type: Boolean,
      default: false,
    },
    /** Show | Hide navigation arrows **/
    showArrows: {
      type: Boolean,
      default: false,
    },
    slidesPerView: {
      type: Object as PropType<ResponsiveOptions>,
      default: () => ({}),
    },
    spaceBetween: {
      type: Object as PropType<ResponsiveOptions>,
      default: () => ({}),
    },
    title: {
      type: String,
      default: '',
    },
    titleStyles: {
      type: Object,
      default: () => ({}),
    },
    translations: {
      type: Object as PropType<CarouselTranslations>,
      default: () => ({}),
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { dispatchEvent } = useGtm(root);
    const config = root.$themeConfig.carousel || false;

    const pageUrl = root.$route.path;
    const pagePath = pageUrl.substring(pageUrl.lastIndexOf('/') + 1);

    const isCustomsHome =
      pagePath === 'customs' || pageUrl.includes('custom-shoes');

    const aria = props.translations.accessibility;

    // Accessibility Labels
    const carouselTitle = aria.carouselTitleLabel
      .replace('{{title}}', props.title)
      .trim();

    let classes = '';
    classes = props.isFullHeight ? 'vf-carousel--full-height' : '';
    classes = props.portraitOnly
      ? `${classes} vf-carousel--portrait-only`
      : classes;
    classes = isCustomsHome ? `${classes} vf-carousel__customs--home` : classes;

    const pushClickDataLayer = (eventLabel) => {
      // publish the events only if it is set in theme config
      if (!config.dispatchGtmEvents) {
        return;
      }
      dispatchEvent({
        eventName: 'loadEventData',
        overrideAttributes: {
          eventCategory: 'Customs',
          eventAction: 'Add Your Touch',
          eventLabel: eventLabel,
          nonInteractive: false,
          customMetrics: {},
          customVariables: {},
          _clear: true,
        },
      });
    };

    return {
      pushClickDataLayer,
      classes,
      isCustomsHome,
      carouselTitle,
    };
  },
  data() {
    return {
      slidesCount: this.$slots.default?.length || 0,
      carouselEl: null,
      shortCarouselHeight: 700, // only used for non-full-height sliders - avg initial height to minimize element jumping around during load
      sliderPaginationTop: -9000, // only used for vans customs - hides dot nav so it doesn't jump around on load
      sliderPaginationCssPosition: 'static', // this is the default
    };
  },
  computed: {
    carouselHeight() {
      return this.shortCarouselHeight;
    },
    carouselFullHeight() {
      if (!this.carouselEl) return 0;
      return (
        window.visualViewport.height -
        this.carouselEl.getBoundingClientRect().top
      );
    },
    currentBreakpointSlidesPerView() {
      const breakpoint = this.$viewport.breakpoints[this.$viewport.size];
      return this.responsiveOptions[breakpoint].slidesPerView;
    },
    responsiveOptions() {
      return {
        ...this.getBreakpointOptions('small'),
        ...this.getBreakpointOptions('medium'),
        ...this.getBreakpointOptions('large'),
      };
    },
    currentBreakpointStyles() {
      const breakpoint = this.$viewport.breakpoints[this.$viewport.size];
      const { spaceBetween, slidesPerView } = this.responsiveOptions[
        breakpoint
      ];

      return {
        '--slider-gap': spaceBetween,
        '--slider-items-count': slidesPerView,
      };
    },
    shouldShowPagination() {
      const enabled = this.controlsType === 'dots' || this.controlsType === '';
      return enabled && this.currentBreakpointSlidesPerView < this.slidesCount;
    },
    sliderPaginationPositionStyle() {
      return this.sliderPaginationCssPosition !== 'static'
        ? this.sliderPaginationCssPosition
        : 'static';
    },
    sliderPaginationTopStyle() {
      return this.sliderPaginationTop + 'px';
    },
  },
  mounted() {
    this.carouselEl = document.querySelector(
      "[data-testid='theme-smart-carousel']"
    );
  },
  methods: {
    getBreakpointOptions(breakpoint: Breakpoint) {
      const slidesPerView =
        (this.slidesPerView[breakpoint] > this.slidesCount
          ? this.slidesCount
          : this.slidesPerView[breakpoint]) || DEFAULT_SLIDERS_PER_VIEW;
      const spaceBetween = this.spaceBetween[breakpoint]
        ? `${this.spaceBetween[breakpoint]}px`
        : DEFAULT_SPACE_BETWEEN;

      return {
        [this.$viewport.breakpoints[breakpoint]]: {
          slidesPerView,
          spaceBetween,
        },
      };
    },
    async updateCustomsSlider() {
      if (this.carouselEl) {
        //only runs on event only emitted by Vans Customs Slider
        //  AND only if NOT full-height
        // if this is the Customs home slider, it only needs dots moved
        if (this.isCustomsHome) {
          this.updateDotNav();
        }
        if (this.isFullHeight) return 0;
        let ht = this.shortCarouselHeight;

        const slides = document.querySelectorAll(
          '.vf-customs-product-slide, .vf-customs-content-slide'
        );
        slides.forEach((slide) => {
          const newHt = slide.getBoundingClientRect().height;
          ht = newHt > ht ? newHt : ht;
        });

        // eliminiate race condition
        this.$nextTick(() => {
          // set a uniform slider height
          this.shortCarouselHeight = ht;
          this.updateDotNav();
        });
      }
    },
    updateDotNav() {
      //customs sliders will have dot nav all over the place
      if (!this.carouselEl) return 0;
      // initally set to the minimum for customs - we want it to start at -9000 so it doesn't jump around for sliders with other positions for  dot nav
      let topHt =
        this.sliderPaginationTop === -9000 ? 380 : this.sliderPaginationTop;
      // there will only be one
      let slideTop; // have a default val
      if (this.isCustomsHome) {
        // customs home
        slideTop = document.querySelector(
          '.carousel-slide .vf-customs-hp-slide'
        ) as HTMLElement;
        // images will not be the same height
        topHt = slideTop ? slideTop.offsetHeight - 20 : 0;
      } else {
        // we're in-store
        slideTop = document.querySelector(
          `.carousel-slide .vf-customs-product-slide__top,
            .carousel-slide .vf-customs-content-slide__top`
        ) as HTMLElement;
        // images will not be the same height
        topHt = slideTop ? slideTop.offsetHeight + 10 : 0;
      }

      // move pagination nav up
      this.sliderPaginationTop = topHt;
      this.sliderPaginationCssPosition = 'absolute';
    },
  },
});
