import { ComponentInstance } from '../types';
import { useCart } from '../useCart';
import useCheckout from '../useCheckout';
import { useDynatrace } from '../useDynatrace';
import { computed } from '@vue/composition-api';
import { apiClientFactory } from '@vf/api-client';
import { errorMessages } from '../../src/utils/errorMessages';
import { isAxiosApiError } from '../../src/utils/isAxiosApiError';

export const useCouponCode = (instance: ComponentInstance) => {
  const { cart, cartId, updateCartItems } = useCart(instance);
  const { getPaymentMethods } = useCheckout(instance);
  const { displayErrorMessages, getErrorDetails } = errorMessages(instance);
  const { pushActionToDynatrace, DynatraceActionType } = useDynatrace(instance);
  const {
    addCoupon: addCouponAPI,
    deleteCoupon: deleteCouponAPI,
  } = apiClientFactory(instance);
  const addCoupon = async (promoCode: string) => {
    try {
      const response = await addCouponAPI({
        cartId: cartId.value,
        code: promoCode.trim(),
      });
      await getPaymentMethods();
      await updateCartItems(response.data);
      return response;
    } catch (error) {
      if (
        isAxiosApiError(error) &&
        [
          'PRM130',
          'PRM135',
          'PRM140',
          'PRM150',
          'PRM155',
          'PRM160',
          'PRM416',
          'PRM175',
        ].includes(error.response.data.errorDetails[0].errorId)
      ) {
        sendErrorToDynatrace(error.response);
        return error.response;
      }
      displayErrorMessages(error);
    }
  };

  const removeCoupon = async (couponItemId) => {
    try {
      const response = await deleteCouponAPI({
        cartId: cartId.value,
        couponItemId,
      });
      await getPaymentMethods();
      await updateCartItems(response.data, true);
      return response;
    } catch (e) {
      displayErrorMessages(e);
    }
  };

  const sendErrorToDynatrace = (response) => {
    const errorsObjects = getErrorDetails(response.data.errorDetails);
    if (errorsObjects.length > 0) {
      errorsObjects.forEach((errorObject) => {
        pushActionToDynatrace(
          DynatraceActionType.API,
          errorObject.message,
          errorObject.errorMessageId,
          response.data.errorDetails?.[0].correlationId ||
            response.data.correlationId
        );
      });
    }
  };

  return {
    appliedPromoCodes: computed(() => cart.value.couponItems || []),
    addCoupon,
    removeCoupon,
  };
};
