

























































import type { PropType } from 'vue';
import {
  ref,
  onMounted,
  onBeforeUnmount,
  defineComponent,
  provide,
} from '@vue/composition-api';
import type { EmptyCartInfoTranslations } from '@vf/api-contract';
import { useCart } from '@vf/composables';
import ss from '@vf/composables/src/utils/sessionStorage';
import useRootInstance from '@/shared/useRootInstance';
import NotAddedItems from '@/components/checkout/NotAddedItems.vue';
import { scrollTo } from '@vf/shared/src/utils/helpers';

export default defineComponent({
  name: 'EmptyCartInfo',
  components: {
    NotAddedItems,
  },
  props: {
    showZeroItemsText: {
      type: Boolean,
      default: false,
    },
    translations: {
      type: Object as PropType<EmptyCartInfoTranslations>,
      default: () => ({}),
    },
    /** Empty cart heading level 1-6 */
    headingLevel: {
      type: Number,
      default: 2,
    },
    /** Continue shopping button URL */
    buttonUrl: {
      type: String,
      default: '/',
    },
    /** Flag to determine if CTA button should be shown */
    showCtaButton: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { root } = useRootInstance();
    const {
      isCartLoading,
      notAddedItems,
      showExpiredSharedCartError,
    } = useCart(root);
    const shareCartLinkExpired = ref(false);
    const showEmptyCartInfo = ref(false);

    provide('pickupOptions', {
      pickupLabelsMapping: [], // injected in: Organism.ShipmentSelector
      showStaticTextInsteadOfShippingLabel: false, // injected in: Organism.ShipmentSelector
      storeDistanceOptionList: [], // injected in: Organism.ProductAvailabilityModal
      storeNearFieldMaxLength: 0, // injected in: Organism.ProductAvailabilityModal
      unitOfMeasure: '', // injected in: Organism.ProductAvailabilityModal
      storeDistanceUnit: '', // injected in: Organism.ProductAvailabilityModal
      productAvailabilityModalCancelButtonClass: '', // injected in: Organism.ProductAvailabilityModal
      showStsInfoInProductAvailabilityModal: false, // injected in: Organism.ProductAvailabilityModal
    });

    onMounted(() => {
      shareCartLinkExpired.value = !!ss.getItem('shareCartExpiredError');
      showEmptyCartInfo.value = true;
      ss.removeItem('shareCartExpiredError');
      scrollTo();
    });

    onBeforeUnmount(showExpiredSharedCartError);

    return {
      isCartLoading,
      showEmptyCartInfo,
      notAddedItems,
      shareCartLinkExpired,
    };
  },
});
