





































import type { PropType } from 'vue';

import { computed, defineComponent } from '@vue/composition-api';
import { CheckoutContext } from '@vf/api-contract';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'CheckoutStep',
  props: {
    activeStep: String as PropType<CheckoutContext>,
    context: String as PropType<CheckoutContext>,
  },
  setup(props) {
    const { root } = useRootInstance();

    const heading = computed(() => {
      const translations = {
        [CheckoutContext.Shipping]: root.$t('checkoutStep.shippingAndDelivery'),
        [CheckoutContext.Payment]: root.$t('checkoutStep.payment'),
      };
      return translations[props.context];
    });

    const isActive = computed(() => {
      return props.activeStep === props.context;
    });

    const isDisabled = computed(() => {
      return (
        props.activeStep === CheckoutContext.Shipping &&
        props.context === CheckoutContext.Payment
      );
    });

    const isEditable = computed(() => {
      if (isActive.value) return false;
      if (props.activeStep === CheckoutContext.Shipping) {
        return props.context !== CheckoutContext.Payment;
      }
      return true;
    });

    return {
      CheckoutContext,
      heading,
      isActive,
      isDisabled,
      isEditable,
    };
  },
});
