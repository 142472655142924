




























































































































































import type { PropType } from 'vue';
import { computed, defineComponent } from '@vue/composition-api';
import type { DataListingTableTranslations } from '@vf/api-contract';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';
import useRootInstance from '@/shared/useRootInstance';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

type Item = {
  selected: boolean;
  addressLines: Array<{ isProtected: boolean; value: string }>;
  code: { isProtected: boolean; value: string };
};

export default defineComponent({
  name: 'DataListingTable',
  serverCacheKey: getCacheKeyFromProps,
  props: {
    translations: {
      type: Object as PropType<DataListingTableTranslations>,
      default: () => ({}),
    },
    /** Flag to determine if edit button should be shown */
    showEditButton: {
      type: Boolean,
      default: true,
    },
    /** Flag to determine if deletes button should be shown */
    showDeleteButton: {
      type: Boolean,
      default: true,
    },
    /** Flag to determine if selected item should be on top row*/
    showDefaultAddressOnTopRow: {
      type: Boolean,
      default: false,
    },
    /** Flag to determine if the subtitle be shown */
    showSubtitle: {
      type: Boolean,
      default: true,
    },

    /** List of available items */
    items: {
      type: Array as PropType<Item[]>,
      default: () => [],
    },
    /** ID of currently selected item */
    selectedItem: {
      type: String,
      default: null,
    },
    dividerBottom: {
      type: Boolean,
      default: false,
    },
    hideHeadings: {
      type: Boolean,
      default: false,
    },
    buttonsSpacingLineClass: {
      type: String,
      default: '',
    },
    addItemPageLink: {
      type: String,
      default: '',
    },
    editItemPageLinkGetter: {
      type: Function,
      default: undefined,
    },
    level: {
      type: Number,
      default: 4,
    },
  },
  emits: ['add-item', 'edit-item', 'delete-item'],
  setup(props, { emit }) {
    const { root } = useRootInstance();
    const featureFlags = useFeatureFlagsStore();
    const theme = featureFlags.isCoreRedesignEnabled
      ? {
          ...root.$themeConfig.dataListingTable,
          addItemButtonClass:
            'vf-button--large vf-button--mobile-full add-button',
        }
      : root.$themeConfig.dataListingTable;

    const setSelectedAddress = (item) => {
      emit('change-selected-item', item);
    };

    const getCodeColumnSize = computed(
      () =>
        2 +
        ((!props.showEditButton as unknown) as number) +
        ((!props.showDeleteButton as unknown) as number)
    );

    const headingSubtitle = computed(() => {
      return !theme.headingWithoutSubtitle && props.showSubtitle
        ? props.translations.subtitle
        : '';
    });

    const sortedItems = computed(() => {
      const items = props.showDefaultAddressOnTopRow
        ? [...props.items].sort((item) => {
            return item.selected ? -1 : 1;
          })
        : props.items;

      return items.map((item) => ({
        ...item,
        addressLines: item.addressLines
          .filter((line) => line)
          .map((line) => ({
            value: line.value,
            isProtected: line.isProtected,
          })),
      }));
    });

    return {
      setSelectedAddress,
      getCodeColumnSize,
      sortedItems,
      theme,
      headingSubtitle,
    };
  },
});
