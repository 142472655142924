


























import type { PropType } from 'vue';
import { defineComponent } from '@vue/composition-api';
import type { OrderPickupDetailsTranslation } from '@vf/api-contract';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'OrderPickupDetails',
  props: {
    translations: {
      type: Object as PropType<OrderPickupDetailsTranslation>,
      required: true,
    },
    /** Property to define if layout should be vertical (fe: Vans) or horizontal (fe: TNF) */
    isHorizontal: {
      type: Boolean,
      default: false,
    },
    /** Property to define level for VfHeading */
    headingLevel: {
      type: Number,
      default: 4,
    },
  },
  setup() {
    const { root } = useRootInstance();
    const theme = root.$themeConfig.OrderPickupDetails;

    return {
      headingStyles: theme?.headingStyles,
    };
  },
});
