









































































import { clearAllBodyScrollLocks } from 'body-scroll-lock';
import type { PropType } from 'vue';
import { Context, SignInFormTranslations } from '@vf/api-contract';
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
} from '@vue/composition-api';
import {
  ROUTES,
  useAccount,
  useAuthentication,
  useCart,
  useFavorites,
  useSearch,
  useGtm,
  useI18n,
  useNotification,
  useReCaptcha,
  useRouting,
  useSaveForLater,
  useLoyalty,
  useMonetate,
} from '@vf/composables';
import { useAddressesStore } from '@vf/composables/src/store/addresses';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import { useUserData } from '../../cms/cmsUtils';
import { PageTypeName } from '@vf/composables/src/useCms/types';
import { getRedirectUrlIfSafe } from '@vf/composables/src/utils/getRedirectUrlIfSafe';
import useAuthenticationEvents, {
  AuthenticationType,
} from '@/shared/useAuthenticationEvents';
import useModal from '@/shared/useModal';
import useRootInstance from '@/shared/useRootInstance';
import { useUserStore } from '@vf/composables/src/store/user';
import { storeToRefs } from 'pinia';
import { useCheckoutStore } from '@vf/composables/src/store/checkoutStore';

export default defineComponent({
  name: 'SignInForm',
  props: {
    translations: {
      type: Object as PropType<SignInFormTranslations>,
      required: true,
    },
    /** Props for create account form */
    createAccountTranslations: {
      type: Object,
      default: () => ({}),
    },
    /** Component Form Validations */
    validations: {
      type: Object,
      default: () => ({
        birthdayRegex: '',
        phoneNumberRegex: '',
        zipCodeRegex: '',
        zipCodeFormat: '',
        phoneNumberFormat: '',
        phoneNumberMask: '',
      }),
    },
    /** Form heading level 1-6 */
    headingLevel: { type: Number, default: 4 },
    interestsLink: String,
    /** Flag determining whether to show create account link or not */
    showCreateAccountLink: Boolean,
    /** Type of content to render */
    createAccountLinkType: { type: String, default: 'Modal' },
    /** Type of content to render */
    forgotPasswordLinkType: { type: String, default: 'Modal' },
    /** Flag determining whether to show social login links or not */
    showSocialLinks: { type: Boolean, default: true },
    /** Facebook image button URL */
    facebookUrl: String,
    /** Flag determining whether to show recaptch after 2 sign in attempts or not */
    showCaptcha: Boolean,
    /** Flag determining whether to show password visibility toggler or not */
    showPasswordToggler: Boolean,
    /** Show sms subscription checkbox below phone number **/
    showSmsSubscription: Boolean,
    /** Flag to redirect in cart after signIn */
    loginRedirectToCart: Boolean,
    checkoutModalLogin: Boolean,
    shippingTermsLink: {
      type: String,
      default: '',
    },
    contextKey: {
      type: String,
      required: true,
      default: Context.Modal,
    },
  },
  setup(props, { emit }) {
    const { root } = useRootInstance();
    const authentication = useAuthentication(root);
    const { getSaveForLaterItems } = useSaveForLater(root);
    const { previousRoute } = useRouting(root);
    const { localePath } = useI18n(root);
    const { cartId, cart, saveGuestCart, isCartMerged } = useCart(root);
    const {
      getBasicInformation,
      pageTypesWithDisplayedNotification,
      displayEmployeeDiscountNotification,
    } = useAccount(root);
    const { favoriteId } = useFavorites(root);
    const { setNotificationVisibility, closeModal } = useModal();
    const { clearNotifications } = useNotification(root);
    const { dispatchEvent, getFormLocation } = useGtm(root);
    const { redirectedUrl } = useSearch(root);
    const reCaptcha = useReCaptcha(root);
    const { getLoyaltyVouchers } = useLoyalty(root);
    const formLocation = ref(getFormLocation(props.contextKey));
    const { addCustomVars, getExperienceDecision } = useMonetate(
      root,
      props.contextKey
    );
    const userStore = useUserStore(root);
    const { loyaltyEnrolled } = storeToRefs(userStore);

    const { createSignIn } = useAuthenticationEvents(formLocation.value, {
      useLoadEvent: true,
      type: AuthenticationType.Login,
    });
    const {
      isCheckoutRedesignEnabled,
      isCheckoutSuccessRedesignEnabled,
      isPointsToCurrencyEnabled,
    } = useFeatureFlagsStore();

    const modalContent = ref('login');
    const signInAttempts = ref(0);
    const formSubmitted = ref(false);
    const createAccountSubmitted = ref(false);
    const modalVisible = ref(false);
    const isCreateAccountModalVisible = ref(false);
    const buttonDisabled = ref(false);

    const isCartPage = computed(() => root.$route.path.endsWith(ROUTES.CART()));
    const isCheckoutContext = computed(() => {
      if (!isCheckoutRedesignEnabled) return props.checkoutModalLogin;
      return root.$route.path.endsWith(ROUTES.CHECKOUT());
    });

    const isModal = computed(() => props.forgotPasswordLinkType === 'Modal');
    const isCreateAccountModal = computed(
      () => props.createAccountLinkType === 'Modal'
    );

    const isLoyaltyEnabled: string = root.$getEnvValueByCurrentLocale(
      'LOYALTY_IS_ENABLED'
    );
    const forgotPasswordLink: string = localePath(ROUTES.FORGOT_PASSWORD());
    const createAccountLink: string = localePath(
      ROUTES.ACCOUNT('create-account')
    );

    const changeModalContentType = (event) => {
      modalVisible.value = event === 'reset';
      if (event === 'create') {
        createAccountSubmitted.value = false;
        isCreateAccountModalVisible.value = true;
      }
    };

    const boundWithGtmEventsSignIn = createSignIn(authentication.signIn);

    const onSignIn = async (data) => {
      buttonDisabled.value = true;
      saveGuestCart();
      formSubmitted.value = true;

      try {
        clearNotifications();
        const checkout = useCheckoutStore();
        const signInStatus = await boundWithGtmEventsSignIn({
          ...data,
          ...(checkout.order && {
            action: 'postLogin',
            st: checkout.order.st,
            orderNo: checkout.order.orderNumber,
          }),
          guestObjects: {
            basketId: cartId.value,
            saveForLaterId: authentication.saveForLaterId.value,
            favoriteId: favoriteId.value,
          },
          formLocation: formLocation.value,
        });

        if (signInStatus) {
          await authentication.processPendingActionForLoyaltyUser();
          if (props.contextKey === Context.Modal) {
            clearAllBodyScrollLocks();
            closeModal();
            root.$eventBus.$emit('close-mobile-menu');
          }

          if (isCheckoutContext.value) {
            isCartMerged.value = true;

            const isShippingPage =
              root.$router.currentRoute.path ===
              localePath(ROUTES.CHECKOUT_SHIPPING());
            if (props.loginRedirectToCart && isShippingPage) {
              root.$router.push(localePath(ROUTES.CART()));
              /** Remove checkout page from already displayed employee notifications */
              displayEmployeeDiscountNotification(
                pageTypesWithDisplayedNotification.value.filter(
                  (pageName) => pageName !== PageTypeName.CHECKOUT
                )
              );
            }

            setNotificationVisibility(false);
            emit('signed-in');
            return;
          }

          const isPaymentPage =
            root.$router.currentRoute.path ===
            localePath(ROUTES.CHECKOUT_PAYMENT());

          if (isPaymentPage) {
            const address = cart.value.shippingMethods[0].address;
            useAddressesStore().saveShippingAddress(address);
          }

          await getBasicInformation();

          if (isPointsToCurrencyEnabled && loyaltyEnrolled.value) {
            await getLoyaltyVouchers();
          }

          if (authentication.saveForLaterId.value && isCartPage.value) {
            await getSaveForLaterItems();
          }

          handlePostLoginRedirect();
        }
        signInAttempts.value++;
      } catch (err) {
        root.$log.error(
          `[@/components/smart/account/SigninForm.vue::onSignIn] do sign in call in error`,
          err.message
        );
      } finally {
        buttonDisabled.value = false;
      }
    };

    const onSignUp = () => clearNotifications();

    const onCreateAccountModalClose = () => {
      isCreateAccountModalVisible.value = false;
      clearNotifications();
    };

    const onCreateAccountSuccess = () => {
      createAccountSubmitted.value = true;
    };

    const handlePostLoginRedirect = () => {
      if (redirectedUrl.value) {
        return root.$router.push(redirectedUrl.value);
      }

      if (props.contextKey === Context.Modal) {
        const userData = useUserData(root);
        addCustomVars(userData);
        getExperienceDecision(true);

        const isCheckoutSuccess = root.$route.path.endsWith(
          ROUTES.CHECKOUT_ORDER_STATUS()
        );

        if (isCheckoutSuccess && isCheckoutSuccessRedesignEnabled) {
          root.$router.push(root.localePath(ROUTES.HOME()));
        }

        /** Prevent any action if sign in happens from Modal context */
        return;
      }

      const stateRedirectTo = getRedirectUrlIfSafe(
        root.$route.query.redirectTo as string
      );
      const fallbackRedirect = localePath(
        ROUTES.ACCOUNT(isLoyaltyEnabled ? 'profile' : '')
      );
      const redirectTo = stateRedirectTo ?? previousRoute.value?.fullPath;

      return root.$router.push(redirectTo ?? fallbackRedirect);
    };

    onMounted(() => {
      reCaptcha.showBadge();
      // hack to workaround modal max height for iphone / mobile devices because of not respecting vh correctly with mobile toolbars
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });

    // Dispatch GTM event for form close
    onUnmounted(() => {
      reCaptcha.hideBadge();
      if (
        formLocation.value.includes('modal') &&
        !formSubmitted.value &&
        !createAccountSubmitted.value
      ) {
        dispatchEvent({
          eventName: 'loadEventData',
          overrideAttributes: {
            eventCategory: 'Account',
            eventAction: 'Login',
            eventLabel: 'Close',
            nonInteractive: false,
            customMetrics: {
              elementView: 1,
            },
            customVariables: {
              formLocation: formLocation.value,
            },
            _clear: true,
          },
        });
      }
    });

    return {
      minRegisterAge: root.$config.MINIMUM_REGISTER_AGE,
      formLocation,
      forgotPasswordLink,
      createAccountLink,
      isModal,
      isCreateAccountModal,
      isCreateAccountModalVisible,
      modalVisible,
      modalContent,
      buttonDisabled,
      onSignIn,
      onSignUp,
      onCreateAccountModalClose,
      onCreateAccountSuccess,
      changeModalContentType,
      signInAttempts,
    };
  },
});
