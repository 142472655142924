


























































import { storeToRefs } from 'pinia';
import { computed, defineComponent } from '@vue/composition-api';
import { useCartStore } from '@vf/composables/src/store/cartStore';
import AddressPreview from '@/components/static/AddressPreview.vue';
import useRootInstance from '@/shared/useRootInstance';
import CheckoutPickupTime from '@/components/static/checkout/pickup/CheckoutPickupTime.vue';
import { pluralize } from '@/helpers/pluralize';
import { replaceAll } from '@/helpers/replaceAll';
import CheckoutDeliveryTime from '@/components/static/checkout/pickup/CheckoutDeliveryTime.vue';

export default defineComponent({
  name: 'ShippingPreview',
  components: {
    CheckoutDeliveryTime,
    AddressPreview,
    CheckoutPickupTime,
  },
  setup() {
    const { root } = useRootInstance();

    const {
      shippingItems,
      inStoreItems,
      shippingAddress,
      pickupAddress,
      pickupPerson,
      selectedPickupStore,
      isMixedShippingAndPickup,
    } = storeToRefs(useCartStore());

    const shipToHomeLabel = computed(() => {
      const count = shippingItems.value.length;
      const label = root.$t(
        pluralize('shippingReview.shipToHome', count)
      ) as string;
      return replaceAll(label, { count });
    });

    const inStorePickupLabel = computed(() => {
      const count = inStoreItems.value.length;
      const label = root.$t(
        pluralize('shippingReview.inStorePickup', count)
      ) as string;
      return replaceAll(label, { count });
    });

    return {
      isMixedShippingAndPickup,
      shipToHomeLabel,
      shippingAddress,
      inStorePickupLabel,
      pickupAddress,
      pickupPerson,
      selectedPickupStore,
    };
  },
});
