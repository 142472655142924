























import { defineComponent } from '@nuxtjs/composition-api';
import {
  requestIdleCallback,
  cancelIdleCallback,
} from '@/helpers/idle-callback-polyfill';
import throttle from '@vf/shared/src/utils/helpers/throttle';

export default defineComponent({
  name: 'ProductGridRecycleList',
  props: {
    items: {
      type: Array,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
    viewport: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      active: false,
      lazyRows: [],
      observer: null,
      /** approximateHeight: { [key: GridConfigName]: string } */
      approximateHeight: {},
      idleCallbackId: undefined,
      isScrolled: false,
    };
  },
  computed: {
    virtualRows() {
      const perRow = 12 / this.$props.config.grid[this.$props.viewport];

      let currentRow = 0;
      let currentWidth = 0;
      return this.$props.items.reduce((acc, item, index) => {
        const isEspot = item?.espot;
        const espotWidth = getEspotWidth(this.$props.viewport, item);
        const maxEspotWidth = espotWidth > perRow ? perRow : espotWidth;

        const width = isEspot ? maxEspotWidth : 1;
        const id = isEspot ? `espot${index}` : item.id;

        if (currentWidth + width <= perRow) {
          currentWidth += width;
        } else {
          currentRow++;
          currentWidth = width;
        }

        const row = (acc[currentRow] = acc[currentRow] || {
          items: [],
          id: `row-${currentRow}`,
          dataProductIds: '',
        });

        const espot = acc.find((row) => row.dataProductIds.includes('espot'));
        const rowBeforeEspotIndex = acc.indexOf(espot) - 1;
        const rowBeforeEspotWidth = acc[rowBeforeEspotIndex]?.items.reduce(
          (acc, item) => (acc += item?.espot ? espotWidth : 1),
          0
        );
        if (espot && rowBeforeEspotWidth < perRow) {
          acc[rowBeforeEspotIndex].dataProductIds += `${
            row.items.length > 0 ? ',' : ''
          }${id}`;
          acc[rowBeforeEspotIndex].items.push({ ...item, index });
          currentWidth -= width;
        } else {
          row.dataProductIds += `${row.items.length > 0 ? ',' : ''}${id}`;
          row.items.push({ ...item, index });
        }

        return acc;
      }, []);
    },
  },
  mounted() {
    this.observeRows();

    this.throttledUpdateAllRows = throttle(this.updateAllRows, 50);
    window.addEventListener('resize', this.throttledUpdateAllRows);
  },
  updated() {
    this.observeRows();
  },
  beforeDestroy() {
    this.unobserveRows();

    window.removeEventListener('resize', this.throttledUpdateAllRows);
  },
  methods: {
    handleObserverAction(action: 'observe' | 'unobserve') {
      if (!this.$refs.rowsRef) return;

      if (action === 'observe' && !this.active) {
        this.observer = new IntersectionObserver(this.processEntries, {
          rootMargin: '50px',
        });

        this.active = true;
      }

      this.$refs.rowsRef.forEach((rowRef) => {
        this.observer[action](rowRef);
      });
    },
    observeRows() {
      this.handleObserverAction('observe');
    },
    unobserveRows() {
      this.handleObserverAction('unobserve');
    },
    getItemStyle(idx) {
      return {
        'min-height': this.getRowHeight(idx) ?? this.getApproximateRowHeight(),
      };
    },
    isRowVisible(rowIdx) {
      return !this.active || this.lazyRows[rowIdx]?.visible;
    },
    getRowHeight(idx) {
      return this.lazyRows[idx]?.height ?? this.lazyRows[0]?.height;
    },
    async tryToScrollFirstTime() {
      if (!this.isScrolled) {
        this.isScrolled = true;
        await this.$nextTick();
        this.$nuxt.$emit('customTriggerGridScroll');
      }
    },
    setApproximateRowHeight(height) {
      this.approximateHeight = {
        ...this.approximateHeight,
        [this.$props.config.name]: `${height}px`,
      };
      this.tryToScrollFirstTime();
    },
    getApproximateRowHeight() {
      return this.approximateHeight[this.$props.config.name] ?? '340px';
    },
    setLazyRowVisibility(idx, visibility) {
      idx === 0
        ? this.$set(this.lazyRows, idx, {
            ...this.lazyRows[idx],
            visible: true,
          })
        : this.$set(this.lazyRows, idx, {
            ...this.lazyRows[idx],
            visible: visibility,
          });
    },
    processEntries(entries) {
      entries.forEach((entry) => {
        const visibilityIndex = Number(
          entry.target.id.substring('row-'.length)
        );

        this.setLazyRowVisibility(visibilityIndex, entry.isIntersecting);
      });

      if (this.idleCallbackId) {
        cancelIdleCallback(this.idleCallbackId);
      }

      this.idleCallbackId = requestIdleCallback(this.updateAllRows, {
        // move the action into the event loop if nothing happens for 500ms
        timeout: 500,
      });
    },
    updateAllRows() {
      this.lazyRows.forEach((_, idx) => {
        this.updateRowHeight(idx);
      });
      this.$forceUpdate();
    },
    updateRowHeight(idx) {
      const row = this.$refs.rowsRef[idx];
      if (row?.childElementCount > 0) {
        row.style.minHeight = null;
        const maxHeight = Math.max(
          ...[].map.call(row.children, (el) => {
            const computedStyle = window.getComputedStyle(el);
            return [
              el.clientHeight,
              computedStyle.marginTop,
              computedStyle.marginBottom,
              computedStyle.borderTopWidth,
              computedStyle.borderBottomWidth,
            ].reduce((acc, s) => {
              acc += parseInt(s);
              return acc;
            }, 0);
          })
        );

        if (maxHeight > 10) {
          this.$set(this.lazyRows, idx, {
            ...this.lazyRows[idx],
            height: `${maxHeight}px`,
          });
          row.style.minHeight = this.lazyRows[idx].height;
          this.setApproximateRowHeight(maxHeight);
        }
      }
    },
  },
});

const getEspotWidth = (viewport, item) => {
  let espotWidth = 1;
  switch (viewport) {
    case 'large':
      espotWidth = parseInt(item['desktopWidth']);
      break;
    case 'medium':
      espotWidth = parseInt(item['tabletWidth']);
      break;
    case 'small':
      espotWidth = parseInt(item['mobileWidth']);
      break;
  }
  return isNaN(espotWidth) ? 1 : espotWidth;
};
