








































































































































































































































































































































































































































































































































import type { PropType } from 'vue';
import {
  computed,
  ref,
  onMounted,
  onUnmounted,
  watch,
  defineComponent,
} from '@vue/composition-api';
import {
  useSearch,
  useNotification,
  useRouting,
  useFilters,
  useCategory,
  useRequestTracker,
  useShippingFilter,
} from '@vf/composables';
import { FILTER_URL as SHIPPING_FILTER_URL } from '@vf/composables/src/useShippingFilter/utils';
import { plpStickyFilters } from '@/helpers/plpStickyFilters';
import useCms from '@vf/composables/src/useCms';
import * as urlDecoder from '@vf/composables/src/utils/urlDecoder';
import Notifications from '../layout/Notifications.vue';
import type { SearchFiltersTranslations } from '@vf/api-contract';
import {
  scrollTo as scrollToTop,
  isClient,
  getPriceFilterItemText,
} from '@vf/shared/src/utils/helpers';
import useRootInstance from '@/shared/useRootInstance';
import {
  getFilterType,
  getFilterClass,
} from '@vf/shared/src/utils/helpers/facetDisplayType';
import Filters from '../../Filters.vue';
import AccordionsExpander from '@/components/AccordionsExpander.vue';
import PriceRangeInput from '@/components/smart/shared/PriceRangeInput.vue';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import ColorsFilter from '@/components/smart/plp/ColorsFilter.vue';
import type { Filter } from '@vf/composables/src/types';

type FacetsOrder = {
  id: string;
};

type FilterCallback = (
  filter: Filter,
  update?: boolean,
  isOnlyGtmChanges?: boolean
) => void;

export default defineComponent({
  name: 'SearchFilters',
  components: {
    ColorsFilter,
    PriceRangeInput,
    AccordionsExpander,
    AccordionsExpanderRedesign: () =>
      import('@/components/AccordionsExpanderRedesign.vue'),
    Notifications,
    Filters,
    VfAccordionRedesign: () =>
      import('@vf/ui/components/Molecule.AccordionRedesign.vue'),
    VfShippingFilter: () =>
      import('@/components/static/plp/ShippingFilter.vue'),
  },
  props: {
    translations: {
      type: Object as PropType<SearchFiltersTranslations>,
      default: () => ({}),
    },
    /** Prop to decide whether accordion should be opened or closed on initial load, can be different for small, medium and large breakpoints  */
    accordionsOpen: {
      type: Object,
      default: () => ({ small: true, medium: true, large: true }),
    },
    /** Flag to define if filters panel is sticky or not  */
    sticky: {
      type: Boolean,
      default: true,
    },
    /** Flag to define if items count should be displayed next to filter item or not  */
    displayCount: {
      type: Boolean,
      default: true,
    },
    /** Prop to decide whether to show reset button or not  */
    showResetButton: {
      type: Boolean,
      default: false,
    },
    /** How many facets to show in accordion on small screens */
    filterNumberOfFacetsToShowSmall: {
      type: Number,
      default: 3,
    },
    /** How many facets to show in accordion on medium screens */
    filterNumberOfFacetsToShowMedium: {
      type: Number,
      default: 7,
    },
    /** How many facets to show in accordion on large screens */
    filterNumberOfFacetsToShowLarge: {
      type: Number,
      default: 7,
    },
    /** How many facets to show in accordion */
    filterNumberOfFacetsToShow: {
      type: Number,
      default: null,
    },
    /** Display variant for size filters (text|chips) */
    sizeFiltersVariant: {
      type: String,
      default: 'text',
      validator: (value: string) => {
        return ['text', 'chips'].includes(value);
      },
    },
    contextKey: {
      type: String,
      default: '',
    },
    facetsOrder: {
      type: Array as PropType<FacetsOrder[]>,
      default: () => [],
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    // TODO: Cleanup in GLOBAL15-63799
    const {
      isCoreRedesignEnabled,
      isVansPlpRedesignEnabled,
    } = useFeatureFlagsStore();
    const { mapOrderFilters } = useCategory(root, props.contextKey);
    const { isEnableShippingFilter } = useShippingFilter(root);

    const {
      changeSort,
      isFilterSidebarOpen,
      setFilterSidebarOpen,
      filteringOptions,
      meta,
      removeFilter,
      resetFilters,
      selectFilter,
      resetFilter,
      selectedFilters,
      selectedSortingOptionId,
      sortingOptions,
      products,
      resetPagination,
      updateSearch,
      pagination,
    } = useSearch(root);
    const {
      getFiltersOptions,
      isViewAllVisible,
      getButtonText,
      toggleVisibilityAllFacets,
      visibleAllFacets,
      setFacetLimits,
      getFilterItemValue,
      isFilterFacetOverriden,
      getFilterItemImageIcon,
      isFacetLimitDefined,
      resetVisibleAllFacets,
      isColorFilter,
      isRangeFilter,
      createRangeFilter,
    } = useFilters(root);
    const { facetConfiguration } = useCms(root, props.contextKey);
    const { clearNotifications } = useNotification(root);
    const {
      currentQueryParams,
      didUrlChange,
      didQueryParametersChange,
    } = useRouting(root);
    const { onBaseDone } = useRequestTracker(root);
    const showResetFiltersButton = ref<number>();
    const isSortModalOpen = ref(false);

    watch(currentQueryParams, () => {
      if (
        !didUrlChange() &&
        didQueryParametersChange(['f', SHIPPING_FILTER_URL])
      ) {
        resetPagination();
        updateSearch();
      }
    });

    let destroyStickyFilter = () => null;
    onMounted(() => {
      onBaseDone(() => {
        showResetFiltersButton.value =
          props.showResetButton && selectedFilters.value.length;
        orderedFilteringOptions.value.forEach((filter) => {
          const allVariantsVisible =
            facetConfiguration.value[filter.code]?.showAllVariants;
          if (allVariantsVisible) toggleVisibilityAllFacets(filter);
        });
      });
      if (props.sticky)
        destroyStickyFilter = plpStickyFilters({
          mobileTargetEl: document.querySelector<HTMLElement>(
            '.vf-plp-wrapper__left'
          ),
          root,
          wrapEl: document.querySelector<HTMLElement>('.vf-plp-wrapper'),
        });
    });

    onUnmounted(() => {
      resetVisibleAllFacets();
      destroyStickyFilter();
    });

    const activeSortingOption = computed(() => {
      const option = translatedSortingOptions.value.find(
        (option) => option.value === selectedSortingOptionId.value
      );

      if (option) {
        return option.label;
      }
      return '';
    });

    const changeActiveSortOption = (sortOptionId) => {
      changeSort(sortOptionId);
      updateSearch();
      isSortModalOpen.value = false;
    };

    const getFacetLink = (filter) => {
      return urlDecoder.getFilterQueryLink(root, selectedFilters.value, filter);
    };

    setFacetLimits({
      filterNumberOfFacetsToShow: props.filterNumberOfFacetsToShow,
      filterNumberOfFacetsToShowLarge: props.filterNumberOfFacetsToShowLarge,
      filterNumberOfFacetsToShowMedium: props.filterNumberOfFacetsToShowMedium,
      filterNumberOfFacetsToShowSmall: props.filterNumberOfFacetsToShowSmall,
    });

    const fetchFilterOptions = () => getFiltersOptions(filteringOptions);
    const filtersOptions = ref(fetchFilterOptions());

    const onCloseModal = () => {
      clearNotifications();
      isSortModalOpen.value = false;
    };

    watch(selectedFilters, () => {
      showResetFiltersButton.value =
        props.showResetButton && selectedFilters.value.length;
    });

    watch(products, () => {
      // we need to recalculate as the change of products list may also change
      // the relation between filters and products height
      window?.dispatchEvent(new CustomEvent('re-calculate-scroll'));
    });

    const defaultFacetType = computed(
      () => root.$themeConfig.facetDisplayType?.defaultFacetDisplayType
    );

    const orderedFilteringOptions = computed(() => {
      if (props.facetsOrder) {
        const order = props.facetsOrder.map((item) => item.id);
        return mapOrderFilters(filteringOptions.value, order, 'code');
      }
      return filteringOptions.value;
    });

    const allAccordionsExpanded = ref(false);

    const initialOpenedFilterAccordions = computed(() => {
      if (root.$viewport?.size === 'small') return 0;
      return isVansPlpRedesignEnabled
        ? 1
        : root.$themeConfig.categoryFilters?.initialOpenedFilterAccordions ||
            filteringOptions.value.length;
    });

    const fetchOpenAccordionItems = () =>
      orderedFilteringOptions.value.reduce((filters, filter, i) => {
        const openByDefault =
          allAccordionsExpanded.value ||
          i < initialOpenedFilterAccordions.value ||
          selectedFilters.value.some((e) => filter.code === e.code);

        if (openByDefault) filters.push(filter.code);

        return filters;
      }, []);

    const openAccordionItems = ref(fetchOpenAccordionItems());

    watch(openAccordionItems, (items) => {
      allAccordionsExpanded.value =
        items.length === orderedFilteringOptions.value.length;
    });

    watch(
      [isFacetLimitDefined, visibleAllFacets],
      () => {
        filtersOptions.value = fetchFilterOptions();
      },
      { deep: true }
    );

    let lastSelectedFilter = '';

    const handleSelectMobile = ({ code }, value: string) => {
      lastSelectedFilter = code;
      selectFilter({ code, value });
    };

    const handleRemoveMobile = ({ code }, value: string) => {
      lastSelectedFilter = code;
      removeFilter({ code, value });
    };

    const scrollMobileFilterIntoView = (code?: string) => {
      if (!isClient) return;
      const filter = document.getElementById(
        `mobile-filter-${code || lastSelectedFilter}`
      );
      filter?.scrollIntoView();
    };

    watch(orderedFilteringOptions, () => {
      filtersOptions.value = fetchFilterOptions();
      root.$nextTick(scrollMobileFilterIntoView);
      openAccordionItems.value = fetchOpenAccordionItems();
    });

    const translatedSortingOptions = computed(() => {
      return sortingOptions.value.map((option) => {
        return {
          value: option.value,
          label: props.translations.sortOptions[option.value] || option.label,
        };
      });
    });

    const toggleAccordionsExpander = () => {
      openAccordionItems.value = allAccordionsExpanded.value
        ? []
        : orderedFilteringOptions.value.map(({ code }) => code);

      window?.dispatchEvent(new CustomEvent('re-calculate-scroll'));
      filtersOptions.value = fetchFilterOptions();
    };

    const trackAccordion = (code: string) => {
      openAccordionItems.value.includes(code)
        ? (openAccordionItems.value = openAccordionItems.value.filter(
            (c: string) => c !== code
          ))
        : openAccordionItems.value.push(code);

      window?.dispatchEvent(new CustomEvent('re-calculate-scroll'));
      filtersOptions.value = fetchFilterOptions();

      // if filter code is equal to the last item then scroll it into view
      if (
        code ===
        orderedFilteringOptions.value[orderedFilteringOptions.value.length - 1]
          .code
      ) {
        root.$nextTick(() => scrollMobileFilterIntoView(code));
      }
    };

    const getFilterItemType = (code: string, variant: string) => {
      return getFilterType(
        code,
        variant,
        facetConfiguration.value,
        defaultFacetType.value,
        isFilterFacetOverriden(
          code,
          'chip',
          facetConfiguration.value,
          defaultFacetType.value
        ),
        isFilterFacetOverriden(
          code,
          'checkbox',
          facetConfiguration.value,
          defaultFacetType.value
        ),
        isFilterFacetOverriden(
          code,
          'thumbnail',
          facetConfiguration.value,
          defaultFacetType.value
        )
      );
    };

    const getFilterItemClass = (code: string, variant: string) => {
      return getFilterClass(
        code,
        variant,
        'search',
        facetConfiguration.value,
        defaultFacetType.value,
        isFilterFacetOverriden(
          code,
          'chip',
          facetConfiguration.value,
          defaultFacetType.value
        ),
        isFilterFacetOverriden(
          code,
          'checkbox',
          facetConfiguration.value,
          defaultFacetType.value
        ),
        isFilterFacetOverriden(
          code,
          'thumbnail',
          facetConfiguration.value,
          defaultFacetType.value
        )
      );
    };

    const isFilterSelected = (filter, item, selectedFilters) => {
      return selectedFilters.some(
        (selectedFilter) =>
          selectedFilter.code === filter.code &&
          selectedFilter.value === item.id
      );
    };
    const isAccordionOpen = (key: string) =>
      openAccordionItems.value.includes(key);

    const productsQtyLabel = computed(() => {
      const translation =
        pagination.value.total > 1
          ? props.translations.productsQuantityPlural
          : props.translations.productsQuantitySingle;
      const label = translation
        ? translation.replace('{{quantity}}', `${pagination.value.total}`)
        : '' + pagination.value.total;
      return isVansPlpRedesignEnabled ? label.toLowerCase() : label;
    });

    const rangeFilter = createRangeFilter(
      props.translations.priceRangeInputLabels?.maximumDisplayPrice,
      resetFilter,
      selectFilter
    );

    const handleApplyBtnClick = () => {
      scrollToTop();
      setFilterSidebarOpen(false);
    };

    const handleFilterClick = (callback: FilterCallback) => ({
      code,
      value,
    }) => {
      const hasFacetOverride = isFilterFacetOverriden(
        code,
        'thumbnail',
        facetConfiguration.value,
        defaultFacetType.value
      );
      callback({ code, value }, hasFacetOverride, !hasFacetOverride);
    };

    return {
      rangeFilter,
      isColorFilter,
      isEnableShippingFilter,
      isRangeFilter,
      changeActiveSortOption,
      wrappingComponent: props.sticky ? 'VfSticky' : 'div',
      trackAccordion,
      meta,
      filteringOptions,
      resetFilters,
      removeFilter,
      translatedSortingOptions,
      selectFilter,
      selectedFilters,
      activeSortingOption,
      selectedSortingOptionId,
      isFilterSidebarOpen,
      setFilterSidebarOpen,
      handleApplyBtnClick,
      isSortModalOpen,
      filtersActive: false,
      products,
      visibleAllFacets,
      filtersOptions,
      getButtonText,
      toggleVisibilityAllFacets,
      isViewAllVisible,
      onCloseModal,
      showResetFiltersButton,
      getFacetLink,
      getFilterType,
      getFilterClass,
      facetConfiguration,
      defaultFacetType,
      getFilterItemType,
      getFilterItemClass,
      toggleAccordionsExpander,
      allAccordionsExpanded,
      orderedFilteringOptions,
      isAccordionOpen,
      productsQtyLabel,
      getFilterItemValue,
      getFilterItemImageIcon,
      isFilterFacetOverriden,
      handleSelectMobile,
      handleRemoveMobile,
      handleFilterClick,
      isCoreRedesignEnabled,
      isFilterSelected,
      isVansPlpRedesignEnabled,
      getPriceFilterItemText,
    };
  },
});
