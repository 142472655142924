import { computed } from '@nuxtjs/composition-api';
import { ComponentInstance } from '../types';
import { PaymentMethodCode } from '@vf/api-client';
import { CheckoutContext } from '@vf/api-contract';
import { useRequestTracker } from '../useRequestTracker';
import { usePayPal } from '../usePayPal';
import { useKlarna } from '../useKlarna';
import { ApplePayContext } from '../useApplePay/types';

const usePaymentMethods = (instance: ComponentInstance) => {
  const { isRequestOngoing } = useRequestTracker(instance);
  const { payPalPaymentInProgress } = usePayPal(instance);
  const { isKlarnaSdkLoaded, loadKlarnaSdk, getKlarnaToken } = useKlarna(
    instance
  );

  const arePaymentMethodsDisabled = computed(() => {
    const requests = [
      'useCart-prepareOrder',
      'useKlarna-getKlarnaToken',
      'usePayPal-initPayPalSession',
      'useApplePay-onpaymentauthorized',
      'useGiftCards-applyPaymentInstrument',
      'usePaymentInstruments-getPaymentInstruments',
      'CreditCardForm-onLoadCyberSource',
      'CreditCardForm-loadAdyen',
    ];
    return (
      requests.some((request) => isRequestOngoing(request)) ||
      payPalPaymentInProgress.value
    );
  });

  const initKlarna = async () => {
    if (!isKlarnaSdkLoaded.value) {
      await loadKlarnaSdk();
    }
    await getKlarnaToken();
  };

  type PaymentMethodTranslations = Record<
    PaymentMethodCode | any,
    {
      label: string;
      tooltip: string;
      introText: string;
    }
  >;

  const getMethodTranslations = (
    methodCode: PaymentMethodCode
  ): PaymentMethodTranslations => {
    return (instance.$t(`paymentMethods.${methodCode}`) ??
      {}) as PaymentMethodTranslations;
  };

  const paymentMethodHandlers = {
    [PaymentMethodCode.PAYPAL]: {
      icon: { name: 'paypal', width: 83, height: 20 },
      name: getMethodTranslations(PaymentMethodCode.PAYPAL).name,
      isLabelDisplayed: false,
      tooltipData: getMethodTranslations(PaymentMethodCode.PAYPAL).tooltip,
      handler: {
        component: 'PayPal',
        props: {
          context: CheckoutContext.Payment,
          header: getMethodTranslations(PaymentMethodCode.PAYPAL).introText,
        },
      },
    },
    [PaymentMethodCode.KLARNA]: {
      icon: { name: 'klarna', width: 63, height: 14 },
      name: getMethodTranslations(PaymentMethodCode.KLARNA).name,
      label: getMethodTranslations(PaymentMethodCode.KLARNA).label,
      isLabelDisplayed: true,
      handler: { component: 'Klarna', props: {}, init: initKlarna },
    },
    [PaymentMethodCode.APPLEPAY]: {
      icon: { name: 'apple_pay', width: 47, height: 20 },
      name: getMethodTranslations(PaymentMethodCode.APPLEPAY).name,
      isLabelDisplayed: true,
      tooltipData: getMethodTranslations(PaymentMethodCode.APPLEPAY).tooltip,
      handler: {
        component: 'ApplePay',
        props: {
          showHeader: true,
          applePayTranslations: getMethodTranslations(
            PaymentMethodCode.APPLEPAY
          ).applePayTranslations,
          buttonClass:
            'checkout-payment-variants__apple-pay-button vf-button--apple-pay-primary vf-button--large',
          context: ApplePayContext.CHECKOUT,
        },
      },
    },
    [PaymentMethodCode.GIFT_CARD]: {
      icon: { name: 'card', width: 35, height: 23 },
      name: getMethodTranslations(PaymentMethodCode.GIFT_CARD).name,
      isLabelDisplayed: true,
      handler: {},
    },
    [PaymentMethodCode.CREDIT_CARD]: {
      icon: { name: 'creditCard', width: 24, height: 24 },
      name: getMethodTranslations(PaymentMethodCode.CREDIT_CARD).name,
      isLabelDisplayed: true,
      handler: { component: 'CreditCardSelector', props: {} },
    },
    [PaymentMethodCode.REWARD_CARD]: {
      icon: { name: 'creditCard', width: 24, height: 24 },
      name: getMethodTranslations(PaymentMethodCode.REWARD_CARD).name,
      isLabelDisplayed: true,
      handler: {},
    },
    [PaymentMethodCode.REWARD_CODE]: {
      icon: { name: 'creditCard', width: 24, height: 24 },
      name: getMethodTranslations(PaymentMethodCode.REWARD_CODE).name,
      isLabelDisplayed: true,
      handler: {},
    },
    [PaymentMethodCode.LOYALTY_POINTS]: {
      icon: { name: 'vansFamily', width: 24, height: 24 },
      name: getMethodTranslations(PaymentMethodCode.LOYALTY_POINTS).name,
      isLabelDisplayed: true,
      handler: {},
    },
  };

  const getPaymentMethodHandler = (code: PaymentMethodCode) => {
    const handler = paymentMethodHandlers[code];
    if (!handler) {
      instance.$log.error(
        `[@vf/composables/usePaymentMethods::getPaymentMethodHandler()] Payment method with code ${code} has no handler.`
      );
      return null;
    }
    return handler;
  };

  return {
    arePaymentMethodsDisabled,
    getPaymentMethodHandler,
    paymentMethodHandlers,
  };
};

export default usePaymentMethods;
