









import { defineComponent, onMounted, ref } from '@vue/composition-api';
import useRootInstance from '@/shared/useRootInstance';
import { useSignInToStore } from '@vf/composables';

export default defineComponent({
  name: 'StoreEmployeeInfo',
  setup() {
    const { root } = useRootInstance();
    const {
      employeeDetails,
      fetchStoreEmployees,
      storeEmployees,
    } = useSignInToStore(root);

    const employeeName = ref(null);
    const storeId = employeeDetails.value.storeId;

    onMounted(async () => {
      await fetchStoreEmployees(employeeDetails.value.storeId);
      employeeName.value = storeEmployees.value.find(
        (employee) => employee.id === employeeDetails.value.employeeId
      )?.name;
    });

    return {
      storeId,
      employeeName,
    };
  },
});
