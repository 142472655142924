




















































































































































































































































































































































































































































































































































import type { PropType } from 'vue';
import {
  computed,
  ref,
  onMounted,
  onUnmounted,
  watch,
  defineComponent,
} from '@vue/composition-api';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import type { CategoryFiltersTranslations } from '@vf/api-contract';
import {
  useCategory,
  useNotification,
  useRouting,
  useFilters,
  useRequestTracker,
  useI18n,
  useShippingFilter,
} from '@vf/composables';
import * as urlDecoder from '@vf/composables/src/utils/urlDecoder';
import Notifications from '../layout/Notifications.vue';
import useRootInstance from '@/shared/useRootInstance';
import {
  getFilterType,
  getFilterClass,
} from '@vf/shared/src/utils/helpers/facetDisplayType';
import { plpStickyFilters } from '@/helpers/plpStickyFilters';
import Filters from '../../Filters.vue';
import { useCmsRefStore } from '@vf/composables/src/store/cmsRef';
import AccordionsExpander from '@/components/AccordionsExpander.vue';
import PriceRangeInput from '@/components/smart/shared/PriceRangeInput.vue';
import ColorsFilter from './ColorsFilter.vue';
import {
  scrollTo as scrollToTop,
  isClient,
  getPriceFilterItemText,
} from '@vf/shared/src/utils/helpers';
import type { Filter } from '@vf/composables/src/types';

type FacetsOrder = {
  id: string;
};

type FilterCallback = (
  filter: Filter,
  update?: boolean,
  isOnlyGtmChanges?: boolean
) => void;

export default defineComponent({
  name: 'CategoryFilters',
  components: {
    ColorsFilter,
    PriceRangeInput,
    AccordionsExpander,
    AccordionsExpanderRedesign: () =>
      import('@/components/AccordionsExpanderRedesign.vue'),
    Notifications,
    Filters,
    VfAccordionRedesign: () =>
      import('@vf/ui/components/Molecule.AccordionRedesign.vue'),
    VfShippingFilter: () =>
      import('@/components/static/plp/ShippingFilter.vue'),
  },
  props: {
    translations: {
      type: Object as PropType<CategoryFiltersTranslations>,
      default: () => ({}),
    },
    /** Prop to decide whether accordion should be opened or closed on initial load, can be different for small, medium and large breakpoints  */
    accordionsOpen: {
      type: Object,
      default: () => ({ small: true, medium: true, large: true }),
    },
    /** Flag to define if filters panel is sticky or not  */
    sticky: {
      type: Boolean,
      default: true,
    },
    /** Flag to define if items count should be displayed next to filter item or not  */
    displayCount: {
      type: Boolean,
      default: true,
    },
    /** Prop to decide whether to show reset button or not */
    showResetButton: {
      type: Boolean,
      default: false,
    },
    /** How many facets to show in accordion on small screens */
    filterNumberOfFacetsToShowSmall: {
      type: Number,
      default: null,
    },
    /** How many facets to show in accordion on medium screens */
    filterNumberOfFacetsToShowMedium: {
      type: Number,
      default: null,
    },
    /** How many facets to show in accordion on large screens */
    filterNumberOfFacetsToShowLarge: {
      type: Number,
      default: null,
    },
    /** How many facets to show in accordion */
    filterNumberOfFacetsToShow: {
      type: Number,
      default: null,
    },
    /** Display variant for size filters (text|chips) */
    sizeFiltersVariant: {
      type: String,
      default: 'text',
      validator: (value: string) => {
        return ['text', 'chips'].includes(value);
      },
    },
    facetsOrder: {
      type: Array as PropType<FacetsOrder[]>,
      default: () => [],
    },
    contextKey: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    // TODO: Cleanup in GLOBAL15-63799
    // TODO: GLOBAL15-61059 remove after redesign core
    const {
      isCoreRedesignEnabled,
      isVansPlpRedesignEnabled,
    } = useFeatureFlagsStore();

    const {
      applyFilters,
      title,
      pageMetaTitle,
      customPageDescription,
      filteringOptions,
      isFilterSidebarOpen,
      setFilterSidebarOpen,
      pagination,
      resetFilters,
      resetFilter,
      removeFilter,
      selectFilter,
      selectedFilters,
      sortedFilters,
      sortingOptions,
      loading,
      changeSort,
      getCatalog,
      setActivePage,
      selectedSortingOptionId,
      mapOrderFilters,
      meta,
      updateCatalog,
      getMetaDescription,
      products,
    } = useCategory(root, props.contextKey);
    const { defaultCurrency } = useI18n(root);
    const { clearNotifications } = useNotification(root);
    const { currentQueryParams, didFiltersOrSortingOrStoreChange } = useRouting(
      root
    );
    const { isEnableShippingFilter } = useShippingFilter(root);
    const {
      getFiltersOptions,
      isViewAllVisible,
      getButtonText,
      toggleVisibilityAllFacets,
      visibleAllFacets,
      setFacetLimits,
      getFilterItemValue,
      isFilterFacetOverriden,
      getFilterItemImageIcon,
      isFacetLimitDefined,
      resetVisibleAllFacets,
      isColorFilter,
      isRangeFilter,
      createRangeFilter,
    } = useFilters(root);
    const { onBaseDone } = useRequestTracker(root);
    const cmsRefStore = useCmsRefStore(root.$pinia);

    const facetConfiguration = root.$getEnvValueByCurrentLocale(
      'STATIC_LAYOUT'
    )['plp']
      ? root.$themeConfig.staticPLP.facetConfig
      : cmsRefStore.facetConfiguration;

    watch(currentQueryParams, () => {
      if (didFiltersOrSortingOrStoreChange()) {
        updateCatalog();
      }
    });

    const isResetButtonVisible = () =>
      props.showResetButton && selectedFilters.value.length > 0;

    const mobileSortVisible = ref(false);
    const showResetFiltersButton = ref(isResetButtonVisible());

    let destroyStickyFilter = () => null;
    onMounted(() => {
      onBaseDone(() => {
        showResetFiltersButton.value = isResetButtonVisible();
        orderedFilteringOptions.value.forEach((filter) => {
          const allVariantsVisible =
            facetConfiguration[filter.code]?.showAllVariants;
          if (allVariantsVisible) toggleVisibilityAllFacets(filter);
        });

        filtersOptions.value = fetchFilterOptions();
      });
      if (props.sticky) {
        const mobileTargetEl = document.querySelector<HTMLElement>(
          '.category-filters-container'
        );
        if (!mobileTargetEl) return;
        destroyStickyFilter = plpStickyFilters({
          mobileTargetEl,
          root,
          wrapEl: mobileTargetEl.parentElement,
        });
      }
    });

    onUnmounted(() => {
      resetVisibleAllFacets();
      destroyStickyFilter();
    });

    const orderedFilteringOptions = computed(() => {
      if (props.facetsOrder) {
        const order = props.facetsOrder.map((item) => item.id);
        return mapOrderFilters(filteringOptions.value, order, 'code');
      }
      return filteringOptions.value;
    });

    const allAccordionsExpanded = ref(false);

    const initialOpenedFilterAccordions = computed(() => {
      if (root.$viewport?.size === 'small') return 0;
      return isVansPlpRedesignEnabled
        ? 1
        : root.$themeConfig.categoryFilters?.initialOpenedFilterAccordions ||
            filteringOptions.value.length;
    });

    const fetchOpenAccordionItems = () =>
      orderedFilteringOptions.value.reduce((filters, filter, i) => {
        const openByDefault =
          allAccordionsExpanded.value ||
          i < initialOpenedFilterAccordions.value ||
          selectedFilters.value.some((e) => filter.code === e.code);

        if (openByDefault) filters.push(filter.code);

        return filters;
      }, []);

    const openAccordionItems = ref(fetchOpenAccordionItems());

    watch(openAccordionItems, (items) => {
      allAccordionsExpanded.value =
        items.length === orderedFilteringOptions.value.length;
    });

    setFacetLimits({
      filterNumberOfFacetsToShow: props.filterNumberOfFacetsToShow,
      filterNumberOfFacetsToShowLarge: props.filterNumberOfFacetsToShowLarge,
      filterNumberOfFacetsToShowMedium: props.filterNumberOfFacetsToShowMedium,
      filterNumberOfFacetsToShowSmall: props.filterNumberOfFacetsToShowSmall,
    });

    const fetchFilterOptions = () => getFiltersOptions(filteringOptions);
    const filtersOptions = ref({});

    const translatedSortingOptions = computed(() =>
      sortingOptions.value.map((item) => ({
        value: item.value,
        label: props.translations.sortOptions[item.value],
      }))
    );
    const selectedSortingOptionLabel = computed(() => {
      const option = sortingOptions.value.find(
        (opt) => opt.value === selectedSortingOptionId.value
      );

      if (option) {
        return props.translations.sortOptions[option.value];
      }

      return '';
    });
    const changeActiveSortOption = (sortOptionId) => {
      changeSort(sortOptionId);
      setActivePage(1);
      getCatalog();
      mobileSortVisible.value = false;
    };

    const getFacetLink = (filter) => {
      return urlDecoder.getFilterQueryLink(root, selectedFilters.value, filter);
    };

    const isFilterSelected = (filter, item, selectedFilters) => {
      return selectedFilters.some(
        ({ code, value }) => filter.code === code && item.id === value
      );
    };
    const isAccordionOpen = (key: string) =>
      openAccordionItems.value.includes(key);

    const onCloseModal = () => {
      clearNotifications();
      mobileSortVisible.value = false;
    };

    watch(selectedFilters, () => {
      showResetFiltersButton.value = isResetButtonVisible();
    });

    watch(products, () => {
      // we need to recalculate as the change of products list may also change
      // the relation between filters and products height
      window?.dispatchEvent(new CustomEvent('re-calculate-scroll'));
    });

    watch(
      [isFacetLimitDefined, visibleAllFacets],
      () => {
        filtersOptions.value = fetchFilterOptions();
      },
      { deep: true }
    );

    let lastSelectedFilter = '';

    const handleSelectMobile = ({ code }, value: string) => {
      lastSelectedFilter = code;
      selectFilter({ code, value });
    };

    const handleRemoveMobile = ({ code }, value: string) => {
      lastSelectedFilter = code;
      removeFilter({ code, value });
    };

    const scrollMobileFilterIntoView = (code?: string) => {
      if (!isClient) return;
      const filter = document.getElementById(
        `mobile-filter-${code || lastSelectedFilter}`
      );
      filter?.scrollIntoView();
    };

    watch(orderedFilteringOptions, () => {
      filtersOptions.value = fetchFilterOptions();
      openAccordionItems.value = fetchOpenAccordionItems();
      root.$nextTick(scrollMobileFilterIntoView);
    });

    const pageMetaDescription = computed(() => {
      return (
        customPageDescription.value ||
        getMetaDescription(
          title.value,
          sortedFilters.value,
          cmsRefStore.cmsSiteConfiguration?.commerceConfig.brand,
          props.translations.shopAt
        )
      );
    });

    const defaultFacetType = computed(
      () => root.$themeConfig.facetDisplayType?.defaultFacetDisplayType
    );

    const toggleAccordionsExpander = () => {
      openAccordionItems.value = allAccordionsExpanded.value
        ? []
        : orderedFilteringOptions.value.map(({ code }) => code);

      window?.dispatchEvent(new CustomEvent('re-calculate-scroll'));
      filtersOptions.value = fetchFilterOptions();
    };

    const trackAccordion = (code: string) => {
      openAccordionItems.value.includes(code)
        ? (openAccordionItems.value = openAccordionItems.value.filter(
            (c: string) => c !== code
          ))
        : openAccordionItems.value.push(code);

      window?.dispatchEvent(new CustomEvent('re-calculate-scroll'));
      filtersOptions.value = fetchFilterOptions();

      // if filter code is equal to the last item then scroll it into view
      if (
        code ===
        orderedFilteringOptions.value[orderedFilteringOptions.value.length - 1]
          .code
      ) {
        root.$nextTick(() => scrollMobileFilterIntoView(code));
      }
    };

    const getFilterItemType = (code: string, variant: string) => {
      return getFilterType(
        code,
        variant,
        facetConfiguration,
        defaultFacetType.value,
        isFilterFacetOverriden(
          code,
          'chip',
          facetConfiguration,
          defaultFacetType.value
        ),
        isFilterFacetOverriden(
          code,
          'checkbox',
          facetConfiguration,
          defaultFacetType.value
        ),
        isFilterFacetOverriden(
          code,
          'thumbnail',
          facetConfiguration,
          defaultFacetType.value
        )
      );
    };

    const getFilterItemClass = (code: string, variant: string) => {
      return getFilterClass(
        code,
        variant,
        'category',
        facetConfiguration,
        defaultFacetType.value,
        isFilterFacetOverriden(
          code,
          'chip',
          facetConfiguration,
          defaultFacetType.value
        ),
        isFilterFacetOverriden(
          code,
          'checkbox',
          facetConfiguration,
          defaultFacetType.value
        ),
        isFilterFacetOverriden(
          code,
          'thumbnail',
          facetConfiguration,
          defaultFacetType.value
        )
      );
    };

    const productsQtyLabel = computed(() => {
      const translation =
        pagination.value.total > 1
          ? props.translations.productsQuantityPlural
          : props.translations.productsQuantitySingle;
      return translation
        ? translation.replace('{{quantity}}', `${pagination.value.total}`)
        : pagination.value.total;
    });

    const rangeFilter = createRangeFilter(
      props.translations.priceRangeInputLabels?.maximumDisplayPrice,
      resetFilter,
      selectFilter
    );

    const handleApplyBtnClick = () => {
      scrollToTop();
      setFilterSidebarOpen(false);
    };

    const handleFilterClick = (callback: FilterCallback) => ({
      code,
      value,
    }) => {
      const hasFacetOverride = isFilterFacetOverriden(
        code,
        'thumbnail',
        facetConfiguration,
        defaultFacetType.value
      );
      callback({ code, value }, hasFacetOverride, !hasFacetOverride);
    };

    return {
      isColorFilter,
      isEnableShippingFilter,
      rangeFilter,
      isRangeFilter,
      pageMetaTitle,
      pageMetaDescription,
      trackAccordion,
      loading,
      changeActiveSortOption,
      isAccordionOpen,
      applyFilters,
      filteringOptions,
      productsQtyLabel,
      resetFilters,
      removeFilter,
      sortingOptions,
      selectFilter,
      selectedSortingOptionLabel,
      selectedSortingOptionId,
      selectedFilters,
      mobileSortVisible,
      isFilterSidebarOpen,
      setFilterSidebarOpen,
      filtersActive: false,
      isViewAllVisible,
      getButtonText,
      toggleVisibilityAllFacets,
      filtersOptions,
      visibleAllFacets,
      onCloseModal,
      orderedFilteringOptions,
      showResetFiltersButton,
      getFacetLink,
      translatedSortingOptions,
      getFilterType,
      getFilterClass,
      facetConfiguration,
      defaultFacetType,
      toggleAccordionsExpander,
      allAccordionsExpanded,
      getFilterItemType,
      getFilterItemClass,
      getFilterItemImageIcon,
      getFilterItemValue,
      isFilterFacetOverriden,
      handleSelectMobile,
      handleRemoveMobile,
      handleApplyBtnClick,
      handleFilterClick,
      meta,
      isFilterSelected,
      isVansPlpRedesignEnabled,
      isCoreRedesignEnabled,
      getPriceFilterItemText,
      defaultCurrency,
    };
  },
  head() {
    return {
      title: this.pageMetaTitle,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.pageMetaDescription,
        },
      ],
    };
  },
});
