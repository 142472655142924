





































import { defineComponent, ref } from '@vue/composition-api';
import { useValidation } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import { required } from 'vuelidate/lib/validators';
import { email } from '@vf/shared/src/utils/helpers/validators';
import SimplifiedEnrollmentHeader from './SimplifiedEnrollmentHeader.vue';

export default defineComponent({
  name: 'SimplifiedEnrollmentEmailIntake',
  components: {
    SimplifiedEnrollmentHeader,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { emit }) {
    const { root } = useRootInstance();
    const { setValidation, $v } = useValidation(root, 'EMAIL_INTAKE_FORM');
    const email = ref(null);

    const submit = () => {
      $v.value.$touch();

      if ($v.value.$invalid) {
        return;
      }

      emit('submit-email', email.value);
    };

    return {
      email,
      submit,
      setValidation,
    };
  },
  mounted() {
    this.setValidation(this.$v);
  },
  validations() {
    return {
      email: {
        required,
        email,
      },
    };
  },
});
