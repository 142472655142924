var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showCartProductList),expression:"showCartProductList"}],staticClass:"cart-product-list",class:{ 'cart-product-list--no-border': _vm.showOnlyErrorInfo }},[(!_vm.showOnlyErrorInfo)?_c('NotAddedItems',{attrs:{"translations":_vm.translations,"not-added-items":_vm.notAddedItems}}):_vm._e(),_vm._v(" "),_c('table',{staticClass:"cart-products",class:{
      'cart-products--collapsed': _vm.collapsed,
      'cart-products--only-error-info': _vm.showOnlyErrorInfo,
    },attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id']),"aria-label":((_vm.cart.totalItems) + " " + (_vm.translations.itemsCount || ''))}},[(!_vm.showOnlyErrorInfo)?[_c('caption',[_c('button',{directives:[{name:"focus",rawName:"v-focus"}],attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id'], 'resourceBundle')},on:{"click":function($event){_vm.collapsed = !_vm.collapsed}}},[_c('span',[_c('span',[_vm._v(_vm._s(_vm.cart.totalItems))]),_vm._v("\n            "+_vm._s(_vm.translations.itemsCount)+"\n          ")]),_vm._v(" "),_c('VfIcon',{attrs:{"icon":_vm.collapsed ? 'plus' : 'minus'}})],1)]),_vm._v(" "),(_vm.cart.totalItems)?_c('thead',[_c('tr',_vm._l((['itemDescription', 'quantity', 'price', 'total']),function(label){return _c('th',{key:label,attrs:{"id":label,"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id'], 'resourceBundle')}},[_vm._v("\n            "+_vm._s(_vm.translations[label])+"\n          ")])}),0)]):_vm._e(),_vm._v(" "),(_vm.isPriceAdjusted && _vm.priceOverrideSuccessMessage)?_c('tbody',[_c('tr',{staticClass:"cart-products__notification-wrapper"},[_c('td',{staticClass:"cart-products__notification",attrs:{"colspan":_vm.theme.tableColspan}},[_c('VfNotification',{staticClass:"notification--price-override",attrs:{"type":"success","message":_vm.priceOverrideSuccessMessage,"visible":""},on:{"click-close":function($event){_vm.priceOverrideSuccessMessage = ''}}})],1)])]):_vm._e(),_vm._v(" "),(_vm.theme.showFavoritesNotificationOnCart && _vm.favoritesMessage)?_c('tbody',[_c('tr',{staticClass:"cart-products__notification-wrapper"},[_c('td',{staticClass:"cart-products__notification_favorites",attrs:{"colspan":_vm.theme.tableColspan}},[_c('VfNotification',{class:_vm.theme.notificationClass,attrs:{"type":"success","message":_vm.favoritesMessage,"show-close-button":false,"html":"","visible":""}})],1)])]):_vm._e()]:_vm._e(),_vm._v(" "),(
        _vm.showGeneralErrorNotification ||
        _vm.showOnlyErrorInfo ||
        ((_vm.theme.showGeneralFlashNotificationOnCart ||
          !_vm.isProductNoLongerAvailable) &&
          _vm.productRelatedFlashMessages.length)
      )?_c('tbody',[_c('tr',[_c('td',{staticClass:"cart-products__product-with-error-notification",class:{
            'cart-products__product-with-error-notification--only-error-info': _vm.showOnlyErrorInfo,
          },attrs:{"colspan":_vm.theme.tableColspan}},[_c('VfNotification',{attrs:{"type":"danger","message":_vm.cartErrorObject
                ? _vm.cartErrorObject.message
                : _vm.translations.productItemHasErrorMessage,"show-close-button":false,"visible":""}})],1)])]):_vm._e(),_vm._v(" "),(_vm.showOnlyErrorInfo)?_c('VfAccordion',{staticClass:"vf-accordion--gray-background vf-accordion--with-border",attrs:{"header":_vm.translations.accordionLabel,"is-open":_vm.cartProductListAccordion},on:{"toggle":function($event){_vm.cartProductListAccordion = !_vm.cartProductListAccordion}}},[_vm._l((_vm.finalRegularProducts),function(cartProduct,i){return _c('CartProduct',_vm._b({key:("regular-product-" + (cartProduct.id) + "-" + i),staticClass:"cart-products__product",attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id'], 'componentConfig'),"product":cartProduct,"max-quantity":_vm.getMaxQuantity(cartProduct, _vm.maxQuantity),"show-save-to-favorites-button":!_vm.isFavorite(cartProduct.id) && _vm.showSaveToFavoritesButton,"row-flash-message":_vm.theme.showSuccessMessagePerRow &&
          _vm.findRowNotification(cartProduct.id),"flash-errors":_vm.getProductFlashErrors(cartProduct),"customer-notifications":_vm.getProductCustomerNotifications(cartProduct),"is-hidden":_vm.isProductHidden(cartProduct.isDeleted, cartProduct)},on:{"click-edit":function($event){return _vm.editProduct(cartProduct)},"click-remove":function($event){return _vm.removeProduct(cartProduct, i)},"click-save-to-favorites":function($event){return _vm.moveToFavorites(cartProduct)},"click-save-for-later":function($event){return _vm.addSaveForLaterAction(cartProduct)},"click-edit-gift-option":_vm.editGiftOption,"click-remove-gift-option":_vm.removeGiftOption,"click-change-quantity":_vm.updateItemAction,"override-price":_vm.overrideProductPrice,"update-price":_vm.updateProductPrice,"reset-price":_vm.resetProductPrice}},'CartProduct',_vm.cartProductBindings,false))}),_vm._v(" "),_c('tbody',[(_vm.finalCustomProducts.length)?_c('tr',{staticClass:"cart-products__section-title"},[_c('td',{staticClass:"cart-products__section-title",attrs:{"colspan":_vm.theme.tableColspan}},[_c('VfSeparator',{staticClass:"vf-cart-products__separator"},[_vm._v("\n              "+_vm._s(_vm.translations.customs)+"\n            ")])],1)]):_vm._e()]),_vm._v(" "),_vm._l((_vm.finalCustomProducts),function(customProduct,i){return _c('CartProduct',_vm._b({key:("custom-product-" + (customProduct.id) + "-" + i),class:[
          'cart-products__product',
          !i && 'cart-products__product--first' ],attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id'], 'componentConfig'),"product":customProduct,"override-image":_vm.getOverrideImage(customProduct, _vm.$root),"show-save-to-favorites-button":!_vm.isFavorite(customProduct.id) && _vm.showSaveToFavoritesButton,"flash-errors":_vm.getProductFlashErrors(customProduct),"customer-notifications":_vm.getProductCustomerNotifications(customProduct),"is-hidden":_vm.isProductHidden(false, customProduct)},on:{"click-edit":function($event){return _vm.editProduct(customProduct)},"click-remove":function($event){return _vm.removeProduct(customProduct, i)},"click-save-to-favorites":function($event){return _vm.moveToFavorites(customProduct)},"click-move-to-cart":function($event){return _vm.$emit('click-move-to-cart', customProduct)},"click-save-for-later":function($event){return _vm.addSaveForLaterAction(customProduct)},"click-edit-gift-option":_vm.editGiftOption,"click-remove-gift-option":_vm.removeGiftOption,"click-change-quantity":_vm.updateItemAction,"override-price":_vm.overrideProductPrice,"update-price":_vm.updateProductPrice,"reset-price":_vm.resetProductPrice}},'CartProduct',_vm.cartProductBindings,false))})],2):[_vm._l((_vm.finalRegularProducts),function(cartProduct,i){return _c('CartProduct',_vm._b({key:("regular-product-" + (cartProduct.id) + "-" + i),class:[
          'cart-products__product',
          !i && 'cart-products__product--first' ],attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id'], 'componentConfig'),"product":cartProduct,"max-quantity":_vm.getMaxQuantity(cartProduct, _vm.maxQuantity),"show-save-to-favorites-button":!_vm.isFavorite(cartProduct.id) && _vm.showSaveToFavoritesButton,"row-flash-message":_vm.theme.showSuccessMessagePerRow &&
          _vm.findRowNotification(cartProduct.id),"flash-errors":_vm.getProductFlashErrors(cartProduct),"customer-notifications":_vm.getProductCustomerNotifications(cartProduct),"is-hidden":_vm.isProductHidden(cartProduct.isDeleted, cartProduct),"show-availability":false},on:{"click-edit":function($event){return _vm.editProduct(cartProduct)},"click-remove":function($event){return _vm.removeProduct(cartProduct, i)},"click-save-to-favorites":function($event){return _vm.moveToFavorites(cartProduct)},"click-save-for-later":function($event){return _vm.addSaveForLaterAction(cartProduct)},"click-edit-gift-option":_vm.editGiftOption,"click-remove-gift-option":_vm.removeGiftOption,"click-change-quantity":_vm.updateItemAction,"override-price":_vm.overrideProductPrice,"update-price":_vm.updateProductPrice,"reset-price":_vm.resetProductPrice},scopedSlots:_vm._u([(
            cartProduct.shippingOptions && cartProduct.shippingOptions.length
          )?{key:"shipmentSelector",fn:function(ref){
          var isGiftOptionAdded = ref.isGiftOptionAdded;
return [_c('VfShipmentSelector',{attrs:{"translations":_vm.translations,"product-id":cartProduct.productId,"shipment-options":cartProduct.shippingOptions || [],"gift":isGiftOptionAdded,"is-prosumer":_vm.isIpaProsumer || _vm.isWranxProsumer},on:{"open-shipment-store-modal":function($event){return _vm.onOpenShipmentStoreModal(cartProduct, isGiftOptionAdded)},"change:shipping-option":function($event){return _vm.updateItemShippingOption(cartProduct, $event)},"find-store":_vm.getBopisStores}})]}}:null],null,true)},'CartProduct',_vm.cartProductBindings,false))}),_vm._v(" "),(_vm.finalCustomProducts.length)?_c('tbody',[_c('tr',{staticClass:"cart-products__section-title"},[_c('td',{staticClass:"cart-products__section-title",attrs:{"colspan":_vm.theme.tableColspan}},[_c('VfSeparator',{staticClass:"vf-cart-products__separator"},[_vm._v("\n              "+_vm._s(_vm.translations.customs)+"\n            ")])],1)])]):_vm._e(),_vm._v(" "),_vm._l((_vm.finalCustomProducts),function(customProduct,i){return _c('CartProduct',_vm._b({key:("custom-product-" + (customProduct.id) + "-" + i),class:[
          'cart-products__product',
          !i && 'cart-products__product--first' ],attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id'], 'componentConfig'),"product":customProduct,"override-image":_vm.getOverrideImage(customProduct, _vm.$root),"show-save-to-favorites-button":!_vm.isFavorite(customProduct.id) && _vm.showSaveToFavoritesButton,"flash-errors":_vm.getProductFlashErrors(customProduct),"customer-notifications":_vm.getProductCustomerNotifications(customProduct),"is-hidden":_vm.isProductHidden(false, customProduct)},on:{"click-edit":function($event){return _vm.editProduct(customProduct)},"click-remove":function($event){return _vm.removeProduct(customProduct, i)},"click-save-to-favorites":function($event){return _vm.moveToFavorites(customProduct)},"click-move-to-cart":function($event){return _vm.$emit('click-move-to-cart', customProduct)},"click-save-for-later":function($event){return _vm.addSaveForLaterAction(customProduct)},"click-edit-gift-option":_vm.editGiftOption,"click-remove-gift-option":_vm.removeGiftOption,"click-change-quantity":_vm.updateItemAction,"override-price":_vm.overrideProductPrice,"update-price":_vm.updateProductPrice,"reset-price":_vm.resetProductPrice}},'CartProduct',_vm.cartProductBindings,false))})]],2),_vm._v(" "),(!_vm.showOnlyErrorInfo)?[_c('SavedForLaterProducts',{class:{
        'cart-products--collapsed': _vm.collapsed,
        'cart-products--only-error-info': _vm.showOnlyErrorInfo,
      },attrs:{"is-cart-empty":!_vm.finalRegularProducts.length,"translations":_vm.translations},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var sflProducts = ref.sflProducts;
      var updateSflProduct = ref.updateSflProduct;
      var removeSflProduct = ref.removeSflProduct;
      var moveSflProductToCart = ref.moveSflProductToCart;
return _vm._l((sflProducts),function(sflProduct,i){return _c('CartProduct',_vm._b({key:("sfl-" + (sflProduct.itemId)),class:[
          'cart-products__product',
          !i && 'cart-products__product--first' ],attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id'], 'componentConfig'),"product":sflProduct,"show-save-for-later-button":false,"show-move-to-cart-button":"","show-save-to-favorites-button":!_vm.isFavorite(sflProduct.id) && _vm.showSaveToFavoritesButton,"row-flash-message":_vm.theme.showSuccessMessagePerRow &&
          _vm.findRowNotification(sflProduct.itemId),"flash-errors":_vm.getRelatedFlashErrors(
            sflProducts.find(function (p) { return p.id === sflProduct.id; })
          ),"show-only-error-info":false,"is-hidden":_vm.isProductHidden(sflProduct.isRemovedFromSaveForLater, sflProduct)},on:{"click-edit":function($event){return _vm.editProduct(sflProduct, true)},"click-remove":function($event){return removeSflProduct(sflProduct, i)},"click-save-to-favorites":function($event){return _vm.moveToFavorites(sflProduct, _vm.FavoritesContext.SaveForLater)},"click-move-to-cart":function($event){return moveSflProductToCart(sflProduct)},"click-change-quantity":updateSflProduct,"override-price":_vm.overrideProductPrice,"update-price":_vm.updateProductPrice,"reset-price":_vm.resetProductPrice}},'CartProduct',_vm.cartProductBindings,false))})}}],null,false,3940922927)})]:_vm._e(),_vm._v(" "),(!_vm.showOnlyErrorInfo && _vm.showAddGiftOptionCta)?_c('GiftOptionModal',{attrs:{"translations":_vm.translations.addGiftOption}}):_vm._e(),_vm._v(" "),(_vm.shouldRenderShipmentStoreModal)?_c('ShipmentStoreModal',{attrs:{"translations":_vm.translations.storeAvailabilityModal,"pristine":_vm.cartProductBindings.bopisModalPristine},on:{"find-store":_vm.getBopisStores,"select-store":function($event){return _vm.updateItemShippingOption(_vm.selectedShipmentStoreModalItem, $event)}}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }