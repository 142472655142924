















import { watch, ref, defineComponent } from '@nuxtjs/composition-api';
import { Color } from '@vf/api-client';
import { WidthAttributeValue } from '@vf/api-contract';
import {
  useProduct,
  useProductInventory,
  useI18n,
  useGtm,
  useMonetate,
} from '@vf/composables';
import { getId } from '@vf/composables/src/useUrl/handlers/parseUrl';
import { addOtherVariantOptionsToColorUrl } from '@/helpers';
import { useUserData } from '@/components/cms/cmsUtils';
import useRootInstance from '@/shared/useRootInstance';
import ProductColors from '@/components/pdp/ProductColors/ProductColors.vue';
import CmsProps from '@/components/pdp/ProductColors/CmsProps';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

const checkWideBadges = (badges = [], wide = false) => {
  const lowerCasedBadges = badges.map((badge) => badge.toLowerCase());

  return wide
    ? lowerCasedBadges.includes('wide')
    : !lowerCasedBadges.includes('wide');
};

export default defineComponent({
  name: 'PdpProductColors',
  components: { ProductColors },
  props: {
    /** Select with page type */
    contextKey: {
      type: String,
      default: 'product',
    },
    /** Flag to determine if product is displayed in swap cart item modal */
    isSwapCartItemModal: {
      type: Boolean,
      default: false,
    },
    lazy: {
      type: Boolean,
      default: false,
    },
    /** Will show a skeleton loading animation while it's true */
    isLoading: {
      type: Boolean,
      default: false,
    },
    ...CmsProps,
  },
  setup(props) {
    const { root } = useRootInstance();
    const {
      product,
      setInitialColor,
      getProductDetails,
      configure,
      loadPdpGalleryImages,
      isQuickShopContext,
      hasColorPriceWithDiscount,
      colorsGroupedByPriceDiscount,
      isWideAvailable,
      attributePriceVariationClickAction,
      getColorByCode,
    } = useProduct(root, props.contextKey);
    const { getProductInventory } = useProductInventory(root, props.contextKey);
    const { pdpColorChangeWithoutRefresh } = useFeatureFlagsStore();
    const { dispatchEvent } = useGtm(root);
    const { getExperienceDecision, addCustomVars } = useMonetate(
      root,
      props.contextKey
    );
    const { localePath } = useI18n(root);
    const loadingNewProductOnQuickshop = ref(false);

    const getProductId = (selectedColor: Color, quickshop = false) => {
      if (quickshop && root.$themeConfig?.productColors?.useSlugForQuickshop) {
        return selectedColor.slug;
      }

      if (selectedColor.pageURL) {
        return getId(selectedColor.pageURL).split('?')[0];
      }

      return '';
    };

    const isSameProductUrl = (id: string) => {
      return id.toLocaleLowerCase() === product.value.id.toLocaleLowerCase();
    };

    const fetchProductData = async (
      selectedColor: Color,
      quickshop: boolean
    ) => {
      if (!selectedColor.pageURL) {
        return;
      }
      const productId = getProductId(selectedColor, quickshop).toUpperCase();
      if (isSameProductUrl(productId)) {
        await loadPdpGalleryImages({
          isBackgroundRequest: false,
          configuredColor: selectedColor.value,
          allowColorOutsideOfVariants: false,
        });
      } else if (quickshop || pdpColorChangeWithoutRefresh) {
        loadingNewProductOnQuickshop.value = quickshop;

        await Promise.all([
          getProductDetails(productId, {
            isBackgroundRequest: false,
            loadImages: true,
            saveVariation: true,
            configuredColor: selectedColor.value,
            showLoadingState: quickshop,
          }),
          getProductInventory(productId, undefined, quickshop),
        ]);
        loadingNewProductOnQuickshop.value = false;
      }
    };

    const dispatchGtmEvent = (selectedColor: Color) => {
      const eventLabel =
        selectedColor && selectedColor.label.includes(selectedColor.slug)
          ? selectedColor.label
          : `${product.value.id} - ${selectedColor?.label}`;

      dispatchEvent({
        eventName: 'loadEventData',
        overrideAttributes: {
          eventCategory: 'PDP',
          eventAction: 'Color Click',
          eventLabel,
        },
      });
    };

    const setQueryParamsForColor = async (
      selectedColor: Color,
      isSameProduct: boolean
    ) => {
      const routerMethod = root.$themeConfig.productColors.replaceHistory
        ? 'replace'
        : 'push';

      if (!selectedColor.pageURL) {
        await root.$router[routerMethod](
          localePath(`/product/${selectedColor.slug}`)
        );
        return;
      }

      if (isSameProduct) {
        if (props.historyReplaceOnColorChange) {
          const query = root.$themeConfig?.productColors
            ?.addVariantOptionToUrlQueryParams
            ? {
                color: selectedColor.value,
                size: product.value.size?.value,
                length: product.value.length?.value,
              }
            : { color: selectedColor.value };

          await root.$router.replace({
            query,
          });
        } else {
          root.$router.push({
            query: {
              color: selectedColor.value,
            },
          });
        }
      } else {
        let routerPushArg = root.$themeConfig?.productColors
          ?.addVariantOptionToUrlQueryParams
          ? addOtherVariantOptionsToColorUrl(
              root,
              selectedColor.pageURL,
              product.value
            )
          : selectedColor.pageURL;

        if (pdpColorChangeWithoutRefresh) {
          // Appends the keep position parameter like this instead of using router.query,
          // because the method above is also appending in the url
          routerPushArg += `${
            routerPushArg.indexOf('?') < 0 ? '?' : '&'
          }keepPosition=true`;
        }

        if (routerMethod === 'replace') {
          await root.$router[routerMethod](localePath(routerPushArg));
        } else {
          root.$router[routerMethod](routerPushArg);
        }
      }
    };

    const setVariant = async (selectedColor: Color) => {
      if (!selectedColor) {
        return;
      }

      attributePriceVariationClickAction('color');

      dispatchGtmEvent(selectedColor);

      const isSameProduct = isSameProductUrl(
        getProductId(selectedColor, isQuickShopContext.value)
      );

      await fetchProductData(selectedColor, isQuickShopContext.value);

      if (!isQuickShopContext.value) {
        if (pdpColorChangeWithoutRefresh)
          root.$data.pdpKey = root.$data.oldPdpKey;

        await setQueryParamsForColor(selectedColor, isSameProduct);
      }
    };

    const selectedValue = ref('');

    watch(
      () => product.value?.id,
      (id) => {
        if (!id) {
          return;
        }

        if (!isQuickShopContext.value) {
          setInitialColor(root.$router.history.current?.query?.color as string);
        } else {
          if (
            !root.$themeConfig.productColors
              ?.addVariantOptionToUrlQueryParams ||
            !getColorByCode(product.value.color?.value || '')
          ) {
            setInitialColor();
          }
        }

        selectedValue.value = product.value.color?.value || '';
      },
      {
        immediate: true,
      }
    );

    const userData = useUserData(root);

    return {
      product,
      hasColorPriceWithDiscount,
      isWideAvailable,
      colorsGroupedByPriceDiscount,
      setVariant,
      userData,
      addCustomVars,
      getExperienceDecision,
      configure,
      selectedValue,
      loadingNewProductOnQuickshop,
      getProductId,
      isSameProductUrl,
    };
  },
  head() {
    return {
      meta: [this.metaOgImage],
    };
  },
  computed: {
    metaOgImage() {
      let imageUrl = '';
      if (this.product?.id) {
        const colorCode = this.$root.$themeConfig.openGraph
          ?.isColorNeededForOgImage
          ? (this.$root.$route.query?.color as string) ??
            this.product.color?.value
          : '';
        const colorName = colorCode
          ? this.product.colors?.find((color) => color.value === colorCode)
              ?.label
          : '';
        imageUrl = this.$root.$mediaUrlGenerator({
          colorCode,
          colorName,
          shotType: this.$root.$env.IMAGES_SHOTTYPE,
          pid: this.product.id,
          preset: '',
          productName: this.product.name,
        });
      }

      return {
        hid: 'og:image',
        name: 'og:image',
        content: imageUrl,
      };
    },
    sortedColorsGroupedByPriceDiscount() {
      return this.colorsGroupedByPriceDiscount
        .filter((group) => group.colors.length > 0)
        .sort((a, b) => b.currentPrice - a.currentPrice);
    },
    hasSwatchGroups() {
      return this.colorsGroupedByPriceDiscount.length > 1;
    },
    availableColors() {
      if (!this.product) {
        return [];
      }

      const colorOptions =
        this.product.attributes.find(({ code }) => code === 'color')?.options ||
        [];

      const filterColorsByWidth =
        this.$root.$env.PRODUCT_COLORS_FILTER_BY_WIDTH === 'true';

      if (filterColorsByWidth && this.isWideAvailable) {
        const wide = this.product.width?.value === WidthAttributeValue.WIDE;

        return colorOptions.filter((color) =>
          checkWideBadges(color.badges, wide)
        );
      }

      return colorOptions;
    },
    label() {
      if (this.loadingNewProductOnQuickshop || this.isLoading) {
        return '';
      }
      return this.product?.color?.label || '';
    },
    isSplit() {
      return (
        this.$props.splitColorsWatchForDiscount &&
        (this.hasColorPriceWithDiscount ||
          this.sortedColorsGroupedByPriceDiscount.length > 1) &&
        this.hasSwatchGroups
      );
    },
    selectedColor() {
      const available = this.availableColors?.find(
        (color) => color.value === this.selectedValue
      );

      return available || this.availableColors[0];
    },
  },
  methods: {
    async handleChange(color) {
      if (this.selectedValue !== color) {
        this.selectedValue = color;
        this.configure({ color });
        await this.setVariant(this.selectedColor);
        this.addCustomVars(this.userData);
        await this.getExperienceDecision(true);
      }
    },
  },
});
