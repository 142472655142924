













































import type { PropType } from 'vue';
import { computed, defineComponent, watch } from '@vue/composition-api';
import { Context } from '@vf/api-contract';
import { Product } from '@vf/api-client';
import useRootInstance from '@/shared/useRootInstance';
import useModal from '@/shared/useModal';
import { isClient, parseProductId } from '@vf/shared/src/utils/helpers';
import { ProductCrossSellItem } from '@vf/api-client';
import { useGtm, useProduct, useProductInventory } from '@vf/composables';
import { getEventFromTemplate } from '@vf/composables/src/useGtm/helpers';
import { EnhancedSaleType } from '@vf/composables/src/types/gtm';

export interface ProductCrossSellTranslations {
  nextCta: string;
  prevCta: string;
  title: string;
}

export default defineComponent({
  name: 'VfProductCrossSell',
  props: {
    contextKey: {
      type: String,
      default: 'page-content',
    },
    quickShopLink: {
      type: String,
      required: true,
    },
    translations: {
      type: Object as PropType<ProductCrossSellTranslations>,
      required: true,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { product, pdpImages } = useProduct(root, props.contextKey);
    const { product: quickShopProduct, toggleQuickShop } = useProduct(
      root,
      Context.QuickShop
    );
    const { getProductInventory } = useProductInventory(
      root,
      Context.QuickShop
    );
    const { modalProps, openModal, setModalProps } = useModal();
    const { dispatchEvent, dispatchEventAfter } = useGtm(root);
    const ITEMS_IN_ONE_VIEW = 4;

    const heroImage = computed(() => pdpImages.value?.[0]);

    // return 4 elements for each column
    const crossSellProductsGrid = computed<ProductCrossSellItem[][]>(() => {
      const crossSellProducts =
        product.value?.attributes
          .find(({ code }) => code === 'color')
          .options.find(({ value }) => value === product.value.color.value)
          ?.crossSellProducts?.map((crossSellProduct) => {
            const {
              highOriginal,
              highCurrent,
              lowOriginal,
              lowCurrent,
            } = crossSellProduct.c_price;
            return {
              id: crossSellProduct.id,
              name: crossSellProduct.name,
              description: crossSellProduct.description,
              pageUrl: crossSellProduct.c_product_slug,
              price: {
                currency: product.value.variant.price.currency,
                original: highOriginal,
                current: highCurrent,
                discount: highOriginal - highCurrent,
                basePriceRange: {
                  maxPrice: highOriginal,
                  minPrice: lowOriginal,
                },
                salePriceRange: {
                  maxPrice: highCurrent,
                  minPrice: lowCurrent,
                },
              },
              colorCode: crossSellProduct.c_colorCode,
            };
          }) || [];
      const productsGrid: ProductCrossSellItem[][] = [];
      let pos = 0;

      for (let i = 0; i < crossSellProducts.length && i < 8; i++) {
        pos = Math.floor(i / ITEMS_IN_ONE_VIEW);
        if (productsGrid[pos] === undefined) {
          productsGrid[pos] = [];
        }
        productsGrid[pos].push(crossSellProducts[i]);
      }
      return productsGrid;
    });

    const openQuickshop = async (
      crossSellProd: ProductCrossSellItem,
      colIndex: number,
      rowIndex: number
    ) => {
      triggerGtmClick(crossSellProd, colIndex * ITEMS_IN_ONE_VIEW + rowIndex);
      const pid = parseProductId(crossSellProd.id);
      await toggleQuickShop(pid, { color: crossSellProd.colorCode });

      openModal({
        contextKey: Context.QuickShop,
        path: props.quickShopLink,
        type: 'page',
      });
      setModalProps({
        ...modalProps.value,
        class: modalProps.value.class + ' vf-modal-crosssell',
      });
      await getProductInventory(pid);
      triggerGtmView(quickShopProduct.value);
    };

    const triggerGtmClick = (
      crossSellProd: ProductCrossSellItem,
      position: number
    ) => {
      dispatchEvent({
        ...getEventFromTemplate('enhanced-sale:click'),
        overrideAttributes: {
          type: EnhancedSaleType.CROSS_SELL,
          product: crossSellProd,
          position,
        },
      });
    };

    const triggerGtmView = (prod: Product) => {
      dispatchEvent({
        ...getEventFromTemplate('enhanced-sale:view'),
        overrideAttributes: {
          type: EnhancedSaleType.CROSS_SELL,
          product: prod,
        },
      });
    };

    const triggerGtmImpression = () => {
      dispatchEventAfter('virtualPageView', () => {
        if (product.value.crossSellProducts?.length) {
          dispatchEvent({
            ...getEventFromTemplate('enhanced-sale:impression'),
            overrideAttributes: {
              type: EnhancedSaleType.CROSS_SELL,
              products: product.value.crossSellProducts,
            },
          });
        }
      });
    };

    if (isClient) {
      watch(crossSellProductsGrid, triggerGtmImpression, { immediate: true });
    }

    return {
      crossSellProductsGrid,
      heroImage,
      openQuickshop,
    };
  },
});
