
import type { PropType } from 'vue';
import HeaderMegaMenu from './HeaderMegaMenu.vue';
import { defineComponent, h } from '@vue/composition-api';
import { VNode } from 'vue';
import type {
  HeaderLogo,
  HeaderMaxItems,
  HeaderUtilityNavigation,
  MegaMenuMobileMenuSettings,
  MegaMenuNavigationItem,
  PromoBar,
  UtilityNavigationSmallTranslations,
  HeaderMenuTranslations,
  LoyaltyRewardsProgressTranslations,
} from '@vf/api-contract';
import { ROUTES, useI18n, useHeaderProps } from '@vf/composables';
import useModal from '@/shared/useModal';
import useLoader from '@/shared/useLoader';
import Notifications from './Notifications.vue';
import { hideDefaultNotificationsRoutes } from '../../../shared/notificationsConfig';

export default defineComponent({
  name: 'SmartHeader',
  props: {
    /** Page logo to render on the left hand side. Will not show up if not provided. */
    logo: {
      type: [Object] as PropType<HeaderLogo[]>,
      default: () => ({}),
    },
    /** Logo alternative text on hover */
    title: {
      type: String,
      default: '',
    },
    /** Which link tag to use on logo element */
    linkTag: {
      type: String,
      default: 'a',
    },
    /** Array of navigation elements */
    navigation: {
      type: Array as PropType<MegaMenuNavigationItem[]>,
      default: () => [],
    },
    mobileMenuSettings: {
      type: Object as PropType<MegaMenuMobileMenuSettings>,
      default: () => ({}),
    },
    utilityNavigation: {
      type: Object as PropType<HeaderUtilityNavigation>,
      default: () => ({ desktop: [], mobile: [] }),
    },
    /** Object of searchBar elements */
    searchBar: {
      type: Object,
      default: () => ({}),
    },
    /** Object of miniCart elements */
    miniCart: {
      type: Object,
      default: () => ({}),
    },
    /** Object of favoritesTooltip elements */
    favoritesTooltip: {
      type: Object,
      default: () => ({}),
    },
    /** Search button text */
    searchButtonText: {
      type: String,
      default: '',
    },
    /** _Deprecated property_ */
    steps: {
      type: Array,
      default: () => [],
    },
    /** Should mobile navigation header stick to top of the device when scrolling down */
    sticky: {
      type: Boolean,
      default: false,
    },
    mobileNav: {
      type: Object as PropType<{
        items: HeaderUtilityNavigation[];
        id: string;
        translations: UtilityNavigationSmallTranslations;
      }>,
      default: () => ({
        items: [],
        id: '',
        translations: {},
      }),
    },
    promoBar: {
      type: Object as PropType<PromoBar>,
      default: () => ({}),
    },
    /** If true added to cart tooltip/notification will display */
    isMinicartOpen: {
      type: Boolean,
      default: false,
    },
    /** Recently added product name to display in tooltip/notification */
    recentlyAddedProductName: {
      type: String,
      default: '',
    },
    /** More button text */
    moreTitle: {
      type: String,
      default: '',
    },
    /** Shop all text */
    shopAll: {
      type: String,
      default: '',
    },
    /** Maximum number of navigation items per screen */
    maxItems: {
      type: Object as PropType<HeaderMaxItems>,
      default: () => ({
        smallMaxItems: null,
        mediumMaxItems: null,
        largeMaxItems: null,
      }),
    },
    menuTranslations: {
      type: Object as PropType<HeaderMenuTranslations>,
      required: true,
    },
    headerConfig: {
      type: Object,
      required: true,
    },
    contextKey: {
      required: false,
      type: String,
    },
    loyaltyRewardsProgressTranslations: {
      type: Object as PropType<LoyaltyRewardsProgressTranslations>,
      default: () => ({}),
    },
  },
  render(): VNode {
    const { localePath } = useI18n(this.$root);
    const { prepareProps, headerEventHandlers } = useHeaderProps(this.$root, {
      useLoader,
      useModal,
    });

    const hideDefaultNotifications = () => {
      return hideDefaultNotificationsRoutes.some((route) => {
        if (route === ROUTES.ORDER_DETAILS())
          return this.$root.$route.path?.startsWith(localePath(route));
        return localePath(route) === this.$root.$route.path;
      });
    };

    return h('div', [
      h(
        HeaderMegaMenu,
        {
          props: prepareProps(this.$options.propsData),
          on: headerEventHandlers,
        },
        this.$children
      ),
      !hideDefaultNotifications() && h(Notifications),
    ]);
  },
});
