var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{staticClass:"form",attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id'])},on:{"submit":function($event){$event.preventDefault();}}},[_c('VfText',{staticClass:"form__helper",attrs:{"content":_vm.translations.cardNumberLabel}}),_vm._v(" "),_c('VfInput',{staticClass:"form__input",attrs:{"label":_vm.translations.cardNumberPlaceholder,"name":_vm.translations.cardNumberPlaceholder,"valid":!_vm.$v.cardNumber.$error,"error-message":!_vm.$v.cardNumber.required
        ? _vm.translations.validationMessages.required.replace(
            '{{fieldName}}',
            _vm.translations.cardNumberPlaceholder.toLowerCase()
          )
        : _vm.translations.validationMessages.digits16},on:{"blur":function($event){return _vm.$v.cardNumber.$touch()}},model:{value:(_vm.cardNumber),callback:function ($$v) {_vm.cardNumber=$$v},expression:"cardNumber"}}),_vm._v(" "),_c('VfText',{staticClass:"form__helper",attrs:{"content":_vm.translations.cardPinLabel}}),_vm._v(" "),_c('VfInput',{staticClass:"form__input",attrs:{"label":_vm.translations.cardPinPlaceholder,"name":_vm.translations.cardPinPlaceholder,"valid":!_vm.$v.pin.$error,"error-message":!_vm.$v.pin.required
        ? _vm.translations.validationMessages.required.replace(
            '{{fieldName}}',
            _vm.translations.cardPinPlaceholder.toLowerCase()
          )
        : _vm.translations.validationMessages.digits4or8},on:{"blur":function($event){return _vm.$v.pin.$touch()}},model:{value:(_vm.pin),callback:function ($$v) {_vm.pin=$$v},expression:"pin"}}),_vm._v(" "),_c('VfButton',{staticClass:"vf-button form__action-button",attrs:{"type":"submit","disabled":_vm.$v.$invalid || _vm.buttonIsDisabled},on:{"click":_vm.submitForm}},[_vm._v("\n    "+_vm._s(_vm.translations.submitButton)+"\n  ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }