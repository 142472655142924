var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VfCollapsible',{staticClass:"vf-accordion--gray-background vf-accordion--with-border checkout-family-rewards",attrs:{"is-open":_vm.isAccordionOpened,"header":_vm.$t('familyRewards.vansFamilyRewards')},on:{"toggle":function($event){_vm.isAccordionOpened = !_vm.isAccordionOpened}}},[_c('div',[(!_vm.isLoyaltyPaymentAvailable)?_c('VfText',[_vm._v("\n      "+_vm._s(_vm.$t('familyRewards.loyaltyPaymentNotAvailable'))+"\n    ")]):(!_vm.hasRewards)?_c('VfText',{attrs:{"html":"","tag":"span","content":_vm.$t('familyRewards.doNotHaveAnyAvailableRewards', {
          amountOfPoints: _vm.amountOfPointsToReachMinReward,
          minValueReward: _vm.$formatPrice(
            _vm.minAndMaxAvailableRewards.min,
            null,
            true
          ),
        })}}):_c('div',[(!_vm.isRewardSelected)?_c('VfText',{staticClass:"vf-text--reward",attrs:{"html":"","tag":"span","content":_vm.availableRewardsLabel}}):_vm._e(),_vm._v(" "),(_vm.isRewardSelected && _vm.isMaximumRewardSelected)?_c('VfText',{staticClass:"vf-text--reward",attrs:{"html":"","tag":"span","content":_vm.$t('familyRewards.rewardAppliedNoRemainingRewards')}}):_vm._e(),_vm._v(" "),(_vm.isRewardSelected && !_vm.isMaximumRewardSelected)?_c('VfText',{staticClass:"vf-text--reward",attrs:{"html":"","tag":"span","content":_vm.$t('familyRewards.rewardAppliedStillHaveRewards')}}):_vm._e(),_vm._v(" "),_c('ThemeLink',{attrs:{"legacy":false,"color":"primary","size":"md"},on:{"click":function($event){_vm.showRewardDetails = !_vm.showRewardDetails}}},[_vm._v("\n        "+_vm._s(_vm.$t('familyRewards.rewardDetails'))+"\n      ")]),_vm._v(" "),(_vm.showRewardDetails)?_c('VfText',{staticClass:"checkout-family-rewards__details",attrs:{"tag":"p","html":"","has-parent-tag":"","content":_vm.$t('familyRewards.rewardDetailsDescription')}}):_vm._e(),_vm._v(" "),_c('VfSelect',{attrs:{"label":_vm.$t('familyRewards.rewardAmount'),"name":_vm.$t('familyRewards.rewardAmount')},on:{"selected":_vm.setFamilyReward},model:{value:(_vm.selectedRewardOption),callback:function ($$v) {_vm.selectedRewardOption=_vm._n($$v)},expression:"selectedRewardOption"}},[_c('VfSelectOption',{attrs:{"selected":"selected","placeholder":_vm.$t('familyRewards.selectAmount'),"value":"selectAmount","disabled":""}}),_vm._v(" "),_vm._l((_vm.rewardsList),function(item){return _c('VfSelectOption',{key:item.id,attrs:{"disabled":item.disabled,"value":item.points}},[_vm._v("\n          "+_vm._s(item.label)+"\n        ")])}),_vm._v(" "),_c('VfSelectOption',{attrs:{"value":"0","placeholder":_vm.$t('familyRewards.saveRewardForLater')}})],2)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }