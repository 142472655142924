











































































































import {
  useGtm,
  useNotification,
  useProduct,
  useNotifyMe,
} from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import { computed, defineComponent, ref } from '@vue/composition-api';
import { getEventFromTemplate } from '@vf/composables/src/useGtm/helpers';
import { Size, Length, Width } from '@vf/api-client';

export default defineComponent({
  name: 'NotifyMeModal',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    translations: {
      type: Object,
      required: true,
    },
    sizes: {
      type: Array,
      default: () => [],
    },
    lengths: {
      type: Array,
      default: () => [],
    },
    widths: {
      type: Array,
      default: () => [],
    },
    zips: {
      type: Array,
      default: () => [],
    },
    notificationVisible: {
      type: Boolean,
      default: false,
    },
    prepopulateEmail: {
      type: String,
      default: '',
    },
    contextKey: {
      type: String,
      default: 'page-content',
    },
  },
  setup(props, { emit }) {
    const { root } = useRootInstance();
    const {
      availableAttributes,
      productSize,
      product,
      productLength,
      productWidth,
      productZip,
    } = useProduct(root, props.contextKey);
    const { subscribe } = useNotifyMe(root, props.contextKey);
    const { addNotification, clearNotifications } = useNotification(root);
    const { dispatchEvent } = useGtm(root);

    const isNotifyMeMessageVisible = ref(false);
    const isNotifyMeFormVisible = ref(false);

    const availableVariants = computed(() => {
      return availableAttributes.value?.size
        ? availableAttributes.value.size
        : [];
    });

    const productVariant = computed(() =>
      product.value.variants.find(
        (variant) =>
          variant.attributes.color === product.value.color.value &&
          variant.attributes.size === sizeValue.value &&
          (lengthValue.value
            ? variant.attributes.length === lengthValue.value
            : true) &&
          (widthValue.value
            ? variant.attributes.width === widthValue.value
            : true) &&
          (zipValue.value ? variant.attributes.zip === zipValue.value : true)
      )
    );

    const handleNotifyme = async (email) => {
      clearNotifications();
      try {
        const response = await subscribe(productVariant.value.id, true, email);
        isNotifyMeMessageVisible.value = response?.success;
        if (!isNotifyMeMessageVisible.value) {
          addNotification({
            message: props.translations.notificationAlreadySubscribedMessage,
            type: 'danger',
          });
          isNotifyMeFormVisible.value = false;
        } else {
          addNotification({
            message: props.translations.notificationMessage,
            type: 'success',
          });
        }
        dispatchEvent(
          getEventFromTemplate('notify-me:request', {
            eventLabel: root.$themeConfig.gtm?.useColorOnId
              ? product.value.id + productVariant.value.attributes.color
              : product.value.id,
          })
        );
        selectedSize.value = '';
      } catch (err) {
        root.$log.error(
          `[@theme/components/modals/NotifyMeModal.vue] Error: ${err.message}`
        );
        addNotification({
          message: props.translations.notificationErrorMessage,
          type: 'danger',
        });
        isNotifyMeMessageVisible.value = false;
      } finally {
        emit('close');
      }
    };

    const selectedSize = ref('');
    const sizeValue = computed(() => {
      if (selectedSize.value) return selectedSize.value;
      if (productSize.value) {
        return unavailableSizes.value.some(
          (size: Size) => size.value === productSize.value.value
        )
          ? productSize.value.value
          : selectedSize.value;
      }
      return '';
    });

    const selectedLength = ref('');
    const lengthValue = computed(() => {
      if (selectedLength.value) return selectedLength.value;
      if (productLength.value) {
        return unavailableLengths.value.some(
          (length: Length) => length.value === productLength.value.value
        )
          ? productLength.value.value
          : selectedLength.value;
      }
      return '';
    });

    const selectedWidth = ref('');
    const widthValue = computed(() => {
      if (selectedWidth.value) return selectedWidth.value;
      if (productWidth.value) {
        return unavailableWidths.value.some(
          (width: Width) => width.value === productWidth.value.value
        )
          ? productWidth.value.value
          : selectedWidth.value;
      }
      return '';
    });

    const selectedZip = ref('');
    const zipValue = computed(() => {
      if (selectedZip.value) return selectedZip.value;
      if (productZip.value) {
        return unavailableZips.value.some(
          (zip: Length) => zip.value === productZip.value.value
        )
          ? productZip.value.value
          : selectedZip.value;
      }
      return '';
    });

    const onCloseModal = () => {
      emit('close');
      selectedSize.value = '';
      selectedLength.value = '';
      selectedWidth.value = '';
      selectedZip.value = '';
    };

    const labelByValue = (list: any[] = [], value: string) => {
      return list.find((item) => item.value === value)?.label;
    };

    const unavailableSizes = computed(() =>
      props.sizes.filter(
        (size: Size) =>
          !availableVariants.value.some(
            (availableVariant) =>
              availableVariant.attributes.size === size.value
          )
      )
    );
    const unavailableLengths = computed(
      () =>
        props.lengths?.filter(
          (length: Length) =>
            !availableVariants.value.some(
              (availableVariant) =>
                availableVariant.attributes.size === sizeValue.value &&
                availableVariant.attributes.length === length.value &&
                availableVariant.attributes.width === widthValue.value &&
                availableVariant.attributes.zip === zipValue.value
            )
        ) || []
    );
    const unavailableWidths = computed(
      () =>
        props.widths?.filter(
          (width: Width) =>
            !availableVariants.value.some(
              (availableVariant) =>
                availableVariant.attributes.size === sizeValue.value &&
                availableVariant.attributes.length === lengthValue.value &&
                availableVariant.attributes.width === width.value &&
                availableVariant.attributes.zip === zipValue.value
            )
        ) || []
    );
    const unavailableZips = computed(
      () =>
        props.zips?.filter(
          (zip: Length) =>
            !availableVariants.value.some(
              (availableVariant) =>
                availableVariant.attributes.size === sizeValue.value &&
                availableVariant.attributes.length === lengthValue.value &&
                availableVariant.attributes.width === widthValue.value &&
                availableVariant.attributes.zip === zip.value
            )
        ) || []
    );

    const notifyBoxText = computed(() => {
      if (!props.translations.notifyBoxTextBox) return '';
      return props.translations.notifyBoxTextBox
        .replace('{{name}}', `<b>${product.value?.name}</b>`)
        .replace('{{color}}', `<b>${product.value?.color.label}</b>`)
        .replace('{{size}}', `<b>${sizeValue.value}</b>`)
        .replace(
          '{{attrs}}',
          [
            labelByValue(product.value.lengths, lengthValue.value),
            labelByValue(product.value.widths, widthValue.value),
            labelByValue(product.value.zips, zipValue.value),
          ]
            .filter((attr) => attr)
            .map((attr) => `<b>${attr}</b>`)
            .join(', ')
        );
    });

    const isSubmitEnabled = computed(() => {
      return (
        (!unavailableSizes.value.length || sizeValue.value) &&
        (!unavailableLengths.value.length || lengthValue.value) &&
        (!unavailableWidths.value.length || widthValue.value) &&
        (!unavailableZips.value.length || zipValue.value)
      );
    });

    return {
      product,
      availableVariants,
      unavailableLengths,
      unavailableWidths,
      unavailableZips,
      handleNotifyme,
      onCloseModal,
      selectedSize,
      sizeValue,
      selectedLength,
      lengthValue,
      selectedWidth,
      widthValue,
      selectedZip,
      zipValue,
      unavailableSizes,
      notifyBoxText,
      isSubmitEnabled,
    };
  },
});
