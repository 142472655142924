import type { ComponentInstance } from '@vf/composables/src/types';
import { useHeaderAndFooterStore } from '@vf/composables/src/store/headerAndFooter';

import throttle from '@vf/shared/src/utils/helpers/throttle';

/**
 * sticky filters logic for plp/srp filter
 * @returns destroy function
 */
export const plpStickyFilters = ({
  mobileTargetEl,
  root,
  wrapEl,
}: {
  mobileTargetEl: HTMLElement | null;
  root: ComponentInstance;
  wrapEl: HTMLElement | null;
}): (() => void) => {
  const headerAndFooterStore = useHeaderAndFooterStore();
  let lastScrollPosition = 0;
  let isHeaderVisible = true;
  let headerFilterConnected = false;
  let lastScrollHeight = document.documentElement.scrollHeight;

  if (!wrapEl || !mobileTargetEl) return () => null;

  mobileTargetEl.classList.add('vf-plp-sticky-filters');
  const showHeader = (show: boolean) => {
    if (isHeaderVisible === show) return;
    root.$eventBus.$emit('hide-header', !show);
    headerAndFooterStore.showHeader = show;
    isHeaderVisible = show;
  };

  const getNumberValue = (property: string) =>
    parseInt(document.documentElement.style.getPropertyValue(property) || '0');

  const getHeaderHeight = () =>
    getNumberValue('--header-height') + getNumberValue('--promo-bar-height');

  const boxShadow = (filterTopPosition: number, headerHeight: number) => {
    const isConnected = filterTopPosition < headerHeight;
    if (isConnected !== headerFilterConnected) {
      root.$eventBus.$emit('filter-panel-sticky', isConnected);
      headerFilterConnected = isConnected;
    }
  };

  const observeScroll = throttle(() => {
    const currentScrollPosition = Math.round(
      window.scrollY || document.documentElement.scrollTop
    );
    if (lastScrollHeight !== document.documentElement.scrollHeight) {
      // reset scroll position when some elements are removed/added in the DOM
      lastScrollPosition = currentScrollPosition;
      lastScrollHeight = document.documentElement.scrollHeight;
      return;
    }
    const isScrollDown = currentScrollPosition - lastScrollPosition > 0;
    const isSmall = root.$viewport?.isSmall;
    const headerHeight = getHeaderHeight();
    const filterTopPosition = wrapEl.getBoundingClientRect().top;
    const hideHeaderThreshold = isSmall
      ? filterTopPosition < 0
      : currentScrollPosition > headerHeight;
    if (isScrollDown && hideHeaderThreshold) {
      showHeader(false);
    } else {
      showHeader(true);
    }
    if (isSmall) boxShadow(filterTopPosition, headerHeight);

    lastScrollPosition = currentScrollPosition;
  }, 100);

  window.addEventListener('scroll', observeScroll, { passive: true });

  return () => {
    window.removeEventListener('scroll', observeScroll);
    showHeader(true);
    root.$eventBus.$emit('filter-panel-sticky', false);
  };
};
