
import { defineComponent } from '@vue/composition-api';
import { treeRenderer } from '../../cms/cmsUtils';

export default defineComponent({
  name: 'ESpot',
  props: {
    contextKey: {
      type: String,
      default: '',
    },
    component: Object,
  },
  render(createElement) {
    return treeRenderer(
      createElement,
      {
        children: [this.component],
      },
      this.contextKey,
      this.$root
    );
  },
});
