









import { defineComponent, ref, watch } from '@vue/composition-api';
import useRootInstance from '@/shared/useRootInstance';
import { ProductUpsellItem } from '@vf/api-client';
import { useCart, useGtm, useProduct } from '@vf/composables';
import Upsell from '@/components/static/cart/Upsell.vue';
import type { UpsellWrapper } from '@vf/api-contract';
import { UpsellType } from '@vf/api-contract';
import { getEventFromTemplate } from '@vf/composables/src/useGtm/helpers';
import { EnhancedSaleType } from '@vf/composables/src/types/gtm';
import { areSameItems } from '@/helpers/upsellHelper';

export default defineComponent({
  name: 'SFCCCartUpsell',
  components: {
    Upsell,
  },
  props: {
    quickShopLink: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { root } = useRootInstance();
    const { cart } = useCart(root);
    const { dispatchEvent, dispatchEventAfter } = useGtm(root);
    const { getProductDetails } = useProduct(root);

    const isMasterProduct = (product: ProductUpsellItem) =>
      !product.id.includes(':');

    const getMasterId = (product: ProductUpsellItem) =>
      isMasterProduct(product)
        ? product.styleCode
        : product.styleCode
            .substring(0, product.styleCode.indexOf(product.colorCode))
            .replace(/:/g, '');

    const onLoad = (upsellProducts) => {
      dispatchEventAfter('virtualPageView', () => {
        if (upsellProducts.length) {
          dispatchEvent({
            ...getEventFromTemplate('enhanced-sale:impression'),
            overrideAttributes: {
              type: EnhancedSaleType.UP_SELL,
              products: upsellProducts,
            },
          });
        }
      });
    };

    const upsells = ref<UpsellWrapper[]>([]);
    const processUpsells = async (cart) => {
      const newUpsells = (cart.upsellProducts || []) as ProductUpsellItem[];
      if (
        areSameItems(
          upsells.value.map((upsell) => upsell.product),
          newUpsells
        )
      )
        return;
      upsells.value = (
        await Promise.all(
          newUpsells.map(
            async (product: ProductUpsellItem): Promise<UpsellWrapper> => {
              let attributes = { color: product.colorCode };
              if (!isMasterProduct(product)) {
                const masterId = getMasterId(product);
                const details = await getProductDetails(masterId, {
                  configuredColor: product.colorCode,
                });
                const variant = details.variants.find(
                  (variant) => variant.id === product.id
                );
                if (!variant) return;
                attributes = { ...variant.attributes };
              }

              return {
                product: {
                  id: product.id,
                  name: product.name,
                  itemGroupId: getMasterId(product),
                  description: product.description,
                  attributes,
                  quantity: 1,
                  price: {
                    original: product.price.original,
                    current: product.price.current,
                    currency: product.price.currency,
                  },
                  custom: false,
                  upsell: true,
                  image: {
                    url: root.$mediaUrlGenerator({
                      pid: getMasterId(product),
                      productName: product.name,
                      colorCode: product.colorCode,
                    }) as string,
                    alt: product.name,
                  },
                  savedForLater: false,
                  pageUrl: product.pageUrl,
                },
                type: isMasterProduct(product)
                  ? UpsellType.Multi
                  : UpsellType.Single,
              };
            }
          )
        )
      ).filter(Boolean);
    };
    watch(cart, async (value) => processUpsells(value), { immediate: true });

    return {
      upsells,
      onLoad,
    };
  },
});
