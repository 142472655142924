import { computed, Ref, ssrRef } from '@nuxtjs/composition-api';
import { apiClientFactory } from '@vf/api-client';
import { ComponentInstance, ComposablesStorage } from '../types';
import initStorage from '../utils/storage';
import { SeoRecommendationStore, SeoRecommendationType } from '../useSeo/types';
import { useSeoRecommendationsStore } from '../store/seoRecommendations';
import useUrl from '../useUrl';

type UseSeoStorage = {
  currentRecommendation: Ref<SeoRecommendationStore>;
};

export const useSeo = (instance: ComponentInstance, contextKey: string) => {
  const { getWidgetRecommendations } = apiClientFactory(instance);
  const store = useSeoRecommendationsStore();
  const { parseUrl } = useUrl(instance, contextKey);

  const {
    addRecommendation,
    getCategoryRecommendation,
    getProductRecommendation,
  } = store;

  const storage: ComposablesStorage<UseSeoStorage> = initStorage<UseSeoStorage>(
    instance,
    `useSeo`
  );

  const currentRecommendation: Ref<SeoRecommendationStore> =
    storage.get('currentRecommendation') ??
    storage.save(
      'currentRecommendation',
      ssrRef({} as SeoRecommendationStore, 'currentRecommendation')
    );

  const getRecommendation = async (
    currentPath: string,
    pageType: SeoRecommendationType,
    id: string
  ) => {
    if (pageType === 'category') {
      currentRecommendation.value = getCategoryRecommendation(id);
    } else {
      currentRecommendation.value = getProductRecommendation(id);
    }

    if (!currentRecommendation.value) {
      currentRecommendation.value = await fetchRecommendationsWidgetData(
        currentPath,
        pageType,
        id
      );
    }
    return new Promise((resolve) => {
      resolve(currentRecommendation.value);
    });
  };

  const fetchRecommendationsWidgetData = async (
    currentPath: string,
    pageType: SeoRecommendationType,
    id: string
  ) => {
    try {
      const response = await getWidgetRecommendations({
        ptype: pageType,
        url: currentPath,
      });

      addRecommendation(pageType, id, response.data);

      return {
        recommendationKey: id,
        recommendationPageType: pageType,
        recommendationData: response.data,
      };
    } catch (error) {
      instance.$log.error(
        `Could not fetch Widget Recommendation for ${pageType}: ${id}.`,
        {
          currentPath,
          error,
        }
      );
    }
  };

  const hasRelatedCategories = computed(() => {
    return !!currentRecommendation.value?.recommendationData?.relatedCategories
      .length;
  });

  const hasRelatedItems = computed(() => {
    return !!currentRecommendation.value?.recommendationData?.relatedItems
      .length;
  });

  const seoObject = computed(() =>
    currentRecommendation.value?.recommendationData?.relatedProducts.map(
      (item) => ({
        title: item.title,
      })
    )
  );

  const isVisible = computed(() => {
    return currentRecommendation.value?.recommendationData?.relatedProducts
      .length;
  });

  const setGlobalVariable = (curRecommendation?: SeoRecommendationStore) => {
    const global = window as any;
    global['br_iuid'] = global['br_related_rid'] = undefined;
    if (curRecommendation?.recommendationData) {
      global['br_iuid'] = curRecommendation.recommendationData.iUId;
      global['br_related_rid'] =
        curRecommendation.recommendationData.relatedRId;
    }
  };

  const getPageUrl = () => {
    let url = instance.$getPageUrl();
    url = url.replace('/plp', '').replace('/pdp', '');
    const [currentUrl] = url.split('?');
    // URL must be an actual domain, localhost wont work. To make it work locally
    // we must replace the domain with one of the UAT domains

    // uncomment and set to proper brand:

    // console.log('-------------------------------');
    // console.log('------ DO NOT COMMIT IF  ------');
    // console.log('----  DEV URL CODE IS NOT  ----');
    // console.log('------   COMMENTED OUT   ------');
    // console.log('-------------------------------');

    // const devUrl = currentUrl.replace('http://', 'https://').replace('localhost:3000', 'dev2.thenorthface.com');
    // const devUrl = currentUrl.replace('http://', 'https://').replace('localhost:3000', 'dev2.vans.com');
    // return devUrl;
    return currentUrl;
  };

  const getPageData = (path: string) => {
    const { resourceType, resourceId } = parseUrl(path);
    if (!resourceType || !resourceId) return;
    return {
      url: getPageUrl(),
      type: resourceType,
      id: resourceId,
    };
  };

  return {
    currentRecommendation: computed(() => currentRecommendation.value),
    getRecommendation,
    hasRelatedCategories,
    hasRelatedItems,
    seoObject,
    isVisible,
    setGlobalVariable,
    getPageData,
  };
};

export default useSeo;
