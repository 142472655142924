const transformers = {
  capitalize: () => {
    return (input: string): string => {
      return input.toUpperCase();
    };
  },
  canadaPostalCode: () => {
    return (input: string): string => {
      return input.length === 0 ? '' : format(input, 'XXX XXX');
    };
  },
};

const format = (input: string, pattern: string): string => {
  const data = input.replace(/[^A-Za-z0-9]/g, '');
  let count = 0;
  return pattern.replace(/X/g, () => {
    return data.charAt(count++);
  });
};

export default transformers;
