






















import type { PropType } from 'vue';
import { ref, defineComponent, Ref, computed } from '@vue/composition-api';
import type { OrderHistoryCtaShowAllTranslations } from '@vf/api-contract';
import { ROUTES, useAccount, useI18n } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

export default defineComponent({
  name: 'OrderHistoryCtaShowAll',
  props: {
    translations: {
      type: Object as PropType<OrderHistoryCtaShowAllTranslations>,
      required: true,
    },
    orderPageType: {
      type: String,
      default: 'order',
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const {
      getOrderHistory,
      getReturnHistory,
      orderHistory,
      returnHistory,
    } = useAccount(root);
    const { localePath } = useI18n(root);
    const { areOnlineReturnsEnabled } = useFeatureFlagsStore();

    const homePageUrl = localePath(ROUTES.HOME());

    const isButtonDisabled: Ref<boolean> = ref(false);
    const isReturnsHistory = computed(
      () => props.orderPageType === 'return' && areOnlineReturnsEnabled
    );
    const amountOfOrdersLeft = computed(() =>
      isReturnsHistory.value
        ? returnHistory.value.totalNumberOfRecords -
          returnHistory.value.returns.length
        : orderHistory.value.totalNumberOfOrders -
          orderHistory.value.orders.length
    );
    const showMoreButton = computed(() =>
      isReturnsHistory.value
        ? returnHistory.value.returns.length && !returnHistory.value.isLastPage
        : orderHistory.value.orders.length && !orderHistory.value.isLastPage
    );
    const showContinueShopping = computed(() =>
      isReturnsHistory.value
        ? !returnHistory.value.returns.length
        : !orderHistory.value.orders.length
    );

    const getMoreOrders = async () => {
      isButtonDisabled.value = true;
      const historyType = isReturnsHistory.value ? returnHistory : orderHistory;
      const getHistory = isReturnsHistory.value
        ? getReturnHistory
        : getOrderHistory;

      await getHistory({
        pageSize: 10,
        pageNumber: historyType.value.pageNumber + 1,
        previousPageNumber: historyType.value.pageNumber,
        lastOrderHeaderKey: historyType.value.lastOrderHeaderKey,
      });
      isButtonDisabled.value = false;
    };

    return {
      isButtonDisabled,
      getMoreOrders,
      orderHistory,
      amountOfOrdersLeft,
      showMoreButton,
      showContinueShopping,
      homePageUrl,
    };
  },
});
