


































































import { ContentCTA, ContentMedia, ContentText } from '@vf/api-contract';
import { defineComponent, PropType } from '@vue/composition-api';
import { extractImageSizes } from '../../../helpers/extractImageSizes';
import ContentCTAButtons from './ContentCTAButtons.vue';

export default defineComponent({
  name: 'ContentImageWithHoverOverlay',
  components: {
    ContentCTAButtons,
  },
  props: {
    media: {
      type: Object as PropType<ContentMedia>,
      required: true,
    },
    title: {
      type: Object as PropType<ContentText>,
    },
    bodyText: {
      type: Object as PropType<ContentText>,
    },
    ctas: {
      type: Array as PropType<Array<Omit<ContentCTA, 'styleClass'>>>,
      default: () => [],
    },
    mediaTarget: {
      type: Object as PropType<Pick<ContentCTA, 'link' | 'linkBehavior'>>,
    },
  },
  computed: {
    imageWidths() {
      return extractImageSizes(this.media.image, 'width');
    },
    imageHeights() {
      return extractImageSizes(this.media.image, 'height');
    },
    hasOverlay() {
      return this.ctas?.length || this.title?.text || this.bodyText?.text;
    },
  },
  methods: {
    isBackgroundTransparent(color: string) {
      return color === '#00000000' || color === null;
    },
    isColorWhite(color: string) {
      // if no color is passed it's white by default for that component
      return color === '#FFFFFF' || color === null;
    },
  },
});
