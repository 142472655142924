






































import type { PropType } from 'vue';
import type { CartLineItem, FlashError } from '@vf/api-client';
import { getFlashesByProductId } from '@/helpers/getFlashesByProductId';
import { replaceMessagePlaceholders } from '@vf/shared/src/utils/helpers';

export default {
  name: 'OrderSummaryProducts',
  props: {
    products: {
      type: Array as PropType<CartLineItem[]>,
      default: () => [],
    },
    flashes: {
      type: Array as PropType<FlashError[]>,
      default: () => [],
    },
    isCartUpdates: {
      type: Boolean,
      default: false,
    },
  },
  getHeader(parent, isCartUpdates, numProducts) {
    return isCartUpdates
      ? parent.$t('orderSummaryProducts.cartUpdates')
      : `${parent.$t('orderSummaryProducts.cart')} (${numProducts})`;
  },
  getFlashesByProductId,
  getMessage(parent, flash) {
    const key = `checkoutNotifications.itemLevel.${flash.code}`;
    return replaceMessagePlaceholders(parent.$t(key) as string, flash.details);
  },
};
