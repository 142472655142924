



























































import type { PropType } from 'vue';
import { computed, defineComponent, watch } from '@vue/composition-api';
import type { ViewMoreProductsButtonsTranslations } from '@vf/api-contract';
import { useCategory, useSearch, useMonetate } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import useLoader from '@/shared/useLoader';
import { useUserData } from '@/components/cms/cmsUtils';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

export default defineComponent({
  name: 'ViewMoreProductsButtons',
  props: {
    translations: {
      type: Object as PropType<ViewMoreProductsButtonsTranslations>,
      default: () => ({}),
    },
    /** Flag to determine if buttons should be disabled */
    disabled: {
      type: Boolean,
      default: false,
    },
    contextKey: {
      type: String,
      default: '',
    },
    showViewAll: {
      type: Boolean,
      default: true,
    },
    showProgressBar: {
      type: Boolean,
      default: false,
    },
    stackButtonsMobile: {
      type: Boolean,
      default: true,
    },
    maxQuery: {
      type: Number,
      required: true,
    },
    infiniteScroll: {
      type: Boolean,
      required: true,
    },
    infiniteScrollOffset: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    // TODO: Cleanup in GLOBAL15-63799
    const { isVansPlpRedesignEnabled } = useFeatureFlagsStore();
    const isSearchRegex =
      root.$themeConfig.viewMoreProductsButtons?.viewMoreButtonSearchRegex ||
      /\/tag\//gi;
    const isCategory = computed(
      () => !root.$route.query?.q && !root.$route.path.match(isSearchRegex)
    );

    const suppressSpinner =
      root.$themeConfig.viewMoreProductsButtons?.suppressSpinner || false;

    const { getExperienceDecision, addCustomVars } = useMonetate(
      root,
      props.contextKey
    );

    const {
      products,
      pagination,
      loading,
      loadNextPage,
      loadAllProducts,
      initialLoadProductsAmount,
      isInfiniteScrolling,
      setInfiniteScrolling,
    } = isCategory.value
      ? useCategory(root, props.contextKey)
      : useSearch(root);

    const { showSpinner, hideSpinner } = useLoader();
    const getProductsQty = computed(() => {
      if (products.value.length === pagination.value.total) {
        return products.value.length;
      }

      const { page, per } = pagination.value;
      return isInfiniteScrolling.value
        ? products.value.length
        : initialLoadProductsAmount.value + (page - 1) * per;
    });

    const qtyLabel = computed(() => {
      return pagination.value.total > 1
        ? props.translations.itemsCountPlural
        : props.translations.itemsCountSingular;
    });

    const viewAllLabel = computed(() => {
      let label = props.translations.viewAllButtonText;
      label = label.replace('{{quantity}}', `${pagination.value.total}`);
      label = label.replace('{{type}}', qtyLabel.value);
      return label;
    });

    const showViewAllButton = computed(() =>
      props.showViewAll === null ? true : props.showViewAll
    );

    const viewMoreProductsCount = computed(() =>
      Math.min(
        pagination.value.per,
        pagination.value.total - getProductsQty.value
      )
    );

    const viewMoreLabel = computed(() => {
      return props.translations.viewMoreButtonText.replace(
        '{{quantity}}',
        viewMoreProductsCount.value + ''
      );
    });

    const scrollOffset = computed(() => {
      const defaultOffset = props.infiniteScrollOffset.large;
      if (!root.$viewport?.size) return defaultOffset;
      return props.infiniteScrollOffset?.[root.$viewport.size] ?? defaultOffset;
    });

    const color = root.$themeConfig.viewMoreProductsButtons?.color || 'primary';

    const isViewMoreVisible = computed(() => {
      return pagination.value.total > getProductsQty.value;
    });

    const progress = computed(() => {
      const total = (products.value.length * 100) / pagination.value.total;
      return Math.ceil(total);
    });

    const progressLabel = computed(() => {
      let label = props.translations.progressBarLabel;
      label = label.replace('{{loaded}}', `${products.value.length}`);
      label = label.replace('{{total}}', `${pagination.value.total}`);
      return label;
    });

    const classes = {
      'category__products-buttons': true,
      'category__products-buttons--stacked': props.stackButtonsMobile,
    };

    const loadNextPageTrigger = async () => {
      const productGrid = document.getElementsByClassName(`products-grid`)[0];
      const index = productGrid.children[0].children.length - 1;
      const productsGridItemToFocus = document.getElementById(`row-${index}`);
      await loadNextPage();

      setTimeout(() => {
        focusFirstProduct(productsGridItemToFocus);
      }, 300);

      const userData = useUserData(root);
      addCustomVars(userData);
      await getExperienceDecision();
    };

    const focusFirstProduct = (element) => {
      element.scrollIntoView();
    };

    const handleVisible = async () => {
      if (!suppressSpinner) showSpinner();
      try {
        await loadAllProducts(props.maxQuery);
      } catch (err) {
        console.error(err);
      }
      hideSpinner();
    };

    const loadAllProductsTrigger = async () => {
      if (props.infiniteScroll) {
        setInfiniteScrolling(true);
        return;
      }
      await handleVisible();
      const userData = useUserData(root);
      addCustomVars(userData);
      await getExperienceDecision();
    };

    watch(pagination, () => {
      if (pagination.value.page === 1) setInfiniteScrolling(false);
    });

    return {
      loading,
      pagination,
      products,
      color,
      loadNextPageTrigger,
      loadAllProductsTrigger,
      getProductsQty,
      isCategory,
      showViewAllButton,
      viewAllLabel,
      viewMoreLabel,
      scrollOffset,
      isInfiniteScrolling,
      isVansPlpRedesignEnabled,
      handleVisible,
      isViewMoreVisible,
      progress,
      progressLabel,
      classes,
    };
  },
  computed: {
    rootMargin() {
      return { rootMargin: this.scrollOffset };
    },
  },
});
