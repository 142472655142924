var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vf-heading"},[(_vm.hasTitle)?_c('VfText',_vm._b({staticClass:"vf-heading__title",class:[
      _vm.styles.titleFontFamily,
      { 'vf-heading__title--quotes': _vm.titleInQuotes } ],style:({
      'font-size': _vm.styles.titleFontSize,
      'line-height': _vm.titleLineHeight,
      'font-weight': _vm.styles.titleFontWeight,
      'text-align': _vm.styles.titleTextAlign,
      'font-style': _vm.styles.titleFontStyle,
    }),attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id'], 'teaserTitle'),"tag":_vm.titleTagLevel,"html":_vm.html,"has-parent-tag":_vm.hasParentTag,"text-color":_vm.styles.titleColor,"text-align":_vm.textAlign,"content":_vm.title,"text-custom-classes":_vm.styles.titleClassModifiers,"modifier":_vm.titleModifier,"background-color":_vm.styles.titleBackgroundColor,"vf-text-aria-role":_vm.vfTextAriaRole,"vf-text-aria-live":_vm.vfTextAriaLive}},'VfText',_vm.$attrs,false)):_vm._e(),_vm._v(" "),(_vm.hasSubtitle)?_c('VfText',_vm._b({staticClass:"vf-heading__subtitle",class:[_vm.styles.subtitleFontFamily],style:({
      'font-size': _vm.styles.subtitleFontSize,
      'line-height': _vm.subtitleLineHeight,
      'font-weight': _vm.styles.subtitleFontWeight,
      'text-align': _vm.styles.subtitleTextAlign,
    }),attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id'], 'teaserSubtitle'),"text-color":_vm.styles.subtitleColor,"text-align":_vm.textAlign,"text-custom-classes":_vm.styles.subtitleClassModifiers,"content":_vm.subtitle,"modifier":_vm.subtitleModifier,"background-color":_vm.styles.subtitleBackgroundColor,"vf-text-aria-role":_vm.vfTextAriaRole,"vf-text-aria-live":_vm.vfTextAriaLive}},'VfText',_vm.$attrs,false)):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }