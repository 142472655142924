






















import { defineComponent, PropType } from '@vue/composition-api';
import type { Image, VideoObject } from '@vf/api-contract';

const DEFAULT_HEADING_LEVEL = 2;

export default defineComponent({
  name: 'CarouselSlideStory',
  inject: ['headingLevel'],
  props: {
    image: Object as PropType<Image>,
    video: Object as PropType<{ small: VideoObject }>,
    title: {
      type: String as PropType<string>,
      default: '',
    },
    link: {
      type: String as PropType<string>,
      default: null,
    },
    hideUnderline: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  computed: {
    // Slide heading should be greater than parent (carousel) title by 1
    level() {
      return this.headingLevel
        ? Number(this.headingLevel) + 1
        : DEFAULT_HEADING_LEVEL;
    },
  },
});
