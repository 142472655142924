import { ref, Ref } from '@vue/composition-api';
import { CartLineItem } from '@vf/api-client/src/types';
import { ComponentInstance, ComposablesStorage } from '../types';
import initStorage from '../utils/storage';

type UseGiftOptionStorage = {
  modalVisible: Ref<boolean>;
  editingProduct: Ref<CartLineItem | any | null>;
};

export const useGiftOption = (instance: ComponentInstance) => {
  const storage: ComposablesStorage<UseGiftOptionStorage> = initStorage<UseGiftOptionStorage>(
    instance,
    'useGiftOption'
  );

  const modalVisible =
    storage.get('modalVisible') ??
    storage.save('modalVisible', ref<boolean>(false));

  const editingProduct =
    storage.get('editingProduct') ??
    storage.save('editingProduct', ref<CartLineItem | any | null>(null));

  const setGiftOptionItem = (item: CartLineItem | any | null) => {
    editingProduct.value = item;
  };

  return {
    modalVisible,
    editingProduct,
    setGiftOptionItem,
  };
};
