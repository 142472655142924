/**
 * Format year to 2-digit format
 * @param {number} year
 * @returns {string} formatted year
 */
export function formatExpirationYear(year: number = 2000): string {
  return year.toString().substr(-2);
}

/**
 * Format month to 2-digit format
 * @param {number} month
 * @returns {string} formatted month
 */
export function formatExpirationMonth(month: number = 1): string {
  return month > 9 ? month.toString() : '0' + month.toString();
}
