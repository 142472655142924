














































































































import type { PropType } from 'vue';
import {
  computed,
  ref,
  onMounted,
  watch,
  defineComponent,
} from '@vue/composition-api';
import type {
  ArticleGridConfiguration,
  ArticleGridTeaserStyleCustomization,
  CmsArticle,
} from '@vf/api-contract';
import { useArticles } from '@vf/composables';
import { AnalyticsDataDomLocation } from '@/types';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'ArticleGrid',
  props: {
    /** Name of the context where component should be displayed (one of 'regular-page' | 'search' | 'news-articles') */
    contextName: {
      type: String as PropType<'regular-page' | 'search' | 'news-articles'>,
      default: 'regular-page',
    },
    /** Article grid main heading */
    title: {
      type: String,
      default: '',
    },
    /** Article grid subheading */
    subtitle: {
      type: String,
      default: '',
    },
    /** Show More button text */
    showMoreButtonText: {
      type: String,
      default: 'Show more',
    },
    /** Default Show More Button Text */
    defaultShowMoreButtonText: {
      type: String,
      default: 'Show more',
    },
    /** Default Read More Button Text */
    defaultReadMoreButtonText: {
      type: String,
      default: 'Read more',
    },
    configuration: {
      type: Object as PropType<ArticleGridConfiguration>,
      default: () => ({}),
    },
    styles: {
      type: Object as PropType<ArticleGridTeaserStyleCustomization>,
      default: () => ({}),
    },
    contextKey: {
      type: String,
      default: 'page-content',
    },
    openInNewModal: {
      type: Boolean,
      default: false,
    },
    openInNewTab: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { articles, pagination, loadMoreArticles } = useArticles(
      root,
      props.contextKey
    );
    const showTagBelowImage =
      root.$themeConfig?.categoryTag?.showCategoryBelowImage || false;
    const numberOfRows = ref(1);
    onMounted(() => {
      numberOfRows.value = startingNumberOfRows.value;
    });
    const articlesPerRow = computed(() => {
      return numberOfArticles('row');
    });

    watch(pagination, () => {
      if (pagination.value.offset === 0) {
        numberOfRows.value = startingNumberOfRows.value;
      }
    });

    const articlesToDisplay = computed(() => {
      return articles.value
        ? articles.value.slice(0, articlesPerRow.value * numberOfRows.value)
        : [];
    });

    const startingNumberOfRows = computed(() => {
      if (
        props.contextName === 'search' ||
        props.contextName === 'news-articles'
      ) {
        return Math.ceil(numberOfArticles('page') / numberOfArticles('row'));
      }
      return 1;
    });

    const numberOfArticles = (type) => {
      if (!root.$isServer && root.$viewport) {
        switch (root.$viewport.size) {
          case 'large':
            return props.configuration[`articles-per-${type}-large-screen`];
          case 'medium':
            return props.configuration[`articles-per-${type}-medium-screen`];
          case 'small':
            return props.configuration[`articles-per-${type}-small-screen`];
          default:
            return props.configuration[`articles-per-${type}-small-screen`];
        }
      }
      return props.configuration[`articles-per-${type}-large-screen`];
    };

    const showMore = async () => {
      if (articlesToDisplay.value.length < pagination.value.total) {
        await loadMoreArticles(articlesPerRow.value);
        numberOfRows.value += 1;
      }
    };

    const getTag = (article: CmsArticle) => {
      if (props.configuration['tile-show-tags']) {
        return article.subjectTaxonomy?.[0]?.value ?? '';
      }
      return '';
    };

    const getPublicationDate = (article: CmsArticle) => {
      if (props.configuration['tile-show-publication-date']) {
        // just passing the date coming from CoreMedia is not valid for Atom.Date component
        // getting only short date sufice for article cards
        const dateData = article.publicationDate.split('T');
        return dateData[0] ?? null;
      }
    };

    const { width: imageWidth, height: imageHeight } = computed(
      () =>
        root.$themeConfig?.articlesGrid?.imageSizes?.[root.$viewport.size] ?? {
          width: 440,
          height: 440,
        }
    ).value;

    return {
      AnalyticsDataDomLocation,
      articlesToDisplay,
      showMore,
      pagination,
      articlesPerRow,
      getTag,
      getPublicationDate,
      showTagBelowImage,
      imageWidth,
      imageHeight,
    };
  },
});
