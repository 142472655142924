



























import { computed, ref, defineComponent, PropType } from '@vue/composition-api';
import KlarnaModal from '../modals/KlarnaModal.vue';
import useRootInstance from '@/shared/useRootInstance';
import { KlarnaIntegration } from '@vf/api-contract';
import { useProduct, useRequestTracker } from '@vf/composables';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
interface KlarnaPrice {
  currentPrice: number;
  currency: string;
}

export default defineComponent({
  name: 'KlarnaInfo',
  components: {
    KlarnaModal,
  },
  props: {
    translations: {
      type: Object as PropType<KlarnaIntegration>,
      required: true,
    },
    price: {
      type: Object as PropType<KlarnaPrice>,
      required: true,
    },
    contextKey: {
      type: String,
      default: 'product',
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { attributesData, variantsData } = useProduct(root, props.contextKey);
    const { onBaseDone } = useRequestTracker(root);
    const klarnaModalVisible = ref(false);
    const { isCoreRedesignEnabled } = useFeatureFlagsStore();

    const {
      minThreshold,
      maxThreshold,
      payments,
    }: {
      minThreshold: number;
      maxThreshold: number;
      payments: number;
    } = root.$getEnvValueByCurrentLocale('KLARNA_CONFIG');

    const actualLabel = computed(() => {
      let label = props.translations.labels.belowThresholdLabel;
      if (isInThreshold.value) {
        label = props.translations.labels.inThresholdLabel;
      }
      return label;
    });

    const isInThreshold = computed(
      () => props.price.currentPrice > minThreshold
    );

    const isKlarnaVisible = ref(false);

    onBaseDone(() => {
      // set the value only when the product's details are received
      isKlarnaVisible.value =
        maxThreshold > props.price.currentPrice &&
        !variantsData.value.areAllVariantsOutOfStock &&
        !attributesData.value.isSoldOut;
    });

    const installmentValue = computed(() => {
      const installment = props.price.currentPrice / payments;
      return Math.round(installment * 100) / 100;
    });

    const formattedInstallment = computed(() =>
      root.$formatPrice(installmentValue.value, props.price.currency)
    );

    const formattedMax = root.$formatPrice(maxThreshold, props.price.currency);
    const formattedMin = root.$formatPrice(minThreshold, props.price.currency);

    const installmentText = computed(() => {
      return formattedInstallment.value;
    });

    const klarnaText = computed(() => {
      return actualLabel.value
        .replace('{{payments}}', `${payments}`)
        .replace('{{amount}}', formattedInstallment.value)
        .replace('{{min}}', formattedMin)
        .replace('{{max}}', formattedMax)
        .replace(
          '{{klarnaIcon}}',
          '<div class="klarnaIcon" role="img" aria-label="Klarna"></div>'
        );
    });

    return {
      klarnaText,
      installmentText,
      klarnaModalVisible,
      isKlarnaVisible,
      isInThreshold,
      installmentValue,
      isCoreRedesignEnabled,
    };
  },
});
