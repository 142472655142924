var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.hideRoot)?_c('div',{class:_vm.wrapperClasses},[_c('div',{staticClass:"vf-loyalty-order-register"},[_c('div',{staticClass:"vf-loyalty-order-register__image"},[(_vm.isCheckoutSuccessRedesignEnabled)?_c('VfIcon',_vm._b({style:({
          '--icon-height': _vm.theme.logo.height,
          '--icon-width': _vm.theme.logo.width,
        })},'VfIcon',_vm.theme.logo,false)):(_vm.data.banner)?_c('VfImage',{attrs:{"src":_vm.data.banner.src,"seo":_vm.data.banner.seo}}):_vm._e()],1),_vm._v(" "),(_vm.data.title)?_c('VfText',{staticClass:"vf-loyalty-order-register__title",attrs:{"modifier":"subtitle-2","content":_vm.data.title}}):_vm._e(),_vm._v(" "),(_vm.data.subtitle)?_c('VfText',{staticClass:"vf-loyalty-order-register__subtitle",attrs:{"content":_vm.data.subtitle}}):_vm._e(),_vm._v(" "),(_vm.data.ptsExplanation)?_c('div',{staticClass:"vf-loyalty-order-register__description"},[_c('VfIcon',{attrs:{"icon":"xplr_points","size":"60px","color":_vm.theme.logo.color}}),_vm._v(" "),_c('VfText',{staticClass:"vf-loyalty-order-register__small",attrs:{"content":_vm.data.ptsExplanation}})],1):_vm._e()],1),_vm._v(" "),(_vm.data.ctaText)?_c('ThemeButton',{staticClass:"vf-loyalty-order-register__cta-button",attrs:{"color":_vm.isCheckoutSuccessRedesignEnabled ? _vm.theme.buttonVariant : 'primary',"size":"md","to":_vm.shouldOpenInNewModal(_vm.data.ctaTarget) ? '' : _vm.data.ctaTarget},on:{"click":function($event){_vm.shouldOpenInNewModal(_vm.data.ctaTarget) &&
        _vm.openModal({
          type: 'lazyFragment',
          resourceId: _vm.data.ctaTarget.replace(/\D/g, ''),
        })}}},[_vm._v("\n    "+_vm._s(_vm.data.ctaText)+"\n  ")]):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }