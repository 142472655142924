import { ROUTES } from '@vf/composables';

export const hideDefaultNotificationsRoutes = [
  ROUTES.ACCOUNT(),
  ROUTES.ACCOUNT_PROFILE(),
  ROUTES.ACCOUNT_FAMILY(),
  ROUTES.RETURNS(),
  ROUTES.PROFILE(),
  ROUTES.ACCOUNT('favourites-list'),
  ROUTES.ADDRESS_BOOK(),
  ROUTES.ORDER_HISTORY(),
  ROUTES.RETURN_HISTORY(),
  ROUTES.ORDER_DETAILS(),
  ROUTES.NEW_SHIPPING_ADDRESS(),
  ROUTES.NEW_BILLING_ADDRESS(),
  ROUTES.CREDIT_CARDS(),
  ROUTES.NEW_CREDIT_CARD(),
  ROUTES.FAVORITES_PRIVATE(),
];
