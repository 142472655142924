














































































































import {
  defineComponent,
  PropType,
  reactive,
  ref,
  onMounted,
  onUnmounted,
} from '@nuxtjs/composition-api';
import ESpot from '@/components/smart/shared/ESpot.vue';
import { CmsProps } from '@/components/smart/shared/ProductsGrid/CmsProps';
import { Context } from '@vf/api-contract';
import QuickShopTile from '@/components/product/QuickShopTile.vue';
import { useProductBadges } from '@/components/smart/shared/composables/useProductBadges';
import {
  useFavorites,
  useGridConfig,
  useGtm,
  useNotification,
  useProduct,
  useProductInventory,
  useUrl,
} from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import { parseProductId } from '@vf/shared/src/utils/helpers';
import {
  getDefaultVariant,
  getTileUrl,
  getVariantByValue,
  isSoldOut,
} from '@/components/smart/shared/ProductsGrid/utils';
import { CategoryProduct, ProductInventoryState } from '@vf/api-client';
import useModal from '@/shared/useModal';
import { ProductDetailViewViewType } from '@vf/composables/src/types/gtm';
import { isCustomsProduct } from '@vf/composables/src/useCustoms/utils';
import { useCmsRefStore } from '@vf/composables/src/store/cmsRef';
import { storeToRefs } from 'pinia';
import plpCache from '@vf/shared/src/theme/productsGridCache';
import { useUserStore } from '@vf/composables/src/store/user';
import useSignInToBuy from '@/components/smart/shared/composables/useSignInToBuy';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

export default defineComponent({
  name: 'ProductsGridItem',
  components: {
    ESpot,
    QuickShopTile,
  },
  props: {
    ...CmsProps,
    id: {
      type: [String, Number],
      required: true,
    },
    rowId: String,
    product: Object as PropType<CategoryProduct>,
    currentPage: Number,
    columnSizes: Function,
    searchState: Object,
    contextKey: String as PropType<Context>,
    eagerLoadedProducts: {
      type: Number,
      default: 6,
    },
    imageSize: Object,
    contextName: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const cmsRefStore = useCmsRefStore();
    const { root } = useRootInstance();
    const { badges: cmsBadges } = storeToRefs(cmsRefStore);
    const {
      getProductBadge,
      getProductBadges,
      getColorSwatchBadge,
    } = useProductBadges(cmsBadges);

    const {
      toggleQuickShop,
      findInStoreQuickShopVisible,
      loadPlpGalleryImages,
    } = useProduct(root, Context.QuickShop);
    const { onSignInToBuy } = useSignInToBuy(root, Context.QuickShop);

    const { getProductUrl } = useUrl(root);
    const userStore = useUserStore(root);
    const { loyaltyEnrolled } = storeToRefs(userStore);

    const { dispatchEvent, updatePersistentVariables } = useGtm(root);

    const { currentConfig, isShowBadgesForGrid, isOneColumn } = useGridConfig(
      root
    );
    const { openModal, isModalOpen } = useModal();
    const {
      isFavorite,
      getFavorites,
      loading: addToWishListLoading,
    } = useFavorites(root);
    const { plpShowReviews } = useFeatureFlagsStore();

    const { addNotification } = useNotification(root);

    const { getProductInventory, inventoryLoading } = useProductInventory(
      root,
      Context.QuickShop
    );

    const theme = {
      productCard: {
        maxSliderImages: 3,
        ...root.$themeConfig.productCard,
      },
      productsGrid: {
        ...root.$themeConfig.productsGrid,
      },
      productAddToCart: {
        ...root.$themeConfig.productAddToCart,
      },
    };

    const getProductImages = (product, selectedVariantValue = '') =>
      getProductImagesImplementation({
        product,
        props,
        root,
        selectedVariantValue,
        theme,
      });
    const showBadgesByColorSwatch = theme.productCard.showBadgesByColorSwatch;

    const galleryImages = ref([]);
    const galleryImagesCache = ref({});
    const images = ref(
      props.showProductImages ? getProductImages(props.product) : ''
    );
    const colorCode = ref();
    const colors = reactive({
      options: props.product.color_swatches,
      selected: getDefaultVariant(props.product),
    });
    const badges = ref(
      getProductBadges(props.product, showBadgesByColorSwatch)
    );

    onMounted(() => {
      // triggered from ProductAddToCart.vue
      root.$eventBus.$on('pDpQuickshopSignInToBuyEmit', onSignInToBuy);
    });

    onUnmounted(() => {
      root.$eventBus.$off('pDpQuickshopSignInToBuyEmit', onSignInToBuy);
    });

    return {
      getProductBadge,
      getProductBadges,
      getColorSwatchBadge,
      loyaltyEnrolled,
      isOneColumn,
      dispatchGtmEvent: dispatchEvent,
      updateGtmPersistentVariables: updatePersistentVariables,
      isModalOpen,
      getProductUrl,
      isFavorite,
      addToWishListLoading,
      onSignInToBuy,
      isCustomsProduct,
      isSoldOut,
      theme,
      loadPlpGalleryImages,
      getProductImages,
      showBadgesByColorSwatch,
      openModal,
      toggleQuickShop,
      findInStoreQuickShopVisible,
      currentConfig,
      isShowBadgesForGrid,
      getFavorites,
      addNotification,
      getProductInventory,
      inventoryLoading,
      galleryImages,
      galleryImagesCache,
      colorCode,
      images,
      colors,
      badges,
      plpShowReviews,
    };
  },
  computed: {
    productItem() {
      return {
        ...this.transformProductToCardData(this.$props.product),
        images: this.images,
        galleryImages: this.galleryImages,
        colorCode: this.colorCode,
        colors: this.colors,
        badges: this.badges,
      };
    },
    pictureScreenLarge() {
      return this.isOneColumn
        ? this.theme.productsGrid.imageSizes.medium.width
        : undefined;
    },
    pictureScreenMedium() {
      return this.isOneColumn
        ? this.theme.productsGrid.imageSizes.small.width
        : undefined;
    },
    isSmallViewport() {
      return this.$root.$viewport.isSmall;
    },
    showSwiperIcons() {
      return (
        this.currentConfig?.product?.[this.$root.$viewport.size]
          ?.showSwiperIcons ?? false
      );
    },
    showImageGallery() {
      const scrollableImages =
        this.currentConfig?.product?.[this.$root.$viewport.size]
          ?.scrollableImages ?? false;
      return this.$props.showProductImages && scrollableImages;
    },
    maxSliderImages() {
      return this.theme.productCard.maxSliderImages;
    },
  },
  methods: {
    transformProductToCardData(product) {
      if (product.espot) return product;
      const tileUrl = this.getTileUrl(product);
      const parsedProductId = parseProductId(product.id);

      return {
        ...product,
        id: product.id,
        tileUrl,
        ratingLink: `${tileUrl}#pr-container`,
        badge: this.getProductBadge(product.labels),
        variationsLabel: this.getVariationsLabel(product),
        parsedProductId,
      };
    },
    getTileUrl(product) {
      return getTileUrl(product, this.getProductUrl);
    },
    getVariationsLabel(product) {
      return product.productType?.variationGroup && product.moreColorsAvailable
        ? this.$props.translations.seeMoreColorsLink
        : null;
    },
    getBadges(productItem) {
      const badges = productItem.badge
        ? [productItem.badge]
        : productItem.badges;

      return this.showProductBadge && this.isShowBadgesForGrid
        ? badges.filter(Boolean)
        : [];
    },
    showQuickShopForProduct(product) {
      return (
        (!product.isCustoms || !!product.dummyCustoms) &&
        !this.isSignInToBuy(product)
      );
    },
    isSignInToBuy(product: CategoryProduct) {
      return product.presale && !this.loyaltyEnrolled;
    },
    getAddToCartCtaConfig(product: CategoryProduct) {
      switch (product?.productInventoryState) {
        case ProductInventoryState.SoldOut:
          return {
            label: this.$props.translations.noLongerAvailableLabel,
            icon: 'info',
            disabled: true,
          };
        case ProductInventoryState.OutOfStock:
          return {
            label: this.$props.translations.notifyMeLabel,
            icon: 'chat_alt',
          };
        default:
          return {
            label: this.$props.translations.addToCartLabel,
            icon: 'cart',
          };
      }
    },
    async openProductQuickShop(
      categoryProduct,
      index = 0,
      productConfiguration = null
    ) {
      this.storeDestination(categoryProduct);
      this.pushClickDataLayer(categoryProduct, index, 'Open Quick View');
      this.findInStoreQuickShopVisible = false;

      try {
        this.inventoryLoading = true;
        await this.toggleQuickShop(
          parseProductId(categoryProduct.id),
          productConfiguration
        );

        if (!this.$root.$getCustomConfiguration('callFavoritesOnSRPAndPLP')) {
          await this.getFavorites();
        }

        this.openModal({
          type: 'page',
          path: this.$props.quickShopLink,
          contextKey: Context.QuickShop,
          additionalData: {
            cssAnimationDuration: this.theme.productCard
              .quickShopAnimationDuration,
          },
        });

        await this.getProductInventory(parseProductId(categoryProduct.id));

        this.dispatchGtmEvent({
          eventName: 'productDetailView',
          composablesContexts: { useProduct: Context.QuickShop },
          overrideAttributes: {
            viewType: ProductDetailViewViewType.QUICK_VIEW,
          },
        });
      } catch (e) {
        this.addNotification({
          message: this.$props.translations.missingProductError,
          type: 'danger',
        });
      } finally {
        this.inventoryLoading = false;
      }
    },
    pushClickDataLayer(categoryProduct, index = 0, action = '') {
      this.dispatchGtmEvent({
        eventName: 'productClick',
        overrideAttributes: {
          product: categoryProduct,
          contextName: this.$props.contextName,
          position: index + 1,
          action,
          badge: categoryProduct.badges?.[0]?.alt,
        },
      });
    },
    onToggleQuickShop(
      { isOpen, contextKey: contextKeyQuickShopTile, productConfiguration },
      categoryProduct,
      index = 0
    ) {
      if (!isOpen) return;
      if (this.isSmallViewport) {
        // open modal
        this.openProductQuickShop(categoryProduct, index, productConfiguration);
        return;
      }
      this.pushClickDataLayer(categoryProduct, index, 'Open Quick Shop');
      this.dispatchGtmEvent({
        eventName: 'productDetailView',
        composablesContexts: {
          useProduct: contextKeyQuickShopTile,
        },
        overrideAttributes: {
          viewType: ProductDetailViewViewType.QUICK_SHOP,
        },
      });
    },
    storeDestination(categoryProduct) {
      const [productPath] = categoryProduct.tileUrl?.split('?') ?? [];

      plpCache.storeDestination({
        productId: categoryProduct.id,
        rowId: this.$props.rowId,
        productPage: this.$props.currentPage,
        from: this.$root.$route.fullPath,
        to: productPath,
      });
    },
    handlerGoToPDP(categoryProduct, index = 0) {
      this.storeDestination(categoryProduct);
      this.pushClickDataLayer(categoryProduct, index, 'Navigate To PDP');
      this.updateGtmPersistentVariables(categoryProduct.id, {
        viewType: ProductDetailViewViewType.PDP,
      });
    },
    onShowDetail(categoryProduct, index = 0) {
      this.storeDestination(categoryProduct);
      this.pushClickDataLayer(categoryProduct, index, 'Click View Details');
      this.updateGtmPersistentVariables(categoryProduct.id, {
        viewType: ProductDetailViewViewType.VIEW_DETAILS,
      });
    },
    getTileImages(productItem) {
      return this.showImageGallery && productItem.galleryImages.length
        ? productItem.galleryImages
        : productItem.images;
    },
    async retrieveGalleryByKey({
      colorCode,
      colorName,
      productId,
      productName,
    }) {
      let imageSliced;
      if (this.galleryImagesCache[colorCode]) {
        imageSliced = this.galleryImagesCache[colorCode];
      } else {
        const images = await this.loadPlpGalleryImages({
          colorCode,
          colorName,
          productId: parseProductId(productId),
          productName,
        });
        imageSliced = images ? images.slice(0, this.maxSliderImages) : [];
        this.galleryImagesCache[colorCode] = imageSliced;
      }
      return imageSliced;
    },
    async changeQuickshopImage(payload: {
      colorCode: string;
      colorName: string;
      productId: string;
      productName: string;
    }) {
      if (!this.showImageGallery) {
        return;
      }

      this.colorCode = payload.colorCode;
      this.colors.selected = payload.colorCode;

      if (this.galleryImages.length) {
        this.galleryImages = await this.retrieveGalleryByKey(payload);
        if (this.galleryImages.length) return;
      }

      this.images = this.getProductImages(this.product, payload.colorCode);
    },
    async fetchPlpGallery(payload: {
      colorCode: string;
      colorName: string;
      productId: string;
      productName: string;
    }) {
      this.galleryImages = await this.retrieveGalleryByKey(payload);
    },
    changeSelectedSwatch(product, label) {
      if (this.showBadgesByColorSwatch) {
        this.badges = this.getColorSwatchBadge(product, label);
      }
    },
  },
});

const getProductImagesImplementation = ({
  product,
  props,
  root,
  selectedVariantValue,
  theme,
}) => {
  if (product.imageDeclined) {
    return root.$mediaUrlFallback();
  }
  const isFavoritesPage = props.contextName === 'favorites';
  const isCustomProduct = product.isCustoms && !product.dummyCustoms;

  if (product.productImageUrl && (isFavoritesPage || isCustomProduct)) {
    return product.productImageUrl;
  }

  let shotType = '';
  const { value: colorCode, name: colorName } = getVariantByValue(
    product,
    selectedVariantValue
  ) ||
    getDefaultVariant(product, true) || { value: '', name: '' };
  if (theme.productCard.showImagesWithShotType) {
    shotType = `_${colorCode}_hero`;
  } else {
    shotType = root.$env.IMAGES_SHOTTYPE;
  }

  let productIdToBuildUrl = product[theme.productCard.stylePropName];

  const imageSizes = theme.productsGrid.imageSizes;
  const mainImage = root.$mediaUrlGenerator({
    pid: productIdToBuildUrl,
    productName: product.name,
    colorName,
    colorCode,
    shotType,
    imageSizes,
  });

  if (product.hasAltImage) {
    const tmpImages = [];
    tmpImages.push(mainImage);
    tmpImages.push(
      root.$mediaUrlGenerator({
        pid: productIdToBuildUrl,
        productName: product.name,
        colorName,
        colorCode,
        shotType: root.$env.IMAGES_ALTTYPE,
        imageSizes,
      })
    );

    return tmpImages;
  }

  return mainImage;
};
