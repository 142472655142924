











































import { PropType } from 'vue';
import { computed, defineComponent } from '@vue/composition-api';
import { Context } from '@vf/api-contract';
import { useReviews, useProduct, useUrl } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import VfFitScale from '@vf/ui/components/Atom.FitScale.vue';
import { scrollToElement } from '@vf/shared/src/utils/helpers';

export default defineComponent({
  name: 'PdpSizeAndFit',
  components: {
    VfFitScale,
  },
  props: {
    translations: Object,
    contextKey: {
      type: String as PropType<Context>,
      required: true,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { product, isQuickShopContext } = useProduct(root, props.contextKey);
    const { getProductUrl } = useUrl(root);

    const modelMeasurements = computed<string[]>(() => {
      const attributeColor = product.value?.attributes?.find(
        (attr) => attr.code === 'color'
      );
      if (!attributeColor || !product.value.color) return;

      return attributeColor.options.find(
        (opt) => opt.description === product.value.color.description
      )?.modelMeasurements;
    });

    const sizeFitSubEnding = computed<string>(() => {
      return props.translations?.sizeFitSubEnding?.replace(
        '{{reviewsCount}}',
        product.value?.rating?.numReviews ?? 0
      );
    });

    const { productReview } = useReviews(root, props.contextKey);

    const isMeasurementsAvailable = computed(
      () => modelMeasurements.value && modelMeasurements.value.length
    );
    const sizing = computed(() => {
      const { value, maxValue } = productReview.value?.sizing ?? {};
      return {
        value,
        maxValue,
        maxLabel: props.translations.tooBig,
        minLabel: props.translations.tooSmall,
      };
    });

    const isFitScaleAvailable = computed(() => {
      return (
        root.$themeConfig.reviews?.useExpandedReviewsPanel &&
        Number.isInteger(sizing.value.value)
      );
    });

    const ratingLink = computed(() => {
      const anchor = '#pr-container';
      if (!isQuickShopContext.value || !product.value) return anchor;
      const productURL = getProductUrl(product.value, {
        recipe:
          product.value.recipe ||
          product.value.recipeId ||
          product.value.customsRecipeID,
      });
      return productURL + anchor;
    });
    const handleRatingClick = () => scrollToElement('#pr-container');

    return {
      modelMeasurements,
      sizeFitSubEnding,
      sizing,
      isFitScaleAvailable,
      isMeasurementsAvailable,
      ratingLink,
      handleRatingClick,
    };
  },
});
