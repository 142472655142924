


























































import type { PropType } from 'vue';
import { defineComponent, computed } from '@vue/composition-api';
import {
  ProductQuantityTranslations,
  SelectorVariant,
  SelectorVariants,
} from '@vf/api-contract';
import { useProduct, useSectionNumeration } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

export default defineComponent({
  name: 'ProductQuantitySelect',
  props: {
    translations: {
      type: Object as PropType<ProductQuantityTranslations>,
      default: () => ({}),
    },
    /** Maximum quantity of product to add */
    maxQuantity: {
      type: Number,
      default: 5,
    },
    /** Whether size selector should be a select or chip */
    displayAs: {
      type: String as PropType<SelectorVariant>,
      default: SelectorVariant.Dropdown,
      validator: (value: SelectorVariant) => {
        return SelectorVariants.includes(value);
      },
    },
    /** Flag to determine if whole component should be hidden or not */
    hideComponent: {
      type: Boolean,
      default: false,
    },
    /** Select with page type */
    contextKey: {
      type: String,
      default: 'product',
    },
    /** Prefix counter label */
    showNumeration: {
      type: Boolean,
    },
  },
  setup(props, { attrs }) {
    const { root } = useRootInstance();
    const { product, changeQuantity } = useProduct(root, props.contextKey);
    const { getSectionNumber } = useSectionNumeration(root);
    // To be cleaned up with GLOBAL15-63801
    const { isVansPdpRedesignEnabled } = useFeatureFlagsStore();

    const selectedQuantity = computed(() => {
      return product.value.quantity ? product.value.quantity.value : 1;
    });

    const title = computed(() => {
      const { quantityLabel } = props.translations;
      const prefixLabel = getSectionNumber(attrs['data-id'] as string);

      return props.showNumeration && prefixLabel
        ? `${prefixLabel}. ${quantityLabel}`
        : quantityLabel;
    });

    return {
      product,
      changeQuantity,
      isVansPdpRedesignEnabled,
      selectedQuantity,
      SelectorVariant,
      title,
    };
  },
});
