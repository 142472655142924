























































const WIDGET_HEIGHT = 192; // vans 4 pill button height design
const DESCRIPTION_KEY = 'title';
import {
  defineComponent,
  computed,
  onMounted,
  onUnmounted,
  ref,
  Ref,
  watch,
} from '@vue/composition-api';
import useRootInstance from '@/shared/useRootInstance';
import { useFetch } from '@nuxtjs/composition-api';
import { useSeo, useRequestTracker } from '@vf/composables';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import { isClient } from '@vf/shared/src/utils/helpers';
import {
  RelatedProduct,
  SeoRecommendationType,
} from '@vf/composables/src/useSeo/types';

export default defineComponent({
  name: 'SeoRelatedWidgetTextOnly',
  props: {
    contextKey: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const {
      currentRecommendation,
      getRecommendation,
      hasRelatedCategories,
      hasRelatedItems,
      seoObject,
      isVisible,
      setGlobalVariable,
      getPageData,
    } = useSeo(root, props.contextKey);
    const { onAllDone } = useRequestTracker(root);

    // TODO: Cleanup in GLOBAL15-63799
    const { isVansPlpRedesignEnabled } = useFeatureFlagsStore();

    const seoPillboxScrollHeight: Ref<number> = ref(0);
    const slideHeight: Ref<number | string> = ref(WIDGET_HEIGHT);
    const hide: Ref<boolean> = ref(true);

    const relatedSearches = computed(() =>
      currentRecommendation.value?.recommendationData
        ? [
            ...currentRecommendation.value.recommendationData.relatedItems,
            ...currentRecommendation.value.recommendationData.relatedCategories,
          ]
        : null
    );

    const seoConsolidatedList = computed(() => {
      let items = [];
      if (hasRelatedItems.value) {
        currentRecommendation.value.recommendationData.relatedItems.forEach(
          (elem, index) => {
            const item = {
              itemKey: `${elem.url}-item-${index}`,
              itemUrl: elem.url,
              itemLabel: elem.anchor,
            };
            items.push(item);
          }
        );
      }

      currentRecommendation.value?.recommendationData.relatedProducts.forEach(
        (elem, index) => {
          const item = {
            itemKey: `${elem.url}-product-${index}`,
            itemUrl: elem.url,
            itemLabel: elem.title,
          };
          items.push(item);
        }
      );

      if (hasRelatedCategories.value) {
        currentRecommendation.value.recommendationData.relatedCategories.forEach(
          (elem, index) => {
            const item = {
              itemKey: `${elem.url}-category-${index}`,
              itemUrl: elem.url,
              itemLabel: elem.anchor,
            };
            items.push(item);
          }
        );
      }
      return items;
    });

    const seoPillsHeight = computed(() => {
      return seoPillboxScrollHeight.value;
    });

    const hasOverflow = computed(() => seoPillsHeight.value > WIDGET_HEIGHT);

    const showHide = () => {
      hide.value = !hide.value;
      hide.value
        ? (slideHeight.value = WIDGET_HEIGHT)
        : (slideHeight.value = seoPillsHeight.value);
    };

    const setContainerHeight = () => {
      const seoPillbox = document.querySelector(
        '.vf-widget-recommendations__pills'
      );
      seoPillboxScrollHeight.value = seoPillbox ? seoPillbox.scrollHeight : 0;
      slideHeight.value = hasOverflow.value ? WIDGET_HEIGHT : 'auto';
      hide.value = true;
    };

    onMounted(() => {
      setContainerHeight();
      window.addEventListener('resize', setContainerHeight);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', setContainerHeight);
    });

    if (isClient) {
      watch(
        () => root.$route.path,
        (newRt, oldRt) => {
          setGlobalVariable();
          if (!newRt || (oldRt && newRt === oldRt)) return;
          const pageUrlData = getPageData(newRt);
          if (!pageUrlData) return;
          onAllDone(() => {
            getRecommendation(
              pageUrlData.url,
              pageUrlData.type as SeoRecommendationType,
              pageUrlData.id
            );
          });
          setGlobalVariable(currentRecommendation.value);
        },
        { immediate: true }
      );
      setGlobalVariable(currentRecommendation.value);
    } else {
      useFetch(async () => {
        const pageUrlData = getPageData(root.$route.path);
        if (!pageUrlData) return;
        await getRecommendation(
          pageUrlData.url,
          pageUrlData.type as SeoRecommendationType,
          pageUrlData.id
        );
      });
    }

    const getProductDescription = (product: RelatedProduct) =>
      product[DESCRIPTION_KEY];

    return {
      hide,
      isVisible,
      showHide,
      seoPillsHeight,
      hasOverflow,
      slideHeight,
      seoConsolidatedList,
      relatedSearches,
      seoObject,
      getProductDescription,
      isVansPlpRedesignEnabled,
    };
  },
});
