











import {
  nextTick,
  ref,
  watch,
  onMounted,
  onBeforeUnmount,
} from '@vue/composition-api';
import { defineComponent, useFetch, useMeta } from '@nuxtjs/composition-api';
import axios from 'axios';
import useRootInstance from '@/shared/useRootInstance';
import { getCoremediaAxiosHeaders } from '@/helpers';

export default defineComponent({
  name: 'StaticContent',
  components: {},
  props: {
    /** Customs Url that used as path name to static-pages directory */
    customUrl: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const staticContentManifest = root.$env.STATIC_CONTENT_MANIFEST;
    const staticContentRequire = root.$env.STATIC_CONTENT_REQUIRE;

    if (staticContentManifest && staticContentRequire) {
      useMeta({
        script: [
          {
            type: 'text/javascript',
            src: staticContentManifest,
          },
          {
            type: 'text/javascript',
            'data-main': 'cms.main',
            src: staticContentRequire,
          },
        ],
      });

      // The meta tags above inject RequireJS for use by pre-existing legacy content.
      // Unfortunately this may cause issues with 3rd party libraries, as when they
      // detect the the "define" function is available on the widnow, a lot of them
      // will register themselves as dependencies instead of automatically running,
      // resulting in objects that should be expected on the window to never be attached.
      //
      // Usually the factory patterns most libraries use can be simplified as follows:
      //   if (window.define && window.define.amd) define(['library-name', libraryFunction])
      //
      // The "amd" property is used to differentiate the RequireJS define from
      // other, even more legacy, functions that might exist in a number of environments.
      // Its default value always is { jQuery: true }
      // (https://github.com/jquery/jquery/pull/331#issue-779774)
      //
      // This allows us to manipulate third parties into thinking we don't have
      // RequireJS in the global scope by simply setting a falsy value to define.amd,
      // and restoring the value when it's needed for static content.
      onMounted(() => {
        const defineInterval = setInterval(() => {
          if (window.define) {
            clearInterval(defineInterval);

            window.define.amd = {
              jQuery: true,
            };
          }
        }, 200);
      });
      onBeforeUnmount(() => {
        window.define.amd = null;
      });
    }

    const html = ref(null);
    const customJavascript = ref(null);
    const scriptSrc = ref(null);
    // This is only necessary for local development.
    // Production and development environments
    // should be root/site relative.
    // Setting the CUSTOM_CONTENT_HOST_URL value
    // is only necessary for local development
    const customContentHostUrl =
      root.$env.CUSTOM_CONTENT_HOST_URL || `https://${root.$getDomainName()}`;

    // /custom-content/ is a path routed to the AWS bucket through Akamai
    const contentPageUrl = `${customContentHostUrl}/custom-content/pages/${props.customUrl}.html`;

    if (customContentHostUrl && props.customUrl) {
      useFetch(async () => {
        root.$log.debug('Static Content Page', { contentPageUrl });
        const content = await axios.get<string>(contentPageUrl, {
          headers: getCoremediaAxiosHeaders(root),
        });

        // Safari doesn't yet support look behind regular expressions
        // so this returns everything we need plus the start script tag
        const dataHtml = content.data;
        const regex = /(?=\<script\>)(.*)(?=\<\/script\>)/gms;
        // get the script inside the content.
        // Note: It still conatins the start tag
        const scriptSourceWithTag = dataHtml.match(regex)[0];
        // Use the script as a reference to split it out the html
        const htmlContent = dataHtml.replace(
          // This needs to be hacked in since the parser
          // doesn't like the script tag inline.
          scriptSourceWithTag + '</' + 'script>',
          ''
        );
        // Update the HTML before we update the JS
        html.value = htmlContent;
        // Remove the start tag from the script.
        const scriptSourceWithoutTag = scriptSourceWithTag.replace(
          /\<script\>/,
          ''
        );
        const cmsComponentInitializer =
          "require.undef('cms.main');\nrequire(['cms.main']);\n";

        // Wrap the JS in an IIFE to provide closure and save it
        scriptSrc.value = `(() => {${cmsComponentInitializer}${scriptSourceWithoutTag}})()`;
      });

      // Watch for changes to the HTML so that we can wait
      // for it to be added to the DOM before injecting the JS
      // There is a race condition when the HTML is updated on CSR
      // The v-html is delayed until after the JS is injected and
      // DOM manipulation is possible.
      watch(html, (newValue) => {
        // See if there is a value for the new HTML and we are on the client
        if (newValue && process.client) {
          // Delay injecting the JS until the next ticket so HTML can be updated
          nextTick(() => {
            customJavascript.value.innerHTML = scriptSrc.value;
          });
        }
      });
    }

    return {
      html,
      customJavascript,
      scriptSrc,
    };
  },
  head: {},
});
