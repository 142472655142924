
import type { PropType, VNode } from 'vue';
import {
  computed,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch,
  set,
  defineComponent,
  h,
  inject,
} from '@vue/composition-api';
import { mask } from 'ke-the-mask';
import { validationMixin } from 'vuelidate';
import type { AddressFormTranslations } from '@vf/api-contract';
import {
  useValidation,
  useUtilities,
  useGoogleAutocomplete,
  useAccount,
} from '@vf/composables';
import { Field, Form } from '@vf/composables/src/types/utilities';
import { errorMessages } from '@vf/composables/src/utils/errorMessages';
import { scrollToFirstError, isSafari } from '@vf/shared/src/utils/helpers';
import { viewBuilder } from '@/helpers/viewBuilder';
import cloneDeep from '@vf/shared/src/utils/cloneDeep';
import useRootInstance from '@/shared/useRootInstance';
import VfButton from '@vf/ui/components/Atom.Button.vue';
import VfHeading from '@vf/ui/components/Atom.VfHeading.vue';
import VfSelectOption from '@vf/ui/components/Atom.VfSelectOption.vue';
import VfSelect from '@vf/ui/components/Molecule.VfSelect.vue';
import VfInput from '@vf/ui/components/Molecule.Input.vue';
import VfPhoneInput from '@vf/ui/components/Molecule.PhoneInput.vue';
import { CountryCode } from 'libphonenumber-js/types';
import merge from '@vf/composables/src/utils/merge';
import { PhoneInputCountry } from '@vf/composables/src/useUtilities';
import { getFieldById } from '@vf/composables/src/utils/getFieldById';

type ValidationRule = {
  fn: (input: string) => boolean;
  predicateName?: string;
};

type FormData = {
  options?: {
    province?: string;
    fieldLabelKey?: string;
  };
  hints?: Record<string, string[]>;
  transformers?: Record<string, (...args: unknown[]) => unknown>;
  validation?: Record<string, Record<string, ValidationRule>>;
};

type UpdateFormDataOptions = {
  preventFormValidationFields: string[];
};

const DEFAULT_FIELDS_ORDER = [
  'country',
  'firstName',
  'lastName',
  'address1',
  'address2',
  'postalCode',
  'city',
  'province',
  'email',
  'phone',
];

const DEFAULT_PHONE_MASK = '###-###-####';
const DEFAULT_US_PHONE_FORMAT = 'XXX-XXX-XXXX';
const DEFAULT_PHONE_FORMAT = 'XXXXXXXX';
const DEFAULT_LOCALES = ['us', 'ca'];

enum AddressFormContext {
  ShippingAddressForm = 'shipping-address-form',
  BillingAddressForm = 'billing-address-form',
  AddressForm = 'address-form',
}

export default defineComponent({
  name: 'AddressForm',
  directives: { mask },
  mixins: [validationMixin],
  props: {
    contextName: {
      type: String as PropType<AddressFormContext>,
      default: AddressFormContext.AddressForm,
    },
    translations: {
      type: Object as PropType<AddressFormTranslations>,
      default: () => ({}),
    },

    /** Prop to decide whether show heading or not */
    showHeading: {
      type: Boolean,
      default: true,
    },
    /** Prop to decide whether show back buttons or not */
    showBackButton: {
      type: Boolean,
      default: false,
    },
    /** Prop to decide whether show action buttons or not */
    showActionButtons: {
      type: Boolean,
      default: false,
    },
    /** Prop to decide whether show email helper text or not */
    showEmailHelperText: {
      type: Boolean,
      default: true,
    },
    /** Prop to decide whether show phone helper text or not */
    showPhoneHelperText: {
      type: Boolean,
      default: true,
    },
    /** Prop to decide whether show edit heading text or not */
    showEditHeading: {
      type: Boolean,
      default: false,
    },
    /** Prop to decide whether show country select or not */
    showCountry: {
      type: Boolean,
      default: true,
    },
    /** Prop to decide whether show email input or not */
    showEmail: {
      type: Boolean,
      default: true,
    },
    /** Prop to use email profile if address email is empty */
    useEmailFromProfile: {
      type: Boolean,
      default: true,
    },
    /** Shipping address form values */
    value: {
      type: Object,
      default: () => ({
        firstName: null,
        lastName: null,
        addressLine1: null,
        addressLine2: null,
        postalCode: null,
        city: null,
        province: null,
        phone: null,
        main: false,
      }),
    },
    /** Alignment of heading in the form */
    headingAlignment: {
      type: String,
      default: 'left',
    },
    /** Is form rendered in a modal? */
    inModal: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { root } = useRootInstance();
    const { setValidation, $v, getValidationHandler } = useValidation(
      root,
      'ADDRESS_FORM'
    );
    const {
      getCountryList,
      getPhoneInputCountryList,
      getI18nFallback,
      getI18n,
    } = useUtilities(root);
    const { displayErrorMessages } = errorMessages(root);
    const {
      basicInformation,
      isAddAddressRequestPending,
      isAccountSaveCreditButtonDisabled,
    } = useAccount(root);
    const {
      loadGoogleApisScript,
      unloadGoogleApisScript,
      setupGoogleAutocomplete,
      isValidContext,
      isGoogleAutocompleteEnabled,
      googleAutocompleteCountries,
    } = useGoogleAutocomplete(root);
    const locale = root.$i18n.locale;

    const addressForm = reactive(cloneDeep(props.value));
    const country = computed(
      () =>
        addressForm?.country ||
        root.$getEnvValueByCurrentLocale<string>('COUNTRY').toUpperCase()
    );
    const phoneInputCountry = ref<CountryCode>();
    const phoneInputCountries = ref<PhoneInputCountry[]>([]);

    const dataI18n = ref<Form>({
      formId: '',
      fields: [],
    });
    const countries = ref([]);
    const formData = ref<FormData>({});
    const validationData = {};
    const provinces = computed(() => formData.value?.options?.province || []);
    const provinceLabel = computed(
      () =>
        formData.value?.options?.fieldLabelKey ||
        props.translations.provinceLabel
    );
    const transformers = computed(() => formData.value?.transformers || {});
    const isProvinceTextField = ref(false);

    const getHeadingText = computed(() => {
      return props.showEditHeading
        ? props.translations.editHeading
        : props.translations.addHeading;
    });
    // TODO: GLOBAL15-61059 remove after core redesign
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');

    watch(addressForm, () => {
      emit('input', addressForm);
    });

    // modelValue is non reactive object while addressForm is a reactive clone
    // addressForm requires manual update upon external modelValue change
    const modelValueRef = computed(() => props.value);

    const theme = root.$themeConfig.AddressForm;

    const buttonBackToAddressBookClass =
      theme.buttonBackToAddressBookClass ||
      'vf-button--text vf-button--no-underline form__back_button';

    const buttonBackToAddressBookShowIcon =
      theme.buttonBackToAddressBookShowIcon || false;

    const buttonBackToAddressBookIconName =
      theme.buttonBackToAddressBookIconName || 'chevron_left';

    const buttonSubmitClass =
      theme.buttonSubmitClass || 'vf-button form__action-button';

    const buttonCancelClass =
      theme.buttonCancelClass || 'vf-button--secondary form__action-button';

    const markFieldAsRequired = theme.markFieldAsRequired || false;

    const scrollFormToError = theme.scrollFormToError || false;

    const showPhoneInputCountry = theme.showPhoneInputCountry || false;

    const phoneMask = ref('');
    const postalCodeMask = ref('');
    const provinceChange = (value) => {
      addressForm.province = value;
    };

    let isMounted = false;

    const countryChange = async (value) => {
      addressForm.country = value;
      addressForm.province = '';
      isProvinceTextField.value = false;
      try {
        await updateFormData();
        if (provinces.value.length === 0) {
          isProvinceTextField.value = true;
        }
      } catch (err) {
        displayErrorMessages(err);
      }
      blurAndValidate('province');
    };

    const phoneInputCountryChange = async (value) => {
      phoneInputCountry.value = value;
      if (!isMounted) return; // do not perform any api calls if component is not mounted
      try {
        await updateFormData({ preventFormValidationFields: ['phone'] });
      } catch (err) {
        displayErrorMessages(err);
      }
    };

    const blurAndValidate = (field) => {
      if (transformers.value[field]) {
        addressForm[field] = transformers.value[field](addressForm[field]);
      }
      $v.value.addressForm?.[field]?.$touch();
      if (
        !$v.value.addressForm?.[field]?.$invalid &&
        !$v.value.addressForm.$anyError
      ) {
        root.$emit('address-form-blur', { field, value: addressForm[field] });
      }
    };

    // Validation checked for all fields as fallback values are provided
    const isFieldValid = (field) => {
      return !$v?.value?.addressForm?.[field]?.$error;
    };

    const getErrorMessage = (field: string, label: string) => {
      let message = props.translations.validationMessages?.fallback;

      const messages = {
        ...props.translations.validationMessages?.default,
        ...props.translations.validationMessages?.[field],
      };

      for (const [key, value] of Object.entries(messages)) {
        const validationField = $v?.value?.addressForm?.[field];
        if (validationField?.[key] === false) {
          message = (value as string).replace(/{{(\w+)}}/, (match, param) => {
            return validationField?.$params?.[key]?.[param] ?? match;
          });
          break;
        }
      }

      if (isHintEnabled(field) && formData?.value?.hints?.[field]) {
        message += getErrorMessageHint(field);
      }

      return message.replace('{{fieldName}}', label.toLowerCase());
    };

    const isHintEnabled = (field: string): boolean => field !== 'postalCode';

    const getErrorMessageHint = (field: string): string => {
      let hint = '';

      if (field === 'phone') {
        hint = formatMaskHint(field);
      } else {
        hint = formData.value.hints[field].join(
          ` ${props.translations.hintJoin} `
        );
      }

      return ` (${hint})`;
    };

    const formatMaskHint = (field: string) => {
      // This regex matches with values accepted by vue-the-mask to handle the mask
      // Values:
      // '#': {pattern: /\d/},
      // 'X': {pattern: /[0-9a-zA-Z]/},
      // 'S': {pattern: /[a-zA-Z]/},
      // 'A': {pattern: /[a-zA-Z]/, transform: v => v.toLocaleUpperCase()},
      // 'a': {pattern: /[a-zA-Z]/, transform: v => v.toLocaleLowerCase()},
      // '!': {escape: true}
      // We do this replacement to display an user friendly hint
      const searchRegExp = new RegExp('[aASx#!]', 'g');
      const replaceWith = 'X';
      let patterns = [];

      formData.value.hints[field].forEach((h) => {
        patterns.push(h.replace(searchRegExp, replaceWith));
      });

      return patterns.join(` ${props.translations.hintJoin} `);
    };

    const mapContextToFormName = (context: AddressFormContext) => {
      switch (context) {
        case AddressFormContext.ShippingAddressForm:
          return 'shippingForm';
        case AddressFormContext.BillingAddressForm:
          return 'billingForm';
        default:
          return context;
      }
    };

    const getPostalCodeMask = () => {
      const postalCodeData = getFieldById(dataI18n.value?.fields, 'postalCode');
      return postalCodeData?.format;
    };

    const getPhoneMask = (value: string): string => {
      const phoneData = getFieldById(dataI18n.value?.fields, 'phone');
      return (
        phoneData?.mask ??
        (DEFAULT_LOCALES.includes(value) ? DEFAULT_PHONE_MASK : '')
      );
    };

    const getPhoneFormat = (value: string): string => {
      const phoneData = getFieldById(dataI18n.value?.fields, 'phone');
      return (
        phoneData?.format ??
        (DEFAULT_LOCALES.includes(value)
          ? DEFAULT_US_PHONE_FORMAT
          : DEFAULT_PHONE_FORMAT)
      );
    };

    const mergeFields = (fields: Field[], overrideFields: Field[]): Field[] => {
      if (!fields?.length || !overrideFields?.length) return fields;
      const mergedFields = cloneDeep(fields);
      overrideFields.forEach((overrideField) => {
        const field = getFieldById(mergedFields, overrideField.fieldId);
        if (!field) return;
        const fieldIndex = mergedFields.indexOf(field);
        const mergedField = merge(field, overrideField);
        mergedFields.splice(fieldIndex, 1, mergedField);
      });
      return mergedFields;
    };

    const filterFieldsByFieldIds = (
      fields: Field[],
      fieldIds: string[]
    ): Field[] =>
      fields ? fields.filter((field) => fieldIds.includes(field.fieldId)) : [];

    const updateFormData = async (options?: UpdateFormDataOptions) => {
      const i18nData = cloneDeep(
        await getI18n(
          country.value.toLowerCase(),
          locale,
          mapContextToFormName(props.contextName)
        )
      );
      /*
       * To ensure proper field values for countries that don't have them
       * specified yet in countryList endpoint we first merge potential country
       * specific fields onto fallback fields. Then we can safely merge the
       * result onto fields provided in i18n endpoint. This way we have proper
       * fields for all countries.
       * */
      if (i18nData) {
        const countryDependantFieldIds = ['phone', 'postalCode', 'province'];
        try {
          const fallbackFields = filterFieldsByFieldIds(
            getI18nFallback(mapContextToFormName(props.contextName))?.fields,
            countryDependantFieldIds
          );

          const countrySpecificFields = normalizeCountrySpecificFields(
            filterFieldsByFieldIds(
              countries.value.find((c) => c.code === phoneInputCountry.value)
                ?.fields,
              countryDependantFieldIds
            ),
            fallbackFields
          );

          const mergedPhoneFields = mergeFields(
            i18nData.fields
              ? mergeFields(fallbackFields, i18nData.fields)
              : fallbackFields,
            countrySpecificFields
          );
          if (!getFieldById(i18nData.fields, 'phone')) {
            // very important because merge function merge only what we have in the first params
            i18nData.fields.push({ fieldId: 'phone' });
          }
          i18nData.fields = mergeFields(i18nData.fields, mergedPhoneFields);
        } catch (err) {
          root.$log.error(
            '[@components/smart/AddressForm::updateFormData]',
            err.message
          );
        }
      }
      dataI18n.value = i18nData;
      formData.value = viewBuilder(dataI18n.value, country.value);
      postalCodeMask.value = getPostalCodeMask();
      phoneMask.value = getPhoneMask(phoneInputCountry.value);
      //ToDo: rework this weird validation logic
      Object.keys(formData.value.validation).forEach((key) => {
        set(
          validationData,
          key,
          getConditionalValidationRules(formData.value.validation[key])
        );
      });
      Object.keys(validationData).forEach((key) => {
        if (!Object.prototype.hasOwnProperty.call(props.value, key)) {
          delete validationData[key];
        }
      });

      resetFormValidation({
        preventValidationOnFields: options?.preventFormValidationFields,
      });
    };

    const getConditionalValidationRules = (
      validators: Record<string, ValidationRule>
    ): Record<string, ValidationRule> => {
      return Object.entries(validators).reduce(
        (finalValidators, [name, config]) => {
          finalValidators[name] = getValidationHandler(config);
          return finalValidators;
        },
        {}
      );
    };

    const resetFormValidation = (options: {
      preventValidationOnFields: string[];
    }) => {
      for (const key of Object.keys(addressForm)) {
        if (
          $v.value.addressForm?.[key]?.$dirty &&
          !options?.preventValidationOnFields?.includes(key)
        ) {
          $v.value.addressForm?.[key]?.$touch();
        } else {
          $v.value.addressForm?.[key]?.$reset();
        }
      }
    };

    const submitAddressForm = () => {
      $v.value.addressForm.$touch();
      if (!$v.value.addressForm.$invalid) {
        emit('submit', addressForm);
      } else {
        if (scrollFormToError) {
          scrollToFirstError();
        }
      }
    };

    const isSaveButtonDisabled = computed(() => {
      return !!(
        isAddAddressRequestPending.value ||
        isAccountSaveCreditButtonDisabled.value ||
        $v.value?.addressForm?.$invalid
      );
    });

    const phoneInputErrorMessage = computed(() =>
      getErrorMessage('phone', props.translations.phoneLabel)?.replace(
        '{{phoneNumberFormat}}',
        getPhoneFormat(phoneInputCountry.value?.toLowerCase())
      )
    );

    onMounted(() => {
      root.$eventBus.$on(
        'validate-and-go-to-payment',
        validateAddressAndGoToPayment
      );

      prepareAddressForm();
    });

    const prepareAddressForm = async () => {
      try {
        countries.value = await getCountryList(locale);
        const allPhoneInputCountries = await getPhoneInputCountryList(locale);
        phoneInputCountries.value = props.showCountry
          ? allPhoneInputCountries
          : allPhoneInputCountries.filter(
              (item) => item.countryCode === country.value
            );
        await updateFormData();
        if (!provinces.value.length) {
          isProvinceTextField.value = true;
        }
        if (props.value.province) {
          provinceChange(props.value.province);
        }
        for (const [key, value] of Object.entries(props.value)) {
          if (value) {
            $v.value.addressForm?.[key]?.$touch();
          }
        }
      } catch (err) {
        displayErrorMessages(err);
      }

      if (
        isValidContext(props.contextName) &&
        isGoogleAutocompleteEnabled.value
      ) {
        await loadGoogleApisScript();

        setupGoogleAutocomplete(
          'addressline1',
          'addressline2',
          addressForm,
          googleAutocompleteCountries
        );
      }

      if (
        props.showEmail &&
        props.useEmailFromProfile &&
        basicInformation.value?.email
      ) {
        addressForm.email =
          !addressForm.email || addressForm.email === ''
            ? basicInformation.value.email
            : addressForm.email;
      }

      isMounted = true;
    };

    const validateAddressAndGoToPayment = () => {
      if ($v.value.addressForm.$invalid) {
        $v.value.addressForm.$touch();
        emit('invalid');
      } else {
        root.$eventBus.$emit('go-to-payment');
      }
    };

    onUnmounted(async () => {
      root.$eventBus.$off('validate-and-go-to-payment');
      if (isGoogleAutocompleteEnabled.value) {
        await unloadGoogleApisScript();
      }
    });

    watch(modelValueRef, () => {
      for (const key in modelValueRef.value) {
        if (transformers.value[key]) {
          addressForm[key] = transformers.value[key](modelValueRef.value[key]);
        } else {
          addressForm[key] = modelValueRef.value[key];
        }
      }
      if (props.inModal) {
        // required when form is contained in a modal
        // and model data changed from existing address
        // to an empty one and vice versa
        $v.value.addressForm.$touch();
      }
    });

    watch(basicInformation, () => {
      if (props.showEmail && basicInformation.value?.email) {
        addressForm.email =
          !addressForm.email || addressForm.email === ''
            ? basicInformation.value.email
            : addressForm.email;
      }
    });

    return {
      country,
      phoneMask,
      addressForm,
      getHeadingText,
      countries,
      provinces,
      provinceLabel,
      formData,
      validationData,
      isProvinceTextField,
      buttonBackToAddressBookClass,
      buttonBackToAddressBookShowIcon,
      buttonBackToAddressBookIconName,
      buttonSubmitClass,
      buttonCancelClass,
      markFieldAsRequired,
      postalCodeMask,
      scrollFormToError,
      isSaveButtonDisabled,
      countryChange,
      provinceChange,
      setValidation,
      blurAndValidate,
      isFieldValid,
      getErrorMessage,
      submitAddressForm,
      isHintEnabled,
      getPhoneFormat,
      showPhoneInputCountry,
      phoneInputCountries,
      phoneInputCountryChange,
      phoneInputErrorMessage,
      validateAddressAndGoToPayment,
      isCoreRedesignEnabled,
    };
  },
  mounted() {
    this.setValidation(this.$v);
  },
  render(): VNode {
    const backButton = h(
      VfButton,
      {
        class: this.buttonBackToAddressBookClass,
        props: {
          icon: this.buttonBackToAddressBookShowIcon
            ? this.buttonBackToAddressBookIconName
            : undefined,
        },
        on: {
          click: () => this.$emit('click-back'),
        },
      },
      this.translations.backButton
    );

    const header = h(VfHeading, {
      class: 'form__heading',
      props: {
        title: this.getHeadingText,
        level: 2,
        'title-modifier': 'title-4',
      },
      style: {
        titleTextAlign: this.headingAlignment,
      },
    });

    const actionButtons = h(
      'div',
      {
        class: 'form__action-buttons',
      },
      [
        h(
          VfButton,
          {
            class: this.buttonSubmitClass,
            props: {
              disabled: this.isSaveButtonDisabled,
            },
            on: {
              click: this.submitAddressForm,
            },
          },
          this.translations.saveButton
        ),
        h(
          VfButton,
          {
            class: this.buttonCancelClass,
            on: {
              click: () => this.$emit('cancel'),
            },
          },
          this.translations.cancelButton
        ),
      ]
    );

    const country = this.showCountry
      ? h(
          VfSelect,
          {
            class: 'form__input form__select',
            props: {
              value: this.addressForm.country || this.addressForm.countryCode,
              valid: this.isFieldValid('country'),
              label: this.translations.countryLabel,
              name: this.translations.countryLabel,
              'error-message': this.getErrorMessage(
                'country',
                this.translations.countryLabel
              ),
              required: this.markFieldAsRequired,
            },
            on: {
              selected: (value) => this.countryChange(value),
              blur: () => this.blurAndValidate('country'),
            },
          },
          this.countries.map((c) =>
            h(
              VfSelectOption,
              {
                key: `country-${c.code}`,
                attrs: {
                  value: c.code,
                  selected:
                    c.code === this.addressForm.country ||
                    c.code === this.addressForm.countryCode,
                },
              },
              c.name
            )
          )
        )
      : null;

    const createInput = (
      name: string,
      label: string,
      props: any = {},
      attrs: any = {},
      listeners: any = {}
    ) => {
      return {
        props: {
          value: this.addressForm[name],
          label,
          name: name,
          required: this.markFieldAsRequired,
          valid: this.isFieldValid(name),
          'error-message': this.getErrorMessage(name, label),
          ...props,
        },
        attrs,
        on: {
          blur: () => this.blurAndValidate(name),
          input: (event) => {
            this.addressForm[name] = event ? event.trim() : '';
          },
          ...listeners,
        },
      };
    };

    const createFormHelperText = (text: string) =>
      h('div', { class: 'form__helper' }, text);

    const firstName = h(VfInput, {
      class: 'form__input',
      ...createInput('firstName', this.translations.firstNameLabel, null, {
        'data-protected': '',
      }),
    });

    const lastName = h(VfInput, {
      class: 'form__input',
      ...createInput('lastName', this.translations.lastNameLabel, null, {
        'data-protected': '',
      }),
    });

    const address1 = h(VfInput, {
      class: 'form__input',
      ...createInput('addressLine1', this.translations.streetLabel, null, {
        'data-protected': '',
      }),
    });

    const address2 = h(VfInput, {
      class: 'form__input',
      ...createInput(
        'addressLine2',
        this.translations.apartmentLabel,
        {
          required: false,
        },
        { 'data-protected': '' }
      ),
    });

    const postalCode = h(VfInput, {
      class: 'form__input form__input-postal',
      ...createInput('postalCode', this.translations.postalCodeLabel),
      directives: [
        {
          name: 'mask',
          value: Array.isArray(this.postalCodeMask)
            ? [...this.postalCodeMask]
            : this.postalCodeMask,
        },
      ],
    });

    const city = h(VfInput, {
      class: 'form__input',
      ...createInput('city', this.translations.cityLabel),
    });

    const provinceSelect = h(
      VfSelect,
      {
        class: 'form__input form__select',
        props: {
          value: this.addressForm.province,
          label: this.provinceLabel,
          name: this.provinceLabel,
          valid: this.isFieldValid('province'),
          'error-message': this.getErrorMessage('province', this.provinceLabel),
          required: this.markFieldAsRequired,
        },
        on: {
          selected: this.provinceChange,
          blur: () => this.blurAndValidate('province'),
        },
      },
      [
        h(VfSelectOption, {
          attrs: {
            disabled: true,
            selected: !this.addressForm.province,
          },
          props: {
            value: null,
            placeholder: this.provinceLabel,
          },
        }),
        ...this.provinces.map((p) =>
          h(
            VfSelectOption,
            {
              key: `province-${p.code}`,
              attrs: {
                value: p.code,
                selected: p.code === this.addressForm.province,
              },
            },
            p.name
          )
        ),
      ]
    );

    const provinceTextField = h(VfInput, {
      class: 'form__input',
      ...createInput('province', this.translations.provinceLabel),
    });

    const province = this.isProvinceTextField
      ? provinceTextField
      : provinceSelect;

    const email = this.showEmail
      ? h(
          'div',
          {
            class: 'form__input form__input-email',
          },
          [
            h(VfInput, {
              ...createInput(
                'email',
                this.translations.emailLabel,
                {
                  type: isSafari() ? 'text' : 'email',
                  // In the Safari browser input with type="email" is not focused at first time when the customer navigates the page by keyboard.
                  // This looks like some Safari issue, so type of this field is set to "text" as a workaround of this issue.
                },
                { 'data-protected': '' }
              ),
            }),
            this.showEmailHelperText
              ? createFormHelperText(this.translations.emailHelperText)
              : null,
          ]
        )
      : null;

    const phone = h(
      'div',
      {
        class: 'form__input form__input-phone',
      },
      [
        h(VfPhoneInput, {
          ...createInput(
            'phone',
            this.translations.phoneLabel,
            {
              type: 'tel',
              'error-message': this.phoneInputErrorMessage,
              mask: this.phoneMask,
              'country-code-label': this.translations.countryCodeLabel,
              'show-country-selector': this.showPhoneInputCountry,
              countries: this.phoneInputCountries,
              defaultCountryCode: this.country,
            },
            { 'data-protected': '' },
            {
              'country-changed': this.phoneInputCountryChange,
            }
          ),
        }),
        this.showPhoneHelperText
          ? createFormHelperText(this.translations.phoneHelperText)
          : null,
      ]
    );

    /**
     * Renders form fields based on configured order
     */
    const formRenderer = () => {
      const formFields = {
        country,
        firstName,
        lastName,
        address1,
        address2,
        postalCode,
        city,
        province,
        email,
        phone,
      };
      const orderedFields =
        this.$themeConfig?.AddressForm?.fieldsOrder || DEFAULT_FIELDS_ORDER;

      return orderedFields.map((field) => formFields[field]);
    };

    return h(
      'div',
      {
        class: ['form', this.isCoreRedesignEnabled && 'form--redesign-core'],
      },
      [
        this.showBackButton ? backButton : null,
        this.showHeading ? header : null,
        h('div', { class: 'form__body' }, [
          h(
            'div',
            { class: 'form__required_fields_text' },
            this.translations.requiredFieldsText
          ),
          ...formRenderer(),
          this.showActionButtons ? actionButtons : null,
        ]),
      ]
    );
  },
  validations() {
    return {
      addressForm: this.validationData,
    };
  },
});

const normalizeCountrySpecificFields = (
  countrySpecificFields,
  fallbackFields
) => {
  // normalize default mask and validation for phone where missing
  const countrySpecificFieldsPhone = getFieldById(
    countrySpecificFields,
    'phone'
  );
  if (!countrySpecificFieldsPhone) return countrySpecificFields;
  if (
    countrySpecificFieldsPhone.mask === undefined ||
    countrySpecificFieldsPhone.validation === undefined
  ) {
    const fallbackFieldsPhone = getFieldById(fallbackFields, 'phone');

    countrySpecificFieldsPhone.mask = fallbackFieldsPhone.mask;
    countrySpecificFieldsPhone.validation = fallbackFieldsPhone.validation;
  }

  return countrySpecificFields;
};
