






import { computed, defineComponent } from '@vue/composition-api';
import { useProduct } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'CustomAttributeRenderer',
  props: {
    /** Attribute name */
    attributeName: {
      type: String,
      default: '',
    },
    contextKey: {
      type: String,
      default: 'product',
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { product } = useProduct(root, props.contextKey);
    const attributeValue = computed(() => {
      return product.value?.[props.attributeName] || null;
    });
    return {
      product,
      attributeValue,
    };
  },
});
