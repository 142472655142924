var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vf-simplified-enrollment"},[(_vm.step === _vm.Steps.EmailIntake)?_c('SimplifiedEnrollmentEmailIntake',{attrs:{"data-testid":"simplified-enrollment-email-intake","disabled":_vm.disabled},on:{"submit-email":_vm.submitEmail}}):_vm._e(),_vm._v(" "),(_vm.step === _vm.Steps.PasswordIntake)?_c('SimplifiedEnrollmentPasswordIntake',{attrs:{"data-testid":"simplified-enrollment-password-intake","initial-mail":_vm.initialMail,"is-partially-enrolled":_vm.$route.query.email},on:{"submit-password":_vm.submitPassword}}):_vm._e(),_vm._v(" "),(_vm.step === _vm.Steps.SignUpNonLoyaltyMember)?_c('SimplifiedEnrollmentNotLoyaltyMember',{attrs:{"initial-mail":_vm.initialMail},on:{"complete-profile-click":function () { return (_vm.step = _vm.Steps.WelcomeScreen); },"skip-click":_vm.closeModal}}):_vm._e(),_vm._v(" "),(_vm.step === _vm.Steps.WelcomeScreen)?_c('SimplifiedEnrollmentConfirmation',{attrs:{"title":_vm.$t('simplifiedEnrollment.welcomeScreen.title'),"subtitle":_vm.$t('simplifiedEnrollment.welcomeScreen.subtitle')}},[_c('ThemeButton',{attrs:{"data-testid":"simplified-enrollment-continue","size":"full","color":"primary"},on:{"click":_vm.goToProgressiveProfile}},[_vm._v("\n      "+_vm._s(_vm.$t('simplifiedEnrollment.welcomeScreen.continueButton'))+"\n    ")]),_vm._v(" "),_c('ThemeButton',{attrs:{"data-testid":"simplified-enrollment-skip","size":"full","color":"tertiary"},on:{"click":_vm.showSetupAccountToast}},[_vm._v("\n      "+_vm._s(_vm.$t('simplifiedEnrollment.welcomeScreen.skipButton'))+"\n    ")])],1):_vm._e(),_vm._v(" "),(_vm.step === _vm.Steps.Login)?[_c('SimplifiedEnrollmentHeader',{attrs:{"title":_vm.$t('simplifiedEnrollment.login.title'),"subtitle":_vm.$t('simplifiedEnrollment.login.subtitle')}}),_vm._v(" "),_c('LoyaltyLogin',{staticClass:"vf-simplified-enrollment__login",attrs:{"show-password-toggler":"","translations":{
        emailLabel: _vm.$t('simplifiedEnrollment.login.emailLabel'),
        passwordLabel: _vm.$t('simplifiedEnrollment.login.passwordLabel'),
        validationMessages: {
          email: _vm.$t('simplifiedEnrollment.login.emailErrorMessage'),
          required: _vm.$t('simplifiedEnrollment.login.requiredErrorMessage'),
        },
      },"context-key":_vm.contextKey,"show-enroll-now":false,"show-heading":false,"show-subheading":false,"initial-mail":_vm.initialMail},on:{"login":_vm.onLogin}})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }