




































import type { PropType } from 'vue';
import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import useRootInstance from '@/shared/useRootInstance';
import type { DiscountNotificationTranslations } from '@vf/api-contract';
import {
  ROUTES,
  useAccount,
  useAuthentication,
  useI18n,
  useRouting,
  useDiscount,
} from '@vf/composables';
import { PageTypeName } from '@vf/composables/src/useCms/types';
import { useCmsRefStore } from '@vf/composables/src/store/cmsRef';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@vf/composables/src/store/user';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

export default defineComponent({
  name: 'DiscountNotification',
  props: {
    translations: {
      type: Object as PropType<DiscountNotificationTranslations>,
      required: true,
    },
    notificationPersistent: {
      type: Boolean,
    },
    contextKey: {
      type: String as PropType<string>,
      default: 'page-content',
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const userStore = useUserStore(root);
    const { isEmployeeDiscountTCAccepted, loggedIn } = storeToRefs(userStore);
    const cmsRefStore = useCmsRefStore(root.$pinia);
    const { previousRoute } = useRouting(root);
    const { localePath } = useI18n(root);
    const {
      pageTypesWithDisplayedNotification,
      displayEmployeeDiscountNotification,
    } = useAccount(root);

    // TODO: isCheckoutRedesignEnabled - GLOBAL15-56318 clean up and try to use only one component of static/DiscountNotification.vue
    // TODO: isCoreRedesignEnabled - GLOBAL15-61059 remove after core redesign
    const {
      isCheckoutRedesignEnabled,
      isCoreRedesignEnabled,
    } = useFeatureFlagsStore();
    const isRedesignEnabled =
      isCheckoutRedesignEnabled || isCoreRedesignEnabled;

    const { consumerType } = useAuthentication(root);

    const {
      activeDiscountName,
      discount,
      consumerReachedSeasonLimit,
      isLimitSpent,
      isVisible,
      maybeTemporary,
    } = useDiscount(root);

    const showNotification = ref(false);

    const chooseDisplayNotification = () => {
      if (!loggedIn.value && !isVisible.value) {
        return;
      }

      const notificationWasDisplayed = pageTypesWithDisplayedNotification.value.find(
        (pageType: string) => {
          return pageType === cmsRefStore.pageTypeName;
        }
      );

      /** Handle situation when user is redirected from shipping to cart page after login */
      if (
        previousRoute.value?.path === localePath(ROUTES.CHECKOUT_SHIPPING()) &&
        cmsRefStore.pageTypeName === PageTypeName.CHECKOUT
      ) {
        return;
      }

      /** Handle situation when user visit favourites page after visiting any of my account pages */
      if (
        pageTypesWithDisplayedNotification.value.includes(
          PageTypeName.MY_ACCOUNT
        ) &&
        cmsRefStore.pageTypeName === PageTypeName.FAVORITES
      ) {
        return;
      }

      displayNotification(notificationWasDisplayed);
    };

    // notificationWasDisplayed - tell us if notification was displayed in certain page
    // if page was visited we store info in session storage
    const displayNotification = (notificationWasDisplayed) => {
      // Info if discount can be visible temporarily is stored in maybeTemporary
      // temporarily means once per page in a browser session
      // info stored in session storage
      const wasDisplayedTemporarily =
        maybeTemporary.value && !!notificationWasDisplayed;

      // if notification wasn't displayed on this page yet or notification
      // should be persistent on this page (info from CMS)
      // and also if customer has data needed to display notification we present it
      if (
        (!wasDisplayedTemporarily || props.notificationPersistent) &&
        isVisible.value
      ) {
        showNotification.value = true;

        // Before saving page to sessionStorage check if message is available and the notification is already displayed
        if (message.value) {
          displayEmployeeDiscountNotification([
            ...pageTypesWithDisplayedNotification.value,
            cmsRefStore.pageTypeName,
          ]);
        }
      } else {
        showNotification.value = false;
      }
    };

    const translations = computed(
      () => props.translations[activeDiscountName.value] ?? {}
    );

    const brand = computed(() => cmsRefStore.cmsSiteConfiguration?.name);

    const message = computed(() =>
      isLimitSpent.value
        ? translations.value.exceededMessage
        : translations.value.message
            ?.replace(
              '{{amount}}',
              root.$formatPrice(discount.value?.currentSpend)
            )
            ?.replace('{{limit}}', root.$formatPrice(discount.value?.annualCap))
            ?.replace('{{brand}}', brand.value)
    );

    const seasonalMessage = computed(() =>
      consumerReachedSeasonLimit.value
        ? translations.value.seasonalExeededMessage
        : ''
    );

    const { pageTypeName } = storeToRefs(cmsRefStore);
    const routePath = computed(() => root.$route.path);

    watch(
      [
        routePath,
        pageTypeName,
        consumerType,
        isEmployeeDiscountTCAccepted,
        activeDiscountName,
      ],
      chooseDisplayNotification,
      { immediate: true }
    );

    return {
      activeDiscountName,
      message,
      isCoreRedesignEnabled,
      isRedesignEnabled,
      seasonalMessage,
      showNotification,
      displayNotification,
    };
  },
});
