












































































import type { PropType } from 'vue';
import { defineComponent, ref } from '@vue/composition-api';
import type { SocialNativeUploadMediaTranslations } from '@vf/api-contract';
import useRootInstance from '@/shared/useRootInstance';
import { useSocialNative } from '@vf/composables';
import VfUploadFile from '@vf/ui/components/Organism.UploadFile.vue';
import VfImageAddCaption from '@vf/ui/components/Molecule.ImageAddCaption.vue';

enum Page {
  login = 0,
  dropFile = 1,
  addCaption = 2,
  thankyou = 3,
}

export default defineComponent({
  name: 'VfSocialNativeUploadMedia',
  components: {
    VfImageAddCaption,
    VfUploadFile,
  },
  props: {
    /** API key  */
    apiKey: {
      type: String,
      required: true,
    },
    translations: {
      type: Object as PropType<SocialNativeUploadMediaTranslations>,
      required: true,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { createUser, postMedia } = useSocialNative(root);
    const createUserError = ref(false);
    const pageIndex = ref(Page.login);
    const userId = ref(null);
    const photoSelected = ref<null | File>(null);
    const photoSrc = ref('');
    const captionLoading = ref(false);
    const captionError = ref('');
    const imageReset = ref(false);

    const gotoDropFile = () => {
      photoSelected.value = null;
      pageIndex.value = Page.dropFile;
    };

    const gotoCaption = () => {
      captionError.value = '';
      pageIndex.value = Page.addCaption;
    };

    const onSignIn = async (data) => {
      try {
        createUserError.value = false;
        const resp = await createUser(props.apiKey, data);
        userId.value = resp;
        gotoDropFile();
      } catch (err) {
        // Failed to create user
        createUserError.value = true;
        root.$log.error('Social Native create user failed!');
      }
    };

    const onPhotoSelected = (file: File) => {
      photoSelected.value = file;
      // create imageURL
      photoSrc.value = URL.createObjectURL(photoSelected.value);
      imageReset.value = false;
      gotoCaption();
    };

    const onCaptionReset = () => {
      imageReset.value = true;
      pageIndex.value = Page.dropFile;
      photoSelected.value = null;
    };
    const onCaptionSubmit = (caption: string) => {
      captionLoading.value = true;
      captionError.value = '';
      return postMedia(props.apiKey, userId.value, {
        file: photoSelected.value,
        caption,
      })
        .then(() => {
          pageIndex.value = Page.thankyou;
        })
        .catch((err) => {
          captionError.value = err.response?.data?.data.message || err.message;
          root.$log.error(
            `[@theme/components/smart/shared/SocialNativeUploadMedia.vue] API Call postMedia failed. Error: ${err.message}`
          );
        })
        .finally(() => {
          captionLoading.value = false;
        });
    };

    //  after image loaded on browser,release memory used for create image object
    const onImageLoaded = () => URL.revokeObjectURL(photoSrc.value);

    return {
      captionError,
      captionLoading,
      createUserError,
      onCaptionReset,
      onCaptionSubmit,
      onImageLoaded,
      onPhotoSelected,
      photoSrc,
      onSignIn,
      pageIndex,
      Page,
      photoSelected,
      imageReset,
    };
  },
});
