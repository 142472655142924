import { render, staticRenderFns } from "./BuyInStoreInfo.vue?vue&type=template&id=53ba654e&scoped=true&"
import script from "./BuyInStoreInfo.vue?vue&type=script&lang=ts&"
export * from "./BuyInStoreInfo.vue?vue&type=script&lang=ts&"
import style0 from "./BuyInStoreInfo.vue?vue&type=style&index=0&id=53ba654e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53ba654e",
  null
  
)

export default component.exports