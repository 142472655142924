












































































import { defineComponent, ref, computed } from '@nuxtjs/composition-api';
import { useCouponCode } from '@vf/composables/src/useCouponCode';
import useRootInstance from '@/shared/useRootInstance';
import useLoader from '@/shared/useLoader';
import { replaceAll } from '@/helpers/replaceAll';
import { pluralize } from '@/helpers/pluralize';

export default defineComponent({
  name: 'PromoCodeSidebar',
  setup() {
    const { root } = useRootInstance();
    const { appliedPromoCodes, addCoupon, removeCoupon } = useCouponCode(root);
    const { showSpinner, hideSpinner } = useLoader();

    const isApiCallInProgress = ref(false);
    const promoCode = ref('');
    const errorMessage = ref(null);

    const filteredPromotions = computed(() => {
      const applied = [];
      const others = [];
      appliedPromoCodes.value.forEach((promoCode) =>
        (promoCode.statusCode === 'applied' ? applied : others).push(promoCode)
      );
      return {
        applied,
        others,
      };
    });

    const updateErrorMessage = (errorId?: string, replaceObj = {}) => {
      errorMessage.value = errorId
        ? replaceAll(root.$t(`muleSoftErrors.${errorId}`) as string, replaceObj)
        : null;
    };
    const addCouponCode = async (code: string) => {
      showSpinner();
      isApiCallInProgress.value = true;
      const response = await addCoupon(code);
      if (response?.status === 200) {
        promoCode.value = '';
        updateErrorMessage();
      } else {
        const { errorId = 'GEN500', message = {} } =
          response.data.errorDetails?.[0] ?? {};
        updateErrorMessage(
          errorId,
          message.distanceFromConditionThreshold && message.discount
            ? {
                remainingAmount: root.$formatPrice(
                  message.distanceFromConditionThreshold
                ),
                discountAmount: message.discount,
              }
            : undefined
        );
      }
      isApiCallInProgress.value = false;
      hideSpinner();
    };

    const removeCouponCode = async (couponCodeId) => {
      showSpinner();
      isApiCallInProgress.value = true;
      const response = await removeCoupon(couponCodeId);
      updateErrorMessage(
        response?.status !== 200
          ? response.data.errorDetails?.[0]?.errorId || 'GEN500'
          : null
      );
      isApiCallInProgress.value = false;
      hideSpinner();
    };

    return {
      filteredPromotions,
      isApiCallInProgress,
      errorMessage,
      updateErrorMessage,
      addCouponCode,
      removeCouponCode,
      promoCode,
      pluralize,
    };
  },
});
