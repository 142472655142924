




import { defineComponent, PropType } from '@vue/composition-api';
import { ShoeSizeSelectorTranslations } from '@vf/api-contract';

export default defineComponent({
  name: 'ShoeSizeSelector',
  props: {
    translations: {
      type: Object as PropType<ShoeSizeSelectorTranslations>,
      required: true,
    },
  },
});
