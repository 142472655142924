import { Ref } from '@vue/composition-api';
import { Validator as JSONSchemaValidator } from 'jsonschema';
import { ComponentInstance } from '@vf/composables';
import { openCustomsExperience } from './index';
import { OpenCustomsExperienceConfig } from './openCustomsExperience';

const dataObjectSchema = {
  type: 'object',
  properties: {
    models: {
      type: 'array',
      items: { type: 'string' },
    },
    ugcImage: { type: 'string' },
    secondaryImage: { type: 'string' },
    headerDesc: { type: 'string' },
    enableEmailGateway: { type: 'boolean' },
    svgrenderer: { type: 'boolean' },
    enableCropPlacement: { type: 'boolean' },
    optInVisible: { type: 'boolean' },
    optInChecked: { type: 'boolean' },
    enableNewUgcTermConditions: { type: 'boolean' },
    forceUGCEntryPointTC: { type: 'boolean' },
  },
};

const handleCustomHtmlTrigger = (
  root: ComponentInstance,
  openCustomsExperienceConfig: Ref<OpenCustomsExperienceConfig>,
  e: CustomEvent
): void => {
  const dataObject = e.detail;

  if (typeof dataObject === 'object') {
    const validator = new JSONSchemaValidator();
    const result = validator.validate(dataObject, dataObjectSchema);

    if (result.valid) {
      openCustomsExperience(root, {
        ...openCustomsExperienceConfig.value,
        dataObject: JSON.stringify(dataObject),
      });
    } else if (root.$env.NODE_ENV !== 'production') {
      console.error(
        'Invalid data object provided in the openDynamicUGC event: ',
        result.errors
      );
    }
  }
};

export default handleCustomHtmlTrigger;
