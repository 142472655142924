
















































import type { PropType } from 'vue';
import type { CartLineItem } from '@vf/api-client';

import { computed, defineComponent } from '@vue/composition-api';
import { AthletePromoType } from '@vf/api-contract';
import { useUserStore } from '@vf/composables/src/store/user';
import { getGiftOptionItem } from '@vf/shared/src/utils/helpers';
import { getItemGiftBoxPrice } from '@vf/shared/src/utils/helpers/giftOption';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'GiftOptionSummary',
  props: {
    product: Object as PropType<CartLineItem>,
    showActions: Boolean,
  },
  emits: ['click:open-modal', 'click:remove-gift-option'],
  setup(props, { emit }) {
    const { root } = useRootInstance();
    const userStore = useUserStore(root);

    const keys = computed(() =>
      [
        {
          label: 'to',
          value: props.product.giftOption.to,
        },
        {
          label: 'from',
          value: props.product.giftOption.from,
        },
        {
          label: 'message',
          value: props.product.giftOption.message,
        },
      ].filter(({ value }) => value)
    );

    const removeGiftOption = () =>
      emit('click:remove-gift-option', getGiftOptionItem(props.product, false));

    const isShippedFromStore = computed(() =>
      // Check if product has SFS attribute after shipping presourcing
      props.product.shippingNodes?.some((node) => node.shipNodeType === 'Store')
    );

    const isExcludedFromAthleteProgram = computed(
      () =>
        userStore.athlete &&
        !props.product.productPromotions?.some(
          (promo) => promo.promotionId === AthletePromoType.PROMO_ID
        )
    );

    const isGiftOptionAvailable = computed(() => {
      const allowedForAthlete =
        !userStore.athlete || isExcludedFromAthleteProgram.value;

      return (
        props.product.isGiftAllowed &&
        allowedForAthlete &&
        !isShippedFromStore.value
      );
    });

    const giftBoxPrice = computed(() => getItemGiftBoxPrice(props.product));

    return {
      isGiftOptionAvailable,
      giftBoxPrice,
      keys,
      removeGiftOption,
    };
  },
});
