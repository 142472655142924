





















































































































import { computed, defineComponent } from '@vue/composition-api';
import {
  CTAExperience,
  CustomsCTAExperiences,
  CustomsCTAExperienceTypes,
} from '@vf/api-contract';
import { useGtm } from '@vf/composables';
import { scrollToElement } from '@vf/shared/src/utils/helpers';
import CustomsCta from '../customs/CustomsCta.vue';
import CustomContent from '../customContent/CustomContent.vue';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'CustomShoesHpStatic',
  components: {
    CustomsCta,
    CustomContent,
  },
  props: {
    customContentInfo: {
      type: Array,
      default: () => [
        {
          customContentFile: {
            type: String,
            default: '',
          },
          customContentPath: {
            type: String,
            default: '',
          },
          modals: {
            type: Object,
            default: () => ({}),
          },
        },
      ],
    },
    customCTAS: {
      type: Array,
      default: () => [
        {
          /** Object containing fields to pass to Iframe. Type string for authoring JSON purposes */
          dataObject: {
            type: String,
            default: '',
          },
          /** Type of UMD experience that will be opened  */
          experience: {
            type: String,
            default: '',
            validator: (value: CTAExperience) =>
              CustomsCTAExperiences.includes(value),
          },
          // /** Type of Customs integration that will be used  */
          experienceType: {
            type: String,
            default: CustomsCTAExperienceTypes.IFrame,
            validator: (value: string) =>
              Object.values(CustomsCTAExperienceTypes).includes(value),
          },
          /** Customize button text */
          icon: {
            type: String,
            default: '',
          },
          style: {
            type: String,
            default: 'secondary',
          },
          text: {
            type: String,
            default: 'Customize',
          },
          // /** Flag to determine if customze button should be underline */
          underline: {
            type: Boolean,
            default: false,
          },
        },
      ],
    },
    buttonsCTA: {
      type: Array,
      default: () => [
        {
          /** Link for the button to be scrolled to the content with passed component id */
          linkToScroll: {
            type: String,
            default: '',
          },
          text: {
            type: String,
            default: 'Get Inspired',
          },
          icon: {
            type: String,
            default: '',
          },
          /** Id of the button which should be action point for the Sticky Header */
          scrollToButtonId: {
            type: String,
            default: '',
          },
        },
      ],
    },
    /** Object containing fields to pass to Iframe. Type string for authoring JSON purposes */
    secondDataObject: {
      type: String,
      default: '',
    },
    /** Type of UMD experience that will be opened  */
    secondExperience: {
      type: String,
      default: '',
      validator: (value: CTAExperience) =>
        CustomsCTAExperiences.includes(value),
    },
    /** Type of Customs integration that will be used  */
    secondExperienceType: {
      type: String,
      default: CustomsCTAExperienceTypes.IFrame,
      validator: (value: string) =>
        Object.values(CustomsCTAExperienceTypes).includes(value),
    },
    /** Media object rendering video for small, background images for medium and large */
    media: {
      type: Object,
      default: () => ({
        small: '',
        medium: '',
        large: '',
      }),
    },
    /** Background video for small breakpoint **/
    smallBackgroundVideo: {
      type: Object,
      default: () => ({}),
    },
    /** Logo object containg src and alt */
    logo: {
      type: Object,
      default: () => ({
        src: '',
        alt: '',
      }),
    },
    /** Rich Text to be displayed under the logo */
    richText: {
      type: String,
      default: '',
    },
    /** Rich Text Color */
    richTextColor: {
      type: String,
      default: '',
    },
    /** Rich Text Background Color */
    richTextBackgroundColor: {
      type: String,
      default: '',
    },
    /** Text to be rendered below action buttons */
    textBelowCtaButton: {
      type: String,
      default: '',
    },
    /** Color of Text to be rendered below action buttons */
    textBelowCtaButtonsColor: {
      type: String,
      default: '',
    },
    /** Click Here text button */
    clickHereText: {
      type: String,
      default: 'Click Here',
    },
    clickHereGtmEventLabel: {
      type: String,
      default: 'Kids Shoes',
    },
    /** Bottom Inspiration Object with text and link */
    bottomInspirationLink: {
      type: Object,
      default: () => ({
        text: 'Inspiration',
        link: '',
      }),
    },
    bottomInspirationLinkGtmEventLabel: {
      type: String,
      default: 'Scroll To Inspiration',
    },
    /** Full height on small breakpoint */
    fullHeightSmall: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { dispatchEvent, dispatchVideoEvent } = useGtm(root);
    const backgroundStyle = computed(() =>
      Object.values(props.media)
        ? {
            '--_grid-background-image': `url(${props.media.medium})`,
            '--_grid-background-small-image':
              props.media.small && `url(${props.media.small})`,
            '--_grid-background-medium-image':
              props.media.medium && `url(${props.media.medium})`,
            '--_grid-background-large-image':
              props.media.large && `url(${props.media.large})`,
          }
        : {}
    );
    const buttonSize = computed(() =>
      root.$viewport?.isSmall ? 'vf-button--thin' : ''
    );
    const logEvent = (data) => {
      dispatchEvent({
        eventName: 'loadEventData',
        overrideAttributes: {
          eventAction: 'Main Page',
          eventLabel: data,
        },
      });
    };

    return {
      logEvent,
      backgroundStyle,
      buttonSize,
      dispatchVideoEvent,
      scrollToElement,
    };
  },
});
