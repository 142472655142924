

































import type { PropType } from 'vue';
import { defineComponent, onMounted, watch } from '@vue/composition-api';
import type { CartNotificationTranslations } from '@vf/api-contract';
import { useCart } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import { getProductImage } from '@vf/shared/src/utils/helpers';

export default defineComponent({
  name: 'CartNotification',
  props: {
    translations: {
      type: Object as PropType<CartNotificationTranslations>,
      required: true,
    },
  },
  setup() {
    const { root } = useRootInstance();
    const {
      cart,
      outOfStockFlashErrors,
      outOfStockList,
      checkAndRemoveOutOfStockItems,
    } = useCart(root);

    onMounted(() => {
      checkAndRemoveOutOfStockItems();
    });

    watch(
      [cart, outOfStockFlashErrors],
      () => {
        checkAndRemoveOutOfStockItems();
      },
      { immediate: true }
    );

    return {
      getProductImage,
      outOfStockList,
    };
  },
});
