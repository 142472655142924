






























































































import type { CartLineItem } from '@vf/api-client';

import {
  computed,
  defineComponent,
  provide,
  ref,
  watch,
} from '@vue/composition-api';
import { ROUTES, useCart, useI18n, useGtm } from '@vf/composables';
import useLoader from '@/shared/useLoader';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import { getEventFromTemplate } from '@vf/composables/src/useGtm/helpers';
import useRootInstance from '@/shared/useRootInstance';
import BasePanel from '@/components/panel/BasePanel.vue';
import { useRecentlyAddedProduct } from '@vf/composables/src/useCart/composables/useRecentlyAddedProduct';

export default defineComponent({
  name: 'MiniCartRedesign',
  components: { BasePanel },
  setup() {
    const { root } = useRootInstance();
    const { showSpinner, hideSpinner, isSpinnerVisible } = useLoader();
    const { dispatchEvent } = useGtm(root);
    const { cart, isMinicartOpen, setMiniCart, deleteItem } = useCart(root);
    const { recentlyAddedProduct } = useRecentlyAddedProduct(root);
    const { isCheckoutSuccessRedesignEnabled } = useFeatureFlagsStore();

    const cartPagePath = useI18n(root).localePath(ROUTES.CART());

    const panel = ref(null);
    const cachedProducts = ref([]);

    const removeProduct = async (product: CartLineItem) => {
      showSpinner();
      await cacheAndDeleteItem(product);
      hideSpinner();
      dispatchEvent({
        ...getEventFromTemplate('cart:remove', {}),
        composablesContexts: { useProduct: 'quickShop' },
        overrideAttributes: {
          product: product,
          quantity: product.qty,
        },
      });
      dispatchEvent(getEventFromTemplate('cart:update', {}));
    };

    const cacheAndDeleteItem = async ({ id, productId }) => {
      const items = cachedProducts.value.filter(
        (item) => !item.removeNotification
      );
      const index = items.findIndex((item) => item.productId === productId);
      await deleteItem(id);
      items.splice(index, 0, { removeNotification: true });

      cachedProducts.value = items.reduce((accItems, item) => {
        // Keeps notification that items was removed from minicart
        if (item.removeNotification) accItems.push(item);
        else {
          // If products still exists in cart, replace/sync item data with backend
          const cartItem = cart.value.items?.find(
            ({ productId }) => productId === item.productId
          );
          if (cartItem) accItems.push(cartItem);
        }
        return accItems;
      }, []);
    };

    const onCloseMinicart = () => {
      setMiniCart(false);
      root.$emit('click:close-minicart');
    };

    const totalAmount = computed(() => {
      return root.$formatPrice(
        cart.value.totals?.totalWithoutTax,
        cart.value.currency
      );
    });

    const hasPromo = computed(() => !!cart.value.totals?.totalDiscount);

    const savings = computed(() => {
      const amount = cart.value.totals?.totalSavings;
      if (amount) return root.$formatPrice(amount, cart.value.currency);
      else return null;
    });

    const approachingShippingDiscount = computed<string>(() => {
      // if the promo is already in summary, it's applied
      if (cart.value.promoSummary?.find(({ level }) => level === 'SHIPPING')) {
        return root.$t('miniCartNew.shippingPromo.reached');
      }

      const { calloutMsg, distanceFromConditionThreshold } =
        cart.value.approachingDiscount?.find(
          ({ promotionClass }) => promotionClass === 'SHIPPING'
        ) || {};

      if (distanceFromConditionThreshold) {
        const value = root.$formatPrice(
          distanceFromConditionThreshold,
          cart.value.currency
        );

        return (root.$t(
          'miniCartNew.shippingPromo.aboveThreshold'
        ) as string).replace('{{value}}', value);
      }

      // fallback to original SFCC callout message when below the lower threshold
      return calloutMsg;
    });

    watch(isMinicartOpen, (isOpen) => {
      if (isOpen) {
        panel.value.openPanel();
        cachedProducts.value = cart.value.items
          .toReversed()
          .sort((_, { bonus }) => (bonus ? -1 : 1));
      } else {
        panel.value.closePanel();
      }
    });

    provide(
      'isCheckoutSuccessRedesignEnabled',
      isCheckoutSuccessRedesignEnabled
    );

    return {
      approachingShippingDiscount,
      isSpinnerVisible,
      panel,
      cartPagePath,
      cachedProducts,
      recentlyAddedProduct,
      removeProduct,
      isMinicartOpen,
      setMiniCart,
      onCloseMinicart,
      totalAmount,
      savings,
      hasPromo,
    };
  },
});
