








































































































































































































import type { PropType } from 'vue';
import {
  defineComponent,
  computed,
  ComputedRef,
  onMounted,
  onUnmounted,
  ref,
} from '@vue/composition-api';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import {
  CTAExperience,
  CustomsCTAExperiences,
  CustomsCTAExperienceTypes,
  CustomContentInfo,
  StickyHeaderContentPosition,
  MegaMenuNavigationItem,
} from '@vf/api-contract';
import CustomsCta from '../customs/CustomsCta.vue';
import CustomContent from '../customContent/CustomContent.vue';
import { AnalyticsDataDomLocation } from '../../../types';
import useRootInstance from '@/shared/useRootInstance';
import useAsyncScrollTo from '@vf/ui/composables/useAsyncScrollTo';

type TranslationsProp = {
  firstButtonText: string;
  secondButtonText: string;
  altText: string;
};

type CtaButtonsPropItem = {
  buttonStyle: string;
  buttonSize: string;
  iconPosition: string;
  disabled: boolean;
  underline: string;
  link: string;
  icon: string;
  buttonText: string;
};

type LogoProp = {
  small: string;
  medium: string;
  large: string;
  link: string;
};

export default defineComponent({
  name: 'StickyHeaderComponent',
  components: {
    CustomContent,
    CustomsCta,
  },
  props: {
    translations: {
      type: Object as PropType<TranslationsProp>,
      default: (): TranslationsProp => ({
        firstButtonText: 'Find a Store',
        secondButtonText: '',
        altText: '',
      }),
    },
    /** Page logo to render on the left hand side. Will not show up if not provided. */
    logo: {
      type: [String, Object] as PropType<string | LogoProp>,
      default: '',
    },
    logoHeight: {
      type: [Number, String],
      default: null,
    },
    logoWidth: {
      type: [Number, String],
      default: null,
    },
    /** Title text */
    title: {
      type: String as PropType<string>,
      default: '',
    },
    /** Object containing fields to pass to Iframe. Type string for authoring JSON purposes */
    dataObject: {
      type: String,
      default: '',
    },
    /** Type of UMD experience that will be opened  */
    experience: {
      type: String,
      default: '',
      validator: (value: CTAExperience) =>
        CustomsCTAExperiences.includes(value),
    },
    /** Type of Customs integration that will be used  */
    experienceType: {
      type: String,
      default: CustomsCTAExperienceTypes.IFrame,
      validator: (value: string) =>
        Object.values(CustomsCTAExperienceTypes).includes(value),
    },
    navigation: {
      type: Array as PropType<MegaMenuNavigationItem[]>,
      default: () => [],
    },
    /** (automatic) Cart items counter */
    counter: {
      type: [Number, String] as PropType<number | string>,
      default: '0',
    },
    ctaButtons: {
      type: Array as PropType<CtaButtonsPropItem[]>,
      default: () => [
        {
          /** First Button style */
          buttonStyle: '',
          /** First Button size */
          buttonSize: '',
          iconPosition: '',
          /** Flag to determine if button should be disabled */
          disabled: false,
          /** Flag to determine if button text should be underline */
          underline: false,
          /** Link for second button */
          link: '',
          icon: '',
          buttonText: 'Find A Store',
        },
      ],
    },
    /** Trigger CTAs fo use by the CustomContent component to inject style selector content */
    customContentInfo: {
      type: Array as PropType<CustomContentInfo[]>,
      default: () => [],
    },
    /** Element id. When element is not visible on the page, it will trigger sticky header */
    triggerStickyOnElementId: {
      type: String as PropType<string>,
      default: '',
    },
    /** Defined number in px. When number is smaller than scroll position, it will make header sticky */
    triggerStickyOnDefinedNumber: {
      type: Number as PropType<number>,
      default: null,
    },
    /** Flag to determine if logo should be visible */
    showLogo: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    /** Flag to determine if title should be visible */
    showTitle: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    /** Flag to determine if navigation should be visible */
    showNavigation: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    /** Flag to determine if custom button should be visible */
    showCustomButton: {
      type: Boolean,
      default: false,
    },
    /** Flag to determine if background should be transparent */
    transparentBackground: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    /** Flag to determine if accordion should be visible on small devices */
    showAccordionOnSmall: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    showOnSmall: {
      type: Boolean,
      default: true,
    },
    showOnMedium: {
      type: Boolean,
      default: true,
    },
    showOnLarge: {
      type: Boolean,
      default: true,
    },
    /** Separator for links if links more than one */
    separatorForLink: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    contentPosition: {
      type: Object as PropType<StickyHeaderContentPosition>,
      default: () => ({
        small: 'default',
        medium: 'default',
        large: 'default',
      }),
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { scrollTo } = useAsyncScrollTo();
    let headerPromoBar = null;
    let headerAnimated = null;

    const theme = root.$themeConfig.header;

    // TODO: GLOBAL15-61059 remove after redesign core
    const { isCoreRedesignEnabled } = useFeatureFlagsStore();

    const showSticky = computed(() => {
      switch (root.$viewport.size) {
        case 'small':
          return props.showOnSmall;
        case 'medium':
          return props.showOnMedium;
        case 'large':
          return props.showOnLarge;
      }
      return true;
    });

    const hasPromobarSticky = ref(true);

    const linkTag: ComputedRef<string> = computed(() => {
      return props.logo.link ? 'a' : 'div';
    });

    const accordionIconType = theme.accordionIconType || 'arrow';
    const accordionIconSize = theme.accordionIconSize || '24px';

    const bindUrlAttribute = (link: string) => {
      return link && { href: link };
    };

    const navigateTo = (link: string, scrollToComponent: boolean) => {
      if (!scrollToComponent) return;
      const targetSelector = link.includes('#')
        ? `#${link.split('#').pop()}`
        : link;
      scrollTo(targetSelector);
    };

    const addStaticHeaderStyles = (header, promoBar) => {
      if (header) {
        header.parentElement.setAttribute('data-vf-enable-sub-sticky', true);
      }
      if (promoBar) {
        if (theme.stickyHeader) {
          promoBar.style.position = 'fixed';
          hasPromobarSticky.value = true;
        } else {
          hasPromobarSticky.value =
            getComputedStyle(promoBar).getPropertyValue('position') === 'fixed';
        }
      }
    };

    const resetStaticHeaderStyles = (header, promoBar) => {
      if (header)
        header.parentElement.removeAttribute('data-vf-enable-sub-sticky');

      if (promoBar) promoBar.style.position = null;
    };

    const whenHeaderExists = (headerAnimated, callback: () => void) => {
      if (!headerAnimated) {
        return;
      }
      callback();
    };
    const addHeaderStyling = () => {
      headerPromoBar = document.querySelector('.vf-header__promo-bar');
      addStaticHeaderStyles(headerAnimated, headerPromoBar);
    };
    const removeHeaderStyling = () => {
      resetStaticHeaderStyles(headerAnimated, headerPromoBar);
    };

    onMounted(() => {
      if (theme.boxShadow) {
        theme.stickyHeader = true;
      }
      headerAnimated = document.querySelector('.vf-header__animated');
      whenHeaderExists(headerAnimated, addHeaderStyling);
    });

    onUnmounted(() => {
      if (theme.boxShadow) {
        theme.stickyHeader = false;
      }
      whenHeaderExists(headerAnimated, removeHeaderStyling);
    });

    return {
      isCoreRedesignEnabled,
      hasPromobarSticky,
      hasTriggerStickyOnElementId: !!props.triggerStickyOnElementId,
      AnalyticsDataDomLocation,
      linkTag,
      bindUrlAttribute,
      navigateTo,
      showSticky,
      accordionIconType,
      accordionIconSize,
      resetStaticHeaderStyles,
      whenHeaderExists,
    };
  },
});
