




import {
  defineComponent,
  nextTick,
  onUnmounted,
  ref,
  onMounted,
} from '@vue/composition-api';
import { useCmsLinks } from '@vf/composables';
import { useCmsRefStore } from '@vf/composables/src/store/cmsRef';
import { getTextWithAttrHrefFromCommonConfig } from '@vf/composables/src/useCms/mappings/utils';
import useModal from '@/shared/useModal';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'CartTopPromoNotificationsCmsLink',
  props: {
    description: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { root } = useRootInstance();

    const cmsRefStore = useCmsRefStore(root.$pinia);
    const { setCmsLinksRef, unsetCmsLinks } = useCmsLinks(root, useModal());

    const signInTextRef = ref(null);
    const signInText = ref('');

    const setupSignInLink = async () => {
      signInText.value = getTextWithAttrHrefFromCommonConfig(
        props.description,
        cmsRefStore.cmsSiteConfiguration
      );
      await nextTick();
      setCmsLinksRef(signInTextRef.value);
    };
    onMounted(() => setupSignInLink());
    onUnmounted(() => unsetCmsLinks());
    return {
      signInTextRef,
      signInText,
    };
  },
});
