





















































































































import { BrandifyStoreInfo } from '@vf/api-client';
import { CartProductsTranslations } from '@vf/api-contract';
import {
  defineComponent,
  ref,
  computed,
  inject,
  PropType,
} from '@vue/composition-api';
import { useFindInStore, useShipmentStore } from '@vf/composables';
import { required, maxLength } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import useRootInstance from '../../shared/useRootInstance';
import { getCartProductListThemeConfig } from '@/helpers';

export default defineComponent({
  name: 'ShipmentStoreModal',
  mixins: [validationMixin],
  props: {
    translations: {
      type: Object as PropType<
        CartProductsTranslations['storeAvailabilityModal']
      >,
      required: true,
    },
    pristine: {
      type: Boolean,
      default: true,
    },
    /** Flag determines if gift option is added */
    gift: {
      type: Boolean,
      default: false,
    },
  },
  emits: {} as {
    'find-store': (payload: {
      postalCode: string;
      distanceValue: string;
      uom: string;
      productVariantId: string;
    }) => void;
    'select-store': (storeId: string) => void;
  },
  setup(props, { emit }) {
    const { root } = useRootInstance();
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');
    const {
      storesWithAvailability,
      storesForPickup,
      requestPendingFlag,
      emptyResponseError,
      resetData,
    } = useFindInStore(root);
    const {
      selectedItem,
      isShipmentModalOpen,
      showGiftOptionWarning,
    } = useShipmentStore(root);
    const theme = getCartProductListThemeConfig(root.$themeConfig);

    const {
      storeDistanceOptionList,
      unitOfMeasure,
      storeDistanceUnit,
    } = inject('pickupOptions');

    const {
      storeNearFieldMaxLength,
      productAvailabilityModalCancelButtonClass,
      showStsInfoInProductAvailabilityModal,
    } = theme;

    const distanceValue = ref(storeDistanceOptionList?.[0]?.value || '');
    const postalCodeFieldDirty = ref(false);
    const postalCode = ref('');
    const selectedStoreId = ref('');

    const isPostalCodeEmpty = computed(
      () => postalCodeFieldDirty.value && !postalCode.value
    );

    const showErrorNotification = computed(
      () =>
        postalCode.value?.length &&
        !props.pristine &&
        !requestPendingFlag.value &&
        (!storesForPickup.value.length || emptyResponseError.value)
    );

    const searchByPostalCode = () => {
      if (postalCode.value.length < 3) return;
      emit('find-store', {
        postalCode: postalCode.value,
        distanceValue: distanceValue.value,
        uom: unitOfMeasure.toLowerCase(),
        productVariantId: selectedItem.value.productId,
      });
    };

    const setDistance = (value: string): void => {
      distanceValue.value = value;
      searchByPostalCode();
    };

    const closeModal = (validator) => {
      postalCode.value = '';
      postalCodeFieldDirty.value = false;
      selectedStoreId.value = '';
      validator.postalCode.$reset();

      isShipmentModalOpen.value = false;
      resetData();
    };

    const onPostalCodeInput = () => {
      postalCodeFieldDirty.value = true;

      searchByPostalCode();
    };

    const saveSelectedStore = (validator) => {
      emit('select-store', selectedStoreId.value);
      closeModal(validator);
    };

    const getLabel = (store: BrandifyStoreInfo): string => {
      let address = `${store.city}, ${store._distance} ${storeDistanceUnit}`;
      if (props.translations.distanceSuffix) {
        address += ` ${props.translations.distanceSuffix}`;
      }
      return !store.has_product && showStsInfoInProductAvailabilityModal
        ? props.translations.stsAvailableLabel.replace('{{address}}', address)
        : address;
    };

    const isProductAvailableInStore = (storeId) => {
      return !!storesWithAvailability.value.find(
        (store) => store.enterprise_store_identifier === storeId
      );
    };

    const isPostalCodeValid = (validator) => {
      return validator.postalCode.$dirty && validator.postalCode.$invalid
        ? !validator.postalCode.maxLength
          ? props.translations.validationMessages.maxLength.replace(
              '{{max}}',
              String(storeNearFieldMaxLength)
            )
          : props.translations.validationMessages.required
        : null;
    };

    return {
      isCoreRedesignEnabled,
      isShipmentModalOpen,
      storesWithAvailability,
      isProductAvailableInStore,
      selectedStoreId,
      distanceValue,
      storeDistanceOptionList,
      postalCode,
      isPostalCodeEmpty,
      isPostalCodeValid,
      postalCodeFieldDirty,
      onPostalCodeInput,
      searchByPostalCode,
      setDistance,
      saveSelectedStore,
      closeModal,
      getLabel,
      showErrorNotification,
      storeNearFieldMaxLength,
      productAvailabilityModalCancelButtonClass,
      showGiftOptionWarning,
      storesForPickup,
      requestPendingFlag,
      emptyResponseError,
    };
  },
  validations() {
    return {
      postalCode: {
        required,
        maxLength: maxLength(this.storeNearFieldMaxLength),
      },
    };
  },
});
