













































































import type { PropType } from 'vue';
import { computed, defineComponent, ref } from '@vue/composition-api';
import type { ProductFindInStoreListTranslations } from '@vf/api-contract';
import { useFindInStore } from '@vf/composables';
import ProductFindInStoreListRow from './ProductFindInStoreListRow.vue';
import useRootInstance from '@/shared/useRootInstance';
import type { Product } from '@vf/api-client';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

export default defineComponent({
  components: {
    ProductFindInStoreListRow,
  },
  props: {
    product: {
      type: Object as PropType<Product>,
      required: true,
    },
    translations: {
      type: Object as PropType<ProductFindInStoreListTranslations>,
      required: true,
    },
    isFindInStorePage: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { root } = useRootInstance();
    const {
      showOnlyWithAvailability,
      stores: allStores,
      storesWithAvailability,
      numStores,
    } = useFindInStore(root);
    // TODO: Cleanup in GLOBAL15-63799
    const { isBopisEnabled, isVansPdpRedesignEnabled } = useFeatureFlagsStore();
    const stores = computed(() => {
      if (showOnlyWithAvailability.value) {
        return isBopisEnabled ? storesWithAvailability.value : [];
      }

      return allStores.value;
    });
    const storesLimit = ref(4);
    function showMore() {
      return (storesLimit.value = this.stores.length);
    }
    return {
      stores,
      showOnlyWithAvailability,
      numStores,
      showMore,
      storesLimit,
      isVansPdpRedesignEnabled,
    };
  },
});
