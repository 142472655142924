








































import { defineComponent } from '@vue/composition-api';
import useRootInstance from '@/shared/useRootInstance';
import { useFilters } from '@vf/composables';

export default defineComponent({
  name: 'ColorsFilter',
  props: {
    filter: {
      type: Object,
      default: () => ({}),
    },
    filterOptions: {
      type: Array,
      default: () => [],
    },
    translations: {
      type: Object,
      default: () => ({}),
    },
    getFacetLink: {
      type: Function,
      default: () => '',
    },
    rel: {
      type: String,
      default: null,
    },
    displayCount: {
      type: Boolean,
      default: true,
    },
    facetConfiguration: {
      type: Object,
      default: () => ({}),
    },
    selectedFilters: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['click-select', 'click-remove'],
  setup() {
    const { root } = useRootInstance();
    const { getFilterItemValue } = useFilters(root);

    const defaultFacetType =
      root.$themeConfig.facetDisplayType?.defaultFacetDisplayType;

    return { defaultFacetType, getFilterItemValue };
  },
});
