













































import { defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
  name: 'VfUploadFile',
  props: {
    // submit button label
    ctaSubmit: {
      type: String,
      default: '$Submit$',
    },
    // message when try to upload wrong extension
    errorMsgWrongExt: {
      type: String,
      default: '$File wrong extension$',
    },
    // message when uploaded file size is major than limit
    errorMsgOutOfSize: {
      type: String,
      default: '$File out of size$',
    },
    // file extension accepted
    fileAccept: {
      type: String,
      default: '',
    },
    // file  max size available in bytes
    fileMaxSizes: {
      type: Number,
      default: 0,
    },
  },
  emits: ['submit'],
  setup() {
    const fileSelected = ref<null | File>(null);
    const errorMessage = ref('');

    const onFileChanged = (files: FileList) => {
      errorMessage.value = '';
      fileSelected.value = files.length ? files[0] : null;
    };

    const printError = (errMsg) => {
      errorMessage.value = errMsg;
    };

    return { errorMessage, fileSelected, onFileChanged, printError };
  },
});
