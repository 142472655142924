






































// TODO: clean up this smart component GLOBAL15-56318
import {
  computed,
  onBeforeMount,
  watch,
  ref,
  defineComponent,
} from '@vue/composition-api';
import { CartShippingGroup } from '@vf/api-client/src/types';
import { useCart, useGtm, useCheckout } from '@vf/composables';
import { CheckoutStepNumber } from '@vf/composables/src/types/gtm';
import ShippingGroupSection from '../../shipping/ShippingGroupSection.vue';
import { isPOBoxAddress } from '@vf/shared/src/utils/helpers';
import useRootInstance from '@/shared/useRootInstance';

const excludedShippingMethods = ['pickup', 'sts'] as const;

export default defineComponent({
  name: 'CheckoutShippingMethod',
  components: {
    ShippingGroupSection,
  },
  props: {
    translations: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    // TODO: Remove it after GLOBAL15-56318
    const { root } = useRootInstance();

    const {
      setShippingMethod,
      cartShippingMethods,
      shippingGroups,
      cart,
      isProceedBillingButtonDisabled,
      hasShippingItems,
    } = useCart(root);
    const { isPoAddress } = useCheckout(root);
    const { dispatchEvent } = useGtm(root);
    const getShippingGroups = ref([]);

    onBeforeMount(() => {
      getShippingGroups.value = shippingGroups.value.filter(
        isValidShippingMethod
      );
    });

    const getDefaultSelectedShippingMethodCode = computed(() => {
      return cartShippingMethods.value.find((element) => {
        return element.shippingId === 'me';
      });
    });

    const updateShippingMethod = async (data, event) => {
      isProceedBillingButtonDisabled.value = true;
      await setShippingMethod({
        code: event,
        shippingId: data,
        shopperAppliedSM: true,
      });
      isProceedBillingButtonDisabled.value = false;
      // TODO: move to useGtm->dispatchPageEvent - GLOBAL15-1943
      dispatchEvent({
        eventName: 'checkoutOption',
        overrideAttributes: {
          step: CheckoutStepNumber.SHIPPING,
          option: `Shipping Method: ${cart.value.shippingMethods[0].label}`,
        },
      });
    };

    /**
     * Checking if is a PO box address.
     */
    const verifyAddress = (address, address2 = '') => {
      if (hasCustomShippingMethod.value) {
        isPoAddress.value = isPOBoxAddress(address, address2);
      }
    };

    /**
     * Checking if shipping methods has customs.
     */
    const hasCustomShippingMethod = computed(() => {
      return shippingGroups.value.find((shippingMethod) => {
        return shippingMethod.code?.startsWith('customs');
      });
    });

    /**
     * Check if single group is customs
     */
    const isCustom = (group) => {
      return group.code.startsWith('customs');
    };

    /**
     * Verify if is a valid method.
     */
    const isValidShippingMethod = (shippingMethod) => {
      return !excludedShippingMethods.includes(shippingMethod.code);
    };

    watch(shippingGroups, () => {
      getShippingGroups.value = shippingGroups.value.filter(
        isValidShippingMethod
      );
    });

    watch<CartShippingGroup>(
      getDefaultSelectedShippingMethodCode,
      (shippingMethod) => {
        const { address } = shippingMethod ?? {};
        if (address) {
          verifyAddress(address.addressLine1, address.addressLine2 || '');
        }
        getShippingGroups.value = shippingGroups.value.filter(
          isValidShippingMethod
        );
      }
    );

    const shippingMethodAccordionClassName =
      root.$themeConfig?.CheckoutShippingMethod
        ?.shippingMethodAccordionClassName || '';

    return {
      getDefaultSelectedShippingMethodCode,
      updateShippingMethod,
      getShippingGroups,
      isPoAddress,
      hasCustomShippingMethod,
      shippingMethodAccordionClassName,
      hasShippingItems,
      shippingGroups,
      isCustom,
    };
  },
});
