type PatternValidator = (input: string) => boolean;

const validators = {
  pattern: (regex: string): PatternValidator => {
    const match = regex.match(/^\/(.*)\/([gimsuy]?)$/);
    const reg = match ? new RegExp(match[1], match[2]) : new RegExp(regex);
    return (input: string): boolean => {
      return reg.test(input);
    };
  },
};

export default validators;
