
















































import { PropType } from 'vue';
import { defineComponent } from '@vue/composition-api';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { PriceOverrideTranslations } from '@vf/api-contract';

export default defineComponent({
  name: 'PriceOverride',
  mixins: [validationMixin],
  props: {
    translations: {
      type: Object as PropType<PriceOverrideTranslations>,
      required: true,
    },
    productId: String,
    priceAdjustmentId: String,
    isManualPromo: Boolean,
  },
  data() {
    return {
      price: '',
      comment: '',
    };
  },
  computed: {
    inputsFilled() {
      return this.price && this.comment;
    },
    resetButtonDisabled() {
      return !this.priceAdjustmentId;
    },
  },
  methods: {
    overridePrice() {
      if (!this.$v.$invalid) {
        const eventName =
          this.priceAdjustmentId && this.isManualPromo
            ? 'update-price'
            : 'override-price';
        this.$emit(eventName, {
          price: this.price,
          comment: this.comment,
          productId: this.productId,
          priceAdjustmentId: this.priceAdjustmentId,
        });
        this.setToEmpty();
      }
    },
    resetPrice() {
      this.$emit('reset-price', this.priceAdjustmentId);
    },
    setToEmpty() {
      this.price = '';
      this.comment = '';
      this.$v.$reset();
    },
    handleInput(event) {
      this.price = event.replace(/[^0-9.]/g, '');
    },
  },
  validations: {
    comment: {
      required,
    },
    price: {
      required,
      price: (value) => /^\d+(\.\d+)?$/.test(value),
    },
  },
});
