









































import { computed, defineComponent, ref } from '@nuxtjs/composition-api';
import type { CartLineItem } from '@vf/api-client';

import { pluralize } from '@/helpers/pluralize';
import useRootInstance from '@/shared/useRootInstance';

import MenuList from '@/components/MenuList.vue';
import { storeToRefs } from 'pinia';
import { useCartStore } from '@vf/composables/src/store/cartStore';

export default defineComponent({
  name: 'OutOfStockProducts',
  components: {
    MenuList,
  },
  setup() {
    const { root } = useRootInstance();

    const { outOfStockProducts } = storeToRefs(useCartStore());

    const staticCartTheme = root.$themeConfig.staticCart;

    const flyoutContainer = ref(null);
    const clickedProduct = ref(null);
    const flyoutContainerListItems = computed(() => {
      return [
        {
          text: root.$t('cartProducts.remove'),
          icon: 'close',
          handler: () => removeAction(clickedProduct.value),
        },
      ];
    });

    const itemsText = computed(() => {
      return `${outOfStockProducts.value.length} ${pluralize(
        root.$t('outOfStockProducts.item') as string,
        outOfStockProducts.value.length
      )}`;
    });

    const removeOutOfStockProducts = () => {
      useCartStore().clearFlashesWithProductIds(
        outOfStockProducts.value.map(({ productId }) => productId)
      );
    };

    const removeAction = (product: CartLineItem) => {
      flyoutContainer.value.hide();
      useCartStore().clearFlashesWithProductIds([product.productId]);
    };

    const openMenuAction = (event, product) => {
      flyoutContainer.value.show(event);
      clickedProduct.value = product;
    };

    return {
      outOfStockProducts,
      itemsText,
      removeOutOfStockProducts,
      staticCartTheme,
      flyoutContainer,
      flyoutContainerListItems,
      removeAction,
      openMenuAction,
    };
  },
});
