














import { defineComponent } from '@vue/composition-api/dist/vue-composition-api';
import { PropType } from 'vue';
import { XPRLWidgetTranslations } from '@vf/api-contract';
import { ROUTES, useLoyalty, useI18n } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import { computed } from '@vue/composition-api';
import { format } from 'date-fns';

export default defineComponent({
  name: 'XPLRWidget',
  props: {
    translations: {
      type: Object as PropType<XPRLWidgetTranslations>,
      required: true,
    },
    since: {
      type: String,
    },
  },
  setup(props) {
    const { root } = useRootInstance();

    const { memberId } = useLoyalty(root);
    const { localePath } = useI18n(root);

    const title = computed(() => {
      if (!props.since) {
        return '';
      }
      const memberSince = format(new Date(props.since), 'MMMM y');
      return props.translations.loyaltyStateEnrolled?.replace(
        '{{memberSince}}',
        memberSince
      );
    });

    return {
      redirectPath: localePath(ROUTES.ACCOUNT_FAMILY()),
      memberId,
      title,
    };
  },
});
