
















import type { PropType } from 'vue';
import { computed, ComputedRef, defineComponent } from '@vue/composition-api';
import type { PageTitleTranslations } from '@vf/api-contract';
import { useCategory } from '@vf/composables';
import PageTitle from '../shared/PageTitle.vue';
import useRootInstance from '@/shared/useRootInstance';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

export default defineComponent({
  name: 'CategoryTitle',
  components: { PageTitle },
  props: {
    /** Name of the context where component should be displayed (one of 'category' | 'search') */
    contextName: {
      type: String as PropType<'category' | 'search'>,
      default: 'category',
    },
    translations: {
      type: Object as PropType<PageTitleTranslations>,
      required: true,
    },
    /** Title heading level 1-6 */
    level: {
      type: Number as PropType<number>,
      default: () => 2,
    },
    /** Back button switcher */
    showBackButton: {
      type: Boolean,
      default: true,
    },
    /** Show/Hide selected filters in title */
    showSelectedFilters: {
      type: Boolean,
      default: true,
    },
    /** Show/Hide product count in subtitle */
    showItemsNumber: {
      type: Boolean,
      default: true,
    },
    contextKey: {
      type: String as PropType<string>,
      default: 'page-content',
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { pageTitle, title, pagination } = useCategory(
      root,
      props.contextKey
    );

    // TODO: Remove in GLOBAL15-62475
    const {
      isFiltersUiUpdateEnabled,
      isVansPlpRedesignEnabled,
    } = useFeatureFlagsStore();

    const plpTitle: ComputedRef<string> = computed(() =>
      props.showSelectedFilters ? pageTitle.value : title.value
    );

    const getProductsQtyLabel: ComputedRef<string> = computed(() => {
      return pagination.value.total > 1
        ? props.translations.itemsCountPlural
        : props.translations.itemsCountSingular;
    });

    const subtitle: ComputedRef<string> = computed(() =>
      props.showItemsNumber
        ? `${pagination.value.total} ${getProductsQtyLabel.value}`
        : ''
    );

    return {
      plpTitle,
      subtitle,
      isFiltersUiUpdateEnabled,
      isVansPlpRedesignEnabled,
    };
  },
});
