var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'vf-customs-product-slide': true,
    'vf-customs-product-slide--full-height': _vm.isFullHeight,
  },attrs:{"role":"group","aria-roledescription":_vm.carouselSlideRoleDescription}},[_c('VfNotification',{attrs:{"visible":_vm.isErrorVisible,"type":"danger"},scopedSlots:_vm._u([{key:"message",fn:function(){return [_vm._v("\n      "+_vm._s(_vm.translations.noProductFound)+"\n    ")]},proxy:true}])}),_vm._v(" "),_c('div',{staticClass:"vf-customs-product-slide__top"},[_c('div',{staticClass:"vf-customs-product-slide__inspired-by vf-customs-product-inspired-by"},[(_vm.authorName)?[_c('div',{staticClass:"pretitle"},[_vm._v("\n          "+_vm._s(_vm.translations.inspiredBy)+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"title"},[_vm._v("\n          "+_vm._s(_vm.authorName)+"\n        ")]),_vm._v(" "),(_vm.location)?_c('div',{staticClass:"subtitle"},[_vm._v("\n          "+_vm._s(_vm.location)+"\n        ")]):_vm._e()]:_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"vf-customs-product-slide__media"},[_c('picture',{staticClass:"vf-customs-product-slide__picture",class:{
          'vf-customs-product-slide__picture--full-height': _vm.isFullHeight,
        },attrs:{"itemscope":"","itemtype":"https://schema.org/ImageObject"}},[(_vm.defaultPicture.large)?_c('img',{directives:[{name:"lazy-image",rawName:"v-lazy-image",value:(_vm.defaultPicture.large),expression:"defaultPicture.large"}],ref:"image",staticClass:"vf-customs-product-slide__media--image",attrs:{"alt":_vm.title},on:{"load":_vm.dispatchHtmlEvent}}):(_vm.image3D)?_c('img',{directives:[{name:"lazy-image",rawName:"v-lazy-image",value:(_vm.image3D),expression:"image3D"}],ref:"image",staticClass:"vf-customs-product-slide__media--image",attrs:{"alt":_vm.title},on:{"load":_vm.dispatchHtmlEvent}}):_vm._e()])])]),_vm._v(" "),_c('div',{staticClass:"vf-customs-product-slide__bottom"},[_c('div',{staticClass:"vf-customs-product-slide__text"},[(_vm.subtitle)?_c('VfText',{staticClass:"vf-customs-product-slide__subtitle vf-text--transform",attrs:{"text-color":"var(--c-primary)","font-family":"vf-text--urw-franklin-gothic-font-family","styles":{
          fontSize: 'var(--font-xs)',
          fontWeight: 'var(--font-bold)',
        }}},[_vm._v("\n        "+_vm._s(_vm.subtitle)+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.title)?_c('VfText',{staticClass:"vf-customs-product-slide__title vf-text--transform",attrs:{"tag":"h1","text-custom-classes":"vf-text--alt-title-7"}},[_vm._v("\n        "+_vm._s(_vm.title)+"\n      ")]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"vf-customs-product-slide__buttons",class:{
        'vf-customs-product-slide__buttons--bt-margin-lg':
          _vm.controlsPosition === 'betweenImageAndText',
      }},[(!_vm.hideBuyCTA)?_c('ThemeButton',{staticClass:"vf-button vf-customs-product-slide-button vf-customs-product-slide-button--buy",attrs:{"to":_vm.localePath(("/add-your-touch?id=" + _vm.recipeId)),"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id'], 'resourceBundle'),"color":"primary","data-testid":"vf-customs-product-slide-button--buy","aria-label":_vm.buttonText}},[_vm._v("\n        "+_vm._s(_vm.buttonText)+"\n      ")]):_vm._e(),_vm._v(" "),_c('VfButton',{staticClass:"vf-customs-product-slide-button vf-customs-product-slide-button--edit",class:{
          'vf-customs-product-slide-button--edit-primary': _vm.hideBuyCTA,
        },attrs:{"role":"button","aria-label":_vm.translations.editDesignCTA,"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id'], 'resourceBundle'),"disabled":_vm.isButtonDisabled,"data-testid":"vf-customs-product-slide-button--edit"},on:{"click":function($event){return _vm.openCustomizer(_vm.translations.editDesignCTA)}}},[_vm._v("\n        "+_vm._s(_vm.translations.editDesignCTA))])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }