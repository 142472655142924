import { ref } from '@vue/composition-api';
import { ContentCTA } from '@vf/api-contract';
import { onStopScroll, scrollTo } from '@vf/shared/src/utils/helpers';
import useModal from '@/shared/useModal';
import useModalHandlers from '@vf/ui/composables/useModalHandlers';
type UseCTAButtonsReturn = {
  scrollToComponent: (selector: string) => void;
  openInNewModal: (cta: ContentCTA) => void;
  mapContentCtas: (ctas: ContentCTA[]) => MappedContentCTA[];
};

type MappedContentCTA = {
  clickHandler: any;
  props: {
    to: string;
    size: string;
    color: string;
    context: string;
    target: string;
  };
  styleClass: string;
  label: string;
  link: string;
  icon: string;
  linkBehavior?: 'openInNewTab' | 'openInNewModal' | 'scrollToComponent';
};

function useCTAButtons(): UseCTAButtonsReturn {
  const { setModalProps } = useModal();
  const { openModal } = useModalHandlers();
  const lastScrollPos = ref(0);

  const scrollToComponent = (selector: string) => {
    const element = document.querySelector(selector);
    const stickyHeader = document.querySelector(
      '.vf-header.sticky-header'
    ) as HTMLElement;

    if (element) {
      const top =
        element.getBoundingClientRect().top +
        window.scrollY -
        (stickyHeader ? stickyHeader.offsetHeight : 0);

      scrollTo({ top });
      const tolerance = 0.01;
      if (Math.abs(lastScrollPos.value - top) > tolerance) {
        lastScrollPos.value = top;
        onStopScroll(() => scrollToComponent(selector));
      }
    }
  };

  const openInNewModal = (cta: ContentCTA) => {
    if (cta.link.includes('data-id')) {
      const resourceId = cta.link.replace(/\D/g, '');
      openModal({
        resourceId,
        type: 'lazyFragment',
      });
      checkButtonTarget(cta.buttonTargetComponent);
    } else {
      openModal({
        path: cta.link,
        type: 'page',
      });
      checkButtonTarget(cta.buttonTargetComponent);
    }
  };

  const checkButtonTarget = (target: string) => {
    if (target === 'CMVideo') {
      setModalProps({ class: 'custom-video-modal' });
    }
  };

  const mapContentCtas = (ctas: ContentCTA[]) => {
    return ctas.map((cta: ContentCTA) => {
      const props = {
        to: cta.link,
        size: 'md',
        color: cta.styleClass,
        context: '',
        target: '_self',
        buttonTargetComponent: cta.buttonTargetComponent,
      };
      let clickHandler = null;

      if (cta.styleClass === 'primaryHoverAlt') {
        props.color = 'primary';
        props.context = 'dark';
      }

      if (cta.linkBehavior === 'openInNewTab') {
        props.target = '_blank';
      }

      if (cta.linkBehavior === 'openInNewModal') {
        props.to = null;
        clickHandler = () => openInNewModal(cta);
      }

      if (cta.linkBehavior === 'scrollToComponent') {
        props.to = null;
        clickHandler = () => scrollToComponent(cta.link);
      }

      return {
        ...cta,
        clickHandler,
        props,
      };
    });
  };

  return {
    scrollToComponent,
    openInNewModal,
    mapContentCtas,
  };
}

export default useCTAButtons;
