




































import { ContentCTA, ContentText } from '@vf/api-contract';
import { defineComponent, PropType, computed } from '@vue/composition-api';
import useCTAButtons from './composables/useCTAButtons';
import { CMContentListComponentStyles } from '@vf/api-contract';

export default defineComponent({
  name: 'ContentList',
  props: {
    title: {
      type: Object as PropType<ContentText>,
    },
    ctas: {
      type: Array as PropType<ContentCTA[]>,
      required: true,
    },
    columnCount: {
      type: Number,
      required: true,
    },
    bottomMarginStyle: {
      type: String,
      required: true,
    },
    linkUnderline: Boolean,
    rowBackgroundColorHex: String,
    linkColorTextHex: String,
    sortOptions: String as PropType<
      CMContentListComponentStyles['sortOptions']
    >,
  },
  setup: (props) => {
    const { mapContentCtas } = useCTAButtons();

    return {
      mappedSortedCtas: computed(() => {
        const mappedCtas = mapContentCtas(props.ctas);

        if (props.sortOptions === 'sort-alphabetical') {
          return [...mappedCtas].sort((a, b) => {
            if (a.label === b.label) {
              return 0;
            }

            return a.label > b.label ? 1 : -1;
          });
        }

        return mappedCtas;
      }),
      gridClassName: computed(
        () => `content-list__grid--size-${props.columnCount || 2}`
      ),
    };
  },
});
