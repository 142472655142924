





















import {
  defineComponent,
  onMounted,
  reactive,
  ref,
} from '@vue/composition-api';
import useRootInstance from '@/shared/useRootInstance';
import { mask } from 'ke-the-mask';
import { useSignInToStore } from '@vf/composables';
import useValidators from '@vf/composables/src/useValidators';
import {
  validateExactLength,
  validateRequired,
} from '@vf/composables/src/useValidators/validators';

export default defineComponent({
  name: 'BuyInStoreInfo',
  directives: {
    mask,
  },
  setup() {
    const { root } = useRootInstance();
    const { storeInfoData, inStoreSalesInfo } = useSignInToStore(root);

    const storeId = storeInfoData?.value?.storeName ?? '';
    const form = reactive({
      storeEmployeeId: inStoreSalesInfo.value.storeEmployeeId || '',
    });

    const { validate, validationFields } = useValidators(
      form,
      ref({
        storeEmployeeId: [
          validateRequired(
            root.$t('buyInStoreInfo.validation.required') as string
          ),
          validateExactLength(
            6,
            root.$t('buyInStoreInfo.validation.exactLength') as string
          ),
        ],
      })
    );
    const submit = () => {
      if (!validate()) return false;
      inStoreSalesInfo.value.storeEmployeeId = form.storeEmployeeId;
      return true;
    };

    onMounted(() => {
      inStoreSalesInfo.value.storeId = storeInfoData.value?.storeId;
    });

    return {
      storeId,
      form,
      validate,
      validationFields,
      submit,
    };
  },
});
