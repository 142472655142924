var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:_vm.slideIndex,staticClass:"vf-hero-item",class:{ 'vf-hero-item--with-button': _vm.hero.buttonText },attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.hero.id)}},[(!_vm.hero.video)?[_c('picture',{staticClass:"vf-hero-item__picture",class:{ 'vf-hero-item__picture--contain': _vm.noCrop },attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.hero.id, 'pictures[0]'),"itemscope":"","itemtype":"https://schema.org/ImageObject"}},[_c('meta',{attrs:{"itemprop":"datePublished","content":_vm.hero.image.seo.date}}),_vm._v(" "),_c('meta',{attrs:{"itemprop":"description","content":_vm.hero.image.seo.description}}),_vm._v(" "),_c('img',{directives:[{name:"lazy-image",rawName:"v-lazy-image",value:(_vm.getMainImage(_vm.hero.image)),expression:"getMainImage(hero.image)"}],ref:"image",attrs:{"alt":_vm.hero.image.seo.title,"loading":"lazy","width":_vm.hero.image.width,"height":_vm.hero.image.height}})]),_vm._v(" "),_c('div',{staticClass:"vf-hero-item__container"},[(_vm.hero.headingText)?_c('VfHeading',{staticClass:"vf-text--highlighted heading",class:[_vm.hero.titleClassModifier],style:(_vm.customTitleStyles(_vm.hero)),attrs:{"data-id":_vm.hero.id,"title":_vm.hero.headingText,"modifier":"title-1","level":_vm.hero.headingLevel || 1,"aria-label":_vm.isFirstHero(_vm.slideIndex) ? _vm.hero.headingText : ''}}):_vm._e(),_vm._v(" "),(_vm.hero.subheadingText)?_c('div',{staticClass:"vf-hero-item__subheading"},[_c('VfText',{class:_vm.hero.subtitleClassModifier,style:({
            color: _vm.hero.subtitleColor,
            'font-size': _vm.hero.subtitleFontSize,
            'font-family': _vm.hero.subtitleFontFamily,
            'font-weight': _vm.hero.subtitleFontFamily,
          }),attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(
              _vm.hero.id,
              _vm.hero.useRawHtml ? 'teaserRichTextObject' : 'teaserText'
            ),"background-color":_vm.hero.subtitleBackgroundColor,"html":_vm.hero.useRawHtml,"content":_vm.hero.subheadingText}})],1):_vm._e(),_vm._v(" "),(_vm.hero.buttonVisible)?_c('ThemeButton',{attrs:{"color":"primary","size":"md","data-cm-metadata":_vm.$previewMetaDataLocation(_vm.hero.id, 'teaserTargets')}},[_vm._v("\n        "+_vm._s(_vm.hero.buttonText)+"\n      ")]):_vm._e()],1)]:_c('client-only',[_c('VfControlledVideo',{attrs:{"data-id":_vm.hero.id,"hero":_vm.hero,"slide-index":_vm.slideIndex,"slide-height":_vm.slideHeight},on:{"video-event":function($event){return _vm.dispatchVideoEvent($event)}}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }