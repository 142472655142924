export const buildStartNarvarReturnUrl = (
  instance: Record<string, any>,
  orderReleaseNumber?: string,
  email?: string
): string => {
  try {
    const url = new URL(instance.$env.NARVAR_START_RETURN_URL);
    url.searchParams.set('locale', instance.$i18n.getPosixLocale());
    /**
     * Prefills return order number and email with given values
     */
    if (orderReleaseNumber) {
      url.searchParams.set('order', orderReleaseNumber);
    }
    if (email) {
      url.searchParams.set('email', email);
    }
    url.searchParams.set('init', 'true');
    return url.toString();
  } catch (error) {
    instance.$log.error(
      `[@composables/utils/orders::buildStartNarvarReturnUrl] Cannot build narvar return url.  Trace: ${error.stack}`
    );
  }
};
