export const getFilterType = (
  filterCode,
  filtersVariant,
  facetConfiguration,
  defaultValue,
  isChipOverriden,
  isCheckboxOverriden,
  isImageOverriden
) => {
  return getFilterOptions(
    filterCode,
    filtersVariant,
    {
      chip: 'VfChip',
      text: 'VfFacet',
      checkbox: 'VfFilterCheckbox',
      image: 'VfFilterImage',
    },
    facetConfiguration,
    defaultValue,
    isChipOverriden,
    isCheckboxOverriden,
    isImageOverriden
  );
};

export const getFilterClass = (
  filterCode,
  filtersVariant,
  pageType,
  facetConfiguration,
  defaultValue,
  isChipOverriden,
  isCheckboxOverriden,
  isImageOverriden
) => {
  return getFilterOptions(
    filterCode,
    filtersVariant,
    {
      chip: `${pageType}__filter--Chip`,
      text: `${pageType}__filter--Facet`,
      checkbox: `${pageType}__filter--Checkbox`,
      image: `${pageType}__filter--Image`,
    },
    facetConfiguration,
    defaultValue,
    isChipOverriden,
    isCheckboxOverriden,
    isImageOverriden
  );
};

const getFilterOptions = (
  filterCode,
  filtersVariant,
  options,
  facetConfiguration,
  defaultValue,
  isChipOverriden,
  isCheckboxOverriden,
  isImageOverriden
) => {
  const variantMapping =
    getDefaultType(filterCode, filtersVariant, defaultValue) === 'chips'
      ? 'chip'
      : defaultValue;
  if (isSizeFilter(filterCode) && isChipOverriden) {
    return options.chip;
  }
  if (isCheckboxOverriden) {
    return options.checkbox;
  }
  if (isImageOverriden) {
    return options.image;
  }
  return options[facetConfiguration[filterCode]?.displayType || variantMapping];
};

const getDefaultType = (filterCode, sizeFiltersVariant, defaultValue) => {
  return isSizeFilter(filterCode) ? sizeFiltersVariant : defaultValue;
};

const isSizeFilter = (filter) => {
  return filter.toLowerCase().includes('size');
};
