




















































































import type { PropType } from 'vue';
import {
  computed,
  ref,
  defineComponent,
  Ref,
  watch,
  onMounted,
} from '@vue/composition-api';
import type { ProductUpsellTranslations } from '@vf/api-contract';
import { useGtm, useProduct, useUpsell } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import { EnhancedSaleType } from '@vf/composables/src/types/gtm';
import { getEventFromTemplate } from '@vf/composables/src/useGtm/helpers';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

export default defineComponent({
  name: 'ProductUpsell',
  props: {
    experienceId: {
      type: String,
      default: null,
    },
    contextKey: {
      type: String,
      default: null,
    },
    translations: {
      type: Object as PropType<ProductUpsellTranslations>,
      default: () => ({}),
    },
    useMonetateFallback: {
      type: Boolean,
      default: false,
    },
    /** Will show a skeleton loading animation while it's true */
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { dispatchEvent } = useGtm(root);
    const { getRecommendedItem, upsellItem, isUpsellSelected } = useUpsell(
      root,
      props.contextKey
    );
    const { product: pdpProduct, attributesData } = useProduct(
      root,
      props.contextKey
    );

    // TODO: Remove in GLOBAL15-63801
    const { isVansPdpRedesignEnabled } = useFeatureFlagsStore();

    const isModalOpen: Ref<boolean> = ref(false);
    const { upsellsCheckboxModifier } = root.$themeConfig?.productUpsell || {};

    watch(
      pdpProduct,
      () => {
        if (pdpProduct.value) {
          getRecommendedItem(
            pdpProduct.value,
            props.useMonetateFallback,
            props.experienceId
          );
        }
      },
      { immediate: true }
    );

    const handleUpdateSelected = (isSelected: boolean) => {
      isUpsellSelected.value = isSelected;
    };

    const getFormattedPrice = (price?: number, currency?: string) => {
      return price ? root.$formatPrice(price, currency) : '';
    };

    const hasSpecialPrice = computed(() => {
      if (!upsellItem.value) {
        return false;
      }
      const { current, original } = upsellItem.value.price;
      return current && original && current !== original;
    });

    onMounted(() => {
      isUpsellSelected.value = false;

      if (!upsellItem.value) {
        return false;
      }
      dispatchEvent({
        ...getEventFromTemplate('enhanced-sale:impression'),
        overrideAttributes: {
          type: EnhancedSaleType.UP_SELL,
          products: [
            {
              ...upsellItem.value,
              id: upsellItem.value.itemGroupId,
            },
          ],
        },
      });
    });

    return {
      upsellItem,
      handleUpdateSelected,
      isUpsellSelected,
      isModalOpen,
      getFormattedPrice,
      hasSpecialPrice,
      upsellsCheckboxModifier,
      attributesData,
      isVansPdpRedesignEnabled,
    };
  },
});
