










































































import { ref, defineComponent } from '@vue/composition-api';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'PromoCode',
  props: {
    translations: {
      type: Object,
      default: () => ({}),
    },
    coupons: {
      type: Array,
      default: () => [],
    },
    code: String,
    currency: String,
  },
  setup() {
    const { root } = useRootInstance();
    const applyBtnClass =
      root.$themeConfig?.checkoutSidebar?.applyPromoCodeBtnClass ||
      'button-size';

    const showTooltip = ref(false);

    return {
      applyBtnClass,
      showTooltip,
    };
  },
});
