import { render, staticRenderFns } from "./OrderSuccess.vue?vue&type=template&id=e08a5782&scoped=true&"
import script from "./OrderSuccess.vue?vue&type=script&lang=ts&"
export * from "./OrderSuccess.vue?vue&type=script&lang=ts&"
import style0 from "./OrderSuccess.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./OrderSuccess.vue?vue&type=style&index=1&id=e08a5782&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e08a5782",
  null
  
)

export default component.exports