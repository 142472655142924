





















import { ROUTES, useI18n } from '@vf/composables';
import { storeToRefs } from 'pinia';
import ExpressCheckout from '@/components/static/cart/ExpressCheckout.vue';
import useRootInstance from '@/shared/useRootInstance';
import { CheckoutContext } from '@vf/api-contract';
import { useCartStore } from '@vf/composables/src/store/cartStore';
import OrderSummaryCollapsible from '@/components/static/OrderSummaryCollapsible.vue';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

export default {
  name: 'CartCtaSidebar',
  components: { ExpressCheckout, OrderSummaryCollapsible },
  setup() {
    const { root } = useRootInstance();
    const { localePath } = useI18n(root);
    const { totalItems } = storeToRefs(useCartStore());
    const { checkoutOrderSummaryUpdated } = useFeatureFlagsStore();

    return {
      CheckoutContext,
      checkoutOrderSummaryUpdated,
      goToCheckoutLink: localePath(ROUTES.CHECKOUT()),
      OrderSummaryCollapsible,
      totalItems,
    };
  },
};
