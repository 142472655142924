




























































































































import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import {
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  Ref,
  toRefs,
  watch,
} from '@vue/composition-api';
import { VueMaskDirective } from 'v-mask';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';
import {
  useCart,
  useGiftCards,
  useReCaptcha,
  useValidation,
} from '@vf/composables';
import { PaymentInstruments } from '@vf/api-client';
import {
  getGiftCardFormValidationPatterns,
  maskGiftCardNumber,
} from '@vf/composables/src/utils/giftCard';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'GiftCardForm',
  serverCacheKey: getCacheKeyFromProps,
  directives: { mask: VueMaskDirective },
  mixins: [validationMixin],
  props: {
    /** Object with static content data */
    translations: {
      type: Object,
      required: true,
    },
    /** Image with information where customer can find gift card number and PIN */
    tooltipHelpImage: String,
    /** Image tooltip width */
    tooltipImageWidth: [String, Number],
    /** Image tooltip height */
    tooltipImageHeight: [String, Number],
  },
  emits: ['apply', 'remove'],
  setup(props, { emit }) {
    const { root } = useRootInstance();
    const {
      appliedGiftCards,
    }: { appliedGiftCards: Ref<PaymentInstruments[]> } = useCart(root);
    const { setValidation, $v } = useValidation(root, 'GIFT_CARD_FORM');
    const recaptcha = useReCaptcha(root);

    const { canAddMoreCards } = useGiftCards(root);

    const state = reactive({
      cardNumber: null,
      pin: null,
      recaptchaToken: null,
    });

    watch(
      /**
       * Reset state if any gift or reward card is applied
       */
      () => appliedGiftCards.value.length,
      () => {
        state.cardNumber = null;
        state.pin = null;
        $v.value.$reset();
      }
    );
    const giftCardValidation = getGiftCardFormValidationPatterns(
      root.$getEnvValueByCurrentLocale<{
        cardNumberLengths: number[];
        pinLengths: number[];
      }>('GIFT_CARD')
    );

    const apply = async () => {
      state.recaptchaToken = await recaptcha.executeRecaptcha('giftCard');
      emit('apply', { ...state });
    };

    const remove = (paymentId: string, method: string) => {
      emit('remove', { paymentId, method });
    };

    onMounted(() => recaptcha.showBadge());
    onUnmounted(() => recaptcha.hideBadge());

    return {
      displayButtonNextToInput:
        root.$themeConfig.giftCard.displayButtonNextToInput,
      ...toRefs(state),
      appliedGiftCards,
      canAddMoreCards,
      giftCardValidation,
      maskGiftCardNumber,
      setValidation,
      apply,
      remove,
    };
  },
  data: function () {
    return {
      showTooltip: false,
    };
  },
  mounted() {
    this.setValidation(this.$v);
  },
  validations: {
    cardNumber: {
      required,
      cardNumber: (value, vm) =>
        new RegExp(vm.giftCardValidation.cardNumber, 'g').test(value),
    },
    pin: {
      required,
      pin: (value, vm) =>
        new RegExp(vm.giftCardValidation.pin, 'g').test(value),
    },
  },
});
