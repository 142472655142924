import type { AxiosResponse } from 'axios';
import type { ContactUsCategory } from '@vf/api-contract';
import { apiClientFactory } from '@vf/api-client';
import { useI18n } from '../useI18n';
import { useFeatureFlagsStore } from '../store/featureFlags';
import { ComponentInstance } from '../types';
import { errorMessages } from '../utils/errorMessages';

export interface ContactFormBody {
  productInfo: string;
  subject: string;
  fullName: string;
  consumerEmail: string;
  consumerPhone: string;
  orderNumber: string;
  message: string;
  lastName?: string;
  consent?: boolean;
}

interface UseContactForm {
  getCategories: () => Promise<ContactUsCategory[]>;
  submitForm: (
    formBody: ContactFormBody,
    recaptcha_token: string
  ) => Promise<AxiosResponse>;
}

export const useContactForm = (instance: ComponentInstance): UseContactForm => {
  const { displayErrorMessages } = errorMessages(instance);
  const {
    getCategorySubject,
    submitContactForm,
    submitContactFormSfcc,
  } = apiClientFactory(instance);
  const { localeCode } = useI18n(instance);
  const { isSfccContactUs } = useFeatureFlagsStore();
  const locale = localeCode();

  const submitForm = async (
    formBody: ContactFormBody,
    recaptcha_token: string
  ) => {
    try {
      if (isSfccContactUs) {
        const [language, country] = locale.split('-');
        return await submitContactFormSfcc({
          category: formBody.productInfo,
          consumerEmail: formBody.consumerEmail,
          consumerPhone: formBody.consumerPhone || '',
          firstName: formBody.fullName,
          lastName: formBody.lastName,
          message: formBody.message,
          orderNumber: formBody.orderNumber || '',
          origin: `${country}-${language}`,
          personTitle: '',
          subject: formBody.subject,
          recaptcha_token,
        });
      } else return await submitContactForm({ ...formBody, recaptcha_token });
    } catch (e) {
      displayErrorMessages(e);
      throw e;
    }
  };

  const getCategories = () => getCategorySubject(locale);

  return { getCategories, submitForm };
};
