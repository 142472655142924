export const formatHour = (hour: string): string =>
  hour.includes(':')
    ? hour.trim().replace('AM', ' AM').replace('PM', ' PM')
    : hour.trim().replace('AM', ':00 AM').replace('PM', ':00 PM');

export const getTwentyFourHourTime = (formattedHour: string): string => {
  const date = new Date('05/26/2023 ' + formattedHour);
  return `${date.getHours()}:${
    date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
  }`;
};
