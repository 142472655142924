




import { defineComponent } from '@nuxtjs/composition-api';
import PageDescription from '@/components/smart/shared/PageDescription.vue';

export default defineComponent({
  name: 'PLPCategoryDescription',
  components: {
    PageDescription,
  },
  props: {
    plpDescription: {
      type: String,
      default: '',
    },
  },
});
