




















import { defineComponent } from '@vue/composition-api';
import { useCms } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'ArticleAbout',
  props: {
    /** Show Page Title **/
    showTitle: {
      type: Boolean,
      default: true,
    },
    /** Show Page Subtitle **/
    showSubtitle: {
      type: Boolean,
      default: false,
    },
    /** Show Page Published Date **/
    showDate: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const { root } = useRootInstance();
    const { pageProps } = useCms(root, 'page-content');
    const { title, subtitle, publicationDate } = pageProps.value;
    const formatDate = (date) =>
      new Intl.DateTimeFormat(root.$i18n.locale, { dateStyle: 'long' }).format(
        new Date(date.split('[')[0])
      );

    return {
      title,
      subtitle,
      publicationDate,
      formatDate,
    };
  },
});
