var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.selectedStore)?_c('div',{staticClass:"store-confirmation"},[_c('div',{staticClass:"store-confirmation-container"},[_c('h2',{staticClass:"store-confirmation-title"},[_vm._v("\n      "+_vm._s(_vm.$t('storeConfirmation.title'))+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"store-confirmation-subtitle"},[(!_vm.hasOutOfStock)?_c('p',[_vm._v("\n        "+_vm._s(_vm.replaceAll(_vm.$t('storeConfirmation.allAvailable'), {
            storeName: _vm.selectedStore.name,
          }))+"\n      ")]):[_c('p',[_vm._v("\n          "+_vm._s(_vm.replaceAll(_vm.$t('storeConfirmation.partialAvailable'), {
              storeName: _vm.selectedStore.name,
            }))+"\n        ")]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.$t('storeConfirmation.outOfStock')))])]],2),_vm._v(" "),_vm._l((_vm.groupedPickupItems),function(ref){
            var key = ref[0];
            var value = ref[1];
return _c('div',{key:key,staticClass:"store-confirmation-group"},[_c('p',[_vm._v("\n        "+_vm._s(_vm.$t(("storeConfirmation." + key)))+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"store-confirmation-group--products"},_vm._l((value),function(ref){
            var productImageURL = ref.productImageURL;
            var productId = ref.productId;
            var name = ref.name;
return _c('VfPicture',{key:productId,attrs:{"src":productImageURL,"alt":name,"height":94,"width":94}})}),1)])})],2),_vm._v(" "),_c('div',{staticClass:"store-confirmation-actions"},[_c('ThemeButton',_vm._b({attrs:{"size":"full"},on:{"click":function($event){return _vm.$emit('close')}}},'ThemeButton',_vm.buttonConfig,false),[_vm._v("\n      "+_vm._s(_vm.$t('storeConfirmation.cancelCta'))+"\n    ")]),_vm._v(" "),_c('ThemeButton',{attrs:{"color":"primary","size":"full"},on:{"click":function($event){return _vm.$emit('submit')}}},[_vm._v("\n      "+_vm._s(_vm.$t('storeConfirmation.confirmCta'))+"\n    ")])],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }