


























import type { LoyaltyVouchersResponse } from '@vf/api-contract';
import type { PropType } from '@vue/composition-api';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'LoyaltyRewardsExpiry',
  props: {
    rewards: {
      type: Object as PropType<LoyaltyVouchersResponse>,
    },
    showPointsHistory: {
      type: Boolean,
      default: true,
    },
  },
});
