
























import {
  computed,
  defineComponent,
  ssrRef,
  useFetch,
  PropType,
} from '@nuxtjs/composition-api';
import { BreadcrumbList, WithContext } from 'schema-dts';
import { StoreCitiesTranslations } from '@vf/api-contract';
import {
  useFindInStore,
  useJsonLinkedData,
  useRequestTracker,
} from '@vf/composables';
import { setPageTypeName } from '@/helpers';
import { capitalizeText } from '@/helpers/capitalizeText';
import useRootInstance from '@/shared/useRootInstance';
import StoreSplitDealers from '@vf/ui/components/Atom.StoreSplitDealers.vue';

export default defineComponent({
  name: 'StoreCities',
  components: {
    StoreSplitDealers,
  },
  props: {
    /**
     * State id.
     */
    id: {
      type: String,
      default: '',
    },
    /**
     * Block title.
     */
    title: {
      type: String,
      default: '',
    },
    /**
     * Block Meta description.
     */
    metaDescription: {
      type: String,
      default: '',
    },
    /**
     * Meta data.
     */
    metaData: {
      type: Object,
      default: () => {
        /**/
      },
    },
    /**
     * enable split by dealer.
     */
    splitDealers: {
      type: Boolean,
      default: false,
    },
    /**
     * title for vf store
     */
    titleVfStore: {
      type: String,
      default: '$titleVfStore$',
    },
    /**
     * title for Dealer store
     */
    titleDealerStore: {
      type: String,
      default: '$titleDealerStore$',
    },
    /**
     * translations
     */
    translations: {
      type: Object as PropType<StoreCitiesTranslations>,
      required: true,
    },
  },
  jsonld(): WithContext<BreadcrumbList> {
    return this.transformBreadcrumbsDataToJsonld(this.breadcrumbs);
  },
  setup(prop) {
    const { root } = useRootInstance();

    const fullTitle = ssrRef('', 'store-cities-title');
    const breadcrumbs = ssrRef([], 'store-cities-breadcrumbs');
    // Function to map breadcrumbs list.
    const mapBreadCrumbs = (data) => {
      breadcrumbs.value = [
        {
          link: root.$storeLocatorUrlGenerator('/stores'),
          text: prop.metaData.main_breadcrumb_url_name,
        },
        {
          link: root.$storeLocatorUrlGenerator('/stores', data.name),
          text: data.description,
        },
      ];
    };

    const { transformBreadcrumbsDataToJsonld } = useJsonLinkedData(root);

    const {
      getStateWithCities,
      state: stateCollection,
      getStore,
      store: stores,
      isDealer,
    } = useFindInStore(root);
    const { onAllDone } = useRequestTracker(root);

    const setPageValues = async () => {
      await getStateWithCities(prop.id.toUpperCase(), !prop.splitDealers);
      fullTitle.value = prop.title.replace(
        '{state_name}',
        stateCollection.value.description
      );
      mapBreadCrumbs(stateCollection.value);
      if (prop.splitDealers) {
        await getStore({
          country: stateCollection.value.country,
          state: prop.id.toUpperCase(),
          order: 'city',
          allStores: true,
        });
      }
    };

    const storesGroupByCities = computed(() => {
      if (prop.splitDealers && stores.value.length) {
        // create two list on for VfStore and one for Dealer removing city duplicates
        const citiesMapVfStore: Record<string, any> = {};
        const citiesMapDealerStore: Record<string, any> = {};
        stores.value.forEach((store) => {
          if (store.city) {
            const city = capitalizeText(store.city, true);
            if (isDealer(store)) {
              citiesMapDealerStore[city] = { ...store, city };
            } else {
              citiesMapVfStore[city] = { ...store, city };
            }
          }
        });
        return Object.values(citiesMapVfStore).concat(
          Object.values(citiesMapDealerStore)
        );
      }
      return [];
    });

    useFetch(setPageValues);

    onAllDone(() => {
      setPageTypeName('stores state page');
    });

    return {
      setPageValues,
      fullTitle,
      isDealer,
      breadcrumbs,
      stateCollection,
      storesGroupByCities,
      urlGenerator: root.$storeLocatorUrlGenerator,
      transformBreadcrumbsDataToJsonld,
    };
  },
  head() {
    const state = this.stateCollection;
    const description = this.metaData.description
      .replace('{state}', state.description)
      .replace('{state_code}', state.name);
    return {
      title: this.metaData.title
        .replace('{state}', state.description)
        .replace('{state_code}', state.name),
      meta: [
        {
          hid: 'description',
          name: 'description',
          property: 'description',
          content: description,
        },
        {
          hid: 'og:description',
          name: 'og:description',
          property: 'og:description',
          content: description,
        },
      ],
      link: [
        {
          rel: 'canonical',
          href:
            'https://' +
            this.$getDomainName() +
            this.$storeLocatorUrlGenerator('/stores', state.name),
        },
      ],
    };
  },
});
