
































import type { PropType } from 'vue';
import {
  computed,
  defineComponent,
  onMounted,
  ref,
} from '@vue/composition-api';
import { storeToRefs } from 'pinia';
import type { OrderDetailsTranslations } from '@vf/api-contract';
import { ReturnDetailedItem } from '@vf/api-client';
import { useI18n, useOrders, useReturns, ROUTES } from '@vf/composables';
import { useUserStore } from '@vf/composables/src/store/user';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import { ReturnStepsType } from '@vf/composables/src/useReturns';
import { getUniqueStatuses } from '@/helpers';
import { getItemGiftBoxPrice } from '@vf/shared/src/utils/helpers';
import useRootInstance from '@/shared/useRootInstance';

import OrderSummary from '@/components/order/OrderSummary.vue';
import { useOrderStatusStore } from '@vf/composables/src/store/orderStatus';

export default defineComponent({
  name: 'OrderDetails',
  components: { OrderSummary },
  props: {
    translations: {
      type: Object as PropType<OrderDetailsTranslations>,
      required: true,
    },
    /** Flag to enable returns interface */
    orderPageType: {
      type: String,
      default: 'order',
    },
    refundMethodOptions: {
      type: Object as PropType<{
        title: string;
        options: {
          label: string;
          method: string;
          tooltip: string;
        }[];
      }>,
      default: () => ({}),
    },
    displayReturnedItemsDetailsCTA: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const theme = {
      searchByKey: root.$themeConfig.orderStatus?.searchByKey,
      notificationType: root.$themeConfig.orderDetails?.notificationType,
    };

    const { order, loadOrder, findGuestOrder, getOrderStatusLabel } = useOrders(
      root
    );
    const userStore = useUserStore(root);
    const { loggedIn } = storeToRefs(userStore);
    const {
      updateReturnItems,
      removeReturnItem,
      returnStep,
      returnPreviewObject,
      getReturnDetails,
    } = useReturns(root);
    const { localePath, getStaticTranslation } = useI18n(root);
    const { areOnlineReturnsEnabled } = useFeatureFlagsStore();

    const showStatusNotification = ref<boolean>(true);

    const isReturns = computed<boolean>(
      () => props.orderPageType == 'return' && areOnlineReturnsEnabled
    );

    // Conditions checking for returns context
    const returnsContext = computed<boolean>(() =>
      [
        loggedIn.value,
        root.$route.fullPath.includes(ROUTES.RETURN_DETAILS()),
        isReturns.value,
      ].every(Boolean)
    );

    const fetchOrderDetails = (orderId?: string): unknown => {
      const orderStatusStore = useOrderStatusStore();

      if (orderId) {
        return loadOrder(orderId);
      }

      if (orderStatusStore.orderNumber) {
        return findGuestOrder(
          orderStatusStore.orderNumber,
          orderStatusStore[theme.searchByKey],
          theme.searchByKey === 'lastName',
          orderStatusStore.zipCode
        );
      }

      root.$router.push(localePath(ROUTES.ORDER_STATUS()));
    };

    onMounted(async () => {
      try {
        const paramId = root.$route.params.id;
        if (returnsContext.value) {
          if (returnPreviewObject.value) return;
          await getReturnDetails(paramId);
        } else {
          if (order.value) return;
          await fetchOrderDetails(paramId);
        }
      } catch (e) {
        root.$router.push(ROUTES.HOME());
      }
    });

    const handleUpdateReturn = (payload: ReturnDetailedItem) => {
      payload.isSelected
        ? updateReturnItems(payload)
        : removeReturnItem(payload);
    };

    const orderStatus = computed<string>(() => {
      const orderData = returnsContext.value
        ? returnPreviewObject.value
        : order.value;

      return orderData?.status
        ? getOrderStatusLabel(
            orderData.status,
            getUniqueStatuses(orderData?.items)
          )
        : '';
    });

    const showOrderStatus = computed(
      () =>
        (!isReturns.value ||
          returnStep.value === ReturnStepsType.HISTORY_DETAILS) &&
        orderStatus.value !== 'NA'
    );

    const sortedOrderItems = computed(() => {
      const orderItems = (order.value?.items ?? []).map((product) => ({
        ...product,
        giftBoxPrice: getItemGiftBoxPrice(product),
      }));
      return orderItems.sort((product, nextProduct) => {
        return product.bonus - nextProduct.bonus;
      });
    });

    return {
      theme,
      showStatusNotification,
      order,
      orderItems: sortedOrderItems,
      orderStatus,
      showOrderStatus,
      orderTranslations: computed(() => ({
        ...props.translations,
        returnReasons: getStaticTranslation('returnReasons'),
      })),
      isReturns,
      returnStep,
      returnPreviewObject,
      handleUpdateReturn,
    };
  },
});
