















import type { PropType } from 'vue';
import { computed, defineComponent, watch } from '@vue/composition-api';
import { storeToRefs } from 'pinia';
import uniqBy from '@vf/composables/src/utils/uniqBy';
import { useCartStore } from '@vf/composables/src/store/cartStore';
import useRootInstance from '@/shared/useRootInstance';
import {
  CartCustomerNotificationType,
  CheckoutContext,
  FlashErrorType,
} from '@vf/api-contract';
import {
  replaceMessagePlaceholders,
  scrollTo as scrollToTop,
} from '@vf/shared/src/utils/helpers';
import type { FlashError } from '@vf/api-client';

type PageLevelMessage = { code: string; message: string | null; path: string };

export default defineComponent({
  name: 'CartNotifications',
  props: {
    currentStep: {
      type: String as PropType<CheckoutContext>,
      default: CheckoutContext.Cart,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { cartItems, customerFacingFlashes } = storeToRefs(useCartStore());

    const pageLevelMessages = computed<PageLevelMessage[]>(() => {
      const messages = customerFacingFlashes.value.map((item) => {
        return {
          code: item.code,
          message: getMessage(
            item,
            props.currentStep,
            !!cartItems.value.length
          ),
          path: item.path || item.code,
        };
      });
      /*
       * We display list of notifications with unique messages.
       * When product becomes unavailable on cart API returns ProductLineItemFullInventoryMissing flash.
       * When same thing happens on checkout two flashes are returned:
       * ProductItemNotAvailable and ProductLineItemInventoryMissing.
       * To not show two notifications signaling the same thing with different wording we filter notifications by path.
       * */
      return uniqBy(
        uniqBy(
          messages.filter(({ message }) => !!message),
          'message'
        ),
        'path'
      );
    });

    const getMessage = (
      flash: FlashError,
      currentStep: CheckoutContext,
      canCompleteOrder = true
    ) => {
      // we should ignore ShippingDowngrade flash on shipping page, but show on other
      const isShippingDowngrade =
        (CartCustomerNotificationType.OrderShippingMethodDowngraded as string) ===
        (flash.code as string);
      const isShipping = currentStep === CheckoutContext.Shipping;
      if (isShipping && isShippingDowngrade) return;
      /*
       * There is a difference in OOS messaging between Cart and Checkout
       * and depending on if customer can complete the order or not.
       * */
      const isOOSFlash = [
        FlashErrorType.NotAvailable,
        FlashErrorType.NoLongerAvailable,
        FlashErrorType.FullInventoryMissing,
      ].includes(flash.code);
      const isCart = currentStep === CheckoutContext.Cart;
      const code =
        isOOSFlash && !isCart && !canCompleteOrder
          ? 'CantCompleteOrder'
          : flash.code;
      const bundleName = isCart ? 'cartNotifications' : 'checkoutNotifications';
      const key = `${bundleName}.pageLevel.${code}`;
      return root.$te(key)
        ? replaceMessagePlaceholders(root.$t(key) as string, flash.details)
        : undefined;
    };

    watch(
      pageLevelMessages,
      (value, oldValue) => {
        if (JSON.stringify(value) !== JSON.stringify(oldValue)) {
          scrollToTop();
        }
      },
      { immediate: true }
    );

    return {
      pageLevelMessages,
    };
  },
});
