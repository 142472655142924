






















































import type { PropType } from 'vue';
import type { PickupPerson } from '@vf/api-client';
import { defineComponent, reactive } from '@vue/composition-api';
import useValidators from '@vf/composables/src/useValidators';
import {
  validateEmail,
  validateMaxLength,
  validateName,
  validateRequired,
} from '@vf/composables/src/useValidators/validators';
import { ref } from '@nuxtjs/composition-api';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'CheckoutPickerInfo',
  props: {
    value: Object as PropType<PickupPerson>,
  },
  emits: ['input'],
  setup(props) {
    const { root } = useRootInstance();

    const form = reactive(props.value);

    const pickupInfoConfig = root.$themeConfig?.checkoutPickupInfo;

    const { validate, validationFields } = useValidators(
      form,
      ref({
        firstName: [
          validateRequired(root.$t('pickerInfo.validation.required') as string),
          validateMaxLength(
            pickupInfoConfig.firstNameMaxLength,
            root.$t('pickerInfo.validation.maxLength') as string
          ),
          validateName(root.$t('pickerInfo.validation.pattern') as string),
        ],
        lastName: [
          validateRequired(root.$t('pickerInfo.validation.required') as string),
          validateMaxLength(
            pickupInfoConfig.lastNameMaxLength,
            root.$t('pickerInfo.validation.maxLength') as string
          ),
          validateName(root.$t('pickerInfo.validation.pattern') as string),
        ],
        email: [
          validateRequired(root.$t('pickerInfo.validation.required') as string),
          validateEmail(root.$t('pickerInfo.validation.email') as string),
        ],
      })
    );

    return {
      form,
      validationFields,
      validate,
    };
  },
});
