














































































import type { PropType } from 'vue';
import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import type {
  ComponentWithVariants,
  EmailPreferencesFormTranslations,
} from '@vf/api-contract';
import { useAccount, useUtilities, useNotification } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'EmailPreferencesForm',
  props: {
    translations: {
      type: Object as PropType<EmailPreferencesFormTranslations>,
      required: true,
    },
    /** Determines if language preference is shown  */
    showLanguagePreference: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    /** Determines if subscription confirmation is shown  */
    showUnsubscribeConfirmation: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    /** Component variant to display */
    componentVariant: {
      type: String as PropType<ComponentWithVariants>,
      default: 'VARIANT_A',
    },
  },
  setup(props) {
    const { root } = useRootInstance();

    const { basicInformation, setBasicInformation } = useAccount(root);
    const {
      addNotification,
      clearNotifications,
      setChangePasswordNotification,
    } = useNotification(root);
    const { getCountry } = useUtilities(root);

    const theme = root.$themeConfig.emailPreferences;
    const saveButton = Object.assign(
      { color: 'primary', size: 'md', class: '' },
      theme?.saveButton
    );

    const disableSubmit = ref(false);

    const isSubscribed = computed<boolean>(() => {
      const newsletter = basicInformation.value.subscriptions.find(
        (subscription) => subscription.channel === 'email'
      );

      return newsletter?.optin;
    });

    const isSubscribedCheckbox = ref(isSubscribed.value);

    watch(
      () => isSubscribed.value,
      (val) => {
        isSubscribedCheckbox.value = val;
      }
    );
    const checkboxClicked = ref(false);
    watch(isSubscribedCheckbox, (value, oldValue) => {
      if (oldValue !== undefined) {
        checkboxClicked.value = !checkboxClicked.value;
      }
    });

    const saveInformation = async (optin) => {
      setChangePasswordNotification(false);
      clearNotifications();
      try {
        disableSubmit.value = true;
        await setBasicInformation({
          subscriptions: [
            {
              type: 'Newsletter',
              optin,
              country: getCountry().toUpperCase(),
              optinSource: root.$env.API_SOURCE,
              region: root.$env.API_REGION,
            },
          ],
        });
        addNotification({
          message: props.translations.emailPreferencesSaved,
          type: 'success',
        });
      } catch (err) {
        addNotification({
          message: props.translations.setBasicInformationError,
          type: 'danger',
        });
      } finally {
        disableSubmit.value = false;
        checkboxClicked.value = false;
      }
    };

    const subscribe = () => {
      saveInformation(true);
    };

    const unsubscribe = () => {
      saveInformation(false);
    };

    return {
      disableSubmit,
      isSubscribed,
      isSubscribedCheckbox,
      basicInformation,
      unsubscribe,
      subscribe,
      theme,
      saveButton,
      checkboxClicked,
    };
  },
});
