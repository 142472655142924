














































import {
  computed,
  defineComponent,
  nextTick,
  onUnmounted,
  PropType,
  watch,
} from '@vue/composition-api';
import { SearchGridTranslations } from '@vf/api-contract';
import {
  useGtm,
  useRequestTracker,
  useSearch,
  useSignInToStore,
  useShippingFilter,
} from '@vf/composables';
import { PageTypeName } from '@vf/composables/src/useCms/types';
import {
  extractGtmPageName,
  getFinalPdoSearch,
} from '@vf/composables/src/useGtm/eventPropsHandlers/helpers';
import { isClient } from '@vf/shared/src/utils/helpers';
import useRootInstance from '@/shared/useRootInstance';
import { useFavoritesWithDataLayer } from '@/shared/useFavoritesWithDataLayer';
import { useCmsRefStore } from '@vf/composables/src/store/cmsRef';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import ProductsListNoResults from '@/components/smart/shared/ProductsListNoResults.vue';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'SearchGrid',
  components: {
    ProductsListNoResults,
    VfPickupStoreModal: () =>
      import('@/components/modals/PickupStoreModal.vue'),
    VfShippingFilter: () =>
      import('@/components/static/plp/ShippingFilter.vue'),
  },
  props: {
    /** Name of the context where component should be displayed */
    contextName: {
      type: String,
      default: 'search',
    },
    /** How many items should be displayed on single page */
    productsPerPage: {
      type: Number,
      default: 25,
    },
    initialProductsLoad: {
      type: Number,
      default: 25,
    },
    showAddToFavourites: {
      type: Boolean,
      default: true,
    },
    /** QuickShop link page to be open in modal */
    quickShopLink: {
      type: [String, Object],
      default: '',
    },
    showQuickShop: {
      type: Boolean,
      default: true,
    },
    showRatingsIcon: {
      type: Boolean,
      default: true,
    },
    showSalePrice: {
      type: Boolean,
      default: true,
    },
    contextKey: {
      type: String,
      default: '',
    },
    translations: {
      type: Object as PropType<SearchGridTranslations>,
      default: () => ({}),
    },
    showNoInventoryProducts: {
      type: Boolean,
      default: true,
    },
    showSeeMore: {
      type: Boolean,
      default: true,
    },
    showColorSwatches: {
      type: Boolean,
      default: true,
    },
    showRegularPrice: {
      type: Boolean,
      default: true,
    },
    showWishListIcon: {
      type: Boolean,
      default: true,
    },
    showProductBadge: {
      type: Boolean,
      default: true,
    },
    showProductName: {
      type: Boolean,
      default: true,
    },
    showEyebrow: {
      type: Boolean,
      default: true,
    },
    showProductDescription: {
      type: Boolean,
      default: true,
    },
    showProductImages: {
      type: Boolean,
      default: true,
    },
    showBadges: {
      type: Boolean,
      default: false,
    },
    showAddToCart: Boolean,
    modals: {
      type: Object,
      default: () => ({
        signInToBuy: null,
        loyaltyEnrollment: null,
      }),
    },
  },
  setup(props) {
    const cmsRefStore = useCmsRefStore();
    const { isBopis20PlpEnabled } = useFeatureFlagsStore();
    const { root } = useRootInstance();
    const {
      autoCorrectQuery,
      fetchedProducts,
      loading,
      isRedirected,
      pagination,
      pdoSearch,
      products,
      queryString,
      selectedFilters,
      selectedSortingOptionId,
      setInitialLoadProductsAmount,
      setProductsPerPage,
      loadPreviousPage: loadPreviousSearchPage,
    } = useSearch(root);
    const { onAllDone } = useRequestTracker(root);
    const { dispatchEvent } = useGtm(root);
    const { isEnableShippingFilter } = useShippingFilter(root);
    const { employeeConnected } = useSignInToStore(root);

    const { pageTypeName } = storeToRefs(cmsRefStore);

    let searchGridLastImpression = '';
    let firstOnAllDoneCall = true;
    let unwatchFetchedProducts = null;

    const { toggleFavorites } = useFavoritesWithDataLayer(PageTypeName.SEARCH);

    setProductsPerPage(props.productsPerPage);
    setInitialLoadProductsAmount(props.initialProductsLoad);

    const triggerProductImpression = () => {
      const fetchedProductsIdsStr = fetchedProducts.value.reduce(
        (acc, prod) => {
          acc += prod.id + '|';
          return acc;
        },
        '|'
      );
      // remove duplicate call
      if (searchGridLastImpression === fetchedProductsIdsStr) {
        // skip
        return false;
      }
      searchGridLastImpression = fetchedProductsIdsStr;
      const { searchType } = getFinalPdoSearch(
        pdoSearch.value,
        pageTypeName.value,
        queryString.value,
        autoCorrectQuery.value,
        isRedirected.value,
        pagination.value.total
      );
      dispatchEvent({
        eventName: 'productImpressions',
        composablesContexts: {
          useSearch: props.contextKey,
        },
        overrideAttributes: {
          productIds: fetchedProducts.value.map((p) => p.id),
        },
        persistentVariables: {
          searchTerm: queryString.value,
          category: extractGtmPageName(
            PageTypeName.SEARCH,
            undefined,
            undefined,
            searchType
          ),
        },
      });
    };

    const searchState = computed(() => ({
      page: pagination.value.page,
      previousPage: pagination.value.previousPage,
      selectedFilters: selectedFilters.value,
      selectedSortingOptionId: selectedSortingOptionId.value,
      queryString: queryString.value,
    }));

    if (isClient) {
      watch(
        () => JSON.stringify(searchState),
        () => {
          searchGridLastImpression = '';
        }
      );
      onAllDone(() => {
        if (firstOnAllDoneCall) {
          firstOnAllDoneCall = false;
          unwatchFetchedProducts = watch(
            fetchedProducts,
            triggerProductImpression,
            { immediate: true }
          );
        }
      });
    }

    onUnmounted(() => {
      // we using this until will be resolve twice loaded component
      firstOnAllDoneCall = false;
      searchGridLastImpression = '';
      unwatchFetchedProducts?.();
    });

    // Do not show if employee is connected and config is true.
    const strictShowAddToFavourites = computed(() => {
      return root.$themeConfig?.saveToFavorites?.hideFavouriteCtaForEmployees &&
        employeeConnected.value
        ? false
        : props.showAddToFavourites;
    });

    const loadPreviousPage = async (elementToScroll) => {
      await loadPreviousSearchPage();

      await nextTick();

      const element = document.querySelector(elementToScroll);
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
    };

    const showShippingFilter = computed(
      () =>
        root.$themeConfig.plpGrid.showShippingFilter &&
        isEnableShippingFilter.value
    );

    return {
      isBopis20PlpEnabled,
      products,
      searchState,
      loading,
      toggleFavorites,
      loadPreviousPage,
      showShippingFilter,
      strictShowAddToFavourites,
    };
  },
});
