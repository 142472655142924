














import type { PropType } from 'vue';
import {
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
} from '@vue/composition-api';
import { useCmsLinks } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';
import useModal from '@/shared/useModal';
import type {
  RichTextColorProp,
  RichTextFamilyProp,
  RichTextSizeProp,
  RichTextTagElement,
  RichTextWeightProp,
} from '@vf/api-contract';

export default defineComponent({
  name: 'RichText',
  serverCacheKey: getCacheKeyFromProps,
  props: {
    tag: {
      type: String as PropType<RichTextTagElement>,
      default: 'p',
    },
    /** True if provided content should be rendered as HTML. False for normal text */
    html: Boolean,
    showLineSeparator: Boolean,
    /** Text content or HTML if html prop is set to true */
    content: {
      type: String,
      default: '',
    },
    /** CSS class modifiers for text from teaser styles */
    textCustomClasses: {
      type: String,
      default: '',
    },
    /** Font size style. Eg. when passed ['xl'] font will be xl sized on all screen sizes. To choose font size for mobile add '-sm' modifier at the end and '-lg' for desktop - ['2xl-lg', 'xl-sm']. */
    fontSizes: {
      type: Array as PropType<RichTextSizeProp[]>,
      default: () => [],
    },
    /** Font weight style */
    fontWeight: {
      type: String as PropType<RichTextWeightProp>,
      default: '',
    },
    /** Font family style */
    fontFamily: {
      type: String as PropType<RichTextFamilyProp>,
      default: '',
    },
    /** Font color style */
    color: {
      type: String as PropType<RichTextColorProp>,
      default: '',
    },
    /** Color for text from teaser styles */
    textColor: {
      type: String,
      default: '',
    },
    /** Background color for text from teaser styles */
    backgroundColor: {
      type: String,
      default: '',
    },
    /**
     * Hyperlink Underline Styles:
     * default - Default
     * noUnderline - No Underline
     * underlineAlways - Underline (Always)
     * underlineOnHover - Underline (on Hover)
     * underlineAnimate - Underline (Animate on Hover)
     */
    hyperlinkStyle: {
      type: String,
      default: 'default',
    },
    /**
     * Gap between the hyperlink text and underline:
     * none - No Gap
     * XS - 1px
     * S - 2px
     */
    hyperlinkMargin: {
      type: String,
      default: 'none',
    },
    wrapperClassModifier: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { root } = useRootInstance();
    const { setCmsLinksRef, unsetCmsLinks } = useCmsLinks(root, useModal());

    const richTextRef = ref(null);

    onMounted(() => {
      setCmsLinksRef(richTextRef.value);
    });

    onUnmounted(() => {
      unsetCmsLinks();
    });

    return {
      richTextRef,
    };
  },
});
