var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-image-with-overlay-on-hover",attrs:{"tabindex":"0"}},[(_vm.media.image)?_c('VfPicture',{staticClass:"content-image-with-overlay-on-hover__image",attrs:{"src":_vm.media.image,"alt":_vm.media.alt,"width":_vm.imageWidths,"height":_vm.imageHeights}}):_vm._e(),_vm._v(" "),(_vm.hasOverlay)?_c('div',{staticClass:"content-image-with-overlay-on-hover__overlay"},[_c('div',{staticClass:"content-image-with-overlay-on-hover__overlay-content"},[_c('div',{staticClass:"content-image-with-overlay-on-hover__titles"},[(_vm.title && _vm.title.text)?_c('ThemeTitle',{staticClass:"content-image-with-overlay-on-hover__title",class:{
            'content-image-with-overlay-on-hover--switched-color':
              _vm.isBackgroundTransparent(_vm.title.backgroundColor) &&
              _vm.isColorWhite(_vm.title.color),
          },attrs:{"variant":_vm.title.variant || 'largeTitle',"color-hex":_vm.title.color,"background-color-hex":_vm.title.backgroundColor,"level":"2","tabindex":"0"}},[_vm._v("\n          "+_vm._s(_vm.title.text)+"\n        ")]):_vm._e(),_vm._v(" "),(_vm.bodyText && _vm.bodyText.text)?_c('ThemeTitle',{staticClass:"content-image-with-overlay-on-hover__body-text",class:{
            'content-image-with-overlay-on-hover--switched-color':
              _vm.isBackgroundTransparent(_vm.bodyText.backgroundColor) &&
              _vm.isColorWhite(_vm.bodyText.color),
          },attrs:{"variant":_vm.bodyText.variant,"color-hex":_vm.bodyText.color,"background-color-hex":_vm.bodyText.backgroundColor,"level":"4","tabindex":"0"}},[_vm._v("\n          "+_vm._s(_vm.bodyText.text)+"\n        ")]):_vm._e()],1),_vm._v(" "),(!_vm.mediaTarget && _vm.ctas.length)?_c('ContentCTAButtons',{staticClass:"content-image-with-overlay-on-hover__cta-items",attrs:{"ctas":_vm.ctas}}):_vm._e()],1)]):_vm._e(),_vm._v(" "),(_vm.mediaTarget)?_c('VfLink',{staticClass:"content-image-with-overlay-on-hover__media-target-overlay",attrs:{"link":_vm.mediaTarget.link,"open-in-new-tab":_vm.mediaTarget.linkBehavior === 'openInNewTab',"scroll-to-component":_vm.mediaTarget.linkBehavior === 'scrollToComponent',"open-in-new-modal":_vm.mediaTarget.linkBehavior === 'openInNewModal',"tag":"div"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }