












































































































import type { PropType } from 'vue';
import type { CartLineItem } from '@vf/api-client';

import { computed, defineComponent, reactive, ref } from '@vue/composition-api';
import useRootInstance from '@/shared/useRootInstance';
import useValidators from '@vf/composables/src/useValidators';
import {
  validateRegex,
  validateRequired,
} from '@vf/composables/src/useValidators/validators';
import { getGiftOptionItem, removeDomain } from '@vf/shared/src/utils/helpers';

export default defineComponent({
  name: 'GiftOption',
  props: {
    product: Object as PropType<CartLineItem>,
  },
  emits: ['close', 'save'],
  setup(props, { emit }) {
    const { root } = useRootInstance();

    const MESSAGE_MAX_LENGTH = 100;

    const form = reactive({
      to: props.product.giftOption.to || '',
      from: props.product.giftOption.from || '',
      message: props.product.giftOption.message || '',
      isGiftBoxSelected: !!props.product.isGiftBoxSelected,
    });

    const getRequiredValidationText = (label) =>
      (root.$t('giftOption.validation.required') as string).replace(
        '{{fieldName}}',
        (root.$t(`giftOption.${label}`) as string).toLowerCase()
      );

    const { validate, validationFields } = useValidators(
      form,
      ref({
        to: [
          validateRequired(getRequiredValidationText('to')),
          validateRegex(
            /^[\wÀ-ÿ¿æœ\s\-',.]*$/,
            root.$t('giftOption.validation.pattern') as string
          ),
        ],
        from: [
          validateRequired(getRequiredValidationText('from')),
          validateRegex(
            /^[\wÀ-ÿ¿æœ\s\-',.]*$/,
            root.$t('giftOption.validation.pattern') as string
          ),
        ],
        message: [
          validateRegex(
            /^[\wÀ-ÿ¿æœ\s\-',.!?&$]*$/,
            root.$t('giftOption.validation.pattern') as string
          ),
        ],
      })
    );

    const attributes = computed(() =>
      (props.product.variants ?? []).map(({ label, value }) => ({
        label,
        value,
      }))
    );

    const charactersRemainingText = computed(() => {
      const count = MESSAGE_MAX_LENGTH - form.message.length;
      return `${root.$t('giftOption.remaining', { count })}`;
    });

    const giftBoxMessage = computed(() => {
      const price = root.$formatPrice(
        props.product.giftBoxPrice,
        props.product.price?.currency
      );
      return `${root.$t('giftOption.giftBox', { price })}`;
    });

    const productUrl = computed(() => {
      const relativeUrl = removeDomain(props.product.pdpUrl);
      return root.localePath(relativeUrl);
    });

    const onSave = () => {
      if (validate()) emit('save', getGiftOptionItem(props.product, form));
    };

    return {
      attributes,
      form,
      validate,
      validationFields,
      charactersRemainingText,
      giftBoxMessage,
      productUrl,
      MESSAGE_MAX_LENGTH,
      onSave,
    };
  },
});
