import { CartShippingGroup, Cart, CartTotals } from '@vf/api-client/src/types';

export const sanitizeCartShippingMethodsForComparison = (
  data: CartShippingGroup[]
): Partial<CartShippingGroup>[] => {
  return data.map((shippingMethod) => ({
    ...shippingMethod,
    /**
     * priceAdjustmentId parameter on each shippingPromotion item is changing every time we fetch the cart from API.
     * Strip irrelevant arrays and values from shipping methods objects to be sure we will not re-fetch shippingMethods when some Ids of promotions changed
     * */
    applicableShippingMethods: null,
    shippingPromotions: null,
  }));
};

/** we need to observe only several properties to observe in order to fetch shipping methods */
export const getCartWithComparableProperties = ({
  totals,
  shippingMethods: baseShippingMethods,
  totalItems,
  currency,
}: Cart): {
  totals: CartTotals;
  shippingMethods: Partial<CartShippingGroup>[];
  totalItems: number;
  currency: string;
} => {
  const shippingMethods = sanitizeCartShippingMethodsForComparison(
    baseShippingMethods
  );

  return {
    shippingMethods,
    totals,
    totalItems,
    currency,
  };
};

export const areCartsDifferent = (
  newValue: Cart | undefined,
  oldValue: Cart | undefined
): boolean => {
  return (
    JSON.stringify(getCartWithComparableProperties(newValue)) !==
    JSON.stringify(getCartWithComparableProperties(oldValue))
  );
};
