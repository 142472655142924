
















































import { defineComponent, ref } from '@nuxtjs/composition-api';
import type { PropType } from 'vue';
import { CartLineItem } from '@vf/api-client';
import useRootInstance from '@/shared/useRootInstance';
import DeliveryMethodSelector from './DeliveryMethodSelector.vue';

export default defineComponent({
  name: 'DeliverMethodModal',
  components: {
    DeliveryMethodSelector,
  },
  props: {
    product: {
      type: Object as PropType<CartLineItem>,
      default: () => ({}),
    },
  },
  emits: ['open:store-selector', 'on-modal-close', 'change:delivery-method'],
  setup(props, { emit }) {
    const selectedShippingOption = ref(
      props.product.shippingOptions?.find((opt) => opt.selected)?.shippingMethod
        ?.code || ''
    );
    const changeDeliveryMethod = (_storeId, code) => {
      selectedShippingOption.value = code;
    };
    const { root } = useRootInstance();
    const cancelButtonColor =
      root.$themeConfig.staticCart.deliveryMethodSelector.cancelButtonColor;
    const onConfirm = () => {
      const selectedOption = props.product.shippingOptions?.find(
        (opt) => opt.shippingMethod.code === selectedShippingOption.value
      );
      if (selectedOption)
        emit(
          'change:delivery-method',
          selectedOption.storeInfo,
          selectedOption.shippingMethod.code
        );
    };
    const openStoreSelector = (product) => {
      emit('open:store-selector', product);
      emit('on-modal-close');
    };
    return {
      cancelButtonColor,
      changeDeliveryMethod,
      onConfirm,
      selectedShippingOption,
      openStoreSelector,
    };
  },
});
