











import { ref, defineComponent } from '@vue/composition-api';
import Placeholder from '@/components/loaders/Placeholder.vue';

export default defineComponent({
  name: 'Container',
  components: { Placeholder },
  props: {
    fullHeight: Boolean,
    nested: Boolean,
    fullWidth: Boolean,
    backgroundColor: String,
    hasPlaceholder: Boolean,
  },
  setup(props) {
    const classes = props.hasPlaceholder ? 'vf-container--relative' : '';
    let loaded = ref(false);
    let loadingState = ref('initialized');

    const hidePlaceholder = () => {
      loaded.value = true;
      loadingState.value = 'completed';
    };

    return {
      classes,
      hidePlaceholder,
      loaded,
      loadingState,
    };
  },
});
