var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VfButton',_vm._g({class:[
    'vf-regular-button',
    _vm.customClasses,
    _vm.textCustomClasses,
    _vm.buttonStyle ? ("vf-button--" + _vm.buttonStyle) : '',
    _vm.buttonSize ? ("vf-button--" + _vm.buttonSize) : '',
    _vm.iconPosition ? ("vf-button--icon-" + _vm.iconPosition) : '',
    _vm.fontFamily,
    {
      'vf-regular-button--fullWidth': _vm.buttonSize === 'fullWidth',
      'vf-button--underline': _vm.underline,
      'vf-button--no-underline': !_vm.underline,
    } ],attrs:{"id":_vm.id,"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id']),"disabled":!_vm.enabled,"link":_vm.link,"scroll-to-link":_vm.scrollToLink,"icon":_vm.icon === 'no-icon' ? '' : _vm.icon,"text-color":_vm.textColor,"font-size":_vm.fontSize,"font-weight":_vm.fontWeight,"open-in-new-modal":_vm.openInNewModal,"open-in-new-tab":_vm.openInNewTab},on:{"click":_vm.addCustomStylesInVideoModal}},_vm.$listeners),[_vm._v("\n  "+_vm._s(_vm.text)+"\n  "),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }