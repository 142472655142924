import { Ref } from '@vue/composition-api';

type CTALifecycleConfig = {
  $eventBus: Vue;
  experience: string;
  useModal: Ref<boolean>;
  disableCta: Ref<boolean>;
  contentLoaded: Ref<boolean>;
  styleSelectorMounted: Ref<boolean>;
  openCustomsExperienceOnLoad: () => void;
};

type CTALifecycleMethods = {
  iframeLoaded: () => void;
  setStyleSelectorOpened: () => void;
  setStyleSelectorMounted: () => void;
};

const ctaLifecycle = ({
  $eventBus,
  experience,
  useModal,
  disableCta,
  contentLoaded,
  styleSelectorMounted,
  openCustomsExperienceOnLoad,
}: CTALifecycleConfig): CTALifecycleMethods => {
  const iframeLoaded = () => {
    contentLoaded.value = true;
    switchCta();

    if (!useModal.value) {
      openCustomsExperienceOnLoad();
    }
  };

  const setStyleSelectorOpened = () => {
    $eventBus.$emit('closeCustomsIframe');
  };

  const setStyleSelectorMounted = () => {
    styleSelectorMounted.value = true;
    switchCta();
  };

  const switchCta = () => {
    if (experience === 'styleSelectorIframeCallEvent') {
      if (styleSelectorMounted.value) {
        disableCta.value = false;
        return;
      }
    } else {
      if (contentLoaded.value) {
        disableCta.value = false;
        return;
      }
    }
    disableCta.value = true;
  };

  return {
    iframeLoaded,
    setStyleSelectorOpened,
    setStyleSelectorMounted,
  };
};

export default ctaLifecycle;
