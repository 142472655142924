











import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'GifVideo',
  props: {
    /** Video Object to display as a pseudo gif */
    video: {
      type: Object,
      default: () => ({}),
    },
    /** Flag to determine if the link on video click is enabled */
    callToActionEnabled: {
      type: Boolean,
      default: false,
    },
    /** Teaser link */
    teaserLink: {
      type: String,
      default: '',
    },
    /** Flag to determine if the link opens a new tab */
    openInNewTab: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    shouldOpenInNewTab() {
      return this.openInNewTab ? '_blank' : null;
    },
  },
});
