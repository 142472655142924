//
//
//
//

import { useArticles } from '@vf/composables';
import { onMounted, ref } from '@vue/composition-api';
import useRootInstance from '@/shared/useRootInstance';

export default {
  name: 'PinnedArticleById',
  props: {
    articlePageId: {
      type: String,
      default: '',
    },
    contextKey: {
      type: String,
      default: 'page-content',
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { fetchArticleById } = useArticles(root, props.contextKey);
    const articleToDisplay = ref(null);
    onMounted(async () => {
      if (props.articlePageId) {
        articleToDisplay.value = await fetchArticleById(props.articlePageId);
      }
    });

    return {
      articleToDisplay,
    };
  },
};
