import {
  ColorSwatch,
  CategoryProduct,
  ProductInventoryState,
} from '@vf/api-client';

export const getDefaultVariant = (
  product: CategoryProduct,
  fallback = false
): ColorSwatch => {
  if (!product || !product.color_swatches) return null;
  const defaultVariant =
    product.color_swatches.find(
      (color: ColorSwatch) => color.defaultColor === true
    ) || (fallback ? product.color_swatches[0] : null);

  return defaultVariant || null;
};

export const getVariantByValue = (
  product: CategoryProduct,
  variantValue: string
): ColorSwatch => {
  if (!product || !product.color_swatches) return null;
  return product.color_swatches.find(
    (color: ColorSwatch) => color.value === variantValue
  );
};

export const areAllColorsDiscounted = (product: CategoryProduct): boolean => {
  if (product?.color_swatches?.[0]?.price?.discount) {
    return product.color_swatches.every((color) => color.price.discount > 0);
  }

  return false;
};

// Retrieving customs recipe only for dummy custom products
export const getRecipe = (product: CategoryProduct): string =>
  product.dummyCustoms &&
  (product.recipe || product.recipeId || product.customsRecipeID);

export const getTileUrl = (
  product: CategoryProduct,
  getProductUrl: (
    product: CategoryProduct,
    qs?: { [key: string]: string },
    isCanonical?: boolean
  ) => string
): string => {
  const defaultVariant = getDefaultVariant(product);

  return (
    defaultVariant?.pageUrl ||
    getProductUrl(product, {
      recipe: getRecipe(product),
    })
  );
};

export const getProductCardDataId = (product: CategoryProduct): string => {
  const recipe = getRecipe(product);
  const recipeSegment = recipe ? `_${recipe}` : '';

  return `${product.id}${recipeSegment}`;
};

export const isSoldOut = (product: CategoryProduct): boolean =>
  product?.productInventoryState === ProductInventoryState.SoldOut;

export default {
  getDefaultVariant,
  areAllColorsDiscounted,
  getRecipe,
  getTileUrl,
  getProductCardDataId,
  isSoldOut,
};
