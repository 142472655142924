






















































































































































































































































































































































































































// TODO: clean up this smart component GLOBAL15-56318
import { storeToRefs } from 'pinia';
import type { PropType } from 'vue';
import {
  computed,
  defineComponent,
  onMounted,
  watch,
} from '@vue/composition-api';
import {
  CouponItem,
  PaymentMethodCode,
  AutoAppliedPromotion,
  OrderPromotion,
} from '@vf/api-client';
import type {
  ShareMyCartModalWindowTranslations,
  AppliedPromotion,
  ApplePayTranslations,
  CheckoutPromoCodeTranslations,
  CheckoutSidebarTranslations,
} from '@vf/api-contract';
import {
  AthletePromoType,
  CheckoutContext,
  FlashErrorType,
} from '@vf/api-contract';
import {
  ApplePayContext,
  ROUTES,
  useApplePay,
  useCart,
  useCheckout,
  useI18n,
  useNotification,
  useAccount,
  useGiftCards,
} from '@vf/composables';

import { useUserStore } from '@vf/composables/src/store/user';
import { maskGiftCardNumber } from '@vf/composables/src/utils/giftCard';
import {
  isCouponPromotionNotApplied,
  isCouponPromotionNotActive,
  getCouponClass,
  getCouponDiscountAmount,
} from '@/helpers';
import {
  getCheckoutSidebarDividersConfig,
  getDeliveryDateLabel,
  isPickupOrSts,
} from '@vf/shared/src/utils/helpers';
import { CheckoutSidebarConfigType } from '@vf/ui/theme/types/CheckoutSidebar';
import { getItemGiftBoxPrice } from '@vf/shared/src/utils/helpers';
import { areCartsDifferent } from '@/helpers/cartOrderSummary';
import useModal from '@/shared/useModal';
import useLoader from '@/shared/useLoader';
import useRootInstance from '@/shared/useRootInstance';
import CheckoutSidebarTop from './CheckoutSidebarTop.vue';
import PayPal from '../../payment/PayPal.vue';
import ApplePay from '../../payment/ApplePay.vue';
import KlarnaOnSiteMessaging from '../../payment/KlarnaOnSiteMessaging.vue';
import CartOrderSummaryProductList from './CartOrderSummaryProductList.vue';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

const OrderSummaryProperties = () =>
  import(
    /* webpackChunkName: "OrderSummaryProperties" */
    /* webpackMode: "lazy" */
    '../../checkout/OrderSummaryProperties.vue'
  );
const CheckoutPromoCode = () =>
  import(
    /* webpackChunkName: "CheckoutPromoCode" */
    /* webpackMode: "lazy" */
    '../checkout/CheckoutPromoCode.vue'
  );
export default defineComponent({
  name: 'CartOrderSummary',
  components: {
    CheckoutSidebarTop,
    PayPal,
    OrderSummaryProperties,
    CheckoutPromoCode,
    KlarnaOnSiteMessaging,
    ApplePay,
    CartOrderSummaryProductList,
  },
  props: {
    translations: {
      type: Object as PropType<CheckoutSidebarTranslations>,
      required: true,
    },
    shareMyCartTranslations: {
      type: Object as PropType<ShareMyCartModalWindowTranslations>,
      default: () => ({}),
    },
    promoCodeTranslations: {
      type: Object as PropType<CheckoutPromoCodeTranslations>,
      default: () => ({}),
    },
    applePayTranslations: {
      type: Object as PropType<ApplePayTranslations>,
      required: true,
    },
    /** Flag to show products on sidebar or not */
    showProducts: Boolean,
    currencySymbol: String,
    /** Context to determine where Sidebar is rendered (cart|shipping|payment) */
    context: {
      type: String as PropType<CheckoutContext>,
      default: CheckoutContext.Cart,
    },
    /** Show/Hide Continue Shopping button */
    showContinueShoppingButton: {
      type: Boolean,
      default: true,
    },
    /** Show/Hide PayPal button */
    showPayPalButton: {
      type: Boolean,
      default: true,
    },
    /** Show/Hide Before Tax message */
    showBeforeTaxMessage: {
      type: Boolean,
      default: true,
    },
    /** Show/Hide Share my cart button */
    showShareMyCartButton: {
      type: Boolean,
      default: true,
    },
    /** Show/Hide Pay with Apple button */
    showPayWithAppleButton: {
      type: Boolean,
      default: true,
    },
    /** Show/Hide Pay with Google button */
    showPayWithGoogleButton: {
      type: Boolean,
      default: true,
    },
    /** Show Add promocode section */
    showAddPromoCode: Boolean,
    /** Show Add promocode with single value section */
    showAddPromoCodeWithSingleValue: Boolean,
    showLifeTimeGuarantee: Boolean,
    showFreeReturns: Boolean,
    /** Flag to determine if EDD should be displayed */
    showEstimatedDate: Boolean,
    showApplePayComponent: Boolean,
    /** Show/Hide selected shipping method details */
    showDetailingShipment: {
      type: Boolean,
      default: true,
    },
    showBottomActionButton: {
      type: Boolean,
      default: true,
    },
    showAgeConfirmation: {
      type: Boolean,
      default: true,
    },
    showSidebarTopMobile: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { showSpinner, hideSpinner } = useLoader();
    const theme: CheckoutSidebarConfigType = root.$themeConfig?.checkoutSidebar;
    const dividersConfig = getCheckoutSidebarDividersConfig(
      props.context as any,
      theme.dividers
    );
    const showSidebarTop = computed(() => {
      return !root.$viewport?.isSmall || props.showSidebarTopMobile;
    });

    const applePayContext =
      props.context === 'cart'
        ? ApplePayContext.CART
        : ApplePayContext.CHECKOUT;
    const { initApplePaySession, isApplePayAvailable } = useApplePay(
      root,
      applePayContext
    );
    const { openModal } = useModal();
    const {
      localePath,
      localeCode,
      localeMapping,
      getStaticTranslation,
    } = useI18n(root);
    const {
      cart,
      setShippingMethod,
      getShippingMethods,
      cartShippingMethods,
      shippingGroups,
      appliedGiftCards,
      appliedRewards,
      autoAppliedPromotions,
      updateCart,
      isGoToBillingButtonDisabled,
      getShippingGroupFormattedPrice,
      isPickupItem,
      hasPickupItems,
      isProductQuantityWasReduced,
      hasItems,
      clearCartLineItemCustomerNotifications,
      hasShippingItems,
      defaultShippingAddress,
    } = useCart(root);
    const {
      paymentMethod,
      isPlaceOrderButtonDisabled,
      deletePromoCode,
      isPoAddress,
      goToPayment,
      placeOrderAction,
      showPlaceOrderButtonAtPayment,
    } = useCheckout(root);
    const { removeGiftCard } = useGiftCards(root);
    const { addNotification, clearNotifications } = useNotification(root);
    const { basicInformation } = useAccount(root);
    const { areRewardCodesEnabled } = useFeatureFlagsStore();

    const userStore = useUserStore(root);
    const { loggedIn } = storeToRefs(userStore);

    const translations = props.translations;

    const localeData = {
      localeCode: localeCode(),
      localeMapping: localeMapping(),
    };

    const narvarTranslations = getStaticTranslation('narvar');

    const cartItems = computed(() =>
      (cart.value.items || []).map((product) => {
        return {
          ...product,
          isQuantityWasReduced: isProductQuantityWasReduced(product),
        };
      })
    );

    const cartProducts = computed(() => {
      return cartItems.value.map((product) => ({
        ...product,
        overrideImage: product.imageDeclined ? root.$mediaUrlFallback() : null,
        giftBoxPrice: getItemGiftBoxPrice(product),
        isPickupItem: isPickupItem(product.id),
        isAthleteExcluded:
          !product.productPromotions?.some(
            (p) => p.promotionId === AthletePromoType.PROMO_ID
          ) && basicInformation.value?.athlete?.athlete,
        isAthleteExcludedDueSale:
          product.price.original > product.price.current &&
          basicInformation.value?.athlete?.athlete,
      }));
    });

    /** Promotions and coupons */

    const pushPromotion = (
      promotions: AppliedPromotion[],
      promo: AppliedPromotion
    ) => {
      const { description, price, id, type, couponCode } = promo;
      const existingPromo = promotions.find(
        (existingPromo) =>
          existingPromo.description === description &&
          existingPromo.type === type
      );

      if (!existingPromo) {
        promotions.push({
          id,
          description,
          /** GLOBAL15-38828 The correct discount amount is placed order level. Product level discount price doesn't consider gift option with gifbox price included */
          price: (getCouponDiscountAmount(couponCode, cart.value) ??
            price) as string,
          type,
          couponCode,
        });
      } else if (!couponCode) {
        existingPromo.price += price;
      }
    };

    const appliedPromotions = computed<AppliedPromotion[]>(() => {
      const promotions = [];

      autoAppliedPromotions.value.forEach((promo: AutoAppliedPromotion) => {
        pushPromotion(promotions, {
          id: promo.promotionId,
          description: promo.calloutMsg,
          price: `${promo.amount}`,
          type: 'auto-promotion',
        });
      });

      cartProducts.value.forEach((product) => {
        product.productPromotions?.forEach((promo) => {
          /** skip buy in store overriden price as it's handled and displayed separately */
          if (promo.appliedDiscount?.type === 'fixed_price') return;

          /**
           * Displaying autoAppliedPromotions from SFCC has higher priority and contains correct calloutMsg.
           * product level promotions contains only itemText which in fact is only promotion name, not actual callout message we need to display
           */
          if (
            promotions.find((promotion) => promotion.id === promo.promotionId)
          )
            return;

          pushPromotion(promotions, {
            id: promo.priceAdjustmentId,
            description: promo.calloutMsg || promo.itemText,
            price: promo.price,
            type: 'product-promotion',
            couponCode: promo.couponCode,
          });
        });
      });

      cart.value.orderPromotions?.forEach((promo: OrderPromotion) => {
        /**
         * Displaying autoAppliedPromotions from SFCC has higher priority and contains correct calloutMsg.
         * Order level promotions contains only itemText which in fact is only promotion name, not actual callout message we need to display
         */
        if (promotions.find((promotion) => promotion.id === promo.promotionId))
          return;

        pushPromotion(promotions, {
          id: promo.promotionId,
          description: promo.itemText,
          price: `${promo.price}`,
          type: 'cart-promotion',
          couponCode: promo.couponCode,
        });
      });

      /**
       * Temporary solution. Refactoring in GLOBAL15-54807.
       * For product product level discounts calculate gift option gift box price from option items
       */
      const optionItemsDiscount = cart.value.discounts.productDiscounts
        ? cart.value.items.reduce((value, item) => {
            value += item.optionItems?.reduce((acc, next) => {
              if (
                next.optionId !== 'giftBox' &&
                next.optionValueId !== 'withGiftBox'
              )
                acc += next.priceAfterItemDiscount;
              return acc;
            }, 0);
            return value;
          }, 0)
        : 0;

      return promotions
        .filter(
          (promo, index, self) =>
            self.findIndex((item) => item.description === promo.description) ===
            index
        )
        .map((promo, index) => {
          const amount = index
            ? promo.price
            : promo.price - optionItemsDiscount; // GLOBAL15-54807
          const price = `-${root.$formatPrice(
            Math.abs(amount),
            cart?.value?.currency
          )}`;
          return { ...promo, price };
        });
    });

    const getCouponLabel = (coupon): string => {
      if (isCouponPromotionNotApplied(coupon))
        return translations.promotionNotAppliedLabel;
      if (isCouponPromotionNotActive(coupon))
        return translations.promotionNotActiveLabel;
      return props.promoCodeTranslations.couponApplied;
    };

    const coupons = computed(() => {
      if (!cart.value?.couponItems) return [];
      return cart.value.couponItems
        .filter((coupon: CouponItem) =>
          theme.hideInvalidCoupons ? coupon.valid : true
        )
        .map((coupon: CouponItem) => ({
          ...coupon,
          label: {
            title: getCouponLabel(coupon),
            className: getCouponClass(coupon),
          },
        }));
    });

    const showCartCouponsFlashErrors = () => {
      const hasCouponMessages = cart.value.flash.some((error) =>
        [FlashErrorType.CouponInvalidException].includes(error.code)
      );
      if (!isOnShippingPage.value && hasCouponMessages) {
        clearNotifications();

        addNotification({
          message: translations.promotionNotActiveErrorMessage,
          type: 'info',
        });
      }
    };

    const removePromoCode = async (couponItemId) => {
      clearNotifications();
      const response = await deletePromoCode(couponItemId);
      if ([200, 201].includes(response?.status)) {
        updateCart(response.data);
        addNotification({
          message: translations.removeCodeMessage,
          type: 'success',
        });
      }
    };

    const isCartButtonsDisabled = computed<boolean>(
      () => !cart.value.totalItems
    );

    const shouldDisplayProducts = computed<boolean>(
      () =>
        props.showProducts && cart.value.totalItems > 0 && !isOnCartPage.value
    );

    onMounted(() => {
      updateShippingMethods();
      showCartCouponsFlashErrors();
    });

    const updateShippingMethods = () => {
      if (isOnShippingPage.value || isOnPaymentPage.value) {
        return;
      }
      shippingGroups.value.forEach((group) => {
        getShippingMethods(group.shippingId, {
          isBackgroundRequest: false,
          isTemporary: false,
        });
      });
    };

    watch(
      () => cart.value,
      (value, oldValue) => {
        if (areCartsDifferent(value, oldValue)) {
          updateShippingMethods();
          isPoAddress.value = false; // reset PO address flag in case cart contents has changed
        }
      }
    );

    const itemTotal = computed<string>(() =>
      root.$formatPrice(cart.value.totals.itemTotal, cart.value.currency)
    );

    const isOnCartPage = computed(() => props.context === 'cart');
    const isOnShippingPage = computed(() => props.context === 'shipping');
    const isOnPaymentPage = computed(() => props.context === 'payment');

    const getCartLink = computed<string>(() => {
      return localePath(ROUTES.CART());
    });
    const getShippingLink = computed<string>(() => {
      return localePath(ROUTES.CHECKOUT_SHIPPING());
    });

    const locale = localePath(ROUTES.HOME());

    const getShippingMethodLabel = (method) => {
      return `${method.label} ${getDeliveryDateLabel(
        method.deliveryTime,
        narvarTranslations.deliveryDateLabel,
        false,
        false,
        localeData
      )} ${getShippingGroupFormattedPrice(method, translations.free)}`;
    };

    const getShippingTimeByGroup = (group) => {
      if (group.code.startsWith('customs')) {
        return translations.productList.customItems;
      }
      return getDeliveryDateLabel(
        getShippingTime(group),
        narvarTranslations.deliveryDateLabel,
        true,
        true,
        localeData
      );
    };

    const getShippingTime = (group) => {
      if (props.showDetailingShipment && group.methods.length) {
        const currentGroup = group.methods.find(
          (method) => group.code === method.code
        );
        return currentGroup?.deliveryTime || '';
      }
    };

    const getShippingLabel = (shippingGroup) => {
      if (props.showDetailingShipment) {
        const shippingTime = getDeliveryDateLabel(
          getShippingTime(shippingGroup),
          narvarTranslations.deliveryDateLabel,
          false,
          false,
          localeData
        );
        return (shippingGroup.code.startsWith('customs') ||
          theme.showShippingTimeAndLabelOneLine) &&
          shippingTime
          ? `${shippingGroup.label} (${shippingTime})`
          : shippingGroup.label;
      }
    };

    const getFormattedShippingPrice = (group) => {
      return group?.cost
        ? root.$formatPrice(group?.cost, cart.value.currency)
        : translations.free;
    };
    const getShippingCost = ({ code }) => {
      const shippingMethods = cart.value?.shippingMethods;
      if (shippingMethods.length) {
        const currentGroup = shippingMethods.find(
          (method) => code === method.code
        );
        const formattedShippingPrice = getFormattedShippingPrice(currentGroup);
        return formattedShippingPrice || '';
      }
      return '';
    };

    const goNext = () => {
      if (isCartButtonsDisabled.value) return;
      // Workaround for GLOBAL15-5927, existence of RequireJS loaded
      // in imported content makes CC fields fail to render
      if (['require', 'define'].some((method) => method in window)) {
        window.location.assign(localePath(ROUTES.CHECKOUT_SHIPPING()));
      } else {
        // clear notifications already rendered on cart page
        // before proceeding to shipping page
        clearCartLineItemCustomerNotifications(true);
        root.$router.push(localePath(ROUTES.CHECKOUT_SHIPPING()));
      }
    };
    /**
     * ToDo For multi shipping option, we should read shipping from product shipping ID
     * Default shipping id = 'ME'
     */

    const updateShippingMethod = (data, event) => {
      setShippingMethod({
        code: event,
        shippingId: data,
        shopperAppliedSM: true,
      });
    };

    const defaultSelectedShippingMethod = computed(() =>
      cartShippingMethods.value.reduce(
        (acc, element) => ({
          ...acc,
          [element.shippingId]: element.code,
        }),
        {}
      )
    );

    const getRemoveNotificationMessage = (
      type: PaymentMethodCode.GIFT_CARD | PaymentMethodCode.REWARD_CARD
    ) => {
      return type === PaymentMethodCode.GIFT_CARD
        ? translations.removeSuccessMessage
        : translations.removeRewardCardSuccessMessage;
    };

    const onClickRemoveGiftCard = async (
      paymentId,
      type: PaymentMethodCode.GIFT_CARD | PaymentMethodCode.REWARD_CARD
    ) => {
      const response = await removeGiftCard({ paymentId: paymentId });
      if (
        typeof response !== 'undefined' &&
        (response.status === 200 || response.status === 201)
      ) {
        clearNotifications();
        addNotification({
          message: getRemoveNotificationMessage(type),
          type: 'success',
          ...(props.context === 'payment' && {
            context:
              type === PaymentMethodCode.GIFT_CARD
                ? 'checkout-gift-card'
                : 'checkout-reward-card',
          }),
        });
      }
    };

    const isAddressInvalid = (code: string): boolean => {
      return code.startsWith('customs') && isPoAddress.value;
    };

    const shippingGroupHasMultipleMethods = (shippingGroup) => {
      return shippingGroup.methods.length > 1;
    };

    const filterShippingGroupsByCodes = (groups, codes) => {
      const codeEncounteredFlags = Array(codes.length).fill(false);
      return groups.filter((group) => {
        const currentCodeIndex = codes.indexOf(group.code);
        if (currentCodeIndex === -1) return true;
        const shouldIncludeCurrentGroup = !codeEncounteredFlags[
          currentCodeIndex
        ];
        codeEncounteredFlags[currentCodeIndex] = true;
        return shouldIncludeCurrentGroup;
      });
    };

    /*
     * To satisfy designs for [GLOBAL15-1205] and based on info from
     * https://digital.vfc.com/wiki/display/ECOM15/SFCC+-+VANS+US+-+Shipping+Methods
     * shipping group filtering was implemented to only display one 'PICKUP' and 'STS' item,
     * as they are expected to be free for consumer.
     * */
    const filteredShippingGroups = computed(() => {
      if (isCartButtonsDisabled.value) {
        return [];
      }
      const shippings = filterShippingGroupsByCodes(shippingGroups.value, [
        'pickup',
        'sts',
      ]);
      return shippings.filter(({ label, cost }, index, self) => {
        const freeGroupWithSameLabelIndex = self.findIndex(
          (item) => cost === 0 && cost === item.cost && label === item.label
        );
        return cost === 0 ? freeGroupWithSameLabelIndex === index : true;
      });
    });

    const getTooltip = (group) => {
      if (group.code === 'pickup' && !isOnCartPage.value)
        return translations.pickupTooltip;
      else if (group.code === 'sts' && !isOnCartPage.value)
        return translations.stsTooltip;
      else return translations.shippingTooltip;
    };

    const getShippingText = (group) => {
      if (isPickupOrSts(group.code)) {
        if (isOnCartPage) {
          return translations.estimatedShipping;
        } else {
          return translations.pickup;
        }
      } else if (!isOnPaymentPage) {
        return translations.estimatedShipping;
      } else {
        return translations.shipping;
      }
    };

    const showApplePayPaymentSheet = () => {
      showSpinner();
      initApplePaySession(props.applePayTranslations, hideSpinner, true);
    };

    const isKlarnaMsgVisible = computed(() => {
      if (hasPickupItems.value && hasShippingItems.value) {
        return theme.isKlarnaAllowedForMixedOrder;
      }

      return hasShippingItems.value && !hasPickupItems.value;
    });

    const onGoToPaymentClick = () => {
      if (loggedIn.value && hasShippingItems.value) {
        // verify selected, saved address before proceeding
        root.$eventBus.$emit('go-to-payment-click');
      } else {
        goToPayment();
      }
    };

    const rewardCategoryToTranslationMap = {
      SUMMER: 'summerReward',
      WINTER: 'winterReward',
      SPRING: 'springReward',
    };

    const getRewardTranslationByCategory = (rewardCategory) =>
      translations[rewardCategoryToTranslationMap[rewardCategory]];

    // the one with REWARD_CODE should stay so truthy conditionals should stay
    const getRewardTranslation = (appliedReward) => {
      return areRewardCodesEnabled &&
        appliedReward.payment_method_id === 'REWARD_CODE'
        ? getRewardTranslationByCategory(appliedReward.rewardCategory)?.replace(
            '{{year}}',
            new Date(appliedReward.issueDateTime).getFullYear()
          )
        : areRewardCodesEnabled
        ? translations.legacyReward
        : translations.rewardCardLabel;
    };

    return {
      areRewardCodesEnabled,
      getRewardTranslation,
      theme,
      isKlarnaMsgVisible,
      dividersConfig,
      isOnCartPage,
      isOnShippingPage,
      isOnPaymentPage,
      showSidebarTop,
      openModal,
      cart,
      shouldDisplayProducts,
      getCartLink,
      cartProducts,
      appliedPromotions,
      getShippingLink,
      goNext,
      itemTotal,
      coupons,
      removePromoCode,
      updateShippingMethod,
      defaultSelectedShippingMethod,
      goToPayment,
      placeOrderAction,
      shippingGroups,
      appliedGiftCards,
      appliedRewards,
      maskGiftCardNumber,
      paymentMethod,
      isGoToBillingButtonDisabled,
      isPlaceOrderButtonDisabled,
      onClickRemoveGiftCard,
      getShippingTimeByGroup,
      getShippingTime,
      getShippingLabel,
      getShippingCost,
      isPoAddress,
      isAddressInvalid,
      isCartButtonsDisabled,
      shippingGroupHasMultipleMethods,
      locale,
      getShippingGroupFormattedPrice,
      getShippingMethodLabel,
      hasPickupItems,
      filteredShippingGroups,
      hasItems,
      getTooltip,
      getShippingText,
      PaymentMethodCode,
      ApplePayContext,
      showApplePayPaymentSheet,
      showPlaceOrderButtonAtPayment,
      isPickupOrSts,
      localeData,
      onGoToPaymentClick,
      isApplePayAvailable,
      narvarTranslations,
      defaultShippingAddress,
    };
  },
});
