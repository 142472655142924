var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.customizerLink)?_c('div',{staticClass:"product-customize",attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id'])}},[_c('ProductCustomizeCta',_vm._b({attrs:{"customizer-link":_vm.customizerLink}},'ProductCustomizeCta',{
      enabled: _vm.enabled,
      text: _vm.text,
      icon: _vm.icon,
      buttonStyle: _vm.buttonStyle,
      buttonSize: _vm.buttonSize,
      iconPosition: _vm.iconPosition,
      textCustomClasses: _vm.textCustomClasses,
      isFullWidth: _vm.isFullWidth,
      buttonComponent: _vm.buttonComponent,
    },false))],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }