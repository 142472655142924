



































































import { CMVideo, ContentCTA, ContentText, Image } from '@vf/api-contract';
import { defineComponent, PropType } from '@vue/composition-api';
import ContentCTAButtons from './ContentCTAButtons.vue';

export default defineComponent({
  name: 'ContentHorizontalSimple',
  components: {
    ContentCTAButtons,
  },
  props: {
    title: {
      type: Object as PropType<ContentText>,
      required: true,
    },
    ctas: {
      type: Array as PropType<ContentCTA[]>,
      required: true,
    },
    bodyText: {
      type: Object,
      required: false,
    },
    pictures: {
      type: Array as PropType<Image[]>,
      required: false,
    },
    videos: {
      type: Array as PropType<CMVideo[]>,
      required: false,
    },
    backgroundColorStyle: {
      type: String,
      required: false,
    },
    bottomMarginStyle: {
      type: String,
      required: true,
    },
    contentAlignment: {
      type: String,
      required: false,
    },
  },
  computed: {
    mediaLength() {
      return this.pictures.length + this.videos.length;
    },
  },
});
