import { ComponentInstance } from '@vf/composables';
import { IGtmEvent } from '@vf/composables/src/types/gtm';

type CustomsDataLayerPushEventBody = {
  dataLayerData: IGtmEvent;
};

type CustomsDataLayerPushEvent = CustomsDataLayerPushEventBody & {
  body?: CustomsDataLayerPushEventBody;
};

const dataLayerPush = (
  instance: ComponentInstance,
  event: CustomsDataLayerPushEvent
): void => {
  const dataLayerData =
    'body' in event ? event.body.dataLayerData : event.dataLayerData;

  instance.$gtm.push(dataLayerData);
};

export default dataLayerPush;
