




import { useArticles } from '@vf/composables';
import { computed, defineComponent } from '@vue/composition-api';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'PinnedArticleByTags',
  props: {
    contextKey: {
      type: String,
      default: 'page-content',
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { articles } = useArticles(root, props.contextKey);

    return {
      articleToDisplay: computed(() => {
        return articles.value?.[0] ?? {};
      }),
    };
  },
});
