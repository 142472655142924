



































import type { PropType } from 'vue';
import { defineComponent } from '@vue/composition-api';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';
import useModal from '@/shared/useModal';
import type {
  IconPosition,
  Icons,
  RegularButtonSize,
  RegularButtonStyle,
} from '@vf/api-contract';

export default defineComponent({
  name: 'RegularButton',
  serverCacheKey: getCacheKeyFromProps,
  props: {
    /** Flag to determine if button should be enabled */
    enabled: {
      type: Boolean,
      default: true,
    },
    openInNewModal: {
      type: Boolean,
      default: false,
    },
    openInNewTab: {
      type: Boolean,
      default: false,
    },
    /** ID of button element */
    id: {
      type: String,
      default: '',
    },
    /** Src Link to be applied to button */
    link: {
      type: [String, Object],
      default: '',
    },
    /** Button text */
    text: {
      type: String,
      default: '',
    },
    icon: {
      type: String as PropType<Icons>,
      default: '',
    },
    buttonStyle: {
      type: String as PropType<RegularButtonStyle>,
      default: '',
    },
    fontSize: {
      type: String,
      default: '',
    },
    fontWeight: {
      type: String,
      default: '',
    },
    fontFamily: {
      type: String,
      default: '',
    },
    buttonSize: {
      type: String as PropType<RegularButtonSize>,
      default: '',
    },
    iconPosition: {
      type: String as PropType<IconPosition>,
      default: '',
    },
    /** Flag to determine if button text should be underline */
    underline: {
      type: Boolean,
      default: false,
    },
    /** Color for text from teaser styles */
    textColor: {
      type: String,
      default: '',
    },
    /** CSS class modifiers for text from teaser styles */
    textCustomClasses: {
      type: String,
      default: '',
    },
    /** Scroll to something on the page */
    scrollToLink: {
      type: Boolean,
      default: false,
    },
    /** Custom classes for button */
    customClasses: {
      type: String,
      default: '',
    },
    buttonTargetComponent: {
      type: String,
      default: '',
    },
  },
  mounted() {
    this.$on('click-native', this.emitButtonClick);
  },
  beforeDestroy() {
    this.$off('click-native', this.emitButtonClick);
  },
  methods: {
    emitButtonClick() {
      this.$parent.$emit('click-button');
    },
    addCustomStylesInVideoModal() {
      if (this.openInNewModal && this.buttonTargetComponent === 'CMVideo') {
        const { setModalProps } = useModal();
        setModalProps({ class: 'custom-video-modal' });
      }
    },
  },
});
