





































import { computed, defineComponent, ref } from '@vue/composition-api';
import { useCartStore } from '@vf/composables/src/store/cartStore';
import { storeToRefs } from 'pinia';
import StoreFinder from './StoreFinder.vue';
import StoreConfirmation from './StoreConfirmation.vue';
import type { PropType } from 'vue';
import type { CartLineItem } from '@vf/api-client';
import type { LocatorRequest } from '@vf/api-client/src/api-types';
import { useCart, useGtm } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import { apiClientFactory } from '@vf/api-client';
import { useFavoriteStore } from '@vf/composables/src/useAccount/composables/useFavoriteStore';
import ls from '@vf/composables/src/utils/localStorage';

export default defineComponent({
  name: 'StoreSelector',
  components: { StoreConfirmation, StoreFinder },
  props: {
    visible: Boolean,
    pickupItem: Object as PropType<CartLineItem>,
  },
  emits: ['close'],
  setup(props, { emit }) {
    const { root } = useRootInstance();
    const { updateItem } = useCart(root);
    const { selectedPickupStore, inStoreItems } = storeToRefs(useCartStore());
    const { setCartLoading } = useCartStore();
    const { getAvailability, getFindInStoreLocations } = apiClientFactory(root);
    const { favoriteStoreId, setFavoriteStoreId } = useFavoriteStore(root);
    const { deleteCartProductTrace } = useGtm(root);

    const unitOfMeasure =
      root.$getEnvValueByCurrentLocale('COUNTRY') === 'us' ? 'mile' : 'km';

    const isSTSEnabled = (store) => store.sts_enabled === '1';
    const isBOPISEnabled = (store) => store.bopis_enabled === '1';
    const orderIndicator = { pickup: 0, sts: 1, outOfStockItems: 2 };

    const resetState = () => {
      isFinder.value = true;
      selectedStoreId.value = '';
      stores.value = null;
      groupedPickupItems.value = [];
      isShowError.value = false;
      errorMessage.value = '';
    };

    const close = () => {
      if (isFinder.value) {
        resetState();
        emit('close');
      } else {
        isFinder.value = true;
      }
    };

    const findStores = async (request: LocatorRequest) => {
      isShowError.value = false;
      const data = {
        ...request,
        unitOfMeasure,
      };
      setCartLoading(true);
      try {
        const {
          data: { response: storeResponse },
        } = await getFindInStoreLocations(data);

        if (storeResponse.collection?.length) {
          const mappedStoreResponseCollection = storeResponse.collection.reduce(
            (stores, store) => {
              if (store.enterprise_store_identifier) {
                stores.push({ storeId: store.enterprise_store_identifier });
              }
              return stores;
            },
            []
          );
          const {
            data: { storeInventory: stocks },
          } = await getAvailability(
            props.pickupItem.productId,
            mappedStoreResponseCollection
          );

          stores.value = storeResponse.collection
            .map((store) => ({
              ...store,
              inventory:
                stocks?.find(
                  (stock) => stock.storeId === store.enterprise_store_identifier
                )?.quantity ?? 0,
            }))
            .filter((store) => isSTSEnabled(store) || isBOPISEnabled(store));

          selectedStoreId.value =
            stores.value.find(
              (store) =>
                store.inventory > 0 ||
                (store.inventory === 0 && isSTSEnabled(store))
            )?.enterprise_store_identifier || '';
        } else {
          selectedStoreId.value = '';
        }
      } catch (error) {
        stores.value = [];
        root.$log.warn(error);
      } finally {
        setCartLoading(false);
      }
    };

    const saveStore = async (newStore?: string) => {
      isShowError.value = false;
      selectedStoreId.value = newStore || selectedStoreId.value;
      try {
        if (
          isFinder.value &&
          selectedPickupStore.value &&
          selectedPickupStore.value.id !== selectedStoreId.value &&
          updatedPickupItems.value.length > 1
        ) {
          setCartLoading(true);
          const stockData = (
            await Promise.all(
              updatedPickupItems.value.map((item) =>
                getAvailability(item.productId, [
                  { storeId: selectedStoreId.value },
                ])
              )
            )
          ).map((response) => response.data);

          groupedPickupItems.value = Object.entries(
            updatedPickupItems.value.reduce((acc, item) => {
              const inventory =
                stockData.find((stock) => stock.productId === item.productId)
                  ?.storeInventory?.[0]?.quantity || 0;

              if (
                inventory >= item.qty &&
                isBOPISEnabled(selectedStore.value)
              ) {
                (acc.pickup = acc.pickup || []).push(item);
              } else if (isSTSEnabled(selectedStore.value)) {
                (acc.sts = acc.sts || []).push(item);
              } else {
                (acc.outOfStockItems = acc.outOfStockItems || []).push(item);
              }
              return acc;
            }, {} as any)
          ).sort(([a], [b]) => orderIndicator[a] - orderIndicator[b]);
          setCartLoading(false);
          isFinder.value = false;
        } else {
          deleteCartProductTrace(props.pickupItem.productId, [
            'shippingMethod',
          ]);
          const query = `action=pickup&favStoreId=${
            selectedStoreId.value || ''
          }`;
          setCartLoading(true);
          await updateItem(
            {
              productId: props.pickupItem.productId,
              recipeId: props.pickupItem.recipeId,
              itemId: props.pickupItem.id,
              qty: props.pickupItem.qty,
              maxQty: props.pickupItem.maxQty,
              pdpUrl: props.pickupItem.pdpUrl,
              productImageURL: props.pickupItem.productImageURL,
              storeId: selectedStoreId.value,
            },
            false,
            query
          );
          if (favoriteStoreId.value !== selectedStoreId.value) {
            setFavoriteStoreId(selectedStoreId.value);
          }
          ls.setItem('defaultStore', JSON.stringify(selectedStore.value));
          setCartLoading(false);
          resetState();
          emit('close');
        }
      } catch (e) {
        setCartLoading(false);
        processError();
        root.$log.warn(e);
      }
    };

    const processError = (key = 'errorNotification') => {
      isShowError.value = true;
      errorMessage.value = root.$t(`storeFinder.${key}`) as string;

      setTimeout(() => {
        isShowError.value = false;
        errorMessage.value = '';
      }, 5000);
    };

    const isFinder = ref(true);
    const selectedStoreId = ref('');
    const stores = ref(null);
    const groupedPickupItems = ref([]);
    const isShowError = ref(false);
    const errorMessage = ref('');
    const wrapperComponent = computed(() =>
      root.$viewport.isSmall ? 'VfPanel' : 'VfModal'
    );
    const selectedStore = computed(() =>
      stores.value
        ? stores.value.find(
            (store) =>
              store.enterprise_store_identifier === selectedStoreId.value
          )
        : null
    );

    const updatedPickupItems = computed(() => {
      const find = inStoreItems.value.find(
        (item) => item.productId === props.pickupItem.productId
      );
      if (!find) {
        return [...inStoreItems.value, props.pickupItem];
      } else {
        return inStoreItems.value;
      }
    });

    return {
      isFinder,
      selectedStoreId,
      selectedStore,
      stores,
      groupedPickupItems,
      close,
      saveStore,
      findStores,
      errorMessage,
      isShowError,
      processError,
      wrapperComponent,
    };
  },
});
