import { defineStore } from 'pinia';
import { ref } from '@vue/composition-api';

export const useOrderStatusStore = defineStore('orderStatus', () => {
  const orderNumber = ref('');
  const lastName = ref('');
  const email = ref('');
  const zipCode = ref('');

  return {
    orderNumber,
    lastName,
    email,
    zipCode,
  };
});
