
















import { defineComponent, PropType } from '@vue/composition-api';

export default defineComponent({
  name: 'PlpVisualFilterTile',
  props: {
    headline: {
      type: String,
      required: true,
    },
    backgroundStyle: {
      type: String as PropType<'dark' | 'light'>,
      default: 'light',
    },
    imageUrl: {
      type: String,
      default: '',
    },
    selected: {
      type: Boolean,
      default: false,
    },
    subcopy: {
      type: String,
      default: '',
    },
  },
});
