import { render, staticRenderFns } from "./ShippingAddressPicker.vue?vue&type=template&id=196d3ce2&scoped=true&"
import script from "./ShippingAddressPicker.vue?vue&type=script&lang=ts&"
export * from "./ShippingAddressPicker.vue?vue&type=script&lang=ts&"
import style0 from "./ShippingAddressPicker.vue?vue&type=style&index=0&id=196d3ce2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "196d3ce2",
  null
  
)

export default component.exports