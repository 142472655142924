import { ComponentInstance } from '../types';
import { ref } from '@vue/composition-api';
import { load as loadScript } from '@vf/shared/src/utils/helpers/load-script';

export const useSmartGift = (instance: ComponentInstance) => {
  const scriptSrc = instance.$env.SMARTGIFT_SCRIPT;
  const merchantCode = instance.$env.SMARTGIFT_MERCHANT_CODE;

  const options = {
    lang: instance.$getEnvValueByCurrentLocale<string>('LANGUAGE'),
    widgetStyle: 'pop',
  };
  // Smart gift should be enabled only if configured in env
  const shouldLoad = Boolean(scriptSrc && merchantCode);
  const isSmartGiftScriptLoaded = ref(false);

  const loadSmartGiftScript = () => {
    return new Promise((resolve, reject) => {
      if (!shouldLoad) {
        resolve(false);
        return;
      }
      loadScript(scriptSrc, async () => {
        window.SmartGiftWidget.mount(merchantCode, options);
        let retries = 0;
        do {
          if (!window.SGBasket) {
            await new Promise((r) => setTimeout(r, 200));
          }
          retries++;
        } while (!window.SGBasket && retries < 5);

        isSmartGiftScriptLoaded.value = true;
        resolve(true);
      }).catch((e) => {
        reject(e);
      });
    });
  };

  const addItemToSmartGiftBasket = (product, productSpecialPrice) => {
    const originalPrice = product.value?.variant?.price?.original;
    const currentPrice = product.value?.variant?.price?.current;
    const price =
      originalPrice !== currentPrice ? productSpecialPrice : originalPrice;
    const item = {
      skuCode: product.value.size ? product.value.sku : product.value.id,
      skuUrl: product.value.fullPageUrl,
      price: price,
      name: product.value.name,
      image: product.value.color.src,
      quantity: product.value.quantity.value,
    };
    window.SGBasket.addItem(item);
  };

  return {
    shouldLoad,
    addItemToSmartGiftBasket,
    isSmartGiftScriptLoaded,
    loadSmartGiftScript,
  };
};

export default useSmartGift;
