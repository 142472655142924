





















import { defineComponent } from '@vue/composition-api';
import { useProduct } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import VfProductMeasurements from '@/components/smart/shared/ProductMeasurements.vue';

export default defineComponent({
  name: 'PdpSizeFitGuide',
  components: { VfProductMeasurements },
  props: {
    /** Select with page type */
    contextKey: {
      type: String,
      default: 'product',
    },
    /** Flag to determine if model measurements plaque is visible */
    showModelMeasurements: Boolean,
    translations: {
      type: Object,
      default: () => ({
        ctaText: '$Size Chart$',
      }),
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { product, sizeChartId } = useProduct(root, props.contextKey);

    const openSizeChart = () => {
      root.$eventBus.$emit(`show-size-chart-modal__${props.contextKey}`);
    };

    return {
      product,
      openSizeChart,
      sizeChartId,
    };
  },
});
