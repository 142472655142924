































import type { PropType } from 'vue';
import { defineComponent } from '@vue/composition-api';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';
import { AnalyticsDataDomLocation } from '@/types';
import type { MediaSeoAttributes } from '@vf/api-contract';

export default defineComponent({
  name: 'ImageComponent',
  serverCacheKey: getCacheKeyFromProps,
  props: {
    /** Image source object */
    src: {
      type: [String, Object],
      default: '',
    },
    /** Media SEO attributes */
    seo: {
      type: Object as PropType<MediaSeoAttributes>,
      default: () => ({}),
    },
    /** Image alternative text */
    alt: {
      type: String,
      default: '',
    },
    /** Either link should be opened in new tab in the browser or not */
    openInNewTab: {
      type: Boolean,
      default: false,
    },
    /** Either link should be opened in new modal window or not */
    openInNewModal: {
      type: Boolean,
      default: false,
    },
    /** Whether to scroll to a component on a page instead of following a link */
    scrollToComponent: {
      type: Boolean,
      default: false,
    },
    /** Link to be applied to anchor wrapping image element */
    link: {
      type: String,
      default: null,
    },
    /** Either image should be lazy loaded or not *TBD* */
    lazy: {
      type: Boolean,
      default: true,
    },
    /** Fixed image width *TBD* */
    width: {
      type: [String, Number],
      default: null,
    },
    /** Fixed image height *TBD* */
    height: {
      type: [String, Number],
      default: null,
    },
    /** (automatic) Data extracted from specified target for analytics purposes */
    dataNavigation: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      AnalyticsDataDomLocation,
    };
  },
  computed: {
    target() {
      return this.openInNewTab ? '_blank' : '';
    },
    component() {
      return this.link ? 'VfLink' : 'div';
    },
  },
  mounted() {
    this.setSvgClass();
  },
  methods: {
    dispatchHtmlEvent() {
      // removed interval and simplified by emitting on load event
      const image = this.$refs.picture.$el.querySelector('img');
      image.dispatchEvent(new Event('clearLoader', { bubbles: true }));
    },
    setSvgClass() {
      const regex = /(https?:\/\/.*\.(svg))$/;
      if (
        regex.test(this.src?.large) ||
        regex.test(this.src?.medium) ||
        regex.test(this.src?.small)
      )
        this.$el.classList.add('vf-image--svg');
    },
  },
});
