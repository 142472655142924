import { render, staticRenderFns } from "./CustomShoesTeaser.vue?vue&type=template&id=a757adc4&scoped=true&"
import script from "./CustomShoesTeaser.vue?vue&type=script&lang=ts&"
export * from "./CustomShoesTeaser.vue?vue&type=script&lang=ts&"
import style0 from "./CustomShoesTeaser.vue?vue&type=style&index=0&id=a757adc4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a757adc4",
  null
  
)

export default component.exports