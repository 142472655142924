











































































































import type { PropType } from 'vue';
import { defineComponent, nextTick } from '@vue/composition-api';
import type { CarouselTranslations } from '@vf/api-contract';

interface ResponsiveOptions {
  small?: string;
  medium?: string;
  large?: string;
}

type Breakpoint = 'small' | 'medium' | 'large';

const DEFAULT_SLIDERS_PER_VIEW = 1;
const DEFAULT_SPACE_BETWEEN = '0px';

export default defineComponent({
  name: 'Carousel',
  provide() {
    return {
      headingLevel: this.titleStyles.level,
      isFullHeight: this.isFullHeight,
    };
  },
  props: {
    translations: {
      type: Object as PropType<CarouselTranslations>,
      default: () => ({}),
    },
    /** Does the carousel display at full height? **/
    isFullHeight: {
      type: Boolean,
      default: false,
    },
    /** Show | Hide navigation arrows **/
    showArrows: {
      type: Boolean,
      default: false,
    },
    controlsType: {
      type: String as PropType<'noControls' | 'dots' | 'thumbnails'>,
      default: 'dots',
    },
    slidesPerView: {
      type: Object as PropType<ResponsiveOptions>,
      default: () => ({}),
    },
    spaceBetween: {
      type: Object as PropType<ResponsiveOptions>,
      default: () => ({}),
    },
    portraitOnly: {
      type: Boolean,
      default: false,
    },
    /** Autoplay slides **/
    autoplay: {
      type: Boolean,
      default: false,
    },
    loop: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    titleStyles: {
      type: Object,
      default: () => ({}),
    },
    dispatchOnly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['index-changed', 'prev-clicked', 'next-clicked'],
  setup(props) {
    // Accessibility Labels
    const aria = props.translations.accessibility;
    const carouselTitle = aria.carouselTitleLabel
      ?.replace('{{title}}', props.title)
      ?.trim();

    return {
      carouselTitle,
    };
  },
  data() {
    return {
      slidesCount: this.$slots.default?.length || 0,
      carouselEl: null,
      shortCarouselHeight: 700, // only used for non-full-height sliders - avg initial height to minimize element jumping around during load
      sliderPaginationCssPosition: 'static', // this is the default
      scrollerInstance: null,
    };
  },
  computed: {
    sliderPaginationPositionStyle() {
      return this.sliderPaginationCssPosition !== 'static'
        ? this.sliderPaginationCssPosition
        : 'static';
    },
    carouselHeight() {
      return this.shortCarouselHeight;
    },
    carouselFullHeight() {
      if (!this.carouselEl) return 0;
      return (
        window.visualViewport.height -
        this.carouselEl.getBoundingClientRect().top
      );
    },
    responsiveOptions() {
      return {
        ...this.getBreakpointOptions('small'),
        ...this.getBreakpointOptions('medium'),
        ...this.getBreakpointOptions('large'),
      };
    },
    currentBreakpointStyles() {
      const breakpoint = this.$viewport.breakpoints[this.$viewport.size];
      return {
        '--slider-gap': this.responsiveOptions[breakpoint].spaceBetween,
        '--slider-items-count': this.responsiveOptions[breakpoint]
          .slidesPerView,
      };
    },
    currentBreakpointSlidesPerView() {
      const breakpoint = this.$viewport.breakpoints[this.$viewport.size];
      return this.responsiveOptions[breakpoint].slidesPerView;
    },
    showPagination() {
      const enabled = this.controlsType === 'dots' || this.controlsType === '';
      return enabled && this.currentBreakpointSlidesPerView < this.slidesCount;
    },
  },
  mounted() {
    this.carouselEl = document.querySelector(
      "[data-testid='theme-smart-carousel']"
    );
  },
  methods: {
    getBreakpointOptions(breakpoint: Breakpoint) {
      // slidesPerView should not be greater than number of slides
      // it should be done automatically as in the CMS slides publish/unpublish could be scheduled
      const slidesPerView =
        (this.slidesPerView[breakpoint] > this.slidesCount
          ? this.slidesCount
          : this.slidesPerView[breakpoint]) || DEFAULT_SLIDERS_PER_VIEW;
      const spaceBetween = this.spaceBetween[breakpoint]
        ? `${this.spaceBetween[breakpoint]}px`
        : DEFAULT_SPACE_BETWEEN;

      return {
        [this.$viewport.breakpoints[breakpoint]]: {
          slidesPerView,
          spaceBetween,
        },
      };
    },
    initSwiper(swiperInstance) {
      if (swiperInstance?.scroller) {
        this.scrollerInstance = swiperInstance.scroller;
      }
    },
    updateActiveSlide() {
      nextTick(() => {
        this.$emit('index-changed', {
          activeItem: this.scrollerInstance.activeItem,
        });
      });
    },
    next() {
      if (this.dispatchOnly) {
        this.$emit('next-clicked');
        return;
      }
      this.scrollerInstance?.next();
    },
    prev() {
      if (this.dispatchOnly) {
        this.$emit('prev-clicked');
        return;
      }
      this.scrollerInstance?.prev();
    },
    slideTo(value) {
      this.scrollerInstance?.scrollToIndex(value);
    },
  },
});
