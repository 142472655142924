import {
  computed,
  onMounted,
  reactive,
  ref,
  watch,
  ComputedRef,
  UnwrapRef,
  Ref,
} from '@nuxtjs/composition-api';
import {
  QuickShopTileTranslations,
  QuickShopTileErrorMessage,
} from '@vf/api-contract';
import { Color, ColorSwatch, Product } from '@vf/api-client';
import { ROUTES, useCart } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import { isProductSegment } from '@vf/composables/src/useUrl/handlers/parseUrl';

export type SelectedItem = {
  color: string;
  size: string;
  length?: string;
  width?: string;
  zip?: string;
};

type Callback = (...args: unknown[]) => unknown;

export type Props = {
  data: Record<string, any>;
  actions: Record<string, Callback>;
};

type UseProductReturn = {
  product: Ref<Partial<Product>>;
  configure: Callback;
};

const prepareColors = (colorsArr) => {
  return colorsArr.map((color) => ({
    available: true,
    description: color.colorHexCode,
    value: color.value,
    label: color.name,
    defaultColor: color.defaultColor,
  }));
};

export const extractDefaultQuickShopData = (
  colors: ColorSwatch[] | Color[]
): UnwrapRef<UseProductReturn['product']> => {
  const colorOptions = prepareColors(colors);
  const defaultColor =
    colorOptions.find((color) => color.defaultColor === true) ??
    colorOptions[0];
  const defaultColorIndex = colorOptions.findIndex(
    (color) => color.value === defaultColor.value
  );

  if (defaultColorIndex > 0) {
    colorOptions.splice(defaultColorIndex, 1);
    colorOptions.unshift(defaultColor);
  }

  return {
    attributes: [
      {
        code: 'color',
        options: colorOptions,
      },
    ],
    color: (defaultColor as unknown) as Product['color'],
  };
};

export const updateProductLinkWithSelectedItem = (
  currentLink: string,
  selectedItem: SelectedItem
): string => {
  let link = currentLink.includes('?')
    ? (currentLink ?? '').substring(0, currentLink.indexOf('?'))
    : currentLink;

  for (const key in selectedItem) {
    if (key === 'color') {
      link += '?color=' + selectedItem.color;
    } else if (selectedItem[key]) {
      link += `&${key}=${selectedItem[key]}`;
    }
  }

  return link;
};

const getAttributesCodes = (selectedItem: SelectedItem, product: Product) => {
  if (!product) return {};
  return product.attributes.reduce((acc, attr) => {
    acc[attr.code] = selectedItem[attr.code];
    return acc;
  }, {});
};

export const checkSelectedProductInStock = (
  selectedItem: SelectedItem,
  product: Product
): boolean => {
  if (!product) {
    return true;
  }

  const attrCodeMap = getAttributesCodes(selectedItem, product);

  const productCodes = Object.keys(attrCodeMap);

  let isAllVariantsSelected = true;

  const attrMap = product.attributes.reduce((acc, item) => {
    const { code, options } = item;

    if (!options?.length) {
      return acc;
    }

    if (productCodes.includes(code)) {
      const value = attrCodeMap[code];

      if (!value) {
        isAllVariantsSelected = false;
      }

      return { ...acc, [code]: value };
    }

    return acc;
  }, {});

  if (!isAllVariantsSelected) {
    return true;
  }

  const variant = product.variants.find(({ attributes }) => {
    const isInStock = [];
    for (const key in attrMap) {
      isInStock.push(attrMap[key] === attributes[key]);
    }

    return isInStock.every((value) => value === true);
  });

  if (!variant) {
    return false;
  }

  return variant.stock.inStock;
};

export const isAnyVariantSelected = (selectedItem: SelectedItem): boolean => {
  // Color is selected by default in quickshop so we need to check that all the other ones are empty
  return !Object.values({ ...selectedItem, color: '' }).every(
    (el) => el === ''
  );
};

export const isAllVariantSelected = (
  selectedItem: SelectedItem,
  product: Product
): boolean => {
  const usedAttributesCode = getAttributesCodes(selectedItem, product);
  const productCodes = Object.keys(usedAttributesCode);
  return productCodes
    ? productCodes.every((el) => usedAttributesCode[el])
    : false;
};

export const createErrorMessage = <ExtendedProduct extends Product>(
  product: Pick<ExtendedProduct, 'attributes'>,
  selectedItem: SelectedItem,
  translations: QuickShopTileTranslations
): QuickShopTileErrorMessage => {
  if (!product) {
    return { role: '', message: '' };
  }
  const code = product.attributes.find((attr) => {
    if (attr.code === 'color') {
      return false;
    }
    return !selectedItem[attr.code];
  })?.code;

  if (!code) {
    return { role: '', message: '' };
  }
  return {
    role: code,
    message:
      translations.errors[
        `select${code[0].toUpperCase() + code.slice(1)}Error`
      ],
  };
};

export const getMiniCartDelayFromRoot = (root: Record<string, any>): number => {
  return (
    root.$themeConfig?.productAddToCart?.closeMiniCartDelay ||
    (root.$viewport?.isSmall ? 6000 : undefined)
  );
};

export const useSetMiniCartWithDelay = (): (() => void) => {
  const { root } = useRootInstance();
  const { setMiniCart } = useCart(root);

  return () => {
    if (!root.$route.path.endsWith(ROUTES.CART())) {
      const closeMiniCartDelay = getMiniCartDelayFromRoot(root);

      setMiniCart(true, closeMiniCartDelay);
    }
  };
};

type UseProductVariantChangeReturn = {
  quickShopData: ComputedRef<UnwrapRef<UseProductReturn['product']>>;
  selectedItem: SelectedItem;
  productLink: ComputedRef<string>;
  handleChange: (optionName: string, selected: string) => void;
};

export const useProductVariantChange = (
  props: Props,
  { product, configure }: UseProductReturn
): UseProductVariantChangeReturn => {
  const quickShopData = computed(() => {
    if (product.value) return product.value;
    if (!product.value?.colors && props.data.colors) {
      return extractDefaultQuickShopData(props.data.colors);
    }
  });

  const selectedItem = reactive({
    color: quickShopData.value?.color?.value ?? '',
    size: '',
    length: '',
    zip: '',
    width: '',
  });

  const productLink = computed(() =>
    updateProductLinkWithSelectedItem(props.data.link, selectedItem)
  );

  const getSelectedVariantBadge = (): string => {
    const selectedColor = props.data.colors?.find(
      (color) => color.value === selectedItem.color
    );

    return selectedColor?.labels ?? null;
  };

  const handleChange = (optionName, selected) => {
    selectedItem[optionName] = selected;

    switch (optionName) {
      case 'color':
        props.actions.changeQuickshopImage(selectedItem.color);

        if (product.value) {
          configure({ color: selectedItem.color });
        }

        props.actions.changeSelectedSwatch({
          link: productLink.value,
          label: getSelectedVariantBadge(),
        });
        break;
      case 'size':
        configure({ color: selectedItem.color, size: selectedItem.size });
        break;
      case 'length':
        configure({ color: selectedItem.color, length: selectedItem.length });
        break;
      case 'zip':
        configure({ color: selectedItem.color, zip: selectedItem.zip });
        break;
      case 'width':
        configure({ color: selectedItem.color, width: selectedItem.width });
        break;
    }
  };

  return {
    quickShopData,
    selectedItem,
    productLink,
    handleChange,
  };
};

type UseVariantPrice = {
  variantRegularPrice: Ref<string>;
  variantSpecialPrice: Ref<string>;
  hasSpecialPrice: Ref<boolean>;
};

export const useVariantPrice = (
  props: Props,
  selectedItem: SelectedItem,
  product: Ref<Product>
): UseVariantPrice => {
  const { root } = useRootInstance();

  const variantRegularPrice = ref('');
  const variantSpecialPrice = ref('');

  const defaultVariant = computed(
    () =>
      props.data.colors?.find((color) => color.defaultColor === true) ||
      props.data.colors?.[0]
  );

  const getDefaultVariantPrice = (type = 'regular'): string => {
    if (!props.data.colors?.length) {
      return type === 'special'
        ? props.data.specialPrice
        : props.data.regularPrice;
    }

    return getProductPrice(defaultVariant.value?.price, type);
  };

  const getSelectedVariantPrice = (type = 'regular'): string => {
    const attributesCode = getAttributesCodes(selectedItem, product.value);
    let selectedColorVariant = product.value?.variants?.find((variant) => {
      return Object.keys(attributesCode).every(
        (key) => attributesCode[key] == variant.attributes[key]
      );
    });
    if (!selectedColorVariant) {
      selectedColorVariant = props.data.colors?.find(
        (color) => color.value === selectedItem.color
      );
    }

    return getProductPrice(selectedColorVariant?.price, type);
  };

  const getProductPrice = (price, type: string): string => {
    if (!price) {
      return type === 'special'
        ? props.data.specialPrice
        : props.data.regularPrice;
    }

    return type === 'special'
      ? root.$formatPrice(price.current, price.currency)
      : root.$formatPrice(price.original, price.currency);
  };

  const hasSpecialPrice = computed(
    () =>
      variantSpecialPrice.value &&
      variantSpecialPrice.value !== variantRegularPrice.value
  );

  onMounted(() => {
    variantRegularPrice.value = getDefaultVariantPrice();
    variantSpecialPrice.value = getDefaultVariantPrice('special');
  });

  watch(
    () => selectedItem,
    () => {
      variantRegularPrice.value = getSelectedVariantPrice();
      variantSpecialPrice.value = getSelectedVariantPrice('special');
    },
    { deep: true }
  );

  return {
    variantRegularPrice,
    hasSpecialPrice,
    variantSpecialPrice,
  };
};

export const retrievePdpColor = (
  from: Record<string, any>,
  productId: string,
  variantId: string
): string => {
  const { path, query } = from;

  const isBackFromPdp = isProductSegment(path);

  if (isBackFromPdp) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, match] = path.match(/-p(\w+)$/);

    if (
      match.toLowerCase() === productId.toLowerCase() &&
      query?.tile === variantId
    ) {
      return query.color;
    }
  }

  throw new Error(
    `Previous page wasn't a PDP page or there's no match for a specified product`
  );
};
