
import type { PropType } from 'vue';
import { defineComponent, watch, ref } from '@vue/composition-api';
import {
  useCart,
  useCms,
  useDynatrace,
  useMonetate,
  useRequestTracker,
} from '@vf/composables';
import { isClient } from '@vf/shared/src/utils/helpers';
import useRootInstance from '@/shared/useRootInstance';
import { finalContentRenderer, useUserData } from '../../cms/cmsUtils';
import type { CmsMonetateExperienceComponentVariant } from '@vf/api-contract';
import PlaceholderBase from '@/components/loaders/Placeholder.vue';
import { PageTypeName } from '@vf/composables/src/useCms/types';
import { useCmsRefStore } from '@vf/composables/src/store/cmsRef';
import { storeToRefs } from 'pinia';

export default defineComponent({
  props: {
    /** Experience ID from Monetate */
    experienceId: {
      type: String,
      default: '',
    },
    /** Baseline component to use in case of any error on Monetate Decision fetching in A/B personalization. Baseline is used as well for products recommendations */
    baseline: {
      type: Object,
      default: null,
    },
    /** Default component to use in case of loading decision. */
    defaultVariant: {
      type: Object,
      default: null,
    },
    variants: {
      type: Array as PropType<CmsMonetateExperienceComponentVariant[]>,
      default: () => [],
    },
    /** (ignore) For internal application usage */
    contextKey: {
      type: String,
      default: '',
    },
    /** (ignore) For internal application usage */
    forcedVariantLabel: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    const cmsRefStore = useCmsRefStore();
    const { root } = useRootInstance();
    const {
      addCustomVars,
      decision,
      loading,
      hasLoaded,
      errored,
      getExperienceDecision,
      extractImpressionReportingDataForGtm,
    } = useMonetate(root);
    const { pageLayoutName, headerConfig } = useCms(root, props.contextKey);
    const { cartItems } = useCart(root);
    const { pushMonetateExperienceRenderedToDynatrace } = useDynatrace(root);
    const { onBaseDone } = useRequestTracker(root);

    const { pageTypeName } = storeToRefs(cmsRefStore);

    const chosenVariantContent = ref(null);
    const showPlaceholder = ref(true);
    const dynatraceActionProperties = ref({
      experienceId: props.experienceId,
      variantId: '',
      variantName: '',
    });

    const getVariantLabel = (monetateDecision) => {
      const actions = monetateDecision.data.responses[0].actions;

      const experienceAction = actions.find(
        (act) =>
          act.impressionReporting[0].experience_id.toString() ===
          props.experienceId
      );
      //** Logging monetate experience id and variant to be able to correctly debug and understand what experience is chosen */
      const prefix = `[@theme/components/smart/monetate/MonetateExperience::getVariantLabel] Monetate decision control: `;
      experienceAction
        ? root.$log.debug(
            `${prefix}${experienceAction.impressionReporting[0].experience_id} - ${experienceAction.impressionReporting[0].variant_label}`
          )
        : root.$log.debug(
            `${prefix}Experience ID <${props.experienceId}> not found`
          );

      if (experienceAction) {
        extractImpressionReportingDataForGtm(experienceAction);
        return experienceAction.impressionReporting[0].variant_label;
      }

      return null;
    };

    const getChosenVariantContent = () => {
      if (loading.value !== 'completed' && errored.value) return props.baseline;

      if (
        loading.value !== 'completed' ||
        (!decision.value && !props.forcedVariantLabel)
      ) {
        return props.defaultVariant;
      }

      try {
        /** Extract chosen variant label, in case of error baseline component will be used */
        let chosenVariantLabel =
          props.forcedVariantLabel || getVariantLabel(decision.value);

        if (chosenVariantLabel === 'Control') {
          return null;
        }

        if (chosenVariantLabel === 'Experiment') {
          chosenVariantLabel = 'A';
        }
        if (chosenVariantLabel) {
          /** Search for chosen variant label in variants array from CMS */
          const variantData = props.variants.find(
            (variant) =>
              variant.label === chosenVariantLabel ||
              variant.label[0] === chosenVariantLabel[0]
          );

          /** If chosen variant exists within CMS data - assign it to current value */
          if (variantData && variantData.content) {
            dynatraceActionProperties.value.variantId = variantData.id;
            dynatraceActionProperties.value.variantName = variantData.label;
            return variantData.content;
          }
        }

        return props.baseline;
      } catch (err) {
        root.$log.warn(
          `[@theme/components/smart/monetate/MonetateExperience::getChosenVariantContent] Error occurred while fetching decision:`,
          err
        );

        return props.baseline;
      }
    };

    watch(
      () => loading.value,
      () => (chosenVariantContent.value = getChosenVariantContent()),
      { immediate: true }
    );

    const hasLoadedUnwatch = watch(hasLoaded, () => {
      if (hasLoaded.value) {
        showPlaceholder.value = false;
        hasLoadedUnwatch();
      }
    });

    onBaseDone(() => {
      if (!isClient) {
        return;
      }
      if (pageTypeName.value === PageTypeName.CART) {
        watch(cartItems, async () => {
          const userData = useUserData(root);
          addCustomVars(userData);
          await getExperienceDecision(true);
        });
      }
    });

    watch(hasLoaded, () => {
      if (!hasLoaded.value) return;
      emit('third-party-loaded');

      pushMonetateExperienceRenderedToDynatrace(
        dynatraceActionProperties.value
      );
    });

    return {
      loading,
      hasLoaded,
      isClient,
      chosenVariantContent,
      pageLayoutName,
      headerConfig,
      showPlaceholder,
    };
  },
  render(createElement) {
    if (!this.chosenVariantContent) return null;
    /** Use content chosen by Monetate to render component */

    const Placeholder = this.showPlaceholder
      ? createElement(PlaceholderBase, {
          props: {
            className: 'vf-monetate__placeholder',
            loading: this.loading,
            hasLoaded: this.hasLoaded,
            hasAnimation: this.$themeConfig?.placeholder?.placeholderAnimation,
          },
        })
      : null;

    const Content = finalContentRenderer(
      createElement,
      this.pageLayoutName,
      this.$props.contextKey,
      [this.chosenVariantContent],
      null,
      this.$root,
      [],
      this.headerConfig
    );

    const experienceClassName = `vf-monetate-experience-${this.$props.experienceId}`;

    return createElement(
      'div',
      {
        class: {
          'vf-monetate': true,
          [experienceClassName]: true,
        },
      },
      [
        createElement('client-only', [
          Content,
          Placeholder,
          createElement(
            'template',
            {
              slot: 'placeholder',
            },
            [Placeholder]
          ),
        ]),
      ]
    );
  },
});
