










import { defineComponent } from '@vue/composition-api';
import { Section, Grid } from '@gemini/pollux';

export default defineComponent({
  name: 'GeminiSectionGrid',
  components: {
    'gemini-section-grid': Section,
    'gemini-grid': Grid,
  },
  props: {
    pattern: {
      type: String,
    },
    wrapper: {
      type: String,
    },
    heading: {
      type: String,
    },
    copy: {
      type: String,
    },
    ctaCopy: {
      type: String,
    },
    ctaUrl: {
      type: String,
    },
    ctaHasIcon: {
      type: Boolean,
    },
    gridProps: {
      type: Object,
    },
  },
});
