

























































































































import type { PropType } from 'vue';
import { onMounted, ref, defineComponent } from '@vue/composition-api';
import type { CustomProductSlideTranslations } from '@vf/api-contract';
import { useCustoms, useGtm, useI18n, ROUTES } from '@vf/composables';
import { lazyImage } from '@vf/shared/src/utils/directives';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'CustomsProductSlide',
  directives: { lazyImage },
  inject: ['isFullHeight'],
  props: {
    id: { type: String, default: 'customs-product-slide' },
    location: { type: String, default: '' },
    defaultPicture: { type: Object, default: () => ({}) },
    authorName: { type: String, default: '' },
    recipeId: { type: String, default: '' },
    title: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    translations: {
      type: Object as PropType<CustomProductSlideTranslations>,
      default: () => ({}),
    },
    isFullHeight: { type: Boolean, default: true },
    /** Where do the three dots show up (Bottom | Between Image and Text) **/
    controlsPosition: {
      type: String,
      default: 'bottom',
    },
    hideBuyCTA: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { localePath } = useI18n(root);
    const { customsDataByRecipe, customsImage } = useCustoms(root);
    const image3D = ref('');
    const isErrorVisible = ref(false);
    const buttonText = ref(props.translations.buyCta.replace('{{price}}', ''));
    const productData = ref<Record<string, unknown>>({});
    const isButtonDisabled = ref(true);
    const { dispatchEvent } = useGtm(root);
    const config = root.$themeConfig?.carousel || false;

    const carouselSlideRoleDescription = props.translations.accessibility.carouselSlideDescription
      .replace('{{inspiredBy}}', props.translations.inspiredBy)
      .replace('{{authorName}}', props.authorName)
      .trim();

    const dispatchHtmlEvent = () => {
      const img = document.querySelector(
        '.vf-customs-product-slide__media--image'
      );
      if (img) {
        img.dispatchEvent(
          new CustomEvent('customsSlideImageLoaded', {
            bubbles: true,
            cancelable: false,
          })
        );
      }
    };

    onMounted(async () => {
      try {
        const image = await customsImage({
          recipe: props.recipeId,
        });
        image3D.value = image;

        const product = await customsDataByRecipe(props.recipeId);
        const priceTxt = product?.priceInDecimal
          ? root.$formatPrice(product.priceInDecimal, product.currency)
          : '';
        buttonText.value = props.translations.buyCta.replace(
          '{{price}}',
          priceTxt
        );
        productData.value = { ...product, image };

        if (!product?.priceInDecimal || !image || !productData?.value?.pdm) {
          isButtonDisabled.value = true;
        }

        isButtonDisabled.value = false;
      } catch (error) {
        console.error(error);
        isErrorVisible.value = true;
        isButtonDisabled.value = true;
      }
    });

    const openCustomizer = (eventLabel) => {
      if (productData?.value?.pdm && props.recipeId) {
        root.$router.push(
          localePath(
            `${ROUTES.CUSTOMIZER()}.${productData.value.pdm}.html?recipe=${
              props.recipeId
            }`
          )
        );
      }
      if (config.dispatchGtmEvents) {
        dispatchEvent({
          eventName: 'loadEventData',
          overrideAttributes: {
            eventCategory: 'Customs',
            eventAction: 'Add Your Touch',
            eventLabel: eventLabel.trim(),
            nonInteractive: false,
            customMetrics: {},
            customVariables: {},
            _clear: true,
          },
        });
      }
    };

    return {
      image3D,
      buttonText,
      openCustomizer,
      isButtonDisabled,
      isErrorVisible,
      productData,
      dispatchHtmlEvent,
      carouselSlideRoleDescription,
    };
  },
});
