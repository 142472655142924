










export default {
  name: 'PaymentMethodSummary',
  props: {
    icon: {
      type: Object,
      default: () => ({
        name: '',
        width: 0,
        height: 0,
      }),
    },
    label: String,
  },
};
