
























































import { computed, defineComponent, inject } from '@vue/composition-api';
import { replaceAll } from '@/helpers/replaceAll';
import type { BrandifyStoreInfo } from '@vf/api-client';
import type { PropType } from 'vue';
import type { Ref } from '@nuxtjs/composition-api';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'StoreConfirmation',
  props: {
    selectedStore: {
      type: Object as PropType<BrandifyStoreInfo>,
    },
    groupedPickupItems: {
      type: Array as PropType<any[]>,
      required: true,
    },
  },
  emits: ['submit', 'close'],
  setup(props) {
    const { root } = useRootInstance();
    const isCoreRedesignEnabled = inject<Ref<boolean>>('isCoreRedesignEnabled');

    const hasOutOfStock = computed(
      () =>
        props.groupedPickupItems.find(([key]) => key === 'outOfStockItems')
          ?.length > 0
    );

    // TODO: GLOBAL15-61059 remove (and move to theme config) after core redesign
    const buttonConfig = computed(() => {
      if (root.$config.theme === 'vans') {
        if (isCoreRedesignEnabled.value) {
          return { color: 'secondary' };
        } else {
          return { color: 'inverse', variant: 'border' };
        }
      } else {
        return { color: 'primary', variant: 'border' };
      }
    });

    return {
      hasOutOfStock,
      buttonConfig,
      replaceAll,
    };
  },
});
