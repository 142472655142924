


































































































import type { PropType } from 'vue';
import { computed, defineComponent, inject } from '@vue/composition-api';
import { Context, ProductGalleryTranslations } from '@vf/api-contract';
import { PageTypeName } from '@vf/composables/src/useCms/types';
import { useGtm, useNotification, useProduct } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import { useFavoritesWithDataLayer } from '@/shared/useFavoritesWithDataLayer';
import CustomsLogo from '@/components/CustomsLogo.vue';
import { useCmsRefStore } from '@vf/composables/src/store/cmsRef';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'ProductGallery',
  components: {
    CustomsLogo,
  },
  props: {
    /**Flag to determine which gallery layout view component to show */
    gridLayout: {
      type: Boolean,
      default: false,
    },
    /** Flag to determine whether to show badges from SFCC or not */
    showBadges: {
      type: Boolean,
      default: true,
    },
    /** Which element in gallery to select by default */
    sequenceNumber: {
      type: Number,
      default: 0,
    },
    /** Flag to determine whether to show Wish List button or not */
    showWishListIcon: {
      type: Boolean,
      default: true,
    },
    /** Flag to determine whether to show navigation dots */
    dotsNavigationEnabled: {
      type: Boolean,
      default: true,
    },
    /** Select with page type */
    contextKey: {
      type: String,
      default: 'product',
    },
    translations: {
      type: Object as PropType<ProductGalleryTranslations>,
      default: () => ({}),
    },
    lazy: {
      type: Boolean,
      default: false,
    },
    hideControls: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const cmsRefStore = useCmsRefStore();
    const { root } = useRootInstance();
    // TODO: GLOBAL15-61059 remove after redesign core
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');

    const { isVansPdpRedesignEnabled } = useFeatureFlagsStore();

    const {
      pdpImages,
      product,
      isQuickShopContext,
      checkAttributes,
      scrollToFirstValidationError,
      loadPdpGalleryImages,
    } = useProduct(root, props.contextKey);
    const { dispatchEvent } = useGtm(root);
    const { badges } = storeToRefs(cmsRefStore);
    const { addNotification, clearNotifications } = useNotification(root);

    const { toggleFavorites, isFavorite, loading } = useFavoritesWithDataLayer(
      PageTypeName.PDP
    );

    const theme = root.$themeConfig.productGallery;

    const maxGridImageCount = computed(() =>
      root.$viewport?.isSmall
        ? theme.maxGridImageCountMobile
        : theme.maxGridImageCountDesktop
    );

    const images = computed(() => {
      return maxGridImageCount.value
        ? pdpImages.value.slice(0, maxGridImageCount.value)
        : pdpImages.value;
    });

    const favouriteIcon = computed(() =>
      isFavorite(product.value.id) ? 'heart__fill' : 'heart'
    );

    const pushGtmEventImageClick = (index) => {
      dispatchEvent({
        eventName: 'loadEventData',
        overrideAttributes: {
          eventCategory: 'PDP',
          eventAction: 'Image Click',
          eventLabel: index + 1,
        },
      });
    };

    const pushGtmEventFullScreenOpen = (index) => {
      dispatchEvent({
        eventName: 'loadEventData',
        overrideAttributes: {
          eventCategory: 'PDP',
          eventAction: 'Full Image Click',
          eventLabel: `${product.value.name} | ${index + 1}`,
        },
      });
    };

    const pushGtmEventImageZoom = ({ zoomScale, index, reset }) => {
      const zoomLabel = reset ? 'Reset Zoom' : `${zoomScale}%`;
      dispatchEvent({
        eventName: 'loadEventData',
        overrideAttributes: {
          eventCategory: 'PDP',
          eventAction: 'Image Zoom',
          eventLabel: `${zoomLabel} | ${index + 1}`,
        },
      });
    };
    const badge = computed(() => {
      const { labels } = product.value;

      if (labels?.[0]) {
        return badges.value[labels[0]] || null;
      }

      return null;
    });

    const scrollToError = () => {
      if (theme.scrollToError) {
        scrollToFirstValidationError(theme.scrollToErrorOffset, true);
      }
    };

    const toggleFavoritesHandler = async (product) => {
      clearNotifications();

      if (checkAttributes()) {
        scrollToError();
        return;
      }

      const result = await toggleFavorites(product);

      if (result === true) {
        const message = props.translations.addedToFavoritesNotification?.replace(
          '{{product}}',
          product.name
        );

        addNotification({
          modifiers: 'scrollToNotification',
          errorMessageId: null,
          message,
          type: 'success',
        });
      }

      return result;
    };

    const configuredColor = root.$route.query?.color as string;

    if (configuredColor && !isQuickShopContext.value) {
      loadPdpGalleryImages({
        isBackgroundRequest: false,
        configuredColor,
        allowColorOutsideOfVariants: props.contextKey === Context.QuickShop,
      });
    }

    return {
      theme,
      images,
      isQuickShopContext,
      product,
      badge,
      favouriteIcon,
      onFavoriteClickLoading: loading,
      pushGtmEventImageClick,
      pushGtmEventFullScreenOpen,
      pushGtmEventImageZoom,
      isFavorite,
      toggleFavoritesHandler,
      isCoreRedesignEnabled,
      isVansPdpRedesignEnabled,
    };
  },
  computed: {
    showCustomsLogo() {
      return (
        this.$root.$themeConfig.productGallery.showCustomsLogo &&
        this.product?.dummyCustoms
      );
    },
  },
});
