






























import type { PropType } from 'vue';
import { computed, ref, defineComponent, watch } from '@vue/composition-api';
import type { CouponItem } from '@vf/api-client';
import type { CheckoutPromoCodeTranslations } from '@vf/api-contract';
import {
  useCart,
  useCheckout,
  useNotification,
  useI18n,
} from '@vf/composables';
import PromoCode from '@/components/payment/PromoCode.vue';
import PromoCodeWithSingleValue from '@/components/payment/PromoCodeWithSingleValue.vue';
import {
  isCouponNotApplicableToCart,
  getCouponLabel,
  getCouponClass,
  getCouponDiscountAmount,
} from '@/helpers';
import useRootInstance from '@/shared/useRootInstance';
import { errorMessages } from '@vf/composables/src/utils/errorMessages';

export default defineComponent({
  name: 'CheckoutPromoCode',
  components: {
    PromoCode,
    PromoCodeWithSingleValue,
  },
  props: {
    translations: {
      type: Object as PropType<CheckoutPromoCodeTranslations>,
      required: true,
    },
    accordionOpened: Boolean,
    currencySymbol: String,
    useMultiplePromoCodes: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { cart, updateCart, hasItems: cartHasItems } = useCart(root);
    const { applyPromoCode, deletePromoCode } = useCheckout(root);
    const { addNotification, clearNotifications } = useNotification(root);
    const { getErrorDetails } = errorMessages(root);
    const { getStaticTranslation } = useI18n(root);

    const promoCodeInvalidMessage = ref('');

    const theme = root.$themeConfig.checkoutSidebar;

    const hasActivePromoCodes = computed(
      () => !!cart.value.couponItems?.length
    );
    const firstActivePromoCode = computed(() =>
      hasActivePromoCodes.value ? cart.value.couponItems[0] : null
    );

    const isAccordionOpen = ref(
      props.accordionOpened || hasActivePromoCodes.value
    );
    const code = ref(
      props.useMultiplePromoCodes
        ? null
        : firstActivePromoCode.value?.code ?? null
    );

    if (!props.useMultiplePromoCodes) {
      watch(firstActivePromoCode, (newFirstActivePromoCode) => {
        code.value = newFirstActivePromoCode
          ? newFirstActivePromoCode.code
          : null;
      });
    }

    const getNotificationMessage = (resStatus, couponNotApplicableToCart) => {
      if (resStatus !== 200) return props.translations.notifications.error;
      if (couponNotApplicableToCart)
        return props.translations.notifications.appliedNotApplicable;
      return props.translations.notifications.applied;
    };

    const getNotificationType = (resStatus, couponNotApplicableToCart) => {
      if (resStatus !== 200) return 'danger';
      if (couponNotApplicableToCart) return 'info';
      return 'success';
    };

    const clearCodeValue = () => (code.value = null);

    const applyPromoCodeTrigger = async (
      promoCode,
      showErrorMessage = true
    ) => {
      clearNotifications();
      promoCodeInvalidMessage.value = '';

      const res = await applyPromoCode(promoCode, showErrorMessage);

      if (!res) {
        return;
      }

      if (res.status !== 200) {
        const errorDetails = getErrorDetails(res.data?.errorDetails)[0]
          ?.errorMessageId;
        promoCodeInvalidMessage.value = getStaticTranslation('muleSoftErrors')[
          errorDetails
        ];
        return;
      }

      const couponNotApplicableToCart = isCouponNotApplicableToCart(
        promoCode,
        res.data?.couponItems
      );

      addNotification({
        message: getNotificationMessage(res.status, couponNotApplicableToCart),
        type: getNotificationType(res.status, couponNotApplicableToCart),
      });

      if (res.status === 200) {
        updateCart(res.data);
      }
    };

    const applyPromoCodeAndClearCodeValueTrigger = async () => {
      await applyPromoCodeTrigger(code.value);
      clearCodeValue();
    };

    const removePromoCode = async (couponItemId) => {
      clearNotifications();
      const res = await deletePromoCode(couponItemId);
      if (!res) return;

      addNotification({
        message:
          res.status === 200
            ? props.translations.notifications.removed
            : props.translations.notifications.error,
        type: res.status === 200 ? 'success' : 'danger',
      });

      if (res.status === 200) {
        let dataToUpdate = res.data;
        if (!('couponItems' in dataToUpdate)) {
          dataToUpdate.couponItems = [];
        }
        updateCart(dataToUpdate);
      }
    };

    const deleteFirstPromoCodeAndClearCodeValueTrigger = async () => {
      if (!hasActivePromoCodes.value) return;
      await removePromoCode(firstActivePromoCode.value.couponItemId);
      clearCodeValue();
    };

    const coupons = computed(() =>
      (cart.value?.couponItems ?? []).map((coupon: CouponItem) => ({
        ...coupon,
        label: {
          title: getCouponLabel(coupon, props.translations),
          className: getCouponClass(coupon),
        },
        promoPrice: Math.abs(getCouponDiscountAmount(coupon.code, cart.value)),
      }))
    );

    return {
      theme,
      code,
      isAccordionOpen,
      isPromoCodeApplied: hasActivePromoCodes,
      coupons,
      applyPromoCodeTrigger,
      applyPromoCodeAndClearCodeValueTrigger,
      removePromoCode,
      deleteFirstPromoCodeAndClearCodeValueTrigger,
      cartHasItems,
      currency: cart.value.currency,
      promoCodeInvalidMessage,
    };
  },
});
