













import useRootInstance from '@/shared/useRootInstance';
import {
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
} from '@vue/composition-api';
import { isSafari } from '@vf/shared/src/utils/helpers';
import WriteReviewWidget from '@/components/WriteReviewWidget.vue';

export default defineComponent({
  name: 'WriteReviewModal',
  components: {
    WriteReviewWidget,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    pageId: {
      type: String,
      default: '',
    },
    pageIdVariant: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const { root } = useRootInstance();
    const modalPaddingBottom = ref('0');

    const onCloseModal = () => {
      emit('close');
      modalPaddingBottom.value = '0';
    };

    let busy = false;
    const calculatePaddingBasedOnKeyboard = () => {
      if (busy) return;
      busy = true;

      requestAnimationFrame(() => {
        modalPaddingBottom.value = `${
          window.innerHeight -
          (visualViewport.height + visualViewport.offsetTop)
        }px`;
        busy = false;
      });
    };

    const isSafariMobileWithVisualViewport = () =>
      'visualViewport' in window &&
      isSafari() &&
      root.$viewport.isSmall &&
      root.$viewport.isMobileDevice;

    onMounted(() => {
      if (isSafariMobileWithVisualViewport()) {
        // resolve safari virtual keyboard overlap
        visualViewport.addEventListener(
          'resize',
          calculatePaddingBasedOnKeyboard
        );
      }
    });

    onUnmounted(() => {
      if (isSafariMobileWithVisualViewport()) {
        visualViewport.removeEventListener(
          'resize',
          calculatePaddingBasedOnKeyboard
        );
      }
    });

    return {
      modalPaddingBottom,
      onCloseModal,
    };
  },
});
