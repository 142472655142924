var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vf-input",class:{
    'vf-input--has-text': !!_vm.value || (_vm.value && _vm.value.toString() === '0'),
    'vf-input--invalid': !_vm.valid,
    'vf-input--tooltip': _vm.showTooltip,
    'vf-input--disabled': _vm.disabled,
    'vf-input--hidden': _vm.type === 'hidden',
    'vf-input--redesign-core': _vm.isCoreRedesignEnabled,
    'vf-input--has-label': _vm.label,
  }},[_c('div',{staticClass:"vf-input__wrapper",class:{ 'vf-input__wrapper-icon': !!_vm.icon }},[(_vm.label)?[_c('label',{class:_vm.labelClasses,attrs:{"for":_vm.inputName}},[_vm._t("label",function(){return [_vm._v(_vm._s(_vm.label))]},null,{ label: _vm.label }),(_vm.required)?_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("*")]):_vm._e()],2)]:_vm._e(),_vm._v(" "),_c('input',_vm._g(_vm._b({directives:[{name:"focus",rawName:"v-focus"}],class:{
        'vf-input--is-password': _vm.isPassword,
        'vf-input--extra-padding':
          _vm.validationIconsVisibility && !_vm.isPassword && !_vm.$attrs.placeholder,
        'vf-input--small-extra-padding': !!_vm.$attrs.placeholder,
      },attrs:{"id":_vm.inputName,"aria-required":_vm.required ? 'true' : undefined,"required":_vm.required,"disabled":_vm.disabled,"aria-label":_vm.ariaLabel,"aria-invalid":!_vm.valid,"aria-describedby":_vm.errorMessageId,"name":_vm.inputName,"type":_vm.inputType},domProps:{"value":_vm.value},on:{"focus":_vm.focusHandler,"blur":_vm.blurHandler}},'input',_vm.$attrs,false),_vm.listeners)),_vm._v(" "),(_vm.validationIconsVisibility)?_vm._t("action-icon",function(){return [(
          (_vm.notification || _vm.hasNotificationContent) &&
          (_vm.stickyTooltip || (_vm.valid && !_vm.showSuccessIcon))
        )?_c('VfTooltip',{staticClass:"vf-input__tooltip vf-input__tooltip-absolute",class:{
          'vf-input__tooltip-sticky':
            _vm.stickyTooltip && (!_vm.valid || _vm.showSuccessIcon),
        },attrs:{"is-visible":_vm.showTooltip,"close-text":_vm.tooltipCloseText},on:{"click-close":function($event){_vm.showTooltip = false},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.showTooltip = !_vm.showTooltip}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._t("notificationContent",function(){return [_c('VfText',{attrs:{"content":_vm.notification}})]})]},proxy:true}],null,true)},[_c('button',{staticClass:"vf-input__icon vf-input__icon--info",attrs:{"aria-label":"Info","type":"button"},on:{"click":function($event){_vm.showTooltip = !_vm.showTooltip}}},[_c('VfIcon',{attrs:{"icon":_vm.infoIcon}})],1)]):_vm._e(),_vm._v(" "),(_vm.showSuccessIcon)?_c('VfIcon',{staticClass:"vf-input__icon vf-input__icon--success",attrs:{"icon":"correct"}}):(!_vm.valid)?_c('VfIcon',{staticClass:"vf-input__icon vf-input__icon--error",attrs:{"icon":"error"}}):_vm._e()]}):_vm._e(),_vm._v(" "),(_vm.isPassword)?_c('UiLink',{staticClass:"vf-input__toggle-password-button",class:{
        'vf-input__toggle-password-button--text-with-right-icon':
          _vm.hasPasswordVisibilityToggle && _vm.isRightIconVisible,
        'vf-input__toggle-password-button-no-margin': !_vm.validationIconsVisibility,
        'vf-input__toggle-password-button--has-sticky-tooltip': _vm.stickyTooltip,
        'vf-input__toggle-password-button--has-sticky-tooltip-with-right-icon':
          _vm.stickyTooltip && (!_vm.valid || _vm.showSuccessIcon),
      },attrs:{"aria-label":"switch-visibility-password","aria-pressed":_vm.isPasswordVisible.toString()},on:{"click":_vm.togglePasswordVisibility}},[(_vm.hasPasswordVisibilityToggle)?_c('span',[_vm._v("\n        "+_vm._s(_vm.isPasswordVisible ? _vm.hidePasswordText : _vm.showPasswordText)+"\n      ")]):_c('VfIcon',{staticClass:"vf-input__toggle-password-icon",class:{
          'vf-input__toggle-password-icon--hidden': _vm.isPasswordVisible,
        },attrs:{"icon":"show_password"}})],1):_vm._e(),_vm._v(" "),(!!_vm.icon)?_c('div',{staticClass:"vf-input__chevron-right-icon-wrapper"},[_c('VfIcon',{attrs:{"icon":_vm.icon,"color":"white","size":_vm.iconSize}})],1):_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"vf-input__error-message"},[_c('transition',{attrs:{"name":"fade"}},[(!!_vm.errorMessage && !_vm.valid)?_vm._t("error-message",function(){return [_c('div',{attrs:{"id":_vm.errorMessageId,"role":"alert"}},[_vm._v(_vm._s(_vm.errorMessage))])]},null,{ errorMessage: _vm.errorMessage }):_vm._e()],2)],1),_vm._v(" "),_c('div',{staticClass:"vf-input__helper-text"},[_c('transition',{attrs:{"name":"fade"}},[(!!_vm.helperText && _vm.valid)?_vm._t("helper-text",function(){return [_c('div',[_vm._v(_vm._s(_vm.helperText))])]},null,{ helperText: _vm.helperText }):_vm._e()],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }