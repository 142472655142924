






























import { defineComponent, computed } from '@vue/composition-api';
import { useCategory, useSearch, useShippingFilter } from '@vf/composables';
import { PdpShippingMethod } from '@vf/composables/src/store/cartStore';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'ProductsListNoResults',
  props: {
    contextKey: {
      type: String,
      default: '',
    },
    /** True if provided content should be rendered as HTML. False for normal text */
    html: {
      type: Boolean,
      default: false,
    },
    /** Title text or RichText object html */
    title: {
      type: String,
      default: 'No results',
    },
    /** Subtitle text */
    subtitle: {
      type: String,
      default: 'We did not find any products.',
    },
    /** Title styles object */
    titleStyles: {
      type: Object,
      default: () => ({
        color: '',
        fontSize: '',
        fontFamily: '',
        fontWeight: '',
      }),
    },
    /** SubTitle styles object */
    subtitleStyles: {
      type: Object,
      default: () => ({
        color: '',
        fontSize: '',
        fontFamily: '',
        fontWeight: '',
      }),
    },
    /** True if component is used in PLP */
    isPlp: {
      type: Boolean,
      default: false,
    },
    /** show line separator */
    showSeparator: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { pagination } = props.isPlp
      ? useCategory(root, props.contextKey)
      : useSearch(root);
    const { selected: shippingFilterSelected } = useShippingFilter(root);

    const isEmpty = computed(() => pagination.value.total === 0);
    const isPickup = computed(
      () => shippingFilterSelected.value === PdpShippingMethod.Pickup
    );
    return {
      isEmpty,
      isPickup,
    };
  },
});
