






























import { defineComponent, onMounted, ref, watch } from '@vue/composition-api';
import { useUtilities } from '@vf/composables';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'AddressSummary',
  serverCacheKey: getCacheKeyFromProps,
  props: {
    /** Address data to render */
    address: {
      type: Object,
      default: () => ({}),
    },
    /** Shorter format of address data  */
    shortFormat: {
      type: Boolean,
      default: false,
    },
    /** Property to show zip code in one line with city and state */
    showZipCodeWithState: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { getStateName } = useUtilities(root);
    const stateName = ref(props.address.province);

    const setStateName = async () => {
      try {
        stateName.value = await getStateName(
          props.address.province,
          props.address.country
        );
      } catch (err) {
        root.$log.error(
          '[@components/AddressSummary::setStateName]',
          err.message || err
        );
      }
    };

    watch(() => props.address.province, setStateName);

    onMounted(setStateName);

    return { stateName };
  },
});
