


























































































import type { PropType } from 'vue';
import {
  computed,
  defineComponent,
  onMounted,
  ref,
} from '@vue/composition-api';
import { useCart, useI18n } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import type { ShippingGroup } from '@vf/api-contract';
import {
  getCacheKeyFromProps,
  getDeliveryDateLabel,
} from '@vf/shared/src/utils/helpers';
import { isValid, parseISO } from 'date-fns';

export default defineComponent({
  name: 'ShippingGroupSection',
  serverCacheKey: getCacheKeyFromProps,
  props: {
    /** Shipping Group data */
    shippingGroup: {
      type: [Object, Array] as PropType<ShippingGroup>,
      default: () => [],
    },
    selectedCode: {
      type: String,
      default: '',
    },
    showRadio: {
      type: Boolean,
      default: true,
    },
    translations: {
      type: Object,
      required: true,
    },
    /** Flag to determine if address contains PO box */
    hasPoAddress: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup(props) {
    // TODO: Remove it after GLOBAL15-56318
    const { root } = useRootInstance();
    const {
      getShippingMethods,
      getShippingGroupFormattedPrice,
      itemsToShip,
    } = useCart(root);
    const { localeCode, localeMapping, getStaticTranslation } = useI18n(root);
    const localeData = {
      localeCode: localeCode(),
      localeMapping: localeMapping(),
    };

    const narvarTranslations = getStaticTranslation('narvar');

    const showTooltip = ref(false);
    const isDeliveryDateValid = (dateString: string): boolean => {
      if (typeof dateString === 'undefined') {
        return false;
      }
      const [minDateString, maxDateString] = dateString.split(',');
      return isValid(parseISO(maxDateString ?? minDateString));
    };

    onMounted(() => {
      if (
        props.shippingGroup.shippingId &&
        props.shippingGroup.methods.length === 0
      ) {
        getShippingMethods(props.shippingGroup.shippingId, {
          isBackgroundRequest: false,
          isTemporary: false,
        });
      }
    });

    const customsShippingText = computed(() => {
      return props.hasPoAddress
        ? props.translations.fedExDeliverPOBox
        : props.translations.shippingTimes;
    });

    return {
      showTooltip,
      itemsToShip,
      getShippingGroupFormattedPrice,
      getDeliveryDateLabel,
      isDeliveryDateValid,
      customsShippingText,
      localeData,
      narvarTranslations,
    };
  },
});
