import { ComponentInstance } from '@vf/composables';

type SendToCustomsPayload = {
  command: string;
  body: object;
};

type SendToCustomsConfig = {
  componentIntegration: boolean;
};

const sendToCustoms = (
  instance: ComponentInstance,
  payload: SendToCustomsPayload,
  { componentIntegration }: SendToCustomsConfig
): void => {
  if (componentIntegration) {
    instance.$eventBus.$emit(payload.command, { ...payload });
  } else {
    instance.$vfIframeCommunicator.postMessage(payload);
  }
};

export default sendToCustoms;
