







































































import {
  defineComponent,
  ref,
  onMounted,
  onUnmounted,
  onBeforeUnmount,
  nextTick,
} from '@vue/composition-api';
import {
  ROUTES,
  useAuthentication,
  useLoyalty,
  useReCaptcha,
} from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import useModal from '@/shared/useModal';
import { useSimplifiedEnrollmentStore } from '@vf/composables/src/store/simplifiedEnrollment';
import { storeToRefs } from 'pinia';
import { getRedirectUrlIfSafe } from '@vf/composables/src/utils/getRedirectUrlIfSafe';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

import LoyaltyLogin from '@/components/smart/loyalty/LoyaltyLogin.vue';
import { Context } from '@vf/api-contract';

import SimplifiedEnrollmentEmailIntake from './SimplifiedEnrollmentEmailIntake.vue';
import SimplifiedEnrollmentPasswordIntake from './SimplifiedEnrollmentPasswordIntake.vue';
import SimplifiedEnrollmentConfirmation from './SimplifiedEnrollmentConfirmation.vue';
import SimplifiedEnrollmentHeader from '@/components/static/simplifiedEnrollment/SimplifiedEnrollmentHeader.vue';
import SimplifiedEnrollmentNotLoyaltyMember from './SimplifiedEnrollmentNotLoyaltyMember.vue';
import { useUserStore } from '@vf/composables/src/store/user';

enum Steps {
  EmailIntake,
  PasswordIntake,
  Login,
  WelcomeScreen,
  SignUpNonLoyaltyMember,
}

export default defineComponent({
  name: 'SimplifiedEnrollment',
  components: {
    SimplifiedEnrollmentHeader,
    LoyaltyLogin,
    SimplifiedEnrollmentEmailIntake,
    SimplifiedEnrollmentPasswordIntake,
    SimplifiedEnrollmentConfirmation,
    SimplifiedEnrollmentNotLoyaltyMember,
  },
  setup() {
    const { root } = useRootInstance();
    const { emailLookup } = useAuthentication(root);
    const { showBadge, hideBadge } = useReCaptcha(root);
    const { closeModal } = useModal();
    const contextKey = Context.Modal;
    const seStore = useSimplifiedEnrollmentStore(root);
    const { isPartiallyEnrolled, showGoBackButton } = storeToRefs(seStore);
    const { isCheckoutSuccessRedesignEnabled } = useFeatureFlagsStore();
    const userStore = useUserStore(root);

    const disabled = ref(false);
    const initialMail = ref('');
    const step = ref(Steps.EmailIntake);

    const goToInitialStep = () => {
      showGoBackButton.value = false;
      step.value = Steps.EmailIntake;
    };

    onMounted(() => {
      showBadge();

      if (!checkIfOpenedFromMailOrProfile()) trackGoBackButton();
    });

    onBeforeUnmount(() => {
      // get rid of query parameter triggering this modal,
      // unless customer has just signed in and there is a redirect URL given
      const { sign_in, sign_up, redirectTo, ...query } = root.$route.query;
      if (
        (userStore.loggedIn && redirectTo) ||
        (sign_in === undefined && sign_up === undefined)
      ) {
        return;
      }
      root.$router.replace({ query });

      root.$eventBus.$off('modalGoBack', goToInitialStep);
    });

    onUnmounted(() => {
      showGoBackButton.value = false;
      hideBadge();
    });

    const trackGoBackButton = () =>
      root.$eventBus.$on('modalGoBack', goToInitialStep);

    const checkIfOpenedFromMailOrProfile = (): boolean => {
      const isAccountProfile = root.$route?.path?.endsWith(
        ROUTES.ACCOUNT_PROFILE()
      );

      if (isAccountProfile) {
        step.value = Steps.SignUpNonLoyaltyMember;
        return true;
      }

      // handle link from retail enrollment welcome email
      const isOpenedFromMail = root.$route?.query?.sign_up === '1';

      const hasEmailInURL = Boolean(root.$route?.query?.email);

      if (isOpenedFromMail && hasEmailInURL) {
        step.value = Steps.PasswordIntake;
        return true;
      }

      return false;
    };

    const checkForRedirect = () => {
      const isOrderConfirmation = root.$route.path?.endsWith(
        ROUTES.CHECKOUT_ORDER_STATUS()
      );

      if (isCheckoutSuccessRedesignEnabled && isOrderConfirmation) {
        root.$router.push(root.localePath(ROUTES.HOME()));
        return;
      }

      const redirect = getRedirectUrlIfSafe(
        root.$route.query.redirectTo as string
      );

      if (redirect) {
        root.$router.push(root.localePath(redirect));
      }
    };

    const submitEmail = async (email: string) => {
      disabled.value = true;
      initialMail.value = email;
      try {
        await emailLookup(email);
        // go to login screen
        step.value = Steps.Login;
      } catch (err) {
        // go to create account screen
        step.value = Steps.PasswordIntake;
      } finally {
        disabled.value = false;
        showGoBackButton.value = true;
      }
    };

    const submitPassword = async () => {
      step.value = Steps.WelcomeScreen;
      showGoBackButton.value = false;
      checkForRedirect();
    };

    const onLogin = () => {
      if (isPartiallyEnrolled.value) {
        closeModal();

        const loyalty = useLoyalty(root);
        if (loyalty.rewards.value.expiringLoyaltyPoints) {
          root.$eventBus.$emit('simplified-enrollment-login');
        } else {
          seStore.activateToast();
        }
      } else {
        close();
      }

      showGoBackButton.value = false;
      checkForRedirect();
    };

    const showSetupAccountToast = () => {
      closeModal();
      seStore.activateToast();
    };

    const goToProgressiveProfile = () => {
      closeModal();
      nextTick(() => seStore.activateProgressiveProfile());
    };

    const close = () => {
      closeModal();
      seStore.deactivate();
    };

    return {
      step,
      Steps,
      disabled,
      submitEmail,
      submitPassword,
      showSetupAccountToast,
      goToProgressiveProfile,
      contextKey,
      onLogin,
      initialMail,
      closeModal,
    };
  },
});
