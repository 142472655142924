



































import type { PropType } from 'vue';
import { computed, defineComponent, watch } from '@vue/composition-api';
import { Context, ProductUpsellTranslations } from '@vf/api-contract';
import useRootInstance from '@/shared/useRootInstance';
import useModal from '@/shared/useModal';
import { isClient, parseProductId } from '@vf/shared/src/utils/helpers';
import { Product, ProductUpsellItem } from '@vf/api-client';
import {
  useCart,
  useGtm,
  useProduct,
  useProductInventory,
} from '@vf/composables';
import { getEventFromTemplate } from '@vf/composables/src/useGtm/helpers';
import { EnhancedSaleType } from '@vf/composables/src/types/gtm';

export default defineComponent({
  name: 'VfProductsUpsell',
  props: {
    contextKey: {
      type: String,
      default: 'page-content',
    },
    quickShopLink: {
      type: String,
      required: true,
    },
    translations: {
      type: Object as PropType<ProductUpsellTranslations>,
      default: () => ({}),
    },
    // if is true read upsell from cart and use cart layout
    isCartLayout: Boolean,
  },
  setup(props) {
    const { root } = useRootInstance();
    const { product } = useProduct(root, props.contextKey);
    const { cart } = useCart(root);
    const { product: quickShopProduct, toggleQuickShop } = useProduct(
      root,
      Context.QuickShop
    );
    const { getProductInventory } = useProductInventory(
      root,
      Context.QuickShop
    );
    const { modalProps, openModal, setModalProps } = useModal();
    const { dispatchEvent, dispatchEventAfter } = useGtm(root);

    const upsellProducts = props.isCartLayout
      ? computed<ProductUpsellItem[]>(() => {
          return cart.value?.upsellProducts || [];
        })
      : computed<ProductUpsellItem[]>(
          () => product.value?.upsellProducts || []
        );

    const openQuickshop = async (
      upsellProd: ProductUpsellItem,
      position: number
    ) => {
      triggerGtmClick(upsellProd, position);
      const pid = parseProductId(upsellProd.id);
      await toggleQuickShop(pid, { color: upsellProd.colorCode });

      openModal({
        contextKey: Context.QuickShop,
        path: props.quickShopLink,
        type: 'page',
      });
      setModalProps({
        ...modalProps.value,
        class: modalProps.value.class + ' vf-modal-upsell',
      });
      await getProductInventory(pid);
      triggerGtmView(quickShopProduct.value);
    };

    const triggerGtmClick = (
      upsellProd: ProductUpsellItem,
      position: number
    ) => {
      dispatchEvent({
        ...getEventFromTemplate('enhanced-sale:click'),
        overrideAttributes: {
          type: EnhancedSaleType.UP_SELL,
          product: upsellProd,
          position,
        },
      });
    };

    const triggerGtmView = (prod: Product) => {
      dispatchEvent({
        ...getEventFromTemplate('enhanced-sale:view'),
        overrideAttributes: {
          type: EnhancedSaleType.UP_SELL,
          product: prod,
        },
      });
    };

    const triggerGtmImpression = () => {
      dispatchEventAfter('virtualPageView', () => {
        if (upsellProducts.value.length) {
          dispatchEvent({
            ...getEventFromTemplate('enhanced-sale:impression'),
            overrideAttributes: {
              type: EnhancedSaleType.UP_SELL,
              products: upsellProducts.value,
            },
          });
        }
      });
    };

    if (isClient) {
      watch(upsellProducts, triggerGtmImpression, { immediate: true });
    }

    return {
      upsellProducts,
      openQuickshop,
    };
  },
});
