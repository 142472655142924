

















import type { PropType } from 'vue';
import { computed, defineComponent, ref } from '@vue/composition-api';
import type { SmartGiftCtaTranslations } from '@vf/api-contract';
import { useProduct, useSmartGift } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'SmartGiftCTAButton',
  props: {
    translations: {
      type: Object as PropType<SmartGiftCtaTranslations>,
      default: () =>
        ({
          sendWithSmartGift: 'Send with smart gift',
        } as SmartGiftCtaTranslations),
    },
    contextKey: {
      type: String,
      default: 'product',
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { product, getProductSpecialPrice } = useProduct(
      root,
      props.contextKey
    );
    const {
      shouldLoad,
      addItemToSmartGiftBasket,
      isSmartGiftScriptLoaded,
      loadSmartGiftScript,
    } = useSmartGift(root);

    const loading = ref(false);

    const addSmartGift = async () => {
      try {
        loading.value = true;
        if (!isSmartGiftScriptLoaded.value) {
          await loadSmartGiftScript();
        }
        addItemToSmartGiftBasket(
          product,
          getProductSpecialPrice(product.value)
        );
      } finally {
        loading.value = false;
      }
    };

    const isOutOfStockProduct = computed(() =>
      product.value !== null
        ? product.value.variants.every((variant) => !variant.stock.inStock)
        : true
    );

    const isSelectedSizeOutOfStock = computed(
      () => product.value.size?.available === false
    );

    const isButtonDeactivated = computed(() => {
      return (
        isOutOfStockProduct.value ||
        isSelectedSizeOutOfStock.value ||
        loading.value
      );
    });

    return {
      loading,
      product,
      isButtonDeactivated,
      shouldLoad,
      addSmartGift,
    };
  },
});
