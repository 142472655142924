


















import type { PropType } from 'vue';
import {
  computed,
  defineComponent,
  ref,
  watch,
  onMounted,
} from '@vue/composition-api';
import type { CartUpsellTranslations } from '@vf/api-contract';
import {
  useCart,
  useGtm,
  useI18n,
  useMonetate,
  useProduct,
} from '@vf/composables';
import { getEventFromTemplate } from '@vf/composables/src/useGtm/helpers';
import useRootInstance from '@/shared/useRootInstance';
import { EnhancedSaleType } from '@vf/composables/src/types/gtm';

const transformProductForCart = (product, defaultCurrency: string) => ({
  id: product.id,
  name: product.title,
  itemGroupId: product.itemGroupId,
  quantity: 1,
  price: {
    original: product.price,
    current: product.salePrice,
    currency: defaultCurrency,
  },
  custom: false,
  upsell: true,
  image: {
    url: product.imageLink,
    alt: product.title,
  },
  savedForLater: false,
  pageUrl: product.link,
});

export default defineComponent({
  name: 'MonetateCartUpsell',
  props: {
    upsellsType: {
      type: String,
      default: '',
    },
    experienceId: {
      type: String,
      default: null,
    },
    /** Selector heading template */
    headingTemplate: {
      type: String,
      default: '',
    },
    /** Button Style */
    buttonStyle: {
      type: String,
      default: '',
    },
    translations: {
      type: Object as PropType<CartUpsellTranslations>,
      default: () => ({}),
    },
  },
  setup(props) {
    const { root } = useRootInstance();

    const { defaultCurrency } = useI18n(root);
    const { addItem } = useCart(root);
    const {
      extractRecommendedItems,
      sendRecImpressionsEvent,
      hasLoaded,
    } = useMonetate(root);
    const { dispatchEvent } = useGtm(root);
    const { getProductDetails, product } = useProduct(root);

    const addToCart = async (selected) => {
      let upsell;
      switch (props.upsellsType) {
        case 'singleSized':
          upsell = selected;
          break;
        case 'multiSized': {
          upsell = {
            ...upsells.value[0],
            id: sizeIdMap.value[selected.value],
          };
          break;
        }
      }

      const productAddedToCart = await addItem({
        ...upsell,
        /** Added variant ID due to requirement in buildItemPDPDetails method in useCart */
        variant: {
          id: upsell.id,
        },
      });

      if (productAddedToCart) {
        dispatchEvent({
          ...getEventFromTemplate('cart:add', {}),
          overrideAttributes: {
            quantity: upsell.qty,
            productId: upsell.id,
            sku: upsell.id,
            catalogCategory: upsell.primaryCategoryName,
          },
        });
        dispatchEvent(getEventFromTemplate('cart:update', {}));
      }
    };

    const recommendedItems = ref([]);

    const itemGroupId = ref('');

    watch(itemGroupId, (id) => {
      if (id) {
        // get the product for extracting available sizes
        getProductDetails(id);
      }
    });

    const upsells = computed(() => {
      switch (props.upsellsType) {
        case 'singleSized':
          return recommendedItems.value
            .map((p) => transformProductForCart(p, defaultCurrency.value))
            .slice(0, 5);
        case 'multiSized': {
          const item = recommendedItems.value.find((i) => i.itemGroupId);

          if (!item) {
            return [];
          }

          // set the value to get available sizes
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          itemGroupId.value = item.itemGroupId;

          return [transformProductForCart(item, defaultCurrency.value)];
        }
        default:
          return [];
      }
    });

    onMounted(() => {
      sendRecImpressionsEvent(
        upsells.value,
        0,
        upsells.value.length,
        props.experienceId,
        props.translations.addToCartButtonText,
        'Upsell Recommendation'
      );
      watch(
        hasLoaded,
        () => {
          if (hasLoaded.value) {
            const newRecommendedItems = extractRecommendedItems(
              props.experienceId
            );
            if (!areSameItems(recommendedItems.value, newRecommendedItems)) {
              recommendedItems.value = newRecommendedItems;
            }
          }
          if (upsells.value.length) {
            dispatchEvent({
              ...getEventFromTemplate('enhanced-sale:impression'),
              overrideAttributes: {
                type: EnhancedSaleType.UP_SELL,
                products: upsells.value,
              },
            });
          }
        },
        { immediate: true }
      );
    });

    const sizeIdMap = computed(() => {
      if (!product.value) {
        return {};
      }

      return (
        product.value.variants?.reduce(
          (acc, variant) => ({
            ...acc,
            [variant.attributes.size]: variant.id,
          }),
          {}
        ) || {}
      );
    });

    const availableSizes = computed(() => {
      if (!product.value) {
        return [];
      }

      const sizes = (product.value.attributes || []).find(
        (attribute) => attribute.code === 'size'
      );

      return sizes?.options || [];
    });

    return {
      availableSizes,
      upsells,
      addToCart,
    };
  },
});

const areSameItems = (oldItems, newItems) => {
  if (oldItems.length !== newItems.length) return false;
  for (let i = 0; i < oldItems.length; i++) {
    if (oldItems[i].id !== newItems[i].id) return false;
  }
  return true;
};
