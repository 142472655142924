


















import type { PropType } from 'vue';
import { computed, onMounted, defineComponent } from '@vue/composition-api';
import type { DataListingTableTranslations } from '@vf/api-contract';
import { useAccount, useI18n, useNotification, ROUTES } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import DataListingTable from '../shared/DataListingTable.vue';
import {
  mapToDataProtectedInfo,
  mapToDataProtectedInfos,
} from '@/helpers/dataProtected';

export default defineComponent({
  name: 'ShippingAddressPicker',
  components: {
    DataListingTable,
  },
  props: {
    /** Name of the context where component should be displayed */
    contextName: {
      type: String,
      default: 'shipping-address',
    },
    translations: {
      type: Object as PropType<DataListingTableTranslations>,
      default: () => ({}),
    },
    /** Flag to determine if edit button should be shown */
    showEditButton: {
      type: Boolean,
      default: true,
    },
    /** Flag to determine if deletes button should be shown */
    showDeleteButton: {
      type: Boolean,
      default: true,
    },
    /** Flag to determine if selected item should be on top row*/
    showDefaultAddressOnTopRow: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { root } = useRootInstance();

    const {
      getBasicInformation,
      deleteAddress,
      updateAddress,
      getAddresses,
      addressOperationMessage,
    } = useAccount(root);
    const { addNotification, clearNotifications } = useNotification(root);
    const { localePath } = useI18n(root);
    const themeConfig = root.$themeConfig.shippingAddressPicker;
    const buttonsSpacingLineClass = props.showEditButton
      ? themeConfig?.buttonsSpacingLineClass
      : '';

    const selectedAddress = computed(() =>
      getAddresses('S').value.find((item) => item.main)
    );

    const selectedAddressId = computed(() => selectedAddress.value?.id);

    const shippingAddresses = computed(() =>
      getAddresses('S').value.map((item) => {
        const addressLines = mapToDataProtectedInfos(item, [
          '{{addressLine1}} {{addressLine2}} {{city}}, ',
          '{{province}} {{postalCode}} {{country}}',
        ]);

        const address = addressLines.reduce(
          (address, line) => (address += line.value),
          ''
        );

        const name = mapToDataProtectedInfo(item, '{{firstName}} {{lastName}}');

        const code = mapToDataProtectedInfo(item, '{{phone}}');

        const label = `${name.value}, ${address}, ${code.value}`;

        return {
          label,
          addressLines,
          name,
          code,
          selected: item.main,
          id: item.id,
        };
      })
    );

    const handleDeleteAddress = async (item) => {
      clearNotifications();
      await deleteAddress(item.id);
      addNotification({
        message: props.translations.deleteSuccess,
        type: 'success',
      });
      getBasicInformation();
    };

    const handleChangeSelected = async (item) => {
      clearNotifications();
      const selectedAddressObject = getAddresses('S').value.find(
        (address) => address.id === item.id
      );
      await updateAddress(item.id, {
        ...selectedAddressObject,
        main: true,
      });
      addNotification({
        message: props.translations.updateSuccess,
        type: 'success',
      });
      getBasicInformation();
    };

    onMounted(() => {
      if (addressOperationMessage.value) {
        addNotification(addressOperationMessage.value);
        addressOperationMessage.value = null;
      }
    });

    return {
      shippingAddresses,
      selectedAddress,
      handleDeleteAddress,
      handleChangeSelected,
      selectedAddressId,
      buttonsSpacingLineClass,
      addShippingAddressUrl: localePath(ROUTES.NEW_SHIPPING_ADDRESS()),
      editShippingAddressGetter: (id) =>
        localePath(ROUTES.SHIPPING_ADDRESS(id)),
    };
  },
});
