















import type { PropType } from 'vue';
import {
  computed,
  ref,
  defineComponent,
  onUnmounted,
} from '@vue/composition-api';
import type { InterestsFormTranslations } from '@vf/api-contract';
import { useAccount, useNotification, useCart } from '@vf/composables';
import { getCacheKeyFromProps, isClient } from '@vf/shared/src/utils/helpers';
import useRootInstance from '@/shared/useRootInstance';
import useModal from '@/shared/useModal';
import { useUserStore } from '@vf/composables/src/store/user';
import { storeToRefs } from 'pinia';

type NotificationData = {
  message: 'string';
  type: 'success' | 'danger';
};

const getNotificationData = (status, translations): NotificationData => {
  return {
    message:
      status === 200
        ? translations.setBasicInformationSuccess
        : translations.setBasicInformationError,
    type: status === 200 ? 'success' : 'danger',
  };
};

export default defineComponent({
  name: 'InterestsForm',
  serverCacheKey: getCacheKeyFromProps,
  props: {
    translations: {
      type: Object as PropType<InterestsFormTranslations>,
      default: () => ({}),
    },
    afterSignUp: {
      type: Boolean as PropType<boolean>,
    },
    welcomeModal: {
      type: String as PropType<string>,
    },
    interestItems: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    contextKey: {
      type: String as PropType<string>,
    },
  },
  setup(props) {
    const { root } = useRootInstance();

    const {
      basicInformation,
      getBasicInformation,
      setBasicInformation,
    } = useAccount(root);
    const userStore = useUserStore(root);
    const { loyaltyEnrolled } = storeToRefs(userStore);
    const {
      addNotification,
      clearNotifications,
      setChangePasswordNotification,
    } = useNotification(root);
    const { openModal, closeModal } = useModal();
    const { loadCart } = useCart(root);

    const isSaveButtonDisabled = ref(false);

    if (isClient) getBasicInformation();

    const savedInterestsKeys = computed(
      () => basicInformation.value.preferences?.interests
    );

    const basicInfoData = computed(() => {
      return {
        email: basicInformation.value.email,
        firstName: basicInformation.value.firstName,
        lastName: basicInformation.value.lastName,
        birthDate: basicInformation.value.birthDate,
      };
    });

    const saveForm = (data: any) => {
      isSaveButtonDisabled.value = true;
      setChangePasswordNotification(false);
      clearNotifications();
      setBasicInformation({
        ...basicInfoData.value,
        preferences: {
          ...basicInformation.value.preferences,
          ...data,
        },
      })
        .then((res) => {
          if (!props.afterSignUp) {
            const notificationData = getNotificationData(
              res.status,
              props.translations
            );
            addNotification({
              message: notificationData.message,
              type: notificationData.type,
            });
          } else {
            closeModal();
          }
        })
        .finally(() => {
          isSaveButtonDisabled.value = false;
        });
    };

    const onSkip = () => {
      closeModal();
    };

    onUnmounted(() => {
      // Post Loyalty Enrollment Modal
      if (props.afterSignUp && loyaltyEnrolled.value) {
        openModal({
          type: 'lazyFragment',
          resourceId: props.welcomeModal,
        });
        loadCart({
          isBackgroundRequest: true,
          isTemporary: false,
          inventorySupplyCheck: true,
        });
      }
    });

    return {
      getNotificationData,
      savedInterestsKeys,
      isSaveButtonDisabled,
      saveForm,
      onSkip,
    };
  },
});
