



























































































import {
  ContentCTA,
  ContentHeroOverlay,
  ContentMedia,
  ContentText,
  LinkBehavior,
} from '@vf/api-contract';
import { defineComponent, PropType } from '@vue/composition-api';
import { extractImageSizes } from '../../../helpers/extractImageSizes';
import ContentCTAButtons from './ContentCTAButtons.vue';

export default defineComponent({
  name: 'ContentHero',
  components: {
    ContentCTAButtons,
  },
  props: {
    variant: {
      type: String,
    },
    media: {
      type: Object as PropType<ContentMedia>,
      required: true,
    },
    title: {
      type: Object as PropType<ContentText>,
    },
    subtitle: {
      type: Object as PropType<ContentText>,
    },
    ctas: {
      type: Array as PropType<ContentCTA[]>,
      required: true,
    },
    overlay: {
      type: Object as PropType<ContentHeroOverlay>,
    },
    mediaTarget: {
      type: Object as PropType<Pick<ContentCTA, 'link' | 'linkBehavior'>>,
    },
    bottomMargin: {
      type: String,
    },
    rowBackgroundColor: {
      type: String,
      default: 'transparent',
    },
    textBlockPosition: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      LinkBehavior,
    };
  },
  computed: {
    imageWidths() {
      return extractImageSizes(this.media.image, 'width');
    },
    imageHeights() {
      return extractImageSizes(this.media.image, 'height');
    },
    overlayClass() {
      return ['width', 'position']
        .map((field) =>
          this.overlay[field] ? `overlay-${this.overlay[field]}` : ''
        )
        .join(' ');
    },
    hasOverlay() {
      return this.ctas.length > 0 || this.title?.text || this.subtitle?.text;
    },
    isInGrid() {
      return this.$parent.$options.name === 'VfColumn';
    },
  },
  methods: {
    isBackgroundTransparent(color: string) {
      return color === '#00000000';
    },
    isColorWhite(color: string) {
      return color === '#FFFFFF';
    },
    checkLinkBehavior(behavior: LinkBehavior) {
      return this.mediaTarget && this.mediaTarget.linkBehavior === behavior;
    },
  },
});
