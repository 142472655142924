
















import type { PropType } from 'vue';
import { computed, watch, defineComponent } from '@vue/composition-api';
import type { CheckGiftCardBalanceMessageTranslations } from '@vf/api-contract';
import { useGiftCards } from '@vf/composables';
import { maskGiftCardNumber } from '@vf/composables/src/utils/giftCard';
import useRootInstance from '@/shared/useRootInstance';
import useModal from '@/shared/useModal';

export default defineComponent({
  name: 'CheckGiftCardBalanceMessage',
  props: {
    translations: {
      type: Object as PropType<CheckGiftCardBalanceMessageTranslations>,
      default: () => ({}),
    },
  },
  setup() {
    const { root } = useRootInstance();
    const { giftCardBalance } = useGiftCards(root);
    const { setScrollModalTop } = useModal();

    const isGiftCardAvailable = computed(() => {
      return (
        giftCardBalance.value.cardNumber &&
        typeof giftCardBalance.value.balance !== 'undefined'
      );
    });

    watch(isGiftCardAvailable, () => {
      setScrollModalTop(true);
    });

    return {
      giftCardBalance,
      isGiftCardAvailable,
      maskGiftCardNumber,
    };
  },
});
