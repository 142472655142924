

































































































import type { PropType } from 'vue';
import {
  defineComponent,
  ref,
  watch,
  inject,
  computed,
  onBeforeUnmount,
} from '@vue/composition-api';
import { SocialNativeTranslations, SocialNativeLayout } from '@vf/api-contract';
import { SocialNativePagination } from '@vf/api-client/src/api-types';
import {
  useCategory,
  useProduct,
  useRequestTracker,
  useSocialNative,
} from '@vf/composables';
import {
  WidgetName,
  WidgetType,
} from '@vf/composables/src/useSocialNative/types';
import useRootInstance from '@/shared/useRootInstance';
import useModal from '@/shared/useModal';
import { isClient } from '@vf/shared/src/utils/helpers';

export default defineComponent({
  name: 'VfSocialNative',
  components: {
    VfSocialNativeModal: () =>
      import(
        /* webpackChunkName: "uiSocialNativeModal" */
        /* webpackMode: "lazy" */
        '@/components/SocialNativeModal.vue'
      ),
  },
  props: {
    /** Background Color HEX */
    backgroundHex: {
      type: String,
      default: 'transparent',
    },
    /** category Id */
    categoryId: {
      type: String,
      default: '',
    },
    /** Select with page type */
    contextKey: {
      type: String,
      default: 'page-content',
    },
    /** goToLink */
    goToLink: {
      type: Object as PropType<{ title: string; to: string }>,
    },
    /** Layout */
    layout: {
      type: String as PropType<SocialNativeLayout>,
      default: SocialNativeLayout.HOMEPAGE,
    },
    /** streamId */
    streamId: {
      type: String,
      default: '',
    },
    /** text color and ctas */
    textColor: {
      type: String as PropType<'dark' | 'light'>,
      default: 'dark',
    },
    translations: {
      type: Object as PropType<SocialNativeTranslations>,
      required: true,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { onAllDone } = useRequestTracker(root);
    const {
      getMediaByCategoryId,
      getMediaByStream,
      getMediaByTagKey,
      getMediaForGallery,
      updatePlacementLayoutAndMedia,
      uploadMediaModalId,
      trackWidgetEvent,
      trackMediaEvent,
    } = useSocialNative(root);

    const { categoryId } = useCategory(root, props.contextKey);
    const { product } = useProduct(root, props.contextKey);
    const { openModal, setModalProps } = useModal();
    const themeConfig = root.$themeConfig.socialNative || {};
    const apiKey = root.$getEnvValueByCurrentLocale<string>(
      'SOCIAL_NATIVE_API_RO_KEY'
    );

    let count = undefined;
    let pageNumber = 1;
    let placementLayout = [];
    const isGalleryLayout = props.layout === SocialNativeLayout.GALLERY;
    const isCLPLayout = props.layout === SocialNativeLayout.CLP;
    const isPDPLayout = props.layout === SocialNativeLayout.PDP;
    const isDark = props.textColor === 'dark';
    const isDarkVariantPrimary = isDark ? undefined : 'border';
    const isDarkVariantSecondary = isDark ? 'border' : undefined;

    let goToLinkAttr = ref({});
    let uploadCtaAttr = ref({});
    const hasNext = ref(false);
    const showSocialNative = ref(false);
    const loading = ref(false);
    // number of item for each column ex: [2, 3, 1, 3, 3, 1, 3, 2] ... 1st column 2items, 2nd column 3items
    const placementLayoutAndMedia = ref([]);
    const mediaLoaded = ref([]);
    const modalMediaIndex = ref(-1);
    const modalOpen = ref(false);
    const useSwiper = ref(false);
    const streamId = ref('');

    const loadItems = () =>
      loadItemsImpl({
        apiKey,
        categoryId,
        count,
        getMediaByCategoryId,
        getMediaByStream,
        getMediaByTagKey,
        isPDPLayout,
        loadGalleryItems,
        loading,
        mediaLoaded,
        placementLayout,
        placementLayoutAndMedia,
        product,
        props,
        showSocialNative,
        streamId,
        updatePlacementLayoutAndMedia,
        $log: root.$log,
      });

    const loadGalleryItems = async () => {
      const res =
        pageNumber === 1
          ? // first time we load double items
            await getMediaForGallery({
              apiKey,
              count: count * 2,
              pageNumber: 1,
              nextCount: count,
              nextPageNumber: 3,
            })
          : await getMediaForGallery({
              apiKey,
              count: count,
              pageNumber,
            });

      const placementLayoutByPage =
        pageNumber % 2 === 0
          ? placementLayout.slice(placementLayout.length / 2)
          : placementLayout;

      hasNext.value = !!res.nextLink;

      if (pageNumber === 1) {
        // first time we load double items
        pageNumber = 3;
        showSocialNative.value = res.media.length >= count * 2;
      } else {
        pageNumber += 1;
        showSocialNative.value = res.media.length >= count;
      }

      if (showSocialNative.value) {
        // append new media list formatted for bucket layout
        placementLayoutAndMedia.value = placementLayoutAndMedia.value.concat(
          updatePlacementLayoutAndMedia(res.media, placementLayoutByPage)
        );
        mediaLoaded.value.push.apply(mediaLoaded.value, res.media);
      }
    };

    const widgetName = computed<WidgetName>(() => {
      switch (props.layout) {
        case SocialNativeLayout.HOMEPAGE:
          return 'hp';
        case SocialNativeLayout.PDP:
          return 'pdp';
        case SocialNativeLayout.CLP:
          return 'clp';
        default:
          return 'gallery';
      }
    });

    const onShopTheLook = (media) => {
      const type = getWidgetType(props.layout, props.streamId);

      const options = {
        apiKey,
        streamId: streamId.value || props.streamId,
        categoryId: props.categoryId || categoryId.value,
        pics: mediaLoaded.value.length,
      };

      trackMediaEvent(type, widgetName.value, {
        ...options,
        action: 'click',
        mediaId: media.id,
      });

      modalMediaIndex.value = mediaLoaded.value.findIndex(
        (item) => item.id === media.id
      );

      if (modalMediaIndex.value > -1) {
        modalOpen.value = true;

        trackMediaEvent(type, widgetName.value, {
          ...options,
          mediaId: mediaLoaded.value[modalMediaIndex.value].id,
          action: 'view',
        });
      }
    };

    const openUploadMediaModal = () => {
      openModal({
        type: 'lazyFragment',
        resourceId: uploadMediaModalId,
      });
      setModalProps({
        class: 'social-native-upload-media-modal',
        ariaLabel: props.translations.addYourPhoto,
      });
    };

    watch(
      () => root.$viewport.isSmall,
      (isSmall: boolean) => {
        const responsiveConfig =
          (isSmall ? themeConfig.mobile : themeConfig.default) || {};
        placementLayout = responsiveConfig.placementLayout || [];
        useSwiper.value = isSmall;

        switch (props.layout) {
          case SocialNativeLayout.HOMEPAGE:
            count = responsiveConfig[SocialNativeLayout.HOMEPAGE + '_count'];
            placementLayout = [2, 3, 1, 3, 3, 1, 3, 2];
            goToLinkAttr.value = uploadCtaAttr.value = { size: 'xs' };
            useSwiper.value = false;
            break;

          case SocialNativeLayout.CLP:
            count = responsiveConfig[SocialNativeLayout.CLP + '_count'];
            if (isSmall) {
              goToLinkAttr.value = uploadCtaAttr.value = { size: 'xs' };
            } else {
              goToLinkAttr.value = {
                size: 'sm',
                color: 'primary',
                variant: isDarkVariantPrimary,
              };
              uploadCtaAttr.value = {
                size: 'sm',
                color: 'primary',
                variant: isDarkVariantSecondary,
              };
            }
            break;

          case SocialNativeLayout.GALLERY:
            count = responsiveConfig[SocialNativeLayout.GALLERY + '_count'];
            goToLinkAttr.value = { style: { display: 'none' } };
            uploadCtaAttr.value = {
              size: 'sm',
              color: 'primary',
              variant: isDarkVariantSecondary,
            };
            break;

          case SocialNativeLayout.PDP:
            placementLayout = [1];
            useSwiper.value = true;
            uploadCtaAttr.value = {
              size: 'sm',
              color: 'primary',
              variant: isDarkVariantSecondary,
            };
        }
      },
      { immediate: true }
    );

    const getWidgetType = (
      layout: SocialNativeLayout,
      streamId: string
    ): WidgetType => {
      switch (layout) {
        case SocialNativeLayout.GALLERY:
          return 'best';
        case SocialNativeLayout.HOMEPAGE:
        case SocialNativeLayout.PDP:
          return 'stream';
        case SocialNativeLayout.CLP:
          return streamId ? 'stream' : 'category';
      }
    };

    const { keepTrackOfTheComponent } = inject('socialNativeAnalytics', {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      keepTrackOfTheComponent: (..._: unknown[]) => undefined,
    });

    const renderSocialMedia = async () => {
      placementLayoutAndMedia.value = [];
      mediaLoaded.value = [];
      showSocialNative.value = false;
      streamId.value = '';

      await loadItems();

      const type = getWidgetType(props.layout, props.streamId);

      // in case of 404 response for a social media stream
      if (isPDPLayout && !streamId.value) {
        return;
      }

      // in case the component is not actually visible
      if (!showSocialNative.value) {
        return;
      }

      const params: [
        WidgetType,
        WidgetName,
        {
          apiKey: string;
          streamId: string;
          categoryId: string;
          action: 'render';
          pics: number;
        }
      ] = [
        type,
        widgetName.value,
        {
          apiKey,
          streamId: streamId.value || props.streamId,
          categoryId: props.categoryId || categoryId.value,
          action: 'render',
          pics: mediaLoaded.value.length,
        },
      ];

      await trackWidgetEvent(...params);

      keepTrackOfTheComponent(...params);
    };

    if (isClient) {
      let isAlive = true;
      const renderSocialMediaIfAlive = () =>
        onAllDone(() => isAlive && renderSocialMedia());

      if (isCLPLayout) {
        // watch used when move from one category to another category
        watch(categoryId, () => renderSocialMediaIfAlive(), {
          immediate: true,
        });
      } else if (isPDPLayout) {
        // watch used when move from one PDP to another PDP
        watch(
          () => product.value?.id,
          (newSlugColor) => newSlugColor && renderSocialMediaIfAlive(),
          { immediate: true }
        );
      } else {
        renderSocialMediaIfAlive();
      }

      onBeforeUnmount(() => {
        isAlive = false;
      });
    }

    return {
      apiKey,
      goToLinkAttr,
      hasNext,
      isDarkVariantPrimary,
      isGalleryLayout,
      isPDPLayout,
      loading,
      loadItems,
      mediaLoaded,
      modalMediaIndex,
      modalOpen,
      onShopTheLook,
      openUploadMediaModal,
      placementLayout,
      placementLayoutAndMedia,
      showSocialNative,
      SocialNativeLayout,
      uploadCtaAttr,
      useSwiper,
    };
  },
});

const loadItemsImpl = async ({
  apiKey,
  categoryId,
  count,
  getMediaByCategoryId,
  getMediaByStream,
  getMediaByTagKey,
  isPDPLayout,
  loadGalleryItems,
  loading,
  mediaLoaded,
  placementLayout,
  placementLayoutAndMedia,
  product,
  props,
  showSocialNative,
  streamId,
  updatePlacementLayoutAndMedia,
  $log,
}) => {
  try {
    loading.value = true;
    let dynamicCall: () => Promise<SocialNativePagination> | null = null;
    switch (props.layout) {
      case SocialNativeLayout.GALLERY:
        await loadGalleryItems();
        break;

      case SocialNativeLayout.PDP:
        dynamicCall = () =>
          getMediaByTagKey({
            apiKey,
            tagKey: product.value?.id,
            count: count,
          }).catch((e) => {
            throw e;
          });
        break;

      case SocialNativeLayout.CLP:
        dynamicCall = () =>
          props.streamId
            ? getMediaByStream({
                apiKey,
                streamId: props.streamId,
                count: count,
              })
            : getMediaByCategoryId({
                apiKey,
                categoryId: props.categoryId || categoryId.value,
                count: count,
              });
        break;

      default:
        dynamicCall = () =>
          getMediaByStream({
            apiKey,
            streamId: props.streamId,
            count: count,
          });
    }
    if (dynamicCall !== null) {
      const res = await dynamicCall();

      if (isPDPLayout) {
        streamId.value = res.streamId;
      }

      if (
        res.media.length >= count ||
        (props.layout === SocialNativeLayout.PDP && res.media.length > 0)
      ) {
        // append new media list formatted for bucket layout
        placementLayoutAndMedia.value = placementLayoutAndMedia.value.concat(
          updatePlacementLayoutAndMedia(res.media, placementLayout)
        );
        mediaLoaded.value.push.apply(mediaLoaded.value, res.media);
        showSocialNative.value = true;
      }
    }
  } catch (err) {
    // Failed to fetch data
    let printMessage =
      '[@theme/components/smart/shared/SocialNative::loadItems]';
    if (err.response?.status === 404) {
      printMessage = '';
    } else {
      printMessage += ' Social Native fetch data failed!';
    }
    printMessage && $log.error(printMessage, err);
  }
  loading.value = false;
};
