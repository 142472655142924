



































import { ContentCTA, ContentText } from '@vf/api-contract';
import { defineComponent, PropType } from '@vue/composition-api';
import ContentCTAButtons from './ContentCTAButtons.vue';

export default defineComponent({
  name: 'ContentBodyText',
  components: {
    ContentCTAButtons,
  },
  props: {
    title: {
      type: Object as PropType<ContentText>,
      required: true,
    },
    ctas: {
      type: Array as PropType<ContentCTA[]>,
      required: true,
    },
    backgroundColorStyle: {
      type: String,
      required: false,
    },
    ctaAlignment: {
      type: String,
      required: false,
      default: 'horizontal',
    },
    bottomMarginStyle: {
      type: String,
      required: true,
    },
    bodyText: {
      type: Object,
      required: false,
    },
  },
});
