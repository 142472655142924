



































import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  inject,
} from '@vue/composition-api';
import {
  useAccount,
  useAuthentication,
  useCart,
  useFavorites,
  useNotification,
  useUtilities,
  useGtm,
  useI18n,
} from '@vf/composables';
import { LoyaltyRegisterTranslations } from '@vf/api-contract';
import useRootInstance from '@/shared/useRootInstance';
import { ValidationSteps } from '@vf/shared/src/utils/helpers';
import useAuthenticationEvents, {
  AuthenticationType,
} from '@/shared/useAuthenticationEvents';
import { PhoneInputCountry } from '@vf/composables/src/useUtilities';
import { useCheckoutStore } from '@vf/composables/src/store/checkoutStore';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

export default defineComponent({
  name: 'LoyaltyRegister',
  props: {
    /** Object containing translations as in interface LoyaltyRegisterTranslations */
    translations: {
      type: Object as PropType<LoyaltyRegisterTranslations>,
      required: true,
    },
    /** Flag do identify legacy loyalty user */
    isLegacyUser: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    /** Consent for marketing enrollment text */
    marketingConsentText: {
      type: String,
      default: '',
    },
    /** Terms and conditions acceptance text */
    termsAndConditionsText: {
      type: String,
      default: '',
    },
    /** Terms and conditions acceptance text */
    privacyPolicyText: {
      type: String,
      default: '',
    },
    showHeading: {
      type: Boolean,
      default: true,
    },
    showSubheading: {
      type: Boolean,
      default: true,
    },
    campaignName: {
      type: String,
      default: '',
    },
    email: {
      type: String as PropType<string>,
      default: null,
    },
    /** Validations for the form */
    validations: {
      type: Object,
      default: () => ({
        phoneNumberRegex: '',
        zipCodeRegex: '',
      }),
    },
    passwordValidationSteps: {
      type: Object as PropType<ValidationSteps>,
      required: true,
    },
    /** Is this component inside a Modal? **/
    isModal: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    /** Initial value for newsletter consent checkbox */
    newsletterSignUpAutoCheck: {
      type: Boolean,
      default: false,
    },
    showSmsSubscription: {
      type: Boolean,
      default: false,
    },
    contextKey: {
      type: String,
      required: true,
    },
  },
  emits: ['sign-up'],
  setup(props, { emit }) {
    const { root } = useRootInstance();
    const { getFormLocation } = useGtm(root);
    const formLocation = getFormLocation(props.contextKey);
    const { order } = useCheckoutStore();
    const {
      signIn,
      signUp,
      saveForLaterId,
      processPendingActionForLoyaltyUser,
    } = useAuthentication(root);
    const { createSignIn, createSignUp } = useAuthenticationEvents(
      formLocation,
      {
        useLoadEvent: true,
        type: AuthenticationType.Registration,
      }
    );
    const { clearNotifications } = useNotification(root);
    const {
      basicInformation,
      getBasicInformation,
      setBasicInformation,
    } = useAccount(root);
    const { cartId, saveGuestCart, isCartMerged } = useCart(root);
    const { favoriteId } = useFavorites(root);
    const { getCountry, getPhoneInputCountryList } = useUtilities(root);
    const { getIetfLocale } = useI18n(root);
    const { isSmsPreferencesSignUpEnabled } = useFeatureFlagsStore();
    const locale = getIetfLocale();
    const minRegisterAge = root.$config.MINIMUM_REGISTER_AGE;
    const buttonDisabled = ref(false);

    // TODO: GLOBAL15-61059 remove after redesign core
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');

    if (props.isLegacyUser) {
      getBasicInformation();
    }

    const heading = computed(() => {
      if (!props.showHeading) {
        return '';
      }
      if (props.isLegacyUser && props.translations.headingLegacy) {
        return props.translations.headingLegacy;
      }
      return props.translations.heading;
    });

    const subheading = computed(() => {
      if (!props.showSubheading) {
        return '';
      }
      if (props.isLegacyUser && props.translations.subheadingLegacy) {
        return props.translations.subheadingLegacy;
      }
      return props.translations.subheading;
    });

    const boundWithGtmEventsSignIn = createSignIn(signIn, {
      isAutomaticLogin: true,
    });
    const boundWithGtmEventsSignUp = createSignUp(
      async <
        T extends {
          email: string;
          password: string;
          campaignName: string;
          enrollments: unknown[];
        }
      >(
        data: T
      ) => {
        saveGuestCart();
        clearNotifications();

        const signUpData = {
          ...data,
          enrollNewUsersToLoyalty: true,
        };

        if (props.campaignName) {
          signUpData.campaignName = props.campaignName;
        }

        if (props.isLegacyUser) {
          const { getCountry } = useUtilities(root);

          signUpData.enrollments = [{ type: 'Loyalty', country: getCountry() }];
          await setBasicInformation(signUpData);

          throw new Error('User type is legacy');
        }

        await signUp(signUpData);
        const signInPayload = {
          password: data.password,
          username: data.email,
          action: 'postLogin',
          guestObjects: {
            basketId: cartId.value,
            saveForLaterId: saveForLaterId.value,
            favoriteId: favoriteId.value,
          },
        };
        if (order) {
          signInPayload['st'] = order.st;
          signInPayload['orderNo'] = order.orderNumber;
        }

        const signInResponse = await boundWithGtmEventsSignIn(
          signInPayload,
          true
        );

        if (signInResponse) {
          await processPendingActionForLoyaltyUser();
          isCartMerged.value = true;
        }

        return signUpData;
      }
    );

    async function handleSignUp(data) {
      buttonDisabled.value = true;

      try {
        const signUpData = await boundWithGtmEventsSignUp(data);
        emit('sign-up', signUpData);
      } catch (e) {
        if (e.message === 'User type is legacy') {
          return;
        }

        console.error(e);
      } finally {
        buttonDisabled.value = false;
      }
    }

    const phoneInputDefaultCountryCode = getCountry().toUpperCase();
    const phoneInputCountries = ref<PhoneInputCountry[]>([]);

    onMounted(async () => {
      const allPhoneInputCountries = await getPhoneInputCountryList(locale);
      phoneInputCountries.value = allPhoneInputCountries.filter(
        (item) => item.countryCode === phoneInputDefaultCountryCode
      );
    });

    const formProps = computed(() => ({
      translations: props.translations,
      passwordValidationSteps: props.passwordValidationSteps,
      subscribeTextCheckbox: props.marketingConsentText,
      isLegacyUser: props.isLegacyUser,
      minRegisterAge: minRegisterAge,
      showPasswordHelper: !props.isModal,
      buttonDisabled: buttonDisabled.value,
      showHeading: false,
      showXplrPass: false,
      showSmsSubscription:
        isSmsPreferencesSignUpEnabled && props.showSmsSubscription,
      phoneInputCountries: phoneInputCountries.value,
      phoneInputDefaultCountryCode,
      prefillData: {
        firstName: basicInformation.value.firstName,
        lastName: basicInformation.value.lastName,
        email: basicInformation.value.email || props.email,
        zip: basicInformation.value.postalCode,
        isSubscribed: props.newsletterSignUpAutoCheck,
      },
      validations: props.validations,
      redirect: false,
    }));

    return {
      heading,
      subheading,
      buttonDisabled,
      formProps,
      minRegisterAge,
      handleSignUp,
      isCoreRedesignEnabled,
    };
  },
  data() {
    return {
      postalCode: null,
      dob: null,
      phone: null,
    };
  },
});
