












































import { ContentCTA, ContentText } from '@vf/api-contract';
import { defineComponent, PropType } from '@vue/composition-api';
import ContentCTAButtons from './ContentCTAButtons.vue';

export default defineComponent({
  name: 'ContentRow',
  components: {
    ContentCTAButtons,
  },
  props: {
    title: {
      type: Object as PropType<ContentText>,
      required: true,
    },
    subtitle: {
      type: Object as PropType<ContentText>,
      required: true,
    },
    ctas: {
      type: Array as PropType<ContentCTA[]>,
      required: true,
    },
    bottomMarginStyle: {
      type: String,
      required: true,
    },
    rowBackgroundColor: {
      type: String,
    },
  },
  computed: {
    hasTitles() {
      return this.title?.text || this.subtitle?.text;
    },
  },
});
