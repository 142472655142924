










































































































import type { PropType } from 'vue';
import {
  computed,
  onMounted,
  ref,
  watch,
  defineComponent,
} from '@vue/composition-api';
import {
  ROUTES,
  useAccount,
  useCart,
  useCheckout,
  useI18n,
  useKlarna,
  useNotification,
  useValidation,
} from '@vf/composables';
import {
  AddressFormTranslations,
  CheckoutBillingInfoTranslations,
  FlashErrorType,
} from '@vf/api-contract';
import { PaymentMethodCode } from '@vf/api-client';
import type { Address } from '@vf/api-client/src/types';
import useRootInstance from '@/shared/useRootInstance';
import ls from '@vf/composables/src/utils/localStorage';
import cloneDeep from '@vf/shared/src/utils/cloneDeep';

import AddressSelection from '@/components/address/AddressSelection.vue';
import AddressSummary from '@/components/AddressSummary.vue';
import Notifications from '@/components/smart/layout/Notifications.vue';
import AddressForm from '@/components/smart/shared/AddressForm.vue';
import { useUserStore } from '@vf/composables/src/store/user';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'CheckoutBillingInfo',
  components: {
    AddressSummary,
    AddressForm,
    AddressSelection,
    Notifications,
  },
  props: {
    translations: {
      type: Object as PropType<CheckoutBillingInfoTranslations>,
      required: true,
    },
    addressFormTranslations: {
      type: Object as PropType<AddressFormTranslations>,
      required: true,
    },
    showEditAddressButton: Boolean, // Property to show edit address button or not
    showZipCodeWithState: Boolean, // Property to show zip code in one line with city and state
    showSubscriptionCheckbox: Boolean, // Property to show subscription checkbox
    clearFieldsOnSameAsShippingDisabling: Boolean, // Property to clear fields when "Same as shipping" checkbox become unchecked
    /** Property to show country field on billing address form */
    showCountry: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    // TODO: clean up this smart component GLOBAL15-56318
    const { root } = useRootInstance();

    const userStore = useUserStore(root);
    const { loggedIn } = storeToRefs(userStore);

    const { setValidation, $v } = useValidation(root, 'ADDRESS_FORM');

    const {
      shippingAddress,
      billingSameAsShipping,
      billingAddress,
      paymentMethod,
      validateAddress,
      checkAddressChanges,
      billingFormAccordion,
      wasShippingStepSkippedByUser,
      isPickupDataValid,
      AVSisAddressInvalid,
      AVSdone,
      AVSskip,
      moveDefaultAddressToShipping,
      handleAddressUpdateForKlarna,
    } = useCheckout(root);
    const {
      getAddresses,
      addAddress,
      updateAddress,
      getBasicInformation,
    } = useAccount(root);
    const { localePath } = useI18n(root);
    const {
      billingAddress: cartBillingAddress,
      cart,
      cartId,
      getShippingMethods,
      prepareOrder,
      hasPickupItems,
      hasShippingItems,
      hasItems,
      clearCartLevelCustomerNotifications,
      refreshCart,
    } = useCart(root);
    const { clearNotifications } = useNotification(root);
    const { isBillingAddressValidForKlarna } = useKlarna(root);
    const addressFormModal = ref(null);

    const userBillingAddresses = getAddresses('B');

    const availableBillingAddresses = computed(() => {
      if (paymentMethod.value === PaymentMethodCode.KLARNA) {
        return userBillingAddresses.value.map((address) =>
          isBillingAddressValidForKlarna(address as Address)
            ? address
            : {
                ...address,
                disabled: true,
              }
        );
      }

      return userBillingAddresses.value;
    });

    /**
     * Determine whether we can use shipping address or not
     * For logged in customers we need to have no billing addresses available
     */
    const canUseShippingAddress = computed(() => {
      return (
        (!hasPickupItems.value || hasShippingItems.value) &&
        (!loggedIn.value || availableBillingAddresses.value.length === 0)
      );
    });

    const addressSelectionAdditionalOption = () => {
      const options = [
        {
          value: 'open-address-modal',
          label:
            props.translations.addressSelectionAdditionalOption
              .openAddressModal,
        },
      ];

      if (!hasPickupItems.value || hasShippingItems.value) {
        options.unshift({
          value: 'set-same-as-shipping',
          label:
            props.translations.addressSelectionAdditionalOption
              .setSameAsShipping,
        });
      }

      return options;
    };

    const copyBillingAddressFromShipping = () => {
      if (shippingAddress.value) {
        billingAddress.value = cloneDeep(shippingAddress.value);
      }
    };

    const getDefaultSelectedOption = () => {
      const loggedInAndAddressesPopulated =
        loggedIn.value && availableBillingAddresses.value.length > 0;
      if (loggedInAndAddressesPopulated) {
        const savedSelectedAddressId = ls.getItem('billingAddressId');
        const address =
          availableBillingAddresses.value.find(
            (address) => address.id === savedSelectedAddressId
          ) ||
          availableBillingAddresses.value.find(
            (address) => address.main && !address.disabled
          ) ||
          availableBillingAddresses.value.find(({ disabled }) => !disabled);
        if (address) {
          billingAddress.value = cloneDeep(address);
          return {
            addressId: address.id,
            option: '',
          };
        }
      }
      const defaultOption = { addressId: '', option: '' };
      if (canUseShippingAddress.value) {
        copyBillingAddressFromShipping();
        defaultOption.option = 'set-same-as-shipping';
      }
      return defaultOption;
    };

    const selectedOption = ref(getDefaultSelectedOption());

    const setSameAddressAsShipping = () => {
      if (!hasPickupItems.value || hasShippingItems.value) {
        copyBillingAddressFromShipping();
      }
      updateSelectedOption(null, 'set-same-as-shipping');
    };

    const updateSelectedOption = (addressId?, option?) => {
      const billingId = billingAddress.value?.id;
      selectedOption.value = {
        addressId: addressId !== null ? billingId : addressId,
        option: option !== null ? option : '',
      };

      ls.setItem('billingAddressId', billingId || addressId);
    };

    watch(paymentMethod, () => {
      if (
        // no need for any actions if the new payment method is not Klarna
        // or the billing address is still valid for Klarna
        paymentMethod.value !== PaymentMethodCode.KLARNA ||
        (billingAddress.value &&
          isBillingAddressValidForKlarna(billingAddress.value))
      ) {
        return;
      }

      selectedOption.value = getDefaultSelectedOption();

      if (!selectedOption.value.addressId) {
        setSameAddressAsShipping();
      }
    });

    const openAddressModal = (type) => {
      addressFormModal.value = type;
    };

    const handleSubmitAddressForm = async (address) => {
      try {
        const data = {
          ...address,
          approachType: 'B',
        };
        let response;
        if (
          addressFormModal.value === 'edit' &&
          billingAddress.value.id &&
          selectedOption.value.option !== 'set-same-as-shipping'
        ) {
          response = await updateAddress(billingAddress.value.id, data);
        } else {
          response = await addAddress(data);
        }
        if (response) {
          await getBasicInformation();
          billingAddress.value = { ...data, id: response?.data.addressId };
          updateSelectedOption();
        }
        closeAddressModal(false);
      } catch (err) {
        console.error(err);
      }
    };

    const closeAddressModal = async (restoreSelection = true) => {
      clearNotifications();
      addressFormModal.value = null;
      if (restoreSelection) {
        await getBasicInformation();
      }
    };

    const selectAddressChangeHandler = (addressId) => {
      billingAddress.value = (availableBillingAddresses.value.find(
        (address) => address.id === addressId
      ) as unknown) as Address;
      updateSelectedOption();
    };

    const billingSameAsShippingChange = (newValue) => {
      if (props.clearFieldsOnSameAsShippingDisabling && !newValue) {
        billingAddress.value.addressLine1 = '';
        billingAddress.value.addressLine2 = '';
        billingAddress.value.city = '';
        billingAddress.value.email = '';
        billingAddress.value.firstName = '';
        billingAddress.value.lastName = '';
        billingAddress.value.phone = '';
        billingAddress.value.postalCode = '';
        billingAddress.value.province = '';
      }
    };

    const addressModelData = computed(() => {
      if (addressFormModal.value === 'edit') {
        const address = availableBillingAddresses.value.find(
          (item) => item.id === billingAddress.value.id
        );
        return {
          ...(address || billingAddress.value),
        };
      }
      return {
        addressLine1: null,
        addressLine2: null,
        approachType: 'B',
        city: null,
        country: root
          .$getEnvValueByCurrentLocale<string>('COUNTRY')
          .toUpperCase(),
        email: null,
        firstName: null,
        lastName: null,
        main: false,
        phone: null,
        postalCode: null,
        province: null,
        recipientContactEmail: null,
        recipientContactPhone: null,
        recipientFirstName: null,
        recipientLastName: null,
      };
    });

    // load billing address from cart if same shipping address is not checked and cart billing address gets updated
    watch(
      cartBillingAddress,
      () => {
        if (canUseShippingAddress.value && billingSameAsShipping.value) {
          copyBillingAddressFromShipping();
        }
      },
      { immediate: true }
    );

    watch([billingSameAsShipping, shippingAddress], () => {
      if (canUseShippingAddress.value && billingSameAsShipping.value) {
        copyBillingAddressFromShipping();
      }
      handleAddressUpdateForKlarna();
    });

    const isVisible = computed(
      () =>
        hasItems.value &&
        paymentMethod.value !== PaymentMethodCode.PAYPAL &&
        paymentMethod.value !== PaymentMethodCode.APPLEPAY
    );

    const initBillingAddresses = async () => {
      // if a saved address is selected or customer is guest or the addresses are loaded - skip
      if (
        !selectedOption.value.addressId &&
        loggedIn.value &&
        !userBillingAddresses.value.length
      ) {
        await getBasicInformation();
      }

      selectedOption.value = getDefaultSelectedOption();
    };

    onMounted(async () => {
      await initBillingAddresses();

      if (!cartId.value) {
        await refreshCart();
      }

      if (hasPickupItems.value) {
        billingSameAsShipping.value = false;
      }

      // Clear customer notifications displayed on checkout shipping page
      clearCartLevelCustomerNotifications();

      // Call to /prepareOrder endpoint needs to be the very first one for payment step
      // as any request updating shipping address call performed in-between steps will
      // clear any existing customerNotifications related to inventory changes.
      await prepareOrder();
      // as prepareOrder may result in changes in eligible shipping methods (background checks and updates on OMS level),
      // we need to fetch them again as it matters for ApplePay, for example (GLOBAL15-76244)
      getShippingMethods('me');

      setValidation($v.value);

      const shippingCartAddress =
        cart.value.shippingMethods?.find(
          (element) => element.shippingId === 'me'
        ) || null;
      if (shippingCartAddress?.address) {
        // Shipping address stored in the cart object contains `addressId` property.
        // However, addresses stored in the customer's address book contain `id` property.
        // The following code handles this discrepancy to have both properties available
        // in the address stored in the app state.
        const { addressId: id, ...addressData } = shippingCartAddress.address;
        shippingAddress.value = {
          id,
          ...addressData,
          ...{
            subscriptions: {
              newsletterConsent:
                shippingAddress.value.subscriptions?.newsletterConsent,
            },
          },
        };
      }
      let isAddressValid;
      if (AVSdone.value || AVSskip.value) {
        // Either stored address was validated as correct (AVSdone) or confirmed as
        // correct manually by the customer in the shipping step (AVSskip).
        isAddressValid = true;
      } else {
        try {
          AVSisAddressInvalid.value = false;
          const validationResponse = await validateAddress(cart.value.id);
          isAddressValid = await checkAddressChanges(validationResponse.data);
        } catch (err) {
          console.error(err);
          isAddressValid = false;
        }
      }

      // When customer enters billing page we must validate if stored shipping address is correct.
      // If not and AVSskip not checked, we redirect him back to the checkout shipping page.
      if (shippingCartAddress?.address && isAddressValid) {
        const invalidShippingAddressFlash = cart.value.flash.find(
          (flash) => flash.code === FlashErrorType.InvalidShippingAddress
        );

        // When customer goes through with default shipment then edits cart and choose pickup and press back button
        // prevents from skipping one checkout step
        if (hasPickupItems.value && !isPickupDataValid()) {
          wasShippingStepSkippedByUser.value = true;
        }

        if (invalidShippingAddressFlash) {
          root.$router.push(localePath(ROUTES.CHECKOUT_SHIPPING()));
        }

        await moveDefaultAddressToShipping(
          shippingAddress.value.subscriptions?.newsletterConsent
        );
      } else if (!AVSskip.value) {
        root.$router.push(localePath(ROUTES.CHECKOUT_SHIPPING()));
      }
    });

    return {
      isVisible,
      billingFormAccordion,
      billingAddress,
      userBillingAddresses,
      billingSameAsShipping,
      availableBillingAddresses,
      loggedIn,
      addressFormModal,
      openAddressModal,
      closeAddressModal,
      handleSubmitAddressForm,
      selectAddressChangeHandler,
      addressModelData,
      selectedOption,
      setSameAddressAsShipping,
      canUseShippingAddress,
      addressSelectionAdditionalOption,
      billingSameAsShippingChange,
      hasPickupItems,
      hasShippingItems,
    };
  },
});
