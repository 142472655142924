






























































import {
  computed,
  defineComponent,
  ref,
  watch,
  onMounted,
  PropType,
  shallowRef,
  onUnmounted,
  nextTick,
  onUpdated,
} from '@vue/composition-api';
import useRootInstance from '@/shared/useRootInstance';
import Carousel from '@/components/smart/carousel/Carousel.vue';
import CarouselSlide from '@/components/smart/carousel/CarouselSlide.vue';
import { CarouselTranslations, Context } from '@vf/api-contract';
import { isClient } from '@vf/shared/src/utils/helpers';
import ContentRenderer from '@/components/smart/shopByActivity/ContentRenderer.vue';
import { getComponentName } from '@/components/cms/cmsUtils';

export default defineComponent({
  name: 'PageTabs',
  components: {
    ContentRenderer,
    CarouselSlide,
    Carousel,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    labels: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    slides: {
      type: Array as PropType<Record<string, any>[]>,
      default: () => [],
    },
    tabs: {
      type: Array,
      default: () => [],
    },
    translations: {
      type: Object as PropType<CarouselTranslations>,
      default: () => ({}),
    },
  },
  setup(props) {
    const contextKey = Context.ShopByActivity;

    const { root } = useRootInstance();

    const tabsWrapperRef = shallowRef(null);
    const tabsRef = shallowRef(null);
    const carouselRef = shallowRef(null);

    const currentIndex = ref(0);
    const transitionName = ref('right');

    const queryParamName = 'activity';

    const queryParamValues = props.labels.map((value) =>
      value.replace(/&/g, 'and').split(' ').join('-')
    );

    const setQueryParam = (name: string, value: string) => {
      const query = Object.assign({}, root.$route.query);
      if (query[name] === value) return;
      query[name] = value;
      root.$router.replace({ query });
    };

    const handleIndexChanged = ({ activeItem }) => {
      setQueryParam(queryParamName, queryParamValues[activeItem]);
    };

    const getItemAtIndexOrFirst = (values, index) => values[index] ?? values[0];

    const handleTabClicked = (value) => {
      const newValue = getItemAtIndexOrFirst(queryParamValues, value);
      setQueryParam(queryParamName, newValue);
    };

    const getNextOrPrevIndex = (delta, currentIndex, length) =>
      (currentIndex + delta + length) % length;

    const handlePrevClicked = () => {
      const index = getNextOrPrevIndex(
        -1,
        currentIndex.value,
        queryParamValues.length
      );
      const newValue = getItemAtIndexOrFirst(queryParamValues, index);
      setQueryParam(queryParamName, newValue);
    };

    const handleNextClicked = () => {
      const index = getNextOrPrevIndex(
        1,
        currentIndex.value,
        queryParamValues.length
      );
      const newValue = getItemAtIndexOrFirst(queryParamValues, index);
      setQueryParam(queryParamName, newValue);
    };

    const currentQueryParam = computed(() =>
      root.$route.query[queryParamName]?.toString()
    );

    const currentChildren = computed<Record<string, unknown>[]>(
      () => (props.tabs[currentIndex.value] as any)?.pageContent
    );

    let tabIndexUpdated = false;

    const updateTabIndex = () => {
      if (!carouselRef.value) return;
      const slides = Array.from(
        carouselRef.value.$el.getElementsByClassName('carousel-slide')
      );
      slides.forEach((el: HTMLElement) => setTabIndex(el, -1));
      setTabIndex(slides[currentIndex.value] as HTMLElement, 0);
    };

    const setTabIndex = (target: HTMLElement, tabIndex: number) => {
      const focusableElements = target?.getElementsByTagName('a');
      tabIndexUpdated = !!(tabIndexUpdated || focusableElements?.length);
      Array.from(focusableElements || []).forEach(
        (el: HTMLElement) => (el.tabIndex = tabIndex)
      );
    };

    const currentQueryParamWatcher = (value, oldValue) => {
      const index = queryParamValues.indexOf(value);
      if (!value || index === -1) return;
      const oldIndex = queryParamValues.indexOf(oldValue);
      transitionName.value = oldIndex < index ? 'right' : 'left';
      currentIndex.value = index;
      carouselRef.value?.slideTo(index);
      updateTabIndex();
    };

    watch(currentQueryParam, currentQueryParamWatcher, { immediate: true });

    let header, headerPromoBar;
    let active = false;

    if (isClient) {
      header = document.querySelector('.vf-header__animated');
      headerPromoBar = document.querySelector('.vf-header__promo-bar');

      onMounted(() => {
        updateTop();
        window.addEventListener('scroll', updateTop, { passive: true });
        root.$eventBus.$on('header-collapse', listenToHeaderHeight);

        const index = queryParamValues.indexOf(currentQueryParam.value);
        if (!currentQueryParam.value || index === -1) return;
        carouselRef.value?.slideTo(currentIndex.value);
      });

      onUpdated(() => {
        if (tabIndexUpdated) return;
        nextTick(updateTabIndex);
      });

      onUnmounted(() => {
        window.removeEventListener('scroll', updateTop);
        root.$eventBus.$off('header-collapse', listenToHeaderHeight);
        root.$eventBus.$emit('filter-panel-sticky', false);
        tabIndexUpdated = false;
      });
    }

    const updateTop = () => {
      requestAnimationFrame(() => {
        if (!header || !tabsWrapperRef.value) return;
        const headerHeight = Math.floor(header.getBoundingClientRect().height);
        const headerPromoHeight = headerPromoBar
          ? Math.floor(headerPromoBar.getBoundingClientRect().height)
          : 0;
        const positionTop = headerHeight + headerPromoHeight;
        const positionTopStyle = `${positionTop}px`;
        if (tabsWrapperRef.value.style.top !== positionTopStyle) {
          tabsWrapperRef.value.style.top = positionTopStyle;
        }
        // just in case scroll ended, but header updated after
        updateShadow(positionTop);
        // if header height still changing, continue to update
        if (active) updateTop();
      });
    };

    const listenToHeaderHeight = (transitioning) => {
      active = transitioning;
      updateTop();
    };

    const updateShadow = (positionTop) => {
      const tabsWrapperTop = Math.floor(
        tabsWrapperRef.value.getBoundingClientRect().top
      );
      const classList = tabsWrapperRef.value.classList;
      const isSticky = tabsWrapperTop <= positionTop;
      classList.toggle('vf-page-tabs__wrapper--shadow', isSticky);
      root.$eventBus.$emit('filter-panel-sticky', isSticky);
    };

    return {
      tabsRef,
      carouselRef,
      tabsWrapperRef,
      currentIndex,
      currentChildren,
      handleIndexChanged,
      handlePrevClicked,
      handleNextClicked,
      handleTabClicked,
      contextKey,
      transitionName,
      setQueryParam,
      currentQueryParam,
      getComponentName,
      currentQueryParamWatcher,
    };
  },
});
