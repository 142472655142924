//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    ratio: Number,
    small: [Boolean, Number],
    medium: [Boolean, Number],
    large: [Boolean, Number],
  },
};
