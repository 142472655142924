import { ComponentInstance, useI18n } from '@vf/composables';
import {
  CTAExperience,
  CustomsCTAExperienceModalNames,
} from '@vf/api-contract';
import { useCmsRefStore } from '@vf/composables/src/store/cmsRef';
import { storeToRefs } from 'pinia';

export type OpenCustomsExperienceConfig = {
  componentIntegration: boolean;
  experience: CTAExperience;
  dataObject: string;
  gtmEventLabel: string;
  emit: (evt: string, label: string) => void;
};

const getModalName = (experience: CTAExperience) =>
  CustomsCTAExperienceModalNames[experience];

const openCustomsVueComponentsIframe = (instance: ComponentInstance) => {
  const frame = document.querySelector('iframe[isCustomsIframe="true"]');
  const customsPage = document.querySelector('.customs-shoe-wrapper');
  if (frame) {
    frame.classList.remove('hidden');
    if (customsPage && instance.$viewport?.isSmall)
      customsPage.classList.add('opened');
  }
};

const openCustomsExperience = (
  instance: ComponentInstance,
  {
    componentIntegration,
    experience,
    dataObject,
    emit,
    gtmEventLabel,
  }: OpenCustomsExperienceConfig
): void => {
  if (componentIntegration) {
    const cmsRefStore = useCmsRefStore();
    const { defaultCurrency } = useI18n(instance);

    const { cmsSiteConfiguration } = storeToRefs(cmsRefStore);

    const currencySymbol =
      cmsSiteConfiguration.value?.commonConfig?.currencySymbol;

    instance.$modal.show(getModalName(experience), {
      caid: instance.$customsCAID,
      currencyCode: currencySymbol || defaultCurrency.value,
      domainName: `https://${instance.$getDomainName()}`,
      env: instance.$env.CUSTOMS_ENV,
      ...JSON.parse(dataObject),
    });
  } else {
    const openCommand =
      experience === 'styleSelectorIframeCallEvent'
        ? experience
        : `open${experience}Iframe`;
    instance.$vfIframeCommunicator.postMessage({
      command: openCommand,
      body: JSON.parse(dataObject),
    });

    openCustomsVueComponentsIframe(instance);
  }

  emit('btnClick', gtmEventLabel);
};

export default openCustomsExperience;
