



























import type { PropType } from 'vue';
import { defineComponent } from '@vue/composition-api';
import type { CartProductsTranslations } from '@vf/api-contract';
import type { NotAddedItem } from '@vf/api-client';

export default defineComponent({
  name: 'NotAddedItems',
  props: {
    translations: {
      type: Object as PropType<CartProductsTranslations>,
      required: true,
    },
    notAddedItems: {
      type: Array as PropType<NotAddedItem[]>,
      default: () => [],
    },
  },
});
