var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({ 'background-color': _vm.rowBackgroundColor })},[_c('VfContainer',{class:[
      'content-hero',
      _vm.variant ? ("content-" + _vm.variant) : '',
      _vm.bottomMargin ? ("bottom-" + _vm.bottomMargin) : '',
      !_vm.isInGrid ? 'top-spacing-default' : '' ]},[_c(_vm.mediaTarget ? 'VfLink' : 'div',{tag:"component",attrs:{"link":_vm.mediaTarget && _vm.mediaTarget.link,"open-in-new-tab":_vm.checkLinkBehavior(_vm.LinkBehavior.Tab),"scroll-to-component":_vm.checkLinkBehavior(_vm.LinkBehavior.Scroll),"open-in-new-modal":_vm.checkLinkBehavior(_vm.LinkBehavior.Modal)}},[(_vm.media.image)?_c('VfPicture',{staticClass:"content-hero__image",attrs:{"src":_vm.media.image,"alt":_vm.media.alt,"width":_vm.imageWidths,"height":_vm.imageHeights}}):_vm._e(),_vm._v(" "),(_vm.media.video)?_c('VfVideo',{staticClass:"content-hero__video",attrs:{"video":_vm.media.video}}):_vm._e()],1),_vm._v(" "),(_vm.hasOverlay)?_c('div',{staticClass:"content-hero__overlay",class:[
        _vm.overlayClass,
        _vm.textBlockPosition
          ? ("content-hero__position-" + _vm.textBlockPosition)
          : '' ]},[_c('div',{staticClass:"content-hero__overlay-wrapper"},[_c('div',{staticClass:"content-hero__titles"},[(_vm.title && _vm.title.text)?_c('ThemeTitle',{staticClass:"content-hero__title",class:{
              'content-hero--no-padding': _vm.isBackgroundTransparent(
                _vm.title.backgroundColor
              ),
              'content-hero--switched-color':
                _vm.isBackgroundTransparent(_vm.title.backgroundColor) &&
                _vm.isColorWhite(_vm.title.color),
            },attrs:{"variant":_vm.title.variant,"color-hex":_vm.title.color,"background-color-hex":_vm.title.backgroundColor,"level":"2"}},[_vm._v("\n            "+_vm._s(_vm.title.text)+"\n          ")]):_vm._e(),_vm._v(" "),(_vm.subtitle && _vm.subtitle.text)?_c('ThemeTitle',{staticClass:"content-hero__subtitle",class:{
              'content-hero--no-padding': _vm.isBackgroundTransparent(
                _vm.subtitle.backgroundColor
              ),
              'content-hero--switched-color':
                _vm.isBackgroundTransparent(_vm.subtitle.backgroundColor) &&
                _vm.isColorWhite(_vm.subtitle.color),
            },attrs:{"variant":_vm.subtitle.variant,"color-hex":_vm.subtitle.color,"background-color-hex":_vm.subtitle.backgroundColor,"level":"3"}},[_vm._v("\n            "+_vm._s(_vm.subtitle.text)+"\n          ")]):_vm._e()],1),_vm._v(" "),(_vm.ctas.length)?_c('ContentCTAButtons',{staticClass:"content-hero__cta-items",attrs:{"ctas":_vm.ctas}}):_vm._e()],1)]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }