





















import { defineComponent, PropType } from '@vue/composition-api';
import type { Image, VideoObject } from '@vf/api-contract';

export default defineComponent({
  name: 'CarouselSlideActivity',
  props: {
    image: Object as PropType<Image>,
    video: Object as PropType<{ small: VideoObject }>,
    teaserStyleCustomizations: Object as PropType<{ teaserTitleColor: string }>,
    text: String,
    link: String,
  },
  computed: {
    styles() {
      return {
        titleColor:
          this.teaserStyleCustomizations?.teaserTitleColor ?? '#ffffff',
      };
    },
  },
});
