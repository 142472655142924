































import type { PropType } from 'vue';
import type { ProductRatingTranslations } from '@vf/api-contract';
import { defineComponent, computed, inject } from '@vue/composition-api';
import useRootInstance from '@/shared/useRootInstance';
import { useProduct, useUrl, useReviews } from '@vf/composables';
import {
  scrollTo,
  computeStickyHeaderHeight,
} from '@vf/shared/src/utils/helpers';

export default defineComponent({
  name: 'ThemeProductRating',
  props: {
    translations: {
      type: Object as PropType<ProductRatingTranslations>,
      required: true,
    },
    /** Display percentage of positive reviews */
    showReviewRatio: {
      type: Boolean,
      default: false,
    },
    /** Select with page type */
    contextKey: {
      type: String,
      default: 'product',
    },
    /** Will show a skeleton loading animation while it's true */
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const {
      product,
      isQuickShopContext,
      shouldShowPdpStickyHeader,
    } = useProduct(root, props.contextKey);
    const { getProductUrl } = useUrl(root);
    const { isLoadingProductReviews, productReview } = useReviews(
      root,
      props.contextKey
    );

    // To be cleaned up with GLOBAL15-63801
    const isVansPdpRedesignEnabled = inject('isVansPdpRedesignEnabled');

    const score = computed(() => product.value.rating.score);
    const numReviews = computed(() => product.value.rating.numReviews);
    const reviewsRatio = computed(
      () => productReview.value?.recommendationsCount ?? 0
    );

    const ratingLink = computed(() => {
      const anchor = '#pr-container';
      if (!isQuickShopContext.value || !product.value) return anchor;
      const productURL = getProductUrl(product.value, {
        recipe:
          product.value.recipe ||
          product.value.recipeId ||
          product.value.customsRecipeID,
      });
      return productURL + anchor;
    });

    const showCustomerRecommendations = computed(
      () =>
        props.showReviewRatio &&
        reviewsRatio.value >= 50 &&
        !isLoadingProductReviews.value
    );

    const showCustomerRecommendationsPlaceholder = computed(
      () =>
        props.showReviewRatio &&
        (isLoadingProductReviews.value || !productReview.value)
    );

    const handleRatingClick = () => {
      const targetElement = document.getElementById('pr-container');

      if (!targetElement) return;

      const headerHeight = computeStickyHeaderHeight(
        root.$viewport?.isSmall,
        shouldShowPdpStickyHeader.value
      );

      scrollTo({ top: targetElement.offsetTop - headerHeight });
      targetElement.focus({
        preventScroll: true,
      });
    };

    return {
      handleRatingClick,
      isLoadingProductReviews,
      numReviews,
      product,
      productReview,
      ratingLink,
      reviewsRatio,
      score,
      showCustomerRecommendations,
      showCustomerRecommendationsPlaceholder,
      isVansPdpRedesignEnabled,
    };
  },
});
