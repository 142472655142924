//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { focus } from '@vf/shared/src/utils/directives';
import { inject } from '@vue/composition-api';

export default {
  name: 'VfSelect',
  directives: { focus },
  model: {
    prop: 'value',
    event: 'selected',
  },
  props: {
    /**
     * Select field label
     */
    label: {
      type: String,
      default: '',
    },
    /**
     * Select id
     */
    id: {
      type: String,
      default: '',
    },
    /**
     * Required attribute
     */
    required: {
      type: Boolean,
      default: false,
    },
    /**
     * Validate value of form select
     */
    valid: {
      type: Boolean,
      default: true,
    },
    /**
     * Disabled status of form select
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Error message value of form select. It will be appeared if `valid` is `true`.
     */
    errorMessage: {
      type: String,
      default: 'This field is not correct.',
    },
    /**
     * Adds placeholder
     */
    placeholder: {
      type: String,
      default: '',
    },
    /**
     * Give the icon we want to use
     */
    iconName: {
      type: String,
      default: 'chevron_down',
    },
    iconClasses: {
      type: String,
      default: 'vf-select__chevron',
    },
    /**
     * Custom size of the icon
     * It can be our standard sizes, or `12px` or `1.2rem` or nothing.
     * Standard sizes: `xxs`, `xs`, `sm`, `md`, `lg`, `xl`, `xxl`, `xl3`, `xl4`.
     */
    iconSize: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Array, Number, Object],
      default: '',
    },
    ariaLabel: {
      type: String,
      default: '',
    },
    excludeRedesign: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');
    return {
      isCoreRedesignEnabled,
    };
  },
  data: () => ({
    isFocused: false,
  }),
  computed: {
    labelClasses() {
      const isActiveState = this.isFocused || this.value || !this.valid;

      return {
        'vf-select__label': true,
        'vf-select__label--active': this.isCoreRedesignEnabled && isActiveState,
      };
    },
  },
  methods: {
    onBlur($event) {
      this.isFocused = false;
      this.$emit('blur', $event);
    },
  },
};
