//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'FitScale',
  // TODO: GLOBAL15-61059 remove after redesign core
  inject: ['isCoreRedesignEnabled'],
  props: {
    maxValue: {
      type: Number,
      default: 5,
    },
    value: {
      type: Number,
      default: 3,
    },
    minLabel: {
      type: String,
      default: '',
    },
    maxLabel: {
      type: String,
      default: '',
    },
    sameLineLabels: {
      type: Boolean,
      default: false,
    },
    heading: {
      type: [Boolean, String],
      default: false,
    },
  },
});
