














































































import {
  computed,
  defineComponent,
  nextTick,
  onBeforeUnmount,
  ref,
  Ref,
  watch,
} from '@vue/composition-api';
import {
  useAccount,
  useFindInStore,
  useProduct,
  useRequestTracker,
} from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import ls from '@vf/composables/src/utils/localStorage';
import {
  PdpShippingMethod,
  useCartStore,
} from '@vf/composables/src/store/cartStore';
import type { BrandifyStoreInfo } from '@vf/api-client';

import VfShippingDestination from '@vf/ui/components/Molecule.ShippingDestination.vue';
import VfShippingDestinationsStoreSearch from '../shippingDestinations/ShippingDestinationsStoreSearch.vue';
import {
  COOKIE_NAME,
  FILTER_URL,
} from '@vf/composables/src/useShippingFilter/utils';

export default defineComponent({
  name: 'VfShippingDestinations',
  components: {
    VfShippingDestination,
    VfShippingDestinationsStoreSearch,
  },
  props: {
    contextKey: {
      type: String,
      default: 'product',
    },
    visible: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { favoriteStoreId, setFavoriteStoreId } = useAccount(root);
    const { BRAND_ID_FIELD, getProductAvailabilities } = useFindInStore(root);
    const {
      checkAttributes,
      isQuickShopContext,
      product,
      scrollToFirstValidationError,
    } = useProduct(root, props.contextKey);
    const { onAllDone } = useRequestTracker(root);
    const cart = useCartStore();
    let isGeolocationUsed = false;

    const attributesNotValid = computed(() => checkAttributes({ lazy: true }));

    const productVariantId = computed(() => product.value?.variant?.id);

    const search = ref('');
    const showSearchForm = ref(false);
    const selectedStore: Ref<BrandifyStoreInfo | null> = ref(
      JSON.parse(ls.getItem('defaultStore'))
    );
    const isProductAvailableInStore = ref(true);
    const productAvailabilityFetching = ref(false);
    const refStoreSearch: Ref<InstanceType<
      typeof VfShippingDestinationsStoreSearch
    > | null> = ref(null);

    if (root.$route.query[FILTER_URL])
      cart.pdpShippingMethod = PdpShippingMethod.Pickup;

    const isSTS = ({ sts_enabled }: BrandifyStoreInfo) => sts_enabled === '1';
    const isBopis = ({ bopis_enabled, has_product }: BrandifyStoreInfo) =>
      has_product && bopis_enabled === '1';

    const checkProductAvailable = (store: BrandifyStoreInfo) =>
      isBopis(store) || isSTS(store);

    const displaySearchForm = async () => {
      showSearchForm.value = true;
      await nextTick();
      refStoreSearch.value?.focusOnInput?.();
    };

    const setStore = (store: BrandifyStoreInfo) => {
      if (
        !store ||
        selectedStore.value?.[BRAND_ID_FIELD] === store[BRAND_ID_FIELD]
      )
        return;
      selectedStore.value = store;
      ls.setItem('defaultStore', JSON.stringify(store));
      root.$cookies.set(COOKIE_NAME, store[BRAND_ID_FIELD]);
      if (favoriteStoreId.value !== store[BRAND_ID_FIELD]) {
        setFavoriteStoreId(store[BRAND_ID_FIELD]);
      }
      isProductAvailableInStore.value = true;
    };

    const scrollToError = () => {
      const {
        scrollToErrorOffsetWithTopStickyHeader,
        scrollToErrorOffset: scrollToErrorOffsetTheme,
      } = root.$themeConfig.productAddToCart;
      // TODO: GLOBAL15-63801 clean up
      const scrollToErrorOffset = !root.$viewport.isSmall
        ? scrollToErrorOffsetWithTopStickyHeader
        : scrollToErrorOffsetTheme;
      scrollToFirstValidationError(
        scrollToErrorOffset,
        root.$viewport.isSmall && isQuickShopContext.value
      );
    };

    const selectShipInStore = () => {
      if (attributesNotValid.value) {
        checkAttributes();
        scrollToError();
        return false;
      }
      cart.pdpShippingMethod = PdpShippingMethod.Pickup;
      return true;
    };

    const changeStore = () => {
      if (!selectShipInStore()) return;
      if (isGeolocationUsed) {
        displaySearchForm();
        return;
      }
      refStoreSearch.value?.searchByGeolocation?.({
        onComplete: () => {
          isGeolocationUsed = true;
          if (!selectedStore.value) {
            displaySearchForm();
          }
        },
        onError: displaySearchForm,
      });
    };

    const getVariantId = () => {
      if (product.value?.variant) {
        for (const attrKey in product.value.variant.attributes) {
          if (
            product.value[attrKey]?.value &&
            product.value.variant.attributes[attrKey] !==
              product.value[attrKey]?.value
          )
            return;
        }
        return product.value.variant.id;
      }
    };

    const availabilityCheck = async (productId?: string) => {
      if (productId) {
        try {
          productAvailabilityFetching.value = true;
          const availabilityResponse = await getProductAvailabilities(
            [selectedStore.value],
            productId
          );
          const quantity =
            availabilityResponse.data.storeInventory?.[0].quantity || 0;
          selectedStore.value = {
            ...selectedStore.value,
            has_product: quantity > 0,
            quantity,
          };
          isProductAvailableInStore.value = checkProductAvailable(
            selectedStore.value
          );
        } catch (err) {
          isProductAvailableInStore.value = false;
          root.$log.error(
            `[[@theme/components/static/pdp/ShippingDestinations::availabilityCheck]: Failed getProductAvailabilities of productId: ${productId}`,
            err
          );
        } finally {
          productAvailabilityFetching.value = false;
        }
      }
    };

    let unwatchAvailabilityCheck = () => null;

    onAllDone(() => {
      unwatchAvailabilityCheck = watch(
        [attributesNotValid, () => product.value?.variant?.id],
        async () => {
          if (
            !selectedStore.value ||
            attributesNotValid.value ||
            !props.visible
          )
            return;
          const variantId = getVariantId();
          if (!variantId) return;

          if (showSearchForm.value) {
            search.value = search.value || selectedStore.value?.postalcode;
            await refStoreSearch.value?.searchByPC?.();
          }
          await availabilityCheck(variantId);
        },
        {
          immediate: true,
        }
      );
    });

    onBeforeUnmount(() => {
      unwatchAvailabilityCheck();
    });

    const onChangeAvailability = (selectedStore: BrandifyStoreInfo) => {
      if (selectedStore)
        isProductAvailableInStore.value = checkProductAvailable(selectedStore);
    };

    return {
      attributesNotValid,
      cart,
      changeStore,
      displaySearchForm,
      isBopis,
      isProductAvailableInStore,
      isSTS,
      onChangeAvailability,
      PdpShippingMethod,
      productAvailabilityFetching,
      productVariantId,
      refStoreSearch,
      search,
      selectedStore,
      selectShipInStore,
      setStore,
      showSearchForm,
    };
  },
});
