






































import {
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  Ref,
  PropType,
} from '@vue/composition-api';
import { useProduct } from '@vf/composables';
import { load } from '@vf/shared/src/utils/helpers/load-script';
import useRootInstance from '@/shared/useRootInstance';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

type OlapicCredentials = {
  id: string;
  newWidgetId: { pdp: string; customs: string; homepage: string };
  newWidgetTestingId: string;
  locale: string;
  buildPath: string;
  apiKey: string;
  instance: string;
};

export default defineComponent({
  name: 'OlapicWidget',
  props: {
    contextKey: {
      type: String,
      default: 'product',
    },
    instance: {
      type: String,
      default: '',
    },
    credentials: {
      type: Object as PropType<OlapicCredentials | null>,
      required: false,
      default: null,
    },
  },
  setup(props, { emit }) {
    const { root } = useRootInstance();
    const {
      isOlapicNewWidget,
      isOlapicNewWidgetTestingData,
      isCustomsOlapicNewWidget,
      isVansPdpRedesignEnabled,
    } = useFeatureFlagsStore();
    const { product } = useProduct(root, props.contextKey);
    const scriptId: Ref<string> = ref('');
    const widgetSrc: Ref<string> = ref('');
    const showStaticText: Ref<boolean> = ref(false);

    let widgetType: 'new' | 'old';
    let instanceId: string;
    // This boolean toogles a CSS class which modifies the layout.
    let isOlapicComponentOnPdp: boolean;
    let isOlapicComponentOnCustomsPage: boolean;

    const credentials: OlapicCredentials = props.credentials
      ? props.credentials
      : root.$getConfigValueByCurrentLocale('OLAPIC_CREDENTIALS');

    let newWidgetId: string;

    const SCRIPT_SRC = `https://photorankstatics-a.akamaihd.net/${credentials.buildPath}/static/frontend/latest/build.min.js`;

    let tag: string;
    const getTagByFeatureFlag = (featureFlag: boolean) => {
      if (featureFlag && isOlapicNewWidgetTestingData) {
        return credentials.newWidgetTestingId;
      }
      return product.value?.masterId || '';
    };

    // Olapic component is still used in customs page driven by CMS.
    // The only different value in credentials is the instance, that's why
    // we need to check if the instance is passed as prop or we can use the one
    // set in HC for static PDP.
    if (props.instance) {
      isOlapicComponentOnPdp = false;
      instanceId = props.instance;
      widgetType = isCustomsOlapicNewWidget ? 'new' : 'old';
      isOlapicComponentOnCustomsPage = root.$route.fullPath.includes('customs');
      newWidgetId = isOlapicComponentOnCustomsPage
        ? credentials.newWidgetId.customs
        : credentials.newWidgetId.homepage;
      tag = getTagByFeatureFlag(isCustomsOlapicNewWidget);
    } else {
      isOlapicComponentOnPdp = true;
      instanceId = credentials.instance;
      widgetType = isOlapicNewWidget ? 'new' : 'old';
      newWidgetId = credentials.newWidgetId.pdp;
      tag = getTagByFeatureFlag(isOlapicNewWidget);
    }

    // Hack as window.OlapicSDK object isn't set right after script onload.
    const timeout = ref<ReturnType<typeof setTimeout>>(null);
    const waitForOlapicSDK = () => {
      let runs = 0;
      const maxRuns = 50;

      return new Promise((resolve, reject) => {
        const checkOlapicSDK = () => {
          clearTimeout((timeout.value as unknown) as number);
          timeout.value = setTimeout(() => {
            if (window.OlapicSDK) {
              resolve(window.OlapicSDK);
              return;
            }
            if (runs++ >= maxRuns) {
              reject(`OlapicSDK can't be loaded!`);
              return;
            }
            checkOlapicSDK();
          }, 200);
        };
        checkOlapicSDK();
      });
    };

    onMounted(async () => {
      if (widgetType === 'old' && !credentials.id) {
        root.$log.warn(
          `[@theme/components/smart/shared/Olapic.vue::onMounted] Missing credentials for old widget`
        );
        return;
      }
      document.getElementById('olapic-placeholder-id').id =
        widgetType === 'new' ? 'olapic-widget-' + newWidgetId : credentials.id;

      if (window.OlapicSDK) {
        widgetType === 'new' ? loadOlapicNewWidget() : loadOlapicWidget();
      } else {
        return load(SCRIPT_SRC)
          .then(async () => {
            return waitForOlapicSDK().then(() => {
              widgetType === 'new' ? loadOlapicNewWidget() : loadOlapicWidget();
            });
          })
          .catch((err) => {
            root.$log.error(
              `[@theme/components/smart/shared/Olapic.vue::onMounted] Error while loading <${SCRIPT_SRC}>`,
              { err }
            );
          });
      }
    });

    const loadOlapicNewWidget = () => {
      scriptId.value = `olapic-widget-${newWidgetId}-script`;
      widgetSrc.value = `https://widgets.olapic-cdn.com/${newWidgetId}/${credentials.locale}`;
    };

    const loadOlapicWidget = () => {
      window.OlapicSDK.conf.set('apikey', credentials.apiKey);
      return new window.OlapicSDK.Olapic((olapicInstance) => {
        olapicInstance.prepareWidget(
          {
            id: instanceId,
            tags: tag,
            wrapper: credentials.id,
          },
          { renderNow: true, force: true }
        );

        emit('third-party-loaded');
      });
    };

    onUnmounted(() => {
      clearTimeout((timeout.value as unknown) as number);
    });

    const onLoadActions = () => {
      showStaticText.value =
        !isOlapicComponentOnCustomsPage && !isOlapicComponentOnPdp;
    };

    return {
      widgetType,
      showStaticText,
      isOlapicComponentOnPdp,
      scriptId,
      widgetSrc,
      tag,
      isVansPdpRedesignEnabled,
      onLoadActions,
    };
  },
});
