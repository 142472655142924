







// TODO: GLOBAL15-6675 check if component is deprecated, not used anymore
import { computed, defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'ShippingLabel',
  props: {
    /** Translations object containing keys matching to OBJECT KEY  */
    translations: {
      type: Object,
      default: () => ({
        express: {
          line1: 'Express Shipping',
          line2: '1-2 business days',
        },
        standard: {
          line1: 'Standard Shipping',
          line2: '2-3 business days',
        },
        pobox: {
          line1: 'Pobox Shipping',
          line2: '3-4 business days',
        },
        customs: {
          line1: 'Customs Shipping',
          line2: '2-3 weeks',
        },
      }),
    },
    /** OBJECT KEY to find matching translations object */
    objectKey: {
      type: String,
      default: 'standard',
    },
  },
  setup(props) {
    const getTranslationsByKey = computed(() => {
      const result = Object.keys(props.translations).find((el) =>
        props.objectKey.toLowerCase().includes(el)
      );
      return props.translations[result];
    });
    return {
      getTranslationsByKey,
    };
  },
});
