



























import { defineComponent, onMounted } from '@vue/composition-api';
import { Section, Carousel, ProductCard } from '@gemini/pollux';
import { useCategory } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';

type Prices = {
  price: string | null;
  price2: string | null;
  salesPrice: string | null;
};

// Hard coding this value to match the catory id that is in SFCC.
// In the future we can have this editable from the CSM and passed in here.
const CATEGORY_ID = '10965782';

export default defineComponent({
  name: 'GeminiSectionCarousel',
  components: {
    'gemini-section-carousel': Section,
    'gemini-carousel': Carousel,
    'gemini-product-card': ProductCard,
  },
  props: {
    pattern: {
      type: String,
    },
    wrapper: {
      type: String,
    },
    heading: {
      type: String,
    },
    copy: {
      type: String,
    },
    ctaCopy: {
      type: String,
    },
    ctaUrl: {
      type: String,
    },
    ctaHasIcon: {
      type: Boolean,
    },
    ctaTarget: {
      type: String,
    },
  },
  setup() {
    const { root } = useRootInstance();
    const { getCatalog, products: catalogProducts } = useCategory(root);

    onMounted(async () => {
      await getCatalog({
        filters: [
          {
            code: 'cgid',
            value: CATEGORY_ID,
          },
          {
            code: 'splitByColor',
            value: 'false',
          },
        ],
      });
    });

    const getImgUrl = (imageUrl) => {
      return (
        imageUrl + `&hei=650&wid=555&qlt=50&resMode=sharp2&op_sum=0.9,1.0,8,0`
      );
    };

    const getPrice = (productDetails): Prices => {
      const prices = {
        price: null,
        price2: null,
        salesPrice: null,
      };

      if (!productDetails?.price) return prices;

      const currency = productDetails.price.currency;

      if (
        productDetails.displayPriceRange &&
        productDetails.price.salePriceRange.minPrice !==
          productDetails.price.salePriceRange.maxPrice
      ) {
        prices.price = root.$formatPrice(
          productDetails.price.salePriceRange.minPrice,
          currency
        );
        prices.price2 = root.$formatPrice(
          productDetails.price.salePriceRange.maxPrice,
          currency
        );
        return prices;
      }

      const defaultColor =
        productDetails.color_swatches.find((color) => {
          return color.defaultColor === true;
        }) || productDetails.color_swatches[0];

      prices.price = root.$formatPrice(defaultColor.price.original, currency);

      const salesPrice = root.$formatPrice(
        defaultColor.price.current,
        currency
      );
      prices.salesPrice = prices.price !== salesPrice ? salesPrice : null;

      return prices;
    };

    return {
      getCatalog,
      catalogProducts,
      getImgUrl,
      getPrice,
    };
  },
});
