
























import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  computed,
  ref,
  watch,
} from '@vue/composition-api';
import Vue from 'vue';
import { useCustoms, useI18n, useCart, useProduct } from '@vf/composables';
import {
  addToCart,
  addToFavorites,
  dataLayerPush as dataLayerPushAction,
  handleModelChange as handleModelChangeAction,
  toggledBuyPanel as toggledBuyPanelAction,
  toggleHeader,
  openSignInToBuy,
} from './actions';
import useRootInstance from '@/shared/useRootInstance';
import { useCmsRefStore } from '@vf/composables/src/store/cmsRef';
import { storeToRefs } from 'pinia';
import useModal from '@/shared/useModal';
import {
  extractProperty,
  extractCommonConfigLink,
} from '@vf/composables/src/useCms/mappings/utils';

export default defineComponent({
  name: 'CustomsCustomizer',
  setup() {
    const cmsRefStore = useCmsRefStore();
    const { root } = useRootInstance();
    const rootOptions = root.$options as Vue;

    const { defaultCurrency } = useI18n(root);
    const { product } = useProduct(root, 'page-content');
    const { getStyleCodeForModel } = useCustoms(root);

    const { cmsSiteConfiguration } = storeToRefs(cmsRefStore);

    const { openModal, isModalOpen } = useModal();

    // Check the availability of the customizer plugin
    const componentIntegration = ref(null);
    const componentConfig = JSON.stringify({
      domain: `https://${root.$getDomainName()}`,
    });

    // BUILD DYNAMIC URL
    const locale = root.$i18n.locale.replace('-', '_');
    const caid = root.$customsCAID;
    const customsNoraUrl = root.$env.CUSTOMS_NORA;
    const model = root.$route.params.model;

    const currencySymbol =
      cmsSiteConfiguration.value?.commonConfig?.currencySymbol;

    const { totalItems } = useCart(root);

    const loyaltyEnrollment = extractProperty(
      extractCommonConfigLink(
        cmsSiteConfiguration.value,
        'loyalty-enrollment-modal'
      ),
      '[0]'
    )?.id;

    const queryParams = ref({
      model,
      locale,
      caid,
      vsf: componentIntegration.value,
      currencyCode: currencySymbol || defaultCurrency.value,
      domainName: `https://${root.$getDomainName()}`,
      env: root.$env.CUSTOMS_ENV,
      totalItems: totalItems.value,
      ...root.$route.query,
      preCreatedCustomCode: product.value?.customsRecipeID
        ? product.value.id
        : '',
      id: product.value ? product.value.id : '',
    } as Record<string, string>);

    watch(
      () => totalItems.value,
      (val) => {
        queryParams.value.totalItems = val;
      }
    );

    if (queryParams.value.ugcSize) {
      queryParams.value.ugcSize = encodeURIComponent(queryParams.value.ugcSize);
    }

    const queryString = computed(() =>
      Object.entries(queryParams.value)
        .map((pair) => pair.join('='))
        .join('&')
    );

    const customURL = computed(
      () => `${customsNoraUrl}?${queryString.value}&no-esi=true`
    );

    const startOver = () => window.location.reload();
    const dataLayerPush = dataLayerPushAction.bind(null, root);
    const toggledBuyPanel = toggledBuyPanelAction.bind(null, root);
    const handleModelChange = handleModelChangeAction.bind(null, root);

    let customizerAddToCart;
    let customizerAddToFavorites;
    let customsLaunchSignInToBuy;

    onMounted(async () => {
      const recipe = root.$route.query.recipe as string;

      queryParams.value.styleCode = await getStyleCodeForModel({
        model,
        recipe,
      });

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      componentIntegration.value = !!(Vue as any)?.options.components
        .customizer;

      customizerAddToCart = addToCart.bind(this, root, {
        toggleHeaderOnSuccess: true,
        componentIntegration: componentIntegration.value,
      });

      customizerAddToFavorites = addToFavorites.bind(this, root, {
        componentIntegration: componentIntegration.value,
      });

      customsLaunchSignInToBuy = openSignInToBuy.bind(this, root, {
        openModal,
        isModalOpen,
        loyaltyEnrollment,
      });

      if (componentIntegration.value) {
        root.$eventBus.$on('customsToggleHeader', toggleHeader);
        root.$eventBus.$on('customsAddToCart', customizerAddToCart);
        root.$eventBus.$on('customsAddToFavorites', customizerAddToFavorites);
        root.$eventBus.$on('customsStartOver', startOver);
        root.$eventBus.$on('customsBuyPanel', toggledBuyPanel);
        root.$eventBus.$on('customsModelChanged', handleModelChange);
        root.$eventBus.$on('dataLayerPush', dataLayerPush);
        root.$eventBus.$on(
          'customsLaunchSignInToBuy',
          customsLaunchSignInToBuy
        );
      } else {
        rootOptions.$eventBus.$on('toggleHeader', toggleHeader);
        rootOptions.$eventBus.$on('addToCart', customizerAddToCart);
        rootOptions.$eventBus.$on('addToFavorites', customizerAddToFavorites);
        rootOptions.$eventBus.$on('startOver', startOver);
        rootOptions.$eventBus.$on('toggledBuyPanel', toggledBuyPanel);
        rootOptions.$eventBus.$on('modelChanged', handleModelChange);
        rootOptions.$eventBus.$on('dataLayerPush', dataLayerPush);
        rootOptions.$eventBus.$on(
          'customsLaunchSignInToBuy',
          customsLaunchSignInToBuy
        );
      }
    });

    onBeforeUnmount(() => {
      rootOptions.$eventBus.$off('toggleHeader', toggleHeader);
      rootOptions.$eventBus.$off('addToCart', customizerAddToCart);
      rootOptions.$eventBus.$off('addToFavorites', customizerAddToFavorites);
      rootOptions.$eventBus.$off('startOver', startOver);
      rootOptions.$eventBus.$off('toggledBuyPanel', toggledBuyPanel);
      rootOptions.$eventBus.$off('modelChanged', handleModelChange);
      rootOptions.$eventBus.$off('dataLayerPush', dataLayerPush);
      root.$eventBus.$off('customsToggleHeader', toggleHeader);
      root.$eventBus.$off('customsAddToCart', customizerAddToCart);
      root.$eventBus.$off('customsAddToFavorites', customizerAddToFavorites);
      root.$eventBus.$off('customsStartOver', startOver);
      root.$eventBus.$off('customsBuyPanel', toggledBuyPanel);
      root.$eventBus.$off('customsModelChanged', handleModelChange);
      root.$eventBus.$off('dataLayerPush', dataLayerPush);
      root.$eventBus.$off('customsLaunchSignInToBuy', customsLaunchSignInToBuy);
    });

    return {
      customURL,
      queryParams,
      componentConfig,
      componentIntegration,
    };
  },
});
