import { format } from 'date-fns';
import { LocaleDataObject } from '@vf/api-contract';
import { getDateFnsLocale } from './getDateFnsLocale';

// TODO: GLOBAL15-56318 Get rid of this helper after checkout redesing (it is replaced by Nuxt plugin)

/**
 * Returns a formatted delivery date label.
 * Important: returned string may contain HTML entities.
 *
 * @param dateString input string containing timestamp or date
 * @param dateTemplate base wrapping template for estimated date label
 * @param useTemplateForInvalidDate boolean value to determine if template should be used for invalid date
 * @param useNbsp boolean value to determine if non breaking space should be used for dangling day digits
 * @param locale object value to pass informations about current locale
 * @returns formatted date string
 */
export const getDeliveryDateLabel = (
  dateString: string = '',
  dateTemplate: string = '',
  useTemplateForInvalidDate: boolean = true,
  useNbsp: boolean = true,
  locale: LocaleDataObject = {
    localeCode: 'en-US',
    localeMapping: '{"en-us":"en_US"}',
  }
): string => {
  if (!dateString) return '';
  let date;
  try {
    // GLOBAL15-3740 When two dates provided, maximum date should be used
    const [minDateString, maxDateString] = dateString.split(',');
    const localTimeZoneDate = new Date(maxDateString ?? minDateString);
    date = new Date(
      localTimeZoneDate.valueOf() +
        localTimeZoneDate.getTimezoneOffset() * 60 * 1000
    );
    if (!dateTemplate) return dateString;
  } catch (e) {
    return dateString;
  }
  const [startMarker, endMarker] = ['{{', '}}'];
  const startIndex = dateTemplate.lastIndexOf(startMarker);
  const endIndex = dateTemplate.indexOf(
    endMarker,
    startIndex + startMarker.length
  );
  if (startIndex === -1 || endIndex === -1) return dateTemplate;
  const dateFormat = dateTemplate.substring(
    startIndex + startMarker.length,
    endIndex
  );
  const placeholder = `${startMarker + dateFormat + endMarker}`;
  try {
    const hangingDigitsRegex = /( )(\d+)/;
    const dateFnsLocale = getDateFnsLocale(
      locale?.localeCode,
      locale?.localeMapping
    );
    const formattedDate = format(date, dateFormat, {
      ...(dateFnsLocale && { locale: dateFnsLocale }),
    });
    return dateTemplate.replace(
      placeholder,
      formattedDate.replace(hangingDigitsRegex, `${useNbsp ? '&nbsp;' : ' '}$2`)
    );
  } catch (e) {
    if (useTemplateForInvalidDate) {
      return dateTemplate.replace(placeholder, dateString);
    }
    return dateString;
  }
};
