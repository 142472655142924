






























import type { PropType } from 'vue';
import {
  computed,
  defineComponent,
  nextTick,
  onUnmounted,
  ref,
  watch,
} from '@vue/composition-api';
import { PaymentMethodCode } from '@vf/api-client';
import type { CheckoutGiftCardTranslations } from '@vf/api-contract';
import {
  ROUTES,
  useCart,
  useCheckout,
  useNotification,
  useCmsLinks,
  useI18n,
  useReCaptcha,
  useGiftCards,
  useApplePay,
} from '@vf/composables';
import get from '@vf/composables/src/utils/get';
import GiftCardForm from '@/components/payment/GiftCardForm.vue';
import useRootInstance from '@/shared/useRootInstance';
import useModal from '@/shared/useModal';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@vf/composables/src/store/user';

export default defineComponent({
  name: 'CheckoutGiftCard',
  components: {
    GiftCardForm,
  },
  props: {
    translations: {
      type: Object as PropType<CheckoutGiftCardTranslations>,
      required: true,
    },
    /** Flag to determine if Gift Card accordion should be expanded by default */
    accordionOpened: Boolean,
    /** Image with information where customer can find gift card number and PIN */
    tooltipHelpImage: String,
    /** Maximum gift/reward cards possible to add per order */
    maxGiftCards: Number,
    /** Sign in link for customer in reward error notification */
    signInLink: {
      type: String,
      default: ROUTES.SIGN_IN(),
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const userStore = useUserStore(root);
    const { loggedIn, loyaltyEnrolled } = storeToRefs(userStore);
    const { applyGiftCard, removeGiftCard } = useGiftCards(root);
    const {
      paymentMethod,
      paymentFormAccordion,
      isZeroOrder,
      paymentMethodsData,
    } = useCheckout(root);
    const {
      appliedGiftCards,
      payFullWithGiftCard,
      hasItems,
      appliedRewardCards,
    } = useCart(root);
    const { localePath } = useI18n(root);
    const {
      addNotification,
      clearNotifications,
      notifications,
    } = useNotification(root);
    const { setCmsLinksRef, unsetCmsLinks } = useCmsLinks(root, useModal());
    const { isApplePayAvailable, unselectApplePayAsDefault } = useApplePay(
      root
    );

    const giftCardNotificationRef = ref(null);

    const isVisible = computed(() => hasItems.value && !isZeroOrder.value);
    const isAcordionOpened = ref(props.accordionOpened);

    const setGiftCartPaymentMethod = () => {
      if (payFullWithGiftCard.value) {
        paymentFormAccordion.value = false;
        paymentMethod.value = PaymentMethodCode.GIFT_CARD;
      }
    };

    const onClickApplyGiftCard = async (data) => {
      const response = await applyGiftCard(data);

      if ([200, 201].includes(response?.status)) {
        const paymentInstruments = response?.data?.payment_instruments;
        const method = paymentInstruments[paymentInstruments.length - 1];
        clearNotifications();
        addNotification({
          message:
            method.payment_method_id === PaymentMethodCode.REWARD_CARD
              ? props.translations.applyRewardCardSuccessMessage
              : props.translations.applySuccessMessage,
          type: 'success',
          context: 'checkout-gift-card',
        });
      } else {
        checkAndDisplayRewardError(response.data, data);
      }
    };

    const onClickRemoveGiftCard = async (data) => {
      const response = await removeGiftCard(data);
      if ([200, 201].includes(response?.status)) {
        if (!payFullWithGiftCard.value) {
          paymentFormAccordion.value = true;
          paymentMethod.value = paymentMethodsData.value[0]?.code;
          if (!isApplePayAvailable()) {
            unselectApplePayAsDefault();
          }
        }
        clearNotifications();
        addNotification({
          message:
            data.method === PaymentMethodCode.REWARD_CARD
              ? props.translations.removeRewardCardSuccessMessage
              : props.translations.removeSuccessMessage,
          type: 'success',
          context: 'checkout-gift-card',
        });
      }
    };

    enum RewardError {
      Invalid = 'RewardCardNotAllowed',
      NotLoggedIn = 'RewardCardsIsNotPermittedForGuests',
    }
    const checkAndDisplayRewardError = (err: unknown, data) => {
      const responseSatusCode = get(
        err,
        'errorDetails[1].additionalInfo[0].argument.statusCode'
      );

      let message = null;
      if (responseSatusCode === RewardError.Invalid) {
        message = props.translations.invalidRewardCardErrorMessage;
      }
      if (responseSatusCode === RewardError.NotLoggedIn) {
        if (props.translations.notLoggedinRewardCardErrorMessageModal) {
          message = props.translations.notLoggedinRewardCardErrorMessageModal;
        } else {
          // TODO: GLOBAL15-41999 - remove notLoggedinRewardCardErrorMessage key and always use modal
          message = props.translations.notLoggedinRewardCardErrorMessage.replace(
            '{{signInURL}}',
            localePath(props.signInLink)
          );
        }

        // This part of code is for auto-apply reward card after sign in
        const unwatch = watch(loggedIn, async (value) => {
          if (value) {
            unwatch();
            if (
              appliedRewardCards.value.find(
                ({ c_gcCardNumber }) => c_gcCardNumber === data.cardNumber
              )
            ) {
              return;
            }
            const recaptchaToken = await useReCaptcha(root).executeRecaptcha(
              'giftCard'
            );
            await onClickApplyGiftCard({
              ...data,
              recaptchaToken,
            });
          }
        });
      }

      if (message !== null) {
        clearNotifications();

        addNotification({
          message,
          type: 'danger',
          context: 'checkout-gift-card',
        });
        nextTick(() => {
          setCmsLinksRef(giftCardNotificationRef.value);
        });
      }
    };

    const giftCardNotification = computed(() =>
      notifications.value.find((n) => n.context === 'checkout-gift-card')
    );

    const showDifferentTitleForNotLoggedIn =
      root.$themeConfig?.checkoutGiftCard?.showDifferentTitleForNotLoggedIn ||
      false;

    const accordionTitle = computed(() => {
      return (!loggedIn.value || !loyaltyEnrolled.value) &&
        showDifferentTitleForNotLoggedIn
        ? props.translations.titleForNotLoggedIn
        : props.translations.title;
    });

    watch(giftCardNotification, () => {
      isAcordionOpened.value = true;
    });

    watch(appliedGiftCards, (val) => val.length && setGiftCartPaymentMethod());

    onUnmounted(() => {
      unsetCmsLinks();
    });

    return {
      isVisible,
      isAcordionOpened,
      giftCardNotification,
      giftCardNotificationRef,
      clearNotifications,
      onClickApplyGiftCard,
      onClickRemoveGiftCard,
      accordionTitle,
      loyaltyEnrolled,
    };
  },
});
