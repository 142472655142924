




import { defineComponent } from '@vue/composition-api';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';
import { PropType } from '@vue/composition-api';
import { VideoObject } from '@vf/api-contract/src';

export default defineComponent({
  name: 'VideoComponent',
  serverCacheKey: getCacheKeyFromProps,
  props: {
    /** Image source object */
    video: {
      type: Object as PropType<{
        small: VideoObject;
        medium: VideoObject;
        large: VideoObject;
      }>,
      required: true,
    },
  },
});
