/**
 * Function to capitalize strings.
 * @param {string} str String to be modified.
 * @param {boolean=false} lower Lower Whether all other letters should be lowercased.
 * @returns {string} Modified string.
 */
export const capitalizeText = (str: string, lower = false): string => {
  if (str) {
    return (lower ? str.toLowerCase() : str).replace(/\S+/g, (word) =>
      word.replace(/\w/, (match) => match.toUpperCase())
    );
  }

  return '';
};
