

































import type { PropType } from 'vue';
import type { Address } from '@vf/api-client/src/types';

export default {
  name: 'AddressPreview',
  props: {
    address: {
      type: Object as PropType<Address>,
      required: true,
    },
    isDataProtected: {
      type: Boolean,
      default: true,
    },
  },
};
