

























import { ref, defineComponent, PropType } from '@vue/composition-api';
import { ProductAdditionalDetailsTranslations } from '@vf/api-contract';
import { useProduct } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

export default defineComponent({
  name: 'ProductAdditionalDetails',
  props: {
    translations: {
      type: Object as PropType<ProductAdditionalDetailsTranslations>,
      default: () => ({}),
    },
    types: {
      type: Array,
      default: () => ['description', 'features', 'productDetails'],
    },
  },

  setup(props) {
    const { root } = useRootInstance();
    const { product } = useProduct(root, 'page-content');
    const { isVansPdpRedesignEnabled } = useFeatureFlagsStore();

    const activeType = ref('description');

    const toggleHandler = (type: string) => {
      activeType.value = type;
    };

    const accordionContentClass = (type) => {
      return `vf-product-details__${type} ${
        type === 'features' ? 'vf-product-details__two-column-list' : ''
      }`;
    };

    const getProductDetailsValue = (productDetailType) => {
      if (
        Array.isArray(product.value[productDetailType]) &&
        product.value[productDetailType].length
      ) {
        let productDetailHtml = '';
        if (productDetailType == 'productDetails') {
          const styleId = product.value.masterId ?? product.value.id;
          const productDetailValuesTop = styleId
            ? [`${props.translations.style}|${styleId}`]
            : [];
          productDetailValuesTop
            .concat(product.value[productDetailType])
            .forEach((listItem) => {
              if (listItem.indexOf('|') !== -1) {
                const listItemArray = listItem.split('|');
                productDetailHtml += `<div><div class="vf-details-title">${listItemArray[0]}:</div> <div>${listItemArray[1]}</div></div>`;
              } else {
                productDetailHtml += listItem;
              }
            });
        } else {
          productDetailHtml = '<ul>';
          product.value[productDetailType]
            .filter((feature) => !(feature as string).includes('|')) // GLOBAL15-91032 filter out features used for compare feature
            .forEach((listItem) => {
              productDetailHtml += `<li>${listItem}</li>`;
            });
          productDetailHtml += '</ul>';
        }
        return productDetailHtml;
      }
      return product.value[productDetailType];
    };

    return {
      product,
      activeType,
      toggleHandler,
      getProductDetailsValue,
      accordionContentClass,
      isVansPdpRedesignEnabled,
    };
  },
});
