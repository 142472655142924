type ToggleHeaderEventBody = {
  visible?: boolean;
};

type ToggleHeaderEvent = ToggleHeaderEventBody & {
  body?: ToggleHeaderEventBody;
};

const toggleHeader = (event: ToggleHeaderEvent): void => {
  const visible = 'body' in event ? event.body.visible : event.visible;
  const header = document.querySelector('.vf-header__container');

  const iframe = document.querySelector('.customs__i-frame-container');
  const component = document.querySelector('.customs__component-container');
  const target = iframe || component;

  if (target) {
    if (visible) {
      header.classList.add('vf-header__container--customs');

      const headerHeight = header.clientHeight;
      target.setAttribute(
        'style',
        `height: calc(100vh - ${headerHeight}px); top: ${headerHeight}px;`
      );
    } else {
      target.setAttribute('style', 'height: 100vh; top: 0;');
      header.classList.remove('vf-header__container--customs');
    }
  }
};

export default toggleHeader;
