import { ApproachType } from 'packages/api-client/src/types';
import { Product } from './product';

export interface UserDataAnalytics {
  attributes: UserAttribute[];
  hashedEmail: string;
  platformId: string;
  salesforceId: string;
  lastPurchaseDate: string;
  loggedIn: boolean;
  loyaltyMember: boolean;
}

export interface UserAttribute {
  id: string;
  value: string;
  label: string;
}

export interface CustomerMainData {
  firstName: string;
  lastName: string;
  email: string;
}

export interface CustomerBasicInformation extends CustomerMainData {
  gender: number;
  dob: number;
}

/**
 * @toDo in my opinion, we should change it to int
 */
export interface CustomerInterests {
  interests: string[];
}

export interface CustomerAddress {
  firstName: string;
  lastName: string;
  email: string;
  country: number;
  streetAddress: string;
  building: string;
  city: string;
  province: number;
  phone: string;
  isBilling: boolean;
  isShipping: boolean;
}

export interface MyFavoriteProducts {
  products: Product;
}

export interface ConsumerAddress {
  id: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  approachType: ApproachType;
  city: string;
  country: string;
  countryCode: string;
  postalCode: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  province: string;
  main: boolean;
  disabled?: boolean;
  recipientFirstName: string;
  recipientLastName: string;
  recipientContactPhone: string;
  recipientContactEmail: string;
  stateCityProvDept: string;
}

export enum ShoesSizeGender {
  Female = 'F',
  Male = 'M',
  Unknown = 'U',
}

export type BasicInformationObject = {
  loyaltyOptInDate: string;
  firstName: string;
  lastName: string;
  email: string;
  gender?: string;
  birthDate?: string;
  postalCode?: string;
  phone?: string;
  mobilePhone?: string;
  primaryPhoneAtBrand?: string;
  mobilePhoneSubscriptionList?: string[];
  address: Record<string, any>[];
  subscriptions: Record<string, never>[];
  enrollments: Record<string, never>[];
  preferences: {
    languageCode: string;
    countryCode: string;
    currencyCode: string;
    interests: string;
    preferredShoeSize?: string;
    preferredShoeSizeGender?: ShoesSizeGender | string;
  };
  employee?: {
    employee?: boolean;
    currentSpend: number;
    annualCap: number;
  };
  athlete?: {
    athlete?: boolean;
    currentSpend: number;
    annualCap: number;
  };
  prosumer?: {
    prosumer?: boolean;
    currentSpend: number;
    annualCap: number;
  };
};
