











import { defineComponent } from '@vue/composition-api';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

export default defineComponent({
  name: 'EmptyCart',
  setup() {
    // TODO: GLOBAL15-61059 remove after redesign core
    const { isCoreRedesignEnabled } = useFeatureFlagsStore();

    return {
      isCoreRedesignEnabled,
    };
  },
});
