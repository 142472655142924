





























































import type { PropType } from 'vue';
import {
  onMounted,
  ref,
  defineComponent,
  computed,
} from '@vue/composition-api';
import {
  useAccount,
  useAuthentication,
  useNotification,
} from '@vf/composables';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import type { OrderHistoryTranslations } from '@vf/api-contract';
import useRootInstance from '@/shared/useRootInstance';

const OrderCard = () =>
  import(
    /* webpackChunkName: "OrderCard" */
    /* webpackMode: "lazy" */
    '../../order/OrderCard.vue'
  );

export default defineComponent({
  name: 'OrderHistory',
  components: { OrderCard },
  props: {
    translations: {
      type: Object as PropType<OrderHistoryTranslations>,
      required: true,
    },
    showPageTitle: {
      type: Boolean,
      default: false,
    },
    /** Flag to enable returns interface */
    historyPageType: {
      type: String,
      default: 'order',
    },
  },
  setup(props) {
    const { root } = useRootInstance();

    const { consumerNo } = useAuthentication(root);
    const {
      getBasicInformation,
      getOrderHistory,
      orderHistory,
      getReturnHistory,
      returnHistory,
    } = useAccount(root);
    const { addNotification } = useNotification(root);
    const {
      areOnlineReturnsEnabled,
      areNarvarReturnsEnabled,
    } = useFeatureFlagsStore();

    const isReturns = computed(
      () =>
        props.historyPageType === 'return' &&
        (areOnlineReturnsEnabled || areNarvarReturnsEnabled)
    );

    const theme = isReturns.value
      ? root.$themeConfig?.returnOrderCard
      : root.$themeConfig?.orderCard;

    const showNoResultsAsNotification =
      theme?.showNoResultsAsNotification ?? false;

    const animationOptions = {
      animationData: root.$themeConfig?.lottieLoader?.lottieJson,
    };
    const loading = ref(true);

    const orderList = computed(() => {
      return isReturns.value
        ? returnHistory.value.returns
        : orderHistory.value.orders;
    });

    const fetchHistory = async () => {
      // Populate orderHistory or returnHistory ref
      try {
        isReturns.value
          ? await getReturnHistory({ pageSize: 10, pageNumber: 1 })
          : await getOrderHistory(
              { pageSize: 10, pageNumber: 1 },
              { isBackgroundRequest: false }
            );
      } catch (error) {
        addNotification({
          errorMessageId: null,
          message: props.translations.serverErrorMessage,
          type: 'danger',
        });
      } finally {
        loading.value = false;
      }
    };

    onMounted(async () => {
      if (!consumerNo.value) {
        await getBasicInformation();
      }
      await fetchHistory();
    });

    return {
      loading,
      animationOptions,
      orderList,
      isReturns,
      showNoResultsAsNotification,
    };
  },
});
