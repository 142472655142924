





















import type { PropType } from 'vue';
import {
  computed,
  onMounted,
  defineComponent,
  ref,
  onUnmounted,
} from '@vue/composition-api';
import type {
  ArticleGridConfiguration,
  SearchResultsArticlesTranslations,
} from '@vf/api-contract';
import { useArticles, useSearch } from '@vf/composables';
import type { Taxonomy } from '@vf/composables/src/types';
import useRootInstance from '@/shared/useRootInstance';

const ArticleGrid = () =>
  import(
    /* webpackChunkName: "ArticleGrid" */
    /* webpackMode: "lazy" */
    /* webpackPrefetch: true */
    /* webpackPreload: true */
    '../shared/ArticleGrid.vue'
  );

export default defineComponent({
  name: 'SearchResultsArticles',
  components: {
    ArticleGrid,
  },
  props: {
    translations: {
      type: Object as PropType<SearchResultsArticlesTranslations>,
      default: () => ({}),
    },
    /** Name of the context where component should be displayed (one of 'regular-page' | 'search') */
    contextName: {
      type: String,
      default: 'search',
    },
    contextKey: {
      type: String,
      default: 'page-content',
    },
    configuration: {
      type: Object as PropType<ArticleGridConfiguration>,
      default: () => ({}),
    },
    filters: {
      type: Array as PropType<Taxonomy[]>,
      default: () => [],
    },
    /** If some articles have to be shown when no result is found */
    noResultShowArticles: {
      type: Boolean,
      default: true,
    },
    /** If no articles have been found what is the tag for the articles to show */
    noResultSearch: {
      type: String,
      default: 'no result search',
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const {
      pagination,
      setNoResultQueryString,
      getArticlesResults,
      resetFilters,
    } = useArticles(root, props.contextKey);
    const { setQueryString: setSearchQueryString } = useSearch(root);
    const noResultShow = ref(false);
    const isEmpty = computed(() => pagination.value.total === 0);
    const queryTerm = computed(() => root.$route.query.q);
    setSearchQueryString(queryTerm.value as string);
    onMounted(async () => {
      if (queryTerm.value && isEmpty.value && props.noResultShowArticles) {
        noResultShow.value = true;
        setNoResultQueryString(props.noResultSearch);
        await getArticlesResults();
      }
    });

    onUnmounted(() => {
      resetFilters();
      setNoResultQueryString('');
    });
    return { isEmpty, noResultShow };
  },
});
