












































import type { PropType } from 'vue';
import { defineComponent, computed } from '@vue/composition-api';
import type { OrderProductsSummaryTranslations } from '@vf/api-contract';
import { useI18n } from '@vf/composables';
import { useCheckoutStore } from '@vf/composables/src/store/checkoutStore';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';
import OrderProducts from '@/components/order/OrderProducts.vue';
import useRootInstance from '@/shared/useRootInstance';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

export default defineComponent({
  serverCacheKey: getCacheKeyFromProps,
  components: { OrderProducts },
  props: {
    translations: {
      type: Object as PropType<OrderProductsSummaryTranslations>,
      required: true,
    },
    /** Flag to determine if shipment section above products should be displayed */
    showShipmentLabel: Boolean,
  },
  setup(props) {
    const { root } = useRootInstance();
    const theme = root.$themeConfig.orderDetails;
    const { isCoreRedesignEnabled } = useFeatureFlagsStore();

    const { localeCode, localeMapping, getStaticTranslation } = useI18n(root);

    const { order, orderShipmentsSortedByDelivery } = useCheckoutStore();

    const localeData = {
      localeCode: localeCode(),
      localeMapping: localeMapping(),
    };

    const narvarTranslations = getStaticTranslation('narvar');

    const getShipmentTranslations = (shipment) => ({
      deliveryDateLabel:
        shipment.storeName ?? narvarTranslations.deliveryDateLabel,
    });

    const getShipmentMethod = (shipment) => ({
      ...shipment.shippingMethod,
      ...(shipment.items?.[0]?.deliveryTime && {
        deliveryTime: shipment.items[0].deliveryTime,
      }),
      trackingNumber: [],
    });

    const orderShipments = computed(() => {
      return orderShipmentsSortedByDelivery.map((shipment) => ({
        ...shipment,
        translations: getShipmentTranslations(shipment),
        shippingMethod: getShipmentMethod(shipment),
        // get shipment item sku and pair with order item
        items: shipment.items.map((shipmentItem) => {
          return order.items.find(
            (orderItem) =>
              orderItem.sku === shipmentItem.sku &&
              orderItem.shipmentId === shipment.shipmentId
          );
        }),
      }));
    });

    const getShippingStatusText = (shipment, number) => {
      if (!shipment || !theme.showDeliveryAsText) return '';

      // Check whether shipment is Pickup In Store
      if (shipment.storeName || shipment.fulfillmentType === 'PIS') {
        return `${props.translations.pickupFromLabel}: `;
      }
      const trackingNumbers = shipment.shippingMethod.trackingNumber;

      if (trackingNumbers.length) {
        const message = props.translations.trackPackageLabel;
        return `${message} ${trackingNumbers.join(', ')}`;
      }

      return props.translations.shipmentSummaryArrival.replace(
        '{{number}}',
        number
      );
    };

    return {
      theme,
      localeData,
      orderShipments,
      getShippingStatusText,
      isCoreRedesignEnabled,
    };
  },
});
