











































































import { computed, defineComponent } from '@vue/composition-api';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';
import { useI18n } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'VfSimplifiedFooter',
  serverCacheKey: getCacheKeyFromProps,
  props: {
    logo: {
      type: [String, Object],
      default: '',
    },
    logoHeight: {
      type: Number,
      default: null,
    },
    logoWidth: {
      type: Number,
      default: null,
    },
    cta: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
    phoneNumber: {
      type: String,
      default: '',
    },
    befItem: {
      type: Object,
      default: () => ({}),
    },
    mcAfeeItem: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const theme = root.$themeConfig.staticCheckout;
    const { localePath } = useI18n(root);
    const isMobile = computed(() => {
      return root.$viewport?.size === 'small';
    });
    // TODO: GLOBAL15-61059 remove after redesign core
    const { isCoreRedesignEnabled } = useFeatureFlagsStore();
    return {
      getLinkType: () => {
        return props.link ? 'ThemeLink' : 'VfText';
      },
      isMobile,
      mcAfeeLogoWidth: root.$themeConfig?.SimplifiedFooter?.logo?.mcAfee.width,
      mcAfeeLogoHeight:
        root.$themeConfig?.SimplifiedFooter?.logo?.mcAfee.height,
      befLogoWidth: root.$themeConfig?.SimplifiedFooter?.logo?.bef.width,
      befLogoHeight: root.$themeConfig?.SimplifiedFooter?.logo?.bef.height,
      isCoreRedesignEnabled,
      footerConfig: {
        ...theme.footer,
        contactLink: localePath(theme.footer.contactLink),
      },
    };
  },
});
