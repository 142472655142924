import { Ref } from '@nuxtjs/composition-api';
import { CategoryProduct } from '@vf/api-client';
import { getDefaultVariant } from '@/components/smart/shared/ProductsGrid/utils';

type Badge = {
  src?: {
    small: string;
    medium: string;
    large: string;
  };
  alt: string;
};

type UseProductBadges = {
  getProductBadge(labels: string[]): Badge;
  getProductBadges(
    product: CategoryProduct,
    showBadgesByColorSwatch: boolean
  ): Badge[];
  getColorSwatchBadge(product: CategoryProduct, label: string): Badge[];
};

export const useProductBadges = (
  badges: Ref<{ [key: string]: Badge }>
): UseProductBadges => {
  const getProductBadge = (labels: string[]) => {
    if (labels && labels[0]) {
      return badges.value[labels[0]] || null;
    }

    return null;
  };
  const getProductBadges = (
    product: CategoryProduct,
    showBadgesByColorSwatch: boolean
  ) => {
    if (showBadgesByColorSwatch) {
      const defaultVariant = getDefaultVariant(product, true);

      return getColorSwatchBadge(product, defaultVariant?.labels);
    }

    if (!product.labels) {
      return [];
    }

    return product.labels.map((label: string) => {
      return badges.value[label] || { alt: label };
    });
  };

  /** Decorate Badge with src Image retrieved from CMS */
  const getColorSwatchBadge = (_product: CategoryProduct, label: string) => {
    return label ? [{ src: badges.value[label]?.src, alt: label }] : [];
  };

  return {
    getProductBadge,
    getProductBadges,
    getColorSwatchBadge,
  };
};

export default {
  useProductBadges,
};
