
































































































































































































































































































import type { PropType } from 'vue';
import {
  computed,
  ref,
  onMounted,
  onBeforeUnmount,
  watch,
  defineComponent,
} from '@vue/composition-api';
import {
  useProduct,
  useProductInventory,
  useValidation,
  useNotification,
  useCms,
  useCart,
  useGtm,
  useAccount,
  useMonetate,
  useSectionNumeration,
  useNotifyMe,
} from '@vf/composables';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import {
  ProductSizesTranslations,
  SelectorVariant,
  SelectorVariants,
  ShoesSizeGender,
  Context,
} from '@vf/api-contract';
import {
  scrollTo,
  scrollIntoView,
  isClient,
} from '@vf/shared/src/utils/helpers';
import Notifications from '../layout/Notifications.vue';
import CustomContent from '../customContent/CustomContent.vue';
import ProductNotificationsForm from './ProductNotificationsForm.vue';
import VfProductMeasurements from '@/components/smart/shared/ProductMeasurements.vue';
import useRootInstance from '@/shared/useRootInstance';
import { addVariantOptionToUrlParams } from '@/helpers';
import { useUserData } from '../../cms/cmsUtils';
import useModal from '@/shared/useModal';
import { PageTypeName } from '@vf/composables/src/useCms/types';
import { useCmsRefStore } from '@vf/composables/src/store/cmsRef';
import { storeToRefs } from 'pinia';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import { useUserStore } from '@vf/composables/src/store/user';
import { Size } from '@vf/api-client';

export default defineComponent({
  name: 'ProductSizes',
  components: {
    Notifications,
    CustomContent,
    ProductNotificationsForm,
    VfProductMeasurements,
  },
  mixins: [validationMixin],
  props: {
    translations: {
      type: Object as PropType<ProductSizesTranslations>,
      default: () => ({}),
    },
    /** Show numeration flag  */
    showNumeration: {
      type: Boolean,
    },
    /** Flag to determine if whole component should be hidden or not */
    hideComponent: {
      type: Boolean,
      default: false,
    },
    /** How this component should be displayed (dropdown|tiles/chips)*/
    displayAs: {
      type: String as PropType<SelectorVariant>,
      default: SelectorVariant.Dropdown,
      validator: (value: SelectorVariant) => {
        return SelectorVariants.includes(value);
      },
    },
    /** Flag to determine if default size from SFCC should be pre-selected */
    preselectDefaultSize: {
      type: Boolean,
      default: true,
    },
    /** Flag to determine if product is displayed in swap cart item modal */
    isSwapCartItemModal: Boolean,
    /** Flag to determine if selected option displyed in option title */
    showSelectedVariationTitle: Boolean,
    /** Flag to determine if model measurements plaque is visible */
    showModelMeasurements: Boolean,
    /** Size chart image width */
    sizeChartWidth: {
      type: [String, Number],
      default: null,
    },
    /** Size chart image height */
    sizeChartHeight: {
      type: [String, Number],
      default: null,
    },
    /** Select with page type */
    contextKey: {
      type: String,
      default: 'product',
    },
    /** Show divider on quick shop page or not */
    showDivider: {
      type: Boolean,
      default: false,
    },
    showSizesExpanded: {
      type: Boolean,
      default: false,
    },
    /** enable feature sizeChart */
    showSizeChart: {
      type: Boolean,
      default: true,
    },
    /** Will show a skeleton loading animation while it's true */
    isLoading: {
      type: Boolean,
      default: false,
    },
    recommendationLink: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit, attrs }) {
    const cmsRefStore = useCmsRefStore();
    const { root } = useRootInstance();
    const {
      isNotifyMeEnabled: isNotifyMeEnabledFeatureFlag,
      isVansPdpRedesignEnabled,
      pdpShowScarcityMessage,
    } = useFeatureFlagsStore();
    const isSizeChartModalVisible = ref(false);
    const size = ref('');
    const thisSize = ref(null);
    const {
      product,
      configure,
      loading,
      isQuickShopContext,
      sizeChartId,
      availableAttributes,
      checkAttributes,
      configureSize,
      isSelectedProductOutOfStock,
      isSelectedVariationLowOnStock,
      attributesData,
      isAttributePriceVariation,
      getGroupsByPriceVariation,
      attributePriceVariationClickAction,
      setInitialColor,
      getSortedVariantGrouping,
      priceData,
      variantsData,
    } = useProduct(root, props.contextKey);
    const { inventoryLoading } = useProductInventory(root, props.contextKey);
    const {
      isNotifyMeFormVisible,
      reset: resetNotifyMe,
      subscriptionFeedback: notifyMeSubscriptionFeedback,
      isAlreadySubscribed,
    } = useNotifyMe(root, props.contextKey);
    const { dispatchEvent } = useGtm(root);
    const userStore = useUserStore(root);
    const { loggedIn } = storeToRefs(userStore);
    const { getBasicInformation, basicInformation } = useAccount(root);
    const { sizeCharts } = useCms(root, props.contextKey);
    const { setQuickshopSizeChart } = useCart(root);
    const { $v, setValidation } = useValidation(root, 'SIZE_SELECT');
    const { clearNotifications } = useNotification(root);
    const { isModalOpen } = useModal();
    const { getExperienceDecision, addCustomVars } = useMonetate(
      root,
      props.contextKey
    );
    const { getSectionNumber } = useSectionNumeration(root);

    const { pageTypeName } = storeToRefs(cmsRefStore);
    const EVENT_SHOW_SIZE_CHART_MODAL = `show-size-chart-modal__${props.contextKey}`;

    const theme = root.$themeConfig?.productSizeSelect || {};
    const sizeLabel = computed(() => product.value?.size?.label || '');
    const headingModifier = computed(
      () => theme.headingModifier || 'subtitle-6'
    );
    const dividerModifier = computed(
      () => theme.dividerModifier || 'divider-default'
    );
    const showProductSizeGuideNearLabel = theme.showProductSizeGuideNearLabel;
    const isTilesWithChart = computed(
      () =>
        props.showSizeChart &&
        sizeChartId.value &&
        props.displayAs === SelectorVariant.Chips &&
        !attributesData.value.isSoldOut
    );

    const notifyMissingSize = computed(() => {
      return (
        !!product.value?.validation && product.value.validation.missingSize
      );
    });

    const onSelectSize = async (selectedSize: Size) => {
      if (selectedSize.value === size.value) return;

      attributePriceVariationClickAction('size');
      const sizeValue = selectedSize.value;
      configureSize(sizeValue);
      size.value = sizeValue;

      if (!isSelectedProductOutOfStock.value) resetNotifyMe();
      if (
        theme.addVariantOptionToUrlQueryParams &&
        props.contextKey !== Context.QuickShop
      ) {
        await root.$router.replace({
          path: addVariantOptionToUrlParams(root, 'size', size.value),
        });
      }

      const userData = useUserData(root);
      addCustomVars(userData);
      getExperienceDecision(true);
    };

    const getSizeChartType = (id) => {
      return id ? sizeCharts?.value?.[id]?.type : null;
    };

    const getSizeChartContent = (id) => {
      return id ? sizeCharts?.value?.[id]?.src : null;
    };

    const availableSizes = computed(() => product.value?.sizes || []);

    const isSingleSizeProduct = computed(() => {
      return availableSizes.value.length === 1;
    });

    const singleSizeValue = computed(() => {
      return isSingleSizeProduct.value ? availableSizes.value[0].value : null;
    });

    const openSizeChart = () => {
      if (props.contextKey === 'quickshop') {
        setQuickshopSizeChart(getSizeChartContent(sizeChartId.value));
        setTimeout(() => {
          scrollIntoView('.vf-modal__content .quickshop__size-chart');
        }, 500);
      } else {
        isSizeChartModalVisible.value = true;
        emit('show-size-chart');
      }
      dispatchEvent({
        eventName: 'loadEventData',
        overrideAttributes: {
          eventCategory: 'PDP',
          eventAction: 'Click on Size Guide',
          eventLabel: undefined,
        },
      });
    };

    if (isClient) {
      watch(
        () => loggedIn,
        (isLoggedIn) =>
          isLoggedIn.value &&
          !basicInformation.value.email &&
          getBasicInformation(),
        { immediate: true }
      );
    }

    watch(
      product,
      (newValue) => {
        if (!newValue) {
          return;
        }

        if (newValue.dummyCustoms && !newValue.color) {
          setInitialColor();
        }

        if (!isClient) return;
        //Code below will work on client side only
        if (!newValue.size || root.$router.history.current?.query?.size) {
          size.value =
            singleSizeValue.value ||
            (root.$router.history.current?.query?.size as string);
          if ($v.value) $v.value.size?.$reset();
          configureSize(size.value);
        } else {
          configureSize(size.value);
        }
      },
      {
        immediate: true,
      }
    );

    const availableVariants = computed(() => availableAttributes.value.size);

    watch(
      [product, basicInformation],
      () => {
        if (
          product.value?.size ||
          !basicInformation.value.preferences.preferredShoeSize
        ) {
          return;
        }

        const { preferredShoeSize } = basicInformation.value.preferences;
        const matchedSize = availableVariants.value.find((v) =>
          v.id.includes(`${preferredShoeSize}:${ShoesSizeGender.Male}`)
        );

        if (!matchedSize) return;

        configureSize(matchedSize.attributes.size);
      },
      { immediate: true }
    );

    const variantsGroupedBySizePrice = computed(() => {
      return getSortedVariantGrouping('size');
    });

    const availableSizesRange = (obj) => {
      return availableSizes.value.filter((el) => {
        return obj.options
          .map((prop) => {
            return prop.attributes.size === el.value;
          })
          .some(Boolean);
      });
    };

    const getFormattedPrice = (price) => {
      return root.$formatPrice(price, priceData.value.currency);
    };

    let initialSizeWasSet = false;
    watch(
      () => product.value?.size?.value || singleSizeValue.value || '',
      (newSize) => {
        if (initialSizeWasSet) return;
        size.value = newSize;
        if (newSize) initialSizeWasSet = true;
      },
      { immediate: true }
    );

    onMounted(() => {
      root.$eventBus.$on('moveToSize', moveToSize);
      root.$eventBus.$on(EVENT_SHOW_SIZE_CHART_MODAL, openSizeChart);
    });

    onBeforeUnmount(() => {
      root.$eventBus.$off('moveToSize', moveToSize);
      root.$eventBus.$off(EVENT_SHOW_SIZE_CHART_MODAL, openSizeChart);
    });

    const onCloseModal = () => {
      clearNotifications();
      isSizeChartModalVisible.value = false;
    };

    const widthObject = computed(() => {
      return {
        large: `${root.$viewport?.breakpoints?.large || 1440}px`,
        medium: `${root.$viewport?.breakpoints?.medium || 1024}px`,
      };
    });

    const mainTitle = computed(() => {
      const titleToUse = props.isSwapCartItemModal
        ? props.translations.sizeLabel
        : props.translations.chooseSize;

      const prefixLabel = getSectionNumber(attrs['data-id'] as string);

      // Clean up with: GLOBAL15-63801
      let displayTitle =
        props.showNumeration && prefixLabel && !isVansPdpRedesignEnabled
          ? `${prefixLabel}. ${titleToUse}`
          : titleToUse;

      if (props.showSelectedVariationTitle && sizeLabel.value) {
        displayTitle += ' - ' + sizeLabel.value;
      }

      // Clean up with: GLOBAL15-63801
      if (
        showProductSizeGuideNearLabel &&
        isTilesWithChart.value &&
        !isVansPdpRedesignEnabled
      ) {
        displayTitle += ' -';
      }
      if (
        attributesData.value.isSoldOut &&
        props.displayAs === SelectorVariant.Chips
      ) {
        displayTitle = props.translations.soldOutTitle;
      }
      return displayTitle;
    });

    const moveToSize = () => {
      // TODO: GLOBAL15-63801 clean up
      const scrollToErrorOffset = !root.$viewport.isSmall
        ? theme.scrollToErrorOffsetWithTopStickyHeader
        : theme.scrollToErrorOffset;

      scrollTo({
        top:
          thisSize.value.getBoundingClientRect().top +
          document.documentElement.scrollTop -
          scrollToErrorOffset,
      });
      checkAttributes();
    };

    const errorMessage = computed(
      () => props.translations?.validationMessages?.unselectedMessage
    );

    const showAltLabels = computed(
      () => pageTypeName.value === PageTypeName.PDP
    );

    const isNotifyMeEnabled = computed(
      () => isNotifyMeEnabledFeatureFlag && product.value?.notifyMe
    );

    const isNotifyMeAvailable = computed(
      () => isNotifyMeEnabled.value && isSelectedProductOutOfStock.value
    );

    const isNotificationFormVisible = computed(
      () =>
        isNotifyMeEnabledFeatureFlag &&
        (attributesData.value.isFutureProduct ||
          (isNotifyMeAvailable.value && props.contextKey === Context.QuickShop))
    );

    const soldOutCopy = computed(() => {
      const regex = /{{(.*?)}}/g;
      const linkText = props.translations.soldOutCopy.match(regex)?.[0];

      return props.translations.soldOutCopy.replace(
        linkText,
        `<a href="${props.recommendationLink}">${linkText.replace(
          /{{|}}/g,
          ''
        )}</a>`
      );
    });

    const showYourSizeUnavailableLink = computed(
      () =>
        theme.showYourSizeUnavailableLink &&
        isNotifyMeEnabled.value &&
        !variantsData.value.areAllVariantsInStock &&
        props.contextKey !== Context.QuickShop
    );

    const chipsNotSoldOut = computed(
      () =>
        product.value &&
        props.displayAs === SelectorVariant.Chips &&
        !attributesData.value.isSoldOut
    );

    return {
      errorMessage,
      loading,
      mainTitle,
      product,
      configure,
      isSizeChartModalVisible,
      notifyMissingSize,
      availableSizes,
      configureSize,
      openSizeChart,
      sizeChartId,
      headingModifier,
      dividerModifier,
      isQuickShopContext,
      size,
      widthObject,
      setValidation,
      onCloseModal,
      getSizeChartContent,
      getSizeChartType,
      availableVariants,
      thisSize,
      onSelectSize,
      isModalOpen,
      showAltLabels,
      sizeLabel,
      isNotifyMeFormVisible,
      showYourSizeUnavailableLink,
      showProductSizeGuideNearLabel,
      isTilesWithChart,
      theme,
      attributesData,
      isAttributePriceVariation,
      getGroupsByPriceVariation,
      availableAttributes,
      variantsGroupedBySizePrice,
      availableSizesRange,
      getFormattedPrice,
      SelectorVariant,
      isAlreadySubscribed,
      notifyMeSubscriptionFeedback,
      isNotificationFormVisible,
      soldOutCopy,
      chipsNotSoldOut,
      inventoryLoading,
      isVansPdpRedesignEnabled,
      moveToSize,
      isSelectedVariationLowOnStock,
      pdpShowScarcityMessage,
    };
  },
  watch: {
    /*
      When we open quickshop of suggested product,
      we mount one more instance of ProductSizes component,
      then we call setValidation for the second size field.
      If left this field as empty, close quickshop,
      then validation of pdp's size field will think that the size field is empty.
      As a fix we reset validation after close the modal.
    */
    isModalOpen(newValue) {
      if (!newValue) {
        this.setValidation(this.$v);
      }
    },
  },
  mounted() {
    this.setValidation(this.$v);
  },
  validations: {
    size: {
      required,
    },
  },
});
