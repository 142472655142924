


































































































































import type { PropType } from 'vue';
import {
  computed,
  defineComponent,
  ref,
  Ref,
  watch,
} from '@vue/composition-api';
import {
  ApplePayTranslations,
  PromotionMessageContext,
  MinicartTranslations,
} from '@vf/api-contract';
import {
  ROUTES,
  useCart,
  useI18n,
  useRouting,
  useRequestTracker,
  useGtm,
} from '@vf/composables';
import { getEventFromTemplate } from '@vf/composables/src/useGtm/helpers';
import useRootInstance from '@/shared/useRootInstance';
import useModal from '@/shared/useModal';

import AdditionalPaymentButtons from './AdditionalPaymentButtons.vue';

export default defineComponent({
  name: 'MinicartSidebar',
  components: {
    AdditionalPaymentButtons,
    PromotionMessage: () => import('../promotion/PromotionMessage.vue'),
  },
  props: {
    translations: {
      type: Object as PropType<MinicartTranslations>,
      required: true,
    },
    /** Flag to determine if share my cart functionality is enabled */
    isShareMyCartEnabled: { type: Boolean, default: true },
    bottomImage: { type: Object, default: null },
    showExpressCheckoutButtons: { type: Boolean, default: true },
    applePayTranslations: {
      type: Object as PropType<ApplePayTranslations>,
      default: () => ({}),
    },
    showApplePayComponent: Boolean,
  },
  setup(props, { refs }) {
    const { root } = useRootInstance();
    const theme = root.$themeConfig.minicartSidebar;

    const {
      totalItems,
      cart,
      cartItems: products,
      deleteItem,
      isMinicartOpen,
      setMiniCart,
    } = useCart(root);
    const { openModal } = useModal();
    const { localePath } = useI18n(root);
    const { trackRequest, clearRequest } = useRequestTracker(root);
    const { dispatchEvent } = useGtm(root);

    const minicartContent = ref();

    const isEmpty = computed(() => totalItems.value === 0);

    const totalProductsLabel = computed(() =>
      totalItems.value === 1
        ? `${totalItems.value} ${props.translations.singleItem}`
        : `${totalItems.value} ${props.translations.multipleItems}`
    );

    const isItemRemoving = ref(false);

    const removeItemFromCart = async (product) => {
      const { tag, isAlreadyTracked } = trackRequest(
        `minicart-product-remove-${product.id}`
      );
      if (isAlreadyTracked) return;

      isItemRemoving.value = true;
      await deleteItem(product.id);
      isItemRemoving.value = false;
      clearRequest(tag);

      dispatchEvent({
        ...getEventFromTemplate('cart:remove', {}),
        overrideAttributes: {
          product: product,
          quantity: product.qty,
        },
      });
      dispatchEvent(getEventFromTemplate('cart:update', {}));
      root.$emit('onDelete', 'Remove');
    };

    const navigateToCheckout = () => {
      root.$emit('navigateToCheckout', 'Checkout');
      root.$router.push(localePath(ROUTES.CART()));
      setMiniCart(false);
    };

    watch(isMinicartOpen, () => {
      const minicartContent = (refs[
        'minicart-content'
      ] as unknown) as Ref<HTMLElement>;
      if (isMinicartOpen.value && minicartContent) {
        minicartContent.value.focus();
      }
    });

    // TODO: Probably can be removed after this will be implemented correctly: GLOBAL15-31976
    const { previousRoute } = useRouting(root);
    watch(previousRoute, () => {
      closeSidebar();
    });

    const openShareMyCartModal = () => {
      setMiniCart(false);
      root.$emit('openShareMyCartModal', 'Share my Cart');
      openModal({ type: 'fragment', resourceId: 'share-my-cart-modal' });
    };

    const closeSidebar = () => {
      setMiniCart(false);
      root.$emit('closeSidebar');
    };

    if (
      root.$route.path.includes(ROUTES.CHECKOUT_ORDER_STATUS()) &&
      isMinicartOpen.value
    ) {
      closeSidebar();
    }

    const promotionTranslations = [];
    promotionTranslations.push({
      name: 'bounceBackVouchers',
      translations: props.translations.promotions,
    });

    return {
      theme,
      minicartContent,
      totalProductsLabel,
      removeItemFromCart,
      isItemRemoving,
      isEmpty,
      openShareMyCartModal,
      products,
      isMinicartOpen,
      setMiniCart,
      navigateToCheckout,
      closeSidebar,
      totals: computed(() => cart.value.totals),
      PromotionMessageContext,
      promotionTranslations,
    };
  },
});
