import { computed, ref, watch } from '@vue/composition-api';
import { PaymentMethodCode } from '@vf/api-client';
import { Ref } from '@nuxtjs/composition-api';
import { PaymentInstrument } from '@vf/api-contract';

const showAddNewCreditCardForm = ref<boolean>(false);

export function useCard({
  savedCreditCard,
  paymentInstruments,
  paymentMethod,
}: {
  savedCreditCard: Ref<string>;
  paymentInstruments: Ref<{ cards: PaymentInstrument[] }>;
  paymentMethod: Ref<string>;
}): {
  filterCreditCardMethod;
  showAddNewCreditCardForm;
  setCreditCardPaymentMethod;
  handleNewCreditCard;
  handleBackToSavedCards;
  showAddNewCreditCardButton;
} {
  let lastSavedCard = '';

  watch(
    savedCreditCard,
    (card: string) => {
      if (card && card !== lastSavedCard) {
        lastSavedCard = card;
      }
    },
    { immediate: true }
  );

  watch(paymentMethod, (value: PaymentMethodCode) => {
    if (value === PaymentMethodCode.CREDIT_CARD) {
      savedCreditCard.value = lastSavedCard;
    }
  });

  const showAddNewCreditCardButton = computed<boolean>(
    () =>
      paymentInstruments.value.cards.length > 0 &&
      !showAddNewCreditCardForm.value
  );

  const filterCreditCardMethod = (paymentMethods) => {
    if (
      !showAddNewCreditCardForm.value &&
      paymentInstruments.value.cards.length
    ) {
      return paymentMethods.filter(
        (method) => method.code !== PaymentMethodCode.CREDIT_CARD
      );
    }
    return paymentMethods;
  };

  const setCreditCardPaymentMethod = () => {
    paymentMethod.value = PaymentMethodCode.CREDIT_CARD;
    showAddNewCreditCardForm.value = false;
  };

  const handleNewCreditCard = () => {
    savedCreditCard.value = '';
    paymentMethod.value = PaymentMethodCode.CREDIT_CARD;
    showAddNewCreditCardForm.value = true;
  };

  const handleBackToSavedCards = () => {
    savedCreditCard.value = lastSavedCard;
    showAddNewCreditCardForm.value = false;
  };

  return {
    filterCreditCardMethod,
    showAddNewCreditCardForm,
    setCreditCardPaymentMethod,
    handleNewCreditCard,
    handleBackToSavedCards,
    showAddNewCreditCardButton,
  };
}

export default useCard;
