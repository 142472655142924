var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vf-select",class:{
    'vf-select--is-selected': _vm.value,
    'vf-select--is-required': _vm.required,
    'vf-select--is-disabled': _vm.disabled,
    'vf-select--is-invalid': !_vm.valid,
    'vf-select--redesign-core': _vm.isCoreRedesignEnabled && !_vm.excludeRedesign,
    'vf-select--has-label': _vm.label,
  }},[(_vm.label)?_c('label',{class:_vm.labelClasses,attrs:{"for":_vm.id || _vm.label}},[_vm._t("label",function(){return [_vm._v(_vm._s(_vm.label))]},{"label":_vm.label})],2):_vm._e(),_vm._v(" "),(!_vm.isCoreRedesignEnabled && !_vm.value && _vm.placeholder)?_c('div',{staticClass:"vf-select__placeholder"},[_vm._v("\n    "+_vm._s(_vm.placeholder)+"\n  ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"vf-select__dropdown"},[_c('fieldset',[_c('legend'),_vm._v(" "),_c('select',_vm._g(_vm._b({directives:[{name:"focus",rawName:"v-focus"}],staticClass:"vf-select__select",attrs:{"id":_vm.id || _vm.label,"disabled":_vm.disabled,"required":_vm.required,"aria-label":_vm.ariaLabel ? _vm.ariaLabel : undefined},domProps:{"value":_vm.value},on:{"focus":function($event){_vm.isFocused = true},"blur":_vm.onBlur,"change":function($event){return _vm.$emit('selected', $event.target.value)}}},'select',_vm.$attrs,false),_vm.$listeners),[_vm._t("default")],2)]),_vm._v(" "),_c('VfIcon',{class:_vm.iconClasses,attrs:{"icon":_vm.iconName,"size":_vm.iconSize}})],1),_vm._v(" "),_c('transition',{attrs:{"name":"vf-fade"}},[(_vm.errorMessage && !_vm.valid)?_c('div',{staticClass:"vf-select__error-message"},[_vm._t("errorMessage",function(){return [_c('span',[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])]},null,{ errorMessage: _vm.errorMessage })],2):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }