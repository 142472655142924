


















































































































import {
  computed,
  onMounted,
  PropType,
  reactive,
  toRefs,
  defineComponent,
  onUnmounted,
} from '@vue/composition-api';
import { validationMixin } from 'vuelidate';
import {
  useAuthentication,
  useCart,
  useFavorites,
  useAccount,
  useUtilities,
  useReCaptcha,
  useGtm,
} from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import { required, requiredIf } from 'vuelidate/lib/validators';
import { checkPassword } from '@vf/shared/src/utils/helpers';
import { OrderRegisterXPLRTranslations } from '@vf/api-contract';
import useAuthenticationEvents from '@/shared/useAuthenticationEvents';
import { storeToRefs } from 'pinia';
import { useCheckoutStore } from '@vf/composables/src/store/checkoutStore';
import { useUserStore } from '@vf/composables/src/store/user';

type UserType = 'guest' | 'member' | 'legacy';

const POINTS_PER_DOLLAR = 1;

/**
 * TNF Specific XPLR pass order confirmation component
 */
export default defineComponent({
  name: 'OrderRegisterXPLR',
  mixins: [validationMixin],
  props: {
    translations: {
      type: Object as PropType<OrderRegisterXPLRTranslations>,
      required: true,
    },
    xplrPassDashboardPage: {
      type: String as PropType<string>,
      default: null,
    },
    xplrPassTermsPage: {
      type: String as PropType<string>,
      default: null,
    },
    xplrPassPage: {
      type: String as PropType<string>,
      default: null,
    },
    shippingTermsPage: {
      type: String as PropType<string>,
      default: null,
    },
    contextKey: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { getFormLocation } = useGtm(root);
    const formLocation = getFormLocation(props.contextKey);

    const userStore = useUserStore(root);
    const { loggedIn, loyaltyEnrolled } = storeToRefs(userStore);

    const { signUp, signIn, saveForLaterId } = useAuthentication(root);

    const { createSignUp, createSignIn } = useAuthenticationEvents(
      formLocation
    );

    const { setBasicInformation } = useAccount(root);
    const { getCountry } = useUtilities(root);
    const { cartId } = useCart(root);
    const { favoriteId } = useFavorites(root);
    const reCaptcha = useReCaptcha(root);

    const state = reactive({
      password: null,
      isTermsConfirmed: false,
      isButtonDisabled: false,
      showNotification: false,
    });

    const { order, rewards } = storeToRefs(useCheckoutStore());
    const customerFirstName = computed(
      () => order.value?.billingAddress?.firstName
    );

    const userType = computed<UserType>(() => {
      // Existing customer (valid only if user is not logged in)
      if (!loggedIn.value && order.value?.existingCustomer) {
        return 'member';
      }

      if (!loggedIn.value) {
        return 'guest';
      }

      if (loyaltyEnrolled.value) {
        return 'member';
      }

      return 'legacy';
    });

    const showUserInformation = computed(
      () => userType.value === 'guest' || userType.value === 'legacy'
    );

    const points = computed(() => {
      if (!order.value) {
        return 0;
      }

      const rewardsAmount =
        rewards.value.reduce((sum, reward) => sum + reward.amount, 0) || 0;
      // points are not given for the amount covered with rewards
      const pointsBase = order.value.totals.totalWithoutTax - rewardsAmount;
      return Math.max(Math.round(pointsBase * POINTS_PER_DOLLAR), 0);
    });

    const heading = computed(() => {
      if (userType.value === 'member') {
        return props.translations.enrolledHeading
          .replace('{{firstName}}', customerFirstName.value || '')
          .replace('{{points}}', points.value.toString());
      }

      return props.translations.heading.replace(
        '{{points}}',
        points.value.toString()
      );
    });

    const termsAndConditionsLink = computed(() =>
      props.translations.agree.replace(
        '{{xplrPassTermsPage}}',
        props.xplrPassTermsPage
      )
    );

    const learnMoreLink = computed(() =>
      props.translations.learnMore.replace(
        '{{xplrPassPage}}',
        props.xplrPassPage
      )
    );

    const shippingTermsLink = computed(() =>
      props.translations.shippingTerms.replace(
        '{{shippingTerms}}',
        props.shippingTermsPage
      )
    );

    const boundWithGtmEventsSignIn = createSignIn(signIn, {
      isAutomaticLogin: true,
    });

    const boundWithGtmEventsSignUp = createSignUp(async () => {
      const {
        email,
        firstName,
        lastName,
        phone,
        country,
        postalCode,
      } = order.value.billingAddress;

      const signUpPayload = {
        country,
        phone,
        email,
        firstName,
        lastName,
        postalCode,
        password: state.password,
        enrollNewUsersToLoyalty: true,
        isSubscribed: false,
        isTermsConfirmed: state.isTermsConfirmed,
      };

      await signUp(signUpPayload);
      state.showNotification = true;

      const signInPayload = {
        username: signUpPayload.email,
        password: signUpPayload.password,
        action: 'postLogin',
        st: order.value.st,
        orderNo: order.value.orderNumber,
        guestObjects: {
          basketId: cartId.value,
          saveForLaterId: saveForLaterId.value,
          favoriteId: favoriteId.value,
        },
      };

      const signInResult = await boundWithGtmEventsSignIn(signInPayload);

      if (signInResult) {
        root.$router.push(props.xplrPassDashboardPage);
      }
    });

    const handleRegister = async () => {
      state.isButtonDisabled = true;
      state.showNotification = false;

      try {
        await boundWithGtmEventsSignUp();
      } catch (e) {
        console.error(e);
      } finally {
        state.isButtonDisabled = false;
      }
    };

    const handleLoyaltyEnrollment = async () => {
      state.isButtonDisabled = true;
      state.showNotification = false;

      try {
        await setBasicInformation({
          enrollments: [{ type: 'Loyalty', country: getCountry() }],
        });
        state.showNotification = true;
        root.$router.push(props.xplrPassDashboardPage);
      } catch (e) {
        console.error(e);
      } finally {
        state.isButtonDisabled = false;
      }
    };

    onMounted(() => {
      reCaptcha.showBadge();
    });

    onUnmounted(() => reCaptcha.hideBadge());

    return {
      ...toRefs(state),
      order,
      customerEmailAddress: computed(() => order.value?.billingAddress?.email),
      customerFirstName,
      userType,
      termsAndConditionsLink,
      learnMoreLink,
      shippingTermsLink,
      showUserInformation,
      heading,
      points,
      handleRegister,
      handleLoyaltyEnrollment,
    };
  },
  validations() {
    return {
      password: {
        required: requiredIf(function () {
          return this.userType === 'guest';
        }),
        checkPassword: (v) =>
          this.userType === 'guest' ? checkPassword(v) : true,
      },
      isTermsConfirmed: {
        required,
        sameAs: (val) => val === true,
      },
    };
  },
});
