

























































































































































































































































































































































































































































































































































































// TODO: GLOBAL15-56318 remove deprecated component
import type { PropType } from 'vue';
import { computed, defineComponent, onMounted } from '@vue/composition-api';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import {
  getCacheKeyFromProps,
  getProductImage,
} from '@vf/shared/src/utils/helpers';
import {
  ProductShippingOption,
  CartProductsTranslations,
  PriceOverrideTranslations,
  AthletePromoType,
  FlashErrorType,
} from '@vf/api-contract';
import useRootInstance from '../../shared/useRootInstance';
import CartItem from './CartItem.vue';
import PriceOverride from './PriceOverride.vue';
import { useAccount, useFindInStore, useSignInToStore } from '@vf/composables';
import { scrollTo as scrollToTop } from '@vf/shared/src/utils/helpers';

export default defineComponent({
  name: 'CartProduct',
  serverCacheKey: getCacheKeyFromProps,
  components: { CartItem, PriceOverride },
  props: {
    translations: {
      type: Object as PropType<CartProductsTranslations>,
      default: () => ({
        color: 'color',
        size: 'size',
        edit: 'edit',
        availability: 'availability',
        outOfStock: 'outOfStock',
        flashMessages: {
          DefaultFlashMessage: 'DefaultFlashMessage',
          InvalidProductItem: 'InvalidProductItem',
          ProductItemNotAvailable: 'ProductItemNotAvailable',
          ProductLineItemInventoryMissing: 'ProductLineItemInventoryMissing',
          ProductLineItemFullInventoryMissing:
            'ProductLineItemFullInventoryMissing',
          ProductItemMaxQuantity: 'ProductItemMaxQuantity',
          PickupToStsTransition: 'PickupToStsTransition',
          PickupToSthTransition: 'PickupToSthTransition.',
          StsToSthTransition: 'StsToSthTransition.',
          StsToPickupTransition: 'StsToPickupTransition.',
          ProductStyleMaxQuantity: 'ProductStyleMaxQuantity',
        },
        addGiftOption: {},
      }),
    },
    priceOverrideTranslations: {
      type: Object as PropType<PriceOverrideTranslations>,
      default: () => ({}),
    },
    product: {
      type: Object,
      default: () => ({}),
    },
    showOnlyErrorInfo: Boolean,
    /** Displays notification on top of cart product row */
    rowFlashMessage: {
      type: [Boolean, String],
      default: '',
    },
    isHidden: Boolean,
    overrideImage: { type: String, default: null },
    imageHeight: { type: Number, default: 180 },
    imageWidth: { type: Number, default: 145 },
    maxQuantity: { type: Number, default: 10 },
    allowRedirectToPdp: { type: Boolean, default: true },
    showEditButton: { type: Boolean, default: true },
    showRemoveButton: { type: Boolean, default: true },
    showSaveForLaterButton: { type: Boolean, default: true },
    showSaveToFavoritesButton: { type: Boolean, default: true },
    showPrice: { type: Boolean, default: true },
    isPricingStrikethroughEnabled: Boolean,
    showTotal: { type: Boolean, default: true },
    showQty: { type: Boolean, default: true },
    reasonForRemovingProduct: { type: String, default: '' },
    showMoveToCartButton: { type: Boolean, default: false },
    showAddGiftOptionCta: { type: Boolean, default: false },
    giftBoxPrice: { type: Number, default: 0 },
    openEditProductModal: { type: Boolean, default: false },
    /** Inline flash error messages list */
    flashErrors: {
      type: Array as PropType<{ code: string; message: string }[]>,
      default: () => [],
    },
    /** Inline customer notifications list */
    customerNotifications: {
      type: Array as PropType<{ code: string; message: string }[]>,
      default: () => [],
    },
    cartError: {
      type: Object as PropType<{ productId?: string; message?: string }>,
      default: () => ({}),
    },
    quantityPicker: {
      type: String as PropType<'dropdown' | 'controller'>,
      default: 'dropdown',
    },
    showAvailability: { type: Boolean, default: true },
    showPriceOverrideBox: { type: Boolean, default: false },
    stores: {
      type: Array as PropType<Record<string, any>[]>,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const { root } = useRootInstance();
    const theme = root.$themeConfig?.cartProductList || {};

    const { storesWithAvailability } = useFindInStore(root);
    const { basicInformation } = useAccount(root);
    const { employeeConnected } = useSignInToStore(root);

    // TODO: GLOBAL15-61059 remove after redesign core
    const { isCoreRedesignEnabled } = useFeatureFlagsStore();

    const price = computed(() => {
      const { currency, original, priceAfterItemDiscount } =
        props.product.price ?? {};
      const { qty = 1 } = props.product;

      let unitPrice = root.$formatPrice(original, currency);
      let salePrice = root.$formatPrice(priceAfterItemDiscount / qty, currency);
      let totalPrice = root.$formatPrice(priceAfterItemDiscount, currency);

      if (unitPrice === salePrice) {
        salePrice = undefined;
      }

      if (!priceAfterItemDiscount || !props.isPricingStrikethroughEnabled) {
        const variant = props.product.variants?.[0] || {};
        unitPrice = root.$formatPrice(
          props.product?.price?.current || variant.price?.original,
          props.product?.price?.currency || variant.price?.currency
        );
        salePrice = null;
        totalPrice = root.$formatPrice(
          props.product?.price?.rowTotal ||
            variant.price?.original * props.product?.quantity?.value,
          props.product?.price?.currency || variant.price?.currency
        );
      }

      return {
        unitPrice,
        salePrice,
        totalPrice,
      };
    });

    const getProductVariantLabel = (label: string): string =>
      (props.translations as any)[label] || label;

    const filteredCustomerNotifications = computed(() => {
      return props.customerNotifications.filter(({ code }) => {
        return !props.showOnlyErrorInfo &&
          code === FlashErrorType.ProductLineItemIsNotEligibleForGiftOption
          ? !selectedShipmentOptionIsSTS.value
          : true;
      });
    });
    const hasErrorsOrNotifications = computed(() => {
      return (
        props.flashErrors.length || filteredCustomerNotifications.value.length
      );
    });

    const isFieldShouldBeHidden = computed(() => {
      return (
        !!props.flashErrors.length &&
        !props.flashErrors.find(
          ({ code }) =>
            code === 'ProductItemMaxQuantity' ||
            code === 'ProductLineItemInventoryMissing'
        )
      );
    });

    const pdpImage = computed(
      () =>
        getProductImage(props.product, props.overrideImage) ||
        root.$mediaUrlFallback()
    );

    const productNotAvailable = computed(() => ({
      ...props.product,
      pdpImage: pdpImage.value,
      reason: 'ProductLineItemFullInventoryMissing',
    }));

    const isShippedFromStore = computed(() =>
      // Check if product has SFS attribute after shipping presourcing
      props.product.shippingNodes?.some((node) => node.shipNodeType === 'Store')
    );

    const isGiftOptionAvailable = computed(() => {
      const showGiftOption =
        props.showAddGiftOptionCta && props.product.isGiftAllowed;
      const allowedForAthlete =
        !basicInformation.value?.athlete?.athlete ||
        isExcludedFromAthleteProgram.value;

      return showGiftOption && allowedForAthlete && !isShippedFromStore.value;
    });

    const isGiftOptionAdded = computed(
      () => isGiftOptionAvailable.value && props.product.gift === true
    );

    const giftOptionBindings = computed(() => {
      const {
        gift,
        giftOption,
        isGiftBoxSelected,
        isGiftBoxAvailable,
        totalGiftBoxPrice,
        price,
      } = props.product;

      return {
        translations: props.translations.addGiftOption,
        gift,
        giftBoxPrice: root.$formatPrice(totalGiftBoxPrice, price?.currency),
        isGiftBoxAvailable,
        isGiftBoxSelected,
        giftOption,
      };
    });

    const shipmentOptions = computed<ProductShippingOption[]>(
      () => props.product.shippingOptions ?? []
    );

    const removeProduct = () => {
      emit('click-remove', props.product);
    };

    const isExcludedFromAthleteProgram = computed(
      () =>
        !props.product.productPromotions?.some(
          (promo) => promo.promotionId === AthletePromoType.PROMO_ID
        ) && basicInformation.value?.athlete?.athlete
    );

    const isExcludedFromAthleteDueSale = computed(
      () =>
        props.product.price?.original > props.product.price?.current &&
        basicInformation.value?.athlete?.athlete
    );

    const attributes = computed(() => {
      return (props.product.variants || [])
        .map((variant) => ({
          id: variant.id,
          code: variant.code,
          label: props.translations[variant.label] || variant.label,
          value: variant.value,
        }))
        .filter(({ id, value }) => id && value);
    });

    // Do not show if employee is connected and config is true.
    const shouldShowFavouriteButton = computed(
      () =>
        !(
          root.$themeConfig?.saveToFavorites?.hideFavouriteCtaForEmployees &&
          employeeConnected.value
        )
    );

    onMounted(() => {
      if (hasErrorsOrNotifications.value) {
        scrollToTop();
      }
    });

    const selectedShipmentOption = computed(() => {
      return shipmentOptions.value.find(({ selected }) => selected);
    });

    const selectedShipmentOptionIsSTS = computed(() => {
      return selectedShipmentOption.value.shippingMethod.code === 'sts';
    });

    return {
      theme,
      price,
      attributes,
      pdpImage,
      removeProduct,
      getProductVariantLabel,
      hasErrorsOrNotifications,
      filteredCustomerNotifications,
      productNotAvailable,
      isCoreRedesignEnabled,
      isGiftOptionAvailable,
      giftOptionBindings,
      isGiftOptionAdded,
      shipmentOptions,
      isFieldShouldBeHidden,
      isExcludedFromAthleteProgram,
      isExcludedFromAthleteDueSale,
      shouldShowFavouriteButton,
      shouldShowSaveForLaterButton: shouldShowFavouriteButton,
      storesWithAvailability,
    };
  },
  data() {
    return {
      collapsed: false,
      selectedQuantity: 0,
      productQty: null,
      productImage: '',
      showShippingOptionTooltip: false,
      isDisabledQuantityPicker: false,
    };
  },
  computed: {
    productAvailable() {
      return (
        !this.productHasError &&
        (!!this.product.recipeId ||
          this.product.qty <= this.product.maxQty ||
          this.product.maxQty !== 0)
      );
    },
    productHasError() {
      return (
        this.cartError?.productId &&
        this.product.productId &&
        this.cartError.productId === this.product.productId
      );
    },
    productAvailabilityLabel() {
      if (this.productHasError) {
        return this.cartError?.message;
      }
      if (this.product.available) {
        return this.product.available;
      } else {
        return this.productAvailable
          ? this.translations.availability
          : this.translations.outOfStock;
      }
    },
    qtySelectValue() {
      const allowedQty =
        !this.product.maxAllowedQty ||
        this.product.maxAllowedQty < 1 ||
        this.product.maxQty < this.product.maxAllowedQty
          ? this.product.maxQty
          : this.product.maxAllowedQty;
      const qtySelectLimit =
        allowedQty > this.maxQuantity ? this.maxQuantity : allowedQty;
      if (qtySelectLimit > this.product.qty) return qtySelectLimit;
      // For cart products with quantity greater than max allowed (already in cart) increase max qty limit for dropdown select list
      return this.product.qty;
    },
    isProductEditable() {
      return !this.product.recipeId && this.openEditProductModal;
    },
    adjustementId() {
      let adjustementId = '';
      const promotions = this.product?.productPromotions;
      if (this.itemHasPromos) {
        if (this.itemWithManualPromo)
          return this.itemWithManualPromo.priceAdjustmentId;
        return promotions[promotions.length - 1].priceAdjustmentId;
      }
      return adjustementId;
    },
    itemHasPromos() {
      const promotions = this.product?.productPromotions;
      return Array.isArray(promotions) && promotions.length > 0;
    },
    itemWithManualPromo() {
      if (this.itemHasPromos)
        return this.product.productPromotions?.find((promotion) =>
          // Try to find a string like "dw.manual1"
          promotion.promotionId?.includes('manual')
        );
      return null;
    },
    itemHasPromoAppliedAndCalloutMsg() {
      return !!this.product.productPromotions?.some(
        (promo) => !!promo.calloutMsg?.length
      );
    },
  },
  methods: {
    changeQuantity(value) {
      this.isDisabledQuantityPicker = true;
      this.$emit('click-change-quantity', {
        product: this.product,
        quantity: parseInt(value),
        onFinishCall: (success: boolean) => {
          this.isDisabledQuantityPicker = false;
          if (!success) {
            // reset cartQty
            this.$refs.refQtyController?.reset?.();
          }
        },
      });
    },
    editProduct() {
      if (this.isProductEditable) {
        this.$emit('click-edit');
      }
    },
    overridePrice(payload) {
      this.$emit('override-price', payload);
    },
    resetPrice(payload) {
      this.$emit('reset-price', payload);
    },
    updatePrice(payload) {
      this.$emit('update-price', payload);
    },
  },
});
