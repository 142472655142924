var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gift-card",class:{ 'gift-card__max-exceeded': !_vm.canAddMoreCards }},[_vm._l((_vm.appliedGiftCards),function(appliedCard){return _c('div',{key:appliedCard.payment_instrument_id,staticClass:"gift-card__applied"},[_c('VfRow',[_c('VfColumn',{attrs:{"small":12,"medium":4,"large":4}},[_c('VfText',{attrs:{"font-weight":"bold","font-sizes":['base']}},[_vm._v("\n          "+_vm._s(_vm.translations.giftCardApplied)+"\n        ")]),_vm._v(" "),_c('VfButton',{staticClass:"vf-button--text gift-card__remove-button vf-button--tiny",on:{"click":function($event){return _vm.remove(
              appliedCard.payment_instrument_id,
              appliedCard.payment_method_id
            )}}},[_vm._v("\n          "+_vm._s(_vm.translations.removeGiftCard)+"\n        ")])],1),_vm._v(" "),_c('VfColumn',{attrs:{"small":12,"medium":4,"large":4}},[_c('VfText',{attrs:{"font-weight":"normal","font-sizes":['sm']}},[_vm._v("\n          "+_vm._s(_vm.maskGiftCardNumber(appliedCard.c_gcCardNumber))+"\n        ")])],1),_vm._v(" "),_c('VfColumn',{attrs:{"small":12,"medium":4,"large":4}},[_c('VfPrice',{attrs:{"regular":("-" + (_vm.$root.$formatPrice(appliedCard.amount)))}})],1)],1),_vm._v(" "),_c('VfDivider')],1)}),_vm._v(" "),(_vm.canAddMoreCards)?[(!!_vm.appliedGiftCards.length)?_c('VfHeading',{staticClass:"gift-card__add-another",attrs:{"level":6,"title-modifier":"title-6","title":_vm.translations.addAnotherGiftCard}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"gift-card__form row"},[_c('VfInput',{staticClass:"gift-card__input gift-card__card-number-input",attrs:{"label":_vm.translations.card_number,"valid":!_vm.$v.cardNumber.$error,"error-message":!_vm.$v.cardNumber.required
            ? _vm.translations.validation_messages.required
            : _vm.translations.validation_messages.digitsLength,"name":"cardNumber"},on:{"blur":function($event){return _vm.$v.cardNumber.$touch()}},model:{value:(_vm.cardNumber),callback:function ($$v) {_vm.cardNumber=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"cardNumber"}}),_vm._v(" "),_c('VfInput',{staticClass:"gift-card__input gift-card__pin-input",attrs:{"label":_vm.translations.pin,"valid":!_vm.$v.pin.$error,"error-message":!_vm.$v.pin.required
            ? _vm.translations.validation_messages.required
            : _vm.translations.validation_messages.pinLength,"name":"pin"},on:{"blur":function($event){return _vm.$v.pin.$touch()},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.apply()}},model:{value:(_vm.pin),callback:function ($$v) {_vm.pin=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"pin"}}),_vm._v(" "),_c('div',{staticClass:"gift-card__button-and-tooltip"},[(_vm.displayButtonNextToInput)?_c('VfButton',{staticClass:"gift-card__button-next-to-input vf-button--extra-small",attrs:{"disabled":_vm.$v.$invalid},on:{"click":function($event){return _vm.apply()}}},[_vm._v("\n          "+_vm._s(_vm.translations.btnCTA)+"\n        ")]):_vm._e(),_vm._v(" "),_c('VfTooltip',{staticClass:"gift-card__tooltip",attrs:{"is-visible":_vm.showTooltip,"close-text":_vm.translations.close},on:{"click-close":function($event){_vm.showTooltip = false}},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.tooltipHelpImage)?_c('VfImage',{attrs:{"src":_vm.tooltipHelpImage,"alt":_vm.translations.tooltip,"width":_vm.tooltipImageWidth,"height":_vm.tooltipImageHeight}}):[_vm._v("\n              "+_vm._s(_vm.translations.tooltip)+"\n            ")]]},proxy:true}],null,false,1426234992)},[_c('VfButton',{staticClass:"vf-button--text gift-card__info",on:{"click":function($event){_vm.showTooltip = true}}},[_vm._v("\n            "+_vm._s(_vm.translations.info)+"\n          ")])],1)],1)],1),_vm._v(" "),(!_vm.displayButtonNextToInput)?_c('div',{staticClass:"gift-card__button-wrapper"},[_c('VfButton',{staticClass:"vf-button--medium",attrs:{"disabled":_vm.$v.$invalid},on:{"click":function($event){return _vm.apply()}}},[_vm._v("\n        "+_vm._s(_vm.translations.btnCTA)+"\n      ")])],1):_vm._e()]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }