



























import {
  computed,
  ref,
  defineComponent,
  PropType,
  reactive,
  onMounted,
  onUnmounted,
} from '@vue/composition-api';
import {
  Context,
  LoyaltyFinalizationType,
  LoyaltySurveyStep,
  LoyaltyConfirmationStep,
  LoyaltyQueryParamSettings,
} from '@vf/api-contract';
import LoyaltyRegisterForms from '@/components/loyalty/LoyaltyRegisterLoginForms.vue';
import SimplifiedEnrollment from '@/components/static/simplifiedEnrollment/SimplifiedEnrollment.vue';
import useRootInstance from '@/shared/useRootInstance';
import { getRedirectUrlIfSafe } from '@vf/composables/src/utils/getRedirectUrlIfSafe';
import { useAuthentication, useReCaptcha } from '@vf/composables';
import useModal from '@/shared/useModal';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

export default defineComponent({
  name: 'LoyaltyRegisterLogin',
  components: {
    LoyaltyRegisterForms,
    SimplifiedEnrollment,
  },
  props: {
    /** Display all the forms of the flow in a modal. Otherwise, all the forms are displayed as a part of the page. */
    displayAsModal: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    /** Override Campaign Greeting text */
    campaignHeading: {
      type: String as PropType<string>,
      default: '',
    },
    /** Override Campaign Subtext text */
    campaignSubheading: {
      type: String as PropType<string>,
      default: '',
    },
    /** Login step bindings object */
    loginStep: {
      type: Object as PropType<Record<string, unknown>>,
      required: true,
    },
    /** Register step bindings object */
    registerStep: {
      type: Object as PropType<Record<string, unknown>>,
      required: true,
    },
    /** Display the survey step as part of the flow */
    surveyStep: {
      type: Object as PropType<LoyaltySurveyStep>,
      required: true,
    },
    /** Display the confirmation step as part of the flow */
    confirmationStep: {
      type: [Boolean, Object] as PropType<boolean | LoyaltyConfirmationStep>,
      required: true,
    },
    /** Page to redirect to after Registration */
    pageToRedirectToAfterRegister: {
      type: String as PropType<string>,
      required: true,
    },
    /** Page to redirect to after Login */
    pageToRedirectToAfterLogin: {
      type: String as PropType<string>,
      required: true,
    },
    /** Finalization type for Register **/
    registerFinalizationType: {
      type: String as PropType<LoyaltyFinalizationType>,
      required: true,
    },
    /** Finalization type for Login **/
    loginFinalizationType: {
      type: String as PropType<LoyaltyFinalizationType>,
      required: true,
    },
    contextKey: {
      type: String as PropType<Context>,
      default: Context.PageContent,
    },
    /** Show radio buttons/tabs on top of form to switch between login/register **/
    showTabsAboveForm: { type: Boolean as PropType<boolean>, default: false },
    /** Show link to register form in login step **/
    loginStepShowRegistrationLink: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    /** In tabs mode, sign up tab text **/
    signUpTabText: { type: String as PropType<string>, default: '' },
    /** In tabs mode, sign in tab text **/
    signInTabText: { type: String as PropType<string>, default: '' },
    /** In tabs mode, sign up button text **/
    joinTheFamilyButtonText: { type: String as PropType<string>, default: '' },
    /** In tabs mode, sign in button text **/
    signInButtonText: { type: String as PropType<string>, default: '' },
    showEnrollmentAfterLoginToLegacyUsers: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    enableLoginForm: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { modalType } = useModal();
    const reCaptcha = useReCaptcha(root);
    const { loyaltyLegacyUser } = useAuthentication(root);
    const { isSimplifiedEnrollmentEnabled } = useFeatureFlagsStore();

    /**
     * To determine if this component is to be explicitly shown inside a modal,
     * because of the special "displayAsModal" prop
     **/
    const isModal = computed(
      () =>
        props.displayAsModal &&
        props.contextKey !== Context.Modal &&
        modalType.value !== 'lazyFragment'
    );
    const modalVisible = ref(isModal.value);
    const queryParams = reactive<LoyaltyQueryParamSettings>({
      campaignName: '',
      stlHash: null,
      pageToRedirectTo: null,
      email: null,
      join: false,
    });

    const readQueryParams = () => {
      const route = root.$route;
      const paramObjectMap = {
        enrollment_marketing_source: 'campaignName',
        stl_params_hash: 'stlHash',
        email: 'email',
        join: 'join',
      };

      Object.keys(route.query).forEach((q) => {
        if (q in paramObjectMap) {
          let value: any = route.query[q];

          if (q === 'email' && process.client) {
            value = window.atob(route.query[q] as string);
          }
          if (q === 'join') {
            value = route.query[q] === 'true';
          }

          queryParams[paramObjectMap[q]] = value;
        }
      });

      queryParams.pageToRedirectTo = getRedirectUrlIfSafe(
        route.query.redirectTo as string
      );
    };

    readQueryParams();

    /**
     * There is also a possibility that this component is already inside a modal
     * if its triggered from a CTA as lazy fragment.
     **/
    const isInsideModal = computed(
      () =>
        props.displayAsModal ||
        (props.displayAsModal && modalType.value === 'lazyFragment') ||
        props.contextKey === Context.Modal
    );

    onMounted(() => reCaptcha.showBadge());
    onUnmounted(() => reCaptcha.hideBadge());

    return {
      isModal,
      modalVisible,
      isInsideModal,
      queryParams,
      loyaltyLegacyUser,
      isSimplifiedEnrollmentEnabled,
    };
  },
});
