




import {
  computed,
  defineComponent,
  onMounted,
  PropType,
} from '@vue/composition-api';
import { CuralateType } from '@vf/api-contract';
import { loadCuralate } from '@/helpers/loadCuralate';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'CuralateSocialMedia',
  props: {
    type: {
      type: String as PropType<CuralateType>,
      default: 'other',
    },
    filter: String as PropType<string>,
    customContainerID: String as PropType<string>,
  },
  setup(props) {
    const { root } = useRootInstance();

    onMounted(() => {
      const crl8SiteName = root.$env.CRL8_SITENAME;
      if (crl8SiteName) {
        loadCuralate(crl8SiteName);
      }
    });

    const curalateData = computed(() => ({
      'data-crl8-container-id':
        props.type === 'other' ? props.customContainerID : props.type,
      ...(props.filter && { 'data-crl8-filter': props.filter }),
    }));

    return {
      curalateData,
    };
  },
});
