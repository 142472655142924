











import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'VfPromoBar',
  props: {
    teasableItems: {
      type: Object,
      default: () => ({}),
    },
    actionButton: {
      type: String,
      default: 'Details',
    },
    actionButtonType: {
      type: String,
      default: 'Modal',
    },
    enableOverlayArrows: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
      default: '#000000',
    },
    textColor: {
      type: String,
      default: '#FFFFFF',
    },
    enablePauseOnHover: {
      type: Boolean,
      default: true,
    },
    promosPerSlide: {
      type: String,
      default: '1',
    },
    enableSwiping: {
      type: Boolean,
      default: true,
    },
    interval: {
      type: Number,
      default: 2000,
    },
  },
});
