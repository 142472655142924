



























import type { PropType } from 'vue';
import {
  ref,
  onMounted,
  watch,
  defineComponent,
  computed,
} from '@vue/composition-api';
import type { CheckoutShippingInfoNotificationTranslations } from '@vf/api-contract';
import { useCheckout } from '@vf/composables';
import AddressVerificationModal from '../../modals/AddressVerification.vue';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'CheckoutShippingInfoNotification',
  components: {
    AddressVerificationModal,
  },
  props: {
    translations: {
      type: Object as PropType<CheckoutShippingInfoNotificationTranslations>,
      default: () => ({}),
    },
  },
  setup(props) {
    const { root } = useRootInstance();

    const {
      AVSrestoreAddress,
      storeAddresses,
      confirmAddressAndClearAVSStorage,
      AVSchanged,
      AVSdone,
      shippingAddress,
      AVSoldAddress,
      AVSisAddressInvalid,
    } = useCheckout(root);

    const isNotificationVisible = ref(false);

    const isNotificationCentered = computed(
      () => root.$themeConfig?.checkoutShippingInfoNotification?.isCentered
    );

    const isNotifyOnUseSuggestedAddress = computed(
      () =>
        root.$themeConfig?.checkoutShippingInfoNotification
          ?.isNotifyOnUseSelectedAddress || false
    );

    const addressVerificationModalVisible = ref(false);

    const notificationMessage = ref({
      message: '',
      type: 'info',
      action: '',
    });

    const closeAddressVerification = (hasNotify = false) => {
      addressVerificationModalVisible.value = false;
      if (hasNotify) {
        isNotificationVisible.value = true;
        notificationMessage.value.message = props.translations.address_updated;
        notificationMessage.value.type = 'success';
        notificationMessage.value.action =
          props.translations.click_to_see_changes;
      } else {
        isNotificationVisible.value = false;
      }
      AVSdone.value = true;
      confirmAddressAndClearAVSStorage();
    };

    const handleAVSChanged = () => {
      isNotificationVisible.value = false;
      if (AVSchanged.value) {
        isNotificationVisible.value = true;
        notificationMessage.value.message = props.translations.address_updated;
        notificationMessage.value.type = 'success';
        notificationMessage.value.action =
          props.translations.click_to_see_changes;
      }
    };

    onMounted(() => {
      handleAVSChanged();
    });

    const updateAddress = () => {
      AVSrestoreAddress().then(() => {
        storeAddresses();
      });
      closeAddressVerification();
      AVSisAddressInvalid.value = false;
    };

    watch(AVSchanged, () => {
      handleAVSChanged();
    });

    return {
      isNotificationVisible,
      isNotificationCentered,
      addressVerificationModalVisible,
      notificationMessage,
      closeAddressVerification,
      AVSrestoreAddress,
      shippingAddress,
      AVSoldAddress,
      updateAddress,
      isNotifyOnUseSuggestedAddress,
    };
  },
});
