import { storeToRefs } from 'pinia';
import { computed } from '@vue/composition-api';
import {
  VoucherMessageType,
  PromotionMessage,
  PromotionMessageContext,
  ApproachingDiscountDetails,
} from '@vf/api-contract';
import { ApproachingDiscount } from '@vf/api-client';

import { useCheckoutStore } from '../store/checkoutStore';
import { usePromotionMessageStore } from '../store/promotionMessageStore';

interface ComposableContext {
  promotions: PromotionMessage[];
  context: PromotionMessageContext;
}

const getMessageType = (voucher: ApproachingDiscount) => {
  return voucher.earned
    ? VoucherMessageType.Success
    : VoucherMessageType.Approaching;
};

export const usePromotionMessage = (
  instance,
  composableContext: ComposableContext
) => {
  const { promotions: cmsPromotions, context } = composableContext;

  const { order } = storeToRefs(useCheckoutStore());
  const { approachingDiscounts } = storeToRefs(usePromotionMessageStore());

  /**
   *
   * @param vouchers - bounceBackVouchers or approachingDiscount items
   * @returns reduced total number of vouchers with valid message to e rendered
   */
  const getVoucherDetails = (approachingDiscount: ApproachingDiscount[]) => {
    if (!approachingDiscount) {
      return [];
    }
    return approachingDiscount.reduce(
      (total: ApproachingDiscountDetails[], voucher) => {
        // prepare voucherDetails based on given promotionId (eg. bounceBackVouchers)
        const promotionId = voucher.promotionId ?? 'bounceBackVouchers';

        const { translations } =
          cmsPromotions?.find(({ name }) => name === promotionId) ?? {};
        if (!translations) {
          console.warn(
            'Cannot find CMS promotion translations for given voucher',
            { promotionId }
          );
        }

        /**
         * Voucher messageType. Based on CMS translations for different type: 'approaching' and 'auccess' accordingly.
         */
        const messageType = getMessageType(voucher);

        /**
         * message property from translations is obligatory to display promotion message at all.
         */
        if (!translations?.[messageType]?.message) return total;

        const discountDetails = {
          earned: voucher.earned,
          conditionThreshold: voucher.conditionThreshold ?? 0,
          currentAmount: voucher.merchandiseTotal ?? 0,
          title: translations[messageType].title,
          message: (translations[messageType].message ?? '')
            .replace('{{discountAmount}}', voucher.discount ?? '$discount$')
            .replace(
              '{{remainingAmount}}',
              instance.$formatPrice(voucher.distanceFromConditionThreshold)
            )
            .replace('{{callout}}', voucher.calloutMsg || '$calloutMsg$'),
        };

        return [...total, discountDetails];
      },
      []
    );
  };

  return {
    bounceBackVouchers: computed(() => {
      /**
       * For OrderConfirmation context we use different API
       */
      if (context === PromotionMessageContext.OrderConfirmation) {
        return getVoucherDetails(order.value?.bounceBackVouchers);
      }

      const vouchers = approachingDiscounts.value.filter(
        ({ promotionId }) => promotionId === 'bounceBackVouchers'
      );
      return getVoucherDetails(vouchers);
    }),
    approachingDiscounts: computed(() =>
      getVoucherDetails(
        approachingDiscounts.value.filter(({ promotionId }) =>
          promotionId.includes('GWP')
        )
      )
    ),
  };
};
