























































import { computed, defineComponent } from '@vue/composition-api';
import { CTAExperience, CustomsCTAExperiences } from '@vf/api-contract';
import { useGtm } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';

const CustomsCta = () =>
  import(
    /* webpackChunkName: "CustomsCta" */
    /* webpackMode: "lazy" */
    /* webpackPrefetch: true */
    /* webpackPreload: true */
    '../customs/CustomsCta.vue'
  );

export default defineComponent({
  name: 'CustomShoesTeaser',
  components: {
    CustomsCta,
  },
  props: {
    /** Object containing fields to pass to Iframe. Type string for authoring JSON purposes */
    dataObject: {
      type: String,
      default: '',
    },
    /** Type of UMD experience that will be opened  */
    experience: {
      type: String,
      default: 'styleSelectorIframeCallEvent',
      validator: (value: CTAExperience) =>
        CustomsCTAExperiences.includes(value),
    },
    /** Flag determining whether button should be rendered as a Button or CustomsCta */
    isCustomsCta: {
      type: Boolean,
      default: false,
    },
    /** Image object to display as a background */
    background: {
      type: Object,
      default: () => ({}),
    },
    /** Image object to display over the background */
    heroPicture: {
      type: Object,
      default: () => ({}),
    },
    /** Images Array to display as a overlay content */
    overlayPictures: {
      type: Array,
      default: () => [],
    },
    /** Rich Text to be displayed under the overlay pictures */
    richText: {
      type: String,
      default: '',
    },
    /** Button text */
    buttonText: {
      type: String,
      default: '',
    },
    /** Button link */
    buttonLink: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { dispatchEvent } = useGtm(root);
    const backgroundStyle = computed(() =>
      Object.values(props.background)
        ? {
            '--_grid-background-image': `url(${props.background.small})`,
            '--_grid-background-medium-medium':
              props.background.medium && `url(${props.background.medium})`,
            '--_grid-background-large-image':
              props.background.large && `url(${props.background.large})`,
          }
        : {}
    );
    const buttonType = computed(() => {
      return props.isCustomsCta && props.dataObject ? 'CustomsCta' : 'VfButton';
    });

    const logEvent = (data) => {
      dispatchEvent({
        eventName: 'loadEventData',
        overrideAttributes: {
          eventAction: 'Main Page',
          eventLabel: data,
        },
      });
    };
    return {
      backgroundStyle,
      buttonType,
      logEvent,
    };
  },
});
