































import type { PropType } from 'vue';
import { defineComponent } from '@vue/composition-api';
import type { IconPosition, Icons } from '@vf/api-contract';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

export default defineComponent({
  name: 'ProductCustomizeCta',
  props: {
    text: {
      type: String,
      default: '',
    },
    /** Flag to determine if button should be enabled */
    enabled: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String as PropType<Icons>,
      default: 'customize',
    },
    /** Button size  */
    buttonSize: {
      type: String as PropType<'sm' | 'md' | 'lg'>,
      default: 'md',
    },
    /** Button style */
    buttonStyle: {
      type: Object as PropType<{ color: string; variant?: string }>,
      default: () => ({ color: 'primary' }),
    },
    iconPosition: {
      type: String as PropType<IconPosition>,
      default: '',
    },
    isFullWidth: {
      type: Boolean,
      default: false,
    },
    buttonComponent: {
      type: String,
      required: true,
    },
    /** CSS class modifiers for text from teaser styles */
    textCustomClasses: {
      type: String,
      default: '',
    },
    contextKey: {
      type: String,
      default: 'product',
    },
    /** Flag to determine if button text should be underline */
    underline: {
      type: Boolean,
      default: false,
    },
    customizerLink: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { isVansPdpRedesignEnabled } = useFeatureFlagsStore(); // TODO: GLOBAL15-63801 clean up

    const overwriteProps = isVansPdpRedesignEnabled
      ? {
          iconPosition: 'left',
          buttonComponent: 'ThemeLink',
          buttonSize: 'base',
          buttonStyle: { color: 'primary' },
        }
      : props;
    return { isVansPdpRedesignEnabled, overwriteProps };
  },
});
