























import { useFetch, defineComponent } from '@nuxtjs/composition-api';
import { useFindInStore, useRequestTracker } from '@vf/composables';
import { setPageTypeName } from '@/helpers';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'StoreCountries',
  props: {
    /**
     * Block title.
     */
    title: {
      type: String,
      default: '',
    },
    /**
     * Meta data.
     */
    metaData: {
      type: Object,
      default: () => {
        /**/
      },
    },
  },
  setup() {
    const { root } = useRootInstance();

    const { getCountriesAndStates, countries } = useFindInStore(root);
    const { onAllDone } = useRequestTracker(root);

    useFetch(async () => {
      await getCountriesAndStates();
    });

    onAllDone(() => {
      setPageTypeName('stores root page');
    });

    return {
      countries,
      urlGenerator: root.$storeLocatorUrlGenerator,
    };
  },
  head() {
    const metaData = this.metaData;
    return {
      title: metaData.title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          property: 'description',
          content: metaData.description,
        },
        {
          hid: 'og:description',
          name: 'og:description',
          property: 'og:description',
          content: metaData.description,
        },
        {
          hid: 'keywords',
          name: 'keywords',
          property: 'keywords',
          content: metaData.keywords,
        },
      ],
      link: [
        {
          rel: 'canonical',
          href:
            'https://' +
            this.$getDomainName() +
            this.$storeLocatorUrlGenerator('/stores'),
        },
      ],
    };
  },
});
