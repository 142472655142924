var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"shipping__group"},[(_vm.selectedCode.startsWith('customs'))?_c('VfText',{staticClass:"shipping__group-description"},[_vm._v("\n    "+_vm._s(_vm.customsShippingText)+"\n    "),_c('VfTooltip',{attrs:{"is-visible":_vm.showTooltip,"close-text":_vm.translations.customsTooltipCloseText},on:{"click-close":function($event){_vm.showTooltip = false}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"shipping-tooltip"},_vm._l((_vm.translations.customsTooltipMessages),function(message,key){return _c('p',{key:key,domProps:{"textContent":_vm._s(message)}})}),0)]},proxy:true}],null,false,1132659664)},[_c('VfButton',{staticClass:"vf-button--text",on:{"click":function($event){_vm.showTooltip = true}}},[_vm._v("\n        "+_vm._s(_vm.translations.shippingDetailsButton)+"\n      ")])],1)],1):(_vm.translations.shippingMethodText)?_c('VfText',{staticClass:"shipping__group-description",attrs:{"content":_vm.translations.shippingMethodText,"html":""}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"shipping__methods"},_vm._l((_vm.shippingGroup.methods),function(method){return _c('label',{key:method.code,staticClass:"item",class:{
        'item-active': method.code === _vm.selectedCode,
      }},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"radio"},[(_vm.showRadio)?_c('VfRadio',{attrs:{"selected":_vm.selectedCode,"name":"shipping","label":method.label,"value":method.code},on:{"input":function($event){return _vm.$emit('change', method.code)}}}):_c('span',[_vm._v("\n            "+_vm._s(method.label)+"\n          ")])],1),_vm._v(" "),_c('div',{staticClass:"labels"},[_c('VfText',{staticClass:"delivery-date",attrs:{"html":true,"content":_vm.getDeliveryDateLabel(
                method.deliveryTime,
                _vm.narvarTranslations.deliveryDateLabel,
                false,
                true,
                _vm.localeData
              )}}),_vm._v(" "),(_vm.itemsToShip.length > 1)?_c('div',{staticClass:"disclaimer"},[_vm._v("\n            "+_vm._s(_vm.translations.deliveryDateDisclaimer)+"\n          ")]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"price"},[_c('VfPrice',{attrs:{"regular":_vm.getShippingGroupFormattedPrice(
                method,
                _vm.translations.freeShippingGroupLabel
              )}})],1)])])}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }