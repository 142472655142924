















import { storeToRefs } from 'pinia';
import { defineComponent } from '@vue/composition-api';
import { useCheckout } from '@vf/composables';
import { useCartStore } from '@vf/composables/src/store/cartStore';
import PaymentReview from '@/components/static/checkout/review/PaymentReview.vue';
import useRootInstance from '@/shared/useRootInstance';
import { useCardStore } from '@vf/composables/src/store/card';

export default defineComponent({
  name: 'CheckoutPaymentReview',
  components: { PaymentReview },
  setup() {
    const { root } = useRootInstance();
    const { paymentMethod } = useCheckout(root);
    const { card } = useCardStore();
    const {
      appliedGiftCards,
      billingAddress,
      isZeroOrder,
      appliedRewardCards,
      appliedRewardCodes,
      appliedPointRewards,
      athleteDiscounts,
    } = storeToRefs(useCartStore());

    return {
      paymentMethod,
      card,
      appliedGiftCards,
      appliedRewardCards,
      appliedRewardCodes,
      appliedPointRewards,
      athleteDiscounts,
      billingAddress,
      isZeroOrder,
    };
  },
});
