































































import type { PropType } from 'vue';
import { computed, defineComponent, reactive, ref } from '@vue/composition-api';
import { CartLineItem } from '@vf/api-client';
import useValidators from '@vf/composables/src/useValidators';
import { validateRequired } from '@vf/composables/src/useValidators/validators';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'PriceOverride',
  props: {
    product: {
      type: Object as PropType<CartLineItem>,
      default: null,
    },
  },
  emits: ['update-price', 'override-price', 'reset-price'],
  setup(props, { emit }) {
    const { root } = useRootInstance();

    const isExpanded = ref(false);
    const isPriceFocused = ref(false);

    const form = reactive({
      price: null,
      comment: '',
    });

    const {
      valid,
      validate,
      reset: resetValidation,
      validationFields,
    } = useValidators(
      form,
      ref({
        price: [
          validateRequired(
            root.$t('priceOverride.validation.required') as string
          ),
        ],
        comment: [
          validateRequired(
            root.$t('priceOverride.validation.required') as string
          ),
        ],
      })
    );

    const productPromotions = computed(() => {
      return props.product?.productPromotions || [];
    });

    const manualProductPromotionId = computed(() => {
      const manualProductPromotion = productPromotions.value.find(
        ({ promotionId }) => promotionId.includes('manual')
      );
      return manualProductPromotion?.priceAdjustmentId;
    });

    const productPromotionId = computed(() => {
      if (manualProductPromotionId.value) return manualProductPromotionId.value;
      const length = productPromotions.value.length;
      return length > 0
        ? productPromotions.value[length - 1].priceAdjustmentId
        : undefined;
    });

    const submit = () => {
      if (!validate()) return;
      const eventName = productPromotionId.value
        ? 'update-price'
        : 'override-price';
      emit(eventName, {
        price: form.price,
        comment: form.comment,
        productId: props.product.productId,
        itemId: props.product.id,
        priceAdjustmentId: productPromotionId.value,
      });
      resetForm();
    };

    const reset = (collapse = true) => {
      if (collapse) isExpanded.value = false;
      resetForm();
      if (productPromotionId.value)
        emit('reset-price', productPromotionId.value);
    };

    const resetForm = () => {
      form.price = null;
      form.comment = '';
      resetValidation();
    };

    const showCurrency = computed(() => {
      return (
        isPriceFocused.value ||
        (!isPriceFocused.value &&
          (form.price !== null || validationFields.value.price.invalid))
      );
    });

    return {
      isExpanded,
      form,
      valid,
      validate,
      validationFields,
      submit,
      reset,
      productPromotionId,
      showCurrency,
      isPriceFocused,
    };
  },
});
