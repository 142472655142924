import { render, staticRenderFns } from "./PromoCodeWithSingleValue.vue?vue&type=template&id=bc0cbe58&scoped=true&"
import script from "./PromoCodeWithSingleValue.vue?vue&type=script&lang=js&"
export * from "./PromoCodeWithSingleValue.vue?vue&type=script&lang=js&"
import style0 from "./PromoCodeWithSingleValue.vue?vue&type=style&index=0&id=bc0cbe58&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc0cbe58",
  null
  
)

export default component.exports