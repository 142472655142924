


























// TODO: clean up this smart component GLOBAL15-56318
import type { PropType } from 'vue';
import { defineComponent } from '@vue/composition-api';
import { useCheckout, useCart } from '@vf/composables';
import RichText from '../common/RichText.vue';
import useRootInstance from '@/shared/useRootInstance';
import type { RichTextTagElement } from '@vf/api-contract';

export default defineComponent({
  name: 'CheckoutTermsConditions',
  components: {
    RichText,
  },
  props: {
    /** Rerirect target by button click */
    target: {
      type: String,
      default: 'payment',
    },
    tag: {
      type: String as PropType<RichTextTagElement>,
      default: 'p',
    },
    /** Text content or HTML if html prop is set to true */
    content: {
      type: String,
      default: '',
    },
    /** True if provided content should be rendered as HTML. False for normal text */
    html: {
      type: Boolean,
      default: false,
    },
    /** Color for text from teaser styles */
    textColor: {
      type: String,
      default: '',
    },
    /** CSS class modifiers for text from teaser styles */
    textCustomClasses: {
      type: String,
      default: '',
    },
    /** Background color for text from teaser styles */
    backgroundColor: {
      type: String,
      default: '',
    },
    /** Translations object */
    translations: {
      type: Object,
      default: () => ({
        buttonText: 'Place order',
      }),
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { isProceedBillingButtonDisabled, hasItems: cartHasItems } = useCart(
      root
    );
    const {
      isPoAddress,
      goToPayment,
      placeOrderAction,
      showPlaceOrderButtonAtPayment,
    } = useCheckout(root);

    const buttonClickAction = () => {
      if (props.target == 'payment') {
        goToPayment();
      } else if (props.target == 'success') {
        placeOrderAction();
      }
    };

    return {
      isProceedBillingButtonDisabled,
      isPoAddress,
      buttonClickAction,
      showPlaceOrderButtonAtPayment,
      cartHasItems,
    };
  },
});
