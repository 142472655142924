









































































import { computed, defineComponent, ref } from '@nuxtjs/composition-api';
import useRootInstance from '@/shared/useRootInstance';

import AddressPreview from '@/components/static/AddressPreview.vue';

export default defineComponent({
  name: 'AddressSuggestionModal',
  components: {
    AddressPreview,
  },
  props: {
    resolveModal: Function,
    visible: Boolean,
    currentAddress: Object,
    suggestedAddress: Object,
  },
  setup() {
    const { root } = useRootInstance();
    const wrapperComponent = computed(() =>
      root.$viewport.breakpoint.current === 'smDown' ? 'VfPanel' : 'VfModal'
    );

    const selectedOption = ref('suggested');
    const selectOption = (value) => {
      selectedOption.value = value;
    };

    return {
      selectOption,
      selectedOption,
      wrapperComponent,
    };
  },
});
