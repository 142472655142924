

























































import { defineComponent, computed } from '@vue/composition-api';
import useRootInstance from '@/shared/useRootInstance';
import { useSaveForLater, useNotification, useCart } from '@vf/composables';
const ROW_NOTIFICATION_CLOSE_TIMEOUT = 5000;

export default defineComponent({
  name: 'SavedForLaterProducts',
  props: {
    translations: {
      type: Object,
      required: true,
    },
    isCartEmpty: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const theme = root.$themeConfig.cartProductList;

    const {
      saveForLater,
      savedForLaterProductsWithDeletedItems,
      updateSaveForLater,
      updateSavedForLaterProductsList,
      removeSaveForLater,
      deletedFromSavedForLaterProductsList,
      clearDeletedSFLproducts,
      moveSaveForLaterToCart,
      loading: isLoading,
    } = useSaveForLater(root);

    const {
      clearNotifications,
      setItemRowNotification,
      addNotification,
    } = useNotification(root);

    const showSavedForLater = computed(() => {
      return sflProducts.value.length || showIsRemovedFromSavedForLater.value;
    });

    const showIsRemovedFromSavedForLater = computed(() => {
      return (
        props.isCartEmpty &&
        !saveForLater.value.count &&
        deletedFromSavedForLaterProductsList.value.length > 0
      );
    });

    const moveSflProductToCart = async (item) => {
      clearNotifications();
      const success = await moveSaveForLaterToCart(item);
      if (success && !theme.showSuccessMessagePerRow) {
        addNotification({
          message: props.translations.movedToCartNotification,
          type: 'info',
        });
      }
    };

    const updateSflProduct = (data) => {
      updateSaveForLater({
        ...data.product,
        quantity: { value: Number(data.quantity) },
      }).then(() => {
        data.onFinishCall?.(true);
      });
    };

    const removeSflProduct = async (item, index) => {
      clearNotifications();
      await removeSaveForLater(item.itemId);

      updateSavedForLaterProductsList({ index, product: item });
      if (theme.showSuccessMessagePerRow) {
        setItemRowNotification({
          id: item.itemId,
          message: props.translations.removedFromSavedForLaterMessage,
        });
      }

      clearDeletedSFLproducts(ROW_NOTIFICATION_CLOSE_TIMEOUT);
    };

    const { cartItems } = useCart(root);

    const isItemInCart = (itemProductId: string): boolean => {
      return !!cartItems.value.find(
        (cartItem) => cartItem.productId === itemProductId
      );
    };

    const getItemImageUrl = ({
      productImageURL,
      masterId,
      name,
      variants,
    }: typeof savedForLaterProductsWithDeletedItems.value[0]) => {
      return (
        productImageURL ||
        (root.$mediaUrlGenerator({
          colorName: variants.find(({ code }) => code === 'color')?.value,
          pid: masterId,
          productName: name,
        }) as string)
      );
    };

    const sflProducts = computed(() => {
      let products = savedForLaterProductsWithDeletedItems.value;
      if (isLoading.value) {
        products = products.filter((item) => !isItemInCart(item.productId));
      }
      return products.map((item) => ({
        ...item,
        image: getItemImageUrl(item),
      }));
    });

    const showSeparator = computed(() => {
      return (
        sflProducts.value.length &&
        (!root.$viewport.isSmall ||
          (root.$viewport.isSmall &&
            theme.showSavedForLaterProductsSeparatorOnMobile))
      );
    });

    return {
      theme,
      savedForLaterProductsWithDeletedItems,
      sflProducts,
      saveForLater,
      showSavedForLater,
      showIsRemovedFromSavedForLater,
      updateSflProduct,
      removeSflProduct,
      moveSflProductToCart,
      isItemInCart,
      getItemImageUrl,
      showSeparator,
    };
  },
});
