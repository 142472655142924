

































import { PropType } from 'vue';
import { defineComponent } from '@vue/composition-api';
import { PromotionMessage, PromotionMessageContext } from '@vf/api-contract';
import { usePromotionMessage } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';

import BounceBackVoucher from '@/components/promotion/BounceBackVoucher.vue';
import ApproachingDiscount from '@/components/promotion/ApproachingDiscount.vue';

export default defineComponent({
  name: 'PromotionMessage',
  components: {
    BounceBackVoucher,
    ApproachingDiscount,
  },
  props: {
    promotions: {
      type: Array as PropType<PromotionMessage[]>,
      required: true,
    },
    /** Context to determine where PromotionMessage is rendered (cart | miniCart | orderConfirmation) */
    context: {
      type: String as PropType<PromotionMessageContext>,
      default: PromotionMessageContext.Cart,
      validator: (value: PromotionMessageContext) => {
        return [
          PromotionMessageContext.Cart,
          PromotionMessageContext.MiniCart,
          PromotionMessageContext.OrderConfirmation,
        ].includes(value);
      },
    },
  },
  setup(props) {
    const { root } = useRootInstance();

    const { bounceBackVouchers, approachingDiscounts } = usePromotionMessage(
      root,
      {
        promotions: props.promotions,
        context: props.context,
      }
    );

    return {
      PromotionMessageContext,
      bounceBackVouchers,
      approachingDiscounts,
    };
  },
});
