




















import { computed, ref, defineComponent, PropType } from '@vue/composition-api';
import { useCart } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import { ShoppingCartLabelTranslations } from '@vf/api-contract';

export default defineComponent({
  name: 'CartShoppingLabel',
  props: {
    translations: {
      type: Object as PropType<ShoppingCartLabelTranslations>,
      default: () => ({}),
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { cart } = useCart(root);
    const { isCartMerged } = useCart(root);
    const showMergedCartNotification = ref(isCartMerged.value);
    const totalItems = computed(() => {
      if (cart.value.totalItems === 1) {
        return props.translations.singleItemLabel;
      } else {
        return props.translations.multipleItemLabel.replace(
          '{0}',
          String(cart.value.totalItems)
        );
      }
    });

    return { totalItems, showMergedCartNotification };
  },
});
