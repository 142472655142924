











































import type { PropType } from 'vue';
import { computed, defineComponent } from '@vue/composition-api';
import { useGtm, ROUTES } from '@vf/composables';
import type {
  CategoryTeaserItem,
  ContentAlignProperties,
} from '@vf/api-contract';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';
import { AnalyticsDataDomLocation } from '@/types';
import useRootInstance from '@/shared/useRootInstance';
import useModal from '@/shared/useModal';

export default defineComponent({
  name: 'VideoWithOverlay',
  serverCacheKey: getCacheKeyFromProps,
  props: {
    /** Category teaser items */
    category: {
      type: Object as PropType<CategoryTeaserItem>,
      default: () => ({}),
    },
    /** Flag to determine if overlay should be visible */
    showOverlay: {
      type: Object,
      default: () => ({
        small: true,
        medium: true,
        large: true,
      }),
    },
    /** Flag to determine if overlay should be transparent */
    transparentOverlay: {
      type: Object,
      default: () => ({
        small: false,
        medium: false,
        large: false,
      }),
    },
    /** Flag to determine if overlay should be visible */
    alwaysShowOverlay: {
      type: Object,
      default: () => ({
        small: false,
        medium: false,
        large: false,
      }),
    },
    /** Flag to determine if should be displayed below */
    showBelowArticle: {
      type: Object,
      default: () => ({
        small: false,
        medium: false,
        large: false,
      }),
    },
    /** Flag to determine if just the buttons should be displayed below banner image */
    showButtonsBelowArticle: {
      type: Object,
      default: () => ({
        small: false,
        medium: false,
        large: false,
      }),
    },
    overlaySettingsSmall: {
      type: Object,
      default: () => ({
        hOffset: null,
        vOffset: null,
        width: '',
      }),
    },
    overlaySettingsMedium: {
      type: Object,
      default: () => ({
        hOffset: null,
        vOffset: null,
        width: '',
      }),
    },
    overlaySettingsLarge: {
      type: Object,
      default: () => ({
        hOffset: null,
        vOffset: null,
        width: '',
      }),
    },
    firstButtonStyles: {
      type: Object,
      default: () => ({
        buttonStyleSmall: 'primary',
        buttonStyleLarge: 'primary',
        buttonSize: '',
        buttonIcon: '',
      }),
    },
    contentPosition: {
      type: Object as PropType<ContentAlignProperties>,
      default: () => ({
        small: 'middle_center',
        medium: 'middle_center',
        large: 'middle_center',
      }),
    },
    textAlign: {
      type: Object as PropType<ContentAlignProperties>,
      default: () => ({
        small: 'left',
        medium: 'center',
        large: 'center',
      }),
    },
    /** (automatic) Data extracted from specified target for analytics purposes */
    dataNavigation: {
      type: String,
      default: null,
    },
    openInNewTab: {
      type: Boolean,
      default: false,
    },
    openInNewModal: {
      type: Boolean,
      default: false,
    },
    /** CTA buttons */
    ctaButtons: {
      type: Array,
      default: () => [
        {
          buttonText: '',
          buttonStyle: '',
          buttonSize: '',
          buttonIcon: '',
          buttonIconPosition: '',
          buttonLink: '',
        },
      ],
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const config = root.$themeConfig?.carousel || false;
    const { setModalProps } = useModal();

    // Determines if is displayed as Article IF showBelowArticle is set
    const teaserTileClasses = computed(() => [
      'video-with-overlay__item',
      {
        'hide-on-small': !props.showBelowArticle.small,
        'hide-on-medium': !props.showBelowArticle.medium,
        'hide-on-large': !props.showBelowArticle.large,
      },
    ]);

    const videoTileClasses = computed(() => ({
      'hide-on-small': props.showBelowArticle.small,
      'hide-on-medium': props.showBelowArticle.medium,
      'hide-on-large': props.showBelowArticle.large,
    }));

    const tileProps = computed(() => ({
      video: props.category.video,
      title: props.category.title,
      text: props.category.text,
      subtitle: props.category.subtitle,
      useRichText: props.category.useRichText,
      textAlign: props.textAlign,
      link: props.category.link,
      buttonSize: props.firstButtonStyles.buttonSize,
      buttonIcon: props.firstButtonStyles.buttonIcon,
      buttonIconPosition: props.firstButtonStyles.buttonIconPosition,
      dataNavigation: props.dataNavigation,
      showOverlay: props.showOverlay,
      showButtonsBelowImage:
        props.showButtonsBelowArticle[root.$viewport?.size || 'small'],
      overlaySettingsSmall: props.overlaySettingsSmall,
      overlaySettingsMedium: props.overlaySettingsMedium,
      overlaySettingsLarge: props.overlaySettingsLarge,
      dataDomLocation: AnalyticsDataDomLocation.FeaturedImageWithOverlay,
      imageLink: props.category.imageLink,
    }));
    const { dispatchVideoEvent } = useGtm(root);

    const wrapperTag = computed(() => {
      return !props.category.imageLink &&
        props.showOverlay[root.$viewport?.size || 'small']
        ? 'div'
        : 'a';
    });

    const isCustomVideo = computed(
      () =>
        config.dispatchGtmEvents &&
        root.$route.path.includes(ROUTES.CUSTOMSTORE())
    );

    const addCustomStylesInVideoModal = () => {
      setModalProps({ class: 'custom-video-modal' });
    };

    return {
      teaserTileClasses,
      videoTileClasses,
      tileProps,
      dispatchVideoEvent,
      isCustomVideo,
      wrapperTag,
      addCustomStylesInVideoModal,
    };
  },
});
