var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"products-grid",class:{
    'products-grid--redesign': _vm.isVansPlpRedesignEnabled,
  }},[(_vm.isLoadPreviousButtonVisible)?_c('div',{staticClass:"load-previous__button"},[(!_vm.isLoading)?_c('VfButton',{class:_vm.viewPreviousButtonClasses,attrs:{"data-testid":"vf-load-previous-button","disabled":_vm.isLoading},on:{"click":_vm.loadPreviousPageTrigger}},[_vm._v("\n      "+_vm._s(_vm.translations.viewPrevious)+"\n    ")]):_vm._l((_vm.productsPerRow),function(i){return [_c('ProductCardPlaceholder',_vm._b({key:("product-previous-placeholder-" + i),attrs:{"ratio":_vm.imageSize.width / _vm.imageSize.height}},'ProductCardPlaceholder',_vm.columnSizes(),false))]})],2):_vm._e(),_vm._v(" "),_c('ProductGridRecycleList',{ref:"recycleListRef",attrs:{"items":_vm.productSlots,"config":_vm.currentConfig,"viewport":_vm.$viewport.size},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var product = ref.item;
  var key = ref.index;
  var visible = ref.visible;
  var rowId = ref.rowId;
return [(visible)?[_c('ProductsGridItem',_vm._g(_vm._b({key:((product.id) + "-" + key),attrs:{"id":key,"data-id":_vm.$attrs['data-id'],"product":product,"row-id":rowId,"image-size":_vm.imageSize,"column-sizes":_vm.columnSizes,"current-page":_vm.calculateCurrentPageForItem(product)}},'ProductsGridItem',Object.assign({}, _vm.$props, {products: undefined, espots: undefined}),false),_vm.$listeners))]:_vm._e()]}}])}),_vm._v(" "),(_vm.isLoading)?_c('VfRow',[_vm._l((_vm.productsPerRow),function(i){return [_c('ProductCardPlaceholder',_vm._b({key:("product-next-placeholder-" + i),attrs:{"ratio":_vm.imageSize.width / _vm.imageSize.height}},'ProductCardPlaceholder',_vm.columnSizes(),false))]})],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }