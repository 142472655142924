
import { defineComponent } from '@vue/composition-api';
import { treeRenderer } from '../../cms/cmsUtils';

export default defineComponent({
  name: 'ContentRenderer',
  props: {
    contextKey: {
      type: String,
      default: '',
    },
    components: {
      type: Array,
      default: () => [],
    },
  },
  render(createElement) {
    return createElement(
      'div',
      {},
      treeRenderer(
        createElement,
        {
          children: this.components,
        },
        this.contextKey,
        this.$root
      )
    );
  },
});
