











import { defineComponent, computed } from '@vue/composition-api';
import { useCategory } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'PlpRestrict',
  props: {
    components: {
      type: Array,
    },
    categoriesId: {
      type: Array,
    },
    contextKey: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { categoryId } = useCategory(root, props.contextKey);
    const componentsToRender = [];

    const isDisplayable = computed(
      () =>
        props.components.length && props.categoriesId.includes(categoryId.value)
    );
    for (const comp of props.components as any[]) {
      const dynComponent = () =>
        import(`@vf/theme/components/smart/${comp.component}`);
      componentsToRender.push({ ...comp, dynComponent });
    }

    return {
      componentsToRender,
      isDisplayable,
    };
  },
});
