


























import type { PropType } from 'vue';
import {
  computed,
  defineComponent,
  onMounted,
  watch,
} from '@vue/composition-api';
import { useProduct, useUrl } from '@vf/composables';
import type { ProductCtaButtonTranslations } from '@vf/api-contract';
import useRootInstance from '@/shared/useRootInstance';
import useModal from '@/shared/useModal';

const ProductAddToCart = () =>
  import(
    /* webpackChunkName: "ProductAddToCart" */
    /* webpackMode: "lazy" */
    /* webpackPrefetch: true */
    /* webpackPreload: true */
    './ProductAddToCart.vue'
  );

export default defineComponent({
  name: 'CtaButtonProduct',
  components: { ProductAddToCart },
  props: {
    modals: {
      type: Object,
      default: () => ({
        signInToBuy: null,
        loyaltyEnrollment: null,
      }),
    },
    /** Type of the button to render proper component */
    type: {
      type: String,
      default: 'add-to-cart',
    },
    /** Button text */
    text: {
      type: String,
      default: 'Add to Cart',
    },
    /** Prop to decide on Notify Me button visibility */
    enableNotifyMeForm: Boolean,
    buttonStyles: {
      type: Object /** Set of ThemeButton props */,
      default: () => ({}),
    },
    isButtonText: Boolean,
    isFullWidth: Boolean,
    translations: {
      type: Object as PropType<ProductCtaButtonTranslations>,
      default: () => ({}),
    },
    /** Select with page type */
    contextKey: {
      type: String,
      default: 'product',
    },
  },
  setup(props) {
    const { root } = useRootInstance();

    const {
      product,
      setInitialColor,
      swapProduct,
      isQuickShopContext,
      isSelectedProductOutOfStock,
      isAttributeOutOfStockStatus,
    } = useProduct(root, props.contextKey);

    const { closeModal } = useModal();

    const { getProductUrl } = useUrl(root);

    const productDetailsLink = computed(() => {
      if (props.type !== 'details' || !product.value) return '';
      let pageUrl;
      const recipe =
        product.value.recipe ||
        product.value.recipeId ||
        product.value.customsRecipeID;

      if (!recipe && product.value.pageUrl) {
        pageUrl = product.value.pageUrl;
        if (root.$themeConfig.CtaButtonProduct?.useColorInQuery) {
          pageUrl += pageUrl.includes('?') ? '&' : '?';
          pageUrl += `color=${product.value.color?.value || ''}`;
        }
      } else {
        pageUrl = getProductUrl(product.value, {
          recipe: recipe,
          color: root.$themeConfig.CtaButtonProduct?.useColorInQuery
            ? product.value.color?.value
            : undefined,
        });
      }
      return pageUrl;
    });

    if (props.type === 'details') {
      watch(product, () => {
        if (!product.value?.color) {
          setInitialColor();
        }
      });

      onMounted(() => {
        if (!product.value?.color) {
          setInitialColor();
        }
      });
    }

    const productSize = computed(() =>
      product.value !== null ? product.value.size?.value : null
    );

    const productColor = computed(() =>
      product.value !== null ? product.value.color?.value : null
    );

    const isButtonDeactivated = computed(() => {
      return (
        isSelectedProductOutOfStock.value ||
        !productSize.value ||
        !productColor.value ||
        isAttributeOutOfStockStatus('size', product.value?.size?.value) ||
        !product.value?.size?.available
      );
    });

    const goToDetails = () => {
      root.$router.push(productDetailsLink.value);
    };

    const buttonProps = computed(() => ({
      ...props.buttonStyles,
      ...(props.type === 'update'
        ? {
            disabled: isButtonDeactivated.value,
          }
        : {}),
    }));

    const buttonEvents = computed(() => ({
      click: {
        update: swapProduct,
        details: goToDetails,
        cancel: closeModal,
      }[props.type],
    }));

    return {
      product,
      isButtonDeactivated,
      isQuickShopContext,
      productDetailsLink,
      buttonProps,
      buttonEvents,
    };
  },
});
