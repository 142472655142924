







import { PropType } from 'vue';
import { ClearSession } from '@vf/api-contract';
import { useCart, useSignInToStore } from '@vf/composables';
import { defineComponent, computed } from '@vue/composition-api';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'CheckoutHeaderDynamic',
  props: {
    logo: {
      type: [String, Object],
      default: '',
    },
    logoHeight: {
      type: Number,
      default: null,
    },
    logoWidth: {
      type: Number,
      default: null,
    },
    title: {
      type: String,
      default: '',
    },
    linkTag: {
      type: String,
      default: 'a',
    },
    triggerStickyOnElementId: {
      type: String,
      default: '',
    },
    triggerStickyOnDefinedNumber: {
      type: Number,
      default: null,
    },
    currentStepProp: {
      type: String,
      default: '',
    },
    translations: {
      type: Object,
      default: () => ({
        shipStepLabel: '',
        pickupStepLabel: '',
        shipAndPickupStepLabel: '',
        payAndReviewLabel: '',
      }),
    },
    shippingPage: {
      type: String,
      default: '',
    },
    billingPage: {
      type: String,
      default: '',
    },
    clearSession: Object as PropType<ClearSession>,
  },
  setup(props) {
    // TODO: Remove it after GLOBAL15-56318
    const { root } = useRootInstance();
    const { hasPickupItems, hasShippingItems } = useCart(root);
    const { clearStoreSession } = useSignInToStore(root);

    const steps = computed(() => {
      const step = { id: 1, link: props.shippingPage, label: '' };

      if (hasPickupItems.value) {
        if (hasShippingItems.value) {
          step.label = props.translations.shipAndPickupStepLabel;
        } else {
          step.label = props.translations.pickupStepLabel;
        }
      } else {
        step.label = props.translations.shipStepLabel;
      }

      return [
        step,
        {
          id: 2,
          link: props.billingPage,
          label: props.translations.payAndReviewLabel,
        },
      ];
    });

    return { steps, clearStoreSession };
  },
});
