var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vf-loyalty-rewards-progress-bar"},[_c('VfText',{staticClass:"vf-loyalty-rewards-progress-bar__summary",attrs:{"content":_vm.summary}}),_vm._v(" "),_c('div',{class:{
      'vf-loyalty-rewards-progress-bar__wrapper': true,
      'vf-loyalty-rewards-progress-bar__wrapper--max': _vm.full,
    }},_vm._l((_vm.vouchers),function(voucher,i){return _c('div',{key:i,staticClass:"vf-loyalty-rewards-progress-bar__reward",style:({
        '--index': i + 1,
        '--partition': 100 / _vm.vouchers.length + '%',
      })},[_c('div',{staticClass:"vf-loyalty-rewards-progress-bar__bar",style:({ '--progress': 100 * voucher.progress + '%' })}),_vm._v(" "),_c('div',{staticClass:"vf-loyalty-rewards-progress-bar__point-details"},[_c('div',{class:{
            'vf-loyalty-rewards-progress-bar__point': true,
            'vf-loyalty-rewards-progress-bar__point--active':
              voucher.available,
          }}),_vm._v(" "),_c('p',{staticClass:"vf-loyalty-rewards-progress-bar__dollar-amount",domProps:{"textContent":_vm._s(voucher.amountInCurrency)}}),_vm._v(" "),_c('p',{staticClass:"vf-loyalty-rewards-progress-bar__point-amount",domProps:{"textContent":_vm._s(voucher.points.toLocaleString('en-US'))}})])])}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }