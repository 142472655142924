





































































































import type { PropType } from 'vue';
import {
  computed,
  defineComponent,
  onMounted,
  onBeforeUnmount,
} from '@vue/composition-api';
import {
  ApplePayContext,
  ROUTES,
  useApplePay,
  useCart,
  useCheckout,
  useI18n,
  useReCaptcha,
  useSignInToStore,
} from '@vf/composables';
import { PaymentMethodCode } from '@vf/api-client';
import {
  CheckoutContext,
  ApplePayTranslations,
  CheckoutSidebarTranslations,
} from '@vf/api-contract';
import PayPal from '../../payment/PayPal.vue';
import ApplePay from '../../payment/ApplePay.vue';
import useRootInstance from '@/shared/useRootInstance';
import AdditionalPaymentButtons from '../minicart/AdditionalPaymentButtons.vue';
import useLoader from '@/shared/useLoader';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@vf/composables/src/store/user';

export default defineComponent({
  name: 'CheckoutSidebarTop',
  components: {
    AdditionalPaymentButtons,
    PayPal,
    ApplePay,
  },
  props: {
    translations: {
      type: Object as PropType<CheckoutSidebarTranslations>,
      required: true,
    },
    applePayTranslations: {
      type: Object as PropType<ApplePayTranslations>,
      required: true,
    },
    /** Context to determine where Sidebar is rendered (cart|shipping|payment) */
    context: {
      type: String as PropType<CheckoutContext>,
      default: CheckoutContext.Cart,
    },
    /** Show/Hide Before Tax message */
    showBeforeTaxMessage: {
      type: Boolean,
      default: true,
    },
    showAgeConfirmation: {
      type: Boolean,
      default: true,
    },
    currencySymbol: {
      type: String,
      default: '',
    },
    showApplePayComponent: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { listeners, emit }) {
    const { root } = useRootInstance();
    const { showSpinner, hideSpinner } = useLoader();
    const userStore = useUserStore(root);
    const { loggedIn } = storeToRefs(userStore);
    const theme = root.$themeConfig.checkoutSidebar;
    const recaptcha = useReCaptcha(root);

    const {
      cart,
      isGoToBillingButtonDisabled,
      hasItems,
      hasShippingItems,
    } = useCart(root);
    const {
      paymentMethod,
      isPlaceOrderButtonDisabled,
      showPlaceOrderButtonAtPayment,
    } = useCheckout(root);
    const { localePath } = useI18n(root);
    const { employeeConnected } = useSignInToStore(root);
    const applePayContext =
      props.context === 'cart'
        ? ApplePayContext.CART
        : ApplePayContext.CHECKOUT;
    const { isApplePayAvailable, initApplePaySession } = useApplePay(
      root,
      applePayContext
    );

    const getTotalToFormat = () => {
      return typeof cart.value.totals.remainingToPay !== 'undefined'
        ? cart.value.totals.remainingToPay
        : cart.value.totals.total;
    };

    const orderTotal = computed(() => {
      const rawTotal = getTotalToFormat();
      return root.$formatPrice(rawTotal, cart.value.currency);
    });

    const taxTotal = computed(() => cart.value.totals.tax);
    const isCartButtonsDisabled = computed(() => !cart.value.totalItems);

    const hidePaypalForEmployee = computed(() => {
      return [theme.hidePaypalForEmployee, employeeConnected.value].every(
        Boolean
      );
    });

    const showPaypalButtonAtPayment = computed(
      () =>
        props.context === 'payment' &&
        paymentMethod.value === PaymentMethodCode.PAYPAL &&
        !hidePaypalForEmployee.value
    );

    const showApplePayButtonAtPayment = computed(
      () =>
        props.context === 'payment' &&
        paymentMethod.value === PaymentMethodCode.APPLEPAY &&
        isApplePayAvailable() &&
        props.showApplePayComponent
    );

    const goToShipping = () => {
      // Workaround for GLOBAL15-5927, existence of RequireJS loaded
      // in imported content makes CC fields fail to render
      if (['require', 'define'].some((method) => method in window)) {
        window.location.assign(localePath(ROUTES.CHECKOUT_SHIPPING()));
      } else {
        root.$router.push(localePath(ROUTES.CHECKOUT_SHIPPING()));
      }
    };

    const proceedToCheckout = () => {
      const eventType = 'proceed-to-checkout';
      if (eventType in listeners) {
        emit(eventType);
        return;
      }
      goToShipping();
    };

    const goToPayment = () => {
      emit('go-to-payment');
    };

    const onGoToPaymentClick = () => {
      if (loggedIn.value && hasShippingItems.value) {
        // verify selected, saved address before proceeding
        root.$eventBus.$emit('go-to-payment-click');
      } else {
        goToPayment();
      }
    };

    const placeOrder = () => emit('place-order');
    const showApplePayPaymentSheet = () => {
      showSpinner();
      initApplePaySession(props.applePayTranslations, hideSpinner, true);
    };

    onMounted(() => {
      root.$eventBus.$on('go-to-payment', goToPayment);
      if (props.context === 'payment' && showPlaceOrderButtonAtPayment) {
        recaptcha.showBadge();
      }
    });

    onBeforeUnmount(() => {
      root.$eventBus.$off('go-to-payment');
      if (props.context === 'payment') {
        recaptcha.hideBadge();
      }
    });

    return {
      theme,
      orderTotal,
      proceedToCheckout,
      goToPayment,
      placeOrder,
      paymentMethod,
      isPlaceOrderButtonDisabled,
      taxTotal,
      isCartButtonsDisabled,
      cart,
      hidePaypalForEmployee,
      isApplePayAvailable,
      showApplePayPaymentSheet,
      isGoToBillingButtonDisabled,
      hasItems,
      showPlaceOrderButtonAtPayment,
      showPaypalButtonAtPayment,
      showApplePayButtonAtPayment,
      ApplePayContext,
      onGoToPaymentClick,
    };
  },
});
