





















import { defineComponent } from '@nuxtjs/composition-api';
import type { FaqItem } from '@vf/api-client';
import { PropType } from '@vue/composition-api';
import { FAQPage, WithContext } from 'schema-dts';
import { useJsonLinkedData } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'SeoFaq',
  props: {
    questions: {
      type: Array as PropType<Array<FaqItem>>,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    titleStyles: {
      type: Object,
      default: () => ({
        headingLevel: '',
        headingModifier: '',
        titleFontFamily: '',
        titleFontSize: '',
        titleFontWeight: '',
        titleColor: '',
        textBackgroundColor: '',
        titleClassModifiers: '',
      }),
    },
  },
  setup() {
    const { root } = useRootInstance();
    const { transformFaqDataToJsonld } = useJsonLinkedData(root);
    return {
      transformFaqDataToJsonld,
    };
  },
  jsonld(): WithContext<FAQPage> {
    return this.transformFaqDataToJsonld(this.questions);
  },
});
