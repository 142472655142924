






















































































































































































import type { PropType } from 'vue';
import {
  computed,
  defineComponent,
  ref,
  onMounted,
} from '@vue/composition-api';
import type { OrderHistoryTranslations } from '@vf/api-contract';
import type { OrderItemAttribute } from '@vf/api-contract/src/entities';
import {
  ROUTES,
  useI18n,
  useOrders,
  useNotification,
  useReturns,
  useUrl,
} from '@vf/composables';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import { getWriteReviewUrl } from '@vf/composables/src/useReviews/utils';
import { getUniqueStatuses } from '@/helpers';
import useRootInstance from '@/shared/useRootInstance';

const getProductsAttributes = (orderLineProducts, attributesOrder) => () =>
  orderLineProducts.value.map((product) => {
    const productAttributes: OrderItemAttribute[] = product.attributes ?? [];
    // Map attributes code to attribute and keep attributes order from theme
    const visibleAttributes = attributesOrder.map((code) =>
      productAttributes.find((attr) => attr.code === code)
    );
    return visibleAttributes.filter((attr) => !!attr);
  });

export default defineComponent({
  name: 'OrderCard',
  props: {
    translations: {
      type: Object as PropType<OrderHistoryTranslations>,
      default: () => ({}),
    },
    order: {
      // TODO: Fix OrderHistoryObject api-contract interface, @vf/api-contract/src/entities/order.ts:281
      type: Object as PropType<{
        order_id: string;
        status: string;
        orderHeaderKey: string;
        items: any[];
        order_lines: any[];
        total_units: number;
      }>,
      default: () => ({}),
    },
    // Determine whether user is on returns history page
    isReturns: Boolean,
  },
  setup(props) {
    const { root } = useRootInstance();
    const { localePath } = useI18n(root);
    const { getProductUrl } = useUrl(root);
    const { loadOrder, getOrderStatusLabel } = useOrders(root);
    const { addNotification, clearNotifications } = useNotification(root);
    const {
      loading,
      getReturnDetails,
      getReturnsConfig,
      printPackingSlip,
      printReturnLabel,
      resetReturn,
    } = useReturns(root);

    const theme = {
      ...root.$themeConfig.orderCard,
      // only override theme config for returns
      ...(props.isReturns && root.$themeConfig.returnOrderCard),
    };

    const isColorVariantId =
      root.$themeConfig?.reviews?.isColorVariantId ?? false;

    const {
      areOnlineReturnsEnabled,
      areNarvarReturnsEnabled,
    } = useFeatureFlagsStore();

    const showPrintReturnLabel = ref(false);
    const showPrintPackingSlip = ref(false);

    const orderStatus = computed(() =>
      getOrderStatusLabel(
        props.order.status,
        getUniqueStatuses(props.order.items)
      )
    );

    const totalUnits = computed(() =>
      props.isReturns ? getTotalUnitsSum() : props.order.total_units
    );

    const screenSize = root.$viewport.size;
    const { width: imageWidth, height: imageHeight } = computed(
      () =>
        theme?.imageSizes?.[screenSize] ?? {
          width: 500,
          height: 500,
        }
    ).value;

    const getTotalUnitsSum = () =>
      props.order.order_lines.reduce(
        (total, currentItem) => total + currentItem.orderedQty,
        0
      );

    const requestOrderDetailsById = async (order) => {
      clearNotifications();
      try {
        props.isReturns
          ? await getReturnDetails(order.orderHeaderKey)
          : await loadOrder(order.order_id);
        root.$router.push({
          path: localePath(
            props.isReturns
              ? ROUTES.RETURN_DETAILS(order.orderHeaderKey)
              : ROUTES.ORDER_DETAILS(order.order_id)
          ),
        });
      } catch (err) {
        addNotification({
          message: props.translations.noOrders,
          type: 'danger',
        });
      }
    };

    const getProductImage = (product) => {
      if (product.imageDeclined) {
        return root.$mediaUrlFallback();
      }
      return product.masterId === product.colorCode &&
        product.productImageURL?.length
        ? product.productImageURL
        : product.image;
    };

    const getReviewLink = (productId: string, variantId: string) => {
      return productId
        ? localePath(
            getWriteReviewUrl(
              {
                // TODO: GLOBAL15-8194 check if productId is correct and pass merchantId after refactor
                productId,
                variantId: isColorVariantId ? variantId : productId,
              },
              root.$options
            )
          )
        : '';
    };

    const orderLineProducts = computed(() =>
      props.order.order_lines.map((product) => {
        return {
          ...product,
          pdpImage: props.isReturns
            ? product.item.img
            : getProductImage(product),
          pdpReview: props.isReturns
            ? getReviewLink(product.item.masterId, product.item.styleCode)
            : getReviewLink(product.masterId, product.style_code),
          pdpUrl: getProductUrl(props.isReturns ? product.item : product),
        };
      })
    );

    const productsAttributes = computed(
      getProductsAttributes(orderLineProducts, theme.attributesOrder)
    );

    const handleReturnOrder = async (orderId) => {
      clearNotifications();
      try {
        loading.value = true;
        resetReturn();
        await loadOrder(orderId);
        root.$router.push({
          path: localePath(ROUTES.RETURN_ORDER(orderId)),
        });
      } catch (err) {
        addNotification({
          message: props.translations.noOrders,
          type: 'danger',
        });
      } finally {
        loading.value = false;
      }
    };

    const isCreateReturnLinkVisible = computed(() => {
      // Note: Since we introduce Narvar Returns, we no longer want to display create return from Order History Page
      if (!areOnlineReturnsEnabled || areNarvarReturnsEnabled) return false;
      const canBeReturned = (item) => {
        return item.isReturnable && item.quantity_returnable > 0;
      };
      return props.order.order_lines.some(canBeReturned);
    });

    onMounted(() => {
      if (areNarvarReturnsEnabled) return;
      const { printReturnLabel, printPackingSlip } = getReturnsConfig();
      showPrintReturnLabel.value = printReturnLabel;
      showPrintPackingSlip.value = printPackingSlip;
    });

    return {
      theme,
      productCardSize: theme?.productCardSize ?? {
        small: 6,
        medium: 6,
        large: 4,
      },
      requestOrderDetailsById,
      orderStatus,
      orderLineProducts,
      productsAttributes,
      totalUnits,
      imageWidth,
      imageHeight,
      isCreateReturnLinkVisible,
      handleReturnOrder,
      printPackingSlip,
      printReturnLabel,
      showPrintReturnLabel,
      showPrintPackingSlip,
    };
  },
});
