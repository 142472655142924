




















































































import type { PropType } from 'vue';
import type { CheckoutFamilyRewardsTranslations } from '@vf/api-contract';
import { FlashErrorType } from '@vf/api-contract';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import {
  computed,
  defineComponent,
  ref,
  onMounted,
  watch,
  nextTick,
} from '@vue/composition-api';
import { useCart, useLoyalty, useNotification } from '@vf/composables';
import { replaceTranslationVariables } from '@vf/shared/src/utils/helpers';
import useRootInstance from '@/shared/useRootInstance';
import { useUserStore } from '@vf/composables/src/store/user';
import { useDiscountStore } from '@vf/composables/src/store/discountStore';

export default defineComponent({
  name: 'CheckoutFamilyRewards',
  components: {},
  props: {
    translations: {
      type: Object as PropType<CheckoutFamilyRewardsTranslations>,
      required: true,
    },
  },
  setup(props) {
    // TODO: Refactor VfText usage in the template - replace duplicated components by just one component with computed content.

    const { root } = useRootInstance();
    const { isPointsToCurrencyEnabled } = useFeatureFlagsStore();
    const userStore = useUserStore(root);
    const { getLoyaltyVouchers, rewards } = useLoyalty(root);
    const { setFamilyReward, cart } = useCart(root);
    const { addNotification } = useNotification(root);
    const discountStore = useDiscountStore();

    const isVisible = computed(
      () => isPointsToCurrencyEnabled && userStore.loyaltyEnrolled
    );
    const showRewardDetails = ref(false);
    const selectedRewardOption = ref<'selectAmount' | number>('selectAmount');

    const hasRewards = computed(() =>
      rewards.value?.applicableLoyaltyVouchers.some(
        (voucher) => voucher.available
      )
    );

    const isAccordionOpened = ref(hasRewards.value);

    const rewardsList = computed(() => {
      return (
        rewards.value?.applicableLoyaltyVouchers.map((voucher, index) => ({
          id: voucher.amountInCurrency + `-${index}`,
          points: voucher.points,
          label: `${root.$formatPrice(
            voucher.amountInCurrency,
            null,
            true
          )} off (${voucher.points} points)`,
          disabled: !(voucher.available && voucher.eligible),
        })) || []
      );
    });

    const isRewardSelected = computed(() => {
      // selected option is amountInCurrency
      return !isNaN(Number(selectedRewardOption.value));
    });

    const isMaximumRewardSelected = computed(() => {
      const maxAmountInPoints = rewards.value?.applicableLoyaltyVouchers.reduce(
        (points, voucher) => {
          if (voucher.available && voucher.eligible) {
            return points > voucher.points ? points : voucher.points;
          }
          return points;
        },
        0
      );

      const selectedRewardAmount = !isNaN(Number(selectedRewardOption.value))
        ? Number(selectedRewardOption.value)
        : 0;

      return selectedRewardAmount === maxAmountInPoints;
    });

    const areLoyaltyVouchersExist = computed(
      () => rewards.value?.applicableLoyaltyVouchers.length > 0
    );

    watch(
      cart,
      (updatedCart: any) => {
        const hasPointsError = updatedCart.flash?.find(
          (error) => error.code === FlashErrorType.LoyaltyPointsNotApplicable
        );
        if (hasPointsError) {
          addNotification({
            message: props.translations.orderUpdated,
            type: 'info',
          });
        }
      },
      { immediate: true }
    );

    watch(
      () => discountStore.appliedFamilyVoucher,
      (newVoucher) => {
        const newPointsApplied = Number(newVoucher?.loyaltyPoints || 0);
        if (newPointsApplied === Number(selectedRewardOption.value)) {
          return;
        }

        selectedRewardOption.value =
          Number(newVoucher?.loyaltyPoints) || 'selectAmount';
      },
      { immediate: true }
    );

    const amountOfPointsToReachMinReward = computed(() => {
      if (!areLoyaltyVouchersExist.value) {
        return;
      }

      const minReward = rewards.value.applicableLoyaltyVouchers.reduce(
        (min, reward) => {
          return reward.points < min.points ? reward : min;
        }
      );

      return minReward.points - rewards.value.currentLoyaltyPoints;
    });

    const minAndMaxAvailableRewards = computed(() => {
      if (!areLoyaltyVouchersExist.value) {
        return { min: 0, max: 0 };
      }

      return rewards.value.applicableLoyaltyVouchers.reduce(
        (minMax, reward) => {
          if (reward.available && reward.eligible) {
            if (+reward.amountInCurrency < minMax.min)
              minMax.min = +reward.amountInCurrency;
            if (+reward.amountInCurrency > minMax.max)
              minMax.max = +reward.amountInCurrency;
          }
          return minMax;
        },
        {
          min: +rewards.value.applicableLoyaltyVouchers[0].amountInCurrency,
          max: 0,
        }
      );
    });

    const translationsWithVariables = computed(() => {
      return replaceTranslationVariables(
        props.translations,
        ['availableRewards', 'doNotHaveAnyAvailableRewards'],
        {
          amount_of_points: amountOfPointsToReachMinReward.value,
          minimal_value_reward: root.$formatPrice(
            minAndMaxAvailableRewards.value.min,
            null,
            true
          ),
          maximum_available_reward: root.$formatPrice(
            minAndMaxAvailableRewards.value.max,
            null,
            true
          ),
        }
      );
    });

    onMounted(async () => {
      if (isVisible.value) {
        await getLoyaltyVouchers();
        nextTick(() => (isAccordionOpened.value = hasRewards.value));
      }
    });

    return {
      isVisible,
      isAccordionOpened,
      hasRewards,
      showRewardDetails,
      rewardsList,
      selectedRewardOption,
      isRewardSelected,
      isMaximumRewardSelected,
      amountOfPointsToReachMinReward,
      minAndMaxAvailableRewards,
      translationsWithVariables,
      setFamilyReward,
      rewards,
    };
  },
});
