import {
  ComponentInstance,
  typesUtilities as types,
  useUtilities,
} from '@vf/composables';
import merge from '@vf/composables/src/utils/merge';
import {
  validateEmail,
  validateMaxLength,
  validateMinLength,
  validateRegex,
  validateRequired,
} from '@vf/composables/src/useValidators/validators';
import type {
  AddressFormTranslations,
  Attrs,
  CountryCode,
  Formats,
  FormConfig,
  Hints,
  Masks,
  Options,
  Transformers,
  Types,
  Validations,
} from './types';
import { replaceAll } from '@/helpers/replaceAll';

export const formatWithPattern = (input: string, pattern: string): string => {
  const data = input.replace(/[^A-Za-z0-9]/g, '');
  let count = 0;
  return pattern.replace(/X/g, () => {
    return data.charAt(count++);
  });
};

export const knownTransformers = {
  capitalize: (input: string): string => input.toUpperCase(),
  canadaPostalCode: (input: string): string =>
    input.length === 0 ? '' : formatWithPattern(input, 'XXX XXX'),
};

// formConfigDefaults mimics the format returned by the API
const formConfigDefaults = () => ({
  addressLine1: { validation: { required: true, maxLength: 30 } },
  addressLine2: { validation: { required: false, maxLength: 30 } },
  city: { validation: { required: true, maxLength: 30 } },
  country: { validation: { required: true } },
  email: { validation: { required: true, email: true } },
  firstName: { validation: { required: true, minLength: 2, maxLength: 30 } },
  lastName: { validation: { required: true, minLength: 2, maxLength: 30 } },
  phone: { validation: { required: true } },
  postalCode: { validation: { required: true } },
  province: { validation: { required: false } },
});

export const buildTransformers = (i18n: types.Form): Transformers => {
  const fields = i18n.fields;
  return fields.reduce((acc, field: Record<string, any>) => {
    const fieldTransformers = (field.transforms || [])
      .filter((transformer) => !!knownTransformers[transformer])
      .map((transformer) => knownTransformers[transformer]);
    if (!fieldTransformers.length) return acc;

    // Generates a function that later applies all the transformers to a given input
    acc[field.fieldId] = (input: string) =>
      fieldTransformers.reduce((t, transformer) => {
        return transformer(t);
      }, input);
    return acc;
  }, {});
};

const buildGeneric = <T>(i18n, property, fallback: string | any[] = []): T => {
  return i18n.fields.reduce((acc, field) => {
    acc[field.fieldId] = field[property] || fallback;
    return acc;
  }, {});
};

const formatMaskHints = (
  formats: Formats,
  translations: Record<string, any>
): Hints => {
  // This regex matches with values accepted by vue-the-mask to handle the mask
  // Values:
  // '#': {pattern: /\d/},
  // 'X': {pattern: /[0-9a-zA-Z]/},
  // 'S': {pattern: /[a-zA-Z]/},
  // 'A': {pattern: /[a-zA-Z]/, transform: v => v.toLocaleUpperCase()},
  // 'a': {pattern: /[a-zA-Z]/, transform: v => v.toLocaleLowerCase()},
  // '!': {escape: true}
  // We do this replacement to display an user friendly hint
  const searchRegExp = new RegExp('[aASx#!]', 'g');
  const replaceWith = 'X';

  return Object.fromEntries(
    Object.entries(formats).map(([field, hint]) => {
      return [
        field,
        hint
          .map((h) => h.replace(searchRegExp, replaceWith))
          .join(translations.hintJoin),
      ];
    })
  );
};

const getMessage = (
  translations: Record<string, string | Record<string, string>>,
  fieldName: string,
  ruleName: string,
  replacements: Record<string, string> | undefined = undefined
): string | undefined => {
  if (!translations) return undefined;
  const message =
    translations[fieldName]?.[ruleName] ||
    translations[ruleName] ||
    translations[fieldName];
  return message && replacements ? replaceAll(message, replacements) : message;
};

const buildValidations = (
  fields,
  hints: Hints,
  translations: Record<string, any>
): [Validations, Attrs] => {
  const messages = translations.validation;
  const attrs: Attrs = {};
  // take the default validation rules and merge in any custom rules
  const validationDefaults = formConfigDefaults();
  const validators = Object.entries(validationDefaults).reduce(
    (acc, [fieldName, defaults]) => {
      const fieldRules = {
        ...defaults.validation,
        ...fields[fieldName]?.validation,
      };
      const { required, pattern, minLength, maxLength, email } =
        fieldRules || {};

      acc[fieldName] = [];
      let message: string | undefined;
      if (required) {
        message = getMessage(messages, fieldName, 'required', {
          fieldName: translations[fieldName].toLowerCase(),
        });
        acc[fieldName].push(validateRequired(message));
        attrs[fieldName] = { required: true };
      }
      if (minLength) {
        message = getMessage(messages, fieldName, 'minLength', {
          min: minLength,
        });
        acc[fieldName].push(validateMinLength(minLength, message));
      }
      if (maxLength) {
        message = getMessage(messages, fieldName, 'maxLength', {
          max: maxLength,
        });
        acc[fieldName].push(validateMaxLength(maxLength, message));
      }
      if (email) {
        message = getMessage(messages, fieldName, 'email');
        acc[fieldName].push(validateEmail(message));
      }
      if (pattern) {
        message = getMessage(messages, fieldName, 'pattern');
        let regex = new RegExp(pattern);
        const flags = pattern.match(/.*\/([gimyu]*)$/)?.[1];
        if (flags)
          regex = new RegExp(
            pattern.replace(new RegExp(`^/(.*?)/${flags}$`), '$1'),
            flags
          );
        acc[fieldName].push(
          validateRegex(regex, `${message} ${hints[fieldName]}`)
        );
      }
      return acc;
    },
    {}
  );
  return [validators, attrs];
};

/* Comment from previous developer:
 * To ensure proper field values for countries that don't have them
 * specified yet in countryList endpoint we first merge potential country
 * specific fields onto fallback fields. Then we can safely merge the
 * result onto fields provided in i18n endpoint. This way we have proper
 * fields for all countries.
 * */

export default async (
  root: ComponentInstance,
  country: CountryCode,
  locale: string,
  formName: string,
  translations: AddressFormTranslations
): Promise<FormConfig> => {
  const { getI18nFallback, getI18n } = useUtilities(root);

  try {
    // this 2 calls should be conciliated in the BE instead of having the logic on the FE, if really needed
    const i18n: types.Form = await getI18n(
      country.toLowerCase(),
      locale,
      formName
    );
    const i18nFallback = await getI18nFallback(formName);
    const fieldsToOverride = i18nFallback.fields?.filter((field) =>
      ['phone', 'postalCode', 'province'].includes(field.fieldId)
    );
    fieldsToOverride.forEach((overrideField) => {
      const index = i18n.fields.findIndex(
        (field) => field.fieldId === overrideField.fieldId
      );
      if (index > -1) {
        const mergedI18n = {};
        merge(mergedI18n, overrideField, i18n.fields[index]);
        merge(i18n.fields[index], mergedI18n);
      }
    });

    // parses the i18n data into a format that can be used by the form
    // replaces the previous viewBuilder function
    const i18nFields = i18n.fields.reduce((acc, field) => {
      acc[field.fieldId] = field;
      return acc;
    }, {});

    const formats = buildGeneric<Formats>(i18n, 'format');
    const hints = formatMaskHints(formats, translations);
    const options = buildGeneric<Options>(i18n, 'list');
    const types = buildGeneric<Types>(i18n, 'type', 'text');
    const masks = Object.fromEntries(
      Object.entries(buildGeneric<Masks>(i18n, 'mask', '')).map(
        ([fieldName, mask]) => {
          return [
            fieldName,
            // ODD: mask for phone comes under the 'mask' key, but zipcode ones come under 'format'
            mask || translations.masks[fieldName] || formats[fieldName] || '',
          ];
        }
      )
    );
    const transformers = buildTransformers(i18n);
    const [validation, attrs] = buildValidations(
      i18nFields,
      hints,
      translations
    );

    return {
      formId: formName,
      formats,
      hints,
      options,
      masks,
      transformers,
      types,
      validation,
      attrs,
    };
  } catch (err) {
    root.$log.error(
      '[@helpers/addressBook/getAddressFormConfig::default]',
      err.message
    );
    return { formId: formName };
  }
};
