











import { defineComponent } from '@vue/composition-api';
import { useAuthentication, useI18n } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'SignOutCTA',
  props: {
    redirectTo: {
      type: String,
      default: null,
    },
    translations: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { signOut } = useAuthentication(root);
    const { localeCode } = useI18n(root);

    const getLocaleUrl = (urlSegment: string, locale: string) => {
      const localeIndex = urlSegment.indexOf(locale);

      if (localeIndex > 0) {
        const segment = urlSegment.slice(localeIndex);
        return `/${segment}`;
      }

      return `/${locale}${urlSegment}`;
    };

    const handleSignOut = async () => {
      const redirectPath =
        props.redirectTo && getLocaleUrl(props.redirectTo, localeCode());
      await signOut(redirectPath);
    };
    return {
      handleSignOut,
    };
  },
});
