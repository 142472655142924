











import {
  defineComponent,
  ref,
  PropType,
  computed,
  onMounted,
} from '@vue/composition-api';
import { useAccount } from '@vf/composables';
import { CreditCardsSummaryProfileTranslations } from '@vf/api-contract';
import useRootInstance from '@/shared/useRootInstance';
import { getCardLogo } from '@/helpers';

import {
  formatExpirationYear,
  formatExpirationMonth,
} from '@vf/shared/src/utils/helpers';

export default defineComponent({
  name: 'CreditCardsSummarySection',
  props: {
    contextKey: {
      type: String,
      default: 'page-content',
    },
    translations: {
      type: Object as PropType<CreditCardsSummaryProfileTranslations>,
      required: true,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { paymentInstruments, getConsumerCreditCards } = useAccount(root);

    onMounted(() => {
      getConsumerCreditCards();
    });

    const creditCard = computed(
      () =>
        paymentInstruments.value.cards
          .map((item) => {
            return {
              id: item.card.paymentInstrumentId,
              name: [
                props.translations.cardNumberReplacementText,
                ' ',
                item.card.maskedNumber.substr(-4),
                ', ',
                props.translations.cardExpReplacementText,
                ` ${formatExpirationMonth(
                  item.card.expirationMonth
                )}/${formatExpirationYear(item.card.expirationYear)}`,
              ].join(''),
              icon: getCardLogo(item.card.cardType),
              address:
                (item.address &&
                  `${[
                    item.address?.addressLine1,
                    item.address?.addressLine2,
                    ', ',
                    item.address?.city,
                    item.address?.province,
                    item.address?.postalCode,
                  ].join(' ')}`) ||
                '',
              phone: item.address?.phone,
              active: item.card.main,
            };
          })
          .filter((item) => item.active)[0]
    );

    const loading = ref(false);

    return {
      loading,
      creditCard,
      paymentInstruments,
    };
  },
});
