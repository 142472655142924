




























import type { PropType } from 'vue';
import { defineComponent, ref } from '@vue/composition-api';
import type { SignInFormTranslations } from '@vf/api-contract';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'SignInModal',
  props: {
    translations: {
      type: Object as PropType<SignInFormTranslations>,
      required: true,
    },
    validations: {
      type: Object,
      default: () => ({}),
    },
    interestsLink: { type: String, default: '' },
    checkoutModalLogin: Boolean,
    loginRedirectToCart: Boolean,
    /** Flag determining whether to show create account link or not */
    showCreateAccountLink: Boolean,
    /** Flag determining whether to show icon button that password in the input or not */
    showPasswordToggler: Boolean,
    /** Show sms subscription checkbox below phone number **/
    showSmsSubscription: Boolean,
    /** Flag determining whether to show recaptch after 2 sign in attempts or not */
    showCaptcha: Boolean,
    contextKey: { type: String, required: true },
  },
  setup() {
    const modalContent = ref('login');
    const { root } = useRootInstance();
    const changeModalContentType = (event) => {
      modalContent.value = event;
    };

    return {
      minRegisterAge: root.$config.MINIMUM_REGISTER_AGE,
      modalContent,
      changeModalContentType,
    };
  },
});
