import { PropType } from '@nuxtjs/composition-api';
import { CategoryProductsTranslations } from '@vf/api-contract';

export const CmsProps = {
  translations: {
    type: Object as PropType<CategoryProductsTranslations>,
    default: (): Record<string, unknown> => ({}),
  },
  /** QuickShop link page to be open in modal */
  quickShopLink: {
    type: [String, Object],
    default: '',
  },
  /** Flag to determine if add to favourites button should be shown */
  showAddToFavourites: {
    type: Boolean,
    default: true,
  },
  /** Flag to determine no inventory products should be fetched */
  showNoInventoryProducts: {
    type: Boolean,
    default: true,
  },
  /** Flag to determine if FE should show See More button */
  showSeeMore: {
    type: Boolean,
    default: true,
  },
  /** Flag to determine if FE should show Color Swatches */
  showColorSwatches: {
    type: Boolean,
    default: true,
  },
  /** Flag to determine if FE should show Regular Price */
  showRegularPrice: {
    type: Boolean,
    default: true,
  },
  /** Flag to determine if FE should show Sale Price */
  showSalePrice: {
    type: Boolean,
    default: true,
  },
  /** Flag to determine if FE should show WishList Icon */
  showWishListIcon: {
    type: Boolean,
    default: true,
  },
  /** Flag to determine if FE should show Product Badge */
  showProductBadge: {
    type: Boolean,
    default: true,
  },
  /** Flag to determine if FE should show Product Name */
  showProductName: {
    type: Boolean,
    default: true,
  },
  /** Flag to determine if FE should show Eyebrow */
  showEyebrow: {
    type: Boolean,
    default: true,
  },
  /** Flag to determine if FE should show QuickShop */
  showQuickShop: {
    type: Boolean,
    default: true,
  },
  /** Flag to determine if FE should show Ratings Icon */
  showRatingsIcon: {
    type: Boolean,
    default: true,
  },
  /** Flag to determine if FE should show Product Description */
  showProductDescription: {
    type: Boolean,
    default: true,
  },
  /** Flag to determine if FE should show Product Images */
  showProductImages: {
    type: Boolean,
    default: true,
  },
  /** How many items should be displayed on single page */
  productsPerPage: {
    type: Number,
    default: null,
  },
  showBadges: {
    type: Boolean,
    default: false,
  },
  /** Items per row for mobile, tablet and desktop */
  itemsPerRow: {
    type: Object,
    default: (): Record<string, unknown> => ({}),
  },
  /** Flag to determine if add to cart button should be shown */
  showAddToCart: Boolean,
  modals: {
    type: Object,
    default: (): Record<string, unknown> => ({
      signInToBuy: null,
      loyaltyEnrollment: null,
    }),
  },
};

export default CmsProps;
