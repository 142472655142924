var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VfContainer',[_c('div',{class:[
      'content-horizontalSimple row',
      _vm.bottomMarginStyle ? ("bottom-" + _vm.bottomMarginStyle) : '' ],style:({ backgroundColor: _vm.backgroundColorStyle })},[_c('div',{staticClass:"content-horizontalSimple__box column small-12 medium-6 large-6",class:( _obj = {}, _obj[("content-horizontalSimple__position--" + _vm.contentAlignment)] = _vm.contentAlignment, _obj )},[(_vm.title && _vm.title.text)?_c('ThemeTitle',{staticClass:"content-horizontalSimple__title",attrs:{"variant":_vm.title.variant,"color-hex":_vm.title.color,"background-color":"transparent","level":"2"}},[_vm._v("\n        "+_vm._s(_vm.title.text)+"\n      ")]):_vm._e(),_vm._v(" "),_c('VfText',{staticClass:"content-horizontalSimple__text",class:("vf-variant-" + (_vm.bodyText.variant)),attrs:{"html":true,"content":_vm.bodyText.content,"text-color":_vm.bodyText.color}}),_vm._v(" "),(_vm.ctas.length)?_c('ContentCTAButtons',{staticClass:"content-horizontalSimple__cta-items",attrs:{"ctas":_vm.ctas}}):_vm._e()],1),_vm._v(" "),_vm._l((_vm.pictures),function(picture){return _c('VfPicture',{key:picture.id,staticClass:"content-horizontalSimple__image column small-12",class:[
        ("content-horizontalSimple__order--" + (picture.order)),
        ("medium-" + (_vm.mediaLength === 1 ? '6' : '3')),
        ("large-" + (_vm.mediaLength === 1 ? '6' : '3')) ],attrs:{"src":picture,"alt":picture.alt,"width":"100%","height":"auto"}})}),_vm._v(" "),_vm._l((_vm.videos),function(video){return _c('VfVideo',{key:video.id,staticClass:"content-horizontalSimple__video column small-12",class:[
        ("content-horizontalSimple__order--" + (video.order)),
        ("medium-" + (_vm.mediaLength === 1 ? '6' : '3')),
        ("large-" + (_vm.mediaLength === 1 ? '6' : '3')) ],attrs:{"video":video}})})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }