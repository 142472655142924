import { defineStore } from 'pinia';
import { RewardCard } from '@vf/api-contract/src';
import { ref } from '@vue/composition-api';
import { GiftCardBalanceObject } from '../useCheckout/types';

export const useGiftCardsStore = defineStore('giftCards', () => {
  const appliedRewardCards = ref<RewardCard['gcNumber'][]>([]);
  const giftCardBalance = ref(<GiftCardBalanceObject>{
    cardNumber: null,
    balance: null,
    paymentMethodsData: null,
  });

  const applyRewardCardNumber = ({ gcNumber }: Partial<RewardCard>) => {
    if (!appliedRewardCards.value.includes(gcNumber)) {
      appliedRewardCards.value.push(gcNumber);
    }
  };

  const removeRewardCard = ({ gcNumber }: Partial<RewardCard>) => {
    const index = appliedRewardCards.value.findIndex((id) => id === gcNumber);

    appliedRewardCards.value.splice(index, 1);
  };

  return {
    giftCardBalance,
    appliedRewardCards,
    applyRewardCardNumber,
    removeRewardCard,
  };
});
