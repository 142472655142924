


















import type { PropType } from 'vue';
import { defineComponent, computed } from '@vue/composition-api';
import { useAccount } from '@vf/composables';
import type { MyAccountHeadingTranslations } from '@vf/api-contract';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'MaTitleHeader',
  props: {
    translations: {
      type: Object as PropType<MyAccountHeadingTranslations>,
      required: true,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { basicInformation } = useAccount(root);

    const notUser = computed(() => {
      const fullName = `${basicInformation.value.firstName} ${basicInformation.value.lastName}`;
      return props.translations.notUserLabel.replace('{0}', fullName);
    });

    return { notUser };
  },
});
