//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// TODO: GLOBAL15-63799 remove the whole file when redesign work is done
export default {
  name: 'AccordionsExpander',
  props: {
    translations: {
      type: Object,
      required: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
    collapseIcon: {
      type: String,
      default: 'minus',
    },
    expandIcon: {
      type: String,
      default: 'plus',
    },
  },
  setup(props, { emit }) {
    const onClick = () => {
      emit('toggleAccordionsExpander');
    };
    return {
      onClick,
    };
  },
};
