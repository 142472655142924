























































































































































import type { PropType } from 'vue';
import {
  computed,
  defineComponent,
  onMounted,
  ref,
} from '@vue/composition-api';
import type { OrderSummarySidebarTranslations } from '@vf/api-contract';
import { PaymentMethodCode } from '@vf/api-client';
import { maskGiftCardNumber } from '@vf/composables/src/utils/giftCard';
import { ROUTES, useI18n, useRewardCycleTranslations } from '@vf/composables';
import { getCouponDiscountAmount } from '@/helpers';
import { isPickupOrSts } from '@vf/shared/src/utils/helpers';
import useRootInstance from '@/shared/useRootInstance';
import { useCheckoutStore } from '@vf/composables/src/store/checkoutStore';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

export default defineComponent({
  props: {
    translations: {
      type: Object as PropType<OrderSummarySidebarTranslations>,
      required: true,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const theme = root.$themeConfig.orderSidebar;
    const { localePath } = useI18n(root);
    const { orderShipmentsSortedByDelivery } = useCheckoutStore();
    const order = ref(null);
    // TODO: GLOBAL15-56318 remove feature flag usage after checkout redesign
    const { areRewardCodesEnabled } = useFeatureFlagsStore();
    const translations = props.translations;

    onMounted(() => {
      order.value = useCheckoutStore().order;
    });

    const shippingTooltip = {
      pickup: translations.pickupTooltip,
      sts: translations.stsTooltip,
    };

    const giftCards = computed(() => {
      return order.value.paymentMethod.filter(
        (method) => method.code === 'GIFT_CARD'
      );
    });

    const formatPrice = (price) =>
      `-${root.$formatPrice(Math.abs(price), order.value.totals?.currency)}`;

    const productsPromotions = computed(() => {
      const promotions = [];
      if (!order.value?.items) return promotions;
      order.value.items.forEach(({ productPromotions = [] }) => {
        productPromotions.forEach((promo) => {
          const {
            calloutMsg,
            price,
            priceAdjustmentId,
            itemText,
            couponCode,
          } = promo;
          const wasPromoAlreadyAdded = promotions.find(
            ({ description }) =>
              description === calloutMsg || description === itemText
          );
          if (!wasPromoAlreadyAdded) {
            promotions.push({
              id: priceAdjustmentId,
              description: calloutMsg || itemText,
              price: getCouponDiscountAmount(couponCode, order.value) ?? price,
            });
          } else if (!couponCode) {
            wasPromoAlreadyAdded.price += price;
          }
        });
      });
      promotions.forEach((promo) => {
        promo.price = formatPrice(promo.price);
      });
      return promotions;
    });

    const appliedPromotions = computed(() => {
      const orderPromotions =
        order.value.orderPromotions?.map((promotion) => ({
          id: promotion.promotionId,
          price: formatPrice(promotion.price),
          description: promotion.couponCode || promotion.itemText,
        })) ?? [];

      return [...productsPromotions.value, ...orderPromotions];
    });

    const itemsCount = computed(() => {
      if (!order.value?.items) return 0;
      return order.value.items.reduce((acc, item) => acc + item.qty, 0);
    });

    const orderTotal = computed(() => {
      const discountCards = [
        ...giftCards.value,
        ...rewards.value,
        ...athleteDiscount.value,
        ...loyaltyRewardsVouchers.value,
      ];
      let total = order.value.totals.total;
      if (!discountCards.length) {
        return total;
      }
      discountCards.forEach((discountCard) => {
        total -= discountCard.additionalData.amount;
      });
      return total;
    });

    const handlePrint = () => {
      try {
        if (!document.execCommand('print', false, null)) {
          window.print();
        }
      } catch {
        window.print();
      }
    };

    const continueShopping = () => root.$router.push(localePath(ROUTES.HOME()));

    const rewards = computed(() => {
      return order.value.paymentMethod.filter((method) =>
        ['REWARD_CARD', 'REWARD_CODE'].includes(method.code)
      );
    });

    const getShippingCost = ({ amount, currency }) => {
      if (theme.showFreeTextIfZeroShippingCost)
        return Math.round(amount)
          ? root.$formatPrice(amount, currency)
          : translations.free;
      return root.$formatPrice(amount, currency);
    };

    const enforcedShippingFeeValue = computed(
      () => order.value.totals?.enforcedShippingFee
    );
    const enforcedShippingFee = computed(() =>
      order.value.totals?.enforcedShippingFee === null ||
      order.value.totals?.enforcedShippingFee === undefined
        ? '--'
        : root.$formatPrice(
            order.value.totals.enforcedShippingFee,
            order.value.totals?.currency
          )
    );
    const enforcedShippingFeeLabels = computed(() => {
      const province = order.value.shipments.filter((sh) => !sh.storeId)[0]
        ?.shippingAddress?.province;
      return root.$t(
        `orderSummarySidebar.success.enforcedShippingFee.${province}`
      );
    });

    const orderSummaryShipments = computed(() => {
      const sortedShipments = orderShipmentsSortedByDelivery.map(
        (shipment) => ({
          shipmentId: shipment.shipmentId,
          storeName: shipment.storeName,
          ...shipment.shippingMethod,
        })
      );
      return sortedShipments.reduce((acc, shipment) => {
        const existingShipment = acc.find(({ label, deliveryTime }) => {
          const sameLabel = label === shipment.label;
          const sameDeliveryTime = deliveryTime === shipment.deliveryTime;
          return sameLabel && sameDeliveryTime;
        });

        if (existingShipment) {
          existingShipment.price.amount += shipment.price.amount;
        } else {
          acc.push(shipment);
        }

        return acc;
      }, []);
    });

    const athleteDiscount = computed(() => {
      return order.value.paymentMethod.filter(
        (payment) => payment.code === PaymentMethodCode.ATHLETES
      );
    });

    const loyaltyRewardsVouchers = computed(() => {
      return order.value.paymentMethod.filter(
        (payment) => payment.code === PaymentMethodCode.LOYALTY_POINTS
      );
    });

    const { getRewardCycleText } = useRewardCycleTranslations({
      summer: translations.summerReward,
      winter: translations.winterReward,
      spring: translations.springReward,
    });

    return {
      theme,
      getRewardCycleText,
      areRewardCodesEnabled,
      appliedPromotions,
      itemsCount,
      order,
      handlePrint,
      productsPromotions,
      giftCards,
      rewards,
      maskGiftCardNumber,
      continueShopping,
      orderTotal,
      orderSummaryShipments,
      getShippingCost,
      shippingTooltip,
      isPickupOrSts,
      enforcedShippingFeeValue,
      enforcedShippingFee,
      enforcedShippingFeeLabels,
      athleteDiscount,
      loyaltyRewardsVouchers,
    };
  },
});
