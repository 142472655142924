
































































import type { PropType } from 'vue';
import {
  ref,
  computed,
  defineComponent,
  onMounted,
} from '@vue/composition-api';
import type { SearchSortByTranslations } from '@vf/api-contract';
import { useArticles } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'SearchArticleSortBy',
  props: {
    translations: {
      type: Object as PropType<SearchSortByTranslations>,
      default: () => ({}),
    },
    /** Default chosen value for Sort By select  */
    selectedSort: {
      type: String,
      default: 'publicationDate',
    },
    contextKey: {
      type: String,
      default: 'page-content',
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const {
      getArticlesResults,
      changeSort,
      sortingOptions,
      setSortingOptions,
    } = useArticles(root, props.contextKey);
    const mobileSortVisible = ref(false);
    const selectedValue = ref(props.selectedSort);
    const changeActiveSortOption = (sortOptionId) => {
      selectedValue.value = sortOptionId;
      changeSort(sortOptionId);
      getArticlesResults();
      mobileSortVisible.value = false;
    };
    const onCloseModal = () => {
      mobileSortVisible.value = false;
    };
    const activeLabel = computed(() => {
      const filteredOption = sortingOptions.value.find(
        (option) => option.value === selectedValue.value
      );
      return filteredOption?.label;
    });

    const isSmallScreenSortByModified =
      root.$themeConfig.sortByType?.modified ?? true;

    const isPlaceholderVisible =
      root.$themeConfig.sortByType?.isPlaceholderVisible ?? false;

    const placeholderValue = isPlaceholderVisible
      ? props.translations.sortLabel
      : '';

    onMounted(() => {
      setSortingOptions(props.translations.sortByOptions);
    });

    return {
      selectedValue,
      mobileSortVisible,
      changeActiveSortOption,
      sortingOptions,
      onCloseModal,
      activeLabel,
      isSmallScreenSortByModified,
      isPlaceholderVisible,
      placeholderValue,
    };
  },
});
