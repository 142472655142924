

































import { defineComponent, ssrRef, useFetch } from '@nuxtjs/composition-api';
import { BreadcrumbList, WithContext } from 'schema-dts';
import {
  ROUTES,
  useFindInStore,
  useI18n,
  useJsonLinkedData,
  useRequestTracker,
} from '@vf/composables';
import StoreSchema from './StoreSchema.vue';
import StoreSplitDealers from '@vf/ui/components/Atom.StoreSplitDealers.vue';
import { setPageTypeName } from '@/helpers';
import { capitalizeText } from '@/helpers/capitalizeText';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'CityStores',
  components: {
    StoreSchema,
    StoreSplitDealers,
  },
  props: {
    /**
     * City id.
     */
    id: {
      type: String,
      default: '',
    },
    /**
     * Block title.
     */
    title: {
      type: String,
      default: '',
    },
    /**
     * Meta data.
     */
    metaData: {
      type: Object,
      default: () => {
        /**/
      },
    },
    /**
     * formatLink.
     */
    formatLink: {
      type: String,
      default: '{address1}, {state}, {postalcode}',
    },
    /**
     * storeOrder used in API call.
     */
    storeOrder: {
      type: String,
      default: undefined,
    },
    /**
     * enable split by dealer.
     */
    splitDealers: {
      type: Boolean,
      default: false,
    },
    /**
     * title for vf store
     */
    titleVfStore: {
      type: String,
      default: '$titleVfStore$',
    },
    /**
     * title for Dealer store
     */
    titleDealerStore: {
      type: String,
      default: '$titleDealerStore$',
    },
  },
  jsonld(): WithContext<BreadcrumbList> {
    return this.transformBreadcrumbsDataToJsonld(this.breadcrumbs);
  },
  setup(props) {
    const { root } = useRootInstance();

    const breadcrumbs = ssrRef([], 'city-stores-breadcrumbs');
    const fullTitle = ssrRef('', 'city-stores-title');
    // Function to map breadcrumbs list.
    const mapBreadCrumbs = (data) => {
      breadcrumbs.value = [
        {
          link: root.$storeLocatorUrlGenerator('/stores'),
          text: props.metaData.main_breadcrumb_url_name,
        },
        {
          link: root.$storeLocatorUrlGenerator('/stores', data.state),
          text: stateCollection.value.description,
        },
        {
          link: root.$storeLocatorUrlGenerator(
            '/stores',
            data.state,
            data.city
          ),
          text: data.city.toUpperCase(),
        },
      ];
    };
    const {
      getCityWithStores,
      getStateWithCities,
      cityStores: cityCollection,
      state: stateCollection,
      isDealer,
    } = useFindInStore(root);
    const { localePath } = useI18n(root);
    const { onAllDone } = useRequestTracker(root);
    const { transformBreadcrumbsDataToJsonld } = useJsonLinkedData(root);

    const setPageValues = async () => {
      await getCityWithStores({
        city: props.id.replace(/-/g, '%'),
        state: root.$route.params.state.toUpperCase(),
        storeOrder: props.storeOrder,
      });
      if (Object.values(cityCollection.value).length > 0) {
        await getStateWithCities(
          cityCollection.value.state.toUpperCase(),
          false
        );
        cityCollection.value.state_name =
          cityCollection.value && cityCollection.value.stores.length > 0
            ? cityCollection.value.stores[0].province
            : '';

        fullTitle.value = props.title.replace(
          '{city_state}',
          cityCollection.value.citystate
        );
        mapBreadCrumbs(cityCollection.value);
      } else {
        if (!process.server) {
          root.$router.push({
            path: localePath(ROUTES.NOT_FOUND()),
          });
        } else {
          root.$nuxt.error({ statusCode: 404, message: 'Not found' });
        }
      }
    };

    const getFormattedLink = (store, city) => {
      const formattedLink = props.formatLink
        .replace('{name}', store.name)
        .replace('{address1}', store.address1)
        .replace('{state}', city.state)
        .replace('{postalcode}', store.postalcode);
      return props.splitDealers
        ? capitalizeText(formattedLink, true)
        : formattedLink;
    };

    useFetch(setPageValues);

    onAllDone(() => {
      setPageTypeName('stores city page');
    });

    return {
      cityCollection,
      breadcrumbs,
      fullTitle,
      getFormattedLink,
      isDealer,
      stateCollection,
      urlGenerator: root.$storeLocatorUrlGenerator,
      transformBreadcrumbsDataToJsonld,
    };
  },
  head() {
    const city = this.cityCollection;
    const state = this.stateCollection;
    const metaDescription = this.metaData.description
      .replace('{city}', capitalizeText(city.city, true))
      .replace('{state_name}', state.name)
      .replace('{city_state}', city.citystate);
    return {
      title: this.metaData.title
        .replace('{city}', capitalizeText(city.city, true))
        .replace('{state}', state.description)
        .replace('{state_name}', state.name),
      meta: [
        {
          hid: 'description',
          name: 'description',
          property: 'description',
          content: metaDescription,
        },
        {
          hid: 'og:description',
          name: 'og:description',
          property: 'og:description',
          content: metaDescription,
        },
        {
          hid: 'keywords',
          name: 'keywords',
          property: 'keywords',
          content: this.metaData.keywords,
        },
      ],
      link: [
        {
          rel: 'canonical',
          href:
            'https://' +
            this.$getDomainName() +
            this.$storeLocatorUrlGenerator('/stores', state.name, city.city),
        },
      ],
    };
  },
});
