import { useGiftCardsStore } from '../store/giftCards';
import { storeToRefs } from 'pinia';
import { ApplePayContext, useApplePayPdpCart, useCart } from '@vf/composables';
import { computed } from '@vue/composition-api';
import { errorMessages } from '../utils/errorMessages';
import {
  apiClientFactory,
  ApplyPaymentInstrumentRequestObject,
  PaymentMethodCode,
} from '@vf/api-client';
import { GiftCardBalanceObject } from '../useCheckout/types';
import { RewardCard } from '@vf/api-contract';
import { useRequestTracker } from '../useRequestTracker';
import { useCartStore } from '../store/cartStore';
import { useFeatureFlagsStore } from '../store/featureFlags';

export interface GiftCard {
  cardNumber: string;
  pin: string;
  recaptchaToken: string;
}

export const useGiftCards = (instance, contextKey?: string) => {
  const { updateCart, cartId } =
    contextKey === ApplePayContext.PDP
      ? useApplePayPdpCart(instance)
      : useCart(instance);
  const { displayErrorMessages } = errorMessages(instance);
  const {
    deleteCartPaymentInstruments: deleteCartPaymentInstrumentsAPI,
    getCartPaymentInstruments: getCartPaymentInstrumentsAPI,
  } = apiClientFactory(instance);

  const store = useGiftCardsStore();
  const cartStore = useCartStore();
  const { isCheckoutRedesignEnabled } = useFeatureFlagsStore();
  const { trackRequest, clearRequest } = useRequestTracker(instance);
  const { appliedRewardCards, giftCardBalance } = storeToRefs(store);
  const { appliedGiftCards } = storeToRefs(cartStore);

  const { applyRewardCardNumber, removeRewardCard } = store;

  const canAddMoreCards = computed(() => {
    const isBelowLimit =
      appliedGiftCards.value.length <
      instance.$themeConfig.staticCheckout.giftCard.maxGiftCards;

    // TODO: GLOBAL15-56318 delete statement
    if (!isCheckoutRedesignEnabled) {
      return isBelowLimit;
    }
    return isBelowLimit && !cartStore.isZeroOrder;
  });

  const setGiftCardBalance = (data: GiftCardBalanceObject) => {
    giftCardBalance.value = data;
  };

  const applyPaymentInstrument = async (
    payload: ApplyPaymentInstrumentRequestObject
  ) => {
    const { tag } = trackRequest('useGiftCards-applyPaymentInstrument');
    try {
      const response = await getCartPaymentInstrumentsAPI(payload);
      await updateCart(response.data);
      return response;
    } catch (e) {
      displayErrorMessages(e);
      return e.response;
    } finally {
      clearRequest(tag);
    }
  };

  const removeGiftCard = async (giftCardData) => {
    try {
      const response = await deleteCartPaymentInstrumentsAPI({
        cartId: cartId.value,
        payment_instrument_id: giftCardData.paymentId,
      });
      await updateCart(response.data);
      return response;
    } catch (e) {
      displayErrorMessages(e);
    }
  };

  const applyGiftCard = (giftCardData: GiftCard) => {
    return applyPaymentInstrument({
      cartId: cartId.value,
      payment_method_id: PaymentMethodCode.GIFT_CARD,
      c_gcCardNumber: giftCardData.cardNumber,
      c_gcEAN: giftCardData.pin,
      recaptcha_response: giftCardData.recaptchaToken,
    });
  };

  const applyRewardCard = async (card: RewardCard) => {
    return applyPaymentInstrument({
      cartId: cartId.value,
      payment_method_id: card.paymentMethodId,
      paymentInstrumentId: card.paymentInstrumentId,
    });
  };

  const applyAthleteDiscount = () => {
    return applyPaymentInstrument({
      cartId: cartId.value,
      payment_method_id: PaymentMethodCode.ATHLETES,
    });
  };

  return {
    applyRewardCardNumber,
    appliedRewardCards,
    applyRewardCard,
    removeRewardCard,
    giftCardBalance,
    setGiftCardBalance,
    removeGiftCard,
    applyGiftCard,
    applyAthleteDiscount,
    canAddMoreCards,
  };
};
