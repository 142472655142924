var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.order || _vm.returnPreviewObject)?_c('div',{staticClass:"vf-order-sidebar"},[(
      !_vm.returnPreviewObject || _vm.returnStep === _vm.ReturnStepsType.HISTORY_DETAILS
    )?_c('VfNotification',{staticClass:"vf-order-sidebar__notification vf-notification--white-background small-only",attrs:{"type":_vm.theme.notificationType,"message":_vm.orderStatus,"show-close-button":_vm.theme.showNotificationClose,"visible":""}}):_vm._e(),_vm._v(" "),(
      _vm.order &&
      [_vm.ReturnStepsType.DETAILS, _vm.ReturnStepsType.EMPTY].includes(_vm.returnStep)
    )?[_c('OrderSidebarProperty',{staticClass:"vf-order-sidebar__order-label",attrs:{"name":_vm.translations.orderLabel},scopedSlots:_vm._u([{key:"value",fn:function(){return [_c('span',{staticClass:"vf-order-sidebar__order-number"},[_vm._v("\n          "+_vm._s(("#" + (_vm.order.orderNumber)))+"\n        ")])]},proxy:true}],null,false,2694951550)}),_vm._v(" "),_c('VfDate',{staticClass:"vf-order-sidebar__date",attrs:{"date":_vm.order.orderDate,"format":"intl-long"}}),_vm._v(" "),(_vm.theme.showDividerAfterDate && _vm.trackableShipments.length)?_c('VfDivider'):_vm._e(),_vm._v(" "),(_vm.trackableShipments.length)?_c('div',{staticClass:"vf-order-sidebar__tracking",class:{
        'vf-order-sidebar__tracking--flattened':
          _vm.theme.hideMultipleTrackingLabels,
      }},_vm._l((_vm.trackableShipments),function(trackable,i){return _c('OrderSidebarProperty',{key:trackable.trackingNumber,class:{
          'vf-order-sidebar__tracking--column': _vm.trackableShipments.length > 1,
        },attrs:{"name":!_vm.theme.hideMultipleTrackingLabels || !i
            ? ((_vm.translations.trackPackageLabel) + ": ")
            : ''},scopedSlots:_vm._u([{key:"value",fn:function(){return [_c('div',{staticClass:"vf-order-sidebar__tracking-label",class:{
              'vf-order-sidebar__tracking-label--inline': !_vm.theme.showFlatTrackOrderText,
            }},[(_vm.trackableShipments.length > 1)?_c('VfText',{attrs:{"content":_vm.translations.trackYourShipment
                  .replace("{{index}}", i + 1)
                  .replace("{{number}}", '')}}):_vm._e(),_vm._v(" "),_c('ThemeLink',{staticClass:"vf-order-sidebar__tracking-number",attrs:{"to":trackable.trackingUrl}},[_vm._v("\n              "+_vm._s(_vm.trackableShipments.length > 1 || !_vm.theme.showFlatTrackOrderText
                  ? trackable.trackingNumber
                  : _vm.translations.trackYourOrder)+"\n            ")])],1)]},proxy:true}],null,true)})}),1):_vm._e(),_vm._v(" "),_c('VfDivider'),_vm._v(" "),_c('OrderSidebarProperty',{attrs:{"name":((_vm.translations.orderedItemsLabel) + ":"),"value":_vm.order.totals.totalUnits}}),_vm._v(" "),_c('VfDivider'),_vm._v(" "),(_vm.giftCardAmount)?_c('OrderSidebarProperty',{attrs:{"name":((_vm.translations.giftCard) + ":"),"value":("-" + (_vm.formatPrice(_vm.giftCardAmount)))}}):_vm._e(),_vm._v(" "),(_vm.rewardCardAmount)?_c('OrderSidebarProperty',{attrs:{"name":((_vm.translations.rewardCardLabel) + ":"),"value":("-" + (_vm.formatPrice(_vm.rewardCardAmount)))}}):_vm._e(),_vm._v(" "),(_vm.theme.showCostsExpanded)?[_c('OrderSidebarProperty',{attrs:{"name":("" + (_vm.translations.itemsSubTotalLabel)),"value":_vm.formatPrice(_vm.order.totals.netAmount)}}),_vm._v(" "),_c('OrderSidebarProperty',{attrs:{"name":("" + (_vm.translations.discountLabel)),"value":_vm.formatPrice(_vm.order.totals.discount)}}),_vm._v(" "),_c('OrderSidebarProperty',{attrs:{"name":("" + (_vm.translations.estimatedShippingLabel)),"value":_vm.formatShippingPrice(_vm.order.totals)}}),_vm._v(" "),_c('OrderSidebarProperty',{attrs:{"name":("" + (_vm.translations.estimatedTaxLabel.replace(
          '{{province}}',
          _vm.shippingAddressProvince
        ))),"value":_vm.formatPrice(_vm.order.totals.taxAmount)}}),_vm._v(" "),_c('VfDivider')]:[_c('OrderSidebarProperty',{attrs:{"name":("" + (_vm.translations.estimatedShippingLabel)),"value":_vm.formatPrice(_vm.order.totals.shipping)}}),_vm._v(" "),_c('OrderSidebarProperty',{attrs:{"name":("" + (_vm.translations.estimatedTaxLabel)),"value":_vm.formatPrice(_vm.order.totals.taxAmount)}})],_vm._v(" "),(!!_vm.order.totals.enforcedShippingFee)?_c('div',{staticClass:"vf-order-sidebar__enforced-shipping-fee"},[_c('OrderSidebarProperty',{attrs:{"name":_vm.$t(
            ("orderSummarySidebar.cart.enforcedShippingFee." + _vm.shippingAddressProvince + ".label")
          ),"value":_vm.formatPrice(_vm.order.totals.enforcedShippingFee)}}),_vm._v(" "),_c('div',{staticClass:"vf-order-sidebar__property-info"},[_vm._v("\n        "+_vm._s(_vm.$t(
            ("orderSummarySidebar.cart.enforcedShippingFee." + _vm.shippingAddressProvince + ".description")
          ))+"\n      ")])],1):_vm._e(),_vm._v(" "),_c('OrderSidebarProperty',{staticClass:"vf-order-sidebar__order-total-label",attrs:{"name":("" + (_vm.translations.orderTotalLabel))},scopedSlots:_vm._u([{key:"value",fn:function(){return [_c('span',{staticClass:"vf-order-sidebar__order-total"},[_vm._v("\n          "+_vm._s(_vm.formatPrice(_vm.order.totals.total))+"\n        ")])]},proxy:true}],null,false,2565989217)})]:_vm._e(),_vm._v(" "),_c('OrderReturnsProperties',{attrs:{"translations":_vm.translations,"are-returns-enabled":_vm.areOnlineReturnsEnabled,"is-narvar-enabled":_vm.areNarvarReturnsEnabled,"return-step":_vm.returnStep,"return-order":_vm.returnPreviewObject}}),_vm._v(" "),(!_vm.returnStep || _vm.returnStep === _vm.ReturnStepsType.HISTORY_DETAILS)?_c('OrderReturnsCTA',{attrs:{"translations":_vm.translations,"returnable-order-id":_vm.returnableOrderId,"start-return-url":_vm.returnPreviewObject &&
      _vm.returnPreviewObject.hasReturnableItems &&
      _vm.$env.NARVAR_START_RETURN_URL,"return-tracking-url":_vm.returnStep && _vm.returnPreviewObject
        ? _vm.returnPreviewObject.extnReturnTrackingURL
        : ''}}):_vm._e(),_vm._v(" "),_c('VfDivider',{staticClass:"vf-order-sidebar__divider small-only"}),_vm._v(" "),(_vm.hasPickupLocations)?_c('OrderPickupDetails',{staticClass:"vf-order-sidebar__pickup-details",attrs:{"translations":_vm.pickupDetailsTranslations}}):_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }