










import {
  ref,
  watch,
  nextTick,
  onUnmounted,
  onMounted,
} from '@vue/composition-api';
import { defineComponent, useFetch } from '@nuxtjs/composition-api';
import axios from 'axios';
import useRootInstance from '@/shared/useRootInstance';
import useModal from '@/shared/useModal';
import { getCoremediaAxiosHeaders } from '@/helpers';

export default defineComponent({
  name: 'CustomContent',
  components: {},
  props: {
    /** Custom Url that used as path name to static-pages directory */
    customContentFile: {
      type: String,
      default: '',
    },
    customContentPath: {
      type: String,
      default: '',
    },
    modals: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const html = ref(null);
    const htmlRef = ref(null);
    const customJavascript = ref(null);
    const scriptSrc = ref(null);
    const externalScriptSrc = ref(null);
    const { openModal } = useModal();

    const getContentPageUrl = () => {
      /** This is only necessary for local development.
       * Production and development environments
       * should be root/site relative.
       * Setting the CUSTOM_CONTENT_HOST_URL value
       * is only necessary for local development
       */
      const customContentHostUrl =
        root.$env.CUSTOM_CONTENT_HOST_URL || `https://${root.$getDomainName()}`;

      /** This path aligns with the name of the custom content
       * repo used. Every brand will have their own repo so
       * this should be configured in the helm files
       */
      const customContentPath =
        props.customContentPath || root.$env.CUSTOM_CONTENT_PATH;

      // preview vs publish
      const folder = root.$isPreview ? 'preview' : 'publish';

      // /custom-content/ is a path routed to the AWS bucket through Akamai
      return customContentHostUrl && customContentPath
        ? `${customContentHostUrl}/custom-content/files/${customContentPath}/${folder}/${props.customContentFile}`
        : '';
    };

    const loadScripts = (arrScripts, scriptIndex = 0) => {
      return new Promise((resolve, reject) => {
        try {
          const s = document.createElement('script');
          s.src = arrScripts[scriptIndex];
          s.onload = resolve;
          s.onerror = reject;
          // Not the best practise use pure JS selectors in Vue
          document.querySelector('.external-scripts-holder').appendChild(s);
          root.$log.info(
            `[@theme/components/smart/customContent/customContent::loadScripts] Loading CustomContent script: ${arrScripts[scriptIndex]}`
          );
        } catch (err) {
          reject(err);
        }
      })
        .then(() => {
          if (arrScripts.length === scriptIndex + 1) {
            nextTick(
              () => (customJavascript.value.innerHTML = scriptSrc.value)
            );
          } else {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            loadScripts(arrScripts, scriptIndex + 1);
          }
        })
        .catch((err) => {
          root.$log.error(
            `[@theme/components/smart/customContent/customContent::loadScripts] Loading CustomContent script: ${arrScripts[scriptIndex]} Error: ${err.message}`
          );
        });
    };

    const handleModalOpen = (event) => {
      const target = event.target as HTMLElement;
      if (target.getAttribute('data-show') === 'modal') {
        event.preventDefault();
        event.stopPropagation();
        const modalId = target.getAttribute('href');

        props.modals?.[modalId] &&
          openModal({
            type: 'lazyFragment',
            resourceId: props.modals[modalId],
          });
      }
    };

    /** Get the script inside the content.
     * Note: It still contains the start tag
     */
    const getScriptSourceWithTag = (dataHtml) => {
      const regex = /(?=\<script\>)(.*)(?=\<\/script\>)/gms;
      return dataHtml.match(regex)?.[0];
    };

    /**
     * Extracts external scripts sources and clears html from it
     */
    const extractExternalScripts = (html: string) => {
      const externalScriptRegExp = /<script[\s\w="'\/]*src\s*=\s*['"]([^"']*)["']><\/script>/gm;

      const externalScriptsSources = Array.from(
        html.matchAll(externalScriptRegExp),
        (match) => match[1]
      );
      const htmlWithoutExternalScripts = html.replace(externalScriptRegExp, '');
      return {
        html: htmlWithoutExternalScripts,
        externalScriptsSources,
      };
    };

    /** Use the script as a reference to split it out the html
     * This needs to be hacked in since the parser
     * doesn't like the script tag inline.
     */
    const transformHtmlContent = (dataHtml, scriptSourceWithTag) => {
      return dataHtml.replace(scriptSourceWithTag + '</' + 'script>', '');
    };

    const contentPageUrl = getContentPageUrl();

    contentPageUrl &&
      useFetch(async () => {
        try {
          const headers = getCoremediaAxiosHeaders(root);
          root.$log.debug(
            `[@theme/components/smart/customContent/customContent::useFetch] GET ${contentPageUrl} at page ${
              root.$route.fullPath
            } with headers: ${JSON.stringify(headers)}`
          );
          const response = await axios.get<string>(contentPageUrl, {
            headers: headers,
          });

          const {
            html: dataHtml,
            externalScriptsSources,
          } = extractExternalScripts(response.data);

          const scriptSourceWithTag = getScriptSourceWithTag(dataHtml) ?? '';
          externalScriptSrc.value = externalScriptsSources;

          // Update the HTML before we update the JS
          html.value = transformHtmlContent(dataHtml, scriptSourceWithTag);

          // Remove the start tag from the script.
          const scriptSourceWithoutTag = scriptSourceWithTag.replace(
            /\<script\>/,
            ''
          );

          // Wrap the JS in an IIFE to provide closure and save it
          scriptSrc.value = `(() => {${scriptSourceWithoutTag}})()`;
        } catch (error) {
          root.$log.error(
            `[@theme/components/smart/customContent/customContent::useFetch] Failed fetching URI: ${contentPageUrl} at ${root.$route.fullPath} Error: ${error.message}`
          );
        }
      });

    /** Watch for changes to the HTML so that we can wait
     * for it to be added to the DOM before injecting the JS
     * There is a race condition when the HTML is updated on CSR
     * The v-html is delayed until after the JS is injected and
     * DOM manipulation is possible.
     */
    watch(html, (newValue) => {
      // See if there is a value for the new HTML and we are on the client
      if (newValue && process.client) {
        // Delay injecting the JS until the next tick so HTML can be updated
        nextTick(() => {
          if (externalScriptSrc.value?.length) {
            loadScripts(externalScriptSrc.value);
          } else {
            customJavascript.value.innerHTML = scriptSrc.value;
          }
        });
      }
    });

    onMounted(() => {
      (htmlRef.value as HTMLElement)?.addEventListener(
        'click',
        handleModalOpen
      );
    });

    onUnmounted(() => {
      (htmlRef.value as HTMLElement)?.removeEventListener(
        'click',
        handleModalOpen
      );
    });

    return {
      html,
      customJavascript,
      scriptSrc,
      htmlRef,
      externalScriptSrc,
    };
  },
  head: {},
});
