






















































































import type { PropType } from 'vue';
import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import type { CheckoutRewardCardTranslations } from '@vf/api-contract';
import {
  useRewardStore,
  useCart,
  useNotification,
  useGiftCards,
  useCartStore,
} from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import { storeToRefs } from 'pinia';
import { replaceAll } from '@/helpers/replaceAll';
import CheckoutRewardNew from './variants/CheckoutRewardNew.vue';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

export default defineComponent({
  name: 'CheckoutRewardCard',
  components: {
    CheckoutRewardNew,
    CheckoutRewardXPLRPass: () =>
      import(
        '@/components/smart/checkout/CheckoutRewardCard/variants/CheckoutRewardXPLRPass.vue'
      ),
  },
  props: {
    // TODO: GLOBAL15-56318 remove prop
    translations: {
      type: Object as PropType<CheckoutRewardCardTranslations>,
      default: () => ({}),
    },
    // TODO: GLOBAL15-56318 change prop name to maxRewardUsage
    /** Maximum gift/reward cards possible to add per order */
    maxGiftCards: {
      type: Number,
      default: 2,
    },
    variant: {
      type: String,
      default: undefined,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { isCheckoutRedesignEnabled } = useFeatureFlagsStore();
    const rewardStore = useRewardStore(root);
    const { appliedRewards, appliedGiftCards, hasItems } = useCart(root);
    const { isZeroOrder } = storeToRefs(useCartStore());

    const {
      addNotification,
      clearNotifications,
      notifications,
    } = useNotification(root);
    const {
      removeGiftCard,
      applyRewardCard,
      appliedRewardCards: appliedRewardCardsStore,
      applyRewardCardNumber: applyRewardCardStore,
      removeRewardCard,
    } = useGiftCards(root);
    const { rewards } = storeToRefs(rewardStore);

    const isAccordionOpened = ref(true);
    const isDetailsExpanded = ref(false);
    const showMoreLimit = ref(3);
    const isShowMoreVisible = ref(true);

    const variants = {
      'xplr-pass': 'CheckoutRewardXPLRPass',
      'checkout-redesign': 'VfCollapsible',
      default: 'VfAccordion',
    };

    // TODO: GLOBAL15-56318 remove this conditional and component is from template
    const accordionComponent =
      variants[
        props.variant ||
          (isCheckoutRedesignEnabled && 'checkout-redesign') ||
          'default'
      ];

    // TODO: GLOBAL15-56318 remove this conditional
    // rename variable to translation because now we can't have the same name as in prop
    const translation = isCheckoutRedesignEnabled
      ? {
          instructionsDefault: root.$t(
            'checkoutReward.instructionsDefault'
          ) as string,
          instructionsOneApplied: root.$t(
            'checkoutReward.instructionsOneApplied'
          ) as string,
          instructionsMaxApplied: root.$t(
            'checkoutReward.instructionsMaxApplied'
          ) as string,
          titleReward: root.$t('checkoutReward.titleReward') as string,
          titleRewardWithAvailableReward: root.$t(
            'checkoutReward.titleRewardWithAvailableReward'
          ) as string,
          seeMoreRewards: root.$t('checkoutReward.seeMoreRewards') as string,
          apply: root.$t('checkoutReward.apply') as string,
          removeRewardCard: root.$t(
            'checkoutReward.removeRewardCard'
          ) as string,
          selectionMax: root.$t('checkoutReward.selectionMax') as string,
          detailsExpanded: root.$t('checkoutReward.detailsExpanded') as string,
          rewardDetails: root.$t('checkoutReward.rewardDetails') as string,
          maxCardsApplied: '', // TODO: GLOBAL15-56318 remove this property
          applySuccessMessage: '', // TODO: GLOBAL15-56318 remove this property
          removeSuccessMessage: '', // TODO: GLOBAL15-56318 remove this property
        }
      : props.translations;

    const toggleDetailsExpanded = () => {
      isDetailsExpanded.value = !isDetailsExpanded.value;
    };

    const isVisible = computed(
      () =>
        hasItems.value &&
        (!isZeroOrder.value || appliedRewards.value.length) &&
        rewards.value.length > 0
    );

    const isMaxCardsAppliedMessageVisible = computed(
      () =>
        appliedRewards.value.length + appliedGiftCards.value.length >=
        props.maxGiftCards
    );

    const appliedGiftAndRewardCards = computed(() => [
      ...appliedGiftCards.value,
      ...appliedRewards.value,
    ]);

    const onClickApplyRewardCard = async (card) => {
      const response = await applyRewardCard(card);

      applyRewardCardStore(card);

      // TODO: GLOBAL15-56318 remove this conditional
      if (!isCheckoutRedesignEnabled && [200, 201].includes(response?.status)) {
        clearNotifications();
        addNotification({
          message: isMaxCardsAppliedMessageVisible.value
            ? translation.maxCardsApplied
            : translation.applySuccessMessage,
          type: 'success',
          context: 'checkout-reward-card',
        });
      }
    };

    const onClickRemoveRewardCard = async (reward) => {
      const data = {
        paymentId: findAppliedReward(reward.id).payment_instrument_id,
        method: reward.paymentMethodId,
      };
      const response = await removeGiftCard(data);

      removeRewardCard(reward);

      // TODO: GLOBAL15-56318 remove this conditional
      if (!isCheckoutRedesignEnabled && [200, 201].includes(response?.status)) {
        clearNotifications();
        addNotification({
          message: translation.removeSuccessMessage,
          type: 'success',
          context: 'checkout-reward-card',
        });
      }
    };

    const findAppliedReward = (rewardId: string) => {
      return appliedRewards.value.find((appliedCard) =>
        [
          appliedCard.customerPaymentInstrumentId,
          appliedCard.c_gcCardNumber,
        ].includes(rewardId)
      );
    };

    const isRewardApplied = (reward) => !!findAppliedReward(reward.id);

    const isApplyCardBtnDisabled = (reward): boolean =>
      isRewardApplied(reward) ||
      appliedGiftAndRewardCards.value.length >= props.maxGiftCards ||
      !reward.balance ||
      reward.authorized;

    const rewardCardNotification = computed(() =>
      notifications.value.find((n) => n.context === 'checkout-reward-card')
    );

    watch(rewardCardNotification, () => {
      isAccordionOpened.value = true;
    });

    const instructionText = computed(() => {
      const numAppliedRewards = appliedRewards.value.length;

      if (numAppliedRewards === 0) {
        return translation.instructionsDefault;
      } else if (numAppliedRewards === 1) {
        return translation.instructionsOneApplied;
      } else {
        return translation.instructionsMaxApplied;
      }
    });

    const slicedRewards = computed(() => {
      return rewards.value.slice(0, showMoreLimit.value);
    });

    const showMoreItems = () => {
      showMoreLimit.value = rewards.value.length;
      isShowMoreVisible.value = false;
    };

    const headerTitle = computed(() => {
      if (props.variant === 'xplr-pass') {
        const allowedToApply = Math.max(
          props.maxGiftCards -
            (appliedRewards.value.length + appliedGiftCards.value.length),
          0
        );

        const rawAmount = rewards.value
          .filter((reward) => !isRewardApplied(reward))
          .map((reward) => reward.rawBalance)
          .sort((a, b) => b - a)
          .slice(0, allowedToApply)
          .reduce((acc, curr) => {
            return acc + curr;
          }, 0);

        return replaceAll(
          translation.titleRewardWithAvailableReward as string,
          {
            availableReward: root.$formatPrice(rawAmount),
          }
        );
      }

      return translation.titleReward;
    });

    return {
      translation,
      isCheckoutRedesignEnabled,
      accordionComponent,
      appliedRewardCardsStore,
      isVisible,
      isAccordionOpened,
      isMaxCardsAppliedMessageVisible,
      rewards,
      slicedRewards,
      rewardCardNotification,
      clearNotifications,
      onClickApplyRewardCard,
      onClickRemoveRewardCard,
      isRewardApplied,
      isApplyCardBtnDisabled,
      instructionText,
      isDetailsExpanded,
      toggleDetailsExpanded,
      showMoreLimit,
      showMoreItems,
      isShowMoreVisible,
      headerTitle,
    };
  },
});
