



























































import { storeToRefs } from 'pinia';
import { computed, defineComponent, ref } from '@vue/composition-api';
import { PaymentMethodCode } from '@vf/api-client';
import { useGiftCards } from '@vf/composables';
import { useCartStore } from '@vf/composables/src/store/cartStore';
import { useOrderStore } from '@vf/composables/src/store/order';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'CheckoutAthlete',
  setup() {
    const { root } = useRootInstance();
    const { applyAthleteDiscount, removeGiftCard } = useGiftCards(root);

    const { athleteDiscounts } = storeToRefs(useCartStore());
    const { paymentMethodsData } = storeToRefs(useOrderStore());

    const applyBtnDisabled = ref(false);

    const remainingAmount = computed(() => {
      const athleteMethod = paymentMethodsData.value.find(
        ({ code }) => code === PaymentMethodCode.ATHLETES
      );
      if (!athleteMethod) return 0;
      return athleteMethod.additionalData.remainingAmount;
    });

    const appliedAmount = computed(() => {
      const [athletePayment] = athleteDiscounts.value;
      if (!athletePayment) return 0;
      return athletePayment.amount;
    });

    const onClickApplyAthleteDiscount = async () => {
      applyBtnDisabled.value = true;
      await applyAthleteDiscount();
      applyBtnDisabled.value = false;
    };

    // TODO: GLOBAL15-65058
    const onClickRemoveAthleteDiscount = async () => {
      applyBtnDisabled.value = true;
      const [{ payment_instrument_id: paymentId }] = athleteDiscounts.value;
      await removeGiftCard({ paymentId });
      applyBtnDisabled.value = false;
    };

    return {
      applyBtnDisabled,
      athleteDiscounts,
      remainingAmount,
      appliedAmount,
      onClickApplyAthleteDiscount,
      onClickRemoveAthleteDiscount,
    };
  },
});
