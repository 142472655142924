var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.theme.tableClass},[(!_vm.hideHeadings)?[_c('VfHeading',{attrs:{"title":_vm.translations.heading,"subtitle":_vm.headingSubtitle,"title-modifier":_vm.theme.headingAttributes.titleModifier,"level":_vm.level}}),_vm._v(" "),(_vm.theme.headingWithoutSubtitle)?_c('VfText',{staticClass:"data-listing-table__subheading",attrs:{"content":_vm.translations.subtitle}}):_vm._e()]:_vm._e(),_vm._v(" "),_vm._l((_vm.sortedItems),function(item){return _c('VfRadio',{key:item.id,attrs:{"selected":_vm.selectedItem,"value":item.id,"is-border-bottom":true,"label":item.label},on:{"input":function($event){return _vm.setSelectedAddress(item)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"dl-table--content"},[_c('VfRow',[_c('VfColumn',{staticClass:"dl-table--column truncate",attrs:{"small":12,"medium":2,"large":3,"small-no-vertical-margin":!_vm.theme.firstColumnSmallBottomMargin,"medium-no-vertical-margin":true,"large-no-vertical-margin":true}},[(item.icon)?_c('VfPicture',{staticClass:"dl-table__icon",style:(_vm.theme.iconSize.width && _vm.theme.iconSize.height
                  ? {
                      '--image-width': _vm.theme.iconSize.width,
                      '--image-height': _vm.theme.iconSize.height,
                    }
                  : {}),attrs:{"alt":item.name.value,"src":item.icon,"width":_vm.theme.iconSize.width,"height":_vm.theme.iconSize.height}}):_vm._e(),_vm._v(" "),_c('VfText',_vm._b({staticClass:"truncate",attrs:{"data-protected":item.name.isProtected ? '' : undefined,"content":item.name.value}},'VfText',_vm.theme.firstColumnTextAttributes,false))],1),_vm._v(" "),_c('VfColumn',{staticClass:"dl-table-column",attrs:{"small":12,"medium":4,"large":5,"small-no-vertical-margin":true,"medium-no-vertical-margin":true,"large-no-vertical-margin":true}},[_c('VfText',_vm._b({staticClass:"truncate"},'VfText',_vm.theme.baseColumnTextAttributes,false),_vm._l((item.addressLines),function(line){return _c('span',{key:line.value,staticClass:"dl-table-column__line",attrs:{"data-protected":line.isProtected ? '' : undefined}},[_vm._v("\n                "+_vm._s(line.value)+"\n              ")])}),0)],1),_vm._v(" "),_c('VfColumn',{staticClass:"dl-table-column truncate",attrs:{"small":12,"medium":_vm.getCodeColumnSize,"large":_vm.getCodeColumnSize,"small-no-vertical-margin":true,"medium-no-vertical-margin":true,"large-no-vertical-margin":true}},[_c('VfText',_vm._b({staticClass:"truncate",attrs:{"data-protected":item.code.isProtected ? '' : undefined,"content":item.code.value}},'VfText',_vm.theme.baseColumnTextAttributes,false),[_vm._v("\n              "+_vm._s(item.code.value)+"\n            ")])],1),_vm._v(" "),(_vm.showEditButton)?_c('VfColumn',_vm._b({staticClass:"dl-table-column",attrs:{"medium-no-vertical-margin":true,"large-no-vertical-margin":true,"custom-class":_vm.theme.actionColumnClass + ' dl-table-column--action-edit'}},'VfColumn',_vm.theme.actionColumnAttributes,false),[(_vm.editItemPageLinkGetter)?_c('ThemeButton',{class:[
                'vf-button',
                'vf-button--text',
                _vm.theme.actionColumnButtonClass ],attrs:{"to":_vm.editItemPageLinkGetter(item.id)}},[_c('span',{staticClass:"vf-button__container"},[_vm._v("\n                "+_vm._s(_vm.translations.editButton)+"\n              ")])]):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.showDeleteButton)?_c('VfColumn',_vm._b({staticClass:"dl-table-column",attrs:{"medium-no-vertical-margin":true,"large-no-vertical-margin":true,"custom-class":_vm.theme.actionColumnClass}},'VfColumn',_vm.theme.actionColumnAttributes,false),[_c('VfButton',{class:[
                'vf-button--text',
                'delete-button',
                _vm.theme.actionColumnButtonClass,
                _vm.buttonsSpacingLineClass ],on:{"click":function($event){return _vm.$emit('delete-item', item)}}},[_vm._v("\n              "+_vm._s(_vm.translations.deleteButton)+"\n            ")])],1):_vm._e()],1)],1)]},proxy:true}],null,true)})}),_vm._v(" "),(_vm.addItemPageLink)?_c('ThemeButton',{attrs:{"color":"primary","size":"lg","to":_vm.addItemPageLink}},[_vm._v("\n    "+_vm._s(_vm.translations.buttonText)+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.dividerBottom)?_c('VfDivider'):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }