






























































































































































































































import type { PropType } from 'vue';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import {
  computed,
  defineComponent,
  ref,
  watch,
  nextTick,
} from '@vue/composition-api';
import { useCart, useGiftOption, useValidation } from '@vf/composables';

import {
  CheckoutContext,
  CheckoutGiftOptionTranslations,
} from '@vf/api-contract';
import type { CartLineItem } from '@vf/api-client';
import useRootInstance from '@/shared/useRootInstance';
import {
  getGiftOptionItem,
  scrollIntoView,
} from '@vf/shared/src/utils/helpers';

//TODO: Remove in GLOBAL15-56318
export default defineComponent({
  name: 'GiftOptionModal',
  mixins: [validationMixin],
  props: {
    translations: {
      type: Object as PropType<CheckoutGiftOptionTranslations>,
      required: true,
    },
    /** Context to determine where Gift Option modal is rendered (cart|shipping) */
    context: {
      type: String as PropType<CheckoutContext>,
      default: CheckoutContext.Cart,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const theme = root.$themeConfig.giftOption;

    const { cartItems, updateItem, productsExcludedFromAthletes } = useCart(
      root
    );
    const { modalVisible, editingProduct, setGiftOptionItem } = useGiftOption(
      root
    );

    const updateGiftOption = async () => {
      if (editingProduct.value) {
        const data = {
          to: giftOptionTo.value,
          from: giftOptionFrom.value,
          message: giftOptionMessage.value,
          isGiftBoxSelected: isGiftBoxSelected.value,
        };
        return updateItem(getGiftOptionItem(editingProduct.value, data));
      }
    };

    const removeGiftOption = async (item: CartLineItem) => {
      return updateItem(getGiftOptionItem(item, false));
    };

    /**
     * Allow editing multiple products (from cart) in modal
     */
    const multiple = computed(
      () => props.context === 'shipping' && cartItems.value.length > 0
    );
    const giftOptionTo = ref('');
    const giftOptionFrom = ref('');
    const giftOptionMessage = ref('');
    const isGiftBoxSelected = ref(false);
    const giftBoxPrice = computed(() => {
      return root.$formatPrice(
        editingProduct.value.giftBoxPrice,
        editingProduct.value.price?.currency
      );
    });

    const { setValidation, $v } = useValidation(root, 'ADD_GIFT_OPTION');

    const validateAndBlur = (field: string) => $v.value?.[field].$touch();

    const getErrorMessage = (field: string) => {
      const validation = $v.value?.[field];
      if (field === 'giftOptionMessage' && validation && validation.$invalid)
        return props.translations.validationMessages.noSpecialCharacters;

      if (validation && validation.$invalid && !validation.required)
        return props.translations.validationMessages.required;
      else if (validation && validation.$invalid)
        return props.translations.validationMessages.noSpecialCharacters;
    };
    const saveButtonDisabled = computed<boolean>(() => $v.value?.$anyError);

    const onSave = async () => {
      $v.value?.$touch();
      if (!$v.value || $v.value.$invalid || !editingProduct.value) return;

      await updateGiftOption();
      setGiftOptionItem(null);
      modalVisible.value = multiple.value;
    };

    const onRemove = async () => {
      if (!editingProduct.value) return;
      await removeGiftOption(editingProduct.value);
      setGiftOptionItem(null);
      modalVisible.value = multiple.value;
    };

    watch(modalVisible, (val) => {
      val && $v.value?.$reset();
    });

    const closeModal = () => {
      modalVisible.value = false;
    };

    const setGiftOptionForm = (product: CartLineItem) => {
      giftOptionTo.value = product.giftOption?.to ?? '';
      giftOptionFrom.value = product.giftOption?.from ?? '';
      giftOptionMessage.value = product.giftOption?.message ?? '';
      isGiftBoxSelected.value = !!product.isGiftBoxSelected;
    };

    watch(editingProduct, (val) => {
      if (val) {
        $v.value?.$reset();
        setGiftOptionForm(val);
      }
    });

    const setGiftOption = (product: CartLineItem) => {
      setGiftOptionItem(product);
      nextTick(() => scrollIntoView('#gift-option-form'));
    };

    const hasNoSpecialChars = (value: string, field?: string): boolean => {
      if (!value) return true;

      const allowedChars =
        field === 'giftOptionMessage'
          ? /^[\wÀ-ÿ¿æœ\s\-',.!?&$]*$/
          : /^[\wÀ-ÿ¿æœ\s\-',.]*$/;

      return allowedChars.test(value);
    };

    return {
      theme,
      giftOptionTo,
      giftOptionFrom,
      giftOptionMessage,
      isGiftBoxSelected,
      charactersRemainingText: computed(() => {
        const remainingCount =
          theme.maxMessageCharacters - giftOptionMessage.value?.length ??
          theme.maxMessageCharacters;
        return props.translations.charactersRemaining.replace(
          '{{count}}',
          String(remainingCount)
        );
      }),
      addGiftBoxText: computed(() =>
        props.translations.giftBoxLabel.replace('{{price}}', giftBoxPrice.value)
      ),
      saveButtonDisabled,
      setValidation,
      validateAndBlur,
      getErrorMessage,
      modalVisible,
      multiple,
      editingProduct,
      setGiftOptionItem,
      cartItems,
      onSave,
      onRemove,
      closeModal,
      removeGiftOption, // expose for unit tests
      updateGiftOption, // expose for unit tests
      productsExcludedFromAthletes,
      setGiftOption,
      hasNoSpecialChars,
    };
  },
  mounted() {
    this.setValidation(this.$v);
  },
  validations: {
    giftOptionTo: {
      required,
      specialChars: (value, vm) => vm.hasNoSpecialChars(value),
    },
    giftOptionFrom: {
      required,
      specialChars: (value, vm) => vm.hasNoSpecialChars(value),
    },
    giftOptionMessage: {
      specialChars: (value, vm) =>
        vm.hasNoSpecialChars(value, 'giftOptionMessage'),
    },
  },
});
