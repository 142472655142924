


























































































































































import ClickOutside from 'vue-click-outside';
import { computed, ref, watch, defineComponent } from '@vue/composition-api';
import { useCart } from '@vf/composables';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'MiniCartPopUp',
  directives: {
    ClickOutside,
  },
  props: {
    icon: {
      type: String,
      default: 'bag',
    },
    translations: {
      type: Object,
      required: true,
    },
    popupData: {
      type: Object,
      required: true,
    },
    currencySymbol: {
      type: String,
      default: '',
    },
    isMinicartOpen: {
      type: Boolean,
      default: false,
    },
    context: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const { root } = useRootInstance();
    const { cart, totalItems } = useCart(root);
    const { isPricingStrikethroughEnabled } = useFeatureFlagsStore();

    const theme = root.$themeConfig.minicartPopup;
    const imageWidth = root.$themeConfig.cartProductList?.imageWidth;
    const imageHeight = root.$themeConfig.cartProductList?.imageHeight;
    const swiperMode = ref('hidden');
    const swiper = ref(null);

    const currency = computed(() => {
      return String(
        props.currencySymbol ?? cart.value.currency ?? ''
      ).toUpperCase();
    });

    const minicartProducts = computed(() => {
      return (cart.value.items || []).map((product) => {
        const { currency, current, original, priceAfterItemDiscount } =
          product.price ?? {};

        let unitPrice = root.$formatPrice(original, currency);
        let salePrice = root.$formatPrice(
          priceAfterItemDiscount / product.qty,
          currency
        );

        if (!isPricingStrikethroughEnabled || unitPrice === salePrice) {
          unitPrice = root.$formatPrice(current, currency);
          salePrice = null;
        }

        return {
          ...product,
          price: {
            unitPrice,
            salePrice,
          },
        };
      });
    });

    const cartTotal = computed(() => {
      return root.$formatPrice(
        cart.value.totals?.totalWithoutTax,
        currency.value
      );
    });

    const showNavigation = computed(() => cart.value.items?.length > 2);
    const _openMiniCart = (delay?) => {
      if (totalItems.value) {
        emit('set-mini-cart', { state: true, delay: delay });
      }
    };
    const _openMiniCartDelay = 5000;
    const openMiniCartTemporarily = () => _openMiniCart(_openMiniCartDelay);
    const closeMiniCart = () => emit('set-mini-cart', { state: false });
    const closeMiniCartOutside = () => {
      if (!root.$viewport.isSmall) {
        closeMiniCart();
      }
    };
    const removeItem = (product) => {
      emit('delete-item', product);
      swiper.value.scroller.next();
    };

    const initSwiper = (swiperInstance) => {
      if (swiperInstance?.scroller) {
        swiper.value = swiperInstance;
      }
    };

    watch(
      () => props.isMinicartOpen,
      (newValue) => {
        if (!newValue && props.popupData.showRemoveItemNotification) {
          emit('reset-show-remove-notification');
        }
        if (newValue) {
          swiperMode.value = 'visible';
        } else {
          swiperMode.value = 'hidden';
        }
      }
    );

    return {
      showNavigation,
      minicartProducts,
      cartTotal,
      openMiniCartTemporarily,
      openMiniCart: () => _openMiniCart(),
      closeMiniCart,
      removeItem,
      closeMiniCartOutside,
      imageWidth,
      imageHeight,
      totalItems,
      theme,
      swiperMode,
      initSwiper,
    };
  },
});
