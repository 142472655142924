

































































































import {
  computed,
  defineComponent,
  onBeforeUnmount,
  PropType,
  ref,
  Ref,
} from '@vue/composition-api';
import {
  useAccount,
  useAuthentication,
  useGtm,
  useNotification,
} from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import {
  Context,
  LoyaltyFinalizationType,
  LoyaltySurveyStep,
  LoyaltyConfirmationStep,
  LoyaltyQueryParamSettings,
} from '@vf/api-contract';
import useModal from '@/shared/useModal';

type Preferences = {
  preferredShoeSize?: string;
  preferredShoeSizeGender?: string;
  interests: string[];
  retailStore?: string;
};
type UserDataItem = {
  key: string;
  value: string;
};
type UserData = UserDataItem[];
type Steps = 'login' | 'join' | 'survey' | 'confirmation';

export default defineComponent({
  name: 'LoyaltyRegisterForms',
  props: {
    /** Page to redirect to After Login */
    pageToRedirectToAfterLogin: {
      type: String as PropType<string>,
      default: '',
    },
    /** Page to redirect to After Login */
    pageToRedirectToAfterRegister: {
      type: String as PropType<string>,
      default: '',
    },
    /** Display all the forms of the flow in a modal. Otherwise, all the forms are displayed as a part of the page. */
    displayAsModal: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    /** Show radio buttons/tabs on top of form to switch between login/register **/
    showTabsAboveForm: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    /** Login step bindings object */
    loginStep: {
      type: Object as PropType<Record<string, unknown>>,
      required: true,
    },
    /** Register step bindings object */
    registerStep: {
      type: Object as PropType<Record<string, unknown>>,
      required: true,
    },
    /** Survey Step bindings object */
    surveyStep: {
      type: Object as PropType<LoyaltySurveyStep>,
      required: true,
    },
    /** Display the confirmation step as part of the flow */
    confirmationStep: {
      type: [Boolean, Object] as PropType<boolean | LoyaltyConfirmationStep>,
      required: true,
    },
    /** Override Campaign Greeting text */
    campaignHeading: {
      type: String as PropType<string>,
      default: '',
    },
    /** Override Campaign Subtext text */
    campaignSubheading: {
      type: String as PropType<string>,
      default: '',
    },
    /** Show link to register form in login step **/
    loginStepShowRegistrationLink: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    /** In tabs mode, sign up tab text **/
    signUpTabText: { type: String as PropType<string>, default: '' },
    /** In tabs mode, sign in tab text **/
    signInTabText: { type: String as PropType<string>, default: '' },
    /** In tabs mode, sign up button text **/
    joinTheFamilyButtonText: { type: String as PropType<string>, default: '' },
    /** In tabs mode, sign in button text **/
    signInButtonText: { type: String as PropType<string>, default: '' },
    /** Is Modal? **/
    isModal: { type: Boolean as PropType<boolean>, default: false },
    params: {
      type: Object as PropType<LoyaltyQueryParamSettings>,
      default: () => ({} as LoyaltyQueryParamSettings),
    },
    contextKey: {
      type: String as PropType<Context>,
      default: Context.PageContent,
    },
    registerFinalizationType: {
      type: String as PropType<LoyaltyFinalizationType>,
      required: true,
    },
    loginFinalizationType: {
      type: String as PropType<LoyaltyFinalizationType>,
      required: true,
    },
    showEnrollmentAfterLoginToLegacyUsers: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    enableLoginForm: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { getFormLocation } = useGtm(root);
    const formLocation = getFormLocation(props.contextKey);
    const { loyaltyLegacyUser } = useAuthentication(root);
    const { setBasicInformation } = useAccount(root);
    const { clearNotifications } = useNotification(root);
    const { dispatchEvent } = useGtm(root);
    const { closeModal } = useModal();
    const isSaving = ref(false);

    const currentStep: Ref<Steps> = ref(
      props.enableLoginForm ? 'login' : 'join'
    );
    const flow: Ref<'login' | 'join'> = ref('login');
    const consumerData: Ref<any> = ref(null);
    // It's needed to save legacy status before enrollment
    // because success step depends on user original enrollment status
    let isLegacyEnrollment = false;
    // Track form submission for GTM events
    let isLoginSubmitted = false;
    let isRegisterSubmitted = false;

    onBeforeUnmount(() => {
      dispatchGTMEventOnFormClose();
      removeSignInQueryParam();
    });

    const dispatchGTMEventOnFormClose = () => {
      const formCloseActionMap = {
        login: 'Login',
        join: 'Registration',
      };

      if (
        (currentStep.value === 'login' && !isLoginSubmitted) ||
        (currentStep.value === 'join' && !isRegisterSubmitted)
      ) {
        // Dispatch GTM event for form close if not submitted
        dispatchEvent({
          eventName: 'loadEventData',
          overrideAttributes: {
            eventCategory: 'Account',
            eventAction: formCloseActionMap[currentStep.value],
            eventLabel: 'Close',
            nonInteractive: false,
            customMetrics: {
              elementView: 1,
            },
            customVariables: {
              formLocation,
            },
            _clear: true,
          },
        });
      }
    };

    const removeSignInQueryParam = () => {
      if (
        root.$route.query.sign_in === undefined &&
        root.$route.query.sign_up === undefined
      ) {
        return;
      }
      let query = Object.assign({}, root.$route.query);
      delete query.sign_in;
      delete query.sign_up;
      root.$router.replace({ query });
    };

    const signUp = () => {
      clearNotifications();
      currentStep.value = 'join';
      flow.value = 'join';
    };

    // Legacy User | Join Param Handling
    if (loyaltyLegacyUser.value || props.params.join) {
      signUp();
    }

    if (loyaltyLegacyUser.value) {
      isLegacyEnrollment = true;
    }

    if (root.$route.query.sign_up === '1') {
      signUp();
    }

    /**
     * Conditionally add props to preferences because the API doesn't like empty values
     * @param data
     */
    const createPreferencesObjectForSQ = (data) => {
      const preferences: Preferences = {
        interests: data.interests,
      };
      if (data.shoes?.size) {
        preferences.preferredShoeSize = data.shoes.size;
      }
      if (data.shoes?.gender) {
        preferences.preferredShoeSizeGender = data.shoes.gender;
      }
      return preferences;
    };

    const createUserDataObjectForSQ = (data) => {
      const userData: UserData = [];
      if (data.store) {
        userData.push({
          key: 'loyalty.store_number',
          value: data.store.toString(),
        });
      }
      if (data.age) {
        userData.push({ key: 'loyalty.tenure', value: data.age.toString() });
      }
      return userData;
    };

    const onLogin = () => {
      isLoginSubmitted = true;

      if (
        props.showEnrollmentAfterLoginToLegacyUsers &&
        loyaltyLegacyUser.value
      ) {
        currentStep.value = 'join';
      } else {
        finalize('login');
      }
    };

    const onSignUp = (data) => {
      isRegisterSubmitted = true;
      consumerData.value = data;

      if (props.surveyStep) {
        currentStep.value = 'survey';

        return;
      }

      finalize('signup');
    };

    const onSurveyComplete = async (data) => {
      if (isSaving.value) {
        return;
      }

      isSaving.value = true;

      if (!data) {
        console.log('no survey data provided', data);
      }
      await setBasicInformation({
        preferences: createPreferencesObjectForSQ(data),
        userData: createUserDataObjectForSQ(data),
      });

      finalize('signup');
      isSaving.value = false;
    };

    const redirectTo = (process: 'login' | 'signup') => {
      const { stlHash } = props.params;
      let path;
      if (process === 'login' && props.pageToRedirectToAfterLogin) {
        path = props.pageToRedirectToAfterLogin;
      } else if (process === 'signup' && props.pageToRedirectToAfterRegister) {
        path = props.pageToRedirectToAfterRegister;
      }
      if (props.params.pageToRedirectTo) {
        path = props.params.pageToRedirectTo;
      }

      if (path) {
        root.$router.push({
          path: path,
          query: {
            ...(stlHash && {
              stl_params_hash: stlHash,
              pageToRedirectTo: path,
            }),
          },
        });
      }
    };

    const finalize = (process: 'login' | 'signup') => {
      let type: LoyaltyFinalizationType =
        process === 'login'
          ? props.loginFinalizationType
          : props.registerFinalizationType;

      // If there is a url param pageToRedirectTo, force redirect
      if (props.params.pageToRedirectTo) {
        type = 'redirectToPage';
      }

      switch (type) {
        case 'refreshCurrentPage':
          return root.$router.go(0);
        case 'displayConfirmationStep':
          if (props.confirmationStep) {
            currentStep.value = 'confirmation';
          }
          return;
        case 'redirectToPage':
          redirectTo(process);
          return;
        case 'closeModal':
          if (props.contextKey === Context.Modal || props.displayAsModal) {
            closeModal();
          }
          return;
      }
    };

    const showTabs = computed(() => {
      return (
        props.showTabsAboveForm && ['join', 'login'].includes(currentStep.value)
      );
    });

    return {
      currentStep,
      loyaltyLegacyUser,
      flow,
      isLegacyEnrollment,
      consumerData: computed(() => consumerData.value || {}),
      signUp,
      onLogin,
      onSignUp,
      onSurveyComplete,
      showTabs,
      isSaving,
    };
  },
});
