var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"color-filters"},[_vm._l((_vm.filterOptions),function(item){return [_c('VfFilterColor',{directives:[{name:"show",rawName:"v-show",value:(item.show),expression:"item.show"}],key:item.id,attrs:{"link":_vm.getFacetLink({ code: _vm.filter.code, value: item.id }),"rel":_vm.rel,"value":_vm.getFilterItemValue(
          _vm.filter,
          item,
          _vm.displayCount,
          _vm.facetConfiguration,
          _vm.defaultFacetType
        ),"aria-label":_vm.selectedFilters.find(function (ref) {
                var value = ref.value;

                return item.label === value;
})
          ? ((_vm.translations.resetAria) + " " + (item.text))
          : ((_vm.translations.selectAria) + " " + (item.text)),"fill":item.colors,"disabled":!item.items,"active":_vm.selectedFilters.some(
          function (ref) {
                  var code = ref.code;
                  var value = ref.value;

                  return _vm.filter.code === code && item.id === value;
}
        )},on:{"click-select":function($event){return _vm.$emit('click-select', { code: _vm.filter.code, value: item.id })},"click-remove":function($event){return _vm.$emit('click-remove', { code: _vm.filter.code, value: item.id })}}})]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }