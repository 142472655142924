var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.returnOrder && _vm.returnStep && (_vm.areReturnsEnabled || _vm.isNarvarEnabled))?_c('div',{staticClass:"vf-order-returns-properties"},[(
      _vm.returnStep === _vm.ReturnStepsType.CONFIRMATION ||
      _vm.returnStep === _vm.ReturnStepsType.HISTORY_DETAILS
    )?_c('OrderSidebarProperty',{staticClass:"vf-order-returns-properties__return-label",attrs:{"name":_vm.translations.authorizationLabel,"value":_vm.returnOrder.returnNumber}}):_vm._e(),_vm._v(" "),_c('OrderSidebarProperty',{staticClass:"vf-order-returns-properties__order-label",attrs:{"name":_vm.translations.orderNumberLabel,"value":_vm.returnOrder.orderNumber}}),_vm._v(" "),(_vm.showReturnLabel)?_c('ThemeLink',{staticClass:"vf-order-returns-properties__link",attrs:{"legacy":false,"aria-label":_vm.translations.viewReturnLabel,"color":"primary","size":"md","to":_vm.returnOrder.extnReturnLabel}},[_vm._v("\n    "+_vm._s(_vm.translations.viewReturnLabel)+"\n  ")]):_vm._e(),_vm._v(" "),_c('VfDivider'),_vm._v(" "),_c('OrderSidebarProperty',{attrs:{"name":_vm.translations.itemsInReturn,"value":_vm.returnOrder.returnedItems}}),_vm._v(" "),(_vm.returnStep === _vm.ReturnStepsType.HISTORY_DETAILS)?[(_vm.showPrintPackingSlip)?_c('ThemeLink',{staticClass:"vf-order-returns-properties__link",attrs:{"legacy":false,"color":"primary","size":"md"},on:{"click":function($event){return _vm.printPackingSlip()}}},[_vm._v("\n      "+_vm._s(_vm.translations.printPackingSlipButtonText)+"\n    ")]):_vm._e(),_vm._v(" "),(_vm.showPrintReturnLabel)?_c('ThemeLink',{staticClass:"vf-order-returns-properties__link",attrs:{"legacy":false,"color":"primary","size":"md"},on:{"click":function($event){return _vm.printReturnLabel(_vm.returnOrder.orderHeaderKey)}}},[_vm._v("\n      "+_vm._s(_vm.translations.printReturnLabelButtonText)+"\n    ")]):_vm._e()]:_vm._e(),_vm._v(" "),_c('VfDivider'),_vm._v(" "),_c('OrderSidebarProperty',{attrs:{"name":_vm.translations.itemCreditSubtotal,"value":_vm.formatPrice(_vm.returnOrder.overallTotals.lineSubTotal)}}),_vm._v(" "),_c('OrderSidebarProperty',{attrs:{"name":_vm.translations.discountLabel,"value":("-" + (_vm.formatPrice(
      _vm.returnOrder.overallTotals.grandDiscount -
        (_vm.returnOrder.overallTotals.hdrDiscount || 0)
    )))}}),_vm._v(" "),_c('OrderSidebarProperty',{attrs:{"name":_vm.translations.shippingCreditLabel,"value":_vm.formatPrice(_vm.returnOrder.overallTotals.grandShippingBaseCharge)}}),_vm._v(" "),_c('OrderSidebarProperty',{attrs:{"name":_vm.translations.shippingTaxLabel,"value":_vm.formatPrice(_vm.returnOrder.overallTotals.grandShippingCharges)}}),_vm._v(" "),(_vm.returnFee > 0)?_c('OrderSidebarProperty',{attrs:{"name":_vm.translations.returnShippingCostLabel,"value":("-" + (_vm.formatPrice(_vm.returnFee)))}}):_vm._e(),_vm._v(" "),_c('OrderSidebarProperty',{attrs:{"name":_vm.translations.salesTaxCreditLabel,"value":_vm.formatPrice(_vm.returnOrder.overallTotals.grandTax)}}),_vm._v(" "),_c('OrderSidebarProperty',{attrs:{"name":_vm.translations.returnTotalLabel,"value":_vm.formatPrice(_vm.returnOrder.overallTotals.grandTotal)}}),_vm._v(" "),_c('VfDivider'),_vm._v(" "),_c('OrderSidebarProperty',{attrs:{"name":_vm.translations.issuedToLabel,"value":_vm.returnOrder.extnIsRefundOnGiftCard
        ? _vm.translations.giftCard
        : _vm.translations.originaleMethod}})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }