










import useRootInstance from '@/shared/useRootInstance';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'Placement',
  props: {
    name: {
      type: String,
      default: '',
    },
    hasItems: {
      type: Boolean,
      default: true,
    },
    isInLayout: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const { root } = useRootInstance();
    const metaDataForPreview = (
      placement,
      isInLayout = true,
      hasItems = true
    ) => {
      if (!root.$isPreview) return;
      const metaData = [
        { _: `properties.placement-${placement}` },
        {
          placementRequest: [
            {
              isInLayout: isInLayout,
              hasItems: hasItems,
              placementName: placement,
            },
          ],
        },
      ];
      return JSON.stringify(metaData);
    };

    return {
      metaDataForPreview,
    };
  },
});
