import { Price } from '..';
import { ProductImage, ProductVariant } from '@vf/api-client/src/types';

export type UpsellsItemPrice = Omit<Price, 'amount' | 'discount'>;
export type UpsellsItemImage = Omit<ProductImage, 'thumbnail'>;
export type UpsellsItemVariant = Omit<
  ProductVariant,
  'attributes' | 'id' | 'price' | 'stock'
>;

export interface UpsellsItem {
  id: string;
  name: string;
  itemGroupId: string;
  price: UpsellsItemPrice;
  custom: boolean;
  upsell: boolean;
  quantity: number;
  image: UpsellsItemImage;
  pageUrl: string;
  sku: string;
  savedForLater: boolean;
  description: string;
  variant: UpsellsItemVariant;
}

export type UpsellWrapper = {
  product: UpsellProduct;
  type: UpsellType;
};

export type UpsellProduct = {
  id: string;
  name: string;
  itemGroupId: string;
  description: string;
  attributes: {
    [key: string]: string;
  };
  quantity: number;
  price: {
    original: number;
    current: number;
    currency: string;
  };
  custom: boolean;
  upsell: boolean;
  image: {
    url: string;
    alt: string;
  };
  savedForLater: boolean;
  pageUrl: string;
  recToken?: string;
};

export enum UpsellType {
  Single = 'singleSized',
  Multi = 'multiSized',
}
