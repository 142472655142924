
import { CreateElement, RenderContext, VNode } from 'vue';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'CarouselSlide',
  functional: true,
  render(h: CreateElement, context: RenderContext): VNode {
    return h(
      'li',
      {
        class: 'carousel-slide',
        attrs: {
          'data-testid': 'theme-smart-carousel-slide',
        },
      },
      context.slots().default
    );
  },
});
