












import type { PropType } from 'vue';
import type {
  ProfileSummaryCardTranslations,
  InterestsArray,
} from '@vf/api-contract';
import { defineComponent, computed } from '@vue/composition-api';
import { useAccount, useAuthentication } from '@vf/composables';
import VfProfileSummary from '@vf/ui/components/Organism.ProfileSummary.vue';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'ProfileSummaryCard',
  components: { VfProfileSummary },
  props: {
    translations: {
      type: Object as PropType<ProfileSummaryCardTranslations>,
      required: true,
    },
    link: String,
    interests: Array as PropType<InterestsArray[]>,
  },
  setup(props) {
    const { root } = useRootInstance();
    const { basicInformation } = useAccount(root);
    const { isWranxProsumer, isIpaProsumer } = useAuthentication(root);

    const extractInterests = (keys: string) => {
      return keys
        .split('|')
        .reduce((acc, key) => {
          const interest = props.interests.find((i) => i.key === key);

          if (interest) {
            acc.push(interest.label);
          }

          return acc;
        }, [])
        .join(', ');
    };

    const interestsText = computed(() => {
      const interests = basicInformation.value.preferences?.interests;
      return interests ? extractInterests(interests) : '';
    });

    const fullName = computed(() => {
      return `${basicInformation.value.firstName} ${basicInformation.value.lastName}`;
    });

    const loading = computed(() => !basicInformation.value.email);

    const isEditDisabled = computed(() => {
      return loading.value || isIpaProsumer.value || isWranxProsumer.value;
    });

    return {
      fullName,
      basicInformation,
      interestsText,
      loading,
      isEditDisabled,
    };
  },
});
