






import { computed, defineComponent, PropType } from '@nuxtjs/composition-api';
import useCms from '@vf/composables/src/useCms';
import { Filter } from '@vf/composables/src/types';
import { PageMetaData } from '@vf/api-client/src/types';
import useRootInstance from '@/shared/useRootInstance';
import {
  isFilterFollowable,
  isPageIndexable,
} from '@vf/shared/src/utils/helpers/seo';
import { useFilters } from '@vf/composables';

export default defineComponent({
  name: 'Filters',
  props: {
    contextKey: {
      type: String,
      required: true,
    },
    selectedFilters: {
      type: Array as PropType<Filter[]>,
      required: true,
    },
    sticky: {
      type: Boolean,
      required: true,
    },
    meta: {
      type: Object as PropType<PageMetaData>,
      default: () => ({}),
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { showFiltersBar } = useFilters(root);
    const { facetConfiguration } = useCms(root, props.contextKey);

    const isFollowable = (filterCode) =>
      isFilterFollowable(filterCode, props.selectedFilters);

    const isIndexable = computed(() =>
      isPageIndexable(props.selectedFilters, facetConfiguration.value)
    );

    const getFilterRelValue = (filterCode: string): null | 'nofollow' => {
      if (
        isFollowable(filterCode) &&
        facetConfiguration.value[filterCode]?.crawlable
      ) {
        return null;
      }

      return 'nofollow';
    };

    const filtersMeta = computed(() => ({
      hid: 'robots',
      name: 'robots',
      content: props.meta.robots
        ? props.meta.robots.content
        : `${isIndexable.value ? 'index' : 'noindex'}, follow`,
    }));

    return {
      getFilterRelValue,
      filtersMeta,
      wrappingComponent: computed(() => (props.sticky ? 'VfSticky' : 'div')),
      showFiltersBar,
    };
  },
  head() {
    return {
      meta: [this.filtersMeta],
    };
  },
});
