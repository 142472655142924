
















import { storeToRefs } from 'pinia';
import { defineComponent, ref } from '@vue/composition-api';
import { useCart, useGtm } from '@vf/composables';
import { CheckoutStepNumber } from '@vf/composables/src/types/gtm';
import { useCartStore } from '@vf/composables/src/store/cartStore';
import CheckoutShippingGroup from '@/components/static/checkout/CheckoutShippingGroup.vue';
import useRootInstance from '@/shared/useRootInstance';
import { nextTick } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'CheckoutShippingMethods',
  components: {
    CheckoutShippingGroup,
  },
  setup() {
    const { root } = useRootInstance();

    const { setShippingMethod } = useCart(root);
    const cartStore = useCartStore();
    const { cartShipments } = storeToRefs(cartStore);

    const { dispatchEvent } = useGtm(root);

    const radioGroup = ref(null);

    const isChangeMethodInProgress = ref({
      me: false,
      customs: false,
    });

    const updateShippingMethod = async (shippingId, { code, label }) => {
      isChangeMethodInProgress.value[shippingId] = true;
      await setShippingMethod({
        code,
        shippingId,
        shopperAppliedSM: true,
      });
      isChangeMethodInProgress.value[shippingId] = false;
      nextTick(() => {
        radioGroup.value[0].$el
          .querySelector(`[value="${code}"]`)
          ?.closest('label')
          ?.focus();
      });
      dispatchEvent({
        eventName: 'checkoutOption',
        overrideAttributes: {
          step: CheckoutStepNumber.SHIPPING,
          option: `Shipping Method: ${label}`,
        },
      });
    };

    return {
      radioGroup,
      updateShippingMethod,
      isChangeMethodInProgress,
      cartShipments,
    };
  },
});
