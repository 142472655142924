











import type { PropType } from 'vue';
import { computed, defineComponent } from '@vue/composition-api';
import Notifications from '../layout/Notifications.vue';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';
import useRootInstance from '@/shared/useRootInstance';
import type { AccountSubNavigationItem } from '@vf/api-contract';
import { useNotification } from '@vf/composables';

export default defineComponent({
  name: 'AccountSubNavigation',
  serverCacheKey: getCacheKeyFromProps,
  components: {
    Notifications,
  },
  props: {
    items: {
      type: Array as PropType<AccountSubNavigationItem[]>,
      default: () => [],
    },
    /** Separator for navigation items */
    separator: {
      type: String,
      default: '/',
    },
  },
  setup(props) {
    const { root } = useRootInstance();

    const { hasChangePasswordNotification } = useNotification(root);

    const activeItemName = computed(() => {
      const pathDepth = props.items[0]?.url.split('/').length + 1;
      const routePath = root.$route.path
        .split('/')
        .slice(0, pathDepth)
        .join('/');

      if (props.items.find((item) => item.url.includes(routePath))) {
        return routePath;
      }

      return props.items.find((item) => item.url.includes('order'))?.url || '';
    });

    const navItems = computed(() =>
      props.items
        .map((item) => {
          return {
            ...item,
            stateFulfilled:
              root.$stateManagementChecker(item._states) &&
              root.$segmentsChecker(item._segments),
          };
        })
        .filter((i) => i.stateFulfilled)
    );

    const noPasswordNotification = computed(() => {
      return !hasChangePasswordNotification.value;
    });

    return {
      activeItemName,
      navItems,
      noPasswordNotification,
    };
  },
});
