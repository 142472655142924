

























































































































































import { PropType, inject } from '@vue/composition-api';
import { focus } from '@vf/shared/src/utils/directives';

export default {
  name: 'VfInput',
  directives: { focus },
  inheritAttrs: false,
  props: {
    /**
     * Current input value (`v-model`)
     */
    value: {
      type: [String, Number],
      default: null,
    },
    /**
     * Form input label
     */
    label: {
      type: String,
      required: true,
    },
    /**
     * Form show input label
     */
    showLabel: {
      type: Boolean,
      default: true,
    },
    /**
     * Form input accessibility label
     */
    ariaLabel: {
      type: String,
    },
    /**
     * Form input name
     */
    name: {
      type: String,
      default: null,
    },
    /**
     * Form input type
     */
    type: {
      type: String,
      default: 'text',
    },
    /**
     * Native input required attribute
     */
    required: {
      type: Boolean,
      default: false,
    },
    /**
     * Native input disabled attribute
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Validate value of form input
     */
    valid: {
      type: Boolean,
      default: true,
    },
    /**
     * Error message value of form input. It will be appeared if `valid` is `true`.
     */
    errorMessage: {
      type: String,
      default: null,
    },
    /**
     * Helper text value of form input. It will be appeared if `valid` is `true`.
     */
    helperText: {
      type: String,
      default: null,
    },
    /**
     * Status of show password icon display
     */
    hasShowPassword: {
      type: Boolean,
      default: false,
    },
    showPasswordText: {
      type: String as PropType<string>,
      default: '',
    },
    hidePasswordText: {
      type: String as PropType<string>,
      default: '',
    },
    /**
     * Status of show validation icons
     */
    validationIconsVisibility: {
      type: Boolean,
      default: true,
    },
    /* Right Icon */
    icon: {
      type: String,
      default: '',
    },
    iconSize: {
      type: String,
      default: '.9rem',
    },
    /**
     * Notification with info icon
     */
    notification: {
      type: String,
      default: '',
    },
    /**
     * Close tooltip button
     */
    tooltipCloseText: {
      type: String,
      default: undefined,
    },
    stickyTooltip: {
      type: Boolean,
      default: false,
    },
    submitButtonText: {
      type: String,
      default: 'Submit',
    },
    submitButtonType: {
      type: String,
      default: 'submit',
    },
    subscription: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    // TODO: GLOBAL15-61059 remove after core redesign
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');
    return {
      isCoreRedesignEnabled,
    };
  },
  data() {
    return {
      isPasswordVisible: false,
      inputType: '',
      isNumberTypeSafari: false,
      showValidationIcon: false,
      showTooltip: false,
      focused: false,
      autoFilled: false,
      isSubscription: false,
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: (event) => this.$emit('input', event.target.value),
      };
    },
    isPassword() {
      return this.type === 'password' && this.hasShowPassword;
    },
    hasPasswordVisibilityToggle() {
      return this.showPasswordText && this.hidePasswordText;
    },
    isRightIconVisible() {
      return (
        this.validationIconsVisibility &&
        (!this.valid ||
          this.showSuccessIcon ||
          this.hasNotificationContent ||
          this.notification)
      );
    },
    showSuccessIcon() {
      return !!this.value && this.valid && this.showValidationIcon;
    },
    hasNotificationContent() {
      return this.$slots['notificationContent'];
    },
    infoIcon() {
      return this.$themeConfig?.input?.infoIcon || 'info';
    },
    labelClasses() {
      return ['vf-input__label', !this.showLabel && 'sr-only'];
    },
    inputName() {
      const subscriptionField = this.isSubscription
        ? `subscription-${this.name}`
        : this.name;
      return subscriptionField?.replace(/\s/g, '').toLocaleLowerCase() || null;
    },
    errorMessageId() {
      return !this.valid && `${this.inputName}-error-message`;
    },
  },
  watch: {
    type: {
      immediate: true,
      handler: function (type) {
        let inputType = type;
        // Safari has bug for number input
        if (typeof window !== 'undefined' || typeof document !== 'undefined') {
          const ua = navigator.userAgent.toLocaleLowerCase();
          if (
            ua.indexOf('safari') !== -1 &&
            ua.indexOf('chrome') === -1 &&
            type === 'number'
          ) {
            this.isNumberTypeSafari = true;
            inputType = 'text';
          }
        }
        this.inputType = inputType;
      },
    },
    value: {
      immediate: true,
      handler: function (value) {
        if (!this.isNumberTypeSafari) return;
        if (isNaN(value)) {
          this.$emit('input');
        }
      },
    },
  },
  mounted() {
    this.isSubscription = this.subscription;
    this.$nextTick(() => {
      this.checkAutoFilling();
    });
  },
  methods: {
    togglePasswordVisibility() {
      this.isPasswordVisible = !this.isPasswordVisible;
      this.inputType = this.isPasswordVisible ? 'text' : 'password';
    },
    submit() {
      this.$emit('submit', this.value);
    },
    focusHandler() {
      this.focused = true;
    },
    blurHandler() {
      this.focused = false;
      this.showValidationIcon = true;
    },
    isAutoFilled() {
      const input = document.querySelector(`[id*="${this.inputName}"]`);
      if (!input) return false;
      return (
        window.getComputedStyle(input, null).getPropertyValue('appearance') ===
        'menulist-button'
      );
    },
    handleAutoFilling() {
      return new Promise((resolve) => {
        setTimeout(() => resolve(this.isAutoFilled()), 0);
      });
    },
    async checkAutoFilling() {
      this.autoFilled = await this.handleAutoFilling();
      if (this.autoFilled) {
        this.focused = true;
      }
    },
  },
};
