






















import type { PropType } from 'vue';
import { onMounted, ref, defineComponent } from '@vue/composition-api';
import type { IconPosition, Icons } from '@vf/api-contract';
import { useCustoms, useI18n, useProduct, ROUTES } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import ProductCustomizeCta from '../../pdp/ProductCuztomizeCta.vue';

export default defineComponent({
  name: 'ProductCustomizeByRecipeId',
  components: { ProductCustomizeCta },
  props: {
    enabled: {
      type: Boolean,
      default: true,
    },
    /** Button text */
    text: {
      type: String,
      default: '',
    },
    icon: {
      type: String as PropType<Icons>,
      default: 'customize',
    },
    /** Button style */
    buttonStyle: {
      type: Object as PropType<{ color: string; variant?: string }>,
      default: () => ({ color: 'primary' }),
    },
    /** Button size  */
    buttonSize: {
      type: String as PropType<'sm' | 'md' | 'lg'>,
      default: 'md',
    },
    iconPosition: {
      type: String as PropType<IconPosition>,
      default: '',
    },
    /** Flag to determine if button text should be underline */
    underline: {
      type: Boolean,
      default: false,
    },
    /** CSS class modifiers for text from teaser styles */
    textCustomClasses: {
      type: String,
      default: '',
    },
    /** Customs Recipe Id */
    recipeId: {
      type: String,
      default: '',
    },
    isFullWidth: {
      type: Boolean,
      default: false,
    },
    buttonComponent: {
      type: String,
      required: true,
    },
    contextKey: {
      type: String,
      default: 'product',
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { localePath } = useI18n(root);
    const { customsDataByRecipe } = useCustoms(root);
    const { setInitialColor } = useProduct(root, props.contextKey);
    const customizerLink = ref('');

    onMounted(async () => {
      const product = await customsDataByRecipe(props.recipeId);
      if (!product?.pdm) {
        return;
      }
      customizerLink.value = localePath(
        `${ROUTES.CUSTOMIZER()}.${product.pdm}.html?recipe=${props.recipeId}`
      );
      if (!product.color) {
        setInitialColor();
      }
    });

    return {
      customizerLink,
    };
  },
});
