var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isVisible)?_c('div',{staticClass:"vf-widget-recommendations"},[_c('h3',{staticClass:"vf-widget-recommendations__title",class:{
      'vf-widget-recommendations--redesign__title': _vm.isVansPlpRedesignEnabled,
    }},[_vm._v("\n    "+_vm._s(_vm.$t('seoRelatedWidgetTextOnly.relatedItems'))+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"vf-widget-recommendations__pills",class:{ 'vf-widget-recommendations__hidden': _vm.hasOverflow },style:(_vm.slideHeight === 'auto'
        ? ("height: " + _vm.slideHeight)
        : ("height: " + _vm.slideHeight + "px"))},_vm._l((_vm.seoConsolidatedList),function(item){return _c('span',{key:item.itemKey,staticClass:"vf-widget-recommendations__pill"},[_c('ThemeLink',{staticClass:"vf-widget-recommendations__pill--link",attrs:{"to":item.itemUrl}},[_vm._v("\n        "+_vm._s(item.itemLabel)+"\n      ")])],1)}),0),_vm._v(" "),(_vm.hasOverflow)?_c('div',{staticClass:"vf-widget-recommendations__show-hide"},[(_vm.hasOverflow)?_c('button',{staticClass:"vf-button vf-button--text",attrs:{"role":"button","aria-label":_vm.hide
          ? _vm.$t('seoRelatedWidgetTextOnly.showMore')
          : _vm.$t('seoRelatedWidgetTextOnly.showLess')},on:{"click":_vm.showHide}},[_vm._v("\n      "+_vm._s(_vm.hide
          ? _vm.$t('seoRelatedWidgetTextOnly.showMore')
          : _vm.$t('seoRelatedWidgetTextOnly.showLess'))+"\n    ")]):_vm._e()]):_vm._e()]):_c('div')}
var staticRenderFns = []

export { render, staticRenderFns }