








import { useKlarna } from '@vf/composables';
import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  watch,
} from '@vue/composition-api';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'Klarna',
  setup() {
    const { root } = useRootInstance();
    const { isCheckoutRedesignEnabled } = useFeatureFlagsStore();
    const { isKlarnaInitialized, loadKlarnaPayment } = useKlarna(root);

    onMounted(() => {
      if (isKlarnaInitialized.value) loadKlarnaPayment();
    });

    watch(isKlarnaInitialized, () => {
      loadKlarnaPayment();
    });

    onBeforeUnmount(() => {
      isKlarnaInitialized.value = false;
    });

    return {
      isCheckoutRedesignEnabled,
    };
  },
});
