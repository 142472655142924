
























































































































































































































































































































































































































































































































































































import type { PropType } from 'vue';
import {
  computed,
  defineComponent,
  ComputedRef,
  inject,
} from '@vue/composition-api';
import type {
  ParsedProductReview,
  ProductReviewTag,
  ReviewsTranslations,
} from '@vf/api-contract';
import VfHistogram from './Molecule.Histogram.vue';
import VfFitScale from './Atom.FitScale.vue';
import VfFlagReview from './Organism.FlagReview.vue';
import VfReviewUgc from './Organism.ReviewUgc.vue';
import { scrollIntoView } from '@vf/shared/src/utils/helpers';
import { pluralize } from '../../theme/helpers/pluralize';

export default defineComponent({
  name: 'VfReviews',
  components: {
    VfHistogram,
    VfFitScale,
    VfFlagReview,
    VfReviewUgc,
  },
  props: {
    translations: {
      type: Object as PropType<ReviewsTranslations>,
      default: () => ({
        heading: '',
        writeReview: '',
        reviewsCount: {
          singular: '',
          plural: '',
        },
        recommendationsLabel: '',
        ratingDistribution: {
          heading: '',
          starsCount: '',
          tooltip: '',
        },
        proConTagTooltip: '',
        pros: {
          heading: '',
          emptyText: '',
        },
        cons: {
          heading: '',
          emptyText: '',
        },
        mostLikedReadComplete: '',
        mostLikedReadLess: '',
        mostLikedPositiveHeading: '',
        mostLikedNegativeHeading: '',
        flagReviewForm: {
          flagReviewNotFlagged: '',
          flagReviewFlagged: '',
          flagUGCFlagged: '',
          flagUGCNotFlagged: '',
          heading: '',
          emailPlaceholder: '',
          emailRequiredText: '',
          emailInvalidText: '',
          commentsPlaceholder: '',
          flagButtonLabel: '',
          cancelButtonLabel: '',
          flagTypes: {
            profane: '',
            wrong_product: '',
            spam: '',
            duplicate: '',
            copyright: '',
            not_review: '',
            customer_image: '',
            other: '',
          },
          helpfulVoteLabel: '',
          unhelpfulVoteLabel: '',
        },
        tags: {
          more: '',
          less: '',
        },
        list: {
          heading: '',
          filtersApplied: '',
          sortingAriaLabel: '',
          merchantResponse: '',
          bottomLine: '',
          helpfulLine: '',
          displayingReviews: '',
          backToTop: '',
          prevPage: '',
          nextPage: '',
          noResults: '',
        },
        submittedBy: {
          submittedLabel: '',
          submittedByLabel: '',
          fromLabel: '',
          verifiedBuyerLabel: '',
        },
        reviewCategories: {
          moreDetails: '',
          lessDetails: '',
        },
        sorting: {
          lowestRating: '',
          highestRating: '',
          mostHelpful: '',
          oldest: '',
          newest: '',
          mediaSort: '',
        },
        bottomLine: {
          recommended: '',
          notRecommended: '',
        },
      }),
    },
    review: {
      type: Object as PropType<ParsedProductReview>,
      default: () => ({
        score: 5,
        reviewsCount: 0,
        recommendationsCount: 0,
        ratingDistribution: [],
        sizing: { value: 0 },
        pros: [],
        cons: [],
        width: 0,
        mostLikedPositive: {
          headline: '',
          comments: '',
          rating: 5,
        },
        mostLikedNegative: {
          headline: '',
          comments: '',
          rating: 5,
        },
        tags: [
          {
            heading: '',
            key: '',
            tags: [],
            minLabel: '',
            maxLabel: '',
          },
        ],
        sorting: {
          sortBy: '',
          options: {},
        },
        reviews: [],
        pagination: {
          totalResults: 0,
          pagesTotal: 0,
          pageSize: 0,
          currentPageNumber: 0,
          nextPageUrl: '',
        },
        filters: [],
      }),
    },
    reducedListCount: {
      type: Number,
      required: true,
    },
    showWriteReviewCta: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    'flag-review-submit',
    'click:write-review',
    'click:helpful-thumb-up',
    'click:helpful-thumb-down',
  ],
  setup(props, { root, emit }) {
    const showMostLikedSection =
      root.$themeConfig.reviews?.showMostLikedSection ?? true;
    const showSubmittedByBeforeDesc =
      root.$themeConfig.reviews?.showSubmittedByBeforeDesc ?? false;
    const showPaginationIcons =
      root.$themeConfig.reviews?.showPaginationIcons ?? false;
    const showStarsIndicator =
      root.$themeConfig.reviews?.showStarsIndicator ?? true;
    const paginationIconPrevious =
      root.$themeConfig.reviews?.paginationIconPrevious ?? 'arrow_left';
    const paginationIconNext =
      root.$themeConfig.reviews?.paginationIconNext ?? 'arrow_right';
    const useWriteAReviewButton =
      root.$themeConfig.reviews?.useWriteAReviewButton ?? false;

    const showPaginationSeperator = computed(
      () =>
        root.$themeConfig.reviews?.showPaginationSeperator &&
        props.review.pagination.currentPageNumber > 1 &&
        props.review.pagination.currentPageNumber <
          props.review.pagination.pagesTotal
    );
    const paginationInfo: ComputedRef<string> = computed(() => {
      const {
        currentPageNumber,
        pageSize,
        totalResults,
      } = props.review.pagination;
      const totalsRest: number =
        totalResults < pageSize ? totalResults : currentPageNumber * pageSize;
      return `${(currentPageNumber - 1) * pageSize + 1}-${totalsRest}`;
    });
    const showMostLikedReviews: ComputedRef<boolean> = computed(() => {
      const positives = props.review.mostLikedPositive || {};
      const negatives = props.review.mostLikedNegative || {};
      return (
        props.review.reviews?.length > 1 &&
        !!Object.keys(positives).length &&
        !!Object.keys(negatives).length &&
        showMostLikedSection
      );
    });

    // TODO: GLOBAL15-61059 remove after redesign core
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');

    const getRatingValue = (rate: number, ratings): number => {
      const starsCount = ratings.reduce((val, nextVal) => {
        val += nextVal.value;
        return val;
      }, 0);
      return (rate / starsCount) * 100;
    };

    const getTagLabels = (tagKey: string): Partial<ProductReviewTag> => {
      const tag = props.review.tags?.find(({ key }) => key === tagKey);
      if (!props.review[tagKey] || !tag) return {};
      return {
        heading: tag.heading,
        value: props.review[tagKey],
        minLabel: tag.minLabel,
        maxLabel: tag.maxLabel,
      };
    };

    const sizing: ComputedRef<Partial<ProductReviewTag>> = computed(() => {
      // iterate through the old api keys in case if there's no sizing
      const keys = ['sizing', 'fit', 'fit_old'];

      for (const key of keys) {
        const tag = props.review.tags?.find((t) => t.key === key);

        if (tag) {
          return {
            heading: tag.heading,
            value: props.review.sizing,
            minLabel: tag.minLabel,
            maxLabel: tag.maxLabel,
          };
        }
      }

      return {};
    });

    const sleevelength: ComputedRef<Partial<ProductReviewTag>> = computed(() =>
      getTagLabels('sleevelength')
    );

    const chestsize: ComputedRef<Partial<ProductReviewTag>> = computed(() =>
      getTagLabels('chestsize')
    );

    const width: ComputedRef<Partial<ProductReviewTag>> = computed(() =>
      getTagLabels('width')
    );

    const hasTagLabel = (tag: object): boolean => {
      return Object.keys(tag).length !== 0;
    };

    const getReviewBottomLine = (bottomLine: string): string => {
      if (bottomLine === 'Yes') {
        return props.translations.bottomLine.recommended;
      }
      if (bottomLine === 'No') {
        return props.translations.bottomLine.notRecommended;
      }
      return '';
    };

    const reviewListHeading = computed(() =>
      props.translations.list.heading.replace(
        '{0}',
        '<span>' + props.review.pagination.totalResults + '</span>'
      )
    );
    const reviewsCount = computed(() =>
      props.review.ratingDistribution.reduce(
        (obj, item) => ((obj[item.stars] = item.value), obj),
        {}
      )
    );

    const getDisclosureLabel = (disclosureCode: string) => {
      if (disclosureCode === 'sweepstakes')
        return props.translations.list.disclosureLabelSweepstakes;
      return '';
    };

    const getAppliedFilterCounter = (
      filterKey: string,
      filterValue: string
    ): number => {
      if (filterKey === 'rating') return reviewsCount.value[filterValue];

      const property = props.review.rollup?.properties?.find(
        (property) => property.key === filterKey
      );

      if (property) {
        const appliedFilter = property.values.find(
          (value) => value.label === filterValue
        );
        if (appliedFilter) return appliedFilter.count;
      }
      return 0;
    };

    const addFilter = (key, value) => {
      emit('click:add-filter', {
        key,
        value: [value],
      });
      scrollIntoView('#reviews__list-top-bar');
    };

    return {
      paginationInfo,
      showMostLikedReviews,
      getRatingValue,
      sizing,
      width,
      getAppliedFilterCounter,
      getReviewBottomLine,
      reviewListHeading,
      hasTagLabel,
      showMostLikedSection,
      showSubmittedByBeforeDesc,
      showPaginationSeperator,
      showPaginationIcons,
      scrollIntoView,
      paginationIconPrevious,
      paginationIconNext,
      pluralize,
      sleevelength,
      chestsize,
      reviewsCount,
      showStarsIndicator,
      getDisclosureLabel,
      addFilter,
      isCoreRedesignEnabled,
      useWriteAReviewButton,
    };
  },
});
