

























import { defineComponent, PropType } from '@vue/composition-api';

export default defineComponent({
  name: 'CheckoutProductsPreviewList',
  props: {
    heading: {
      type: String,
      default: '',
    },
    products: {
      type: Array,
      default: () => [],
    },
    withLabel: {
      type: Boolean,
      default: false,
    },
    context: {
      type: String as PropType<'shipping' | 'pickup'>,
      required: true,
    },
  },
  setup(props) {
    const getLabel = (product) => {
      switch (props.context) {
        /** Get name of store where product will be picked up */
        case 'pickup': {
          return (
            product?.shippingOptions?.find(
              (option) => option.selected && !!option.storeInfo?.name
            )?.storeInfo?.name || ''
          );
        }

        /** No need for label in case of STH */
        case 'shipping':
        default:
          return '';
      }
    };

    return {
      getLabel,
    };
  },
});
