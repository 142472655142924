var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VfCollapsible',{staticClass:"vf-checkout-athlete",attrs:{"header":_vm.$t('checkoutAthlete.title'),"is-open":""}},[_c('VfText',{staticClass:"vf-checkout-athlete__description",attrs:{"content":_vm.athleteDiscounts.length
        ? _vm.$t('checkoutAthlete.balanceMessage')
        : _vm.$t('checkoutAthlete.applyMessage'),"font-sizes":['base']}}),_vm._v(" "),_c('VfRow',[_c('VfColumn',{attrs:{"small":12,"medium":12,"large":12}},[_c('VfText',{attrs:{"font-weight":"bold","font-sizes":['base'],"content":_vm.$t('checkoutAthlete.amount')}}),_vm._v(" "),_c('VfText',{attrs:{"font-weight":"normal","font-sizes":['base'],"content":_vm.athleteDiscounts.length
            ? _vm.$formatPrice(_vm.remainingAmount - _vm.appliedAmount)
            : _vm.$formatPrice(_vm.remainingAmount)}})],1)],1),_vm._v(" "),_c('VfRow',[_c('VfColumn',{attrs:{"small":12,"medium":12,"large":12}},[_c('ThemeButton',{staticClass:"vf-checkout-athlete__cta",attrs:{"size":"xs","color":"primary","variant":_vm.athleteDiscounts.length ? 'border' : '',"disabled":_vm.applyBtnDisabled || !_vm.remainingAmount},on:{"click":function($event){_vm.athleteDiscounts.length
            ? _vm.onClickRemoveAthleteDiscount()
            : _vm.onClickApplyAthleteDiscount()}}},[_vm._v("\n        "+_vm._s(_vm.athleteDiscounts.length
            ? _vm.$t('checkoutAthlete.remove')
            : _vm.$t('checkoutAthlete.apply'))+"\n      ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }