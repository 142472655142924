//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { onMounted, computed } from '@vue/composition-api';
import LoyaltyRewardsProgressBar from './LoyaltyRewardsProgressBar.vue';
import LoyaltyRewardsExpiry from './LoyaltyRewardsExpiry.vue';
import { scrollTo as scrollToTop } from '@vf/shared/src/utils/helpers';
import useRootInstance from '@/shared/useRootInstance';

export default {
  name: 'LoyaltyRewardsProgressAccordion',
  components: {
    LoyaltyRewardsProgressBar,
    LoyaltyRewardsExpiry,
  },
  props: {
    greeting: {
      type: String,
      default: '',
    },
    totalRewards: {
      type: String,
      default: '',
    },
    summary: {
      type: String,
      default: '',
    },
    vouchers: {
      type: Array,
      default: () => [],
    },
    full: {
      type: Boolean,
      default: false,
    },
    rewards: {
      type: Object,
    },
  },
  setup(props) {
    const { root } = useRootInstance();

    const hasExpiringPoints = computed(
      () =>
        props.rewards &&
        props.rewards.expiringLoyaltyPoints &&
        props.rewards.expiringLoyaltyPointsDays <= 30
    );

    onMounted(() => {
      // no need for a computed prop here - queried node is in the parent wrapper structure
      const containerNode = document.querySelector('.vf-header__content');

      root.$eventBus.$on('simplified-enrollment-login', () => {
        if (hasExpiringPoints.value && root.$viewport.isSmall) {
          scrollToTop({
            target: containerNode,
          });
        }
      });
    });

    return {
      hasExpiringPoints,
    };
  },
};
