







































import type { PropType } from 'vue';
import { computed, onMounted, ref } from '@vue/composition-api';
import { useAccount, useUtilities } from '@vf/composables/src';
import useRootInstance from '../../../shared/useRootInstance';
import type { LanguagePreferencesFormTranslations } from '@vf/api-contract';
import { useI18n, useNotification } from '@vf/composables';

interface LocaleData {
  currency: string;
  link: string;
  locale: string;
  localeName: string;
  code?: string;
}

interface Locale {
  displayName: string;
  locales: LocaleData[];
}

export default {
  name: 'LanguagePreference',
  props: {
    locales: {
      type: Array as PropType<Locale[]>,
    },
    translations: {
      type: Object as PropType<LanguagePreferencesFormTranslations>,
      required: true,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const {
      basicInformation,
      setBasicInformation,
      setCountryPreferences,
    } = useAccount(root);
    const { localeCode } = useI18n(root);
    const { getCountry, getCountryList } = useUtilities(root);
    const { addNotification, clearNotifications } = useNotification(root);

    const theme = root.$themeConfig.languagePreferences;
    const submitButton = {
      ...theme?.submitButton,
    };
    const disableSubmit = ref(false);

    const country = getCountry().toUpperCase();
    const locale = localeCode();

    const localeData = ref<Locale>(null);
    const countries = ref([]);

    const preferredLocale = computed(
      () => basicInformation.value.preferences.languageCode
    );

    const currentLocales = computed<LocaleData[]>(
      () => localeData.value?.locales || []
    );

    const selectedLanguage = ref(null);

    const countryChange = (languageCode) => {
      selectedLanguage.value = languageCode;
    };

    const languageHasChanged = computed(
      () =>
        selectedLanguage.value &&
        selectedLanguage.value !== preferredLocale.value
    );

    const showMessage = ref(false);

    const save = async () => {
      if (!languageHasChanged.value) {
        return;
      }
      clearNotifications();
      try {
        showMessage.value = false;
        disableSubmit.value = true;
        await setBasicInformation({
          preferences: {
            languageCode: selectedLanguage.value,
          },
        });

        const selectedLocale = currentLocales.value.find(
          (locale) => locale.code === selectedLanguage.value
        );

        setCountryPreferences({
          languageCode: selectedLanguage.value,
          country,
          currency: selectedLocale?.currency,
        });

        showMessage.value = true;
      } catch (err) {
        addNotification({
          message: props.translations.setBasicInformationError,
          type: 'danger',
        });
      } finally {
        disableSubmit.value = false;
      }
    };

    onMounted(async () => {
      countries.value = await getCountryList(locale);
      const countryData = countries.value?.find((c) => c.code === country);
      const currentLocale = props.locales.find(
        (locale) => locale.displayName === countryData?.name
      );

      if (currentLocale) {
        localeData.value = {
          ...currentLocale,
          locales: currentLocale.locales.map((locale) => ({
            ...locale,
            code: locale.locale.split('-')[0].toUpperCase(),
          })),
        };
      }
    });

    return {
      save,
      disableSubmit,
      country,
      localeData,
      submitButton,
      preferredLocale,
      currentLocales,
      countryChange,
      showMessage,
    };
  },
};
