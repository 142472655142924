

































// TODO: GLOBAL15-56318 remove deprecated component
import type { PropType } from 'vue';
import { computed, defineComponent } from '@vue/composition-api';
import {
  CheckoutContext,
  CheckoutGiftOptionTranslations,
  FlashErrorType,
} from '@vf/api-contract';
import { useCart, useGiftOption, useAccount } from '@vf/composables';
import GiftOptionModal from '@/components/modals/GiftOptionModal.vue';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'CheckoutGiftOption',
  components: {
    GiftOptionModal,
  },
  props: {
    translations: {
      type: Object as PropType<CheckoutGiftOptionTranslations>,
      required: true,
    },
    /** Context to determine where Gift Option modal is rendered (cart|shipping) */
    context: {
      type: String as PropType<CheckoutContext>,
      default: CheckoutContext.Cart,
    },
  },
  setup() {
    const { root } = useRootInstance();
    const { cartItems, productsExcludedFromAthletes } = useCart(root);
    const { modalVisible } = useGiftOption(root);
    const { basicInformation } = useAccount(root);

    const allowedProducts = computed(() =>
      cartItems.value.filter((item) => {
        // Temporary workaround when 'isGiftAllowed' is not updated to false when customer notification shows up
        const hasError = item.customerNotifications?.find(
          ({ type }) =>
            type === FlashErrorType.ProductLineItemIsNotEligibleForGiftOption
        );
        return !!item.isGiftAllowed || hasError;
      })
    );

    const addedProducts = computed(() =>
      allowedProducts.value.filter((item) => item.gift)
    );

    const isGiftOptionVisible = computed(() => {
      return (
        cartItems.value.length &&
        (!basicInformation.value?.athlete?.athlete ||
          productsExcludedFromAthletes.value.length)
      );
    });

    return {
      cartItems,
      addedProducts,
      modalVisible,
      isGiftOptionVisible,
    };
  },
});
