





































import type { PropType } from 'vue';
import {
  computed,
  onMounted,
  ref,
  defineComponent,
} from '@vue/composition-api';
import type {
  GridOverImageBackgroundPosition,
  GridOverImageBackgroundRepeat,
  GridOverImageBackgroundSize,
} from '@vf/api-contract';

export default defineComponent({
  name: 'GridOverImage',
  props: {
    /** Array containing images to display: as a background or background and image */
    images: {
      type: [Array, Object],
      default: () => [],
    },
    /** Video Object to display: as a background or video */
    video: {
      type: Object,
      default: () => ({}),
    },
    backgroundPosition: {
      type: String as PropType<GridOverImageBackgroundPosition>,
      default: 'center',
    },
    backgroundSize: {
      type: String as PropType<GridOverImageBackgroundSize>,
      default: 'cover',
    },
    backgroundRepeat: {
      type: String as PropType<GridOverImageBackgroundRepeat>,
      default: 'no-repeat',
    },
    mediaLayout: {
      type: String,
      default: 'background',
    },
  },
  setup(props, context) {
    const hasVideo = computed(() => Object.keys(props.video).length !== 0);
    const hasMoreThanOneImage = computed(() => props.images?.length > 1);
    const hasBackground = computed(() => props.mediaLayout === 'background');
    const isParentSticky = ref(false);
    const imageOnBackground = computed(
      () => hasMoreThanOneImage && props.images[1]
    );

    onMounted(() => {
      isParentSticky.value = context.parent.$el.className === 'vf-sticky';
    });

    const backgroundStyle = computed(() => {
      if (props.images?.length) {
        const backgroundImage = props.images[0];
        return {
          '--grid-background-image': `url('${backgroundImage.small}')`,
          '--grid-background-large-image':
            backgroundImage.large && `url('${backgroundImage.large}')`,
          '--grid-background-position': props.backgroundPosition,
          '--grid-background-size': props.backgroundSize,
          '--grid-background-repeat': props.backgroundRepeat,
        };
      } else {
        return {};
      }
    });
    return {
      hasVideo,
      hasMoreThanOneImage,
      backgroundStyle,
      imageOnBackground,
      isParentSticky,
      hasBackground,
    };
  },
});
