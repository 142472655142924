import { ref, computed } from '@vue/composition-api';
import { CartAddressRequest } from '@vf/api-contract';
import { apiClientFactory } from '@vf/api-client';

export const useAddressVerificationService = (instance) => {
  const { addressValidation: addressValidationAPI } = apiClientFactory(
    instance
  );

  const addressVerification = ref(null);
  const suggestedAddress = ref(null);
  const originalAddress = ref(null);

  const addressMap = {
    standardizedAddress1: 'addressLine1',
    standardizedAddress2: 'addressLine2',
    standardizedCity: 'city',
    standardizedState: 'province',
    standardizedPostalCode: 'postalCode',
    standardizedCountry: 'country',
  };

  const mapResponseToSuggestedAddress = (serviceResponse) => {
    return Object.keys(addressMap).reduce((acc, key) => {
      acc[addressMap[key]] = serviceResponse[key];
      return acc;
    }, {});
  };

  const isRejected = computed(() => {
    const isException =
      addressVerification.value.serviceResponse?.decision === 'ERROR' &&
      !![
        addressVerification.value.serviceResponse?.reasonCode,
        addressVerification.value.serviceResponse?.davRasonCode,
      ].includes('150');

    return (
      addressVerification.value.serviceResponse?.decision === 'REJECT' ||
      isException ||
      addressVerification.value.error
    );
  });

  const verifyAddress = async (address: CartAddressRequest, cartId: string) => {
    originalAddress.value = {
      firstName: address.firstName || '',
      lastName: address.lastName || '',
      addressLine1: address.addressLine1 || '',
      addressLine2: address.addressLine2 || '',
      city: address.city || '',
      postalCode: address.postalCode || '',
      province: address.province || '',
      country: address.country || '',
    };

    const addressToVerify = {
      firstName: address.firstName || '',
      lastName: address.lastName || '',
      address1: address.addressLine1 || '',
      address2: address.addressLine2 || '',
      city: address.city || '',
      zipCode: address.postalCode || '',
      state: address.province || '',
      countryCode: address.country || '',
    };

    try {
      const verificationResponse = await addressValidationAPI(
        addressToVerify,
        cartId
      );
      addressVerification.value = verificationResponse.data;
      const suggestion = mapResponseToSuggestedAddress(
        addressVerification.value.serviceResponse
      );
      const hasChanges = Object.values(addressMap).reduce(
        (acc, value) =>
          acc ||
          (suggestion[value] !== address[value] &&
            !!suggestion[value] &&
            !!address[value]), // if both values are empty, we don't consider it as a change
        false
      );

      suggestedAddress.value = hasChanges
        ? {
            ...suggestion,
            firstName: address.firstName,
            lastName: address.lastName,
          }
        : null;
    } catch (error) {
      addressVerification.value = { error, serviceResponse: {} };
    }
  };

  return {
    addressVerification: computed(() => addressVerification.value),
    isRejected,
    originalAddress: computed(() => originalAddress.value),
    suggestedAddress: computed(() => suggestedAddress.value),
    verifyAddress,
  };
};
