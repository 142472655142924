












import { defineComponent, PropType, watch } from '@vue/composition-api';
import { useI18n, useRouting, useSignInToStore, ROUTES } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import { Context } from '@vf/api-contract';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@vf/composables/src/store/user';

export default defineComponent({
  name: 'ClearSessionButton',
  props: {
    title: {
      type: String as PropType<string>,
      required: true,
    },
    buttonUrl: {
      type: String as PropType<string>,
      default: '',
    },
    backgroundColor: {
      type: String as PropType<string>,
      default: '#c22014',
    },
    titleColor: {
      type: String as PropType<string>,
      default: '',
    },
    positionX: {
      type: String as PropType<string>,
      default: 'left',
    },
    positionY: {
      type: String as PropType<string>,
      default: 'bottom',
    },
    contextKey: {
      type: String as PropType<Context>,
      default: Context.PageContent,
    },
  },
  setup(props) {
    // TODO: Remove it after GLOBAL15-56318
    const { root } = useRootInstance();
    const { previousRoute } = useRouting(root);
    const { clearStoreSession } = useSignInToStore(root);
    const { localePath } = useI18n(root);
    const userStore = useUserStore(root);
    const { loggedIn } = storeToRefs(userStore);

    const shouldRender = props.contextKey !== Context.Modal;

    const clearStore = () => {
      clearStoreSession(props.buttonUrl);
    };

    const stylesList = {
      ...{
        backgroundColor: props.backgroundColor,
        top: props.positionY === 'top' ? '0px' : null,
        bottom: props.positionY === 'bottom' ? '0px' : null,
        left: props.positionX === 'left' ? '0px' : null,
        right: props.positionX === 'right' ? '0px' : null,
      },
    };

    watch(previousRoute, () => {
      if (
        previousRoute.value.path ===
          localePath(ROUTES.CHECKOUT_ORDER_STATUS()) &&
        !loggedIn.value
      ) {
        clearStoreSession(props.buttonUrl, true);
      }
    });

    return {
      clearStore,
      stylesList,
      shouldRender,
    };
  },
});
