import { defineStore } from 'pinia';
import {
  SeoRecommendationData,
  UseRecommendationState,
  SeoRecommendationType,
} from '../useSeo/types';

export const useSeoRecommendationsStore = defineStore('seoRecommendations', {
  state: (): UseRecommendationState => ({
    categoryRecommendations: [],
    productRecommendations: [],
  }),
  actions: {
    addRecommendation(
      type: SeoRecommendationType,
      key: string,
      recommendation: SeoRecommendationData
    ) {
      if (type === 'product') {
        this.productRecommendations.push({
          recommendationKey: key,
          recommendationPageType: type,
          recommendationData: recommendation,
        });
        return;
      }
      this.categoryRecommendations.push({
        recommendationKey: key,
        recommendationPageType: type,
        recommendationData: recommendation,
      });
    },
  },
  getters: {
    getCategoryRecommendation: (state) => {
      return (id) =>
        state.categoryRecommendations.find(
          (item) => item.recommendationKey === id
        );
    },
    getProductRecommendation: (state) => {
      return (id) =>
        state.productRecommendations.find(
          (item) => item.recommendationKey === id
        );
    },
  },
});
