export const addressModel = {
  id: null,
  addressId: null,
  addressLine1: null,
  addressLine2: null,
  city: null,
  country: null,
  email: null,
  emailSubscription: false,
  firstName: null,
  lastName: null,
  main: false,
  phone: null,
  postalCode: null,
  province: null,
  saveAddress: false,
};
export type AddressFormContext = 'shippingForm' | 'billingForm';
export type AddressModel = typeof addressModel;
export default addressModel;
