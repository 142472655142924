



















import type { PropType } from 'vue';
import {
  ref,
  defineComponent,
  Ref,
  onBeforeMount,
  onUnmounted,
} from '@vue/composition-api';
import type { ResetPasswordTranslations } from '@vf/api-contract';
import {
  ROUTES,
  useAuthentication,
  useNotification,
  useI18n,
  useCart,
  useFavorites,
  useReCaptcha,
} from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import useAuthenticationEvents from '@/shared/useAuthenticationEvents';

export default defineComponent({
  name: 'ResetPasswordForm',
  props: {
    translations: {
      type: Object as PropType<ResetPasswordTranslations>,
      required: true,
    },
    /** Custom sign in link value */
    signInLink: {
      type: String,
      required: true,
    },
    /** Custom redirect link value after resetting the password */
    successLink: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { changePassword, signIn, saveForLaterId } = useAuthentication(root);
    const { createSignIn } = useAuthenticationEvents(
      'inline:body:reset-password'
    );
    const { localePath } = useI18n(root);
    const {
      clearNotifications,
      setPasswordChangeNotification,
      setChangePassLinkExpiredNotification,
    } = useNotification(root);
    const reCaptcha = useReCaptcha(root);
    const disableSubmit: Ref<boolean> = ref(false);
    const token = getToken();

    /**
     * Set Token Expired notification if expired time was defined in URL
     */
    function setLinkExpiredNotification() {
      if (token.exp && token.exp <= new Date().getTime()) {
        setChangePassLinkExpiredNotification(true);
        root.$router.replace(localePath(ROUTES.FORGOT_PASSWORD()));
      }
    }

    const boundWithGtmEventsSignIn = createSignIn(signIn, {
      isAutomaticLogin: true,
    });

    async function handleChangePassword(data) {
      disableSubmit.value = true;

      clearNotifications();
      const email = await getUserEmail();

      const recaptchaToken = await reCaptcha.executeRecaptcha('resetPassword');

      try {
        await changePassword({
          password: data.password,
          confirmationCode: token.value,
          captchaResponse: recaptchaToken,
        });
        const { cartId } = useCart(root);
        const { favoriteId } = useFavorites(root);
        await boundWithGtmEventsSignIn({
          password: data.password,
          username: email,
          guestObjects: {
            basketId: cartId.value,
            saveForLaterId: saveForLaterId.value,
            favoriteId: favoriteId.value,
          },
        });
        setPasswordChangeNotification({
          isShown: true,
          message: props.translations.changePasswordSuccess,
        });
        await root.$nextTick();
        await root.$router.push(localePath(props.successLink));
      } catch (e) {
        console.error(e);
        root.$emit('resetPasswordData');
      } finally {
        disableSubmit.value = false;
      }
    }

    async function getUserEmail(): Promise<string> {
      const base64Email = getQueryStringFromURL('email');
      const Base64 = await import(
        /* webpackChunkName: "crypto" */
        /* webpackMode: "lazy" */
        /* webpackPrefetch: true */
        /* webpackPreload: true */
        'crypto-js/enc-base64'
      );
      const Utf8 = await import(
        /* webpackChunkName: "crypto" */
        /* webpackMode: "lazy" */
        /* webpackPrefetch: true */
        /* webpackPreload: true */
        'crypto-js/enc-utf8'
      );
      const parsedEmail = Base64.parse(base64Email);
      return Utf8.stringify(parsedEmail);
    }

    /**
     * Extract token value and expiration timestamp from URL
     * Examples:
     * http://brand.com#token=tokenValue_tokenExpTimestamp?email=email
     * Note: It has changed in scope of https://digital.vfc.com/jira/browse/GLOBAL15-53534
     * since this change #token is at the end of URL
     */
    function getToken(): { value: string; exp: number } | undefined {
      // split route hash and query params
      const hash = root.$route.hash ? root.$route.hash.split('?')[0] : null;

      if (!hash) {
        return;
      }

      // split token and token expiration timestamp
      // NOTE: some urls has only token value without timestamp
      const [tokenStr, expStr] = hash.split('_');
      const token = tokenStr.replace('#token=', '');
      // handle additional &exp=value param which could be a part of hash
      // Example: #token=tokenValue_tokenExpTimestamp&exp=012455?queryParams=''
      const exp = Number(expStr?.split('&')[0] || 0);

      return {
        value: token,
        exp,
      };
    }

    function getQueryStringFromURL(query: string) {
      // This is complicated because it handle 2 url options where #token
      // can be just after path and at the end of url search
      return (
        root.$route.query?.[query] ||
        new URLSearchParams(root.$route.fullPath.split('?')[1]).get(query) ||
        ''
      );
    }

    onBeforeMount(() => {
      reCaptcha.showBadge();

      if (!token || !token.value) {
        root.$router.replace(localePath(ROUTES.HOME()));
        return;
      }

      setLinkExpiredNotification();
    });

    onUnmounted(() => reCaptcha.hideBadge());

    return {
      disableSubmit,
      handleChangePassword,
    };
  },
});
