



















import { defineComponent } from '@vue/composition-api';
import RichText from '@/components/smart/common/RichText.vue';

export default defineComponent({
  name: 'AccordionItem',
  components: {
    RichText,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    iconType: {
      type: String,
      default: '',
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
});
