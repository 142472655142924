
















































import { computed, defineComponent } from '@vue/composition-api';
import PaymentReview from '@/components/static/checkout/review/PaymentReview.vue';
import { useCheckoutStore } from '@vf/composables/src/store/checkoutStore';
import { storeToRefs } from 'pinia';
import AddressPreview from '@/components/static/AddressPreview.vue';
import { PaymentMethodCode } from '@vf/api-client';

export default defineComponent({
  name: 'SuccessReview',
  components: {
    AddressPreview,
    PaymentReview,
  },
  setup() {
    const {
      order,
      orderShipments,
      orderPickups,
      giftCards,
      appliedFamilyVoucher,
      athleteDiscounts,
      rewardCards,
      rewardCodes,
      isZeroOrder,
    } = storeToRefs(useCheckoutStore());

    const paymentMethodsHiddenInUi = [
      PaymentMethodCode.GIFT_CARD,
      PaymentMethodCode.REWARD_CARD,
      PaymentMethodCode.REWARD_CERTIFICATE,
      PaymentMethodCode.REWARD_CODE,
      PaymentMethodCode.LOYALTY_POINTS,
    ];

    const shippingAddress = computed(
      () => orderShipments.value[0]?.shippingAddress
    );

    const pickupData = computed(() => {
      const orderPickup = orderPickups.value[0];
      if (!orderPickup) return;

      const {
        firstName,
        lastName,
        email,
        ...address
      } = orderPickup.shippingAddress;

      return {
        store: {
          firstName: orderPickup.storeName,
          ...address,
        },
        person: {
          firstName,
          lastName,
          email,
        },
      };
    });

    const card = computed(() => {
      const creditCard = order.value?.paymentMethod.find(
        (method) => method.code === PaymentMethodCode.CREDIT_CARD
      );
      if (!creditCard) return;

      const {
        expiration_month,
        expiration_year,
        card_type,
        masked_number,
      } = creditCard.additionalData;

      return {
        expirationMonth: expiration_month,
        expirationYear: expiration_year,
        cardType: card_type,
        maskedNumber: masked_number,
      };
    });

    return {
      shippingAddress,
      pickupData,
      paymentMethod: computed(
        () =>
          order.value?.paymentMethod
            .map(({ code }) => code)
            .filter(
              (code) =>
                !paymentMethodsHiddenInUi.includes(code as PaymentMethodCode)
            )[0]
      ),
      card,

      appliedGiftCards: giftCards,
      appliedRewardCards: rewardCards,
      appliedRewardCodes: rewardCodes,
      appliedPointRewards: appliedFamilyVoucher,
      athleteDiscounts: athleteDiscounts,
      billingAddress: computed(() => order.value?.billingAddress),
      isZeroOrder,
    };
  },
});
