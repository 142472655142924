import { Ref } from '@nuxtjs/composition-api';
import { computed, watch } from '@vue/composition-api';

import { isClient } from '@vf/shared/src/utils/helpers';

import { CheckoutStepNumber } from '@vf/composables/src/types/gtm';
import { useGtm, useRequestTracker } from '@vf/composables';

import useRootInstance from '@/shared/useRootInstance';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

export function useCheckoutPaymentGTM({
  paymentMethodsData,
  paymentMethod,
}: {
  paymentMethodsData: Ref<any[]>;
  paymentMethod: Ref<string>;
}): { disableWatch: () => void } {
  const { root } = useRootInstance();
  const { onBaseDone } = useRequestTracker(root);
  const { dispatchEvent } = useGtm(root);
  const { isCheckoutRedesignEnabled } = useFeatureFlagsStore();
  let unWatchPaymentMethod = null;
  let isFirstCall = true;

  if (isClient) {
    const methodLabel = computed(
      () =>
        paymentMethodsData.value.find(
          (method) => method.code === paymentMethod.value
        )?.label
    );

    onBaseDone(() => {
      // TODO: move to useGtm->dispatchPageEvent - GLOBAL15-1943
      if (isFirstCall) {
        isFirstCall = false;
        if (!isCheckoutRedesignEnabled) {
          dispatchEvent({
            eventName: 'checkout',
            overrideAttributes: {
              step: CheckoutStepNumber.BILLING,
              option: `Payment Method: ${methodLabel.value}`,
            },
          });
        }
        unWatchPaymentMethod = watch(paymentMethod, () => {
          dispatchEvent({
            eventName: 'checkoutOption',
            overrideAttributes: {
              step: CheckoutStepNumber.BILLING,
              option: `Payment Method: ${methodLabel.value}`,
            },
          });
        });
      }
    });
  }

  const disableWatch = () => {
    // disable watch
    unWatchPaymentMethod?.();
    // disable lazy onBaseDone
    isFirstCall = false;
  };

  return {
    disableWatch,
  };
}

export default useCheckoutPaymentGTM;
