
import { computed, defineComponent } from '@vue/composition-api';
import useRootInstance from '@/shared/useRootInstance';

import usePlaceholders from '@vf/composables/src/usePlaceholders';
import { replacePlaceholdersInObject } from '@vf/composables/src/utils/placeholders';

export default defineComponent({
  name: 'PlaceholderReplacementWrapper',
  props: {
    childProps: Object,
  },
  setup(props) {
    const { root } = useRootInstance();
    const { fields, placeholderValues } = usePlaceholders(root);

    const processedProps = computed(() => {
      return replacePlaceholdersInObject(
        props.childProps,
        fields,
        placeholderValues.value,
        true
      );
    });
    return { processedProps, placeholderValues };
  },
  render() {
    return this.$scopedSlots.default(this.processedProps);
  },
});
