

































import type { PropType } from 'vue';
import { defineComponent, computed, onMounted } from '@vue/composition-api';
import { useArticles, useCms } from '@vf/composables';
import type { Taxonomy } from '@vf/composables/src/types';
import type {
  ArticleGridConfiguration,
  NewsArticleTranslations,
} from '@vf/api-contract';
import useRootInstance from '@/shared/useRootInstance';

const NewsArticleFilters = () =>
  import(
    /* webpackChunkName: "NewsArticleFilters" */
    /* webpackMode: "lazy" */
    /* webpackPrefetch: true */
    /* webpackPreload: true */
    '../shared/NewsArticleFilters.vue'
  );
const ArticleGrid = () =>
  import(
    /* webpackChunkName: "ArticleGrid" */
    /* webpackMode: "lazy" */
    /* webpackPrefetch: true */
    /* webpackPreload: true */
    '../shared/ArticleGrid.vue'
  );

export default defineComponent({
  name: 'NewsArticleGrid',
  components: {
    NewsArticleFilters,
    ArticleGrid,
  },
  props: {
    translations: {
      type: Object as PropType<NewsArticleTranslations>,
      required: true,
    },
    /** Page Title **/
    title: {
      type: String as PropType<string>,
      required: true,
    },
    contextKey: {
      type: String,
      default: 'news-articles',
    },
    configuration: {
      type: Object as PropType<ArticleGridConfiguration>,
      default: () => ({}),
    },
    filters: {
      type: Array as PropType<Taxonomy[]>,
      default: () => [],
    },
  },
  setup(props) {
    const { root } = useRootInstance();

    const {
      pagination,
      selectPrimaryFilters,
      getArticlesResults,
      changeSort,
    } = useArticles(root, 'news-articles');

    const isEmpty = computed(() => pagination.value.total === 0);
    const { setup } = useCms(root, 'news-articles');

    onMounted(async () => {
      await setup({ forcePreview: root.$isPreview });
      changeSort('publicationDate');
      selectPrimaryFilters(props.filters);
      await getArticlesResults();
    });

    return {
      isEmpty,
    };
  },
});
