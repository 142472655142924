










import type { PropType } from 'vue';
import { defineComponent } from '@vue/composition-api';
import type { Icons } from '@vf/api-contract';
import { useCart } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'FloatingCartButton',
  props: {
    icon: {
      type: String as PropType<Icons>,
      default: 'cart',
    },
    randomizer: {
      type: Boolean,
      required: false,
      default: false,
    },
    ariaLabel: {
      type: String,
      default: 'Show cart',
    },
  },
  setup() {
    const { root } = useRootInstance();

    const { totalItems, setMiniCart, isMinicartHidden } = useCart(root);

    return {
      totalItems,
      setMiniCart,
      isMinicartHidden,
    };
  },
});
