



























































import type { PaymentMethod as PaymentMethodType } from '@vf/api-contract/src/cmsPropsEntities';

import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
} from '@vue/composition-api';
import PaymentMethod from '@/components/payment/PaymentMethod.vue';
import { useCheckout, usePaymentMethods } from '@vf/composables';
import { useCheckoutPaymentGTM } from '../../smart/checkout/CheckoutPaymentInfo/composable/useCheckoutPaymentGTM';
import useRootInstance from '@/shared/useRootInstance';

import Klarna from '../../payment/Klarna.vue';
import ApplePay from '../../payment/ApplePay.vue';
const PayPal = () =>
  import('@/components/static/checkout/payment/paypal/index.vue');
const CreditCardSelector = () =>
  import('@/components/static/checkout/CreditCardSelector.vue');
import BillingAddressSelector from '@/components/static/checkout/BillingAddressSelector.vue';

export default defineComponent({
  name: 'PaymentInformation',
  components: {
    PaymentMethod,
    PayPal,
    Klarna,
    ApplePay,
    CreditCardSelector,
    BillingAddressSelector,
  },
  props: {
    paymentMethods: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  setup(props, { emit }) {
    const { root } = useRootInstance();
    const { paymentMethod, paymentMethodsData } = useCheckout(root);
    const {
      arePaymentMethodsDisabled,
      paymentMethodHandlers,
    } = usePaymentMethods(root);
    const { disableWatch: paymentMethodGtmUnwatch } = useCheckoutPaymentGTM({
      paymentMethodsData,
      paymentMethod,
    });

    const availablePaymentMethods = computed(() =>
      props.paymentMethods.map((method: PaymentMethodType) => ({
        code: method.code,
        ...(paymentMethodHandlers[method.code] ?? {
          label: method.code,
          isLabelDisplayed: true,
        }),
      }))
    );

    const setPaymentMethod = () => {
      if (!paymentMethod.value) return;
      emit('change:payment-method', paymentMethod.value);
    };

    const refreshMethodHandler = (isOpen: boolean) => {
      // for handlers which require embed container within the viewport at all times
      // re-run init when the collapsible parent gets expanded
      isOpen && setPaymentMethod();
    };

    const onPaymentMethodChange = (method: PaymentMethodType) => {
      emit('change:payment-method', method);
    };

    onMounted(() => {
      // preselect and initiate previously picked method persisted in store
      setPaymentMethod();
    });

    onUnmounted(paymentMethodGtmUnwatch);

    return {
      paymentMethod,
      onPaymentMethodChange,
      availablePaymentMethods,
      arePaymentMethodsDisabled,
      refreshMethodHandler,
    };
  },
});
