import { Reward } from '../store/rewards';
import { Payment } from '@vf/api-contract/src/entities';
import { CheckoutOrderPaymentMethod } from '@vf/api-client/src/types';
import { useFeatureFlagsStore } from '../store/featureFlags';
import { getCurrentInstance } from '@vue/composition-api';

export const useRewardCycleTranslations = (
  translation: {
    summer: string;
    winter: string;
    spring: string;
  } & Record<any, any>
) => {
  const { areRewardCodesEnabled } = useFeatureFlagsStore();
  const currentInstance = getCurrentInstance();
  const localInstance = currentInstance?.proxy;

  const translationsForCycles: Record<Reward['rewardCategory'], string> = {
    SUMMER: translation.summer,
    WINTER: translation.winter,
    SPRING: translation.spring,
  };

  const getRewardDetails = (reward) => {
    // this complicated logic is caused by different data structure from api
    // order cart has different payment method structure
    const additionalData =
      !Array.isArray(reward.additionalData) && reward.additionalData;
    return additionalData || reward;
  };

  const seasonTranslationTemplate = (rewardData) => {
    const year = new Date(rewardData.issueDateTime).getFullYear();
    const seasonTranslation = translationsForCycles[rewardData.rewardCategory];

    return seasonTranslation.replace('{{year}}', year);
  };

  const getPaymentId = (rewardData): 'REWARD_CARD' | 'REWARD_CODE' => {
    if ('payment_method_id' in rewardData) {
      return rewardData.payment_method_id;
    }
    return 'paymentMethodId' in rewardData
      ? rewardData.paymentMethodId
      : rewardData.paymentMethod;
  };

  const getRewardCycleText = (
    reward: Reward | Payment | CheckoutOrderPaymentMethod
  ): string => {
    const rewardData = getRewardDetails(reward);
    const methodCode = getPaymentId(rewardData);

    // TODO: GLOBAL15-56318 remove feature flag usage after checkout redesign
    // the one with REWARD_CODE should stay so truthy conditionals should stay
    return areRewardCodesEnabled && methodCode === 'REWARD_CODE'
      ? seasonTranslationTemplate(rewardData)
      : areRewardCodesEnabled &&
        'expireDateTime' in reward &&
        reward.expireDateTime
      ? expireDateToIssuedCycle(reward.expireDateTime)
      : areRewardCodesEnabled
      ? localInstance.$t('checkoutReward.legacyIssuedText')
      : localInstance.$t('checkoutReward.rewardCardLabel');
  };

  function expireDateToIssuedCycle(date: string) {
    const expireDate = new Date(date);
    const month = expireDate.getMonth() as 1 | 4 | 8; // 1, 4, or 8 (February, May, or September)

    const expireMonthToIssuedCycleMap = {
      1: translation.spring,
      4: translation.summer,
      8: translation.winter,
    };
    const issuedCycle = expireMonthToIssuedCycleMap[month];
    const issuedYear = expireDate.getFullYear() - 1;
    return issuedCycle.replace('{{year}}', issuedYear.toString());
  }

  return {
    getRewardCycleText,
  };
};
