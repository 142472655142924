









import {
  computed,
  defineComponent,
  onMounted,
  watch,
} from '@vue/composition-api';
import { ROUTES, useI18n } from '@vf/composables';
import { CmsProps } from '@/components/smart/shared/ProductsGrid/CmsProps';
import { PageTypeName } from '@vf/composables/src/useCms/types';
import useRootInstance from '@/shared/useRootInstance';
import useLoader from '@/shared/useLoader';
import { useFavoritesWithDataLayer } from '@/shared/useFavoritesWithDataLayer';
import { isClient } from '@vf/shared/src/utils/helpers';
import { useUserStore } from '@vf/composables/src/store/user';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'FavoritesProducts',
  props: {
    /** Name of the context where component should be displayed */
    contextName: {
      type: String,
      default: 'favorites',
    },
    ...CmsProps,
    /** How many items should be displayed on single page */
    productsPerPage: {
      type: Number,
      default: 25,
    },

    contextKey: {
      type: String,
      default: 'page-content',
    },
  },
  setup() {
    const { root } = useRootInstance();

    const {
      toggleFavorites,
      getFavorites,
      getSharedFavorites,
      favorites,
      sharedFavorites,
    } = useFavoritesWithDataLayer(PageTypeName.FAVORITES);

    const { showSpinner, hideSpinner } = useLoader();
    const { localePath } = useI18n(root);
    const userStore = useUserStore(root);
    const { loggedIn } = storeToRefs(userStore);
    const isShared = computed(
      () => root.$route.path === localePath(ROUTES.FAVORITES())
    );
    const isPublic = computed(
      () => root.$route.path === localePath(ROUTES.FAVORITES_PUBLIC())
    );
    const favouritesProducts = computed(() => {
      return isShared.value ? sharedFavorites.value : favorites.value;
    });

    const fetchFavorites = async () => {
      showSpinner();
      if (isShared.value) {
        await getSharedFavorites(root.$route.query.fvid as string);
      } else {
        await getFavorites();
      }
      hideSpinner();
    };

    onMounted(() => {
      fetchFavorites();
    });

    if (isClient) {
      watch([loggedIn, isPublic], ([isLoggedIn, isPublic]) => {
        if (isLoggedIn || isPublic) fetchFavorites();
      });
    }
    return {
      favouritesProducts,
      toggleFavorites,
    };
  },
});
