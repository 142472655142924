import { Image } from '@vf/api-contract';

export type ImageSizes = {
  small: number;
  medium: number;
  large: number;
};

export const extractImageSizes = (
  image: Image,
  prop: 'width' | 'height'
): ImageSizes | null =>
  image && image.sizes
    ? (Object.fromEntries(
        Object.entries(image.sizes).map(([key, size]) => [
          key as keyof Image['sizes'],
          size[prop],
        ])
      ) as ImageSizes)
    : null;
