type DimensionType = 'Small' | 'Medium' | 'Large';
const DIMENSIONS: DimensionType[] = ['Small', 'Medium', 'Large'];

type OptionValueType = string | number | boolean | undefined;
type DimensionSettings = { [key: string]: OptionValueType };
type OptionCallbackType = (dimension?: DimensionType) => OptionValueType;

export const generateDimensionsSettings = (
  optionCallback: OptionCallbackType,
  keyPrefix = ''
): DimensionSettings => {
  const getSettingsKey = (dimension: DimensionType) => {
    if (keyPrefix) {
      return dimension !== 'Small'
        ? `${keyPrefix}-${dimension.toLowerCase()}`
        : keyPrefix;
    }
    return dimension.toLowerCase();
  };

  return DIMENSIONS.reduce(
    (settings: DimensionSettings, dimension) => ({
      ...settings,
      [getSettingsKey(dimension)]: optionCallback(dimension),
    }),
    {}
  );
};
