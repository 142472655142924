























































































import { computed, defineComponent, PropType } from '@vue/composition-api';
import { ImageObject, LoyaltySuccessStepTranslations } from '@vf/api-contract';
import useModal from '@/shared/useModal';

export default defineComponent({
  name: 'LoyaltySuccessStep',
  props: {
    flow: {
      type: String as PropType<'login' | 'join'>,
      required: true,
    },
    isLegacyUser: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    /** Object containing translations as in interface LoyaltyRegisterTranslations */
    translations: {
      type: Object as PropType<LoyaltySuccessStepTranslations>,
      required: true,
    },
    /** Image to be displayed instead of promo */
    imageObject: {
      type: Object as PropType<ImageObject>,
      default: null,
    },
    imageLegacy: {
      type: Object as PropType<ImageObject>,
      default: null,
    },
    imageSignIn: {
      type: Object as PropType<ImageObject>,
      default: null,
    },
    /** First Button CTA link */
    buttonLink: {
      type: String as PropType<string>,
      default: '',
    },
    /** Second Button CTA link */
    secondButtonLink: {
      type: String as PropType<string>,
      default: '',
    },
    buttonLegacy: {
      type: Object,
      default: null,
    },
    secondButtonLegacy: {
      type: Object,
      default: null,
    },
    buttonSignIn: {
      type: Object,
      default: null,
    },
    secondButtonSignIn: {
      type: Object,
      default: null,
    },
    showHeading: {
      type: Boolean,
      default: true,
    },
    showSubheading: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { closeModal } = useModal();

    const data = computed(() => {
      let heading = props.translations.heading;
      let subheading = props.translations.subheading;
      let image = props.imageObject;
      let buttonLink = props.buttonLink;
      let buttonText = props.translations.buttonText;
      let secondButtonLink = props.secondButtonLink;
      let secondButtonText = props.translations.secondButtonText;

      if (props.flow === 'login') {
        // if we have at least one override button it means that we should override whole list
        // in case flow needs only 1 button instead of 2 buttons in original
        const hasButtonOverride =
          props.buttonSignIn || props.secondButtonSignIn;

        return {
          heading: props.translations.headingSignIn || heading,
          subheading: props.translations.subheadingSignIn || subheading,
          image: props.imageSignIn || image,
          buttonLink: hasButtonOverride ? props.buttonSignIn?.link : buttonLink,
          buttonText: hasButtonOverride ? props.buttonSignIn?.text : buttonText,
          secondButtonLink: hasButtonOverride
            ? props.secondButtonSignIn?.link
            : secondButtonLink,
          secondButtonText: hasButtonOverride
            ? props.secondButtonSignIn?.text
            : secondButtonText,
        };
      }

      if (props.isLegacyUser) {
        const hasButtonOverride =
          props.buttonLegacy || props.secondButtonLegacy;

        return {
          heading: props.translations.headingLegacy || heading,
          subheading: props.translations.subheadingLegacy || subheading,
          image: props.imageLegacy || image,
          buttonLink: hasButtonOverride ? props.buttonLegacy?.link : buttonLink,
          buttonText: hasButtonOverride ? props.buttonLegacy?.text : buttonText,
          secondButtonLink: hasButtonOverride
            ? props.secondButtonLegacy?.link
            : secondButtonLink,
          secondButtonText: hasButtonOverride
            ? props.secondButtonLegacy?.text
            : secondButtonText,
        };
      }

      return {
        heading,
        subheading,
        image,
        buttonLink,
        buttonText,
        secondButtonLink,
        secondButtonText,
      };
    });

    return {
      data,
      closeModal,
    };
  },
});
