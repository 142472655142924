import { OrderDetails, PaymentMethodCode } from '@vf/api-client';

export const getOrderGiftOrRewardCardAmount = (
  order: OrderDetails,
  type: PaymentMethodCode.GIFT_CARD | PaymentMethodCode.REWARD_CARD
): number => {
  if (!Array.isArray(order.payments)) return 0;
  return order.payments.reduce(
    (total, { paymentMethod, amount }) =>
      paymentMethod === PaymentMethodCode[type] ? total + +amount : total,
    0
  );
};
