var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vf-order-summary-product-list"},[_vm._l((_vm.availableShipments),function(shipment,shipmentKey){return _c('div',{key:("order-shipment-" + (shipment.shipmentId)),staticClass:"vf-order-summary__shipment"},[_c('VfOrderShipmentLabel',{staticClass:"order-products__shipment-label",attrs:{"translations":_vm.getShipmentTranslations(shipment),"shipping-method":_vm.getShippingMethods(shipment)[0],"text-content":_vm.getShippingStatusText(shipment, shipmentKey + 1),"locale-data":_vm.localeData}}),_vm._v(" "),(_vm.theme.showEddAndTrackingInSeparateLines)?_vm._t("shipment-tracking",null,{"shipment":shipment}):_vm._e(),_vm._v(" "),(
        !_vm.theme.groupItemsFromShipment &&
        _vm.productStatuses.every(function (status) { return status === 'all'; })
      )?_c('div',[_c('VfOrderProductSummary',_vm._b({attrs:{"products":shipment.shipmentProductsByStatus['all'],"products-key":("" + (shipment.shipmentId)),"is-returns":_vm.isReturns},on:{"handle-update-return":function($event){return _vm.$emit('handle-update-return', $event)}}},'VfOrderProductSummary',_vm.productSummaryBindings,false),[(
            _vm.displayReturnedItemsDetailsCTA &&
            shipment.returnedProductsCount > 0
          )?_c('tr',[_c('td',{staticClass:"vf-order-summary__shipment--returns",attrs:{"colspan":_vm.theme.showGiftOption ? 5 : 4}},[_c('VfText',{attrs:{"content":_vm.getReturnedItemsTranslations(shipment),"font-sizes":['sm']}}),_vm._v(" "),(_vm.showReturnHistoryLink)?_c('ThemeLink',{attrs:{"to":_vm.returnedItemsPath}},[_vm._v("\n              "+_vm._s(_vm.translations.returnedItemsLinkText)+"\n            ")]):_vm._e()],1)]):_vm._e()])],1):_vm._l((_vm.productStatuses),function(status){return _c('div',{key:("order-shipment-" + (shipment.shipmentId) + "-" + status)},[(shipment.shipmentProductsByStatus[status].length)?_c('VfOrderProductSummary',_vm._b({attrs:{"products":shipment.shipmentProductsByStatus[status],"order-summary-status":_vm.orderSummaryStatusObject[status],"products-key":("" + (shipment.shipmentId) + status),"is-returns":_vm.isReturns && !_vm.isReturnStatus(status),"is-returned":_vm.isReturnStatus(status),"is-cancelled":_vm.isCancelledStatus(status),"return-step":_vm.returnsContext},on:{"handle-update-return":function($event){return _vm.$emit('handle-update-return', $event)}}},'VfOrderProductSummary',_vm.productSummaryBindings,false),[(
              _vm.displayReturnedItemsDetailsCTA &&
              shipment.returnedProductsCount > 0 &&
              _vm.isReturnStatus(status)
            )?_c('tr',[_c('td',{staticClass:"vf-order-summary__shipment--returns",attrs:{"colspan":_vm.theme.showGiftOption ? 5 : 4}},[_c('VfText',{attrs:{"content":_vm.getReturnedItemsTranslations(shipment),"font-sizes":['sm']}}),_vm._v(" "),(_vm.showReturnHistoryLink)?_c('ThemeLink',{attrs:{"to":_vm.returnedItemsPath}},[_vm._v("\n                "+_vm._s(_vm.translations.returnedItemsLinkText)+"\n              ")]):_vm._e()],1)]):_vm._e()]):_vm._e()],1)}),_vm._v(" "),(!_vm.returnsContext)?_c('OrderReturnsCTA',{attrs:{"translations":_vm.translations,"start-return-url":shipment.startReturnURL,"return-tracking-url":shipment.extnReturnTrackingURL,"tracking-modal-mode":shipment.trackableItems.length > 1},on:{"click:track-return":function($event){_vm.returnTrackableItems = shipment.trackableItems}}}):_vm._e()],2)}),_vm._v(" "),(_vm.showCancelledItems)?_c('div',[_c('VfOrderShipmentLabel',{class:_vm.theme.cancelledItemsLabelClass,attrs:{"translations":_vm.getShipmentTranslations(_vm.shipments[0]),"text-content":_vm.translations.cancelledItemsLabel,"locale-data":_vm.localeData}}),_vm._v(" "),_c('VfOrderProductSummary',_vm._b({attrs:{"products":_vm.cancelledItems,"show-gift-option":_vm.theme.showGiftOption,"is-cancelled":""}},'VfOrderProductSummary',_vm.productSummaryBindings,false))],1):_vm._e(),_vm._v(" "),(_vm.isReturns && _vm.returnsContext !== _vm.ReturnStepsType.DETAILS)?_c('div',[_c('VfOrderProductSummary',_vm._b({staticClass:"vf-order-summary__return",attrs:{"products":_vm.returnsItems,"is-returns":_vm.isReturns,"return-step":_vm.returnsContext}},'VfOrderProductSummary',_vm.productSummaryBindings,false))],1):_vm._e(),_vm._v(" "),_c('ShipmentTrackReturnModal',{attrs:{"translations":_vm.translations,"return-trackable-items":_vm.returnTrackableItems},on:{"close":function($event){_vm.returnTrackableItems = []}}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }