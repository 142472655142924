
























































import type { PropType } from 'vue';
import { ref, computed, watch, defineComponent } from '@vue/composition-api';
import type {
  CheckoutLogInTranslations,
  FormValidationConfig,
  SignInFormTranslations,
} from '@vf/api-contract';
import { useCart, useNotification, useSignInToStore } from '@vf/composables';
import Notifications from '../layout/Notifications.vue';
import useRootInstance from '@/shared/useRootInstance';
import useModal from '@/shared/useModal';
import { useUserStore } from '@vf/composables/src/store/user';
import { storeToRefs } from 'pinia';
import SignInModal from '../../../components/modals/SignIn.vue';
import CreateAccountFormBirthdate from '../account/CreateAccountFormBirthdate.vue';

export default defineComponent({
  name: 'CheckoutLogin',
  components: {
    SignInModal,
    Notifications,
    CreateAccountFormBirthdate,
  },
  props: {
    /** CMS driven login modal */
    checkoutCmsLoginModal: {
      type: String,
      default: null,
    },
    translations: {
      type: Object as PropType<CheckoutLogInTranslations>,
      required: true,
    },
    signInModalTranslations: {
      type: Object as PropType<SignInFormTranslations>,
      required: true,
    },
    /** Validations for the form */
    validations: {
      type: Object as PropType<FormValidationConfig>,
      required: true,
    },
    interestsLink: {
      type: String,
      default: '',
    },
    /** Flag to redirect in cart after signIn */
    loginRedirectToCart: {
      type: Boolean,
      default: false,
    },
    /** Flag determining whether to show create account link or not */
    showCreateAccountLink: {
      type: Boolean,
      default: false,
    },
    /** Flag determining whether to show icon button that password in the input or not */
    showPasswordToggler: {
      type: Boolean,
      default: false,
    },
    /** Show sms subscription checkbox below phone number **/
    showSmsSubscription: Boolean,
    /** Flag determining whether to show recaptch after 2 sign in attempts or not */
    showCaptcha: {
      type: Boolean,
      default: false,
    },
    /** Create account link type */
    createAccountLinkType: {
      type: String,
      default: '',
    },
    contextKey: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const theme = root.$themeConfig.checkoutLogin;

    const checkoutLoginComponent = computed(() =>
      root.$viewport?.isSmall ? 'ThemeLink' : theme.checkoutLoginComponent
    );

    const size = computed(() => (root.$viewport?.isSmall ? '' : theme.size));

    const loginButtonClass = computed(() =>
      theme.loginButtonClass || root.$viewport?.isSmall
        ? 'shipping__log-in-button shipping__log-in-button--text'
        : 'shipping__log-in-button'
    );

    const loginTextAttributes = computed(() => ({
      content: props.translations.continueAsAGuest,
      level: 5,
      titleModifier: 'title-5',
    }));
    const userStore = useUserStore(root);
    const { loggedIn } = storeToRefs(userStore);
    const { addNotification, clearNotifications } = useNotification(root);
    const {
      isCartMerged,
      saveGuestCart,
      clearGuestCart,
      isCartMergeNotificationVisible,
    } = useCart(root);
    const { employeeConnected } = useSignInToStore(root);
    const { openModal, setNotificationVisibility } = useModal();

    const signUpVisible = ref(false);

    const onCloseModal = () => {
      clearNotifications();
      signUpVisible.value = false;
      setNotificationVisibility(false);
    };

    const onSignedIn = () => {
      signUpVisible.value = false;
      setNotificationVisibility(false);
    };

    const isCreateAccountModalVisible = ref(false);
    watch(isCartMerged, () => {
      if (isCartMergeNotificationVisible()) {
        addNotification({
          message: props.translations.cartMergedNotification,
          type: 'success',
        });

        clearGuestCart();
      }
    });

    const handleLoginClick = async () => {
      saveGuestCart();
      if (props.checkoutCmsLoginModal) {
        openModal({
          type: 'lazyFragment',
          resourceId: props.checkoutCmsLoginModal,
        });
      } else {
        signUpVisible.value = true;
        setNotificationVisibility(true);
      }
    };

    const isCreateAccountModal = computed(() => {
      return props.createAccountLinkType === 'Modal';
    });

    const onCreateAccountModalClose = () => {
      isCreateAccountModalVisible.value = false;
      clearNotifications();
    };

    const isNotLoggedIn = computed(() => {
      return !(loggedIn.value || employeeConnected.value);
    });

    return {
      minRegisterAge: root.$config.MINIMUM_REGISTER_AGE,
      theme,
      checkoutLoginComponent,
      size,
      loginTextAttributes,
      loginButtonClass,
      handleLoginClick,
      signUpVisible,
      isCreateAccountModalVisible,
      isCreateAccountModal,
      onCreateAccountModalClose,
      isNotLoggedIn,
      onSignedIn,
      onCloseModal,
    };
  },
});
