












































































































import type { PropType } from 'vue';
import { defineComponent, computed } from '@vue/composition-api';
import type { CheckoutOrderLineItem } from '@vf/api-client';
import type { OrderProductsSummaryTranslations } from '@vf/api-contract';
import { useUrl } from '@vf/composables';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import {
  getGiftOptionBindings,
  getGiftOptionOrderLabelText,
  getCacheKeyFromProps,
} from '@vf/shared/src/utils/helpers';
import useRootInstance from '@/shared/useRootInstance';
export default defineComponent({
  props: {
    translations: {
      type: Object as PropType<OrderProductsSummaryTranslations>,
      required: true,
    },
    /** Shipment Store Name */
    shipmentStore: String,
    shipmentItems: {
      type: Array as PropType<CheckoutOrderLineItem[]>,
      required: true,
    },
  },
  serverCacheKey: getCacheKeyFromProps,
  setup(props) {
    const { root } = useRootInstance();
    const theme = root.$themeConfig.orderDetails || {};
    const { isPricingStrikethroughEnabled } = useFeatureFlagsStore();
    const { getRelativeUrl } = useUrl(root);

    const getProductPrice = (product) => {
      const { currency, current, original, priceAfterItemDiscount, rowTotal } =
        product.price || {};
      const { qty = 1 } = product;

      // Adjust price after discount for bonus (GWP) items
      const priceAfterDiscount = product.bonus
        ? original
        : priceAfterItemDiscount;

      let unitPrice = root.$formatPrice(original, currency);
      let salePrice = root.$formatPrice(priceAfterDiscount / qty, currency);
      let totalPrice = root.$formatPrice(priceAfterDiscount, currency);

      if (unitPrice === salePrice) {
        salePrice = null;
      }

      if (!isPricingStrikethroughEnabled) {
        unitPrice = root.$formatPrice(current, currency);
        salePrice = null;
        totalPrice = root.$formatPrice(rowTotal, currency);
      }

      return {
        unitPrice,
        salePrice,
        totalPrice: product.bonus ? props.translations.free : totalPrice,
      };
    };

    const orderProducts = computed(() => {
      return props.shipmentItems.map((product) => ({
        ...product,
        pdpUrl: getRelativeUrl(product.pdpUrl),
        price: getProductPrice(product),
        productPromotions: (product.productPromotions || [])
          .map(({ calloutMsg }) => calloutMsg)
          .filter((message) => !!message),
        giftOption: getGiftOptionBindings(product, root),
        giftOptionOrderLabel: getGiftOptionOrderLabelText(
          product,
          root,
          props.translations
        ),
      }));
    });

    return {
      theme,
      isPricingStrikethroughEnabled,
      orderProducts,
    };
  },
});
