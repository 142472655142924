




















import type { PropType } from 'vue';
import { computed, onMounted, defineComponent } from '@vue/composition-api';
import { useAccount, useNotification, useI18n, ROUTES } from '@vf/composables';
import type { DataListingTableTranslations } from '@vf/api-contract/src/cmsPropsEntities';
import DataListingTable from '../shared/DataListingTable.vue';
import useRootInstance from '@/shared/useRootInstance';
import {
  DataProtectedInfo,
  getCardLogo,
  mapToDataProtectedInfo,
  mapToDataProtectedInfos,
} from '@/helpers';
import {
  formatExpirationYear,
  formatExpirationMonth,
} from '@vf/shared/src/utils/helpers';

export default defineComponent({
  name: 'CreditCardsSelect',
  components: {
    DataListingTable,
  },
  props: {
    /** Name of the context where component should be displayed */
    contextName: {
      type: String,
      default: 'new-credit-card',
    },
    translations: {
      type: Object as PropType<DataListingTableTranslations>,
      default: () => ({}),
    },
    /** Flag to determine if edit button should be shown */
    showEditButton: {
      type: Boolean,
      default: false,
    },
    /** Flag to determine if deletes button should be shown */
    showDeleteButton: {
      type: Boolean,
      default: true,
    },
    addCreditCardButtonLink: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const {
      paymentInstruments,
      defaultPaymentInstrument,
      getConsumerCreditCards,
      deletePaymentInstrument,
      setDefaultPaymentInstrument,
      newPaymentInstrumentAdded,
      setNewPaymentInstrumentAdded,
    } = useAccount(root);
    const { addNotification, clearNotifications } = useNotification(root);
    const { localePath } = useI18n(root);
    const themeConfig = root.$themeConfig?.creditCardsSelect;

    onMounted(() => {
      getConsumerCreditCards();

      if (newPaymentInstrumentAdded.value) {
        setNewPaymentInstrumentAdded(false);
        addNotification({
          type: 'success',
          message: props.translations.addSuccess,
        });
      }
    });

    const cards = computed(() =>
      paymentInstruments.value.cards.map((item) => {
        const name = getCreditCardInfo(
          props.translations.cardEndingIn,
          item.card.maskedNumber.substr(-4),
          props.translations.cardExpiration,
          formatExpirationMonth(item.card.expirationMonth),
          formatExpirationYear(item.card.expirationYear)
        );

        return {
          name,
          label: name.value,
          id: item.card.paymentInstrumentId,
          icon: getCardLogo(item.card.cardType),
          addressLines: item.address
            ? mapToDataProtectedInfos(item.address, [
                '{{addressLine1}} {{addressLine2}} {{city}}, ',
                '{{province}} {{postalCode}} {{country}}',
              ])
            : [],
          code: mapToDataProtectedInfo(item.address, '{{phone}}') ?? {},
          active: item.card.main,
        };
      })
    );

    const hideHeadings = computed(() => {
      return themeConfig.conditionallyShowHeading
        ? cards.value.length < 1
        : false;
    });

    const getCreditCardInfo = (
      endingLabel: string,
      cardEnding: string,
      expirationLabel: string,
      expirationMonth: string,
      expirationYear: string
    ): DataProtectedInfo => {
      return {
        value: `${endingLabel} ${cardEnding}, ${expirationLabel} ${expirationMonth}/${expirationYear}`,
        isProtected: true,
      };
    };

    const handleDeleteCard = async (item) => {
      clearNotifications();
      const response = await deletePaymentInstrument(item.id);
      if (response.status === 200) {
        await getConsumerCreditCards();
        addNotification({
          type: 'success',
          message: props.translations.deleteSuccess,
        });
      }
    };

    const handleChangeSelected = async (item) => {
      clearNotifications();
      const response = await setDefaultPaymentInstrument(item.id);
      if (response.status === 200) {
        addNotification({
          type: 'success',
          message: props.translations.updateSuccess,
        });
      }
    };

    const selectedItem = computed(() => {
      if (!defaultPaymentInstrument.value) {
        return '';
      }
      return defaultPaymentInstrument.value.card.paymentInstrumentId;
    });

    return {
      paymentInstruments,
      cards,
      selectedItem,
      handleDeleteCard,
      handleChangeSelected,
      hideHeadings,
      addCardPageLink:
        props.addCreditCardButtonLink || localePath(ROUTES.NEW_CREDIT_CARD()),
    };
  },
});
