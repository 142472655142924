






export default {
  name: 'SmartRow',
  props: {
    fullHeight: Boolean,
    nested: Boolean,
    contentPosition: {
      type: Object,
      default: () => ({
        small: 'left',
        medium: 'left',
        large: 'left',
      }),
    },
  },
};
