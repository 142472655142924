




























































































































































import type { PropType } from 'vue';
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
  nextTick,
} from '@vue/composition-api';
import type {
  AddressFormTranslations,
  CheckoutShippingInfoTranslations,
} from '@vf/api-contract';
import {
  useAccount,
  useCart,
  useCheckout,
  useNotification,
} from '@vf/composables';
import { scrollTo } from '@vf/shared/src/utils/helpers';
import debounce from '@vf/shared/src/utils/helpers/debounce';
import AddressForm from '../shared/AddressForm.vue';
import AddressSelection from '../../address/AddressSelection.vue';
import AddressSummary from '../../AddressSummary.vue';
import Notifications from '../layout/Notifications.vue';
import useRootInstance from '@/shared/useRootInstance';
import CheckoutProductsPreviewList from '@/components/checkout/CheckoutProductsPreviewList.vue';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@vf/composables/src/store/user';

export default defineComponent({
  name: 'CheckoutShippingInfo',
  components: {
    CheckoutProductsPreviewList,
    AddressForm,
    AddressSelection,
    AddressSummary,
    Notifications,
  },
  props: {
    addressFormTranslations: {
      type: Object as PropType<AddressFormTranslations>,
      required: true,
    },
    translations: {
      type: Object as PropType<CheckoutShippingInfoTranslations>,
      required: true,
    },
    /** Initial value for newsletter consent checkbox */
    newsletterSignUpAutoCheck: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const theme = root.$themeConfig.checkoutShippingInfo;

    const userStore = useUserStore(root);
    const { loggedIn } = storeToRefs(userStore);

    const {
      storeAddresses,
      shippingAddress,
      shippingFormAccordion,
      moveDefaultAddressToShipping,
      AVSisAddressInvalid,
      AVSchanged,
      AVSskip,
      setAVSSkip,
      setShippingAddressFromCart,
    } = useCheckout(root);

    const {
      getShippingMethods,
      defaultShippingAddress,
      isCartMerged,
      isProceedBillingButtonDisabled,
      hasShippingItems,
      giftedBasketId,
      hasPickupItems,
      itemsToShip,
      clearCartLevelCustomerNotifications,
      missingPhoneErrorDetail,
      setMissingPhoneErrorDetail,
      cartShippingMethods,
    } = useCart(root);

    const { addNotification, clearNotifications } = useNotification(root);

    // addresses
    const {
      getAddresses,
      addAddress,
      updateAddress,
      getBasicInformation,
      isAddAddressRequestPending,
    } = useAccount(root);
    const userShippingAddresses = getAddresses('S');
    const selectedUserShippingAddress = ref(null);
    const addressFormModal = ref('edit');
    const isAddressFormModalVisible = ref(false);
    const isAddressFormModalRerendered = ref(true);

    const addressSelectionAdditionalOption = () => [
      {
        value: 'open-address-modal',
        label:
          props.translations.addressSelectionAdditionalOption.openAddressModal,
      },
    ];

    onMounted(async () => {
      root.$on('address-form-blur', handleBlur);
      root.$eventBus.$on('go-to-payment-click', onGoToPaymentClick);

      if (loggedIn.value) {
        await setupLoggedInUserAddress();
      }
      if (!isGiftedBasket.value) {
        setShippingAddressFromCart(
          shippingAddress.value.subscriptions?.newsletterConsent ||
            props.newsletterSignUpAutoCheck
        );
      }

      clearCartLevelCustomerNotifications();

      if (missingPhoneErrorDetail.value) {
        // show error about missing phone number in the shipping address (returned
        // on order placement rejection, i.e. for accounts migrated from ECOM-1.0)
        // see: GLOBAL15-22061
        const message = props.translations.phoneNumberMissingNotification
          ? props.translations.phoneNumberMissingNotification
          : missingPhoneErrorDetail.value.additionalInfo.message;
        addNotification({
          message: message,
          type: 'danger',
        });
        setMissingPhoneErrorDetail(null);
      }
    });

    onBeforeUnmount(() => {
      root.$off('address-form-blur', handleBlur);
      root.$eventBus.$off('go-to-payment-click');
    });

    const setShippingAddressFromMain = () => {
      const mainAddress =
        userShippingAddresses.value.find((address) => address.main) ||
        userShippingAddresses.value[0];

      if (mainAddress?.addressLine1) {
        selectedUserShippingAddress.value = mainAddress;
        copyUserAddressToCheckoutAddress(selectedUserShippingAddress.value);
      } else {
        setShippingAddressFromCart();
      }
    };

    const setupLoggedInUserAddress = async () => {
      await getBasicInformation();
      const addressExists = userShippingAddresses.value.find((address) => {
        const addressIds = cartShippingMethods.value.map(
          (method) => method.address.addressId
        );
        return addressIds.includes(address.id);
      });

      if (addressExists) {
        setShippingAddressFromCart(false);
      } else {
        setShippingAddressFromMain();
      }
      await updateShippingAddress();
    };

    const copyUserAddressToCheckoutAddress = (address) => {
      const applyNewsletterConsent =
        (!loggedIn.value && props.newsletterSignUpAutoCheck) ||
        !!address.subscriptions?.newsletterConsent;
      shippingAddress.value = {
        ...address,
        subscriptions: {
          newsletterConsent: applyNewsletterConsent,
        },
      };
    };

    const selectUserAddress = async (addressId) => {
      setAVSSkip(false);
      AVSisAddressInvalid.value = false;
      selectedUserShippingAddress.value = userShippingAddresses.value.find(
        (item) => item.id === addressId
      );
      copyUserAddressToCheckoutAddress(selectedUserShippingAddress.value);
      await updateShippingAddress();
      addressFormModal.value = 'edit';
      reRenderAddressFormModal(isAddressFormModalVisible.value);
    };

    const onGoToPaymentClick = async () => {
      root.$eventBus.$emit('validate-and-go-to-payment');
    };

    const reRenderAddressFormModal = (show = false) => {
      // as modal is initially mounted with all its contents
      // and hidden, to trigger selected address validation
      // we need to force its re-rendering each time type of
      // address changes (new/edit) to avoid displaying error
      // messages without interaction with the form
      isAddressFormModalRerendered.value = false;
      nextTick(() => {
        isAddressFormModalRerendered.value = true;
        isAddressFormModalVisible.value = show;
      });
    };

    const openAddressModal = (type) => {
      addressFormModal.value = type;
      reRenderAddressFormModal(true);
    };

    const closeAddressModal = () => {
      clearNotifications();
      addressFormModal.value = 'edit';
      isAddressFormModalVisible.value = false;
    };

    const isGiftedBasket = computed(() => {
      return giftedBasketId.value || null;
    });

    const addressModelData = computed(() =>
      addressFormModal.value === 'edit'
        ? userShippingAddresses.value.find(
            (item) => item.id === shippingAddress.value.id
          )
        : {
            addressLine1: null,
            addressLine2: null,
            approachType: 'B',
            city: null,
            country: root
              .$getEnvValueByCurrentLocale<string>('COUNTRY')
              .toUpperCase(),
            email: null,
            firstName: null,
            lastName: null,
            main: false,
            phone: null,
            postalCode: null,
            province: null,
            recipientContactEmail: null,
            recipientContactPhone: null,
            recipientFirstName: null,
            recipientLastName: null,
            subscriptions: {
              newsletterConsent:
                !loggedIn.value && props.newsletterSignUpAutoCheck,
            },
          }
    );

    const handleSubmitAddressForm = async (address) => {
      isAddAddressRequestPending.value = true;
      try {
        const data = {
          ...address,
          approachType: 'S',
        };
        const response = await (addressFormModal.value === 'edit'
          ? updateAddress(shippingAddress.value.id, data)
          : addAddress(data));
        await getBasicInformation();
        await selectUserAddress(response.data.addressId);
        closeAddressModal();
      } catch (err) {
        console.error(err);
      } finally {
        isAddAddressRequestPending.value = false;
      }
    };

    const handleBlur = debounce(({ field }) => {
      // Check if there was a change of field value on blur
      if (
        shippingAddress.value[field] !== defaultShippingAddress.value[field]
      ) {
        updateShippingAddress(field);
        setAVSSkip(false);
      }
    }, 300);

    const updateShippingAddress = async (changedField?) => {
      isProceedBillingButtonDisabled.value = true;
      await storeAddresses();
      if (shouldUpdateShippingMethods(changedField)) {
        // TODO: change default shipping id 'me' with multishipping
        await getShippingMethods('me', {
          isBackgroundRequest: false,
          isTemporary: false,
        });
        isProceedBillingButtonDisabled.value = false;
      } else {
        isProceedBillingButtonDisabled.value = false;
      }
    };

    const shouldUpdateShippingMethods = (field) => {
      // No field provided means that the whole object has changed,
      // shipping method update should be performed
      if (!field) return true;
      // List of required fields for shipping method update
      const requiredFields = ['province', 'addressLine1', 'postalCode'];
      // We check here if the changed field is in the required fields and it is not empty
      const fieldIsNotEmpty = !!shippingAddress.value[field];
      const fieldIsRequired = requiredFields.includes(field);
      return fieldIsRequired && fieldIsNotEmpty;
    };

    const isNewsletterTooltipVisible = ref(false);
    const toggleNewsletterTooltip = () =>
      (isNewsletterTooltipVisible.value = !isNewsletterTooltipVisible.value);
    const isMobile = computed(() => root.$viewport?.size === 'small');
    const showNewsletterTooltip = computed(
      () => isMobile.value && theme?.showNewsletterTooltipOnMobile
    );
    const newsletterConsentLabel = computed(
      () =>
        props.translations[`marketingOptIn${isMobile.value ? 'Mobile' : ''}`]
    );

    watch(isCartMerged, () => {
      setupLoggedInUserAddress();
    });

    watch(AVSisAddressInvalid, (curr) => {
      if (curr) {
        scrollTo();
      }
    });

    watch(defaultShippingAddress, (current, prev) => {
      if (prev === null && current !== null) {
        moveDefaultAddressToShipping(
          !loggedIn.value && props.newsletterSignUpAutoCheck
        );
      }
    });

    return {
      theme,
      loggedIn,
      shippingAddress,
      shippingFormAccordion,
      AVSisAddressInvalid,
      AVSchanged,
      AVSskip,
      handleBlur,
      selectUserAddress,
      selectedUserShippingAddress,
      userShippingAddresses,
      openAddressModal,
      closeAddressModal,
      addressFormModal,
      handleSubmitAddressForm,
      addressModelData,
      addressSelectionAdditionalOption,
      hasShippingItems,
      isGiftedBasket,
      updateShippingAddress,
      hasPickupItems,
      itemsToShip,
      setAVSSkip,
      showNewsletterTooltip,
      newsletterConsentLabel,
      isNewsletterTooltipVisible,
      toggleNewsletterTooltip,
      isMobile,
      isAddressFormModalVisible,
      isAddressFormModalRerendered,
    };
  },
});
