



import { defineComponent, onMounted, ref, watch } from '@vue/composition-api';
import { scrollTo } from '@vf/shared/src/utils/helpers';
import { useRoute } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'Anchor',
  props: {
    id: {
      type: String,
      required: true,
      default: '',
    },
  },
  setup(props) {
    const route = useRoute();
    const element = ref();

    const scroll = () => {
      const { left, top } = element.value.getBoundingClientRect();
      scrollTo({
        left,
        top,
      });
    };

    onMounted(() => {
      if (`#${props.id}` === route.value.hash) {
        setTimeout(scroll, 1000);
      }

      watch(route, (to) => {
        if (`#${props.id}` === to.hash) {
          scroll();
        }
      });
    });

    return {
      element,
    };
  },
});
