




import { defineComponent } from '@vue/composition-api';
import { ProductCard } from '@gemini/pollux';

export default defineComponent({
  name: 'GeminiProductCard',
  components: {
    'gemini-product-card': ProductCard,
  },
  props: {
    pattern: {
      type: String,
      required: false,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
    price: {
      type: String,
      required: true,
    },
    price2: String,
    salesPrice: String,
    url: {
      type: String,
      required: true,
    },
    imgUrl: {
      type: String,
      required: true,
    },
    imgH: String,
    imgW: String,
    imgAlt: {
      type: String,
      required: true,
    },
  },
});
