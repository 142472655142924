import { onBeforeUnmount, onMounted, Ref, ref } from '@vue/composition-api';

type UseChatReturn = {
  loaded: Ref<boolean>;
  dispatchChat: () => void;
};

export function useChat(): UseChatReturn {
  const loaded = ref(false);
  let intervalId = null;

  onMounted(() => {
    intervalId = setInterval(() => {
      loaded.value =
        typeof window.liveChatAvailable !== 'undefined'
          ? window.liveChatAvailable
          : false;
    }, 5000);
  });

  onBeforeUnmount(() => {
    if (intervalId) {
      clearInterval(intervalId);
    }
  });

  const dispatchChat = () => {
    const event = new Event('chat-initiated');
    window.dispatchEvent(event);
  };

  return {
    loaded,
    dispatchChat,
  };
}
