










import type { PropType } from 'vue';
import { defineComponent } from '@vue/composition-api';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';
import type { SocialAccountLinkTranslations } from '@vf/api-contract';

export default defineComponent({
  name: 'SocialMediaLink',
  serverCacheKey: getCacheKeyFromProps,
  props: {
    translations: {
      type: Object as PropType<SocialAccountLinkTranslations>,
      default: () => ({}),
    },
    /** Link to be applied to social account integration */
    link: {
      type: String,
      default:
        'https://images.vans.com/is/image/Vans/facebook?$SCALE-ORIGINAL$',
    },
  },
});
