
































import {
  defineComponent,
  onMounted,
  PropType,
  ref,
} from '@vue/composition-api';
import useRootInstance from '@/shared/useRootInstance';
import { mask } from 'ke-the-mask';
import { BuyInStoreInfoTranslations } from '@vf/api-contract';
import { validationMixin } from 'vuelidate';
import { required, maxLength } from 'vuelidate/lib/validators';
import { useValidation, useSignInToStore } from '@vf/composables';

export default defineComponent({
  name: 'BuyInStoreInfo',
  directives: {
    mask,
  },
  mixins: [validationMixin],
  props: {
    translations: {
      type: Object as PropType<BuyInStoreInfoTranslations>,
      default: () => ({
        associateIdLabel: '$Associate ID$',
        associateIdRequiredError: '$The Associate ID field is required$',
        storeIdLabel: '$Store ID$',
      }),
    },
  },
  setup() {
    const { root } = useRootInstance();
    const { setValidation } = useValidation(root, 'buyInStoreInfo');
    const {
      hasStoreCookie,
      storeInfoData,
      inStoreSalesInfo,
    } = useSignInToStore(root);

    const storeId = storeInfoData?.value?.storeName ?? '';
    const storeEmployeeId = ref(inStoreSalesInfo.value.storeEmployeeId);
    const updateInStoreSalesInfo = () => {
      inStoreSalesInfo.value.storeEmployeeId = storeEmployeeId.value;
    };

    onMounted(() => {
      inStoreSalesInfo.value.storeId = storeInfoData?.value?.storeId;
    });

    return {
      storeEmployeeId,
      setValidation,
      hasStoreCookie,
      storeId,
      updateInStoreSalesInfo,
    };
  },
  mounted() {
    this.setValidation(this.$v);
  },
  validations: {
    storeEmployeeId: {
      required,
      maxLength: maxLength(6),
    },
  },
});
