























import type { PropType } from 'vue';
import { computed, defineComponent } from '@vue/composition-api';
import { useProduct, useUrl } from '@vf/composables';
import type { IconPosition, Icons } from '@vf/api-contract';
import useRootInstance from '@/shared/useRootInstance';
import ProductCustomizeCta from '../../pdp/ProductCuztomizeCta.vue';

export default defineComponent({
  name: 'ProductCustomize',
  components: { ProductCustomizeCta },
  props: {
    /** Flag to determine if button should be enabled */
    enabled: {
      type: Boolean,
      default: true,
    },
    /** Button text */
    text: {
      type: String,
      default: '',
    },
    icon: {
      type: String as PropType<Icons>,
      default: 'customize',
    },
    /** Button size  */
    buttonSize: {
      type: String as PropType<'sm' | 'md' | 'lg'>,
      default: 'md',
    },
    /** Button style */
    buttonStyle: {
      type: Object as PropType<{ color: string; variant?: string }>,
      default: () => ({ color: 'primary' }),
    },
    /** Flag to determine if button text should be underline */
    underline: {
      type: Boolean,
      default: false,
    },
    iconPosition: {
      type: String as PropType<IconPosition>,
      default: '',
    },
    /** CSS class modifiers for text from teaser styles */
    textCustomClasses: {
      type: String,
      default: '',
    },
    isFullWidth: {
      type: Boolean,
      default: false,
    },
    contextKey: {
      type: String,
      default: 'product',
    },
    buttonComponent: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { product } = useProduct(root, props.contextKey);
    const { getProductUrl } = useUrl(root);

    const customizerLink = computed(() => {
      if (
        product.value.customsExternalurl ??
        product.value.customsExternalUrl
      ) {
        return getProductUrl(
          {
            ...product.value,
            customsCustomizer: true,
          },
          root.$route.query as { [key: string]: string }
        );
      }
      return null;
    });

    return {
      customizerLink,
    };
  },
});
