











































import type { PropType } from 'vue';
import { computed, defineComponent } from '@vue/composition-api';
import type { ProductDescriptionTranslations } from '@vf/api-contract';
import { useProduct } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import { mapProductDetails, ProductDetail } from '@/helpers/mapProductDetails';

export default defineComponent({
  name: 'ProductDescription',
  props: {
    translations: {
      type: Object as PropType<ProductDescriptionTranslations>,
      default: () => ({}),
    },
    /** Select with page type */
    contextKey: {
      type: String,
      default: 'product',
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { product } = useProduct(root, props.contextKey);

    const productDetails = computed<ProductDetail[]>(() => {
      return mapProductDetails(product.value?.productDetails);
    });

    return {
      product,
      productDetails,
    };
  },
});
