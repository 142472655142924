import { ref, computed, ComputedRef, onMounted } from '@nuxtjs/composition-api';
import {
  EventType,
  getEventFromTemplate,
} from '@vf/composables/src/useGtm/helpers';
import useGtm from '@vf/composables/src/useGtm';
import useRootInstance from '@/shared/useRootInstance';
import { SignUpRequest } from '@vf/api-client/src/api-types';
import merge from '@vf/composables/src/utils/merge';

type ArgumentTypes<F> = F extends (...args: infer A) => unknown ? A : never;

type Authenticate = (...args: unknown[]) => Promise<unknown>;

type SignInOptions = {
  isAutomaticLogin: boolean;
};

type UseAuthenticationEvents = {
  createSignUp: <T extends Authenticate>(
    authenticate: T
  ) => (
    args?: SignUpRequest,
    overrideAttributes?: Record<string, unknown>
  ) => Promise<ReturnType<T>>;
  createSignIn: <T extends Authenticate>(
    authenticate: T,
    options?: SignInOptions
  ) => (...args: ArgumentTypes<T>) => Promise<ReturnType<T>>;
  loading: ComputedRef<boolean>;
  getEventData: (
    event: EventType,
    overrideAttributes?: Record<string, unknown>
  ) => any;
};

export enum AuthenticationType {
  Registration = 'Registration',
  Login = 'Login',
}

type Options = {
  useLoadEvent: boolean;
  type: AuthenticationType;
};

const templateMap = {
  [AuthenticationType.Registration]: 'create-account',
  [AuthenticationType.Login]: 'login',
} as const;

const useAuthenticationEvents = (
  formLocation: string,
  options?: Options
): UseAuthenticationEvents => {
  const { useLoadEvent = false, type } = options ?? {};
  const { root } = useRootInstance();

  const loading = ref(false);

  const { dispatchEvent } = useGtm(root);

  const getEventData = (
    event: EventType,
    overrideAttributes?: Record<string, unknown>
  ) => {
    const eventFromTemplate = getEventFromTemplate(event, {
      formLocation,
    });

    return overrideAttributes
      ? merge(eventFromTemplate, overrideAttributes)
      : eventFromTemplate;
  };

  useLoadEvent &&
    onMounted(() => {
      dispatchEvent(getEventData(`${templateMap[type]}:onload` as EventType));
    });

  const createSignUp = <T extends Authenticate>(signUp: T) => async (
    userPayload?: SignUpRequest,
    overrideAttributes?: Record<string, unknown>
  ): Promise<ReturnType<T>> => {
    !userPayload?.skipSubmit &&
      dispatchEvent(
        getEventData('create-account:onsubmit', overrideAttributes)
      );

    try {
      loading.value = true;
      const result = await signUp(userPayload);
      const createAccountConfirmedEvent = getEventData(
        'create-account:confirmed',
        overrideAttributes
      );

      if (userPayload?.isTermsConfirmed) {
        createAccountConfirmedEvent.overrideAttributes.customVariables = {
          ...createAccountConfirmedEvent.overrideAttributes.customVariables,
          loyaltySignUp: 1,
        };
      }

      dispatchEvent(createAccountConfirmedEvent);

      return result;
    } finally {
      loading.value = false;
    }
  };

  const createSignIn = <T extends Authenticate>(
    signIn: T,
    options?: SignInOptions
  ) => async (...args: ArgumentTypes<T>): Promise<ReturnType<T>> => {
    const { isAutomaticLogin = false } = options ?? {};

    const eventType = isAutomaticLogin ? 'autologin' : 'login';

    dispatchEvent(getEventData(`${eventType}:onsubmit` as EventType));

    try {
      loading.value = true;
      const result = await signIn(...args);

      dispatchEvent({
        eventName: 'loadUserData',
      });

      dispatchEvent(getEventData(`${eventType}:confirmed` as EventType));
      dispatchEvent(getEventFromTemplate('cart:update', {}));

      return result;
    } finally {
      loading.value = false;
    }
  };

  return {
    createSignUp,
    createSignIn,
    loading: computed(() => loading.value),
    getEventData,
  };
};

export default useAuthenticationEvents;
