





























import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'CartTopPromo',
  props: {
    /** Free shipping heading text */
    headingText: {
      type: String,
      default: 'FREE SHIPPING AND RETURNS!',
    },
    /** Image to be displayed instead of promo */
    imageObject: {
      type: Object,
      default: null,
    },
  },

  computed: {
    imageHeight() {
      const DEFAULT_HEIGHT = 200;
      return this.imageObject.images?.height ?? DEFAULT_HEIGHT;
    },

    imageWidth() {
      const DEFAULT_WIDTH = 150;
      return this.imageObject.images?.width ?? DEFAULT_WIDTH;
    },
  },
});
