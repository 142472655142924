









import { ref, defineComponent } from '@vue/composition-api';

const customsPrepared = ref(false);
const customsPrepareStarted = ref(false);

export default defineComponent({
  name: 'CustomsPreloader',
  props: {
    preloadedComponent: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      customsPrepared,
    };
  },
  async beforeCreate() {
    if (this.$prepareCustoms && !customsPrepareStarted.value) {
      customsPrepareStarted.value = true;
      await this.$prepareCustoms();
      customsPrepared.value = true;
    } else if (!this.$prepareCustoms) {
      customsPrepared.value = true;
    }
  },
});
