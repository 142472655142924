import type { Ref } from '@vue/composition-api';
import type { ApplePayTranslations, UpsellsItem } from '@vf/api-contract';
import type { Product } from '@vf/api-client';
import type { ComponentInstance, ComposablesStorage } from '../types';
import type { UpsellItem } from '../useUpsell';
import type {
  ApplePayLineItem,
  ApplePayPaymentContact,
  ApplePayShippingMethod,
  paymentRequestType,
  ShippingContact,
  useApplePayStorage,
} from './types';

import { storeToRefs } from 'pinia';
import { computed, ref } from '@vue/composition-api';
import { apiClientFactory, PaymentMethodCode } from '@vf/api-client';
import useCheckout from '../useCheckout';
import { useApplePayPdpCart, useCart } from '../useCart';
import { useI18n } from '../useI18n';
import useNotification from '../useNotification';
import { useRequestTracker } from '../useRequestTracker';
import { useUtilities } from '../useUtilities';
import { ApplePayContext } from './types';
import initStorage from '../utils/storage';
import { maskGiftCardNumber } from '../utils/giftCard';
import {
  getDeliveryDateLabel,
  checkName,
  NAME_MAX_LENGTH,
} from '@vf/shared/src/utils/helpers';
import { useCartStore } from '../store/cartStore';
import { useFeatureFlagsStore } from '../store/featureFlags';
import { useAddressValidationServiceStore } from '../store/addressValidationService';

declare const ApplePayError: any;
declare const ApplePaySession: any;
const APPLE_PAY_VERSION = 10;
const DEFAULT_ADDRESS = {
  emailAddress: '',
  familyName: '',
  givenName: '',
  phoneNumber: '',
  phoneticFamilyName: '',
  phoneticGivenName: '',
  addressLines: ['', ''],
  locality: '',
  subLocality: '',
  administrativeArea: '',
  subAdministrativeArea: '',
  postalCode: '',
  country: '',
  countryCode: '',
};

const createApplePaySession = (payload, onError) => {
  try {
    return new ApplePaySession(APPLE_PAY_VERSION, payload);
  } catch (e) {
    onError(e);

    throw e;
  }
};

export const useApplePay = (
  instance: ComponentInstance,
  contextKey?: ApplePayContext
) => {
  const {
    getCartPaymentInstruments: getCartPaymentInstrumentsAPI,
    getPaymentSession: getPaymentSessionAPI,
    getProvinceList: getProvinceListApi,
  } = apiClientFactory(instance);
  const { isApplePayEnabled } = useFeatureFlagsStore();
  const addressValidationServiceStore = useAddressValidationServiceStore();
  const {
    isAVSRejected,
    confirmAddressAndClearAVSStorage,
  } = addressValidationServiceStore;

  const storage: ComposablesStorage<useApplePayStorage> = initStorage<useApplePayStorage>(
    instance,
    `useApplePay-${contextKey}`
  );

  const paymentRequestRef: Ref<paymentRequestType> =
    storage.get('paymentRequest') ?? storage.save('paymentRequest', ref({}));

  const postalCodeValidationRegex: Ref<string | null> =
    storage.get('postalCodeValidationRegex') ??
    storage.save('postalCodeValidationRegex', ref(null));

  /**
   * Use different context key (separate storage) for sub composable only when it's Apple Pay on PDP.
   */
  let contextKeyForSubComposable;
  if (contextKey === ApplePayContext.PDP) {
    contextKeyForSubComposable = contextKey;
  }

  const {
    defaultCurrency,
    getStaticTranslation,
    localeCode,
    localeMapping,
  } = useI18n(instance);
  const localeData = {
    localeCode: localeCode(),
    localeMapping: localeMapping(),
  };

  const {
    cart,
    shippingGroups,
    setShippingMethod,
    getShippingMethods,
    hasPickupItems,
    addItem,
  } = contextKeyForSubComposable
    ? useApplePayPdpCart(instance)
    : useCart(instance);
  const {
    shippingAddress,
    billingAddress,
    validateAddress,
    checkAddressChanges,
    setShippingAddress,
    saveShippingAddress,
    saveBillingAddress,
    paymentMethod,
    placeOrder,
    applePayOrderToken,
    paymentMethodsData,
    setPaymentMethod,
  } = useCheckout(instance, contextKeyForSubComposable);
  const { getCountry, getI18n } = useUtilities(instance);
  const { addNotification } = useNotification(instance);
  const narvarTranslations = getStaticTranslation('narvar');
  const { trackRequest, clearRequest } = useRequestTracker(instance);

  const { athleteDiscounts } = storeToRefs(useCartStore());
  const { AVSchanged } = storeToRefs(addressValidationServiceStore);

  const getProvinceListResponse = ref(null);

  const applePayConf = instance.$getEnvValueByCurrentLocale<any>(
    'APPLE_PAY_CONF'
  );

  const isApplePayApiDefined = (): boolean => {
    return typeof ApplePaySession !== 'undefined';
  };

  const isAddressValid = async (
    translations: ApplePayTranslations,
    address,
    isBilling = false
  ): Promise<boolean> => {
    if (!checkName()(address.lastName) || !checkName()(address.firstName)) {
      addNotification({
        message: translations.nameFormatError,
        type: 'danger',
      });
      return false;
    }
    if (
      address.lastName.length > NAME_MAX_LENGTH ||
      address.firstName.length > NAME_MAX_LENGTH
    ) {
      addNotification({
        message: translations.nameLengthError.replace(
          '{{nameLengthLimit}}',
          NAME_MAX_LENGTH.toString()
        ),
        type: 'danger',
      });
      return false;
    }
    if (!address.addressLine1?.trim().length) {
      addNotification({
        message: translations.addressFormatError,
        type: 'danger',
      });
      return false;
    }

    if (isBilling) {
      const i18n = await getI18n(
        address.countryCode.toLowerCase(),
        instance.$i18n.locale,
        'billingAddress'
      );
      const {
        validation: { pattern },
      } = i18n.fields.find(({ fieldId }) => fieldId === 'postalCode');
      if (pattern && !address.postalCode.match(pattern)) {
        return false;
      }
    }

    return isProvinceValid(address);
  };

  const getProvinceList = async () => {
    try {
      /** Get provinces list, as it contains postal code regex as well */
      getProvinceListResponse.value =
        (await getProvinceListApi(getCountry(), instance.$i18n.locale)) || null;
      /** Set postal code regex value. It is used later when authorizing payment */
      postalCodeValidationRegex.value =
        getProvinceListResponse?.value.data?.postCodeRegEx || null;
    } catch (e) {
      getProvinceListResponse.value = null;
      /** In case of any error - unset regex value, it will be skipped during payment authorization */
      postalCodeValidationRegex.value = null;
    }
  };

  const isProvinceValid = async (address): Promise<boolean> => {
    let provinces = getProvinceListResponse.value?.data?.provinces;
    /** Get provinces if the country is the different.
     * For example in case of vans.com the different country is CA
     */
    if (address.countryCode !== getCountry().toLocaleUpperCase()) {
      try {
        provinces =
          (await getProvinceListApi(
            address.countryCode,
            instance.$i18n.locale
          )) || null;
        /** Sometimes we don't have any provinces, in this case need to remove validation */
        if (!provinces?.length) return true;
      } catch (error) {
        return true;
      }
    }
    if (
      address.province &&
      provinces?.map((item) => item.code)?.includes(address.province)
    ) {
      return true;
    }
    addNotification({
      message: '',
      type: 'danger',
    });
    return false;
  };

  const isApplePayAvailable = (): boolean => {
    return (
      /** The base feature flag is enabled */
      isApplePayEnabled &&
      // ApplePay is configured
      !!applePayConf &&
      /** ApplePay API is available */
      isApplePayApiDefined() &&
      /** There are no BOPIS items in the cart */
      !hasPickupItems.value &&
      /** There are not custom products in the cart */
      !cart.value?.items?.some(
        (product) =>
          !!(product.customsRecipeID || product.recipe || product.recipeId)
      )
    );
  };

  // Update payment request data
  const updatePaymentRequest = (data: paymentRequestType): void => {
    paymentRequestRef.value = { ...paymentRequestRef.value, ...data };
  };

  const getProductPriceValue = (product: Product | UpsellItem) => {
    const isUpsellItem = (item: Product | UpsellItem): item is UpsellItem => {
      return (<UpsellItem>item).upsell !== undefined;
    };
    if (isUpsellItem(product)) {
      return product?.price?.current || 0;
    }
    return product?.variant?.price?.current || 0;
  };

  const getValueOfProductsToBeAdded = (
    productsToBeAddedToCart?: Product | (Product | UpsellsItem)[]
  ) => {
    const productsToBeAdded = [].concat(productsToBeAddedToCart);

    return productsToBeAdded
      .reduce((total, product: Product | UpsellItem) => {
        return total + getProductPriceValue(product);
      }, 0)
      .toFixed(2);
  };

  const getPaymentTotal = (
    translations: ApplePayTranslations,
    productsToBeAddedToCart?: Product | (Product | UpsellsItem)[]
  ): ApplePayLineItem => {
    /** Calculate total amount based on cart state */
    let cartPaymentTotal =
      cart.value.totals.remainingToPay || cart.value.totals.total;

    /** If cart value is not set and there are pending products to be added - display products pre-calculated total value */
    if (!parseInt(String(cartPaymentTotal)) && productsToBeAddedToCart) {
      cartPaymentTotal = getValueOfProductsToBeAdded(productsToBeAddedToCart);
    }

    return {
      label: instance.$env.WEBSITE_NAME || translations.total,
      amount: String(cartPaymentTotal),
      type: 'final',
    };
  };

  const getLineItems = (
    translations: ApplePayTranslations,
    productsToBeAddedToCart?: Product | (Product | UpsellsItem)[]
  ): ApplePayLineItem[] => {
    /** If cart value is not set and there are pending products to be added - display products pre-calculated total value */
    let productsValue = 0;
    if (!cart.value.totals.itemTotal && productsToBeAddedToCart) {
      productsValue = getValueOfProductsToBeAdded(productsToBeAddedToCart);
    }

    const lineItems: ApplePayLineItem[] = [
      {
        label: translations.subtotal,
        amount: `${cart.value.totals.itemTotal || productsValue}`,
        type: 'final',
      },
      {
        label: translations.tax,
        amount: `${cart.value.totals.tax}`,
      },
      {
        label: translations.shipping,
        amount: `${cart.value.totals.shipping}`,
      },
    ];

    if (cart.value.totals.totalDiscount) {
      lineItems.push({
        label: translations.discount,
        amount: `${cart.value.totals.totalDiscount}`,
      });
    }

    if (athleteDiscounts.value.length) {
      lineItems.push({
        label: translations.athleteCreditLabel,
        amount: `${athleteDiscounts.value[0].amount}`,
      });
    }

    /** List every applied gift card as a line item */
    cart.value.payment_instruments?.forEach((instrument) => {
      if (instrument.payment_method_id === PaymentMethodCode.GIFT_CARD) {
        lineItems.push({
          label: `${translations.giftCard} - ${maskGiftCardNumber(
            instrument.c_gcCardNumber
          )}`,
          amount: '-' + instrument.amount,
        });
      }
    });

    return lineItems;
  };

  const getApplePayShippingMethods = (): ApplePayShippingMethod[] => {
    return shippingGroups.value.reduce((allMethods, group) => {
      if (group.shippingId === 'me') {
        return allMethods.concat(
          group.methods
            .map((method) => ({
              label: method.label,
              amount: '' + method.price.finalAmount,
              detail:
                getDeliveryDateLabel(
                  method.deliveryTime,
                  narvarTranslations.deliveryDateLabel,
                  false,
                  false,
                  localeData
                ) || '',
              identifier: method.code,
            }))
            /** First item needs to be currently selected shipping method */
            .sort((a) => (a.identifier === group.code ? -1 : 0))
        );
      }
    }, [] as ApplePayShippingMethod[]);
  };

  const refreshPaymentRequestWithCurrentCartData = (
    translations: ApplePayTranslations,
    overrideAddress = false,
    productsToBeAddedToCart?: Product | (Product | UpsellsItem)[]
  ) => {
    updatePaymentRequest({
      countryCode: instance
        .$getEnvValueByCurrentLocale<string>('COUNTRY')
        .toUpperCase(),
      currencyCode: cart.value.currency || defaultCurrency.value,
      shippingContactEditingMode: 'enabled',
      lineItems: getLineItems(translations, productsToBeAddedToCart),
      merchantCapabilities: applePayConf?.merchant_capabilities || [],
      supportedNetworks: applePayConf?.supported_networks || [],
      requiredBillingContactFields:
        applePayConf?.required_billing_contact_fields || [],
      requiredShippingContactFields:
        applePayConf?.required_shipping_contact_fields || [],
      total: getPaymentTotal(translations, productsToBeAddedToCart),
      shippingType: 'shipping',
      shippingMethods: getApplePayShippingMethods(),
      shippingContact: overrideAddress
        ? mapCartShippingAddressToApplePay()
        : undefined,
    });
  };

  // Initialize ApplePay session
  const initApplePaySession = (
    translations: ApplePayTranslations,
    onInitialLoadingFinished: () => void,
    overrideAddress = false,
    productsToBeAddedToCart?: Product | (Product | UpsellsItem)[]
  ) => {
    const applePaySessionQuery: string[] = [];
    if ([ApplePayContext.PDP, ApplePayContext.CART].includes(contextKey)) {
      applePaySessionQuery.push(
        `context=${PaymentMethodCode.APPLE_PAY_EXPRESS}`
      );
    }
    if (!isApplePayAvailable()) {
      onInitialLoadingFinished();
      return;
    }

    refreshPaymentRequestWithCurrentCartData(
      translations,
      overrideAddress,
      productsToBeAddedToCart
    );

    const session = createApplePaySession(paymentRequestRef.value, (e) => {
      onInitialLoadingFinished();
      instance.$log.error('Could not initialise ApplePaySession: ', e);
    });

    /** Shipping Method change callback */
    session.onshippingmethodselected = async (event) => {
      const shippingMethod: ApplePayShippingMethod = event.shippingMethod;

      await setShippingMethod({
        code: shippingMethod.identifier,
        shippingId: 'me',
        isTemporary: [ApplePayContext.PDP].includes(contextKey),
        shopperAppliedSM: true,
        isApplePayExpress: [ApplePayContext.PDP, ApplePayContext.CART].includes(
          contextKey
        ),
      });

      session.completeShippingMethodSelection({
        newTotal: getPaymentTotal(translations),
        newLineItems: getLineItems(translations),
      });
    };

    /** Shipping Address change callback */
    session.onshippingcontactselected = async (event) => {
      await getProvinceList();
      saveShippingAddress(mapAddressData(event?.shippingContact)[0]);
      await setShippingAddress(
        mapAddressData(event?.shippingContact),
        {
          updateCartData: true,
          isTemporary: contextKey === ApplePayContext.PDP,
        },
        applePaySessionQuery
      );

      await getShippingMethods('me', {
        isBackgroundRequest: false,
        isTemporary: contextKey === ApplePayContext.PDP,
      });

      session.completeShippingContactSelection({
        newTotal: getPaymentTotal(translations),
        newLineItems: getLineItems(translations),
        newShippingMethods: getApplePayShippingMethods(),
      });
    };

    /** Session validation callback */
    session.onvalidatemerchant = async () => {
      const res = await getPaymentSessionAPI({
        referer: instance.$getDomainName().replace(/(^\w+:|^)\/\//, ''),
        paymentMethodId: PaymentMethodCode.APPLEPAY,
      });

      onInitialLoadingFinished();

      if (productsToBeAddedToCart) {
        const productAddedSuccessfully = await addItem(
          productsToBeAddedToCart,
          { isTemporary: true }
        );
        if (!productAddedSuccessfully) {
          return session.completeMerchantValidation({
            status: ApplePaySession.STATUS_FAILURE,
            errors: [new ApplePayError('unknown')],
          });
        }
        refreshPaymentRequestWithCurrentCartData(translations, false);
      }

      /** Fetch postal code validation regex. Do not put async in here to not block apple payment sheet loading */
      if (!postalCodeValidationRegex.value) getProvinceList();

      session.completeMerchantValidation(res.data.meta.merchantSession);
    };

    /** Payment authorization callback */
    session.onpaymentauthorized = async (event) => {
      /** Check zip code in the first place */
      if (
        /** Zip pattern exists for current language */
        postalCodeValidationRegex.value &&
        /** Zip code is not defined or does not pass regex check */
        !event?.payment?.shippingContact?.postalCode?.match(
          postalCodeValidationRegex.value
        )
      ) {
        return session.completePayment({
          status: ApplePaySession.STATUS_FAILURE,
          errors: [new ApplePayError('shippingContactInvalid', 'postalCode')],
        });
      }

      let isPaymentInstrumentValid = false;

      try {
        /** No need to check for Apple Pay on PDP based on ISD */
        if (contextKey === ApplePayContext.PDP) {
          isPaymentInstrumentValid = true;
        } else {
          /** Double check based on ISD to determine if Apple Pay is still valid for given cart */
          const { tag } = trackRequest('useApplePay-onpaymentauthorized');
          const response = await getCartPaymentInstrumentsAPI({
            cartId: cart.value.id,
            payment_method_id: PaymentMethodCode.APPLEPAY,
          });
          clearRequest(tag);

          isPaymentInstrumentValid = !!response.data.payment_instruments?.find(
            (method) => method.payment_method_id === PaymentMethodCode.APPLEPAY
          );
        }
        /** Proceed with payment flow if payment instrument has been validated */
        if (isPaymentInstrumentValid) {
          await getProvinceList();
          applePayOrderToken.value = JSON.stringify({
            payment: { token: event.payment.token },
          });

          /** Save shipping and billing address, as we have all the information after authorization */
          saveShippingAddress(
            mapAddressData(event?.payment?.shippingContact)[0]
          );

          /* AVS Validation */
          const addressValidationResponse = await validateAddress(
            cart.value.id
          );

          if (addressValidationResponse?.data) {
            if (isAVSRejected(addressValidationResponse.data)) {
              // the address was not valid nor changeable
              return session.completePayment({
                status: ApplePaySession.STATUS_FAILURE,
                errors: [
                  new ApplePayError(
                    'shippingContactInvalid',
                    'postalAddress',
                    translations.addressFormatError
                  ),
                ],
              });
            }

            // The address was validated so we check for changes.
            // If changes need to be made they will be made in
            // checkAddressChanges, calling setShippingAddress from within
            // the storeAddresses function.
            await checkAddressChanges(addressValidationResponse.data, {
              changeShippingAddress: true,
              updateCartData: false,
              isTemporary: contextKey === ApplePayContext.PDP,
              setShippingQueryParams: applePaySessionQuery,
            });

            if (!AVSchanged.value) {
              // The address was validated but changes were not needed,
              // therefore we call setShippingAddress with the provided address
              await setShippingAddress(
                mapAddressData(event?.payment?.shippingContact),
                {
                  updateCartData: false,
                  isTemporary: contextKey === ApplePayContext.PDP,
                },
                applePaySessionQuery
              );
            }

            confirmAddressAndClearAVSStorage();
          } else {
            // AVS did not return any data so we attempt to use the given address.
            await setShippingAddress(
              mapAddressData(event?.payment?.shippingContact),
              {
                updateCartData: false,
                isTemporary: contextKey === ApplePayContext.PDP,
              },
              applePaySessionQuery
            );
          }

          saveBillingAddress(
            mapAddressData({
              ...event?.payment?.billingContact,
              emailAddress: event?.payment?.shippingContact?.emailAddress,
              phoneNumber: event?.payment?.shippingContact?.phoneNumber,
            })[0]
          );
          paymentMethod.value = PaymentMethodCode.APPLEPAY;
          const [isShippingValid, isBillingValid] = [
            await isAddressValid(translations, shippingAddress.value),
            await isAddressValid(translations, billingAddress.value, true),
          ];
          if (!isShippingValid || !isBillingValid) {
            session.completePayment({
              status: ApplePaySession.STATUS_FAILURE,
              errors: [new ApplePayError('addressInvalid')],
            });
            return;
          }
          const orderState = await placeOrder({
            isTemporary: contextKey === ApplePayContext.PDP,
          });

          if (orderState && orderState.success === false) {
            /** Rough check for any billing address failures */
            const billingAddressInvalid = orderState.errors?.find((error) =>
              error?.message?.includes('billingAddress')
            );

            /** Show error message if Billing Address is invalid or something else failed */
            session.completePayment({
              status: ApplePaySession.STATUS_FAILURE,
              errors: [
                new ApplePayError(
                  billingAddressInvalid ? 'billingContactInvalid' : 'unknown'
                ),
              ],
            });
          } else {
            /** Complete payment with success */
            session.completePayment({
              status: ApplePaySession.STATUS_SUCCESS,
            });
          }
        } else {
          /** AP Payment is not supported for some reason */
          session.completePayment({
            status: ApplePaySession.STATUS_FAILURE,
            errors: [new ApplePayError('unknown')],
          });
        }
      } catch (e) {
        /** Something went wrong along the way (ex. network issue) */
        session.completePayment({
          status: ApplePaySession.STATUS_FAILURE,
          errors: [new ApplePayError('unknown')],
        });
      } finally {
        applePayOrderToken.value = '';
      }
    };

    session.oncancel = (event) => {
      console.error('Apple Pay session canceled: ', event);
      onInitialLoadingFinished();
    };

    session.begin();

    return session;
  };

  const mapAddressData = (inputAddress: ApplePayPaymentContact) => {
    const address = { ...DEFAULT_ADDRESS, ...inputAddress };
    return [
      {
        firstName: address.givenName.trim() || '',
        lastName: address.familyName.trim() || '',
        email: address.emailAddress.trim() || '',
        country: address.countryCode.trim() || '',
        addressLine1: address.addressLines?.[0]?.trim() || '',
        addressLine2: address.addressLines?.[1]?.trim() || '',
        postalCode: address.postalCode.trim() || '',
        city: address.locality.trim() || '',
        province: address.administrativeArea?.trim().toUpperCase() || '',
        phone: address.phoneNumber.trim() || '',
        stateCode: address.administrativeArea?.trim().toUpperCase() || '',
        countryCode: address.countryCode.toUpperCase() || '',
      },
    ];
  };

  const mapCartShippingAddressToApplePay = (): ShippingContact => {
    const cartShippingAddress = cart.value.shippingMethods[0].address;
    const addressLines = [cartShippingAddress.addressLine1];
    if (cartShippingAddress.addressLine2 !== undefined) {
      addressLines.push(cartShippingAddress.addressLine2);
    }
    return {
      addressLines,
      locality: cartShippingAddress.city,
      administrativeArea:
        cartShippingAddress.province || cartShippingAddress.stateCode,
      postalCode: cartShippingAddress.postalCode,
      countryCode:
        cartShippingAddress.countryCode || cartShippingAddress.country,
      familyName: cartShippingAddress.lastName,
      givenName: cartShippingAddress.firstName,
      emailAddress: cartShippingAddress.email,
      phoneNumber: cartShippingAddress.phone,
    };
  };

  const unselectApplePayAsDefault = () => {
    const [firstPaymentMethod, secondPaymentMethod] = paymentMethodsData.value;
    if (
      paymentMethod.value === PaymentMethodCode.APPLEPAY &&
      firstPaymentMethod?.code === PaymentMethodCode.APPLEPAY
    ) {
      setPaymentMethod(secondPaymentMethod?.code);
    }
  };

  return {
    paymentRequestData: computed(() => paymentRequestRef.value),
    isApplePayAvailable,
    isApplePayApiDefined,
    isProvinceValid,
    isAddressValid,
    initApplePaySession,
    updatePaymentRequest,
    refreshPaymentRequestWithCurrentCartData,
    mapAddressData,
    mapCartShippingAddressToApplePay,
    postalCodeValidationRegex,
    getProvinceListResponse,
    getProductPriceValue,
    getValueOfProductsToBeAdded,
    getProvinceList,
    unselectApplePayAsDefault,
  };
};
