




import { defineComponent } from '@vue/composition-api';
import { Hero } from '@gemini/pollux';

export default defineComponent({
  name: 'GeminiHero',
  components: {
    'gemini-hero': Hero,
  },
  props: {
    pattern: String,
    img1Desktop: {
      type: String,
      required: true,
    },
    img1DesktopH: String,
    img1DesktopW: String,
    img1Tablet: String,
    img1TabletH: String,
    img1TabletW: String,
    img1Mobile: String,
    img1MobileH: String,
    img1MobileW: String,
    img1Alt: {
      type: String,
      required: true,
    },
    caption: String,
    grid: String,
    eyebrow: String,
    heading1: {
      type: String,
      required: true,
    },
    heading2: String,
    copy1: String,
    copy2: String,
    copy3: String,
    legalCopy: String,
    legalUrl: String,
    legalTarget: String,
    cta1Url: {
      type: String,
      required: true,
    },
    cta1Target: String,
    cta2Url: String,
    cta2Target: String,
    cta3Url: String,
    cta3Target: String,
    cta1Copy: {
      type: String,
      required: true,
    },
    cta2Copy: String,
    cta3Copy: String,
    cta1Type: {
      type: String,
      required: true,
    },
    cta2Type: String,
    cta3Type: String,
    terms: String,
    termsCopy: String,
    termsUrl: String,
    termsTarget: String,
    addDesktop: String,
    addDesktopH: String,
    addDesktopW: String,
    addTablet: String,
    addTabletH: String,
    addTabletW: String,
    addMobile: String,
    addMobileH: String,
    addMobileW: String,
    addAlt: String,
    lockupDesktop: String,
    lockupAlt: String,
    lockupDesktopH: String,
    lockupDesktopW: String,
    lockupMobile: String,
    lockupMobileH: String,
    lockupMobileW: String,
    vidDesktop: String,
    vidDesktopH: String,
    vidDesktopW: String,
    vidMobile: String,
    vidMobileH: String,
    vidMobileW: String,
  },
});
