


































































import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import { apiClientFactory, Product } from '@vf/api-client';
import { parseProductId } from '@vf/shared/src/utils/helpers';
import { Context } from '@vf/api-contract';
import { getEventFromTemplate } from '@vf/composables/src/useGtm/helpers';
import { EnhancedSaleType } from '@vf/composables/src/types/gtm';
import useModal from '@/shared/useModal';
import {
  useCart,
  useGtm,
  useI18n,
  useProduct,
  useProductInventory,
} from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import { prepareLocale } from '@vf/composables/src/utils/query';
import type { PropType } from 'vue';
import type { UpsellProduct, UpsellWrapper } from '@vf/api-contract';
import { UpsellType } from '@vf/api-contract';
import { replaceAll } from '@/helpers/replaceAll';
import useLoader from '@/shared/useLoader';

export default defineComponent({
  name: 'Upsell',
  props: {
    upsells: {
      type: Array as PropType<UpsellWrapper[]>,
      required: false,
    },
    quickShopLink: String,
  },
  emits: ['load:upsell'],
  setup(props, { emit }) {
    const { root } = useRootInstance();
    const { modalProps, openModal, setModalProps } = useModal();
    const { dispatchEvent } = useGtm(root);
    const { product: quickShopProduct, toggleQuickShop } = useProduct(
      root,
      Context.QuickShop
    );
    const { getProductInventory } = useProductInventory(
      root,
      Context.QuickShop
    );
    const { showSpinner, hideSpinner } = useLoader();
    const { getProductInventory: getProductInventoryAPI } = apiClientFactory(
      root
    );
    const { addItem } = useCart(root);
    const { localeCode } = useI18n(root);

    // TODO: GLOBAL15-61059 remove after redesign core
    const { isCoreRedesignEnabled } = useFeatureFlagsStore();

    const query = computed(() => [prepareLocale(localeCode())]);

    const filteredUpsells = ref<UpsellWrapper[]>([]);

    const openQuickshop = async (product: UpsellProduct, position: number) => {
      showSpinner();
      triggerGtmClick(product, position);
      const pid = parseProductId(product.itemGroupId);
      await toggleQuickShop(pid, product.attributes);

      openModal({
        contextKey: Context.QuickShop,
        path: props.quickShopLink,
        type: 'page',
      });
      setModalProps({
        ...modalProps.value,
        class: modalProps.value.class + ' vf-modal-upsell',
      });
      await getProductInventory(pid);
      triggerGtmView(quickShopProduct.value);
    };

    const addToCart = async (selected: UpsellProduct) => {
      showSpinner();
      const productAddedToCart = await addItem({
        ...selected,
        /** Added variant ID due to requirement in buildItemPDPDetails method in useCart */
        variant: {
          id: selected.id,
          attributes: selected.attributes,
        },
      });
      hideSpinner();

      if (productAddedToCart) {
        dispatchEvent({
          ...getEventFromTemplate('cart:add', {}),
          overrideAttributes: {
            quantity: selected.quantity,
            productId: selected.id,
            sku: selected.id,
          },
        });
        dispatchEvent(getEventFromTemplate('cart:update', {}));
      }
    };

    const triggerGtmClick = (product: UpsellProduct, position: number) => {
      dispatchEvent({
        ...getEventFromTemplate('enhanced-sale:click'),
        overrideAttributes: {
          type: EnhancedSaleType.UP_SELL,
          product: {
            id: product.id,
            sku: product.id,
            name: product.name,
            description: product.description,
            pageUrl: product.pageUrl,
            price: product.price,
            styleCode: product.itemGroupId,
            ...(product.attributes.color && {
              colorCode: product.attributes.color,
            }),
          },
          position,
        },
      });
    };

    const triggerGtmView = (prod: Product) => {
      dispatchEvent({
        ...getEventFromTemplate('enhanced-sale:view'),
        overrideAttributes: {
          type: EnhancedSaleType.UP_SELL,
          product: prod,
        },
      });
    };

    watch(
      props,
      async ({ upsells }) => {
        if (!upsells) return;

        const result = await Promise.all(
          upsells.map(async (upsell) => ({
            upsell,
            response: await getProductInventoryAPI(
              upsell.product.itemGroupId,
              query.value.join('&')
            ),
          }))
        );

        filteredUpsells.value = result
          .filter(({ upsell, response }) => {
            if (upsell.type === UpsellType.Single) {
              return response.data.variants[upsell.product.id]?.inStock;
            } else {
              return Object.values(response.data.variants).some(
                (stockData: any) => stockData.inStock
              );
            }
          })
          .map(({ upsell }) => upsell);

        emit(
          'load:upsell',
          filteredUpsells.value.map((upsell) => upsell.product)
        );
      },
      { immediate: true }
    );

    return {
      isCoreRedesignEnabled,
      filteredUpsells,
      UpsellType,
      openQuickshop,
      addToCart,
      replaceAll,
    };
  },
});
