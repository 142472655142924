







































import { PropType } from 'vue';
import { defineComponent } from '@vue/composition-api';
import {
  ApproachingDiscountDetails,
  PromotionMessageContext,
} from '@vf/api-contract';

export default defineComponent({
  name: 'BounceBackVoucher',
  props: {
    vouchers: {
      type: Array as PropType<ApproachingDiscountDetails[]>,
      required: true,
    },
    context: {
      type: String as PropType<PromotionMessageContext>,
      required: true,
    },
  },
  setup(props) {
    const isCartContext = props.context === PromotionMessageContext.Cart;
    const isOrderConfirmationContext =
      props.context === PromotionMessageContext.OrderConfirmation;

    return { isCartContext, isOrderConfirmationContext };
  },
});
