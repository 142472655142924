var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vf-order-summary"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.orderPageType === 'order' || _vm.returnStep === 'details'),expression:"orderPageType === 'order' || returnStep === 'details'"}],staticClass:"vf-order-summary__details"},[(_vm.pickupLocations.length > 0)?_c('div',{staticClass:"vf-order-summary__tile"},[_c('VfHeading',{staticClass:"vf-order-summary__heading",attrs:{"level":4,"title-modifier":"title-4","title":_vm.translations.pickupInfoLabel}}),_vm._v(" "),_vm._l((_vm.pickupLocations),function(store){return _c('div',{key:store.shippingAddress.storeId},[_c('div',{staticClass:"vf-order-summary__info"},[_c('OrderSummaryInfo',{attrs:{"heading":_vm.translations.pickupLocationLabel,"address-lines":_vm.getPickupLocationInfo(store)}}),_vm._v(" "),_c('OrderSummaryInfo',{attrs:{"heading":_vm.translations.pickupPersonLabel,"address-lines":_vm.mapToDataProtectedInfos(
                store.shippingAddress,
                _vm.theme.pickupPersonInfoFormat
              )}}),_vm._v(" "),(_vm.isAltPickupPerson(store.pickupInformation))?_c('OrderSummaryInfo',{attrs:{"heading":_vm.translations.altPickupPersonLabel,"address-lines":_vm.mapToDataProtectedInfos(
                store.pickupInformation,
                _vm.theme.pickupPersonInfoFormat
              )}}):_vm._e()],1),_vm._v(" "),_c('VfButton',{class:_vm.theme.getDirectionButtonClass,attrs:{"link":_vm.getDirectionLink(store.shippingAddress),"open-in-new-tab":""}},[_vm._v("\n          "+_vm._s(_vm.translations.direction)+"\n        ")])],1)})],2):_vm._e(),_vm._v(" "),(_vm.shippingLocations.length > 0)?_c('div',{staticClass:"vf-order-summary__tile"},[_c('VfHeading',{staticClass:"vf-order-summary__heading",attrs:{"level":4,"title-modifier":"title-4","title":_vm.translations.shippingInfoLabel}}),_vm._v(" "),_c('div',{staticClass:"vf-order-summary__info"},[_c('OrderSummaryInfo',{staticClass:"vf-order-summary__user-info",attrs:{"heading":_vm.translations.shippingToLabel,"address-lines":_vm.mapToDataProtectedInfos(
              _vm.shippingLocations[0].shippingAddress,
              _vm.theme.personInfoFormat
            )}}),_vm._v(" "),_c('OrderSummaryInfo',{attrs:{"heading":_vm.translations.shippingMethodLabel}},_vm._l((_vm.uniqueShippingMethods),function(method){return _c('div',{key:method.carrierCode,staticClass:"vf-order-summary__shipping-method"},[_c('VfText',{attrs:{"content":method.label}}),_vm._v(" "),_c('VfText',{attrs:{"content":method.shippingDuration}})],1)}),0),_vm._v(" "),_c('OrderSummaryInfo',{attrs:{"heading":_vm.translations.addressLabel,"address-lines":_vm.mapToDataProtectedInfos(
              _vm.shippingLocations[0].shippingAddress,
              _vm.theme.addressFormat
            )}})],1)],1):_vm._e(),_vm._v(" "),(_vm.order && _vm.order.billingAddress && _vm.order.billingAddress.firstName)?_c('div',{staticClass:"vf-order-summary__tile"},[_c('VfHeading',{staticClass:"vf-order-summary__heading",attrs:{"level":4,"title-modifier":"title-4","title":_vm.translations.billingInfoLabel}}),_vm._v(" "),_c('div',{staticClass:"vf-order-summary__info"},[(_vm.order)?_c('OrderSummaryInfo',{staticClass:"vf-order-summary__user-info",attrs:{"heading":_vm.translations.billingToLabel,"address-lines":_vm.mapToDataProtectedInfos(
              _vm.order.billingAddress,
              _vm.theme.personInfoFormat
            )}}):_vm._e(),_vm._v(" "),_c('OrderSummaryInfo',{staticClass:"vf-order-summary__payment-method",attrs:{"heading":_vm.translations.paymentLabel,"address-lines":_vm.paymentDetails,"html":_vm.isPaymentLabelHtml}}),_vm._v(" "),(_vm.order)?_c('OrderSummaryInfo',{attrs:{"heading":_vm.translations.addressLabel,"address-lines":_vm.mapToDataProtectedInfos(_vm.order.billingAddress, _vm.theme.addressFormat)}}):_vm._e()],1)],1):_vm._e(),_vm._v(" "),(_vm.isReturns)?_c('SelectRefundMethod',{attrs:{"title":_vm.refundMethodOptions.title,"options":_vm.refundMethodOptions.options,"input-label":_vm.translations.emailPlaceholder}}):_vm._e()],1),_vm._v(" "),_c('VfHeading',{staticClass:"vf-order-summary__heading vf-order-summary__heading-item-summary",attrs:{"level":4,"title-modifier":"title-4","title":_vm.itemsSummaryLabel}}),_vm._v(" "),(_vm.isReturns)?_c('VfDivider',{staticClass:"vf-divider"}):_vm._e(),_vm._v(" "),_c('VfNotification',{staticClass:"vf-order-summary__notification vf-notification--white-background",attrs:{"type":"info","visible":_vm.hasEmptyShipmentIdItems,"show-close-button":false},scopedSlots:_vm._u([{key:"message",fn:function(){return [_vm._v("\n      "+_vm._s(_vm.translations.shipmentDataProcessedMessage)+"\n    ")]},proxy:true}])}),_vm._v(" "),(_vm.theme.showTrackingBeforeProducts && _vm.containTrackingNumber)?_c('div',_vm._l((_vm.trackableShipments),function(shipment,index){return _c('VfProperty',{key:'shipment' + index,staticClass:"vf-tracking vf-property--small",attrs:{"name":((_vm.translations.trackPackageLabel2 +
        (_vm.trackableShipments.length > 1 ? ' ' + (index + 1) : '')) + ":")},scopedSlots:_vm._u([{key:"value",fn:function(){return _vm._l((shipment.shippingMethod
            .trackingNumber),function(trackingElement,i){return _c('ThemeLink',{key:trackingElement,staticClass:"vf-tracking__number",attrs:{"to":shipment.shippingMethod.trackingUrl[i]}},[_vm._v("\n          "+_vm._s(trackingElement)+"\n        ")])})},proxy:true}],null,true)})}),1):_vm._e(),_vm._v(" "),_c('OrderSummaryProductList',{staticClass:"vf-order-summary__product-list",attrs:{"translations":_vm.translations,"products":_vm.localizedProducts,"shipments":_vm.orderShipments,"context-key":_vm.orderPageType,"show-notification-text":_vm.showNotificationText,"product-statuses":_vm.productStatuses,"is-returns":_vm.isReturns,"returns-context":_vm.returnStep,"returns-items":_vm.returnsItems,"return-statuses":_vm.returnStatuses,"display-returned-items-details-c-t-a":_vm.displayReturnedItemsDetailsCTA},on:{"handle-update-return":function($event){return _vm.handleUpdateReturn($event)}},scopedSlots:_vm._u([{key:"shipment-tracking",fn:function(ref){
            var shipment = ref.shipment;
return [(_vm.getShipmentTrackings(shipment).trackingNumber.length)?_c('div',{staticClass:"vf-order-summary__shipment-tracking-label"},[_c('VfText',{attrs:{"content":_vm.translations.tracking}}),_vm._v(" "),_vm._l((_vm.getShipmentTrackings(shipment)
            .trackingNumber),function(tracking,t){return _c('ThemeLink',{key:tracking,staticClass:"vf-order-summary__tracking-number",attrs:{"to":_vm.getShipmentTrackings(shipment).trackingUrl[t],"open-in-new-tab":""}},[_vm._v("\n          "+_vm._s(tracking)+"\n        ")])})],2):_vm._e()]}}])}),_vm._v(" "),(_vm.showReturnButton)?_c('div',{staticClass:"vf-order-summary__return-button"},[_c('ThemeButton',{attrs:{"data-testid":"order-summary__btn__new-return","disabled":_vm.isReturnInvalid,"color":"primary","size":_vm.theme.returnButtonSize},on:{"click":_vm.handleCreateReturn}},[_vm._v("\n      "+_vm._s(_vm.returnButtonText)+"\n    ")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }