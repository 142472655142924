import * as vuelidate from 'vuelidate/lib/validators';
import transformers from './viewBuilder/transformers';
import validators from './viewBuilder/validators';
import { typesUtilities as types } from '@vf/composables';
import { checkPhone } from '@vf/shared/src/utils/helpers';
import { CountryCode } from 'libphonenumber-js/types';

export function viewBuilder(
  form: types.Form,
  country: CountryCode
): types.ViewInfo {
  return {
    formId: form.formId,
    validation: getValidation(form, country),
    transformers: getTransformers(form),
    hints: getHints(form),
    options: getOptions(form),
    types: getType(form),
  };
}

// validation rule parser
const getValidation = (form: types.Form, country: CountryCode) => {
  const validation = getData(form, 'validation', extractValidator);
  addTypeValidation(validation, form, country);
  return validation;
};

// transformer parser
const getTransformers = (form: types.Form) => {
  return getData(form, 'transforms', extractTransformer);
};

// hint parser
const getHints = (form: types.Form) => {
  return getData(form, 'format', extractArray);
};

// option parser
const getOptions = (form: types.Form) => {
  return getData(form, 'list', extractArray);
};

// type parser
const getType = (form: types.Form) => {
  return getData(form, 'type', extractType);
};

const getData = (form: types.Form, fieldName: string, extractor) => {
  const all = {};
  form.fields?.forEach((field) => {
    const extractedObject = extractor(field, fieldName);
    if (extractedObject) {
      all[field.fieldId] = extractedObject;
      if (field.fieldLabelKey) all['fieldLabelKey'] = field.fieldLabelKey;
    }
  });
  return all;
};

const extractValidator = (field: types.Field, fieldName: string) => {
  const rules = {};
  for (const [validator, params] of Object.entries(field[fieldName] || {})) {
    const validatorInput = params['if-macro'] ? params['value'] : params;
    let validatorFn;
    if (validators[validator]) {
      // using validator defined in validators
      validatorFn = validators[validator](validatorInput);
    } else if (vuelidate[validator]) {
      // using a validator from vuelidate
      validatorFn = getVuelidateValidator(validator, validatorInput);
    } else {
      console.error(
        'ViewDefinition',
        `Unknown validation type: ${validator}`,
        field
      );
    }
    if (validatorFn) {
      rules[validator] = { fn: validatorFn };
      if (params['if-macro']) {
        rules[validator].predicateName = params['if-macro'];
      }
    }
  }
  return Object.keys(rules).length ? rules : null;
};

const getVuelidateValidator = (validator, params) => {
  // check if vuelidate[validator] function requires params
  if (vuelidate[validator].length) {
    if (Array.isArray(params)) {
      return vuelidate[validator](...params);
    } else {
      return vuelidate[validator](params);
    }
  } else if (params) {
    return vuelidate[validator];
  }
};

const extractTransformer = (field: types.Field, fieldName: string) => {
  const fieldTransformer = [];
  for (const transformer of field[fieldName] || []) {
    if (transformers[transformer]) {
      fieldTransformer.push(transformers[transformer]());
    } else {
      console.error(
        'ViewDefinition',
        `Unknown transformer type: ${transformer}`,
        field
      );
    }
  }
  if (fieldTransformer.length) {
    return getTransformerFunction(fieldTransformer);
  }
  return null;
};

// this function retrieves a function that performs all defined transformers on a field
const getTransformerFunction = (transformers) => (input: string): string => {
  let res = input ?? '';
  for (const transformer of transformers) {
    res = transformer(res);
  }
  return res;
};

const extractArray = (field: types.Field, fieldName: string) => {
  const fieldArray = field[fieldName] || [];
  if (fieldArray.length) {
    return fieldArray;
  }
  return null;
};

const extractType = (field: types.Field, fieldName: string) => {
  return field[fieldName] || 'text';
};

const addTypeValidation = (
  validation,
  form: types.Form,
  country: CountryCode
) => {
  form.fields?.forEach((field) => {
    let validator;

    if (field?.fieldId === 'phone') {
      field.type = 'phone';
    }

    switch (field?.type) {
      case 'email':
        validator = {
          email: {
            fn: (eml: string) =>
              eml ? vuelidate.email(eml.toLowerCase()) : false,
          },
        };

        break;
      case 'phone':
        validator = {
          phone: {
            fn: (phone: string) => (phone ? checkPhone(phone, country) : false),
          },
        };
        break;
    }

    validation[field.fieldId] = {
      ...validation[field.fieldId],
      ...(validator && { ...validator }),
    };
  });
};
