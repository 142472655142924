

















import { defineComponent, computed } from '@vue/composition-api';
import { useProduct } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import { useCmsRefStore } from '@vf/composables/src/store/cmsRef';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'OneLineLabel',
  props: {
    contextKey: {
      type: String,
      default: 'product',
    },
    /** Will show a skeleton loading animation while it's true */
    isLoading: {
      type: Boolean,
      default: false,
    },
    customLabel: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const cmsRefStore = useCmsRefStore();
    const { root } = useRootInstance();
    const { product } = useProduct(root, props.contextKey);
    const { badges } = storeToRefs(cmsRefStore);

    const badge = computed(() => {
      const { labels } = product.value ?? {};

      if (labels?.[0]) {
        const productBadge = badges.value[labels[0]] || null;
        return productBadge ?? { alt: labels[0] };
      }

      return [];
    });

    return {
      badge,
    };
  },
});
