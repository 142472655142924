






import { defineComponent, computed } from '@vue/composition-api';
import useRootInstance from '@/shared/useRootInstance';
import { useCms } from '@vf/composables';
import PlpVisualFilter from './PlpVisualFilter.vue';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import { PlpVisualCarouselFilter } from '@vf/api-contract';
import { ProductAttributeCodes } from '@vf/api-client';

type VisualFilterProps = {
  filters?: PlpVisualCarouselFilter[];
};

export default defineComponent({
  name: 'PlpVisualFilterPlaceholder',
  components: { PlpVisualFilter },
  setup() {
    const { root } = useRootInstance();
    const { visualFilter } = useCms(root, 'page-content');
    const { isNewColorDividerEnabled } = useFeatureFlagsStore();
    const visualFilterProps = !isNewColorDividerEnabled
      ? computed(() => visualFilter.value?.props)
      : computed(() => mapColorFilter(visualFilter.value?.props));

    const mapColorFilter = (props?: VisualFilterProps) => {
      if (!props) return;
      return Object.assign({}, props, {
        filters: props.filters?.map((item) =>
          item.code.toLowerCase() === ProductAttributeCodes.Color
            ? { ...item, value: item.value.replace('|', ';') }
            : item
        ),
      });
    };

    const visualFilterDataId = computed(
      () => visualFilter.value?.attrs['data-id']
    );

    return {
      visualFilterProps,
      visualFilterDataId,
    };
  },
});
