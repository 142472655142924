




import { ContentCard } from '@gemini/pollux';

export default {
  name: 'GeminiContentCard',
  components: {
    'gemini-content-card': ContentCard,
  },
  props: {
    htmlTag: String,
    ctaTag: {
      type: String,
      default: 'link',
    },
    pattern: String,
    eyebrow: String,
    badge: String,
    heading1: String,
    heading2: String,
    headingUrl: String,
    copy: String,
    img1Url: String,
    img1Desktop: String,
    img1DesktopH: String,
    img1DesktopW: String,
    img1Tablet: String,
    img1TabletH: String,
    img1TabletW: String,
    img1Mobile: String,
    img1MobileH: String,
    img1MobileW: String,
    img1Alt: String,
    img2Url: String,
    img2: String,
    img2H: String,
    img2W: String,
    img2Alt: String,
    img3Url: String,
    img3: String,
    img3H: String,
    img3W: String,
    img3Alt: String,
    vidDesktop: String,
    vidDesktopH: String,
    vidDesktopW: String,
    vidMobile: String,
    vidMobileH: String,
    vidMobileW: String,
    cta1Type: String,
    cta1Copy: String,
    cta1Url: String,
    cta1HasIcon: Boolean,
    cta2Type: String,
    cta2Copy: String,
    cta2Url: String,
    cta2HasIcon: Boolean,
    cta3Copy: String,
    cta3HasIcon: Boolean,
    cta3Type: String,
    cta3Url: String,
    headingTarget: String,
    img1Target: String,
    img2Target: String,
    img3Target: String,
    cta1Target: String,
    cta2Target: String,
  },
};
