/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// disabled because it print error about default function in .vue file it is valid

import type { PropType } from 'vue';
import type {
  ApplePayTranslations,
  CheckoutPaymentInfoTranslations,
  PaymentMethodsIcon,
  PaymentMethodsLabel,
} from '@vf/api-contract';

export const CheckoutPaymentInfoProps = {
  translations: {
    type: Object as PropType<CheckoutPaymentInfoTranslations>,
    default: () => ({}),
  },
  applePayTranslations: {
    type: Object as PropType<ApplePayTranslations>,
    default: () => ({}),
  },
  /** Maximum expiration year of the card */
  maxExpirationYear: {
    type: [Number, String],
    default: 2040,
  },
  paymentMethodsIcons: {
    type: Array as PropType<PaymentMethodsIcon[]>,
    default: () => [],
  },
  paymentLabels: {
    type: Array as PropType<PaymentMethodsLabel[]>,
    required: true,
  },
  /** Uri to card CVV tooltip hint image */
  securityCodeTooltipImage: {
    type: String,
  },
  /** Whether to render a checkbox for saving payment instrument */
  showSaveCardCheckbox: {
    type: Boolean,
    default: true,
  },
  contextKey: {
    type: String,
    default: 'page-content',
  },
};

export default CheckoutPaymentInfoProps;
