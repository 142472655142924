












































































// TODO: GLOBAL15-56318 remove deprecated component
import type { PropType } from 'vue';
import { computed, defineComponent, ref } from '@vue/composition-api';
import type { CheckoutAthleteDiscountTranslations } from '@vf/api-contract';
import useRootInstance from '@/shared/useRootInstance';
import { PaymentMethodCode } from '@vf/api-client';
import {
  useCheckout,
  useNotification,
  useCart,
  useAccount,
  useGiftCards,
} from '@vf/composables';

export default defineComponent({
  name: 'CheckoutAthleteDiscount',
  props: {
    translations: {
      type: Object as PropType<CheckoutAthleteDiscountTranslations>,
      required: true,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { applyAthleteDiscount, removeGiftCard } = useGiftCards(root);
    const { paymentMethodsData } = useCheckout(root);
    const { addNotification, clearNotifications } = useNotification(root);
    const { cart } = useCart(root);
    const { basicInformation } = useAccount(root);

    const isAccordionOpened = ref(true);
    const applyBtnDisabled = ref(false);
    const isAmountExceeded = ref(false);

    const remainingAmount = computed(() => {
      return (
        paymentMethodsData.value.find(
          (method) => method.code === PaymentMethodCode.ATHLETES
        )?.additionalData?.remainingAmount ?? 0
      );
    });

    const remainingAmountFormatted = computed(() =>
      root.$formatPrice(remainingAmount.value)
    );

    const isAthleteCreditApplied = computed(() => {
      return (
        typeof cart.value.payment_instruments !== 'undefined' &&
        cart.value.payment_instruments.some(
          (payment) => payment.payment_method_id === PaymentMethodCode.ATHLETES
        )
      );
    });

    const onClickApplyAthleteDiscount = async () => {
      applyBtnDisabled.value = true;
      const response = await applyAthleteDiscount();
      applyBtnDisabled.value = false;

      if ([200, 201].includes(response?.status)) {
        clearNotifications();
        addNotification({
          message: props.translations.applySuccessMessage,
          type: 'success',
        });
      }
    };

    const onClickRemoveAthleteDiscount = async () => {
      applyBtnDisabled.value = true;
      const appliedPaymentInstrument = cart.value.payment_instruments.find(
        (payment) => payment.payment_method_id === PaymentMethodCode.ATHLETES
      );
      const response = await removeGiftCard({
        paymentId: appliedPaymentInstrument?.payment_instrument_id,
      });
      applyBtnDisabled.value = false;

      if ([200, 201].includes(response?.status)) {
        clearNotifications();
        addNotification({
          message: props.translations.removeSuccessMessage,
          type: 'success',
        });
      }
    };

    const showAthleticAccordion = computed(
      () => basicInformation.value?.athlete?.athlete
    );

    return {
      applyBtnDisabled,
      isAmountExceeded,
      isAccordionOpened,
      remainingAmount,
      remainingAmountFormatted,
      isAthleteCreditApplied,
      onClickApplyAthleteDiscount,
      onClickRemoveAthleteDiscount,
      showAthleticAccordion,
    };
  },
});
