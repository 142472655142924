

























import { CheckoutContext } from '@vf/api-contract';
import OrderSummaryCollapsible from '@/components/static/OrderSummaryCollapsible.vue';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

export default {
  name: 'PlaceOrderButton',
  components: { OrderSummaryCollapsible },
  props: {
    disabled: {
      type: Boolean,
    },
  },
  setup() {
    const { checkoutOrderSummaryUpdated } = useFeatureFlagsStore();

    return {
      CheckoutContext,
      checkoutOrderSummaryUpdated,
      OrderSummaryCollapsible,
    };
  },
};
