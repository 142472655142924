






















































import { defineComponent, onMounted } from '@vue/composition-api';
import useRootInstance from '@/shared/useRootInstance';
import useModal from '@/shared/useModal';
import { useNotification } from '@vf/composables';

export default defineComponent({
  name: 'Dashboard',
  props: {
    /** Items of the dashboard */
    dashboardItems: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const { root } = useRootInstance();
    const { openModal } = useModal();
    const {
      addNotification,
      passwordChangeNotification,
      setPasswordChangeNotification,
    } = useNotification(root);
    const onItemClick = (target) => {
      if (target.includes('data-id')) {
        const resourceId = target.replace(/\D/g, '');
        openModal({ type: 'lazyFragment', resourceId });
      } else {
        root.$router.push(target);
      }
    };

    onMounted(() => {
      if (!passwordChangeNotification.value.isShown) return;

      addNotification({
        message: passwordChangeNotification.value.message,
        type: 'success',
      });
      setPasswordChangeNotification({ isShown: false });
    });

    return {
      onItemClick,
    };
  },
});
