













import { computed, defineComponent, PropType } from '@vue/composition-api';
import { Product } from '@vf/api-client';

export default defineComponent({
  name: 'VfProductMeasurements',
  props: {
    product: {
      type: Object as PropType<Product>,
      default: () => ({}),
    },
  },
  setup(props) {
    const modelMeasurements = computed<string[]>(() => {
      const attributeColor = props.product.attributes?.find(
        (attr) => attr.code === 'color'
      );
      if (!attributeColor || !props.product.color) return [];

      return (
        attributeColor.options.find(
          (opt) => opt.description === props.product.color.description
        )?.modelMeasurements || []
      );
    });

    return {
      modelMeasurements,
    };
  },
});
