

































import type { PropType } from 'vue';
import { computed, defineComponent, inject } from '@vue/composition-api';
import type { ContentAlignProperties } from '@vf/api-contract';
import { useProduct } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import { useCmsRefStore } from '@vf/composables/src/store/cmsRef';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'ProductName',
  props: {
    /** Product name heading level 1-6 */
    headingLevel: {
      type: Number,
      default: 1,
    },
    /** Select with page type */
    contextKey: {
      type: String,
      default: 'product',
    },
    displayBadgeInsideTitle: {
      type: Boolean,
      default: true,
    },
    /** Will show a skeleton loading animation while it's true */
    isLoading: {
      type: Boolean,
      default: false,
    },
    textAlign: {
      type: Object as PropType<ContentAlignProperties>,
      default: () => ({
        small: 'left',
        medium: 'left',
        large: 'left',
      }),
    },
  },
  setup(props) {
    const cmsRefStore = useCmsRefStore();
    const { root } = useRootInstance();

    // To be cleaned up with GLOBAL15-63801
    const isVansPdpRedesignEnabled = inject('isVansPdpRedesignEnabled');

    const { badges } = storeToRefs(cmsRefStore);
    const { product } = useProduct(root, props.contextKey);

    const isQuickshop = computed(() => {
      return props.contextKey === 'quickshop';
    });

    const getProductBadge = (labels: string[]) => {
      if (labels && labels[0]) {
        const productBadge = badges.value[labels[0]];
        return productBadge ?? { alt: labels[0] };
      }
    };

    const getProductBadgeFromColors = () => {
      const variant = product.value.variant;
      if (variant) {
        const color =
          (root.$route.query?.color as string) || variant.attributes?.color;
        const colors = product.value.colors.filter((c) => c.value == color);
        if (colors?.[0]?.badges?.[0]) {
          return getProductBadge([colors[0].badges[0]]);
        }
      }
    };

    const badge = computed(() => {
      const badgeFromColors = getProductBadgeFromColors();
      if (
        root.$themeConfig.productColors.enableBadgesBasedOnColor &&
        badgeFromColors
      ) {
        return badgeFromColors;
      }
      return getProductBadge(product.value.labels ?? []);
    });

    return {
      getProductBadge,
      product,
      isQuickshop,
      badge,
      isVansPdpRedesignEnabled,
    };
  },
});
