





















import { defineComponent, ref, onMounted } from '@vue/composition-api';
import { useCms, useI18n, useSignInToStore, ROUTES } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'StoresList',
  props: {
    storesListCsvPath: {
      type: String,
      default: '',
    },
    redirectPath: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { localePath } = useI18n(root);
    const { setStoreCookie } = useSignInToStore(root);
    const { getArrayFromCsv } = useCms(root);
    const storesList = ref([]);

    onMounted(async () => {
      storesList.value = await getArrayFromCsv(props.storesListCsvPath);
      if (Object.keys(root.$route.query).length) {
        const storeIdKey = Object.keys(root.$route.query).find(
          (key) => key.toLowerCase() === 'storeid'
        );
        if (!storeIdKey) return;
        const sanitizedStoreId = Array.isArray(root.$route.query[storeIdKey])
          ? root.$route.query[storeIdKey][0]
          : root.$route.query[storeIdKey];
        const storeInCsv = storesList.value.find(
          (store) => store.storeId === sanitizedStoreId
        );
        if (storeInCsv) {
          setStoreCookie(storeInCsv);
          root.$router.push(pushPath);
        }
      }
    });

    const pushPath = props.redirectPath
      ? props.redirectPath
      : localePath(ROUTES.HOME());

    return {
      setStoreCookie,
      storesList,
      pushPath,
    };
  },
});
