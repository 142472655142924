export const getPriceFilterItemText = (
  item: { id?: string; value?: string; text: string },
  translations: {
    abovePriceFilterLabel: string;
    belowPriceFilterLabel: string;
  },
  priceFormatter: (
    price: number | string,
    currency?: string,
    rounded?: boolean
  ) => string
): string => {
  const matches = (item.id || item.value)?.match(
    /\[(\*|\d+)\s+TO\s+(\*|\d+)\]/
  );

  if (
    !matches ||
    !matches.some((value) => value.includes('*')) ||
    !translations ||
    !priceFormatter
  )
    return item.text;

  const [, min, max] = matches;

  const { abovePriceFilterLabel, belowPriceFilterLabel } = translations;

  const [label, value] =
    max === '*' ? [abovePriceFilterLabel, min] : [belowPriceFilterLabel, max];

  return label.replace('{{value}}', priceFormatter(value, undefined, true));
};
