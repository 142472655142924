const determineStickySelector = (
  isSmallViewport: boolean,
  shouldShowPdpStickyHeader: boolean
): string => {
  switch (true) {
    case isSmallViewport:
      return '.vf-header__sticky';
    case shouldShowPdpStickyHeader:
      return '.sticky-add-to-cart';
    default:
      return '';
  }
};

export function computeStickyHeaderHeight(
  isSmallViewport: boolean,
  shouldShowPdpStickyHeader: boolean
): number {
  const stickySelector = determineStickySelector(
    isSmallViewport,
    shouldShowPdpStickyHeader
  );
  if (!stickySelector) return 0;

  const stickyHeader = document.querySelector(stickySelector) as HTMLElement;

  return stickyHeader ? stickyHeader.offsetHeight : 0;
}
