











import {
  defineComponent,
  ref,
  PropType,
  onMounted,
} from '@vue/composition-api';
import {
  useAccount,
  useAuthentication,
  useNotification,
  useOrders,
} from '@vf/composables';
import { OrderSummaryProfileTranslations } from '@vf/api-contract';
import useRootInstance from '@/shared/useRootInstance';
import { format } from 'date-fns';

export default defineComponent({
  name: 'OrderSummaryCard',
  props: {
    translations: {
      type: Object as PropType<OrderSummaryProfileTranslations>,
      default: () => ({}),
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { getBasicInformation, getOrderHistory, orderHistory } = useAccount(
      root
    );
    const { consumerNo } = useAuthentication(root);
    const { addNotification } = useNotification(root);
    const { getOrderStatusLabel } = useOrders(root);

    type OrderItemProfile = {
      orderNumber: string;
      orderStatus: string;
      orderDate: string;
    };

    const orderItems = ref([]);
    const loading = ref(false);

    const filterOrderItemProps = (orderItem): OrderItemProfile => {
      const { order_number, status, order_date } = orderItem;
      return {
        orderNumber: order_number,
        orderStatus: getOrderStatusLabel(status, []),
        orderDate: format(new Date(order_date), 'MM/dd/yyyy'),
      };
    };

    const fetchOrderHistory = async () => {
      try {
        loading.value = true;
        await getOrderHistory({ pageSize: 3, pageNumber: 1 });
        orderItems.value = orderHistory.value.orders.map(filterOrderItemProps);
      } catch (error) {
        addNotification({
          errorMessageId: null,
          message: props.translations.serverErrorMessage,
          type: 'danger',
        });
      } finally {
        loading.value = false;
      }
    };

    onMounted(async () => {
      if (!consumerNo.value) {
        await getBasicInformation();
      }
      await fetchOrderHistory();
    });

    return {
      orderItems,
      loading,
    };
  },
});
