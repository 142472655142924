var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vf-rich-text-ctas",class:{ 'vf-rich-text-ctas--redesign-core': _vm.isCoreRedesignEnabled }},[_c('VfHeading',{staticClass:"vf-rich-text-ctas__header",attrs:{"title":_vm.title,"subtitle":_vm.subtitle}}),_vm._v(" "),_c('VfText',{staticClass:"vf-rich-text-ctas__text",attrs:{"html":true,"content":_vm.content}}),_vm._v(" "),(_vm.showButtons)?_c('div',{staticClass:"vf-rich-text-ctas__links",style:({ justifyContent: _vm.ctaAlign })},_vm._l((_vm.links),function(ref){
var id = ref.id;
var link = ref.link;
var text = ref.text;
var cta = ref.cta;
return _c('VfButton',{key:id,class:[
        'vf-rich-text-ctas__link',
        cta.customClasses,
        cta.textCustomClasses,
        cta.buttonStyle ? ("vf-button--" + (cta.buttonStyle)) : '',
        cta.buttonSize ? ("vf-button--" + (cta.buttonSize)) : '',
        cta.iconPosition ? ("vf-button--icon-" + (cta.iconPosition)) : '',
        cta.fontFamily,
        {
          'vf-button--underline': cta.underline,
          'vf-button--no-underline': !cta.underline,
        } ],attrs:{"id":cta.id,"link":link,"data-cm-metadata":_vm.$previewMetaDataLocation(cta.id),"disabled":!cta.enabled,"scroll-to-link":cta.scrollToLink,"icon":cta.icon === 'no-icon' ? '' : cta.icon,"text-color":cta.textColor,"font-size":cta.fontSize,"font-weight":cta.fontWeight,"open-in-new-modal":cta.openInNewModal,"open-in-new-tab":cta.openInNewTab},nativeOn:{"click":function($event){return _vm.closeModal.apply(null, arguments)}}},[_vm._v("\n      "+_vm._s(text)+"\n    ")])}),1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }