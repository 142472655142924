






import type { PropType } from 'vue';
import {
  useLoyalty,
  useNotification,
  useAuthentication,
} from '@vf/composables';
import {
  defineComponent,
  useMeta,
  useFetch,
  ref,
  watch,
  computed,
} from '@nuxtjs/composition-api';
import type { LoyaltyMemberDashboardTranslations } from '@vf/api-contract';
import useRootInstance from '@/shared/useRootInstance';

interface ILoyaltyCheetahContext {
  apiContext: {
    apiDomain: string;
  };
  sessionContext: {
    accessToken: string;
    expiresAt: number;
    refreshTokenCallback: () => Promise<{
      memberToken: string;
      expiresAt: number;
    }>;
  };
  localeContext:
    | string
    | {
        language: string;
        locale: string;
        country: string;
      };
  appConfigURL: string;
  welcomePromoStatusCallBack: () => void;
  messages: {
    loadingIndicatorText: string;
    retryAgain: string;
    appErrorMessage: string;
  };
  usid: string;
  source: string;
  brand: string;
  region: string;
  siteId: string;
  channel: string;
  myAccountOverviewPage: boolean;
  routerCallBack: (u: any) => void;
  location: string;
  setPageMeta: (value: any) => void;
}

export default defineComponent({
  name: 'LoyaltyMemberDashboard',
  components: {},
  props: {
    translations: {
      type: Object as PropType<LoyaltyMemberDashboardTranslations>,
      default: () => ({}),
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const {
      loyaltyToken,
      getLoyaltyToken,
      getLoyaltyWelcomePromoStatus,
    } = useLoyalty(root);
    const { getUsid } = useAuthentication(root);
    const {
      translations: { loadingIndicator, retryAgain, appError },
    } = props;
    const scriptReady = ref(false);
    const fullPath = computed(() => {
      return root.$route.fullPath;
    });
    const appRoot = ref(null);
    const { addNotification } = useNotification(root);

    const pageMeta = ref(null);
    const setPageMeta = (value) => {
      pageMeta.value = value;
    };

    useMeta(() => ({
      ...pageMeta.value,
      link: [
        {
          rel: 'stylesheet',
          type: 'text/css',
          href: root.$env.LOYALTY_APP_STYLESHEET,
        },
      ],
      script: [
        {
          vmid: 'vf-loyalty-app-script',
          type: 'text/javascript',
          src: root.$env.LOYALTY_APP_SCRIPT,
          defer: true,
          callback: () => {
            scriptReady.value = true;
          },
        },
      ],
    }));

    if (process.client) {
      if (typeof window['LoyaltyApp']?.renderApp === 'function') {
        scriptReady.value = true;
      }
      const { fetch } = useFetch(async () => {
        try {
          // this should not be a background request, since it is also used for the user datalayer
          await getLoyaltyToken();
        } catch (err) {
          console.error(err);
          addNotification({
            message: appError,
            type: 'danger',
          });
        }
      });

      fetch();
    }

    const router = root.$router;
    const routerCallBack = (u) => {
      router.push(u);
    };

    const refreshTokenCallback = async () => {
      const tokenObj = await getLoyaltyToken();
      return {
        memberToken: tokenObj.token,
        expiresAt: tokenObj.expiresAt,
      };
    };

    const renderApp = () => {
      if (loyaltyToken.value.token.length <= 0) {
        return;
      }
      if (!scriptReady.value) {
        return;
      }
      if (!appRoot.value) {
        return;
      }

      let ltyCtx: ILoyaltyCheetahContext = {
        apiContext: {
          apiDomain: root.$env.LOYALTY_APP_API_DOMAIN,
        },
        sessionContext: {
          accessToken: loyaltyToken.value.token,
          expiresAt: loyaltyToken.value.expiresAt,
          refreshTokenCallback,
        },
        localeContext: root.$getEnvValueByCurrentLocale<string>(
          'LOYALTY_APP_LOCALE_MAP'
        ),
        appConfigURL: root.$getEnvValueByCurrentLocale<string>(
          'LOYALTY_APP_CONFIG_URL'
        ),
        welcomePromoStatusCallBack: getLoyaltyWelcomePromoStatus,
        messages: {
          loadingIndicatorText: loadingIndicator,
          retryAgain,
          appErrorMessage: appError,
        },
        usid: getUsid.value,
        source: root.$env.API_SOURCE,
        brand: root.$env.BRAND?.toUpperCase(),
        region: root.$env.API_REGION,
        siteId: root.$getEnvValueByCurrentLocale<string>('API_SITE_ID'),
        channel: root.$env.API_CHANNEL,
        myAccountOverviewPage: false,
        routerCallBack,
        location: root.$route.fullPath,
        setPageMeta,
      };

      window['LoyaltyApp'].renderApp(appRoot.value, ltyCtx);
    };

    watch([loyaltyToken, scriptReady, fullPath, appRoot], renderApp);

    return {
      appRoot,
    };
  },
  head: {},
});
