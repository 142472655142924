







































import type { PropType } from 'vue';
import { computed, defineComponent } from '@vue/composition-api';
import {
  useProduct,
  useSectionNumeration,
  useValidation,
  useNotifyMe,
} from '@vf/composables';
import { validationMixin } from 'vuelidate';
import { email, required } from 'vuelidate/lib/validators';
import type { ProductSizesTranslations } from '@vf/api-contract';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'ProductNotificationsForm',
  mixins: [validationMixin],
  props: {
    translations: {
      type: Object as PropType<ProductSizesTranslations>,
      default: () => ({}),
    },
    /** Prefix counter label */
    showNumeration: {
      type: Boolean,
    },
    /** Select with page type */
    contextKey: {
      type: String,
      default: 'product',
    },
    /** Modifier class for form heading */
    headingModifier: {
      type: String,
      default: 'subtitle-2',
    },
    /** Show divider on quick shop page or not */
    showDivider: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { attrs }) {
    const { root } = useRootInstance();
    const { setValidation } = useValidation(root, 'NOTIFY_ME');
    const { product, attributesData } = useProduct(root, props.contextKey);
    const {
      emailAddress: notifyMeEmailAddress,
      subscriptionFeedback: notifyMeSubscriptionFeedback,
      isAlreadySubscribed,
    } = useNotifyMe(root, props.contextKey);
    const { getSectionNumber } = useSectionNumeration(root);

    const title = computed(() => {
      if (!attributesData.value.isFutureProduct)
        return props.translations.letMeKnow;
      const splitDate = product.value?.targetPublishDate?.split('-') || [];
      const formattedDate =
        splitDate.length === 3 ? `${splitDate[1]}/${splitDate[2]}` : '';
      const titleWithDate = props.translations.comingSoon.replace(
        '{{date}}',
        formattedDate
      );

      const prefixLabel = getSectionNumber(attrs['data-id'] as string);

      return props.showNumeration && prefixLabel
        ? `${prefixLabel}. ${titleWithDate}`
        : titleWithDate;
    });

    const introText = computed(() => {
      if (
        notifyMeSubscriptionFeedback.value.attempted &&
        notifyMeSubscriptionFeedback.value.success
      )
        return successText.value;
      return (attributesData.value.isFutureProduct
        ? props.translations.signUpToBeAlerted
        : props.translations.weCanNotifyYou
      )
        .replace('{{name}}', product.value?.name)
        .replace('{{color}}', product.value?.colorDescription);
    });

    const successText = computed(() => {
      return props.translations.youAreOnTheList
        .replace('{{name}}', product.value?.name)
        .replace('{{color}}', product.value?.colorDescription);
    });

    return {
      setValidation,
      title,
      introText,
      isAlreadySubscribed,
      notifyMeEmailAddress,
      notifyMeSubscriptionFeedback,
    };
  },
  mounted() {
    this.setValidation(this.$v);
  },
  validations: {
    notifyMeEmailAddress: {
      required,
      email: (eml) => email(eml ? eml.toLowerCase() : eml), // vuelidate considers domain names with captial letters as invalid
    },
  },
});
