






















export default {
  name: 'Toast',
  props: {
    title: {
      type: String,
    },
    content: {
      type: String,
    },
    type: {
      type: String,
      default: 'success',
      validator: function (value) {
        return ['success', 'danger'].includes(value);
      },
    },
    visibleFor: {
      type: Number,
      default: 2000,
    },
  },
};
