









import { defineComponent, onMounted, ref, watch } from '@vue/composition-api';
import { useGtm, useI18n, useMonetate, useProduct } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import Upsell from '@/components/static/cart/Upsell.vue';
import { getEventFromTemplate } from '@vf/composables/src/useGtm/helpers';
import { EnhancedSaleType } from '@vf/composables/src/types/gtm';
import { UpsellType } from '@vf/api-contract';
import { areSameItems } from '@/helpers/upsellHelper';

export default defineComponent({
  name: 'MonetateCartUpsell2',
  components: {
    Upsell,
  },
  props: {
    upsellsType: {
      type: String,
      default: UpsellType.Single,
    },
    experienceId: {
      type: String,
      default: null,
    },
    quickShopLink: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { defaultCurrency } = useI18n(root);
    const {
      extractRecommendedItems,
      sendRecImpressionsEvent,
      hasLoaded,
    } = useMonetate(root);
    const { dispatchEvent } = useGtm(root);
    const { getProductDetails } = useProduct(root);

    const transformProductForCart = (product, attributes = {}) => ({
      product: {
        id: product.id,
        name: product.title,
        itemGroupId: product.itemGroupId,
        description: product.description,
        attributes,
        quantity: 1,
        price: {
          original: product.price,
          current: product.salePrice,
          currency: defaultCurrency.value,
        },
        custom: false,
        upsell: true,
        image: {
          url: product.imageLink,
          alt: product.title,
        },
        savedForLater: false,
        pageUrl: product.link,
        recToken: product.recToken,
      },
      type: props.upsellsType,
    });

    const onLoad = (upsellProducts) => {
      sendRecImpressionsEvent(
        upsellProducts,
        0,
        upsellProducts.length,
        props.experienceId,
        root.$t('upsell.addToCartCta') as string,
        'Upsell Recommendation'
      );
      if (upsellProducts.length) {
        dispatchEvent({
          ...getEventFromTemplate('enhanced-sale:impression'),
          overrideAttributes: {
            type: EnhancedSaleType.UP_SELL,
            products: upsellProducts,
          },
        });
      }
    };

    const recommendedItems = ref([]);
    const upsells = ref([]);

    onMounted(() => {
      watch(
        hasLoaded,
        async () => {
          if (!hasLoaded.value) return;

          const newRecommendedItems = extractRecommendedItems(
            props.experienceId
          );
          if (!areSameItems(recommendedItems.value, newRecommendedItems)) {
            recommendedItems.value = newRecommendedItems;
            if (props.upsellsType === UpsellType.Single) {
              upsells.value = (
                await Promise.all(
                  recommendedItems.value.slice(0, 5).map(async (p) => {
                    const details = await getProductDetails(p.itemGroupId);
                    const variant = details.variants.find(
                      (variant) => variant.id === p.id
                    );
                    if (!variant) return;
                    return transformProductForCart(p, {
                      ...variant.attributes,
                    });
                  })
                )
              ).filter(Boolean);
            } else {
              upsells.value = recommendedItems.value
                .filter((i) => i.itemGroupId)
                .slice(0, 1)
                .map((p) => transformProductForCart(p));
            }
          }
        },
        { immediate: true }
      );
    });

    return {
      upsells,
      onLoad,
    };
  },
});
