





































































































































import {
  computed,
  defineComponent,
  PropType,
  watch,
} from '@vue/composition-api';
import { useFetch } from '@nuxtjs/composition-api';
import {
  useGtm,
  useProduct,
  useProductInventory,
  useSeo,
  useRequestTracker,
} from '@vf/composables';
import { Context } from '@vf/api-contract';
import useRootInstance from '@/shared/useRootInstance';
import useModal from '@/shared/useModal';
import { isClient } from '@vf/shared/src/utils/helpers';
import {
  RelatedProduct,
  SeoRecommendationType,
} from '@vf/composables/src/useSeo/types';
import { ProductDetailViewViewType } from '@vf/composables/src/types/gtm';
import {
  SeoRelatedWidgetTranslations,
  SlidesToShow,
  TitlePosition,
  SearchDisplayMode,
} from '@vf/api-contract';

export default defineComponent({
  name: 'SeoRelatedWidget',
  props: {
    slidesToShow: {
      type: Object as PropType<SlidesToShow>,
      required: true,
    },
    relatedProductsDisplayMode: {
      type: String as PropType<TitlePosition>,
      required: true,
    },
    relatedSearchesDisplayMode: {
      type: String as PropType<SearchDisplayMode>,
      default: 'merged',
    },
    showRelatedProducts: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    showRelatedSearches: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    relatedSearchesCount: {
      type: Number,
      required: true,
    },
    translations: {
      type: Object as PropType<SeoRelatedWidgetTranslations>,
      required: true,
    },
    quickShopLink: {
      type: String as PropType<string>,
      default: null,
    },
    contextKey: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const {
      currentRecommendation,
      getRecommendation,
      hasRelatedCategories,
      hasRelatedItems,
      seoObject,
      isVisible,
      setGlobalVariable,
      getPageData,
    } = useSeo(root, props.contextKey);
    const { openModal } = useModal();
    const { dispatchEvent } = useGtm(root);
    const { toggleQuickShop, findInStoreQuickShopVisible } = useProduct(
      root,
      Context.QuickShop
    );
    const { getProductInventory } = useProductInventory(
      root,
      Context.QuickShop
    );
    const { onAllDone } = useRequestTracker(root);

    const theme = root.$themeConfig.seoRelatedWidget;

    const swiperOptions = computed(() => ({
      classContainer: 'loop',
      gap: theme.sliderGap || '10px',
      loop: false,
      scrollBy: 1,
    }));

    const relatedSearches = computed(() =>
      currentRecommendation.value?.recommendationData
        ? [
            ...currentRecommendation.value.recommendationData.relatedItems,
            ...currentRecommendation.value.recommendationData.relatedCategories,
          ]?.slice(0, props.relatedSearchesCount)
        : null
    );

    if (isClient) {
      watch(
        () => root.$route.path,
        (newRoute, oldRoute) => {
          setGlobalVariable();
          if (!newRoute || oldRoute === newRoute) return;
          const pageData = getPageData(newRoute);
          if (!pageData) return;
          onAllDone(() => {
            getRecommendation(
              pageData.url,
              pageData.type as SeoRecommendationType,
              pageData.id
            );
          });
          setGlobalVariable(currentRecommendation.value);
        },
        { immediate: true }
      );
      setGlobalVariable(currentRecommendation.value);
    } else {
      useFetch(async () => {
        const pageData = getPageData(root.$route.path);
        if (!pageData) return;
        await getRecommendation(
          pageData.url,
          pageData.type as SeoRecommendationType,
          pageData.id
        );
      });
    }

    const showQuickShop = async (product: RelatedProduct) => {
      const productId = product.attributes?.find((a) => a.key === 'productId');
      findInStoreQuickShopVisible.value = false;

      // Fallback redirect to product page
      if (!productId || !productId.value) {
        root.$router.push(product.url);
      }

      try {
        await toggleQuickShop(productId.value);
        openModal({
          type: 'page',
          path: props.quickShopLink,
          contextKey: Context.QuickShop,
        });
        await getProductInventory(productId.value);
        dispatchEvent({
          eventName: 'productDetailView',
          composablesContexts: { useProduct: Context.QuickShop },
          overrideAttributes: {
            viewType: ProductDetailViewViewType.QUICK_VIEW,
          },
        });
      } catch (e) {
        root.$router.push(product.url);
      }
    };

    const getProductDescription = (product: RelatedProduct) => {
      return theme.descriptionKey === 'shortDescription'
        ? `${product[theme.descriptionKey]}...`
        : product[theme.descriptionKey];
    };

    return {
      currentRecommendation,
      isVisible,
      swiperOptions,
      relatedSearches,
      showQuickShop,
      seoObject,
      theme,
      getProductDescription,
      hasRelatedItems,
      hasRelatedCategories,
    };
  },
});
