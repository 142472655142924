








// removed composition API 'defineComponent' to save on overhead
// this component used nothing requiring composition API
export default {
  name: 'Placeholder',
  props: {
    loading: {
      type: String,
      default: 'initialized',
    },
    hasLoaded: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      default: '',
    },
    hasAnimation: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    classes() {
      if (this.loading === 'completed' && this.hasLoaded) {
        return this.$themeConfig.placeholder?.animateHide
          ? `${this.className} loaded-animation`
          : `${this.className} loaded`;
      } else {
        return `${this.className} pulse loading`;
      }
    },
  },
};
