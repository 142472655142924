




import type { PropType } from 'vue';
import { defineComponent } from '@vue/composition-api';
import type { SearchToggleTranslations } from '@vf/api-contract';

export default defineComponent({
  name: 'SearchToggle',
  props: {
    translations: {
      type: Object as PropType<SearchToggleTranslations>,
      default: () => ({}),
    },
  },
});
