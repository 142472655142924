import { ComponentInstance, useCustoms, useFavorites } from '@vf/composables';
import { sendToCustoms } from './index';

type CustomsAddToFavoritesEventBody = {
  sku: string;
  recipe: string;
};

type CustomsAddToFavoritesEvent = CustomsAddToFavoritesEventBody & {
  body?: CustomsAddToFavoritesEventBody;
};

type CustomsAddToFavoritesConfig = {
  componentIntegration: boolean;
};

const addToFavorites = async (
  instance: ComponentInstance,
  { componentIntegration }: CustomsAddToFavoritesConfig,
  event: CustomsAddToFavoritesEvent
): Promise<void> => {
  const recipe = 'body' in event ? event.body.recipe : event.recipe;
  const sku = 'body' in event ? event.body.sku : event.sku;
  const imageDetails =
    'body' in event ? event.body['2dImageView'] : event['2dImageView'];

  const { customsImage } = useCustoms(instance);
  const { addToFavorites: addToFavoritesCall, favoriteId } = useFavorites(
    instance
  );

  const productImageUrl = await customsImage({
    recipe,
    view: imageDetails?.view || 'left_square',
    size: imageDetails?.size || 'lg-square-white',
  });

  const response = await addToFavoritesCall({
    favoriteId: favoriteId.value ?? '',
    recipeId: recipe,
    id: sku,
    itemType: 'product',
    pdpUrl: `${instance.$route.path}?recipe=${recipe}`,
    productImageUrl,
    qty: 1,
    defaultProductView: '',
    public: true,
  });

  sendToCustoms(
    instance,
    {
      command: componentIntegration ? 'parentAddToFavorites' : 'addToFavorites',
      body: {
        status: response ? 'success' : 'error',
        message: response
          ? 'successfully added to favorites'
          : 'errror in adding to favorites',
      },
    },
    {
      componentIntegration: componentIntegration,
    }
  );
};

export default addToFavorites;
