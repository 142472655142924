var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VfContainer',{class:{
    'vf-discount-notification--core-redesign': _vm.isCoreRedesignEnabled,
    'vf-discount-notification--redesign': _vm.isRedesignEnabled,
  }},[(_vm.showNotification && _vm.message)?_c('div',[_c('VfNotification',{staticClass:"vf-notification--employee-discount",class:{
        'vf-notification--center vf-notification--with-gray-background vf-notification--no-box-shadow': !_vm.isRedesignEnabled,
      },attrs:{"type":"success","show-close-button":false,"message":_vm.message,"visible":"","html":""}})],1):_vm._e(),_vm._v(" "),(_vm.showNotification && _vm.seasonalMessage)?_c('div',[_c('VfNotification',{staticClass:"vf-notification--employee-discount",class:{
        'vf-notification--center vf-notification--with-gray-background vf-notification--no-box-shadow': !_vm.isRedesignEnabled,
      },attrs:{"type":"success","show-close-button":false,"message":_vm.seasonalMessage,"visible":"","html":""}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }