
























import {
  computed,
  onMounted,
  defineComponent,
  ref,
  PropType,
} from '@vue/composition-api';
import { useSearch, useArticles, useMonetate } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';

type Toggle = {
  label: string;
  value: string;
};

export default defineComponent({
  name: 'SearchResultsToggle',
  props: {
    toggles: {
      type: Array as PropType<Toggle[]>,
      default: () => [],
    },
    /** should display count of the results, when products toggle is selected */
    displayProductsCount: {
      type: Boolean,
      default: false,
    },
    contextKey: {
      type: String,
      default: 'page-content',
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { setSearchType, searchType, pagination } = useSearch(root);
    const { setHasLoaded } = useMonetate(root);
    const {
      pagination: articlesPagination,
      noArticlesFound,
      sortingOptions,
      toggleSelection,
      loading: loadingArticles,
      setLoading,
      setTransitionFromProducts,
      transitioningFromProducts,
      noResultQueryString,
    } = useArticles(root, props.contextKey);

    const currentSearchType = ref('');
    const itemsCountLabel = computed(() => {
      if (
        searchType.value === 'product' ||
        (loadingArticles.value && transitioningFromProducts.value)
      ) {
        return pagination.value.total;
      }
      return noArticlesFound.value || noResultQueryString.value
        ? 0
        : articlesPagination.value.total;
    });
    const selectedToggle = computed(() => root.$route.path);

    const isTab = root.$themeConfig?.toggleType?.isTab || false;

    onMounted(() => {
      currentSearchType.value = selectedToggle.value.includes('article')
        ? 'article'
        : 'product';
      setSearchType(currentSearchType.value);
    });

    const setResultType = (toggle: Toggle) => {
      if (root.$route.path !== toggle.value) {
        toggle.value.includes('product') && setHasLoaded(false);
        if (toggle.value.includes('article')) {
          setLoading();
          setTransitionFromProducts();
        }
        setSearchType(toggle.label.toLowerCase());
        root.$router.push({
          path: toggle.value,
          query: { q: root.$route.query.q },
        });
      }
    };

    const searchToggleOptions = computed<Toggle[]>(() => {
      if (!props.displayProductsCount) return props.toggles;
      let toggleLabel: string;
      return props.toggles.map(({ label, value }) => {
        if (isTab && value.includes(searchType.value)) {
          toggleLabel = `${label} (${itemsCountLabel.value})`;
        } else {
          toggleLabel = label;
        }
        return {
          label: toggleLabel,
          value,
        };
      });
    });

    return {
      setResultType,
      searchType,
      selectedToggle,
      searchToggleOptions,
      isTab,
      itemsCountLabel,
      sortingOptions,
      toggleSelection,
    };
  },
});
