







































































































import type { PropType } from 'vue';
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
} from '@vue/composition-api';
import { apiClientFactory } from '@vf/api-client';
import type { LoyaltyQuestionsTranslations } from '@vf/api-contract';
import useRootInstance from '@/shared/useRootInstance';
import { Interest } from '@vf/composables/src/useCms/types';

const MAX_USER_AGE = 100;
const STORE_LOCATOR_RADIUS = '100';
const fillUserAgeSelect = (maxAge) =>
  Array.from({ length: maxAge }, (_, i) => i + 1);

export default defineComponent({
  name: 'LoyaltyQuestions',
  props: {
    postalCode: {
      type: String,
      default: '',
    },
    interests: {
      type: Array as PropType<Interest[]>,
      required: true,
    },
    showHeading: {
      type: Boolean,
      default: true,
    },
    showSubheading: {
      type: Boolean,
      default: true,
    },
    translations: {
      type: Object as PropType<LoyaltyQuestionsTranslations>,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['submit'],
  setup(props, { emit }) {
    const { root } = useRootInstance();
    const { locatorSearch } = apiClientFactory(root);
    const state = reactive({
      store: null,
      shoes: {
        size: null,
        gender: null,
      },
      age: null,
      interests: props.interests.map((interest) => ({
        ...interest,
        isActive: false,
      })),
    });

    const stores = ref([]);
    const shoesSizes = ref([]);
    const ageList = ref(fillUserAgeSelect(MAX_USER_AGE));

    const storePrefixMap = JSON.parse(root.$env.LOYALTY_STORE_PREFIX_MAP) ?? {};
    const replaceStorePrefix = (storeId) => {
      const prefix = Object.keys(storePrefixMap).find((p) =>
        storeId.startsWith(p)
      );
      if (prefix) {
        return storeId.replace(prefix, storePrefixMap[prefix]);
      }
      return storeId;
    };

    const getStores = async () => {
      const storesList = [
        {
          label: props.translations.whichLocation.differentStore,
          value: 'other',
        },
        {
          label: props.translations.whichLocation.didNotHearInStore,
          value: 'none',
        },
      ];

      try {
        if (props.postalCode) {
          const response = await locatorSearch({
            postalCode: props.postalCode,
            distance: STORE_LOCATOR_RADIUS,
            unitOfMeasure: 'km',
          });

          if (response.data.code === 1 && response.data.response.collection) {
            const stores = response.data.response.collection
              .filter((shop) => shop.enterprise_store_identifier)
              .map(({ name, enterprise_store_identifier }) => ({
                label: name,
                value: replaceStorePrefix(enterprise_store_identifier),
              }));

            return [...stores, ...storesList];
          }
        }
        return storesList;
      } catch (e) {
        return storesList;
      }
    };

    const handleStoreSelect = (value) => (state.store = value);
    const handleAgeSelect = (value) => (state.age = value);
    const handleInterestsChange = ({ code, isActive }) => {
      const interest = state.interests.find(
        (interest) => interest.code === code
      );
      interest.isActive = isActive;
    };
    const handleFormSubmit = () => {
      emit('submit', {
        ...state,
        interests: state.interests
          .filter((interest) => interest.isActive)
          .map((interest) => interest.code)
          .join('|'),
      });
    };

    onMounted(async () => {
      stores.value = await getStores();
    });

    return {
      state,
      stores,
      shoesSizes,
      ageList,
      handleStoreSelect,
      handleAgeSelect,
      handleInterestsChange,
      handleFormSubmit,
    };
  },
});
