var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"fade"}},[_c('VfConditionalRenderer',_vm._g({staticClass:"vf-mega-menu",class:{
      'vf-mega-menu--with-aside': _vm.withAside,
      'vf-mega-menu--redesign-core': _vm.isCoreRedesignEnabled,
    },attrs:{"show":_vm.visible,"ssr":_vm.ssr}},_vm.$listeners),[_vm._t("shop-all",null,{"classList":_vm.getSpacersClasslist(_vm.subcategories.filter(function (i) { return i.stateFulfilled; })[0]),"styleList":_vm.getSpacersCustomStyles(
          _vm.subcategories.filter(function (i) { return i.stateFulfilled; })[0]
        )}),_vm._v(" "),_c('div',{class:[
        'vf-mega-menu__content',
        { 'vf-mega-menu__content--offset-top': !_vm.isShopAllSlotUsed } ]},[(_vm.withAside)?_c('div',{staticClass:"vf-mega-menu__aside"},[_c('VfContentPages',{staticClass:"menu-aside",attrs:{"active":_vm.activePageId,"subcategories":_vm.stateFulfilledCategories,"subcategories-max-items-config":_vm.subcategoriesMaxItemsConfig},on:{"click-change":function($event){_vm.activePageId = $event}},scopedSlots:_vm._u([{key:"menu-item",fn:function(ref){
      var updatePage = ref.updatePage;
      var category = ref.category;
      var active = ref.active;
return [_c('VfMenuItem',{staticClass:"vf-content-pages__menu",class:{ 'vf-menu-item--is-active': category.id === active },attrs:{"label":category.title},on:{"mouseover":function($event){return updatePage(category.id)}},scopedSlots:_vm._u([{key:"label",fn:function(ref){
      var label = ref.label;
return [_c('VfButton',_vm._b({staticClass:"vf-menu-item__label vf-button--text vf-button--no-underline",on:{"mouseover":function($event){return updatePage(category.id)}}},'VfButton',category.link ? { link: category.link } : {},false),[(!category.isImage)?_c('span',[_vm._v(_vm._s(label))]):_c('VfImage',{attrs:{"src":category.imageData,"width":category.imageData.width,"height":category.imageData.height}})],1)]}},{key:"mobile-nav-icon",fn:function(){return [_c('span')]},proxy:true}],null,true)})]}}],null,false,3987129940)},_vm._l((_vm.stateFulfilledCategories),function(subcategory,key){return _c('VfContentPage',{key:("" + (subcategory.id) + key),attrs:{"page-id":subcategory.id,"title":subcategory.title}},[_c('div',{staticClass:"menu-aside__content"},[_c('div',{staticClass:"menu-aside__links"},_vm._l((subcategory.columns.filter(
                    function (i) { return i.stateFulfilled; }
                  )),function(column,l1index){return _c('div',{key:'column' + l1index},_vm._l((column.level2MenuItems.filter(
                      function (i) { return i.stateFulfilled; }
                    )),function(l2Item,l2index){return _c('div',{key:("column-item" + l2index)},[(!l2Item.isImage)?_c('ThemeLink',{staticClass:"menu-aside__link",style:(Object.assign({}, l2Item.componentStyles)),attrs:{"data-navigation":l2Item.dataNavigation,"data-dom-location":_vm.AnalyticsDataDomLocation.TopNavigation,"to":l2Item.link,"target":l2Item.openInNewTab ? '_blank' : '_self'},on:{"click":function($event){return _vm.handleNavLinkClick($event, l2Item.link, {
                          l2: subcategory.name,
                          l3: l2Item.name,
                          isModal: l2Item.openInNewModal,
                        })}}},[_vm._v("\n                      "+_vm._s(l2Item.title))]):_c('VfImage',{attrs:{"src":l2Item.imageData,"width":l2Item.imageData.width,"height":l2Item.imageData.height}}),_vm._v(" "),_vm._l((l2Item.children.filter(
                        function (i) { return i.stateFulfilled; }
                      )),function(l3Item,l3Index){return _c('div',{key:("column-item-l3-" + l3Index)},[(!l3Item.isImage)?_c('ThemeLink',{staticClass:"menu-aside__link",style:(Object.assign({}, l3Item.componentStyles)),attrs:{"to":l3Item.link,"target":l3Item.openInNewTab ? '_blank' : '_self'},on:{"click":function($event){return _vm.handleNavLinkClick($event, l3Item.link, {
                            l2: l2Item.name,
                            l3: l3Item.name,
                            isModal: l3Item.openInNewModal,
                          })}}},[_vm._v("\n                        "+_vm._s(l3Item.title))]):_c('VfImage',{attrs:{"src":l3Item.imageData,"width":l3Item.imageData.width,"height":l3Item.imageData.height}})],1)})],2)}),0)}),0)])])}),1)],1):_c('div',{staticClass:"vf-mega-menu__menu"},[(_vm.featured.length)?_c('div',{staticClass:"vf-mega-menu-column menu-column--fixed large-only"},[_c('VfMenuItem',{staticClass:"vf-mega-menu-column__header",attrs:{"label":'Featured'},scopedSlots:_vm._u([{key:"mobile-nav-icon",fn:function(){return [_c('span')]},proxy:true}],null,false,1203546006)}),_vm._v(" "),_c('div',{staticClass:"vf-mega-menu-column__content"},[_c('VfList',_vm._l((_vm.featured),function(item,key){return _c('VfListItem',{key:key},[(!item.isImage)?_c('ThemeLink',{staticClass:"menu-column__item",attrs:{"data-dom-location":_vm.AnalyticsDataDomLocation.TopNavigation,"to":item.link,"data-navigation":item.dataNavigation},on:{"click":function($event){return _vm.handleNavLinkClick($event, item.link, {
                      l2: 'Featured',
                      l3: item.name,
                    })}}},[_vm._v("\n                  "+_vm._s(item.title)+"\n                ")]):_c('VfImage',{attrs:{"src":item.imageData,"width":item.imageData.width,"height":item.imageData.height}})],1)}),1)],1)],1):_vm._e(),_vm._v(" "),_vm._t("content",function(){return _vm._l((_vm.subcategories.filter(
              function (i) { return i.stateFulfilled; }
            )),function(subcategory,key){return _c(_vm.setSubcategoryTag(subcategory),{key:'subcategory' + key,tag:"component",staticClass:"vf-mega-menu-column",class:Object.assign({}, _vm.getSpacersClasslist(subcategory),
              {espot: _vm.isCoreRedesignEnabled && _vm.hasEspot(subcategory)}),style:(Object.assign({}, _vm.getSpacersCustomStyles(subcategory),
              {'--vf-column-quantity': subcategory.medium}))},_vm._l((subcategory.level2MenuItems.filter(
                function (i) { return i.stateFulfilled; }
              )),function(l2,l2key){return _c(_vm.setL2Tag(l2),{key:("l2MenuItems" + l2key),tag:"component",class:l2.cssClass ? l2.cssClass : null},[(!l2.isImage)?[(!l2.placeholder)?_c('ThemeLink',{class:['menu-column__title', l2.style],style:(Object.assign({}, l2.componentStyles)),attrs:{"to":l2.link,"target":l2.openInNewTab ? '_blank' : '_self',"data-navigation":l2.dataNavigation,"data-dom-location":_vm.AnalyticsDataDomLocation.TopNavigation,"aria-label":_vm.formatForScreenReader(l2.title)},on:{"click":function($event){return _vm.handleNavLinkClick($event, l2.link, {
                      l2: l2.name,
                      isModal: l2.openInNewModal,
                    })}}},[_vm._v("\n                  "+_vm._s(l2.title)+"\n                ")]):_c('p',{class:['menu-column__title', l2.style],attrs:{"aria-label":_vm.formatForScreenReader(l2.title)}},[_vm._v("\n                  "+_vm._s(l2.title)+"\n                ")]),_vm._v(" "),_c('div',{staticClass:"vf-mega-menu-column__content"},[_c('VfList',_vm._l((l2.children.filter(
                        function (i) { return i.stateFulfilled; }
                      )),function(l3,l3key){return _c('VfListItem',{key:l3key,class:l3.isImage ? 'vf-list__image' : ''},[(!l3.isImage)?[(!l3.placeholder)?_c('ThemeLink',{class:['menu-column__item', l3.style],style:(Object.assign({}, l3.componentStyles)),attrs:{"to":l3.link,"target":l3.openInNewTab ? '_blank' : '_self',"data-navigation":l3.dataNavigation,"data-dom-location":_vm.AnalyticsDataDomLocation.TopNavigation},on:{"click":function($event){return _vm.handleNavLinkClick($event, l3.link, {
                              l2: l2.name,
                              l3: l3.name,
                              isModal: l3.openInNewModal,
                            })}}},[_vm._v("\n                          "+_vm._s(l3.title)+"\n                        ")]):_c('p',{class:[
                            'vf-link menu-column__item item-placeholder',
                            l3.style ]},[_vm._v("\n                          "+_vm._s(l3.title)+"\n                        ")])]:_c('VfLink',{attrs:{"link":l3.link}},[_c('VfImage',{attrs:{"src":l3.imageData,"width":0,"height":0}})],1)],2)}),1)],1)]:[_c('VfImage',{attrs:{"src":l2.imageData,"width":0,"height":0}})]],2)}),1)})})],2)])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }