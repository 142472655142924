





























import { defineComponent, ref, onMounted } from '@vue/composition-api';
import { useReturns, useAccount } from '@vf/composables';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'SelectRefundMethod',
  serverCacheKey: getCacheKeyFromProps,
  props: {
    title: {
      type: String,
      default: '1. SELECT REFUND METHOD',
    },
    options: {
      type: Array,
      default: () => [
        {
          label: 'Refund return amount to my original form of payment',
          method: 'card',
          tooltip: 'First tooltip',
        },
        {
          label: 'Receive an E-gift card via email',
          method: 'giftCard',
          tooltip: 'Second tooltip',
        },
      ],
    },
    inputLabel: {
      type: String,
      default: 'Email Address',
    },
  },
  setup() {
    const { root } = useRootInstance();
    const { setGiftOrder, giftEmail, returnStep } = useReturns(root);
    const { basicInformation, getBasicInformation } = useAccount(root);
    const radioValue = ref('card');
    const setMethod = (method: string) => {
      radioValue.value = method;
      setGiftOrder(method === 'giftCard');
    };

    onMounted(async () => {
      try {
        if (basicInformation.value.email === '') {
          await getBasicInformation();
        }
        giftEmail.value = basicInformation.value.email;
      } catch (error) {
        console.log(error);
      }
    });
    return { setMethod, radioValue, giftEmail, returnStep };
  },
});
