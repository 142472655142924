




























































import { defineComponent, ref } from '@nuxtjs/composition-api';
import { Context } from '@vf/api-contract';
import {
  useAuthentication,
  useGridConfig,
  useGtm,
  useNotification,
  useProduct,
  useProductInventory,
} from '@vf/composables';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import ProductGridRecycleList from '@/components/smart/shared/ProductGridRecycleList.vue';
import useRootInstance from '@/shared/useRootInstance';
import ProductCardPlaceholder from './ProductsGrid/ProductCardPlaceholder.vue';
import ProductsGridItem from './ProductsGrid/ProductsGridItem.vue';
import { CmsProps } from './ProductsGrid/CmsProps';

export default defineComponent({
  name: 'ProductsGrid',
  components: {
    ProductGridRecycleList,
    ProductsGridItem,
    ProductCardPlaceholder,
  },
  props: {
    ...CmsProps,
    products: {
      type: Array,
      required: true,
    },
    espots: {
      type: Array,
      default: () => [],
    },
    contextName: {
      type: String,
      default: '',
    },
    searchState: {
      type: Object,
      default: null,
    },
    contextKey: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    eagerLoadedProducts: {
      type: Number,
      default: 6,
    },
  },
  setup(props, { emit }) {
    const { root } = useRootInstance();
    const { currentConfig, isOneColumn } = useGridConfig(root);
    const { toggleQuickShop, findInStoreQuickShopVisible } = useProduct(
      root,
      Context.QuickShop
    );
    const { addNotification } = useNotification(root);
    const { dispatchEvent } = useGtm(root);
    const { setPendingActionForLoyaltyUser } = useAuthentication(root);
    const { getProductInventory, inventoryLoading } = useProductInventory(
      root,
      Context.QuickShop
    );
    // TODO: Cleanup in GLOBAL15-63799
    const { isVansPlpRedesignEnabled } = useFeatureFlagsStore();

    const theme = root.$themeConfig.productsGrid;

    const recycleListRef = ref(null);

    const loadPreviousPageTrigger = () => {
      const [firstChild] = recycleListRef.value.$refs.rowsRef;
      const id = firstChild.getAttribute('data-lazy-row-id');
      const elementToScroll = `[data-lazy-row-id="${id}"]`;
      emit('load-previous-page', elementToScroll);
    };

    return {
      currentConfig,
      isOneColumn,
      isVansPlpRedesignEnabled,
      setPendingActionForLoyaltyUser,
      dispatchGtmEvent: dispatchEvent,
      inventoryLoading,
      findInStoreQuickShopVisible,
      toggleQuickShop,
      getProductInventory,
      addNotification,
      loadPreviousPageTrigger,
      recycleListRef,
      theme,
    };
  },
  computed: {
    productSlots() {
      if (!this.$props.products.length) {
        return [];
      }

      const slots = [];
      const espots = [...this.$props.espots];
      for (let pos = 0; pos < this.$props.products.length; pos++) {
        const espot = espots.find((obj) => pos === obj.position - 1);
        if (espot) {
          slots.push({ espot: true, ...espot });
          espots.splice(espots.indexOf(espot), 1);
        }
        slots.push(this.$props.products[pos]);
      }

      const items = slots.concat(
        espots.map((espot) => ({ espot: true, ...espot }))
      );

      return items;
    },
    isLoadPreviousButtonVisible() {
      return (
        this.theme.loadPrevious.isLoadPreviousEnabled &&
        this.$props.searchState.previousPage > 0
      );
    },
    viewPreviousButtonClasses() {
      return `vf-button--default vf-button--large vf-button--mobile-full
      ${this.theme.loadPrevious.loadPreviousAdditionalButtonClasses}`;
    },
    imageSize() {
      return (
        this.theme.imageSizes[this.$root.$viewport.size] ?? {
          width: 380,
          height: 380,
        }
      );
    },
    productsPerRow() {
      const grid = this.currentConfig.grid;
      const size = this.$root.$viewport.size;

      return this.$props.itemsPerRow[size] || 12 / grid[size];
    },
  },
  methods: {
    columnSizes(width = '1') {
      const grid = this.currentConfig.grid;
      const { small, medium, large } = this.$props.itemsPerRow;
      const parsedWidth = Number(width);

      return {
        small: (small ? 12 / small : grid.small) * parsedWidth,
        medium: (medium ? 12 / medium : grid.medium) * parsedWidth,
        large: (large ? 12 / large : grid.large) * parsedWidth,
      };
    },
    calculateCurrentPageForItem(categoryProduct) {
      const index = this.$props.products.findIndex(
        (p) => p.id === categoryProduct.id
      );
      const currentPageByIndex = Math.ceil(
        (index + 1) / this.$props.productsPerPage
      );
      const previousPage = this.isLoadPreviousButtonVisible
        ? this.$props.searchState.previousPage
        : 0;

      return previousPage + currentPageByIndex;
    },
  },
});
