













import { PropType } from 'vue';
import {
  defineComponent,
  nextTick,
  onMounted,
  ref,
  watch,
} from '@vue/composition-api';
import { CheckoutContext } from '@vf/api-contract';
import useRootInstance from '@/shared/useRootInstance';
import usePayPalButton from '@/shared/usePayPalButton';
import {
  usePayPalStore,
  PayPalContext,
} from '@vf/composables/src/store/paypal';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'CybersourcePayPal',
  /** PayPal style object */
  props: {
    header: {
      type: String,
      default: '',
    },
    context: {
      type: String as PropType<CheckoutContext>,
      required: true,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const payPalStore = usePayPalStore();
    const { paypalScriptLoaded } = storeToRefs(payPalStore);
    const {
      renderPayPalButton,
      setPayPalContext,
      payPalContext,
    } = usePayPalButton();

    const button = ref(null);

    onMounted(() => {
      setPayPalContext(props.context as PayPalContext);
      const unwatch = watch(
        paypalScriptLoaded,
        async (value) => {
          if (value) {
            renderPayPalButton(
              button.value,
              root.$themeConfig.staticCheckout.payPal
            );
            nextTick(() => {
              // https://github.com/vuejs/docs/issues/575
              unwatch?.();
            });
          }
        },
        {
          immediate: true,
        }
      );
    });

    return {
      button,
      payPalContext,
    };
  },
});
