































import { PropType } from 'vue';
import { computed, defineComponent } from '@vue/composition-api';
import {
  ApplePayContext,
  useApplePay,
  useCart,
  useSignInToStore,
} from '@vf/composables';
import {
  CheckoutContext,
  ApplePayTranslations,
  CheckoutSidebarTranslations,
} from '@vf/api-contract';
import PayPal from '../../payment/PayPal.vue';
import ApplePay from '../../payment/ApplePay.vue';
import useRootInstance from '@/shared/useRootInstance';
import useLoader from '@/shared/useLoader';

export default defineComponent({
  name: 'AdditionalPaymentButtons',
  components: {
    PayPal,
    ApplePay,
  },
  props: {
    applePayButtonVariant: {
      type: String as PropType<'primary' | 'secondary'>,
      default: 'primary',
    },
    translations: {
      type: Object as PropType<CheckoutSidebarTranslations>,
      required: true,
    },
    applePayTranslations: {
      type: Object as PropType<ApplePayTranslations>,
      required: true,
    },
    showApplePayComponent: {
      type: Boolean,
      default: false,
    },
    /** Context to determine where component is rendered (mini-cart|cart|shipping|payment) */
    context: {
      type: String as PropType<CheckoutContext>,
      default: CheckoutContext.Cart,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { showSpinner, hideSpinner } = useLoader();
    const theme = {
      hidePaypalForEmployee:
        root.$themeConfig?.checkoutSidebar?.hidePaypalForEmployee || false,
    };

    const { cart } = useCart(root);
    const { employeeConnected } = useSignInToStore(root);
    const applePayContext = ['cart', 'mini-cart'].includes(props.context)
      ? ApplePayContext.CART
      : ApplePayContext.CHECKOUT;
    const { isApplePayAvailable, initApplePaySession } = useApplePay(
      root,
      applePayContext
    );

    const isCartButtonsDisabled = computed(() => !cart.value.totalItems);

    const showPayPalButton = computed(() => {
      return !(theme.hidePaypalForEmployee && employeeConnected.value);
    });

    const showApplePayButton = computed(() => {
      return isApplePayAvailable() && props.showApplePayComponent;
    });

    const showExpressCheckoutButtons = computed(() => {
      return (
        ['cart', 'mini-cart'].includes(props.context) &&
        !isCartButtonsDisabled.value &&
        (showPayPalButton.value || showApplePayButton.value)
      );
    });

    const showApplePayPaymentSheet = () => {
      showSpinner();
      initApplePaySession(props.applePayTranslations, hideSpinner, false);
    };

    return {
      showPayPalButton,
      showApplePayButton,
      showExpressCheckoutButtons,
      ApplePayContext,
      showApplePayPaymentSheet,
    };
  },
});
