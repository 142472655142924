import useRootInstance from '@/shared/useRootInstance';
import { useFavorites, useGtm, useUrl } from '@vf/composables';
import { Product } from '@vf/api-client/src/types';
import { PageTypeName } from '@vf/composables/src/useCms/types';
import { getEventFromTemplate } from '@vf/composables/src/useGtm/helpers';
import { isCustomsProduct } from '@vf/composables/src/useCustoms/utils';

type UseFavoritesWithDataLayer = {
  removeFromFavorites: (product: Product) => Promise<boolean>;
  addToFavorites: (product: Product) => Promise<boolean>;
  toggleFavorites: (product: Product) => Promise<boolean>;
};

export const useFavoritesWithDataLayer = (
  eventCategory: PageTypeName
): Omit<
  ReturnType<typeof useFavorites>,
  'removeFromFavorites' | 'addToFavorites'
> &
  UseFavoritesWithDataLayer => {
  const { root } = useRootInstance();

  const {
    addToFavorites,
    removeFromFavorites,
    isFavorite,
    favoriteId,
    getFavorites,
    setFavoriteId,
    setFavoritesCount,
    favorites,
    favoritesCount,
    ownerFirstName,
    setShareLink,
    getSharedFavorites,
    sharedFavorites,
    sharedFavoritesCount,
    sharedOwnerFirstName,
    sharedFavoriteLink,
    isFavoritesTooltipOpen,
    showFavoritesTooltip,
    loading,
    isPublicList,
    moveFavoriteItemToCart,
    makeImageVariantUrlIdentifierByProduct,
    makeImageUrlIdentifierByProductId,
  } = useFavorites(root);

  const { dispatchEvent } = useGtm(root);
  const { getProductUrl } = useUrl(root);

  const extractEventLabelFromProduct = (product: Product) => {
    return `${product.id} - ${product.colorDescription}`;
  };

  const handleRemoveFromFavorites = async (product: Product) => {
    const eventLabel = extractEventLabelFromProduct(product);

    const isSuccessfullyRemoved = await removeFromFavorites(
      product.id,
      product.recipeId
    );

    if (isSuccessfullyRemoved) {
      dispatchEvent(
        getEventFromTemplate('favorite:remove', {
          eventCategory,
          eventLabel,
        })
      );
    }

    return isSuccessfullyRemoved;
  };

  const handleAddToFavorites = async (product: Product) => {
    const eventLabel = extractEventLabelFromProduct(product);
    const productUrl = getProductUrl(product, {
      recipe: product.recipe || product.recipeId || product.customsRecipeID,
    });

    const imageUrlIdentifier = makeImageVariantUrlIdentifierByProduct(product);

    let recipeId: string = '';
    if (isCustomsProduct(product)) {
      recipeId = product.customsRecipeID || product.recipe || product.recipeId;
    }
    const isSuccessfullyAdded = await addToFavorites({
      favoriteId: favoriteId.value ?? '',
      recipeId,
      dummyCustoms: product.dummyCustoms,
      id: product.id,
      available: product.available || '',
      qty: 1,
      itemType: 'product',
      defaultProductView: '',
      public: true,
      pdpUrl: product.pdpUrl ?? productUrl,
      pageUrl: product.pageUrl ?? productUrl,
      productImageUrl:
        product.productImageUrl ||
        (root.$mediaUrlGenerator({
          colorName: product.color?.label,
          pid: [PageTypeName.PLP, PageTypeName.SEARCH].includes(eventCategory)
            ? product.id
            : imageUrlIdentifier,
          productName: product.name,
        }) as string),
    });

    if (isSuccessfullyAdded) {
      dispatchEvent(
        getEventFromTemplate('favorite:add', {
          eventCategory,
          eventLabel,
        })
      );
    }

    return isSuccessfullyAdded;
  };

  const handleToggleFavorites = async (product: Product) => {
    const isAlreadyFavorite = isFavorite(product.id, product.recipeId);

    const success = isAlreadyFavorite
      ? await handleRemoveFromFavorites(product)
      : await handleAddToFavorites(product);

    return success === true ? !isAlreadyFavorite : undefined;
  };

  return {
    removeFromFavorites: handleRemoveFromFavorites,
    addToFavorites: handleAddToFavorites,
    toggleFavorites: handleToggleFavorites,
    getFavorites,
    isFavorite,
    setFavoriteId,
    setFavoritesCount,
    favorites,
    favoriteId,
    favoritesCount,
    ownerFirstName,
    setShareLink,
    getSharedFavorites,
    sharedFavorites,
    sharedFavoritesCount,
    sharedOwnerFirstName,
    sharedFavoriteLink,
    isFavoritesTooltipOpen,
    showFavoritesTooltip,
    loading,
    isPublicList,
    moveFavoriteItemToCart,
    makeImageVariantUrlIdentifierByProduct,
    makeImageUrlIdentifierByProductId,
  };
};
