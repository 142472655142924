





















import type { PropType } from 'vue';
import { defineComponent } from '@vue/composition-api';
import type { OrderDetailsSidebarTranslations } from '@vf/api-contract';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'BackToOrderHistoryButton',
  props: {
    translations: {
      type: Object as PropType<OrderDetailsSidebarTranslations>,
      default: () => ({}),
    },
    /** Teaser link */
    teaserLink: {
      type: String,
      default: '',
    },
    showPageTitle: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { root } = useRootInstance();
    return {
      backToHistoryLinkCustomClass:
        root.$themeConfig?.orderDetails?.backToHistoryLinkCustomClass ?? '',
    };
  },
});
