


























import { defineComponent, computed } from '@vue/composition-api';
import useModal from '@/shared/useModal';
import { isImageTypeOf } from '@/helpers';

export default defineComponent({
  name: 'LoyaltyPdpBanner',
  props: {
    /** Title for the loyalty pdp banner*/
    title: { type: String, default: 'EARN POINTS GET REWARDS' },
    /** Image object for pdp banner */
    imageObject: {
      type: Object,
      default: null,
    },
    /** Link for the loyLoyaltyPdpBanner.spec pdp banner */
    link: { type: String, default: '' },
    /** Text for the cta */
    ctaLabel: { type: String, default: '' },
    /** If the cta need to be opened in a new modal */
    openInNewModal: { type: Boolean, default: true },
  },
  setup(props) {
    const { openModal, getModalSettingsFromLink } = useModal();

    const imageHeight = computed(() =>
      /** SVG images are excluded from adding dimensions as they are not handled properly by cms - get default value of 400 */
      isImageTypeOf(props.imageObject.images, 'svg')
        ? null
        : props.imageObject.images?.height ?? null
    );

    const imageWidth = computed(() =>
      isImageTypeOf(props.imageObject.images, 'svg')
        ? null
        : props.imageObject.images?.width ?? null
    );

    const openLinkInModal = (link: string): void => {
      openModal(getModalSettingsFromLink(link));
    };

    return {
      imageHeight,
      imageWidth,
      openLinkInModal,
    };
  },
});
