// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../ui/assets/syte-search-icon.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".product-gallery[data-v-2b16f64b]{position:relative}@media (max-width:1023px){.product-gallery--sm-min-height[data-v-2b16f64b]{min-height:25rem}}@media (max-width:1023px){.product-gallery__quickshop[data-v-2b16f64b] .gallery__controls{display:none}}.product-gallery__customs-logo[data-v-2b16f64b]{height:1.4rem;padding:.5rem .5rem .5rem 0;width:4.8125rem}.product-gallery__visual-search-btn[data-v-2b16f64b]{align-items:center;background:hsla(0,0%,100%,.8);border-radius:17px;color:var(--c-secondary-variant);display:flex;font-size:var(--font-xs);justify-content:center;left:var(--spacer-default);max-width:11.25rem;min-height:2.125rem;min-width:8.125rem;padding:.375rem 0 .375rem var(--spacer-xl);position:absolute;text-align:left;top:var(--spacer-sm);z-index:1}.product-gallery__visual-search-btn--redesign-core[data-v-2b16f64b]{background:var(--c-gray-90);color:var(--c-primary-variant)}.product-gallery__visual-search-btn--customs[data-v-2b16f64b]{top:var(--spacer-2-3xl)}@media (min-width:1024px){.product-gallery__visual-search-btn[data-v-2b16f64b]{top:var(--spacer-lg)}.product-gallery__visual-search-btn--customs[data-v-2b16f64b]{top:3.75rem}}.product-gallery__visual-search-btn[data-v-2b16f64b]:before{background:no-repeat 50%/20px url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");content:\"\";display:inline-block;height:1.25rem;left:var(--spacer-xs);position:absolute;width:1.25rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
