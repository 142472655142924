
























import { onBeforeUnmount, defineComponent } from '@vue/composition-api';
import { useCart } from '@vf/composables';
import CustomContent from '../customContent/CustomContent.vue';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'QuickshopSizeChart',
  components: { CustomContent },
  setup() {
    const { root } = useRootInstance();
    const { quickshopSizeChart, setQuickshopSizeChart } = useCart(root);
    const DEFAULT_IMAGE_HEIGHT = 400;
    const DEFAULT_IMAGE_WIDTH = 600;

    onBeforeUnmount(() => {
      setQuickshopSizeChart(null);
    });

    return {
      quickshopSizeChart,
      setQuickshopSizeChart,
      DEFAULT_IMAGE_HEIGHT,
      DEFAULT_IMAGE_WIDTH,
    };
  },
});
