


















import { defineComponent, computed, PropType } from '@vue/composition-api';

export default defineComponent({
  name: 'StoreSplitDealers',
  props: {
    /**
     * Enable Split VFStore and Dealer
     */
    enableSplit: {
      type: Boolean,
    },
    /**
     * title for vf store
     */
    titleVfStore: {
      type: String,
      default: '$titleVfStore$',
    },
    /**
     * title for Dealer store
     */
    titleDealerStore: {
      type: String,
      default: '$titleDealerStore$',
    },
    /**
     * Array of stores
     */
    stores: {
      type: Array as PropType<
        Array<{
          enterprise_store_identifier: string | null;
          clientkey: string;
          city: string;
        }>
      >,
      default: () => [],
    },
    /**
     * function to discriminate Dealer
     */
    isDealer: {
      type: Function as PropType<(a: any) => boolean>,
      default: () => false,
    },
  },
  setup(props) {
    const renderByStoreTypes = computed(() => {
      const storeByTypes: Array<{
        title: string;
        stores: any[];
        key: string;
      }> = [];
      const vfStores = [];
      const dealerStores = [];

      if (props.enableSplit) {
        // split vfstores and dealer store
        props.stores.forEach((store) => {
          if (props.isDealer(store)) {
            dealerStores.push(store);
          } else {
            vfStores.push(store);
          }
        });

        // prepare for render in template
        vfStores.length &&
          storeByTypes.push({
            title: props.titleVfStore,
            stores: vfStores,
            key: 'vf-stores-',
          });

        dealerStores.length &&
          storeByTypes.push({
            title: props.titleDealerStore,
            stores: dealerStores,
            key: 'dealer-stores-',
          });
      } else {
        storeByTypes.push({
          title: '',
          stores: props.stores,
          key: 'stores-',
        });
      }

      return storeByTypes;
    });

    return { renderByStoreTypes };
  },
});
