import { ComponentInstance } from '../types';
import { ref } from '@vue/composition-api';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import { storeToRefs } from 'pinia';

export const useGoogleAutocomplete = (instance: ComponentInstance) => {
  const featureFlagsStore = useFeatureFlagsStore();

  const { isGoogleAutocompleteEnabled } = storeToRefs(featureFlagsStore);

  const googleAutocompleteCountries: string[] =
    instance.$getConfigValueByCurrentLocale('GOOGLE_AUTOCOMPLETE_COUNTRIES') ??
    [];

  const scriptSrc = `https://maps.googleapis.com/maps/api/js?key=${instance.$root.$env.GOOGLE_APIS_KEY}&libraries=places`;
  const googleAutocomplete = ref(null);
  const googleAutocompleteInput = ref(null);
  const googleAutocompleteListener = ref(null);
  const googleAutocompleteInputAfter = ref(null);

  const loadGoogleApisScript = () => {
    return new Promise(function (resolve, reject) {
      const s = document.createElement('script');
      s.src = scriptSrc;
      s.onload = resolve;
      s.onerror = reject;
      document.head.appendChild(s);
    });
  };

  const unloadGoogleApisScript = () => {
    return new Promise(function (resolve) {
      googleAutocompleteListener.value?.remove();
      const scripts = document.querySelectorAll('script');
      scripts.forEach((s) => {
        if (
          s.src.indexOf('googleapis.com/maps') >= 0 ||
          s.src.indexOf('maps.gstatic.com') >= 0 ||
          s.src.indexOf('earthbuilder.googleapis.com') >= 0
        ) {
          s.remove();
        }
      });
      removeGoogleAutocompleteStyles();
      window.google = {};
      resolve(true);
    });
  };

  const getOptionsConf = (countries: string[]) => {
    return {
      componentRestrictions: {
        country: countries, // This param accepts up to 5 countries
      },
      types: ['address'],
      strictBounds: false,
      fields: ['address_components'],
    };
  };

  const isValidContext = (contextName: string) => {
    const validContexts = [
      'shipping-address-form',
      'billing-address-form',
      'basic-information-form',
    ];
    return validContexts.includes(contextName);
  };

  const fillInAddress = (addressModel) => {
    let addressLine1 = '';
    let postalCode = '';
    let city = '';
    let province = '';

    const place = googleAutocomplete.value.getPlace();

    for (const component of place.address_components) {
      const componentType = component.types[0];

      switch (componentType) {
        case 'street_number': {
          addressLine1 = `${component.long_name} ${addressLine1}`;
          break;
        }

        case 'route': {
          addressLine1 += component.short_name;
          break;
        }

        case 'postal_code': {
          postalCode = `${component.long_name}${postalCode}`;
          break;
        }

        case 'postal_code_suffix': {
          postalCode = `${postalCode}-${component.long_name}`;
          break;
        }

        case 'locality': {
          city = component.long_name;
          break;
        }

        case 'administrative_area_level_1': {
          province = component.short_name;
          break;
        }
      }
    }
    addressModel.addressLine1 = addressLine1;
    addressModel.postalCode = postalCode;
    addressModel.city = city;
    addressModel.province = province;

    googleAutocompleteInputAfter.value.focus();
  };

  // Removes all divs and styles added by Google on loading lib
  const removeGoogleAutocompleteStyles = () => {
    const pacContainerClassItems = document.querySelectorAll('.pac-container');
    pacContainerClassItems.forEach((s) => {
      s.remove();
    });

    const pacStyles = document.querySelectorAll('style');
    pacStyles.forEach((s) => {
      if (s.innerHTML.includes('.pac-container')) {
        s.remove();
      }
    });
  };

  const getInputbyId = (inputId: string) => {
    return document.getElementById(inputId);
  };

  const setupGoogleAutocomplete = (
    input: string,
    inputAfter: string,
    addressModel,
    countries: string[],
    onAddressFill?: () => void
  ) => {
    if (countries.length <= 5) {
      googleAutocompleteInput.value = getInputbyId(input);
      googleAutocompleteInputAfter.value = getInputbyId(inputAfter);
      googleAutocomplete.value = new window.google.maps.places.Autocomplete(
        googleAutocompleteInput.value,
        getOptionsConf(countries)
      );
      googleAutocompleteListener.value = googleAutocomplete.value.addListener(
        'place_changed',
        () => {
          fillInAddress(addressModel);
          onAddressFill && onAddressFill();
        }
      );
    } else {
      throw 'Max 5 countries are allowed in Google Autocomplete restrictions.';
    }
  };

  return {
    scriptSrc,
    loadGoogleApisScript,
    unloadGoogleApisScript,
    getOptionsConf,
    isValidContext,
    fillInAddress,
    setupGoogleAutocomplete,
    isGoogleAutocompleteEnabled,
    googleAutocompleteCountries,
  };
};

export default useGoogleAutocomplete;
