









import type { PropType } from 'vue';
import { defineComponent } from '@vue/composition-api';
import type { ProductRatingTranslations } from '@vf/api-contract';
import ThemeProductRating from '../../pdp/ProductRating.vue';

export default defineComponent({
  name: 'ProductRating',
  components: { ThemeProductRating },
  props: {
    translations: {
      type: Object as PropType<ProductRatingTranslations>,
      required: true,
    },
    /** Display percentage of positive reviews */
    showReviewRatio: {
      type: Boolean,
      default: false,
    },
    /** Select with page type */
    contextKey: {
      type: String,
      default: 'product',
    },
    /** Will show a skeleton loading animation while it's true */
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
});
