



























































import type { PropType } from 'vue';
import { ref, reactive, defineComponent } from '@vue/composition-api';
import { useProduct } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import type { BenefitItem } from '@vf/api-contract';

export default defineComponent({
  name: 'ProductBenefitRating',
  props: {
    /** Title of benefits section */
    title: {
      type: String,
      default: 'Benefit Rating',
    },
    /** List of all benefit ratings from CMS */
    benefitItems: {
      type: Array as PropType<BenefitItem[]>,
      default: () => [],
    },
    /** Select with page type */
    contextKey: {
      type: String,
      default: 'product',
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { product } = useProduct(root, props.contextKey);
    const activeBenefit = ref(null);
    const headingStyles = {
      titleFontWeight: 400,
    };

    const benefitsList = reactive([]);

    props.benefitItems.forEach((item) => {
      if (product.value && product.value[item.benefitAttributeName]) {
        const benefitVariationsArr = product.value[
          item.benefitAttributeName
        ].split('|');
        if (+benefitVariationsArr[0]) {
          benefitsList.push({
            title: item.title,
            value: benefitVariationsArr.shift(),
            variations: benefitVariationsArr,
          });
        }
      }
    });
    benefitsList.splice(4);

    return {
      product,
      headingStyles,
      benefitsList,
      activeBenefit,
    };
  },
});
