import { Ref } from '@vue/composition-api';

type CloseCustomsVueComponentsIframeConfig = {
  contentLoaded: Ref<boolean>;
};

const closeIframe = ({
  contentLoaded,
}: CloseCustomsVueComponentsIframeConfig): void => {
  const frame = document.querySelector('iframe[isCustomsIframe="true"]');
  const customsPage = document.querySelector('.customs-shoe-wrapper');

  if (frame && contentLoaded.value) {
    frame.classList.add('hidden');

    if (customsPage) {
      customsPage.classList.remove('opened');
    }
  }
};

export default closeIframe;
