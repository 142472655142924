














































import {
  defineComponent,
  reactive,
  onMounted,
  ref,
  PropType,
  computed,
} from '@vue/composition-api';
import { PlpVisualCarouselFilter } from '@vf/api-contract';
import useRootInstance from '@/shared/useRootInstance';
import { useCategory } from '@vf/composables';
import VfPlpVisualFilterTile from '@vf/ui/components/Molecule.PlpVisualFilterTile.vue';

export default defineComponent({
  name: 'PlpVisualFilter',
  components: { VfPlpVisualFilterTile },
  props: {
    filters: {
      type: Array as PropType<PlpVisualCarouselFilter[]>,
      default: () => [],
    },
    enablePagination: Boolean,
  },
  setup(props) {
    const { root } = useRootInstance();
    const {
      selectedFilters,
      removeFilter,
      selectFilter,
      resetFilters,
      resetAppliedFilters,
    } = useCategory(root, 'page-content');
    const selected = reactive({ code: null, value: null });
    const showPagination = ref(false);
    const showArrows = ref(false);

    const toggleFilter = (filter) => {
      if (isSelected(filter)) {
        removeFilter(selected);
        selected.code = null;
        selected.value = null;
      } else {
        selected.code = filter.code;
        selected.value = filter.value;
        resetAppliedFilters();
        resetFilters();
        selectFilter(selected);
      }
    };

    const isSelected = (filter) =>
      selected.code === filter.code &&
      selected.value === filter.value &&
      selectedFilters.value.length === 1 &&
      selected.code === selectedFilters.value[0].code &&
      selected.value === selectedFilters.value[0].value;

    const hasSelection = computed(() => isSelected(selected));

    onMounted(() => {
      if (props.enablePagination) {
        showPagination.value =
          root.$viewport.isSmall || props.filters.length > 3;
      }
      showArrows.value = !root.$viewport.isSmall && props.filters.length > 3;
    });

    return {
      isSelected,
      showPagination,
      showArrows,
      toggleFilter,
      hasSelection,
    };
  },
});
