























import { defineComponent } from '@vue/composition-api';
import { PropType } from 'vue';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';
import useRootInstance from '@/shared/useRootInstance';
import { DataProtectedInfo } from '@/helpers/dataProtected';

export default defineComponent({
  name: 'OrderSummaryInfo',
  serverCacheKey: getCacheKeyFromProps,
  props: {
    heading: {
      type: String,
      default: '',
    },
    addressLines: {
      type: Array as PropType<DataProtectedInfo[]>,
      default: () => [],
    },
    html: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { root } = useRootInstance();
    const titleModifier =
      root.$themeConfig?.orderSummaryInfo?.titleModifier || 'alt-title-6';
    return {
      titleModifier,
    };
  },
});
