



















































import { defineComponent, computed } from '@nuxtjs/composition-api';
import useRootInstance from '@/shared/useRootInstance';

import AddressPreview from '@/components/static/AddressPreview.vue';

export default defineComponent({
  name: 'AddressConfirmationModal',
  components: {
    AddressPreview,
  },
  props: {
    resolveModal: Function,
    visible: Boolean,
    currentAddress: Object,
  },
  setup() {
    const { root } = useRootInstance();
    const wrapperComponent = computed(() =>
      root.$viewport.breakpoint.current === 'smDown' ? 'VfPanel' : 'VfModal'
    );

    return {
      wrapperComponent,
    };
  },
});
