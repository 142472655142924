



















































































































import type { PropType } from 'vue';
import { defineComponent, onMounted, ref } from '@vue/composition-api';
import type { OrderDetailsSidebarTranslations } from '@vf/api-contract';
import type { ReturnPreviewObject } from '@vf/api-client';
import { useReturns } from '@vf/composables';
import { ReturnStepsType } from '@vf/composables/src/useReturns';
import useRootInstance from '@/shared/useRootInstance';
import OrderSidebarProperty from './OrderSidebarProperty.vue';

export default defineComponent({
  name: 'OrderReturnsProperties',
  components: { OrderSidebarProperty },
  props: {
    translations: {
      type: Object as PropType<OrderDetailsSidebarTranslations>,
      required: true,
    },
    returnStep: {
      type: String as PropType<ReturnStepsType>,
      default: ReturnStepsType.EMPTY,
    },
    returnOrder: {
      type: Object as PropType<ReturnPreviewObject>,
      default: () => ({} as ReturnPreviewObject),
    },
    areReturnsEnabled: Boolean,
    isNarvarEnabled: Boolean,
  },
  setup(props) {
    const { root } = useRootInstance();
    const formatPrice = (amount) => {
      const returnItem = (props.returnOrder
        ?.orderLines as ReturnPreviewObject['items'])?.[0];
      return root.$formatPrice(amount, returnItem?.totals?.currency);
    };

    const {
      getReturnsConfig,
      returnFee,
      printReturnLabel,
      printPackingSlip,
    } = useReturns(root);

    const showReturnLabel = ref(false);
    const showPrintReturnLabel = ref(false);
    const showPrintPackingSlip = ref(false);

    onMounted(async () => {
      if (props.isNarvarEnabled) {
        showReturnLabel.value = !!props.returnOrder?.extnReturnLabel;
        return;
      }

      const { printReturnLabel, printPackingSlip } = getReturnsConfig();
      showPrintReturnLabel.value = printReturnLabel;
      showPrintPackingSlip.value = printPackingSlip;
    });

    return {
      formatPrice,
      ReturnStepsType,
      returnFee,
      showReturnLabel,
      showPrintReturnLabel,
      showPrintPackingSlip,
      printReturnLabel,
      printPackingSlip,
    };
  },
});
