
















































































import { defineComponent } from '@vue/composition-api';
import { lazyImage } from '@vf/shared/src/utils/directives';
import { useGtm } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';

type TeaserStyleObject = {
  titleColor: string;
  titleFontSize: string;
  titleFontFamily: string;
  titleFontWeight: string;
};

export default defineComponent({
  name: 'HeroItem',
  directives: { lazyImage },
  props: {
    hero: {
      type: Object,
      default: () => ({}),
    },
    slideIndex: {
      type: Number,
      default: 0,
    },
    slideHeight: {
      type: Number,
      default: null,
    },
    noCrop: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { root } = useRootInstance();
    const customTitleStyles = (hero): TeaserStyleObject => {
      return {
        titleColor: hero.titleColor,
        titleFontSize: hero.titleFontSize,
        titleFontFamily: hero.titleFontFamily,
        titleFontWeight: hero.titleFontWeight,
      };
    };
    const getMainImage = (image) => {
      if (root.$viewport && root.$viewport.size) {
        switch (root.$viewport.size) {
          case 'small':
            return image.small || image.medium || image.large || image || '';
          case 'medium':
            return image.medium || image.small || image.large || image || '';
          case 'large':
            return image.large || image.medium || image.small || image || '';
          default:
            return image.small || image.medium || image.large || image || '';
        }
      }
      return image.small || image.medium || image.large || image || '';
    };
    const isFirstHero = (index) => {
      return index === 0;
    };

    const { dispatchVideoEvent } = useGtm(root);

    return {
      customTitleStyles,
      getMainImage,
      isFirstHero,
      dispatchVideoEvent,
    };
  },
});
