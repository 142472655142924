import {
  ComponentInstance,
  useNotification,
  useAuthentication,
} from '@vf/composables';
import { watch, nextTick } from '@vue/composition-api';
import { sendToCustoms } from './index';
import { useUserStore } from '@vf/composables/src/store/user';
import { storeToRefs } from 'pinia';

type CustomsOpenSignInToBuyConfig = {
  openModal: any;
  isModalOpen?: any;
  loyaltyEnrollment?: any;
};

const openSignInToBuy = async (
  instance: ComponentInstance,
  { openModal, isModalOpen, loyaltyEnrollment }: CustomsOpenSignInToBuyConfig
): Promise<void> => {
  try {
    const userStore = useUserStore(instance);
    const { loyaltyEnrolled } = storeToRefs(userStore);
    const { clearNotifications } = useNotification(instance);
    const { setPendingActionForLoyaltyUser } = useAuthentication(instance);

    clearNotifications();

    setPendingActionForLoyaltyUser(async () => {
      if (loyaltyEnrolled.value) {
        sendToCustoms(
          instance,
          {
            command: 'customsAddToCartSignInToBuy',
            body: {
              status: 'success',
              message: `Successfully Signed in and ready to add product(s) to cart`,
              userLoggedIn: true,
            },
          },
          { componentIntegration: true }
        );
      }
    });
    const unwatchIsModalOpen = watch(isModalOpen, () => {
      if (!isModalOpen.value) {
        unwatchIsModalOpen();
        setPendingActionForLoyaltyUser(null);
      }
    });
    await nextTick();
    openModal({
      type: 'lazyFragment',
      resourceId: loyaltyEnrollment,
    });
  } catch (error) {
    sendToCustoms(
      instance,
      {
        command: 'customsAddToCartSignInToBuy',
        body: {
          status: 'error',
          message: `Error in adding to cart with ${error}`,
          userLoggedIn: false,
        },
      },
      { componentIntegration: true }
    );
  }
};

export default openSignInToBuy;
