

















import type { PropType } from 'vue';
import { computed, defineComponent } from '@vue/composition-api';
import type { AddressFormTranslations } from '@vf/api-contract';
import { useAccount, useI18n, ROUTES } from '@vf/composables';
import AddressForm from '../shared/AddressForm.vue';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'ShippingAddressForm',
  components: {
    AddressForm,
  },
  props: {
    /** Name of the context where component should be displayed */
    contextName: {
      type: String,
      default: 'shipping-address-form',
    },
    translations: {
      type: Object as PropType<AddressFormTranslations>,
      default: () => ({}),
    },
    /** Prop to decide whether show heading or not */
    showHeading: {
      type: Boolean,
      default: true,
    },
    /** Prop to decide whether show back buttons or not */
    showBackButton: {
      type: Boolean,
      default: true,
    },
    /** Prop to decide whether show action buttons or not */
    showActionButtons: {
      type: Boolean,
      default: true,
    },
    /** Prop to decide whether show email helper text or not */
    showEmailHelperText: {
      type: Boolean,
      default: true,
    },
    /** Prop to decide whether show phone helper text or not */
    showPhoneHelperText: {
      type: Boolean,
      default: true,
    },
    /** Prop to decide whether show country select or not */
    showCountry: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { root } = useRootInstance();

    const {
      addAddress,
      updateAddress,
      getAddresses,
      addressOperationMessage,
      isAddAddressRequestPending,
      customerEmail,
    } = useAccount(root);
    const { localePath } = useI18n(root);

    const showEditHeading = computed(() => !!root.$route.query.id);

    const translationsData = computed(() => ({
      ...props.translations,
      addHeading: props.translations.shipping.addHeading,
      editHeading: props.translations.shipping.editHeading,
      addedMessage: props.translations.shipping.addedMessage,
      modifiedAddress: props.translations.shipping.modifiedAddress,
    }));

    const centerFormHeading = computed(
      () =>
        root.$themeConfig?.ShippingAddressForm?.formHeadingCenterTitles || false
    );

    const addressData = computed(() =>
      root.$route.query.id
        ? getAddresses('S').value.find(
            (item) => item.id === root.$route.query.id
          )
        : {
            addressLine1: null,
            addressLine2: null,
            approachType: 'S',
            city: null,
            country: root
              .$getEnvValueByCurrentLocale<string>('COUNTRY')
              .toUpperCase(),
            firstName: null,
            lastName: null,
            main: getAddresses('S').value.length === 0,
            phone: null,
            postalCode: null,
            province: null,
            recipientContactEmail: null,
            recipientContactPhone: null,
            recipientFirstName: null,
            recipientLastName: null,
          }
    );

    const handleSubmit = async (address) => {
      const id = root.$route.query.id;
      isAddAddressRequestPending.value = true;
      if (id) {
        const result = await updateAddress(id, {
          ...address,
          approachType: 'S',
        });
        if (!result) return;
        addressOperationMessage.value = {
          message: props.translations.shipping.modifiedAddress,
          type: 'success',
        };
        root.$router.push(localePath(ROUTES.ADDRESS_BOOK()));
        isAddAddressRequestPending.value = false;
      } else {
        if (!address.email && customerEmail.value) {
          address.email = customerEmail.value;
        }
        const result = await addAddress({ ...address, apporachType: 'S' });
        if (!result) return;
        root.$router.push(localePath(ROUTES.ADDRESS_BOOK()));
        addressOperationMessage.value = {
          message: props.translations.shipping.addedMessage,
          type: 'success',
        };
        isAddAddressRequestPending.value = false;
      }
    };

    const handleCancel = () => {
      root.$router.push(localePath(ROUTES.ADDRESS_BOOK()));
    };

    return {
      handleSubmit,
      handleCancel,
      centerFormHeading,
      showEditHeading,
      addressData,
      translationsData,
    };
  },
});
