var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'vf-customs-content-slide': true,
    'vf-customs-content-slide--full-height': _vm.isFullHeight,
  },attrs:{"role":"group","aria-roledescription":_vm.carouselSlideDescription},on:{"click":_vm.slideClickHandler}},[_c('div',{staticClass:"vf-customs-content-slide__top"},[(_vm.headerImage || _vm.headerTitle)?_c('div',{staticClass:"vf-customs-content-slide__header"},[(_vm.headerImage.small || _vm.headerImage.medium || _vm.headerImage.large)?_c('img',{directives:[{name:"lazy-image",rawName:"v-lazy-image",value:(_vm.getMainImage(_vm.headerImage)),expression:"getMainImage(headerImage)"}],staticClass:"vf-customs-content-slide__header-image",attrs:{"alt":(_vm.headerTitle + " " + _vm.headerSubtitle)},on:{"load":_vm.dispatchHtmlEvent}}):_vm._e(),_vm._v(" "),(_vm.headerTitle)?_c(_vm.headerTitleComponent,{tag:"component",staticClass:"vf-customs-content-slide__header-title"},[_vm._v("\n        "+_vm._s(_vm.headerTitle)+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.headerSubtitle)?_c(_vm.headerTitleComponent,{tag:"component",staticClass:"vf-customs-content-slide__header-subtitle"},[_vm._v("\n        "+_vm._s(_vm.headerSubtitle)+"\n      ")]):_vm._e()],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"vf-customs-content-slide__media",class:{
        'vf-customs-content-slide__media--video': _vm.video,
      }},[(_vm.video)?_c('VfVideo',{staticClass:"vf-customs-content-slide__video",attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id'], 'videos[0]'),"video":_vm.video,"yt-aspect16-to9":true},on:{"load":_vm.dispatchHtmlEvent}}):_c('picture',{staticClass:"vf-customs-content-slide__picture",class:{
          'vf-customs-content-slide__picture--full-height': _vm.isFullHeight,
        },attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.id, 'pictures[0]'),"itemscope":"","itemtype":"https://schema.org/ImageObject"}},[_c('meta',{attrs:{"itemprop":"datePublished","content":_vm.image.seo.date}}),_vm._v(" "),_c('meta',{attrs:{"itemprop":"description","content":_vm.image.seo.description}}),_vm._v(" "),_c('img',{directives:[{name:"lazy-image",rawName:"v-lazy-image",value:(_vm.getMainImage(_vm.image)),expression:"getMainImage(image)"}],ref:"image",staticClass:"vf-customs-product-slide__media--image",attrs:{"alt":_vm.image.seo.title},on:{"load":_vm.dispatchHtmlEvent}})]),_vm._v(" "),(_vm.ctaType === 'slot')?_c('div',{staticClass:"vf-customs-content-slide__cta-container"},[_vm._t("default")],2):_vm._e(),_vm._v(" "),(_vm.ctaType === 'link')?_c('div',{staticClass:"vf-customs-content-slide__cta-container"},[_c('ThemeButton',{class:[
            'vf-customs-content-slide__cta',
            ("vf-customs-content-slide__cta--" + _vm.ctaButtonPosition) ],attrs:{"to":_vm.ctaButtonLink,"color":"primary","size":"md","data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id'], 'resourceBundle')}},[_vm._v("\n          "+_vm._s(_vm.ctaButtonText)+"\n        ")])],1):_vm._e()],1)]),_vm._v(" "),_c('div',{staticClass:"vf-customs-content-slide__bottom"},[(_vm.text)?_c('VfText',{staticClass:"vf-customs-content-slide__text",attrs:{"html":_vm.useRichText,"content":_vm.text}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }