














import { computed, defineComponent, PropType } from '@vue/composition-api';
import useSearch from '@vf/composables/src/useSearch';
import PageTitle from '../shared/PageTitle.vue';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'ThematicCategoryTitle',
  components: { PageTitle },
  props: {
    /** Title heading level 1-6 */
    level: {
      type: Number as PropType<number>,
      default: () => 1,
    },
  },
  setup() {
    const { root } = useRootInstance();
    const { meta, headerData } = useSearch(root);

    const title = computed(() => {
      try {
        const [firstPart] = meta.value.title.content.split('|');

        return firstPart.trim();
      } catch {
        return '';
      }
    });

    return {
      title,
      headerData,
    };
  },
});
