




import { defineComponent } from '@nuxtjs/composition-api';
import PageDescription from '@/components/smart/shared/PageDescription.vue';
import useSearch from '@vf/composables/src/useSearch';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'ThematicCategoryDescription',
  components: {
    PageDescription,
  },
  setup() {
    const { root } = useRootInstance();
    const { headerData } = useSearch(root);

    return {
      headerData,
    };
  },
});
