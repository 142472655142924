//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'PromoCodeWithSingleValue',
  props: {
    translations: {
      type: Object,
      default: () => ({}),
    },
    code: String,
    isApplied: Boolean,
    promoCodeInvalidMessage: String,
  },
};
