import { ComponentInstance } from '@vf/composables';

type HandleModelChangeEventBody = {
  newModel: string;
  prevModel: string;
};

type HandleModelChangeEvent = HandleModelChangeEventBody & {
  body?: HandleModelChangeEvent;
};

const handleModelChange = (
  instance: ComponentInstance,
  event: HandleModelChangeEvent
): void => {
  const { newModel } = event.body || event || {};

  if (newModel) {
    const newUrl = instance.$route.path.replace(
      /customizer\.[^.]+\.html/,
      `customizer.${newModel}.html`
    );

    window.history.replaceState({}, '', newUrl);
  }
};

export default handleModelChange;
