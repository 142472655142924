





















































import { PropType } from 'vue';
import {
  defineComponent,
  ref,
  onMounted,
  computed,
} from '@vue/composition-api';
import { useOrders, useReturns } from '@vf/composables';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import { ReturnStepsTranslations } from '@vf/api-contract';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';
import RichText from '../common/RichText.vue';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'ReturnSteps',
  serverCacheKey: getCacheKeyFromProps,
  components: { RichText },
  props: {
    translations: {
      type: Object as PropType<ReturnStepsTranslations>,
      required: true,
    },
    topPart: String,
  },
  setup(props) {
    const { root } = useRootInstance();
    const { order } = useOrders(root);
    const {
      getReturnsConfig,
      printPackingSlip,
      printReturnLabel,
      returnFee,
      returnStep,
      setShippingMethod,
    } = useReturns(root);
    const { areNarvarReturnsEnabled } = useFeatureFlagsStore();

    const showPrintReturnLabel = ref(false);
    const showPrintPackingSlip = ref(false);

    onMounted(async () => {
      if (areNarvarReturnsEnabled) return;
      const { printReturnLabel, printPackingSlip } = getReturnsConfig();
      showPrintReturnLabel.value = printReturnLabel;
      showPrintPackingSlip.value = printPackingSlip;
    });

    const refundMethods = computed(() => {
      if (!showPrintReturnLabel.value && !showPrintPackingSlip.value) return [];

      const key = returnFee.value > 0 ? 'paid' : 'free';
      const method = {
        key,
        label: props.translations.radioOptions[key],
        tooltip: props.translations.radioOptionsTooltips[key],
      };
      if (key === 'free') {
        return [method];
      }

      const price = root.$formatPrice(
        returnFee.value,
        order.value?.totals.currency
      );
      method.label = method.label.replace('{{price}}', price);
      method.tooltip = method.tooltip.replace(
        new RegExp('{{price}}', 'g'),
        price
      );
      return [method];
    });

    return {
      returnStep,
      refundMethods,
      showPrintPackingSlip,
      showPrintReturnLabel,
      printPackingSlip,
      printReturnLabel,
      setShippingMethod,
    };
  },
});
