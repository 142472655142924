







import { defineComponent } from '@vue/composition-api';
import WriteReviewWidget from '@/components/WriteReviewWidget.vue';

export default defineComponent({
  name: 'ProductReviewForm',
  components: {
    WriteReviewWidget,
  },
});
