import {
  ComponentInstance,
  useAuthentication,
  useCart,
  useGtm,
} from '@vf/composables';
import { checkSignInToBuy } from '@vf/shared/src/utils/helpers';
import { Product } from '@vf/api-client';
import useModal from '../../../../shared/useModal';
import { watch } from '@vue/composition-api';
import { getEventFromTemplate } from '@vf/composables/src/useGtm/helpers';
import { getAddToCartOverrideAttributes } from '@vf/composables/src/useGtm/eventPropsHandlers/helpers';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@vf/composables/src/store/user';
import { useSetMiniCartWithDelay } from '@/components/product/quickShopUtils';

type UseSignInToBuyReturn = {
  getSignInToBuyState: (
    product: Product,
    isCustomerAllowed: boolean
  ) => boolean;
  startSignInToBuyFlow: (resourceId: string, product: Product) => void;
  afterAddToCart: (product: Product) => void;
  onSignInToBuy: (product: Product) => void;
};
const useSignInToBuy = (
  instance: ComponentInstance,
  contextKey?: string
): UseSignInToBuyReturn => {
  const userStore = useUserStore(instance);
  const { loyaltyEnrolled } = storeToRefs(userStore);
  const { openModal, isModalOpen } = useModal();
  const { setPendingActionForLoyaltyUser } = useAuthentication(instance);
  const { addItem } = useCart(instance);
  const { dispatchEvent } = useGtm(instance);
  const setMiniCartWithDelay = useSetMiniCartWithDelay();

  const getSignInToBuyState = (product: Product, isCustomerAllowed: boolean) =>
    checkSignInToBuy(
      instance.$root.$themeConfig.productAddToCart.isSignInToBuyAtVariantLevel,
      product,
      isCustomerAllowed
    );

  const startSignInToBuyFlow = (resourceId: string, product: Product) => {
    openModal({
      type: 'lazyFragment',
      resourceId,
    });
    onSignInToBuy(product);
  };

  const afterAddToCart = (payloadToAddToCart) => {
    dispatchEvent({
      ...getEventFromTemplate('cart:add', {}),
      composablesContexts: { useProduct: contextKey },
      overrideAttributes: getAddToCartOverrideAttributes(
        payloadToAddToCart,
        instance.$themeConfig
      ),
    });
    dispatchEvent(getEventFromTemplate('cart:update', {}));
    setMiniCartWithDelay();
  };

  const onSignInToBuy = (payloadToAddToCart) => {
    setPendingActionForLoyaltyUser(async () => {
      if (loyaltyEnrolled.value) {
        const isAddedToCart = await addItem(payloadToAddToCart);
        isAddedToCart && afterAddToCart(payloadToAddToCart);
      }
    });

    const unwatchIsModalOpen = watch(isModalOpen, () => {
      unwatchIsModalOpen();
      setPendingActionForLoyaltyUser(null);
    });
  };

  return {
    getSignInToBuyState,
    startSignInToBuyFlow,
    afterAddToCart,
    onSignInToBuy,
  };
};

export default useSignInToBuy;
