































































































































import type { PropType } from 'vue';
import { storeToRefs } from 'pinia';
import { computed, defineComponent } from '@vue/composition-api';
import { CartTotals, PaymentMethodCode } from '@vf/api-client';
import type {
  AppliedPromotion,
  CheckoutSidebarTranslations,
} from '@vf/api-contract';
import { useCart, useCheckout } from '@vf/composables';
import { useDiscountStore } from '@vf/composables/src/store/discountStore';
import useRootInstance from '@/shared/useRootInstance';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
//TODO: Remove in GLOBAL15-56318
export default defineComponent({
  name: 'OrderSummaryProperties',
  props: {
    translations: {
      type: Object as PropType<CheckoutSidebarTranslations>,
      required: true,
    },
    appliedPromotions: {
      type: Array as PropType<AppliedPromotion[]>,
      default: () => [],
    },
    totals: {
      type: Object as PropType<CartTotals>,
      required: true,
    },
    dividersConfig: {
      type: Object as PropType<{ taxTop: boolean; taxBottom: boolean }>,
      default: () => ({}),
    },
    currency: String,
    totalItemsCount: Number,
    showBeforeTaxMessage: Boolean, // Show/Hide 'Before Tax' message
    isOnPaymentPage: Boolean,
    shippingProvince: String,
  },
  setup(props) {
    const { root } = useRootInstance();
    const { isPointsToCurrencyEnabled } = useFeatureFlagsStore();

    const { totalBuyInStoreFixedPrices, appliedFamilyVoucher } = storeToRefs(
      useDiscountStore()
    );

    const athleteDiscount = computed(() => {
      return (
        cart.value?.payment_instruments?.find(
          (payment) => payment.payment_method_id === PaymentMethodCode.ATHLETES
        ) || false
      );
    });

    const taxTotal = computed(() =>
      props.totals.tax === null || props.totals.tax === undefined
        ? '--'
        : root.$formatPrice(props.totals.tax, props.currency)
    );
    const enforcedShippingFeeValue = computed(
      () => props.totals.enforcedShippingFee
    );
    const enforcedShippingFee = computed(() =>
      props.totals.enforcedShippingFee === null ||
      props.totals.enforcedShippingFee === undefined
        ? '--'
        : root.$formatPrice(props.totals.enforcedShippingFee, props.currency)
    );
    const enforcedShippingFeeLabels = computed(() => {
      return root.$t(
        `orderSummarySidebar.cart.enforcedShippingFee.${props.shippingProvince}`
      );
    });

    const { shippingAddress } = useCheckout(root);
    const { hasShippingItems, cart } = useCart(root);

    const orderTotal = computed(() => {
      const { remainingToPay, total } = props.totals;
      return root.$formatPrice(remainingToPay ?? total, props.currency);
    });

    const appliedVoucher = computed(
      () => (isPointsToCurrencyEnabled && appliedFamilyVoucher.value) || false
    );

    return {
      athleteDiscount,
      totalBuyInStoreFixedPrices,
      shippingAddress,
      hasShippingItems,
      taxTotal,
      enforcedShippingFeeValue,
      enforcedShippingFee,
      orderTotal,
      appliedVoucher,
      enforcedShippingFeeLabels,
    };
  },
});
