/**
 * Return Product's variants as query string or as object.
 * ?color={colorCode}&size={sizeCode}
 * {color: 'colorCode', size: 'sizeCode'}
 * @param variants
 */
export enum ProductVariantsToQueryStringReturnType {
  'queryString' = 'queryString',
  'object' = 'object',
}

export const productVariantsToQueryString = (
  variants,
  returnType = ProductVariantsToQueryStringReturnType.queryString
): string | Record<string, string> => {
  if (variants.length === 0) return '';

  const objValues = {};
  variants.forEach((variant) => {
    objValues[variant.code] = variant.id;
  });

  return returnType === ProductVariantsToQueryStringReturnType.queryString
    ? Object.keys(objValues)
        .map((key) => key + '=' + objValues[key])
        .join('&')
    : objValues;
};
