


























































































































































// TODO: clean up this smart component GLOBAL15-56318
import useRootInstance from '@/shared/useRootInstance';
import {
  computed,
  defineComponent,
  watch,
  ref,
  onMounted,
} from '@vue/composition-api';
import { validationMixin } from 'vuelidate';
import {
  email,
  required,
  requiredIf,
  maxLength,
} from 'vuelidate/lib/validators';
import {
  useCheckout,
  useValidation,
  useAccount,
  useCart,
} from '@vf/composables';
import CheckoutProductsPreviewList from '../../checkout/CheckoutProductsPreviewList.vue';
import { checkName } from '@vf/shared/src/utils/helpers';
import { useUserStore } from '@vf/composables/src/store/user';
import { storeToRefs } from 'pinia';

export default defineComponent({
  name: 'CheckoutPickupInfo',
  components: { CheckoutProductsPreviewList },
  mixins: [validationMixin],
  props: {
    translations: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const {
      hasPickupItems,
      cartShippingMethods,
      itemsForPickup,
      hasShippingItems,
    } = useCart(root);
    const {
      pickupFormAccordion,
      altPickupFormAccordion,
      setPickupAddressField,
      setPickupAddressData,
      setupInitialPickupAddress,
      wasShippingStepSkippedByUser,
      validatePickupForm,
    } = useCheckout(root);

    const { basicInformation, getBasicInformation } = useAccount(root);

    const userStore = useUserStore(root);
    const { loggedIn } = storeToRefs(userStore);

    const pickupInfoConfig = root.$themeConfig?.checkoutPickupInfo;

    const firstName = ref(null);
    const lastName = ref(null);
    const email = ref(null);
    const newsletterConsent = ref(false);
    const altFirstName = ref(null);
    const altLastName = ref(null);
    const altEmail = ref(null);

    const pickupData = computed(() => ({
      firstName,
      lastName,
      email,
      newsletterConsent,
      altFirstName,
      altLastName,
      altEmail,
    }));

    watch(
      cartShippingMethods,
      async (_, oldValue) => {
        if (!hasPickupItems.value) return;
        if (!loggedIn.value && oldValue.length) return; // apply only as initial load values

        const initialData = setupInitialPickupAddress();
        if (initialData) {
          firstName.value = initialData.firstName;
          lastName.value = initialData.lastName;
          email.value = initialData.email;
          altFirstName.value = initialData.altFirstName;
          altLastName.value = initialData.altLastName;
          altEmail.value = initialData.altEmail;
        }
        if (
          loggedIn.value &&
          [firstName.value, lastName.value, email.value].some(
            (fieldValue) => !fieldValue
          )
        ) {
          await getBasicInformation();

          firstName.value = basicInformation.value.firstName;
          lastName.value = basicInformation.value.lastName;
          email.value = basicInformation.value.email;

          setPickupAddressData({
            firstName: firstName.value,
            lastName: lastName.value,
            email: email.value,
          });
        }
      },
      { immediate: true }
    );

    const { setValidation, $v } = useValidation(root, 'PICKUP_FORM');

    const validateAndBlur = async (field) => {
      const fieldValidation = $v.value[field];
      if (!fieldValidation) return;
      fieldValidation.$touch();
      if (!fieldValidation.$error) {
        await setPickupAddressField(field, pickupData.value[field].value);
      }
    };

    const subscriptionsChangedHandler = async (field) => {
      const value = pickupData.value[field]?.value;
      await setPickupAddressField('subscriptions', { [field]: value });
    };

    const getErrorMessage = (fieldName, maxLengthCount) => {
      if ($v.value?.[fieldName]?.$invalid) {
        if (!$v.value[fieldName].maxLength) {
          return props.translations.validationMessages.default.maxLength.replace(
            '{{max}}',
            maxLengthCount
          );
        }

        if (!$v.value[fieldName].required) {
          return props.translations.validationMessages.default.required;
        }

        return props.translations.validationMessages.default.fallback;
      }
    };

    onMounted(() => {
      if (wasShippingStepSkippedByUser.value) {
        wasShippingStepSkippedByUser.value = false;
        setTimeout(validatePickupForm);
      }
    });

    return {
      firstName,
      lastName,
      email,
      newsletterConsent,
      altFirstName,
      altLastName,
      altEmail,
      pickupFormAccordion,
      altPickupFormAccordion,
      validateAndBlur,
      hasPickupItems,
      setValidation,
      wasShippingStepSkippedByUser,
      validatePickupForm,
      subscriptionsChangedHandler,
      getErrorMessage,
      itemsForPickup,
      hasShippingItems,
      wrapPickupInfoInOneAccordion:
        pickupInfoConfig?.wrapPickupInfoInOneAccordion ?? false,
      newsletterCheckboxAtBottom:
        pickupInfoConfig?.newsletterCheckboxAtBottom ?? false,
      accordionClass:
        pickupInfoConfig?.accordionClass ?? 'vf-accordion--gray-background',
      firstNameMaxLength: pickupInfoConfig?.firstNameMaxLength ?? 256,
      lastNameMaxLength: pickupInfoConfig?.lastNameMaxLength ?? 256,
      showPickingUpProducts: pickupInfoConfig?.showPickingUpProducts ?? false,
    };
  },
  mounted() {
    this.setValidation(this.$v);
  },
  methods: {
    altPersonRequired() {
      return this.altFirstName || this.altLastName || this.altEmail;
    },
  },
  validations() {
    return {
      firstName: {
        required,
        maxLength: maxLength(this.firstNameMaxLength),
        checkName: checkName(),
      },
      lastName: {
        required,
        maxLength: maxLength(this.lastNameMaxLength),
        checkName: checkName(),
      },
      email: { required, email },
      altFirstName: {
        required: requiredIf(function () {
          return this.altPersonRequired();
        }),
        maxLength: maxLength(this.firstNameMaxLength),
        checkName: checkName(),
      },
      altLastName: {
        required: requiredIf(function () {
          return this.altPersonRequired();
        }),
        maxLength: maxLength(this.lastNameMaxLength),
        checkName: checkName(),
      },
      altEmail: {
        required: requiredIf(function () {
          return this.altPersonRequired();
        }),
        email,
      },
    };
  },
});
