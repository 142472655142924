








import type { PropType } from 'vue';
import { computed, defineComponent } from '@vue/composition-api';
import type { PageTitleTranslations } from '@vf/api-contract';
import { useFavorites, useI18n, useAccount, ROUTES } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';

const PageTitle = () =>
  import(
    /* webpackChunkName: "PageTitle" */
    /* webpackMode: "lazy" */
    /* webpackPrefetch: true */
    /* webpackPreload: true */
    '../shared/PageTitle.vue'
  );

export default defineComponent({
  name: 'FavoritesTitle',
  components: { PageTitle },
  props: {
    /** Name of the context where component should be displayed */
    contextName: {
      type: String,
      default: 'favorites',
    },
    translations: {
      type: Object as PropType<PageTitleTranslations>,
      default: () => ({}),
    },
    /** Title heading level 1-6 */
    level: {
      type: Number,
      default: () => 2,
    },
    contextKey: {
      type: String,
      default: 'page-content',
    },
  },
  setup(props) {
    const { root } = useRootInstance();

    const {
      favoritesCount,
      ownerFirstName,
      sharedFavoritesCount,
      sharedOwnerFirstName,
    } = useFavorites(root);
    const { localePath } = useI18n(root);
    const { basicInformation } = useAccount(root);
    const isShared = computed(
      () => root.$route.path === localePath(ROUTES.FAVORITES())
    );
    const productCount = computed(() => {
      return isShared.value ? sharedFavoritesCount.value : favoritesCount.value;
    });
    const productsQtyLabel = computed(() => {
      return productCount.value > 1
        ? props.translations.itemsCountPlural
        : props.translations.itemsCountSingular;
    });

    const favoritesOwnerFirstName = computed(() => {
      return isShared.value
        ? sharedOwnerFirstName.value
        : ownerFirstName.value || basicInformation.value.firstName;
    });

    const title = computed(() => {
      return favoritesOwnerFirstName.value
        ? props.translations.favoritesLoggedIn.replace(
            '{{name}}',
            favoritesOwnerFirstName.value
          )
        : props.translations.favoritesLoggedOut;
    });

    return {
      productsQtyLabel,
      title,
      productCount,
      isShared,
    };
  },
});
