
















import { defineComponent, ref } from '@vue/composition-api';
import AccordionItem from '@/components/smart/accordion/AccordionItem.vue';
export default defineComponent({
  name: 'Accordion',
  components: { AccordionItem },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const lastClickedItem = ref(-1);
    const handleToggle = (index) => {
      lastClickedItem.value = index;
    };

    return {
      handleToggle,
      lastClickedItem,
    };
  },
});
