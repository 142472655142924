

























import { WithContext, LocalBusiness } from 'schema-dts';
import {
  ssrRef,
  useFetch,
  computed,
  defineComponent,
} from '@nuxtjs/composition-api';
import {
  ROUTES,
  useFindInStore,
  useI18n,
  useRequestTracker,
} from '@vf/composables';
import {
  formatHour,
  getTwentyFourHourTime,
} from '@vf/composables/src/useFindInStore/utils';
import { setPageTypeName } from '@/helpers';
import useRootInstance from '@/shared/useRootInstance';
import { capitalizeText } from '@/helpers/capitalizeText';
import StoreContent from '@/components/stores/StoreContent.vue';
import ExtendedStoreContent from '@/components/stores/ExtendedStoreContent.vue';

export default defineComponent({
  name: 'StoreMap',
  components: {
    StoreContent,
    ExtendedStoreContent,
  },
  jsonld(): WithContext<LocalBusiness> {
    return {
      '@context': 'https://schema.org',
      '@type': 'LocalBusiness',
      '@id': this.id,
      name: this.storeCollection.name,
      address: {
        '@type': 'PostalAddress',
        streetAddress: this.storeCollection.address1 || '',
        addressLocality: this.storeCollection.city || '',
        addressRegion: this.province || '',
        postalCode: this.storeCollection.postalcode || '',
        addressCountry: this.storeCollection.country || '',
      },
      containedInPlace: this.data.appData.containedIn,
      geo: {
        '@type': 'GeoCoordinates',
        latitude: this.storeCollection.latitude,
        longitude: this.storeCollection.longitude,
      },
      parentOrganization: this.$getBranchName(),
      url:
        'https://' +
        this.$getDomainName() +
        this.$storeLocatorUrlGenerator(
          '/stores',
          this.province,
          this.storeCollection.city,
          this.storeCollection.clientkey
        ),
      telephone: this.storeCollection.phone || '',
      logo: this.data.logo,
      image: this.data.image,
      // if the location file does not have the hours separated into open/close for each day, remove the below section
      openingHoursSpecification: this.openingHoursSpecification,
    };
  },
  setup() {
    const { root } = useRootInstance();
    const {
      getStore,
      getStateWithCities,
      store: storeCollection,
      state: stateCollection,
    } = useFindInStore(root);
    const { localePath } = useI18n(root);
    const { onAllDone } = useRequestTracker(root);

    const fullTitle = ssrRef('', 'full-title');
    const fullMetaTitle = ssrRef('', 'meta-title');
    const fullDescription = ssrRef('', 'full-description');
    const fullAlt = ssrRef('', 'full-alt');
    const date = new Date();
    const dayIndex = date.getDay() - 1;
    const locale = root.$i18n.locale;
    const data = root.$themeConfig.storeData[locale].store;
    const id = root.$route.params.store.toUpperCase();

    const isStoreContentExtendedVisible = data.isStoreContentExtended;

    const daysOfWeek = root.$themeConfig.storeData['en-us'].store.daysOfWeek;

    const iframeUrl = computed(() => {
      const mapIframeUrl =
        root.$env.BRANDIFY_IFRAME_URL || data.appData.iframe_url;
      return (
        mapIframeUrl +
        `?form=getlist_search&clientkey=${storeCollection.value.clientkey}`
      );
    });

    const storeCarries = computed(() => {
      const carries = [];
      const items = data.store.carries;
      const keys = Object.keys(items);
      keys.forEach((key) => {
        if (storeCollection.value[key]) {
          carries.push(items[key]);
        }
      });
      return carries;
    });

    // Building Google map link.
    const gMapUrl = computed(() => {
      // Check address2 to avoid null value.
      const address = storeCollection.value.address2
        ? `${storeCollection.value.address2} ${storeCollection.value.city}`
        : storeCollection.value.city;
      const gMapData = [
        `${data.appData.mapUrl}/${root.$getBranchName()}`,
        storeCollection.value.name,
        storeCollection.value.address1,
        address,
      ];
      const url = gMapData.join(', ');
      return url.trim();
    });

    const province = computed(() => {
      return storeCollection.value.province ?? storeCollection.value.state;
    });

    const storeImage = computed(() => {
      return storeCollection.value.enterprise_store_identifier &&
        storeCollection.value.outletstore !== '1'
        ? root.$config.imagesStorePageDefaultUrl
        : ``;
    });

    const specialHours = computed(() => {
      const arr = storeCollection.value.specialhours
        ? storeCollection.value.specialhours.split(' ')
        : [];
      const specialDays = [];
      const specialHours = [];
      arr.forEach((el, i) => {
        i % 2 === 0 ? specialDays.push(el) : specialHours.push(el);
      });
      return specialDays.map((el, i) => `${el}: ${specialHours[i]}`);
    });

    const openingHoursSpecification = computed(() =>
      daysOfWeek.reduce((result, { dayOfWeek, key }) => {
        const [opens, closes] = storeCollection.value[key]
          ?.toUpperCase()
          .split('-') || ['', ''];

        if (opens && closes) {
          const formattedOpens = formatHour(opens);
          const formattedCloses = formatHour(closes);

          const twentyFourHourOpens = getTwentyFourHourTime(formattedOpens);
          const twentyFourHourCloses = getTwentyFourHourTime(formattedCloses);

          const previousResult = result.find(
            (day) =>
              day.opens === twentyFourHourOpens &&
              day.closes === twentyFourHourCloses
          );

          if (previousResult) {
            previousResult.dayOfWeek =
              typeof previousResult.dayOfWeek === 'string'
                ? [previousResult.dayOfWeek, dayOfWeek]
                : [...previousResult.dayOfWeek, dayOfWeek];
          } else {
            result.push({
              '@type': 'OpeningHoursSpecification',
              dayOfWeek: dayOfWeek,
              opens: twentyFourHourOpens,
              closes: twentyFourHourCloses,
            });
          }
        }
        return result;
      }, [])
    );

    const setPageValues = async () => {
      await getStore({
        clientKey: id,
        city: root.$route.params.city.replace(/[-]/g, '%'),
        state: root.$route.params.state.toUpperCase(),
      });
      if (Object.values(storeCollection.value).length > 0) {
        await getStateWithCities(storeCollection.value.province, false);
        const storeName = capitalizeText(storeCollection.value.name, true);
        const cityName = capitalizeText(storeCollection.value.city, true);
        fullTitle.value =
          data.store_map_title
            ?.replace('{city}', cityName)
            .replace('{province}', province.value)
            .replace('{id}', id) || storeName;
        fullMetaTitle.value =
          data.store_meta_title
            ?.replace('{name}', storeName)
            .replace('{city}', cityName)
            .replace('{province}', province.value)
            .replace('{postalcode}', storeCollection.value.postalcode) ||
          storeName;
        fullDescription.value =
          data.store_meta_description
            ?.replace('{name}', storeName)
            .replace('{city}', cityName)
            .replace('{province}', province.value) || storeName;
        fullAlt.value =
          data.store_picture_alt
            ?.replace('{name}', storeName)
            .replace('{city}', cityName)
            .replace('{province}', province.value) || storeName;
      } else {
        const redirectPath = localePath(ROUTES.STORE_LOCATOR_BASE());

        if (!process.server) {
          root.$router.push({
            path: redirectPath,
          });
        } else {
          root.$nuxt.context.redirect(302, redirectPath, { _sr: '1' });
        }
      }
    };

    useFetch(setPageValues);

    onAllDone(() => {
      setPageTypeName('store page');
    });

    return {
      fullTitle,
      fullMetaTitle,
      fullDescription,
      fullAlt,
      iframeUrl,
      dayIndex,
      stateCollection,
      storeCollection,
      province,
      capitalizeText,
      storeCarries,
      gMapUrl,
      setPageValues,
      openingHoursSpecification,
      isStoreContentExtendedVisible,
      specialHours,
      locale,
      data,
      id,
      storeImage,
    };
  },
  head() {
    return {
      title: this.fullMetaTitle,
      meta: [
        {
          hid: 'og:type',
          name: 'og:type',
          property: 'og:type',
          content: 'business.business',
        },
        {
          hid: 'og:title',
          name: 'og:title',
          property: 'og:title',
          content: this.fullMetaTitle,
        },
        {
          hid: 'og:description',
          name: 'og:description',
          property: 'og:description',
          content: this.fullDescription,
        },
        {
          hid: 'description',
          name: 'description',
          property: 'description',
          content: this.fullDescription,
        },
        {
          property: 'og:image',
          content: this.storeImage || this.data.image || '',
        },
        {
          property: 'business:contact_data:street_address',
          content:
            this.capitalizeText(this.storeCollection.address1, true) || '',
        },
        {
          property: 'business:contact_data:locality',
          content: this.capitalizeText(this.storeCollection.city, true) || '',
        },
        {
          property: 'business:contact_data:region',
          content: this.storeCollection.province || '',
        },
        {
          property: 'business:contact_data:postal_code',
          content: this.storeCollection.postalcode || '',
        },
        {
          property: 'business:contact_data:country_name',
          content: this.storeCollection.country || '',
        },
        {
          property: 'business:contact_data:phone_number',
          content: this.storeCollection.phone || '',
        },
        {
          property: 'business:contact_data:website',
          content: 'https://' + this.$getDomainName() + this.$route.path,
        },
        {
          property: 'place:location:latitude',
          content: this.storeCollection.latitude,
        },
        {
          property: 'place:location:longitude',
          content: this.storeCollection.longitude,
        },
        {
          property: 'og:url',
          content:
            'https://' +
            this.$getDomainName() +
            this.$storeLocatorUrlGenerator(
              '/stores',
              this.storeCollection.province,
              this.storeCollection.city,
              this.storeCollection.clientkey
            ),
        },
      ],
      link: [
        {
          rel: 'canonical',
          href:
            'https://' +
            this.$getDomainName() +
            this.$storeLocatorUrlGenerator(
              '/stores',
              this.storeCollection.province,
              this.storeCollection.city,
              this.storeCollection.clientkey
            ),
        },
      ],
    };
  },
});
