























































































































































































































































import type { PropType } from 'vue';
import {
  ref,
  onMounted,
  defineComponent,
  computed,
} from '@vue/composition-api';
import type { ProductFindInStoreListRowTranslations } from '@vf/api-contract';
import type { BrandifyStoreInfo, Product } from '@vf/api-client';
import { useFindInStore, useAccount } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

export default defineComponent({
  props: {
    translations: {
      type: Object as PropType<ProductFindInStoreListRowTranslations>,
      required: true,
    },
    /** Either link should be opened in new tab in the browser or not */
    openInNewTab: {
      type: Boolean,
      default: true,
    },
    shop: {
      type: Object as PropType<BrandifyStoreInfo>,
      required: true,
    },
    product: {
      type: Object as PropType<Product>,
      required: true,
    },
    index: {
      type: Number as PropType<number>,
      required: true,
    },
    isFindInStorePage: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { root } = useRootInstance();
    const theme = root.$themeConfig.findInStore;
    // TODO: Cleanup in GLOBAL15-63799
    const { isBopisEnabled, isVansPdpRedesignEnabled } = useFeatureFlagsStore();

    const { productAvailabilities } = useFindInStore(root);
    const { favoriteStoreId, setFavoriteStoreId } = useAccount(root);

    const showDetails = ref(!props.index);
    const LOW_INVENTORY = 1;
    const geoLink = ref<string>();

    onMounted(() => {
      if (root.$viewport?.isMobileDevice) {
        geoLink.value = `geo:${props.shop.latitude},${props.shop.longitude}`;
      } else {
        geoLink.value = `http://maps.google.com/?q=${props.shop.latitude},${props.shop.longitude}`;
      }
    });

    const getProductVariants = (product: Product) => {
      let variants = [];
      if (product.size?.value)
        variants.push({
          label: props.translations.size,
          value: product.size.label,
        });

      if (product.color?.value)
        variants.push({
          label: props.translations.color,
          value: product.color.label,
        });

      return variants;
    };

    const phoneLink = `tel:${props.shop.phone}`;

    const toggleDetails = (): void => {
      showDetails.value = !showDetails.value;
      emit('trigger-slot-change');
    };

    const checkAvailability = (storeId: string): number =>
      !storeId
        ? 0
        : productAvailabilities.value.find(
            (element) => element.storeId === storeId
          )?.quantity || 0;

    const favoriteButtonClass = computed(() =>
      favoriteStoreId.value === props.shop.enterprise_store_identifier
        ? 'vf-button--tertiary'
        : ''
    );

    // To be cleaned up with GLOBAL15-63801
    const showFavoriteLabel = computed(
      () =>
        isBopisEnabled &&
        favoriteStoreId.value === props.shop.enterprise_store_identifier &&
        !isVansPdpRedesignEnabled
    );

    // To be cleaned up with GLOBAL15-63801
    const showMobileFavoriteLabel = computed(
      () =>
        isVansPdpRedesignEnabled &&
        root.$viewport.isSmall &&
        isBopisEnabled &&
        favoriteStoreId.value === props.shop.enterprise_store_identifier
    );

    const isStsAvailable =
      props.shop.sts_enabled === '1' && !props.isFindInStorePage;

    return {
      theme,
      getProductVariants,
      geoLink,
      phoneLink,
      toggleDetails,
      showDetails,
      checkAvailability,
      LOW_INVENTORY,
      favoriteStoreId,
      setFavoriteStoreId,
      favoriteButtonClass,
      isStsAvailable,
      isBopisEnabled,
      isVansPdpRedesignEnabled,
      showFavoriteLabel,
      showMobileFavoriteLabel,
    };
  },
});
