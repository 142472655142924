












import { defineComponent } from '@vue/composition-api';
import useRootInstance from '@/shared/useRootInstance';
import { useGtm } from '@vf/composables';
import { getEventFromTemplate } from '@vf/composables/src/useGtm/helpers';
import { useChat } from '@vf/ui/composables/useChat';

export default defineComponent({
  name: 'FloatingChatButton',
  props: {
    title: {
      type: String,
      required: true,
    },
    cssClass: {
      type: String,
      default: '',
    },
    backgroundColor: {
      type: String,
      default: '',
    },
    titleColor: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { root } = useRootInstance();
    const { dispatchEvent } = useGtm(root);
    const { loaded, dispatchChat: defaultDispatchChat } = useChat();

    const dispatchChat = () => {
      defaultDispatchChat();

      dispatchEvent(
        getEventFromTemplate('live-chat:open', {
          eventLabel:
            root.$getEnvValueByCurrentLocale('LIVE_CHAT_VENDOR') || '',
          buttonLocation: 'floating-side-bar',
        })
      );
    };

    return {
      loaded,
      dispatchChat,
    };
  },
});
