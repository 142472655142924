
















































































import { defineComponent, ref } from '@vue/composition-api';
import { required } from 'vuelidate/lib/validators';
import {
  useAccount,
  useNotification,
  useUtilities,
  useValidation,
} from '@vf/composables';
import { errorMessages } from '@vf/composables/src/utils/errorMessages';
import type { Values as VueI18nValues } from 'vue-i18n';

import useRootInstance from '@/shared/useRootInstance';
import SimplifiedEnrollmentHeader from './SimplifiedEnrollmentHeader.vue';

export default defineComponent({
  name: 'VfSimplifiedEnrollmentPasswordIntake',
  components: {
    SimplifiedEnrollmentHeader,
  },

  setup(props, { emit }) {
    const { root } = useRootInstance();
    const { getCountry } = useUtilities(root);
    const { setBasicInformation } = useAccount(root);
    const { setValidation, $v } = useValidation(root, 'PASSWORD_INTAKE_FORM');
    const { clearNotifications } = useNotification(root);
    const { displayErrorMessages } = errorMessages(root);

    const t = (key: string, values?: VueI18nValues) =>
      root.$t(`simplifiedEnrollment.passwordIntake.${key}`, values) as string;

    const isTermsConfirmed = ref(false);
    const isPrivacyConfirmed = ref(false);
    const isSubscribed = ref(false);
    const isLoading = ref(false);

    const termsLabel = t('termsLabel', {
      termsConditionsLink: root.localePath('/family/terms-conditions'),
      termsOfUseLink: root.localePath('/company/terms-of-use'),
    });

    const onSubmit = async () => {
      $v.value.$touch();

      if ($v.value.$invalid) {
        return;
      }

      const subscriptions = [
        {
          type: 'Newsletter',
          channel: 'EMAIL',
          optin: true,
          doubleOptin: true,
          country: getCountry().toUpperCase(),
          optinSource: root.$env.API_SOURCE,
          region: root.$env.API_REGION,
        },
      ];

      const payload = {
        isSubscribed: isSubscribed.value,
        isTermsConfirmed: true,
        enrollments: [{ type: 'Loyalty', country: getCountry() }],
        ...(isSubscribed.value ? { subscriptions } : {}),
      };

      try {
        isLoading.value = true;
        clearNotifications();
        await setBasicInformation(payload);
        emit('complete-profile-click');
      } catch (e) {
        displayErrorMessages(e);
      } finally {
        isLoading.value = false;
      }
    };

    return {
      isTermsConfirmed,
      isPrivacyConfirmed,
      isSubscribed,
      onSubmit,
      setValidation,
      isLoading,
      t,
      termsLabel,
    };
  },

  mounted() {
    this.setValidation(this.$v);
  },

  validations() {
    return {
      isTermsConfirmed: {
        required,
        sameAs: (val) => val === true,
      },
      isPrivacyConfirmed: {
        required,
        sameAs: (val) => val === true,
      },
    };
  },
});
