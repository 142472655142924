

























import { computed, defineComponent } from '@vue/composition-api';
import { useProduct } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'ProductPrice',
  props: {
    /** Type of the price to render in component */
    priceType: {
      type: String,
      default: 'sale',
      validator: (value: string) => {
        return ['sale', 'regular'].includes(value);
      },
    },
    /** Flag to determine if FE should draw a strike line on regular price when there is a promotion */
    strikeOriginalPrice: {
      type: Boolean,
      default: true,
    },
    /** Select with page type */
    contextKey: {
      type: String,
      default: 'product',
    },
    /** Will show a skeleton loading animation while it's true */
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { product, priceData } = useProduct(root, props.contextKey);

    const regularPrice = computed(() => {
      if (props.priceType === 'regular' && !priceData.value.hasSpecialPrice) {
        return root.$formatPrice(
          priceData.value.selectedVariant.currentPrice,
          priceData.value.currency
        );
      }
      return root.$formatPrice(
        priceData.value.selectedVariant.originalPrice,
        priceData.value.currency
      );
    });

    const specialPrice = computed(() =>
      root.$formatPrice(priceData.value.specialPrice, priceData.value.currency)
    );

    const isEnablePriceRange =
      root.$themeConfig?.productPrice?.isEnablePriceRange ?? false;

    const defaultPriceString = computed(() => {
      if (priceData.value.hasSpecialPrice || !isEnablePriceRange) {
        return regularPrice.value;
      }
      return '';
    });

    const showPriceRange = computed(() => priceData.value.showPriceRange);
    const hasSpecialPrice = computed(() => priceData.value.hasSpecialPrice);
    const priceRange = computed(() => priceData.value.priceRange);

    return {
      product,
      isEnablePriceRange,
      hasSpecialPrice,
      regularPrice,
      specialPrice,
      defaultPriceString,
      showPriceRange,
      priceRange,
    };
  },
});
