






import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'StockLabel',
  props: {
    green: Boolean,
    message: String,
  },
});
