













import { PropType } from 'vue';
import useRootInstance from '@/shared/useRootInstance';
import {
  ref,
  defineComponent,
  onMounted,
  onBeforeUnmount,
} from '@vue/composition-api';

type SourceType = 'eventBus' | 'window';

export default defineComponent({
  name: 'LottieLoader',
  props: {
    /** JSON string for animation*/
    animation: {
      type: String,
      default: '',
    },
    /** Minimum loading time for loading */
    minumumLoading: {
      type: Number,
      default: 1500,
    },
    /** Optional timeout for loading */
    optionalTimeout: {
      type: Number,
      default: 2000,
    },
    /** Event listeners for ending loading */
    endLoadingEvent: {
      type: Array,
      default: () => [],
    },
    /** Source for setting listeners */
    source: {
      type: String as PropType<SourceType>,
      default: '',
    },
    /** Width for svg */
    width: {
      type: Number,
      default: 300,
    },
    /** Height SVG */
    height: {
      type: Number,
      default: 500,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { customize, customsFlow } = root.$route.query || {};
    const lottieLoading = JSON.parse(props.animation);

    // hardcode iframe events. they should be added dynamically
    const customsEvents = ['closeCustomsIframe', 'customsToolkitClosed'];
    const isCustoms = customize || customsFlow;
    const endLoadingEvents = isCustoms
      ? [...props.endLoadingEvent, ...customsEvents]
      : props.endLoadingEvent;

    const source = props.source;

    const isLoaded = ref(false);

    const animationOptions = {
      animationData: lottieLoading,
    };

    const loaded = () => {
      isLoaded.value = true;
    };

    const addEvents = () => {
      endLoadingEvents.forEach((event: string) => {
        if (source === 'eventBus') {
          root.$eventBus.$on(event, loaded);
        } else if (source === 'window') {
          window.addEventListener(event, loaded);
        }
      });
    };

    const removeEvents = () => {
      endLoadingEvents.forEach((event: string) => {
        if (source === 'eventBus') {
          root.$eventBus.$off(event, loaded);
        } else if (source === 'window') {
          window.removeEventListener(event, loaded);
        }
      });
    };

    onMounted(() => {
      if (!endLoadingEvents.length) {
        setTimeout(loaded, props.minumumLoading);
      } else {
        addEvents();
      }
    });

    onBeforeUnmount(() => {
      if (!endLoadingEvents.length) {
        return;
      }
      removeEvents();
    });

    return {
      animationOptions,
      isLoaded,
    };
  },
});
