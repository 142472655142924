


























































































































import type { PropType } from 'vue';
import { ref, watch, defineComponent, computed } from '@vue/composition-api';
import type { ProductFindInStoreTranslations } from '@vf/api-contract';
import { useFindInStore, useProduct } from '@vf/composables';
import ProductFindInStoreList from '@vf/theme/components/findInStore/ProductFindInStoreList.vue';
import useRootInstance from '@/shared/useRootInstance';
import { scrollIntoView } from '@vf/shared/src/utils/helpers';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import { capitalizeText } from '@/helpers/capitalizeText';

export default defineComponent({
  name: 'ProductFindInStore',
  components: {
    ProductFindInStoreList,
  },
  props: {
    translations: {
      type: Object as PropType<ProductFindInStoreTranslations>,
      default: () => ({}),
    },
    /** Config for unit of measure */
    unitOfMeasure: {
      type: String,
      default: 'km',
    },
    contextKey: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const { root } = useRootInstance();

    // TODO: Cleanup in GLOBAL15-63799 and GLOBAL15-61059
    const {
      isVansPdpRedesignEnabled,
      isCoreRedesignEnabled,
    } = useFeatureFlagsStore();

    const postalCode = ref('');

    const postalCodeEmpty = ref(false);
    const distanceValue = ref(root.$themeConfig.findInStore.defaultDistance);
    const { searchButtonClass } = root.$themeConfig.findInStore;
    const displayUom =
      props?.unitOfMeasure?.toLowerCase() === 'km'
        ? props.translations.km
        : props.translations.miles;
    const distances = [
      {
        label: `5 ${displayUom}`,
        value: '5',
      },
      {
        label: `10 ${displayUom}`,
        value: '10',
      },
      {
        label: `15 ${displayUom}`,
        value: '15',
      },
      {
        label: `25 ${displayUom}`,
        value: '25',
      },
      {
        label: `50 ${displayUom}`,
        value: '50',
      },
      {
        label: `100 ${displayUom}`,
        value: '100',
      },
    ];
    const SELECTORS = '.find-in-store__form';
    const {
      getStoresByPostalCode,
      getStoresByGeo,
      emptyResponseError,
      resetData,
    } = useFindInStore(root);
    const { product } = useProduct(root, props.contextKey);

    const searchByPC = async () => {
      if (postalCode.value === '') {
        postalCodeEmpty.value = true;
        resetData();
        return;
      }
      await getStoresByPostalCode(
        postalCode.value,
        distanceValue.value,
        props.unitOfMeasure.toLowerCase(),
        product.value.variant.id
      );
      emit('trigger-slot-change');
      scrollIntoView(SELECTORS);
    };

    const getCurrentPosition = () => {
      if (!navigator.geolocation) return Promise.resolve(null);
      return new Promise(function (resolve, reject) {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });
    };

    const searchByGeo = async () => {
      const position = (await getCurrentPosition()) as any;
      if (!position) return;
      await getStoresByGeo(
        position.coords.latitude,
        position.coords.longitude,
        distanceValue.value,
        props.unitOfMeasure.toLowerCase(),
        product.value.variant.id
      );
      emit('trigger-slot-change');
      scrollIntoView(SELECTORS);
    };

    const setDistance = (val) => {
      distanceValue.value = val;
    };

    watch(postalCode, () => {
      if (postalCodeEmpty.value && postalCode.value !== '')
        postalCodeEmpty.value = false;
    });

    const isInQuickshopContext = computed(
      () => props.contextKey === 'quickshop'
    );

    const useLocationClass = computed(
      () => root.$themeConfig?.findInStore?.useLocationClass || ''
    );

    const findInStoreComponent = computed(
      () => root.$themeConfig?.findInStore?.findInStoreComponent || 'ThemeLink'
    );

    const findInStoreComponentSize = computed(
      () => root.$themeConfig?.findInStore?.findInStoreComponentSize || ''
    );

    const findInStoreComponentColor = computed(
      () => root.$themeConfig?.findInStore?.findInStoreComponentColor || ''
    );

    return {
      postalCode,
      distances,
      distanceValue,
      searchByPC,
      setDistance,
      postalCodeEmpty,
      searchByGeo,
      emptyResponseError,
      product,
      isInQuickshopContext,
      SELECTORS,
      searchButtonClass,
      useLocationClass,
      findInStoreComponent,
      findInStoreComponentSize,
      findInStoreComponentColor,
      isCoreRedesignEnabled,
      isVansPdpRedesignEnabled,
      capitalizeText,
    };
  },
});
