

















import type { PropType } from 'vue';
import type { MenuListItem } from '@vf/ui/types';
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'MenuList',
  props: {
    items: {
      type: Array as PropType<MenuListItem[]>,
      default: () => [],
    },
    showIcons: {
      type: Boolean,
      default: true,
    },
  },
});
