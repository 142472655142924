var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showSticky)?_c('VfSticky',{staticClass:"vf-sticky-header",class:{
    'vf-sticky-header--promobar-sticky': _vm.hasPromobarSticky,
    'vf-sticky-header--has-sticky-element': _vm.hasTriggerStickyOnElementId,
  },attrs:{"trigger-element-id":_vm.triggerStickyOnElementId,"trigger-defined-number":_vm.triggerStickyOnDefinedNumber,"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id']),"no-padding-top":"","always-top":_vm.hasTriggerStickyOnElementId}},[_c('div',{staticClass:"vf-header sticky-header",class:{
      'sticky-header--height-alt': _vm.showCustomButton,
      'vf-header--redesign-core sticky-header--redesign-core': _vm.isCoreRedesignEnabled,
    }},[_c('div',{staticClass:"vf-header__wrapper",class:[{ 'large-only': _vm.showAccordionOnSmall }]},[_c('header',{ref:"header",staticClass:"container",class:[
          ("sticky-header--" + (_vm.contentPosition.small) + "-small"),
          ("sticky-header--" + (_vm.contentPosition.medium) + "-medium"),
          ("sticky-header--" + (_vm.contentPosition.large) + "-large"),
          {
            'sticky-header--padding-alt': _vm.showCustomButton,
          } ]},[(_vm.showLogo)?_c(_vm.linkTag,_vm._b({tag:"component",staticClass:"vf-header__logo-wrapper",attrs:{"title":_vm.logo.link ? _vm.title : null,"data-cm-metadata":_vm.$previewMetaDataLocation(
              _vm.$attrs['data-id'],
              'localSettingsExpanded'
            )}},'component',_vm.bindUrlAttribute(_vm.logo.link),false),[_c('VfImage',{staticClass:"vf-header__logo",attrs:{"src":_vm.logo,"alt":_vm.translations.altText,"height":_vm.logoHeight,"width":_vm.logoWidth}})],1):_vm._e(),_vm._v(" "),(_vm.showTitle)?_c('VfHeading',{staticClass:"vf-header__title",attrs:{"data-id":_vm.$attrs['data-id'],"title":_vm.title,"title-modifier":"title-5"}}):_vm._e(),_vm._v(" "),(_vm.showNavigation)?_vm._t("actions",function(){return [(_vm.navigation && _vm.navigation.length)?_c('nav',{attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(
                _vm.$attrs['data-id'],
                'localSettingsExpanded'
              )}},[_c('ul',{staticClass:"vf-header__navigation"},_vm._l((_vm.navigation),function(subcategory){return _c('li',{key:subcategory.id,staticClass:"vf-header__navigation-block"},[_c('UiLink',{staticClass:"vf-header__navigation-item",attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(subcategory.id, 'teaserTargets'),"data-navigation":subcategory.dataNavigation,"data-dom-location":_vm.AnalyticsDataDomLocation.StickyHeader,"to":!subcategory.scrollToComponent && subcategory.link},on:{"click":function($event){return _vm.navigateTo(
                      subcategory.link,
                      subcategory.scrollToComponent
                    )}}},[_vm._v("\n                  "+_vm._s(subcategory.label)+"\n                ")]),_vm._v(" "),(_vm.separatorForLink)?_c('span',{staticClass:"vf-header__navigation-item-separator"},[_vm._v("\n                  /\n                ")]):_vm._e()],1)}),0)]):_vm._e()]}):_vm._e(),_vm._v(" "),_vm._t("buttons",function(){return [(_vm.showCustomButton)?[_c('CustomsCta',{attrs:{"experience":_vm.experience,"experience-type":_vm.experienceType,"data-object":_vm.dataObject,"icon":_vm.ctaButtons[0].icon,"button-text":_vm.ctaButtons[0].buttonText,"underline":_vm.ctaButtons[0].underline,"button-style":_vm.ctaButtons[0].buttonStyle,"button-size":_vm.ctaButtons[0].buttonSize,"icon-position":_vm.ctaButtons[0].iconPosition}})]:(_vm.customContentInfo.length)?_vm._l((_vm.customContentInfo),function(singleCustomContent,index){return _c('CustomContent',{key:((singleCustomContent.customContentFile) + "-" + index),attrs:{"custom-content-file":singleCustomContent.customContentFile,"custom-content-path":singleCustomContent.customContentPath}})}):_vm._l((_vm.ctaButtons),function(button,key){return _c('VfButton',{key:key,class:[
                button.buttonStyle ? ("vf-button--" + (button.buttonStyle)) : '',
                button.buttonSize ? ("vf-button--" + (button.buttonSize)) : '',
                button.iconPosition
                  ? ("vf-button--icon-" + (button.iconPosition))
                  : '',
                { 'sticky-header__cart-button': button.icon === 'cart' } ],attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(
                  _vm.$attrs['data-id'],
                  'localSettingsExpanded'
                ),"icon":button.icon,"link":button.link,"disabled":button.disabled}},[(_vm.counter !== null && button.icon === 'cart')?_c('span',{staticClass:"sticky-header__counter"},[_vm._v("\n                "+_vm._s('(' + _vm.counter + ')')+"\n              ")]):[_vm._v("\n                "+_vm._s(button.buttonText)+"\n              ")]],2)})]})],2)]),_vm._v(" "),(_vm.showAccordionOnSmall)?_c('VfAccordion',{staticClass:"small-only",attrs:{"header":_vm.title,"icon-type":_vm.accordionIconType,"icon-size":_vm.accordionIconSize}},[_c('VfList',_vm._l((_vm.navigation),function(subcategory){return _c('VfListItem',{key:subcategory.id},[_c('ThemeLink',{staticClass:"vf-header__navigation-item",attrs:{"to":subcategory.link,"data-cm-metadata":_vm.$previewMetaDataLocation(subcategory.id, 'teaserTargets'),"data-dom-location":_vm.AnalyticsDataDomLocation.StickyHeader},on:{"click":function($event){return _vm.navigateTo(subcategory.link, subcategory.scrollToComponent)}}},[_vm._v("\n            "+_vm._s(subcategory.label)+"\n          ")])],1)}),1)],1):_vm._e()],1)]):_c('div')}
var staticRenderFns = []

export { render, staticRenderFns }