




















































import type { PropType } from 'vue';
import { computed, defineComponent } from '@vue/composition-api';
import type { OrderDetailsSidebarTranslations } from '@vf/api-contract';
import { ROUTES, useI18n, useReturns } from '@vf/composables';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'OrderReturnsCTA',
  props: {
    translations: {
      type: Object as PropType<OrderDetailsSidebarTranslations>,
      required: true,
    },
    /**
     * Pass Order ID for Online Returns creation
     */
    returnableOrderId: String,
    /**
     * Start Return Link
     */
    startReturnUrl: String,
    /**
     * Tracking Return Link
     */
    returnTrackingUrl: String,
    /**
     * For multiple tracking urls open select return item modal
     */
    trackingModalMode: Boolean,
  },
  setup(props) {
    const { root } = useRootInstance();
    const theme = root.$themeConfig.orderReturnsCTA;

    const { localePath } = useI18n(root);
    const { resetReturn } = useReturns(root);
    const {
      areOnlineReturnsEnabled,
      areNarvarReturnsEnabled,
    } = useFeatureFlagsStore();

    const onlineReturnLink = computed(() => {
      const isVisible = !areNarvarReturnsEnabled && props.returnableOrderId;
      return (
        isVisible && localePath(ROUTES.RETURN_ORDER(props.returnableOrderId))
      );
    });

    const createOnlineReturn = () => {
      resetReturn();
      root.$router.push(onlineReturnLink.value);
    };

    return {
      areOnlineReturnsEnabled,
      areNarvarReturnsEnabled,
      theme,
      onlineReturnLink,
      createOnlineReturn,
    };
  },
});
