






































import { defineComponent } from '@vue/composition-api';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';
import RichText from '../common/RichText.vue';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'FaqCollection',
  components: {
    RichText,
  },
  serverCacheKey: getCacheKeyFromProps,
  props: {
    /** Title of the FAQ Collection */
    title: {
      type: String,
      default: '',
    },
    /** Questions in the FAQ Collection */
    questions: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const { root } = useRootInstance();
    const dividerClass = root.$themeConfig?.faqCollection?.dividerClass || '';
    const iconSize = root.$themeConfig?.faqCollection?.iconSize || '14px';

    return {
      dividerClass,
      iconSize,
    };
  },
});
