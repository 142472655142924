




























import { useFilters } from '@vf/composables';
import { defineComponent, computed } from '@vue/composition-api';
import useRootInstance from '@/shared/useRootInstance';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
export default defineComponent({
  name: 'CategoryFiltersAndGridWrapper',
  setup() {
    const { root } = useRootInstance();
    const { showFiltersBar } = useFilters(root);

    const { isVansPlpRedesignEnabled } = useFeatureFlagsStore();

    // TODO: GLOBAL15-63799 update when redesign work is done
    const filtersColumnWidth = isVansPlpRedesignEnabled
      ? {
          large: 2,
          medium: 2,
          small: 12,
          mediumMargins: { top: 'md-no-margin-t' },
          largeMargins: { top: 'lg-no-margin-t' },
        }
      : {
          large: 3,
          medium: 3,
          small: 12,
        };

    // TODO: GLOBAL15-63799 update when redesign work is done
    const productColumnWidth = computed(() =>
      isVansPlpRedesignEnabled
        ? {
            large: showFiltersBar.value ? 10 : 12,
            medium: showFiltersBar.value ? 10 : 12,
            small: 12,
            mediumMargins: { top: 'md-no-margin-t' },
            largeMargins: { top: 'lg-no-margin-t' },
          }
        : {
            large: showFiltersBar.value ? 9 : 12,
            medium: showFiltersBar.value ? 9 : 12,
            small: 12,
          }
    );

    return {
      showFiltersBar,
      filtersColumnWidth,
      productColumnWidth,
    };
  },
});
