















































import type { PropType } from 'vue';
import {
  ref,
  computed,
  defineComponent,
  onBeforeUnmount,
} from '@vue/composition-api';
import type { QuickAddToCartTranslations } from '@vf/api-contract';
import { useCart } from '@vf/composables';
import useLoader from '@/shared/useLoader';
import useRootInstance from '@/shared/useRootInstance';
import useModal from '@/shared/useModal';
import { useGtm } from '@vf/composables';

export default defineComponent({
  name: 'QuickAddToCart',
  components: {
    CustomsSizeQuantitySelector: () =>
      import('./CustomsSizeQuantitySelector.vue'),
  },
  props: {
    translations: {
      type: Object as PropType<QuickAddToCartTranslations>,
      default: () => ({}),
    },
    /** Max quantity of items to be added in modal */
    maxQuantity: { type: Number, default: 10 },
  },
  setup(props, context) {
    const { root } = useRootInstance();

    const { additionalData, setAdditionalData } = useModal();
    const { addItem, setMiniCart } = useCart(root);
    const { showSpinner, hideSpinner } = useLoader();
    const modalSelect = ref(null);
    const showError = ref(false);

    const sizeQuantitySelectors = ref([
      {
        size: null,
        qty: 0,
        model: '',
        recipeId: '',
      },
    ]);
    const { dispatchEvent } = useGtm(root);
    const config = root.$themeConfig?.carousel || false;

    const addToDataLayer = (eventLabel) => {
      if (!config.dispatchGtmEvents) {
        return;
      }
      dispatchEvent({
        eventName: 'loadEventData',
        overrideAttributes: {
          eventCategory: 'Customs',
          eventAction: 'Add Your Touch',
          eventLabel,
          nonInteractive: false,
          customMetrics: {},
          customVariables: {},
          _clear: true,
        },
      });
    };
    let title = '';
    if (additionalData.value.pdm) {
      title = additionalData.value.pdm.replaceAll('-', ' ').toUpperCase();
    }

    const updateSizeQuantitySelectors = () => {
      sizeQuantitySelectors.value = (
        ((context.refs.modalSelect as unknown) as Record<string, unknown>[]) ??
        []
      ).map((modal) => {
        return {
          ...additionalData.value,
          size: modal.size,
          qty: parseInt(modal.qty as string),
          model: additionalData.value.pdm,
          recipeId: additionalData.value.recipe_id,
          isCustoms: true,
        };
      });

      showError.value = false;
    };

    const isAddToCartEnabled = computed(
      () =>
        sizeQuantitySelectors.value.length &&
        sizeQuantitySelectors.value.every(
          (item) => item.size !== null && item.qty > 0
        )
    );

    const addToCart = () => {
      if (!isAddToCartEnabled.value) {
        modalSelect.value[0].$el.scrollIntoView({ behavior: 'smooth' });
        showError.value = true;
        return;
      }

      showSpinner();
      addItem(sizeQuantitySelectors.value, { isTemporary: false })
        .then(() => {
          setMiniCart(true, root.$viewport?.isSmall && 6000);
        })
        .finally(() => {
          hideSpinner();
          addToDataLayer(props.translations.addToCartButtonText);
        });
    };

    const formattedPrice = computed(() =>
      root.$formatPrice(
        additionalData.value.priceInDecimal,
        additionalData.value.currency
      )
    );

    onBeforeUnmount(() => {
      setAdditionalData(null);
    });

    return {
      additionalData,
      title,
      showSpinner,
      hideSpinner,
      addItem,
      setMiniCart,
      addToCart,
      updateSizeQuantitySelectors,
      isAddToCartEnabled,
      sizeQuantitySelectors,
      modalSelect,
      showError,
      formattedPrice,
    };
  },
});
