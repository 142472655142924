import { render, staticRenderFns } from "./CheckoutGiftCard.vue?vue&type=template&id=0deb67cb&scoped=true&"
import script from "./CheckoutGiftCard.vue?vue&type=script&lang=ts&"
export * from "./CheckoutGiftCard.vue?vue&type=script&lang=ts&"
import style0 from "./CheckoutGiftCard.vue?vue&type=style&index=0&id=0deb67cb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0deb67cb",
  null
  
)

export default component.exports