


















































import { computed, defineComponent, PropType } from '@vue/composition-api';
import useRootInstance from '@/shared/useRootInstance';
import { useGridConfig } from '@vf/composables';
import ESpot from './ESpot.vue';

/**
 * This compontent is used for preview purposes only.
 * it mimics `ProductGrid` with placeholders
 * ESpots are rendered via ProductGrid component.
 */
export default defineComponent({
  name: 'ESpotPreviewGrid',
  components: {
    ESpot,
  },
  props: {
    mobileWidth: Number as PropType<1 | 2>,
    tabletWidth: Number as PropType<1 | 2>,
    desktopWidth: Number as PropType<number>,
    position: Number as PropType<number>,
    component: Object,
    contextKey: String as PropType<string>,
  },
  setup(props) {
    const { root } = useRootInstance();

    const { currentConfig } = useGridConfig(root);

    const columnSizes = (width = '1') => {
      const grid = currentConfig.value.grid;
      const small = 3;
      const medium = 3;
      const large = 4;
      const parsedWidth = Number(width);

      return {
        small: (small ? 12 / small : grid.small) * parsedWidth,
        medium: (medium ? 12 / medium : grid.medium) * parsedWidth,
        large: (large ? 12 / large : grid.large) * parsedWidth,
      };
    };

    const productSlots = computed(() => {
      const slots = [];
      for (let pos = 0; pos < 12; pos++) {
        const espot = pos === props.position - 1;
        slots.push({ espot });
      }
      return slots;
    });

    return { columnSizes, productSlots };
  },
});
