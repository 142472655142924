















































import VfProfileSummaryRow from './Atom.ProfileSummaryRow.vue';
import { defineComponent, PropType } from '@vue/composition-api';
import { ProfileSummaryCardTranslations } from '@vf/api-contract';

export default defineComponent({
  name: 'VfProfileSummary',
  components: { VfProfileSummaryRow },
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    link: {
      type: String,
      default: '',
    },
    email: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    interests: {
      type: String,
      default: '',
    },
    buttonDisabled: {
      type: Boolean,
    },
    translations: {
      type: Object as PropType<ProfileSummaryCardTranslations>,
      required: true,
    },
  },
});
