var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.sortedProductsList.length),expression:"sortedProductsList.length"}],staticClass:"vf-recommendations",style:({ '--vf-product-recommendation-ratio': _vm.size.aspectRatio }),attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id'])}},[_c('VfHeading',{class:_vm.showAsCarousel ? '' : 'vf-heading__non-carousel',attrs:{"level":_vm.headingLevel,"data-id":_vm.$attrs['data-id'],"title":_vm.translations.heading,"title-modifier":"subtitle-2","text-align":_vm.textAlignSettings,"styles":_vm.stylesSettings}}),_vm._v(" "),_c(_vm.showAsCarousel ? 'UiSwiper' : 'div',_vm._b({tag:"component",class:[
      _vm.showAsCarousel ? '' : 'recommendations-container',
      _vm.isVansPdpRedesignEnabled && !_vm.showAsCarousel
        ? 'recommendations-container--redesign'
        : '',
      _vm.showMobileOnTwoRows && !_vm.isCarousel ? 'non-carousel-two-rows' : '',
      { 'carousel-quickshop-open': _vm.isQuickShopOpen } ],attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id'], 'resourceBundle')},on:{"init":_vm.initSwiper,"change":_vm.changeSwiper}},'component',
      _vm.showAsCarousel ? _vm.boundCarouselProps : { style: Object.assign({}, {gap: _vm.gap}, _vm.styles) }
    ,false),_vm._l((_vm.sortedProductsList),function(product,index){return _c('VfProductCard',{key:((product.id) + "-" + index),attrs:{"title":product.title,"image":product.images.src,"currency":product.price.currency,"regular-price":_vm.showPrice ? product.price.original : '',"special-price":_vm.showPrice ? product.price.current : '',"wishlist-icon":_vm.showAddToFavorites && typeof product.isOnWishlist === 'undefined'
          ? 'heart'
          : '',"is-on-wishlist":product.isOnWishlist,"button-text":_vm.translations.quickShop,"rating":product.rating,"link":product.link,"eyebrow":product.eyebrow,"parsed-product-id":_vm.getProductCardDataId(product),"rating-link":product.ratingLink,"show-quick-shop":_vm.showQuickShopCta,"show-quick-shop-cta":_vm.showQuickShopCta,"image-width":_vm.size.width,"image-height":_vm.size.height},on:{"add-to-cart":function($event){return _vm.openProductQuickShop(product)},"cache-plp":function($event){return _vm.clickProductTile(product)}},scopedSlots:_vm._u([{key:"quickshop",fn:function(quickshop){return [(quickshop)?_c('QuickShopTile',{attrs:{"data":Object.assign({}, quickshop.product,
            {id: product.id,
            variantId: product.id + '-' + index,
            displayPriceRange: product.displayPriceRange || false,
            priceRange: {
              base: product.price.basePriceRange,
              sale: product.price.salePriceRange,
            },
            colorCode: product.colorCode,
            currency: product.price.currency,
            presale: product.presale}),"context-key":_vm.contextKey,"actions":quickshop.actions,"experience-id":_vm.experienceId,"list-type":"Recommendation Product Carousel","open":quickshop.open,"translations":_vm.translations,"disable-quickshop-action":_vm.isSmallViewport,"disable-sign-in-to-buy":true,"modals":_vm.modals},on:{"toggle-quickshop":function($event){return _vm.openProductQuickShop(product, $event)},"show-detail":function($event){return _vm.onShowDetails(product)}}}):_vm._e()]}}],null,true)})}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }