















































import type { PropType } from 'vue';
import type { CartLineItem } from '@vf/api-client';
import { Context } from '@vf/api-contract';

import { computed, defineComponent, ref, watch } from '@nuxtjs/composition-api';
import {
  useSaveForLater,
  useNotification,
  useProduct,
  useProductInventory,
  useI18n,
} from '@vf/composables';

import { getCartProductListThemeConfig } from '@/helpers';
import { pluralize } from '@/helpers/pluralize';
import useRootInstance from '@/shared/useRootInstance';
import useLoader from '@/shared/useLoader';
import useModal from '@/shared/useModal';

import MenuList from '@/components/MenuList.vue';

export default defineComponent({
  name: 'SavedForLaterProducts',
  components: {
    MenuList,
  },
  props: {
    products: {
      type: Array as PropType<CartLineItem[]>,
      default: () => [],
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { localePath } = useI18n(root);
    const { showSpinner, hideSpinner, isSpinnerVisible } = useLoader();
    const { clearNotifications, addNotification } = useNotification(root);
    const {
      moveSaveForLaterToCart,
      updateSaveForLater,
      removeSaveForLater,
      isSaveForLaterProductToUpdate,
    } = useSaveForLater(root);
    const { ...editedProduct } = useProduct(root, Context.QuickShop);
    const { getProductInventory } = useProductInventory(
      root,
      Context.QuickShop
    );
    const { openModal, closeModal } = useModal();

    const staticCartTheme = root.$themeConfig.staticCart;
    const theme = getCartProductListThemeConfig(root.$themeConfig);
    const flyoutContainer = ref(null);
    const clickedProduct = ref(null);

    const flyoutContainerListItems = computed(() => {
      return [
        {
          text: root.$t('cartProducts.remove'),
          icon: 'close',
          handler: () => removeAction(clickedProduct.value),
        },
        {
          text: root.$t('cartProducts.moveToCart'),
          icon: 'cart',
          handler: () => moveToCartActon(clickedProduct.value),
        },
        {
          text: root.$t('cartProducts.edit'),
          icon: 'edit',
          handler: () => editProductAction(clickedProduct.value),
        },
      ];
    });

    const numberOfProductsToShow = ref(
      staticCartTheme.savedForLaterProducts.paginationCounter
    );

    const itemsText = computed(() => {
      return `${props.products.length} ${pluralize(
        root.$t('cartProducts.item') as string,
        props.products.length
      )}`;
    });

    const increaseNumberOfProductsToShow = () => {
      numberOfProductsToShow.value +=
        staticCartTheme.savedForLaterProducts.paginationCounter;
    };

    const openMenuAction = (event, product) => {
      flyoutContainer.value.show(event);
      clickedProduct.value = product;
    };

    const changeQuantityAction = async (data: {
      product: any;
      quantity?: number;
      storeId?: string;
      onFinishCall?: (status?: boolean) => void;
    }) => {
      showSpinner();
      await updateSaveForLater({
        ...data.product,
        quantity: { value: Number(data.quantity) },
      });
      hideSpinner();
      data.onFinishCall?.(true);
    };

    const removeAction = async (product: CartLineItem) => {
      flyoutContainer.value.hide();
      clearNotifications();
      showSpinner();
      await removeSaveForLater(product.itemId);
      hideSpinner();

      // TODO: GLOBAL15-56035 Resolve problem with product visible in the same time in sandard products and saved for later

      addNotification({
        message: root.$t(
          'cartProducts.removedFromSavedForLaterNotification'
        ) as string,
        type: 'info',
      });
    };

    const moveToCartActon = async (product: CartLineItem) => {
      flyoutContainer.value.hide();
      clearNotifications();
      showSpinner();
      const success = await moveSaveForLaterToCart(product);
      hideSpinner();

      if (success) {
        addNotification({
          message: root.$t('cartProducts.movedToCartNotification') as string,
          type: 'info',
        });
      }
    };

    const editProductAction = async (product) => {
      editedProduct.setOldProduct(product);
      isSaveForLaterProductToUpdate.value = true;
      // if customizer or Custom PDP product
      if (product.recipeId) {
        root.$router.push(product.pdpUrlRelative);
        return;
      }

      // open quickshop
      const defaultVariants = product.variants.reduce(
        (acc, variant) => ({
          ...acc,
          [variant.code]: variant.id,
        }),
        {}
      );

      await editedProduct.toggleQuickShop(
        product[theme.productIdPropertyName],
        defaultVariants
      );

      openModal({
        type: 'page',
        path: localePath('/quickshop-cart'),
        contextKey: Context.QuickShop,
      });

      await getProductInventory(product[theme.productIdPropertyName]);

      flyoutContainer.value.hide();
    };

    watch(
      () => editedProduct.isQuickShopOpen.value,
      (val) => {
        !val && closeModal();
      }
    );

    return {
      itemsText,
      openMenuAction,
      changeQuantityAction,
      removeAction,
      moveToCartActon,
      isSpinnerVisible,
      numberOfProductsToShow,
      increaseNumberOfProductsToShow,
      staticCartTheme,
      flyoutContainer,
      flyoutContainerListItems,
      editProductAction,
    };
  },
});
