











export default {
  name: 'ProductButtonsWrapper',
};
