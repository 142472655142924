





















import { PropType } from 'vue';
import { defineComponent, computed } from '@vue/composition-api';
import { AddressSummaryTranslations } from '@vf/api-contract';
import { useAccount } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'AddressSummaryCard',
  props: {
    translations: {
      type: Object as PropType<AddressSummaryTranslations>,
      required: true,
    },
  },
  setup() {
    const { root } = useRootInstance();
    const { basicInformation, getAddresses } = useAccount(root);
    const address = computed(() =>
      getAddresses('S').value?.find(({ main }) => main)
    );
    const loading = computed(() => basicInformation.value.firstName === '');

    const nameText = computed(() =>
      address.value
        ? `${address.value.firstName} ${address.value.lastName}`
        : ''
    );
    const phoneText = computed(() =>
      address.value ? address.value.phone : ''
    );
    const addressText = computed(() =>
      address.value
        ? `${address.value.addressLine1}${
            address.value.addressLine2 ? ` ${address.value.addressLine2}` : ''
          } ${address.value.city} ${
            address.value.state || address.value.province
          }`
        : ''
    );

    return {
      nameText,
      phoneText,
      addressText,
      loading,
    };
  },
});
