var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"empty-cart",class:{
    'empty-cart--with-share-error':
      _vm.shareCartLinkExpired || _vm.notAddedItems.length,
    'empty-cart--invisible': _vm.isCartLoading,
  },attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id'])}},[(_vm.showZeroItemsText)?_c('div',[_vm._v(_vm._s(_vm.translations.zeroItemsText))]):_vm._e(),_vm._v(" "),_c('VfHeading',{staticClass:"empty-cart__heading",class:{ 'empty-cart--invisible': !_vm.showEmptyCartInfo },attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id'], 'teaserTitle'),"title":!_vm.shareCartLinkExpired
        ? _vm.translations.heading
        : _vm.translations.shareCartExpiredHeading,"title-modifier":"title-2","level":_vm.headingLevel}}),_vm._v(" "),(_vm.translations.text)?_c('VfText',{staticClass:"empty-cart__text vf-text--center",attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id'], 'teaserText'),"content":_vm.translations.text}}):_vm._e(),_vm._v(" "),(_vm.showCtaButton)?_c('ThemeButton',{class:{ 'empty-cart--invisible': !_vm.showEmptyCartInfo },attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id'], 'teaserTargets'),"color":"primary","size":"md","to":_vm.buttonUrl}},[_vm._v("\n    "+_vm._s(!_vm.shareCartLinkExpired
        ? _vm.translations.buttonText
        : _vm.translations.shareCartExpiredButtonText)+"\n  ")]):_vm._e(),_vm._v(" "),_c('NotAddedItems',{attrs:{"translations":_vm.translations,"not-added-items":_vm.notAddedItems}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }