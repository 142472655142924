













































import {
  ref,
  PropType,
  defineComponent,
  computed,
  onMounted,
  nextTick,
} from '@vue/composition-api';
import { QuickShopTileTranslations } from '@vf/api-contract';
import {
  useCart,
  useGridConfig,
  useGtm,
  useProduct,
  useProductInventory,
  useMonetate,
  useShippingFilter,
  ROUTES,
} from '@vf/composables';
import { prepareStoreFilter } from '@vf/composables/src/utils/query';
import { getAddToCartOverrideAttributes } from '@vf/composables/src/useGtm/eventPropsHandlers/helpers';
import { getEventFromTemplate } from '@vf/composables/src/useGtm/helpers';
import useRootInstance from '@/shared/useRootInstance';
import {
  checkSelectedProductInStock,
  createErrorMessage,
  useSetMiniCartWithDelay,
  useProductVariantChange,
  useVariantPrice,
  retrievePdpColor,
  isAllVariantSelected,
  extractDefaultQuickShopData,
} from './quickShopUtils';
import { useCmsRefStore } from '@vf/composables/src/store/cmsRef';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@vf/composables/src/store/user';
import useSignInToBuy from '@/components/smart/shared/composables/useSignInToBuy';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

export default defineComponent({
  name: 'QuickShopTile',
  props: {
    data: {
      type: Object,
      required: true,
    },
    actions: {
      type: Object,
      required: true,
    },
    lastFocusedElement: {
      type: String,
    },
    contextKey: {
      type: String as PropType<string>,
      default: '',
    },
    translations: {
      type: Object as PropType<QuickShopTileTranslations>,
      required: true,
    },
    open: {
      type: Boolean,
      default: false,
    },
    searchState: {
      type: Object,
      default: null,
    },
    experienceId: {
      type: String,
      default: '',
    },
    listType: {
      /* used for GTM - to distinguish from different quick shop lists */
      type: String,
      default: '',
    },
    disableQuickshopAction: {
      type: Boolean,
      default: false,
    },
    modals: {
      type: Object,
      default: () => ({
        signInToBuy: null,
        loyaltyEnrollment: null,
      }),
    },
    ratingLink: {
      type: String,
      default: '',
    },
    rating: {
      type: [Object, Array],
      default: () => ({}),
    },
    isCustoms: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const cmsRefStore = useCmsRefStore();
    const { root } = useRootInstance();

    const quickShopContext = getQuickShopContext(
      props.data.id,
      props.data.colors || [],
      props.data.colorCode
    );

    const {
      getProductDetails,
      product,
      availableAttributes,
      configure,
      getDefaultAttributesOptionValues,
      getColorByCode,
      loading: isGetProductPending,
      attributesData,
    } = useProduct(root, quickShopContext);
    const { getSignInToBuyState, startSignInToBuyFlow } = useSignInToBuy(
      root,
      quickShopContext
    );

    const { getProductInventory, inventoryLoading } = useProductInventory(
      root,
      quickShopContext
    );

    const { colorsCountForPlp: colorsCount } = useGridConfig(root);
    const userStore = useUserStore(root);
    const { loyaltyEnrolled, loggedIn } = storeToRefs(userStore);

    const { addItem, isAddItemRequestPending, setMiniCart } = useCart(root);
    const { dispatchEvent } = useGtm(root);
    const setMiniCartWithDelay = useSetMiniCartWithDelay();
    const { isCheckoutRedesignEnabled } = useFeatureFlagsStore();

    const {
      selectedItem,
      productLink: _productLink,
      handleChange,
      quickShopData,
    } = useProductVariantChange(props, { product, configure });

    const {
      variantRegularPrice,
      hasSpecialPrice,
      variantSpecialPrice,
    } = useVariantPrice(props, selectedItem, product);

    const { extractRecommendedAction } = useMonetate(root, quickShopContext);
    const { selectedStoreId } = useShippingFilter(root);
    const { pageTypeName } = storeToRefs(cmsRefStore);

    const productLink = computed(() => {
      if (selectedStoreId.value)
        return `${_productLink.value}${
          _productLink.value.includes('?') ? '&' : '?'
        }${prepareStoreFilter(selectedStoreId.value)}`;
      return _productLink.value;
    });

    const handleShowDetails = () => {
      emit('show-detail');
      root.$router.push(productLink.value);
    };

    const status = ref('');
    const isAddToCartClicked = ref(false);
    const areAllVariantsOutOfStock = ref(false);

    const isProductInStock = computed(
      () =>
        checkSelectedProductInStock(selectedItem, product.value) &&
        !areAllVariantsOutOfStock.value
    );

    const errorMessage = computed(() =>
      isAddToCartClicked.value
        ? createErrorMessage(product.value, selectedItem, props.translations)
        : { role: '', message: '' }
    );

    const isSignInToBuy = computed(() => {
      return getSignInToBuyState(product.value, loyaltyEnrolled.value);
    });

    const buttonText = computed(() => {
      const { addToCartButtonText, outOfStockText } = props.translations;
      return !isProductInStock.value ? outOfStockText : addToCartButtonText;
    });

    const productConfig = computed(() => ({
      ...quickShopData.value,
      availableAttributes: availableAttributes.value,
    }));

    const isMonetate = computed(() => Boolean(props.experienceId));

    const validateAndAddToCart = async (): Promise<void> => {
      const elementToFocus = document.querySelector(
        `#${props.lastFocusedElement} .vf-quickshop__title`
      );
      (elementToFocus as HTMLElement)?.focus();

      isAddToCartClicked.value = true;
      if (!isProductInStock.value || errorMessage.value.message) return;

      const favStoreId = selectedStoreId.value;
      const isAddedToCart = await addItem(
        { ...product.value, ...(favStoreId && { storeId: favStoreId }) },
        {
          isTemporary: false,
          ...(favStoreId && {
            queryParamsObject: {
              action: 'pickup',
              favStoreId,
            },
          }),
        }
      );
      let overrideAttributes = getAddToCartOverrideAttributes(
        product.value,
        root.$themeConfig
      );
      if (favStoreId)
        overrideAttributes = Object.assign({}, overrideAttributes, {
          shippingMethod: 'BOPIS_PLP_SD',
        });
      if (props.experienceId) {
        const experience = extractRecommendedAction(props.experienceId);
        overrideAttributes = Object.assign({}, overrideAttributes, {
          experience,
          list_type: props.listType,
        });
      }
      if (isAddedToCart) {
        status.value = root.$t('productAddToCart.buttonCTASuccess') as string;

        await nextTick();
        dispatchEvent({
          ...getEventFromTemplate('cart:add', {}),
          composablesContexts: { useProduct: props.contextKey },
          overrideAttributes,
        });
        dispatchEvent(getEventFromTemplate('cart:update', {}));
        if (isCheckoutRedesignEnabled) {
          if (!root.$route.path.endsWith(ROUTES.CART())) {
            setMiniCart(true);
          }
        } else {
          setMiniCartWithDelay();
        }
      }
      status.value = '';
      props.actions.toggleQuickshop(false);
    };

    const openSignInToBuyModal = () => {
      isAddToCartClicked.value = true;
      if (!isProductInStock.value || errorMessage.value.message) return;

      const resourceId = !loggedIn.value
        ? props.modals.signInToBuy
        : props.modals.loyaltyEnrollment;
      startSignInToBuyFlow(resourceId, product.value);
    };

    const gridClassSuffix = ref<string>('');

    onMounted(() => {
      gridClassSuffix.value =
        sessionStorage.getItem('selectedGridConfig') ?? 'normal';

      if (root.$themeConfig?.productCard?.updateColorOnBack) {
        try {
          const color = retrievePdpColor(
            root.context.from,
            props.data.id,
            props.data.variantId
          );

          handleChange('color', color);
        } catch {
          handleChange('color', selectedItem.color);
        }
      } else {
        if (selectedItem.color) {
          handleChange('color', selectedItem.color);
        }
      }
    });

    root.$on('set-grid-config', (config) => {
      gridClassSuffix.value = config.name;
    });

    const getDefaultColorValue = () => {
      const { color } = extractDefaultQuickShopData(
        quickShopData.value.colors || product.value.colors
      );
      return color?.value || '';
    };

    const emitToggleQuickShop = ({
      isOpen,
      productConfiguration,
      ...additionalConfiguration
    }) => {
      emit('toggle-quickshop', {
        isOpen,
        contextKey: quickShopContext,
        productConfiguration,
        ...additionalConfiguration,
      });
    };

    const toggleQuickShopOptions = async (isOpen) => {
      const productConfiguration = {
        color: selectedItem.color || props.data.colorCode,
      };

      if (props.disableQuickshopAction) {
        props.actions.toggleQuickshop(false);

        emitToggleQuickShop({
          isOpen,
          productConfiguration,
        });
        return;
      }

      props.actions.toggleQuickshop(isOpen);

      if (!isOpen) {
        emitToggleQuickShop({
          isOpen,
          productConfiguration,
        });
        return;
      }

      if (product.value === null) {
        await getProductDetails(props.data.id, {
          loadImages: false,
          configuredColor: selectedItem.color,
        });
        await getProductInventory(props.data.id);
      }

      const isColorInProductOptions = getColorByCode(
        productConfiguration.color
      );

      if (!isColorInProductOptions) {
        productConfiguration.color = getDefaultColorValue();
      }

      if (!selectedItem.color && product.value?.colors?.length) {
        handleChange('color', productConfiguration.color);
      }

      configure(productConfiguration);
      const defaultValues = getDefaultAttributesOptionValues();
      Object.entries(defaultValues).forEach(([key, val]) => {
        if (val) handleChange(key.replace('Value', ''), val);
      });

      checkIfAllVariantsAreOOS();

      emitToggleQuickShop({
        isOpen,
        productConfiguration,
      });
    };

    const addToCartButtonDisabled = computed(
      () =>
        isAddItemRequestPending.value ||
        !isProductInStock.value ||
        !!errorMessage.value.message
    );

    const onHandleChange = (optionName, selected) => {
      handleChange(optionName, selected);
      const attrOptSelected = productConfig.value.attributes
        .find((attr) => attr.code === optionName)
        .options.find((attrOpt) => attrOpt.value === selected);

      checkIfAllVariantsAreOOS();

      dispatchEvent({
        eventName: 'loadEventData',
        overrideAttributes: {
          eventCategory: pageTypeName.value,
          eventAction: optionName === 'color' ? 'Color Click' : 'Select Size',
          eventLabel: `${props.data.id} - ${attrOptSelected.label}`,
        },
      });
    };

    const checkIfAllVariantsAreOOS = () => {
      const variantsFilteredByColor = product.value?.variants.filter(
        (variant) => variant.attributes.color === selectedItem.color
      );
      areAllVariantsOutOfStock.value = variantsFilteredByColor?.length
        ? variantsFilteredByColor.every((variant) => !variant.stock.inStock)
        : false;
    };

    const showSizes = computed(
      () => props.open && attributesData.value.showSizeSelector
    );

    return {
      toggleQuickShopOptions,
      onHandleChange,
      isGetProductPending,
      availableAttributes,
      product,
      productConfig,
      isMonetate,
      colorsCount,
      isSignInToBuy,
      validateAndAddToCart,
      openSignInToBuyModal,
      getColorByCode,
      emitToggleQuickShop,
      isAddItemRequestPending,
      handleShowDetails,
      gridClassSuffix,
      quickShopData,
      selectedItem,
      showSizes,
      buttonText,
      status,
      errorMessage,
      variantRegularPrice,
      hasSpecialPrice,
      variantSpecialPrice,
      addToCartButtonDisabled,
      areAllVariantsOutOfStock,
      isAllVariantSelected,
      inventoryLoading,
      productLink,
    };
  },
});

const getContextPart = (source) => {
  return source ? `_${source}` : '';
};

const getQuickShopContext = (productId, colors, colorCode) => {
  const { color } = extractDefaultQuickShopData(colors || []);

  return (
    'quickShopTile__' +
    productId +
    `${getContextPart(color?.value)}` +
    `${getContextPart(colorCode)}`
  );
};
