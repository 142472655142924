









































































import type { PropType } from 'vue';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';
import { storeToRefs } from 'pinia';
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
} from '@vue/composition-api';
import type { SignInConfirmationModalTranslations } from '@vf/api-contract';
import {
  useAccount,
  useAuthentication,
  useCart,
  useFavorites,
  useGtm,
  useNotification,
  useOrders,
  useReCaptcha,
  useValidation,
} from '@vf/composables';
import { useCheckoutStore } from '@vf/composables/src/store/checkoutStore';
import { errorMessages } from '@vf/composables/src/utils/errorMessages';
import useAuthenticationEvents, {
  AuthenticationType,
} from '@/shared/useAuthenticationEvents';
import useModal from '@/shared/useModal';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'SignInConfirmation',
  mixins: [validationMixin],
  props: {
    translations: {
      type: Object as PropType<SignInConfirmationModalTranslations>,
      required: true,
    },
    contextKey: { type: String, required: true },
  },
  setup(props) {
    const { root } = useRootInstance();

    const { getFormLocation } = useGtm(root);
    const formLocation = getFormLocation(props.contextKey);
    const { getBasicInformation } = useAccount(root);
    const { loadOrder } = useOrders(root);
    const { signIn, saveForLaterId } = useAuthentication(root);
    const { createSignIn } = useAuthenticationEvents(formLocation, {
      useLoadEvent: true,
      type: AuthenticationType.Login,
    });

    const { forgotPassword } = useAccount(root);

    const { displayErrorMessages } = errorMessages(root);
    const { cartId } = useCart(root);
    const { closeModal } = useModal();
    const { setValidation, $v } = useValidation(root, 'ORDER_SIGNIN');
    const { favoriteId } = useFavorites(root);
    const password = ref(null);
    const isButtonDisabled = ref(false);
    const forgotPasswordSection = ref(false);
    const forgotPasswordNotification = ref(false);
    const { clearNotifications } = useNotification(root);
    const reCaptcha = useReCaptcha(root);
    const { order } = storeToRefs(useCheckoutStore());

    const email = computed(() => order.value?.billingAddress?.email);

    onMounted(() => {
      reCaptcha.showBadge();
    });

    onUnmounted(() => reCaptcha.hideBadge());

    const onForgotPasswordClick = () => {
      forgotPasswordSection.value = true;
    };

    const onResetPasswordClick = async () => {
      try {
        const recaptchaToken = await reCaptcha.executeRecaptcha(
          'resetPassword'
        );
        await forgotPassword({ login: email.value, recaptchaToken });
        forgotPasswordNotification.value = true;
        forgotPasswordSection.value = false;
      } catch (err) {
        forgotPasswordSection.value = false;
        console.error(err);
      }
    };

    const boundWithGtmEventsSignIn = createSignIn(signIn);

    const onLoginWithOrderClick = async () => {
      $v.value.$touch();
      if ($v.value.$invalid) return;
      isButtonDisabled.value = true;

      try {
        const data = {
          username: email.value,
          password: password.value,
          st: order.value.st,
          orderNo: order.value.orderNumber,
          action: 'postLogin',
          guestObjects: {
            basketId: cartId.value,
            saveForLaterId: saveForLaterId.value,
            favoriteId: favoriteId.value,
          },
        };

        clearNotifications();
        const signInResult = await boundWithGtmEventsSignIn(data);

        if (signInResult) {
          await getBasicInformation();
          await loadOrder(order.value.orderNumber);
          closeModal();
        }
      } catch (err) {
        displayErrorMessages(err);
      } finally {
        isButtonDisabled.value = false;
        clearAllBodyScrollLocks();
      }
    };

    return {
      forgotPasswordSection,
      forgotPasswordNotification,
      onForgotPasswordClick,
      onResetPasswordClick,
      onLoginWithOrderClick,
      email,
      password,
      isButtonDisabled,
      setValidation,
      closeModal,
    };
  },
  validations: {
    password: {
      required,
    },
  },
  mounted() {
    this.setValidation(this.$v);
  },
});
