var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vf-order-summary"},[(_vm.order)?_c('div',{staticClass:"vf-order-summary__details",class:{
      'vf-order-summary__details--in-one-row': _vm.showDeliveryDataInOneRow,
    }},[_c('div',{staticClass:"vf-order-summary__fulfillment-methods"},[(_vm.shippingLocations.length > 0)?_c('div',{staticClass:"vf-order-summary__tile vf-order-summary__tile--shipping-info"},[_c('div',{staticClass:"vf-order-summary__shipping-details"},[_c('VfHeading',{staticClass:"vf-order-summary__heading",attrs:{"level":_vm.headingLevel,"title-modifier":_vm.headingModifier,"title":_vm.translations.shippingInfoLabel}}),_vm._v(" "),_c('div',{staticClass:"vf-order-summary__info"},[_c('OrderSummaryInfo',{attrs:{"heading":_vm.translations.shippingToLabel,"address-lines":_vm.mapToDataProtectedInfos(
                  _vm.shippingLocations[0].shippingAddress,
                  _vm.theme.personInfoFormat
                )}}),_vm._v(" "),_c('OrderSummaryInfo',{staticClass:"vf-order-summary-shipping",attrs:{"heading":_vm.translations.shippingMethodLabel}},_vm._l((_vm.uniqueSortedShipments),function(shipment){return _c('div',{key:shipment.shippingMethod.carrierCode,staticClass:"vf-order-summary__shipping-method"},[_c('VfText',{staticClass:"vf-order-summary-info__content",attrs:{"content":shipment.shippingMethod.label}}),_vm._v(" "),_c('VfText',{attrs:{"content":_vm.getDeliveryDateLabel(
                      shipment.shippingMethod.deliveryTime,
                      _vm.narvarTranslations.deliveryDateLabel,
                      false,
                      false,
                      _vm.localeData
                    )}})],1)}),0),_vm._v(" "),_c('OrderSummaryInfo',{staticClass:"vf-order-summary-address",attrs:{"heading":_vm.translations.addressLabel,"address-lines":_vm.mapToDataProtectedInfos(
                  _vm.shippingLocations[0].shippingAddress,
                  _vm.theme.addressFormat
                )}})],1)],1)]):_vm._e(),_vm._v(" "),(_vm.pickupLocations.length > 0)?_c('div',{staticClass:"vf-order-summary__tile vf-order-summary__tile--pickup-info"},_vm._l((_vm.pickupLocations),function(store){return _c('div',{key:store.storeId,staticClass:"vf-order-summary__pickup-info"},[_c('VfHeading',{staticClass:"vf-order-summary__heading",attrs:{"level":_vm.headingLevel,"title-modifier":_vm.headingModifier,"title":_vm.translations.pickupInfoLabel}}),_vm._v(" "),_c('div',{staticClass:"vf-order-summary__info"},[_c('OrderSummaryInfo',{staticClass:"vf-order-summary__pickup-location",style:(("--pickup-location-width-lg: " + (_vm.isAltPickupPerson(store.shippingAddress) ? '34%' : '50%'))),attrs:{"heading":_vm.translations.pickupLocationLabel,"address-lines":_vm.getPickupLocationInfo(store)}}),_vm._v(" "),_c('div',{staticClass:"vf-order-summary__pickup-people",style:(("--pickup-people-width-lg: " + (_vm.isAltPickupPerson(store.shippingAddress) ? '66%' : '50%')))},[_c('OrderSummaryInfo',{staticClass:"vf-order-summary__pickup-person",attrs:{"heading":_vm.translations.pickupPersonLabel,"address-lines":_vm.mapToDataProtectedInfos(
                    store.shippingAddress,
                    _vm.theme.pickupPersonInfoFormat
                  )}}),_vm._v(" "),(_vm.isAltPickupPerson(store.shippingAddress))?_c('OrderSummaryInfo',{staticClass:"vf-order-summary__pickup-person",attrs:{"heading":_vm.translations.altPickupPersonLabel,"address-lines":_vm.mapToDataProtectedInfos(
                    store.shippingAddress,
                    _vm.theme.altPickupPersonInfoFormat
                  )}}):_vm._e()],1)],1),_vm._v(" "),_c('VfButton',{staticClass:"vf-order-summary__direction-button",class:_vm.getDirectionButtonClass,attrs:{"link":_vm.getDirectionLink(store.shippingAddress),"icon":_vm.getDirectionButtonIcon,"open-in-new-tab":""}},[_vm._v("\n            "+_vm._s(_vm.translations.direction)+"\n          ")])],1)}),0):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"vf-order-summary__tile vf-order-summary__tile--reversed vf-order-summary__tile--billing-info"},[_c('div',{staticClass:"vf-order-summary__billing-details"},[_c('VfHeading',{staticClass:"vf-order-summary__heading",attrs:{"level":_vm.headingLevel,"title-modifier":_vm.headingModifier,"title":_vm.translations.billingInfoLabel}}),_vm._v(" "),_c('div',{staticClass:"vf-order-summary__info"},[_c('OrderSummaryInfo',{attrs:{"heading":_vm.translations.billingToLabel,"address-lines":_vm.mapToDataProtectedInfos(
                _vm.order.billingAddress,
                _vm.theme.personInfoFormat
              )}}),_vm._v(" "),_c('OrderSummaryInfo',{staticClass:"vf-order-summary__payment-info",attrs:{"heading":_vm.translations.paymentLabel,"address-lines":_vm.paymentDetails,"html":_vm.isPaymentLabelHtml}}),_vm._v(" "),_c('OrderSummaryInfo',{staticClass:"vf-order-summary-address",attrs:{"heading":_vm.translations.addressLabel,"address-lines":_vm.mapToDataProtectedInfos(
                _vm.order.billingAddress,
                _vm.theme.addressFormat
              )}})],1)],1),_vm._v(" "),(!_vm.pickupDetailsAtTop && _vm.pickupLocations.length > 0)?_c('OrderPickupDetails',{staticClass:"vf-order-summary__pickup-details",attrs:{"translations":_vm.pickupDetailsTranslations}}):_vm._e()],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }