



































































































































































































































/* eslint-disable no-case-declarations */
import type { PropType } from 'vue';
import {
  computed,
  onBeforeMount,
  defineComponent,
  ref,
  watch,
} from '@vue/composition-api';
import type {
  OrderDetailsTranslations,
  OrderDetails,
  Shipment,
  ShipmentExtended,
} from '@vf/api-contract';
import {
  PaymentMethodCode,
  PaymentDetailsCode,
  ReturnDetailedItem,
  Product,
} from '@vf/api-client';
import {
  ROUTES,
  useReturns,
  useI18n,
  useRewardCycleTranslations,
  useUrl,
} from '@vf/composables';
import { ReturnStepsType } from '@vf/composables/src/useReturns';
import {
  feOrderStatusMapping,
  OrderStatus,
  scrollTo as scrollToTop,
} from '@vf/shared/src/utils/helpers';
import {
  getItemsFromShipment,
  getShipmentTrackings,
  sortOrderShipmentsByDeliveryDate,
} from '@vf/composables/src/utils/orders';
import useRootInstance from '@/shared/useRootInstance';
import {
  DataProtectedInfo,
  mapToDataProtectedInfo,
  mapToDataProtectedInfos,
  pushProtected,
  pushUnprotected,
} from '@/helpers';

import OrderSummaryInfo from '@/components/order/OrderSummaryInfo.vue';
import OrderSummaryProductList from '@/components/order/OrderSummaryProductList.vue';
import SelectRefundMethod from '@/components/return/SelectRefundMethod.vue';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

export default defineComponent({
  name: 'OrderSummary',
  components: {
    OrderSummaryInfo,
    OrderSummaryProductList,
    SelectRefundMethod,
  },
  props: {
    translations: {
      type: Object as PropType<OrderDetailsTranslations>,
      required: true,
    },
    order: {
      type: Object as PropType<OrderDetails>,
      default: () => ({}),
    },
    products: {
      type: Array as PropType<OrderDetails['items']>,
      default: () => [],
    },
    isReturns: Boolean,
    orderPageType: {
      type: String,
      default: 'order',
    },
    refundMethodOptions: {
      type: Object,
      default: () => ({}),
    },
    displayReturnedItemsDetailsCTA: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, context) {
    const { root } = useRootInstance();
    const { getProductUrl } = useUrl(root);
    const theme = root.$themeConfig.orderDetails;
    const { areRewardCodesEnabled } = useFeatureFlagsStore();

    const {
      returnPayload,
      createReturn,
      resetReturnPreview,
      setReturnStep,
      returnStep,
      returnPreviewObject,
    } = useReturns(root);
    const { localePath } = useI18n(root);

    const getDirectionLink = (address) => {
      return `https://maps.google.com/?q=${address.addressLine1} ${address.addressLine2} ${address.addressLine3},${address.city},${address.postalCode},${address.country}`;
    };

    const isAltPickupPerson = (pickupInformation): boolean => {
      return pickupInformation?.firstName?.length > 0;
    };

    const isPickupShipment = (shipment: Shipment): boolean =>
      !!shipment.storeName || shipment.fulfillmentType === 'PIS';

    const pickupLocations = computed(() => {
      return orderShipments.value.filter(isPickupShipment);
    });

    const getPickupLocationInfo = (store) => {
      return [
        {
          value: store.storeName || theme.defaultStoreName,
          isProtected: false,
        },
        ...mapToDataProtectedInfos(
          store.shippingAddress,
          theme.pickupAddressFormat
        ),
      ];
    };

    const shippingLocations = computed(() => {
      return orderShipments.value.filter(
        (shipment) => !isPickupShipment(shipment)
      );
    });

    const uniqueShippingMethods = computed(() =>
      Array.from(
        shippingLocations.value
          .reduce((accMethods, shipment) => {
            const methods = shipment.shippingMethod?.services?.length
              ? shipment.shippingMethod.services
              : [shipment.shippingMethod];

            methods.forEach((method) => {
              method?.label && accMethods.set(method.label, method);
            });

            return accMethods;
          }, new Map())
          .values()
      )
    );

    const trackableShipments = computed(() => {
      return props.order?.shipments.filter(
        (shipment) => !!shipment.shippingMethod.trackingNumber.length
      );
    });

    const containTrackingNumber = computed<boolean>(() =>
      props.order?.shipments.some(
        (shipment) => !!shipment.shippingMethod?.trackingNumber?.length
      )
    );

    const hasEmptyShipmentIdItems = computed<boolean>(() =>
      props.products.some(
        (item) =>
          item.status !== OrderStatus.cancelled && !item?.shipments?.length
      )
    );

    const itemsSummaryLabel = ref<string>('');

    const isPaymentLabelHtml = computed<boolean>(() => {
      return props.order?.payments?.some(
        (method) =>
          method.paymentDetails === PaymentDetailsCode.KLARNA &&
          method.paymentMethod === PaymentMethodCode.CREDIT_CARD
      );
    });

    const showNotificationText = computed(
      () =>
        theme.showNotificationText &&
        [
          OrderStatus.processing,
          OrderStatus.shippedToStore,
          OrderStatus.created,
        ].includes(props.order?.status as OrderStatus) &&
        pickupLocations.value.length > 0
    );

    function expireDateToIssuedCycle(
      date: string,
      {
        spring,
        winter,
        summer,
      }: { spring: string; winter: string; summer: string }
    ) {
      const expireDate = new Date(date);
      const month = expireDate.getMonth(); // 1, 4, or 8 (February, May, or September)
      const expireMonthToIssuedCycleMap = {
        1: spring,
        4: summer,
        8: winter,
      };
      const issuedCycle = expireMonthToIssuedCycleMap[month];
      const issuedYear = expireDate.getFullYear() - 1;
      return `${issuedCycle} ${issuedYear}`;
    }

    // TODO: verify if we can use getPaymentDetails fn from helpers/payments.ts as well
    const paymentDetails = computed<DataProtectedInfo[]>(() => {
      const infos = [];
      const skipPayments = ['EGIFT_CARD'];
      // Map payment methods
      const paymentMethods =
        props.order?.payments
          ?.map((payment) => ({
            ...payment,
            code: payment.paymentMethod,
            cardType: payment.paymentDetails,
          }))
          .filter((payment) => !skipPayments.includes(payment.code)) || [];

      paymentMethods.forEach((paymentMethod) => {
        // Payment method code for order details is different than order confirmation
        if (paymentMethod.code === 'APPLEPAY') {
          paymentMethod.code = PaymentMethodCode.APPLEPAY;
        }
        // List of payments to skip
        switch (paymentMethod.code) {
          case PaymentMethodCode.CREDIT_CARD:
            // handle Klarna (sub case of credit card for OMS)
            if (paymentMethod.cardType === PaymentDetailsCode.KLARNA) {
              const klarnaLabel = `<div class="klarnaLabel">${props.translations.paymentMethodLabel.KLARNA.replace(
                '{{klarnaIconRed}}',
                '<div class="klarnaIconRed" role="img" aria-label="Klarna"></div>'
              )}</div>`;
              pushUnprotected(klarnaLabel, infos);
            }
            // handle regular credit carts
            else {
              const card = paymentMethod.cardType
                ? props.translations.paymentTextWithCardType
                    .replace('{{type}}', paymentMethod.cardType)
                    .replace('{{number}}', paymentMethod.number)
                : props.translations.paymentTextWithoutCardType.replace(
                    '{{number}}',
                    paymentMethod.number
                  );
              pushUnprotected(
                props.translations.paymentMethodLabel.CREDIT_CARD,
                infos
              );
              pushProtected(card, infos);
            }
            break;

          case PaymentMethodCode.GIFT_CARD:
            pushUnprotected(
              props.translations.paymentMethodLabel.GIFT_CARD,
              infos
            );
            pushProtected(
              props.translations.paymentTextWithoutCardType.replace(
                '{{number}}',
                paymentMethod.number
              ),
              infos
            );
            break;
          case PaymentMethodCode.REWARD_CERTIFICATE:
          case PaymentMethodCode.REWARD_CARD:
            // TODO: GLOBAL15-56318 remove if else and just keep the logic inside the if
            pushUnprotected(
              props.translations.paymentMethodLabel.REWARD_CARD,
              infos
            );
            if (areRewardCodesEnabled) {
              if (paymentMethod.expireDateTime) {
                const { summer, spring, winter } = props.translations;
                pushProtected(
                  expireDateToIssuedCycle(paymentMethod.expireDateTime, {
                    summer,
                    spring,
                    winter,
                  }),
                  infos
                );
              } else {
                pushProtected(props.translations.legacyReward, infos);
              }
            } else {
              pushProtected(
                props.translations.paymentTextWithoutCardType.replace(
                  '{{number}}',
                  paymentMethod.number
                ),
                infos
              );
            }

            break;

          case PaymentMethodCode.REWARD_CODE:
            const { getRewardCycleText } = useRewardCycleTranslations(
              props.translations
            );

            pushUnprotected(
              props.translations.paymentMethodLabel.REWARD_CARD,
              infos
            );
            pushProtected(getRewardCycleText(paymentMethod), infos);
            break;

          case PaymentMethodCode.PAYPAL:
            pushUnprotected(
              props.translations.paymentMethodLabel.PAYPAL,
              infos
            );
            break;

          case PaymentMethodCode.APPLEPAY:
            const labels = [
              props.translations.paymentMethodLabel.DW_APPLE_PAY,
              paymentMethod.cardType.toUpperCase(),
            ];
            pushUnprotected(labels.join('-'), infos);
            break;

          case PaymentMethodCode.ATHLETE_CREDIT:
            pushUnprotected(
              props.translations.paymentMethodLabel.ATHLETE_CREDIT,
              infos
            );
            break;

          case PaymentMethodCode.LOYALTY_POINTS:
            pushUnprotected(
              props.translations.paymentMethodLabel.LOYALTY_POINTS,
              infos
            );
            const total = root.$formatPrice(paymentMethod.amount);
            pushUnprotected(total, infos);
            break;

          default:
            pushUnprotected(paymentMethod.code, infos);
            break;
        }
      });
      return infos;
    });

    const returnStatuses = [
      OrderStatus.returnReceived,
      OrderStatus.returnCreated,
      OrderStatus.returnInvoiced,
    ].map((status) => status.toLowerCase());

    const cancelledStatuses = [
      OrderStatus.cancelled,
      OrderStatus.dyoCancelled,
    ].map((status) => status.toLowerCase());

    const isCancelled = (status = '') => {
      return cancelledStatuses.includes(status.toLowerCase());
    };

    const localizedProducts = computed(() =>
      props.products.map((product) => ({
        ...product,
        pdpUrl: getProductUrl((product as unknown) as Product),
      }))
    );

    // Order products object grouped by status
    const remappedProductsByStatus = localizedProducts.value.reduce(
      (groupedItems, nextItem) => {
        const status = nextItem.status.toLowerCase();

        // skip cancelled items
        if (isCancelled(nextItem.status)) return groupedItems;

        let mappedStatus = feOrderStatusMapping[status];

        // if grouping by status is not needed
        // move items to arbitrary "all" status
        if (!theme.groupItemsFromShipment && !returnStatuses.includes(status))
          mappedStatus = 'all';

        groupedItems[mappedStatus] = [
          ...(groupedItems[mappedStatus] || []),
          nextItem,
        ];
        return groupedItems;
      },
      {}
    );

    const productStatuses: string[] = Object.keys(remappedProductsByStatus);

    /**
     * GLOBAL15-62360 - hack for displaying items that are not present in order shipment.
     * Checks Release Acknowledged status for shipment
     */
    const getShipmentAcknowledgedProducts = (shipment) => {
      return props.products.filter(
        (product) =>
          product.status == 'Release Acknowledged' &&
          product.shipments.every(
            // We are looking for product with no shipmentId or valid shipmentId ('108962097' is valid, '0' or '1' is not.)
            ({ shipmentId }) => !shipmentId || shipmentId.length > 3
          ) &&
          !product.shipments.every(
            ({ shipmentGroupId, shipmentId }) =>
              shipmentId && shipmentGroupId === shipment.shipmentGroupId
          )
      );
    };

    const getShipmentProductsByStatus = (shipment: Shipment) => {
      const shipmentProducts = productStatuses.map((status) => [
        status,
        getItemsFromShipment(shipment, remappedProductsByStatus[status]),
      ]);

      const productsByStatus = Object.fromEntries(shipmentProducts);
      // GLOBAL15-62360 hack
      const itemStatus = productsByStatus.all ? 'all' : OrderStatus.processing;
      productsByStatus[itemStatus] = [
        ...(productsByStatus[itemStatus] ?? []),
        ...(getShipmentAcknowledgedProducts(shipment) ?? []),
      ];

      return productsByStatus;
    };

    const orderShipments = computed<ShipmentExtended[]>(() => {
      // Prepare shipments as array
      const shipments = props.order?.shipments.every(
        (shipment) => shipment.shipmentId
      )
        ? JSON.parse(JSON.stringify(props.order.shipments))
        : [props.order?.shipments[0]];

      const shipmentsWithMappedItems = shipments
        .map((shipment) => ({
          ...shipment,
          orderItems: getItemsFromShipment(shipment, props.products).filter(
            (item) => !isCancelled(item.status)
          ),
          billingAddressEmail: props.order?.billingAddress?.email,
          hasReturnableItems: props.order?.hasReturnableItems,
          shipmentProductsByStatus: getShipmentProductsByStatus(shipment),
          returnedProductsCount: getreturnedProductsCount(shipment),
        }))
        .map((shipment) => ({
          ...shipment,
          orderItems: [
            ...shipment.orderItems,
            ...getShipmentAcknowledgedProducts(shipment),
          ],
        }));

      return sortOrderShipmentsByDeliveryDate(
        shipmentsWithMappedItems,
        theme.groupItemsFromShipment
      );
    });

    const returnButtonText = ref<string>('');

    const getReturnedItemsText = (quantity): string =>
      props.translations.returnedItemsText.replace('{{quantity}}', quantity);

    const getreturnedProductsCount = (shipment: Shipment) => {
      return (
        getShipmentProductsByStatus(shipment)[OrderStatus.returnReceived]
          ?.length ??
        getShipmentProductsByStatus(shipment)[OrderStatus.returnCreated]
          ?.length ??
        0
      );
    };

    const showReturnButton = computed(
      () =>
        props.isReturns &&
        ![
          ReturnStepsType.CONFIRMATION,
          ReturnStepsType.HISTORY_DETAILS,
        ].includes(returnStep.value)
    );

    const isReturnInvalid = computed(() =>
      returnPayload.value.items.length
        ? returnPayload.value.items.some((item) => item.isInvalid) ||
          returnPayload.value.isRefundOnGiftCard === null
        : true
    );

    const handleUpdateReturn = (payload: ReturnDetailedItem) => {
      context.emit('handle-update-return', payload);
    };

    const handleCreateReturn = async () => {
      let response;

      switch (returnStep.value) {
        case ReturnStepsType.DETAILS:
          response = await createReturn(props.order.orderId, true);
          response && setReturnStep(ReturnStepsType.SUMMARY);
          break;
        case ReturnStepsType.SUMMARY:
          response = await createReturn(props.order.orderId, false);
          if (response) {
            root.$router.push({
              path: localePath(ROUTES.RETURN_CONFIRMATION()),
            });
            setReturnStep(ReturnStepsType.CONFIRMATION);
          }
          break;
      }
    };

    onBeforeMount(() => {
      if (!props.isReturns)
        itemsSummaryLabel.value = props.translations.itemSummaryLabel;
      else {
        switch (returnStep.value) {
          case ReturnStepsType.DETAILS:
            itemsSummaryLabel.value =
              props.translations.selectItemsToReturnSummaryHeading;
            returnButtonText.value = props.translations.returnItemsButtonText;
            break;
          case ReturnStepsType.SUMMARY:
            itemsSummaryLabel.value =
              props.translations.itemsToReturnSummaryHeading;
            returnButtonText.value =
              props.translations.confirmReturnsButtonText;
            break;
          case ReturnStepsType.CONFIRMATION:
          case 'historyDetails':
            itemsSummaryLabel.value =
              props.translations.itemsInReturnSummaryHeading;
            break;
        }
      }
    });

    watch(returnStep, (val) => {
      val === ReturnStepsType.EMPTY && resetReturnPreview();
      scrollToTop();
    });

    return {
      theme,
      localizedProducts,
      pickupLocations,
      shippingLocations,
      uniqueShippingMethods,
      isAltPickupPerson,
      paymentDetails,
      isPaymentLabelHtml,
      containTrackingNumber,
      getDirectionLink,
      showNotificationText,
      trackableShipments,
      hasEmptyShipmentIdItems,
      orderShipments,
      getShipmentTrackings: (shipment) =>
        getShipmentTrackings(props.order.shipments, shipment),
      remappedProductsByStatus,
      productStatuses,
      itemsSummaryLabel,
      isReturnInvalid,
      showReturnButton,
      returnStep,
      returnStatuses,
      returnsItems: computed(
        () =>
          returnPreviewObject.value?.items ?? returnPayload.value?.items ?? []
      ),
      returnPayload,
      returnButtonText,
      getReturnedItemsText,
      setReturnStep,
      handleUpdateReturn,
      handleCreateReturn,
      mapToDataProtectedInfo,
      mapToDataProtectedInfos,
      getPickupLocationInfo,
      getShipmentAcknowledgedProducts, // exposed for unit tests
    };
  },
});
