



















































import type { PropType } from 'vue';
import { defineComponent, ref, Ref } from '@vue/composition-api';
import { useArticles } from '@vf/composables';
import type { NewsArticleTranslations } from '@vf/api-contract';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'NewsArticleFilters',
  props: {
    translations: {
      type: Object as PropType<NewsArticleTranslations>,
      required: true,
    },
    /** Page Title **/
    title: {
      type: String as PropType<string>,
      required: true,
    },
    contextKey: {
      type: String,
      default: 'news-articles',
    },
  },
  setup() {
    const { root } = useRootInstance();
    const {
      filtersOptions,
      selectedFilters,
      isSelected,
      toggleSelection,
      resetFilters,
    } = useArticles(root, 'news-articles');

    const isExpanded: Ref<boolean> = ref(false);

    return {
      filtersOptions,
      selectedFilters,
      isSelected,
      toggleSelection,
      resetFilters,
      isExpanded,
    };
  },
});
