
































import { computed, defineComponent } from '@vue/composition-api';
import useRootInstance from '@/shared/useRootInstance';
import { useShippingFilter } from '@vf/composables';
import { BRAND_ID_FIELD } from '@vf/composables/src/useFindInStore';
import { PdpShippingMethod } from '@vf/composables/src/store/cartStore';

export default defineComponent({
  name: 'VfShippingFilter',
  setup() {
    const { root } = useRootInstance();
    const {
      onChange,
      selected,
      selectedStore,
      selectedStoreId,
    } = useShippingFilter(root);

    const storeName = computed(() => {
      if (selectedStoreId.value) {
        return selectedStore.value?.[BRAND_ID_FIELD] === selectedStoreId.value
          ? selectedStore.value.name
          : '';
      }
      return selectedStore.value?.name;
    });

    return {
      PdpShippingMethod,
      onChange,
      selected,
      storeName,
    };
  },
});
