var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VfAccordion',{staticClass:"cart-order-summary-products-list",attrs:{"header":_vm.productsListHeader,"is-open":true,"strong-header":false,"icon-size":'12px'}},[_vm._l((_vm.standardProducts),function(product){return _c('ThemeProductLine',{key:product.id,attrs:{"product":product,"translations":_vm.translations,"no-link":product.bonus,"gift-box-price":_vm.getGiftBoxPriceLabel(product)},scopedSlots:_vm._u([{key:"details1",fn:function(){return [(
          _vm.showEstimatedDate &&
          _vm.getPickupLabel(product) &&
          _vm.mergeNarvarAndPickupInfo &&
          _vm.getCommonNarvarAndPickupLabel(product)
        )?[_c('div',{staticClass:"cart-order-summary-products-list__product-message",domProps:{"innerHTML":_vm._s(_vm.getCommonNarvarAndPickupLabel(product))}})]:[(_vm.showEstimatedDate)?_c('div',{staticClass:"cart-order-summary-products-list__product-message",domProps:{"innerHTML":_vm._s(
            _vm.getDeliveryDateLabel(
              product.deliveryTime,
              _vm.translations.deliveryDateLabel,
              true,
              true,
              _vm.localeData
            )
          )}}):_vm._e(),_vm._v(" "),(_vm.getPickupLabel(product))?_c('div',{staticClass:"cart-order-summary-products-list__product-message"},[_vm._v("\n          "+_vm._s(_vm.getPickupLabel(product))+"\n        ")]):_vm._e()],_vm._v(" "),(product.isAthleteExcluded && !product.isAthleteExcludedDueSale)?_c('div',{staticClass:"cart-order-summary-products-list__product-message"},[_vm._v("\n        "+_vm._s(_vm.translations.excludedFromAthleteMessage)+"\n      ")]):_vm._e(),_vm._v(" "),(product.isAthleteExcludedDueSale)?_c('div',{staticClass:"cart-order-summary-products-list__product-message"},[_vm._v("\n        "+_vm._s(_vm.translations.excludedFromAthleteDueSale)+"\n      ")]):_vm._e()]},proxy:true},{key:"bottom",fn:function(){return [(product.bonus)?_c('div',{staticClass:"cart-order-summary-products-list__product-gift-message"},[_c('VfIcon',{attrs:{"icon":"info","size":"20px"}}),_vm._v(" "),_c('span',{staticClass:"cart-order-summary-products-list__product-gift-message-text"},[_vm._v(_vm._s(_vm.translations.giftWithPurchaseMessage))])],1):_vm._e(),_vm._v(" "),_c('VfDivider')]},proxy:true}],null,true)})}),_vm._v(" "),(_vm.customProducts.length > 0)?[_c('div',{staticClass:"cart-order-summary-products-list__section-title"},[_vm._v("\n      "+_vm._s(_vm.translations.customItems)+"\n    ")]),_vm._v(" "),_c('VfDivider'),_vm._v(" "),_vm._l((_vm.customProducts),function(product){return _c('ThemeProductLine',{key:product.id,attrs:{"product":product,"translations":_vm.translations,"no-link":product.bonus},scopedSlots:_vm._u([{key:"details1",fn:function(){return [(product.available)?_c('div',{staticClass:"cart-order-summary-products-list__product-message"},[_vm._v("\n          "+_vm._s(product.available)+"\n        ")]):_vm._e()]},proxy:true}],null,true)})})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }