//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'ContentPages',
  provide() {
    const provided = {};
    Object.defineProperty(provided, 'active', {
      get: () => this.active,
    });
    return { provided };
  },
  props: {
    /**
     * Pages title
     */
    title: {
      type: String,
      default: '',
    },
    /**
     * Active page ID
     */
    active: {
      type: Number,
      default: 0,
    },

    subcategories: {
      type: Array,
      default: () => [],
    },
    subcategoriesMaxItemsConfig: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    isActive() {
      return this.active > 0;
    },
  },
  methods: {
    isMenuItemHidden(index) {
      if (!this.$isServer && this.$viewport) {
        switch (this.$viewport.size) {
          case 'large':
            return (
              index < this.$props.subcategoriesMaxItemsConfig.largeMaxItems
            );
          case 'medium':
            return (
              index < this.$props.subcategoriesMaxItemsConfig.mediumMaxItems
            );
        }
      }

      return false;
    },
    updatePage(id) {
      /**
       * Active page updated event
       *
       * @event click-change
       * @type String
       */
      this.$emit('click-change', id);
    },
  },
});
