import { ROUTES } from '../../utils/routes';

type GetWriteReviewUrl = {
  productId: string;
  variantId: string;
  pageId?: string;
  name?: string;
};

export const getWriteReviewUrl = (
  { productId, variantId, pageId = null, name = null }: GetWriteReviewUrl,
  context
): string => {
  const isTrimProductIdAmount =
    context.$themeConfig?.reviews?.isTrimProductIdAmount ?? true;

  if (isTrimProductIdAmount) productId = productId.slice(0, -3);

  const prPageId = `?pr_page_id=${pageId || productId}`; // usually pageId is a part of productId
  const prPageIdVariant = `&pr_page_id_variant=${variantId}`;
  const prName = name ? `&name=${name}` : ''; // used for old implementation of power reviews
  return `${ROUTES.PRODUCT_REVIEW()}${prPageId}${prPageIdVariant}${prName}`;
};
