//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'VfProfileSummaryRow',
  props: {
    /** Definition Title */
    title: String,
    isTitleDataProtected: Boolean,
    /** Definition Description */
    description: String,
    isDescriptionDataProtected: Boolean,
  },
});
