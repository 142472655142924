import { computed } from '@vue/composition-api';
import { PaymentMethodCode } from '@vf/api-client';
import { useApplePay, useCheckout } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';

export function usePayments(): {
  visiblePaymentMethods;
  currentVisiblePaymentMethod;
} {
  const { root } = useRootInstance();

  const { isApplePayAvailable } = useApplePay(root);

  const { paymentMethodsData, paymentMethod } = useCheckout(root);

  const visiblePaymentMethods = computed(() => {
    if (!isApplePayAvailable()) {
      return paymentMethodsData.value?.filter(
        (payment) =>
          ![PaymentMethodCode.APPLEPAY, PaymentMethodCode.ATHLETES].includes(
            payment.code
          )
      );
    }
    return paymentMethodsData.value?.filter(
      (payment) => ![PaymentMethodCode.ATHLETES].includes(payment.code)
    );
  });

  const currentVisiblePaymentMethod = computed(() => {
    return visiblePaymentMethods.value?.find(
      (method) => method?.code === paymentMethod.value
    );
  });

  return {
    visiblePaymentMethods,
    currentVisiblePaymentMethod,
  };
}

export default usePayments;
