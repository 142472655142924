var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"checkout-picker-info"},[_c('VfInput',{staticClass:"pickup__input",attrs:{"name":"firstName","label":_vm.$t('pickerInfo.firstName'),"required":"","valid":_vm.validationFields.firstName.valid,"error-message":_vm.validationFields.firstName.message.replace(
        '{{fieldName}}',
        _vm.$t('pickerInfo.firstName').toLowerCase()
      )},on:{"blur":function($event){return _vm.validate('firstName')},"input":function($event){return _vm.$emit('input', _vm.form)}},model:{value:(_vm.form.firstName),callback:function ($$v) {_vm.$set(_vm.form, "firstName", $$v)},expression:"form.firstName"}}),_vm._v(" "),_c('VfInput',{staticClass:"pickup__input",attrs:{"name":"lastName","label":_vm.$t('pickerInfo.lastName'),"required":"","valid":_vm.validationFields.lastName.valid,"error-message":_vm.validationFields.lastName.message.replace(
        '{{fieldName}}',
        _vm.$t('pickerInfo.lastName').toLowerCase()
      )},on:{"blur":function($event){return _vm.validate('lastName')},"input":function($event){return _vm.$emit('input', _vm.form)}},model:{value:(_vm.form.lastName),callback:function ($$v) {_vm.$set(_vm.form, "lastName", $$v)},expression:"form.lastName"}}),_vm._v(" "),_c('VfInput',{staticClass:"pickup__input",attrs:{"type":"email","name":"email","label":_vm.$t('pickerInfo.email'),"required":"","valid":_vm.validationFields.email.valid,"error-message":_vm.validationFields.email.message.replace(
        '{{fieldName}}',
        _vm.$t('pickerInfo.email').toLowerCase()
      )},on:{"blur":function($event){return _vm.validate('email')},"input":function($event){return _vm.$emit('input', _vm.form)}},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }