//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, ref, inject } from '@vue/composition-api';
export default defineComponent({
  name: 'VfHistogram',
  props: {
    value: {
      type: Number,
      default: 0,
    },
    percentageValue: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: '',
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    // TODO: GLOBAL15-61059 remove after redesign core
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');

    const showTooltip = ref(false);

    return {
      showTooltip,
      isCoreRedesignEnabled,
    };
  },
});
