export function replaceTranslationVariables(
  translations: Record<string, string>,
  translationKeys: string[],
  mapper: Record<string, string | number>
): Record<string, string> {
  const finalTranslations: Record<string, string> = {};

  const variablesToReplace = Object.keys(mapper)
    .map((variable) => '{{' + variable + '}}')
    .join('|');

  const variables = new RegExp(variablesToReplace, 'gi');

  translationKeys.forEach((key) => {
    finalTranslations[key] = translations[key]?.replace(variables, (match) =>
      mapper[match.slice(2, -2)]?.toString()
    );
  });

  return finalTranslations;
}
