



















import type { PropType } from 'vue';
import {
  computed,
  defineComponent,
  onBeforeUnmount,
} from '@vue/composition-api';
import useRootInstance from '@/shared/useRootInstance';
import type { CategoryFiltersDisplayToggle } from '@vf/api-contract';
import { useFilters } from '@vf/composables';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';

export default defineComponent({
  name: 'CategoryFiltersDisplayToggle',
  props: {
    translations: {
      type: Object as PropType<CategoryFiltersDisplayToggle>,
      default: () => ({}),
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { setShowFiltersBar, showFiltersBar } = useFilters(root);
    // TODO: GLOBAL15-63799 remove after plp redesign
    const { isVansPlpRedesignEnabled } = useFeatureFlagsStore();

    const toggleShowFiltersBar = () => {
      setShowFiltersBar(!showFiltersBar.value);
    };
    const computedTranslations = computed(() => {
      const {
        showFilters,
        showFiltersAria,
        hideFilters,
        hideFiltersAria,
      } = props.translations;
      return showFiltersBar.value
        ? { aria: hideFiltersAria, label: hideFilters }
        : { aria: showFiltersAria, label: showFilters };
    });

    onBeforeUnmount(() => {
      setShowFiltersBar(true);
    });

    return {
      isVansPlpRedesignEnabled,
      setShowFiltersBar,
      showFiltersBar,
      computedTranslations,
      toggleShowFiltersBar,
    };
  },
});
