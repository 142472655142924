



























import type { PropType } from 'vue';
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  Ref,
} from '@vue/composition-api';
import type {
  ChangePasswordTranslations,
  ComponentWithVariants,
} from '@vf/api-contract';
import {
  ROUTES,
  useAccount,
  useAuthentication,
  useNotification,
} from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import Notifications from '../layout/Notifications.vue';

export default defineComponent({
  name: 'ChangePasswordForm',
  components: {
    Notifications,
  },
  props: {
    translations: {
      type: Object as PropType<ChangePasswordTranslations>,
      required: true,
    },
    /** Determines if show cancel button is shown */
    showCancelButton: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    /** Determines if show old password button is shown */
    showOldPasswordInput: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    /** Custom sign in link value */
    signInLink: {
      type: String as PropType<string>,
      default: ROUTES.SIGN_IN(),
    },
    /** Component variant to display */
    componentVariant: {
      type: String as PropType<ComponentWithVariants>,
      default: 'VARIANT_A',
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { changePassword } = useAccount(root);
    const { signOut } = useAuthentication(root);
    const {
      addNotification,
      hasChangePasswordNotification,
      setChangePasswordNotification,
      passwordChangeNotification,
      setPasswordChangeNotification,
      clearNotifications,
    } = useNotification(root);

    const disableSubmit: Ref<boolean> = ref(false);
    const getComponentVariant = computed(() => () =>
      import(
        `@vf/ui/components/Organism.ChangePassword.${props.componentVariant}.vue`
      )
    );
    const handleChangePassword = async (value) => {
      clearNotifications();
      setChangePasswordNotification(true);
      try {
        disableSubmit.value = true;
        const changePasswordResponse = await changePassword(value);
        if (
          changePasswordResponse &&
          [200, 201].includes(changePasswordResponse.status)
        ) {
          addNotification({
            message: props.translations.changePasswordSuccess,
            type: 'success',
            modifiers: 'scrollToNotification',
          });
          signOut();
        }
      } catch (e) {
        console.error(e);
      } finally {
        disableSubmit.value = false;
      }
    };

    onMounted(() => {
      if (!passwordChangeNotification.value.isShown) return;

      addNotification({
        message: props.translations.changePasswordSuccess,
        type: 'success',
        modifiers: 'scrollToNotification',
      });
      setPasswordChangeNotification({ isShown: false });
    });

    return {
      disableSubmit,
      getComponentVariant,
      handleChangePassword,
      hasChangePasswordNotification,
    };
  },
});
