




import { defineComponent } from '@vue/composition-api';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';

export default defineComponent({
  name: 'CMVendorPlaceholder',
  serverCacheKey: getCacheKeyFromProps,
  props: {
    /** Class passed as a reference for vendors */
    cssClass: {
      type: String,
      default: '',
    },
  },
});
