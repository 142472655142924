import { Product } from '@vf/api-client';

export const checkSignInToBuy = (
  isSignInToBuyAtVariantLevel: boolean,
  product: Product,
  isCustomerAllowed: boolean
): boolean =>
  isSignInToBuyAtVariantLevel
    ? product?.presale && product?.color?.presale && !isCustomerAllowed
    : product?.presale && !isCustomerAllowed;

export default checkSignInToBuy;
