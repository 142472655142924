








import type { PropType } from 'vue';
import { computed, defineComponent } from '@vue/composition-api';
import type { InterestsFormCollectionTranslations } from '@vf/api-contract';
import { useAccount } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import { Interest } from '@vf/composables/src/useCms/types';

export default defineComponent({
  name: 'InterestsFormCollection',
  props: {
    interests: {
      type: Array as PropType<Interest[]>,
      required: true,
    },
    translations: {
      type: Object as PropType<InterestsFormCollectionTranslations>,
      default: () => ({}),
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { basicInformation } = useAccount(root);
    const loyaltyInterests = computed(() => {
      const selectedInterests = basicInformation.value.preferences.interests
        .split('|')
        .map((i) => i?.toLowerCase());
      return props.interests.map((i) => ({
        ...i,
        isActive: selectedInterests.includes(i.title.toLowerCase()),
      }));
    });

    return {
      loyaltyInterests,
    };
  },
});
