




import { WithContext } from 'schema-dts';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'StoreSchema',
  props: {
    /**
     * Store data.
     */
    store: {
      type: Object,
      default: () => {
        /**/
      },
    },
  },
  jsonld() {
    return ({
      '@context': 'https://schema.org',
      '@type': 'LocalBusiness',
      '@name': this.store.name,
      address: {
        '@type': 'PostalAddress',
        streetAddress: this.store.address1,
        addressLocality: this.store.city,
        addressRegion: this.store.province,
        postalCode: this.store.postalCode,
        addressCountry: this.store.country,
      },
      containedIn: '',
      geo: {
        '@type': 'GeoCoordinates',
        latitude: this.store.latitude,
        longitude: this.store.longitude,
      },
      branchOf: {
        name: this.$getBranchName(),
        url: 'https://' + this.$getDomainName(),
      },
      url:
        'https://' +
        this.$getDomainName() +
        this.$storeLocatorUrlGenerator(
          '/stores',
          this.store.province,
          this.store.city,
          this.store.clientkey
        ),
      telephone: this.store.phone,
    } as unknown) as WithContext<string>;
  },
  setup() {
    const display = false;
    return {
      display,
    };
  },
});
