



























































import type { PropType } from 'vue';
import { defineComponent, ref, watch } from '@vue/composition-api';
import type { CategorySortTranslations } from '@vf/api-contract';
import { useCategory } from '@vf/composables';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'CategorySort',
  props: {
    /** Name of the context where component should be displayed (one of 'category' | 'search-products' | 'search-articles) */
    contextName: {
      type: String,
      default: 'category',
    },
    translations: {
      type: Object as PropType<CategorySortTranslations>,
      default: () => ({}),
    },
    /** Default chosen value for Sort By select  */
    selectedSort: {
      type: String,
      default: '',
    },
    contextKey: {
      type: String,
      default: 'page-content',
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const {
      changeSort,
      getCatalog,
      sortingOptions,
      selectedSortingOptionId,
      loading,
      setActivePage,
    } = useCategory(root, props.contextKey);
    // TODO: GLOBAL15-63799 remove after plp redesign
    const { isVansPlpRedesignEnabled } = useFeatureFlagsStore();

    const activeSortOption = ref(props.selectedSort);
    changeSort(props.selectedSort, false);

    watch(
      selectedSortingOptionId,
      () => {
        if (selectedSortingOptionId.value)
          activeSortOption.value = selectedSortingOptionId.value;
      },
      { immediate: true }
    );

    const changeActiveSortOption = (sortOptionId) => {
      activeSortOption.value = sortOptionId;
      changeSort(sortOptionId);
      setActivePage(1);
      getCatalog();
    };

    return {
      changeActiveSortOption,
      selectedSortingOptionId,
      isVansPlpRedesignEnabled,
      loading,
      sortingOptions,
      activeSortOption,
    };
  },
});
