



























import type { PropType } from 'vue';
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  Ref,
} from '@vue/composition-api';
import type {
  FavoriteItemNotification,
  FavoritesTranslations,
} from '@vf/api-contract';
import { useCart, useRouting, useI18n } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import useLoader from '@/shared/useLoader';
import { useFavoritesWithDataLayer } from '@/shared/useFavoritesWithDataLayer';
import { PageTypeName } from '@vf/composables/src/useCms/types';

export default defineComponent({
  name: 'Favorites',
  props: {
    /** Name of the context where component should be displayed */
    contextName: {
      type: String,
      default: 'favorites',
    },
    translations: {
      type: Object as PropType<FavoritesTranslations>,
      default: () => ({}),
    },
  },
  setup() {
    const { root } = useRootInstance();
    const {
      getFavorites,
      favorites,
      isPublicList,
      favoritesCount,
      setShareLink,
      moveFavoriteItemToCart,
      removeFromFavorites,
      sharedFavoriteLink,
    } = useFavoritesWithDataLayer(PageTypeName.FAVORITES);
    const { showSpinner, hideSpinner } = useLoader();
    const { getPathWithoutLocalization } = useRouting(root);
    const { localePath } = useI18n(root);
    const { setMiniCart } = useCart(root);
    const areProductsVisible = ref(true);
    const productsViewType = ref('grid');
    const isEmptyList = computed(
      () => favoritesCount.value === 0 && !itemNotification.value.display
    );
    const itemNotification: Ref<FavoriteItemNotification> = ref({
      display: false,
      itemIndex: null,
      type: '',
    });

    const fetchFavorites = async () => {
      showSpinner();
      await getFavorites();
      if (isPublicList.value) await setShareLink();
      hideSpinner();
    };

    const showProducts = (flag: boolean) => {
      areProductsVisible.value = flag;
    };

    const setListPublic = async () => {
      showSpinner();
      await setShareLink();
      hideSpinner();
    };

    const changeViewType = (viewType: 'grid' | 'list') => {
      productsViewType.value = viewType;
    };

    const removeItemAction = async (event) => {
      showSpinner();
      try {
        const success = await removeFromFavorites(event.item);
        if (success) {
          itemNotification.value = {
            display: true,
            itemIndex: event.itemIndex,
            type: 'removeFromList',
          };
        }
      } finally {
        hideSpinner();
      }
    };

    const moveToCartAction = async (event) => {
      showSpinner();
      const resp = await moveFavoriteItemToCart(event.itemId);
      hideSpinner();
      if (resp) {
        itemNotification.value = {
          display: true,
          itemIndex: event.itemIndex,
          type: 'addToCart',
        };
        setMiniCart(true);
      }
    };

    const favoritesWithCorrectLocale = computed(() =>
      favorites.value.map((product) => ({
        ...product,
        pdpUrl: localePath(
          getPathWithoutLocalization(product.pdpUrl || product.pageUrl)
        ),
      }))
    );

    onMounted(() => {
      fetchFavorites();
    });

    return {
      areProductsVisible,
      changeViewType,
      favorites,
      favoritesWithCorrectLocale,
      favoritesCount,
      isEmptyList,
      isPublicList,
      itemNotification,
      moveToCartAction,
      productsViewType,
      removeItemAction,
      setListPublic,
      sharedFavoriteLink,
      showProducts,
    };
  },
});
