




























import { defineComponent, ref, computed } from '@nuxtjs/composition-api';
import { storeToRefs } from 'pinia';
import { CheckoutContext } from '@vf/api-contract';
import {
  ApplePayContext,
  useApplePay,
  useSignInToStore,
} from '@vf/composables';
import { useCartStore } from '@vf/composables/src/store/cartStore';

import PayPal from '../checkout/payment/paypal/index.vue';
import ApplePay from '../../payment/ApplePay.vue';

import useRootInstance from '@/shared/useRootInstance';

export default defineComponent({
  name: 'ExpressCheckout',
  components: {
    PayPal,
    ApplePay,
  },
  setup() {
    const { root } = useRootInstance();
    const theme = root.$themeConfig.staticCart;

    const { employeeConnected } = useSignInToStore(root);
    const { isApplePayAvailable } = useApplePay(root, ApplePayContext.CART);

    const { hasCustomItems } = storeToRefs(useCartStore());

    const isOpen = ref(false);

    const isPayPalVisible = computed(
      () =>
        theme.expressCheckout.isPayPalVisible &&
        !(
          root.$themeConfig.checkoutSidebar.hidePaypalForEmployee &&
          employeeConnected.value
        )
    );
    const isApplePayVisible = computed(
      () =>
        !hasCustomItems.value &&
        theme.expressCheckout.isApplePayVisible &&
        isApplePayAvailable()
    );

    const isSeparatorVisible = computed(
      () =>
        [isPayPalVisible.value, isApplePayVisible.value].filter(
          (value) => value
        ).length === 1
    );

    const isExpressCheckoutButtonVisible = computed(() => {
      return (
        isPayPalVisible.value &&
        isApplePayVisible.value &&
        !isOpen.value &&
        root.$viewport.breakpoint.smDown
      );
    });

    const areButtonsVisible = computed(() => {
      return (
        isOpen.value ||
        !isPayPalVisible.value ||
        !isApplePayVisible.value ||
        !root.$viewport.breakpoint.smDown
      );
    });

    return {
      isOpen,
      CheckoutContext,
      isPayPalVisible,
      isApplePayVisible,
      isSeparatorVisible,
      isExpressCheckoutButtonVisible,
      areButtonsVisible,
      ApplePayContext,
    };
  },
});
