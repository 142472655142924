




































import {
  computed,
  onMounted,
  ref,
  defineComponent,
} from '@vue/composition-api';
import { useGtm } from '@vf/composables';
import useRootInstance from '@/shared/useRootInstance';
import { scrollToElement } from '@vf/shared/src/utils/helpers';

export default defineComponent({
  name: 'CustomShoesTopLeft',
  props: {
    /** Video Object to display: as a background or video */
    video: {
      type: Object,
      default: () => ({}),
    },
    subheading: {
      type: String,
      default: '',
    },
    links: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, context) {
    const { root } = useRootInstance();
    const hasVideo = computed(() => Object.keys(props.video).length !== 0);
    const isParentSticky = ref(false);
    const { dispatchEvent, dispatchVideoEvent } = useGtm(root);

    onMounted(() => {
      isParentSticky.value = context.parent.$el.className === 'vf-sticky';
    });

    const backgroundStyle = computed(() => {
      return {
        '--_grid-background-position': 'center',
        '--_grid-background-size': 'cover',
        '--_grid-background-repeat': 'no-repeat',
      };
    });
    const logEvent = (data) => {
      dispatchEvent({
        eventName: 'loadEventData',
        overrideAttributes: {
          eventAction: 'Main Page',
          eventLabel: data,
        },
      });
    };
    return {
      hasVideo,
      backgroundStyle,
      isParentSticky,
      logEvent,
      dispatchVideoEvent,
      scrollToElement,
    };
  },
});
