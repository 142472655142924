






import {
  onMounted,
  onUnmounted,
  ref,
  defineComponent,
} from '@vue/composition-api';
import useRootInstance from '@/shared/useRootInstance';
import { load } from '@vf/shared/src/utils/helpers';
import { useReviews } from '@vf/composables';

declare global {
  interface Window {
    POWERREVIEWS: {
      display: {
        render: (obj: any) => void;
        unmount: () => void;
      };
    };
  }
}

export default defineComponent({
  props: {
    pageId: {
      type: String,
      required: true,
    },
    pageIdVariant: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { powerReviewsConf } = useReviews(root);

    const powerReviewUrl = 'https://ui.powerreviews.com/stable/4.0/ui.js';

    const timeout = ref(null);
    const waitForPowerReviews = () => {
      let runs = 0;
      const maxRuns = 50;
      return new Promise((resolve, reject) => {
        const checkPowerReviews = () => {
          clearTimeout(timeout.value);
          timeout.value = setTimeout(() => {
            if (window['POWERREVIEWS']) {
              resolve(window['POWERREVIEWS']);
              return;
            }
            if (runs++ >= maxRuns) {
              reject(`POWERREVIEWS can't be loaded!`);
              return;
            }
            checkPowerReviews();
          }, 200);
        };
        checkPowerReviews();
      });
    };

    onMounted(() => {
      load(powerReviewUrl)
        .then(() => {
          waitForPowerReviews()
            .then(() => {
              window.POWERREVIEWS.display.render({
                api_key: powerReviewsConf.apiKey,
                locale: powerReviewsConf.locale,
                merchant_group_id: powerReviewsConf.merchantGroupId,
                merchant_id: powerReviewsConf.merchantId,
                page_id: props.pageId,
                page_id_variant: props.pageIdVariant,
                on_submit: function () {
                  window.scrollTo(0, 0);
                },
                components: { Write: 'pr-write' },
              });
            })
            .catch((error) => {
              root.$log.error(
                `[@ui/components/Atom.PowerReviews.vue::onMounted] waitForPowerReviews failed! ${error.message}`,
                { path: root.$route.path, error }
              );
            });
        })
        .catch(() => {
          // Failed to fetch script
          console.error('PowerReview script loading failed!');
        });
    });

    onUnmounted(() => {
      typeof window.POWERREVIEWS !== 'undefined' &&
        window.POWERREVIEWS.display.unmount();
    });
  },
});
