import { ComponentInstance, useCart } from '@vf/composables';

type ToggledBuyPanelEventBody = {
  visible: boolean;
};

type ToggledBuyPanelEvent = ToggledBuyPanelEventBody & {
  body?: ToggledBuyPanelEventBody;
};

const toggledBuyPanel = (
  instance: ComponentInstance,
  event: ToggledBuyPanelEvent
): void => {
  const { setHideMiniCart } = useCart(instance);
  const visible = 'body' in event ? event.body.visible : event.visible;

  setHideMiniCart(visible);
};

export default toggledBuyPanel;
