







































import type { PropType } from 'vue';
import { defineComponent } from '@vue/composition-api';
import type { PageTitleTranslations } from '@vf/api-contract';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';
import { useRootInstance } from '@/shared/useRootInstance';

export default defineComponent({
  name: 'PageTitle',
  serverCacheKey: getCacheKeyFromProps,
  props: {
    /** Title heading level 1-6 */
    level: {
      type: Number,
      default: () => 2,
    },
    /** Back button switcher */
    showBackButton: {
      type: Boolean,
      default: true,
    },
    /** Title text */
    title: {
      type: String,
      default: '',
    },
    /** Subtitle text */
    subtitle: {
      type: String,
      default: '',
    },
    translations: {
      type: Object as PropType<PageTitleTranslations>,
      default: () => ({}),
    },
    showTitleInQuotes: {
      type: Boolean,
      default: false,
    },
    vfTextAriaRole: String,
    vfTextAriaLive: String,
  },
  setup() {
    const { root } = useRootInstance();
    const goBack = () => root.$router.back();

    return {
      goBack,
    };
  },
  computed: {
    isSmall() {
      return !!this.$root.$viewport.isSmall;
    },
  },
});
