









import Vue from 'vue';
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  watch,
} from '@vue/composition-api';
import useRootInstance from '@/shared/useRootInstance';
import { useI18n } from '@vf/composables';

Vue.config.ignoredElements.push('klarna-placement');

export default defineComponent({
  name: 'KlarnaOnSiteMessaging',
  props: {
    price: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { getIetfLocale } = useI18n(root);
    const scriptReady = ref(false);

    const refreshPlacements = () => {
      window.Klarna?.OnsiteMessaging?.refresh();
    };

    const purchaseAmount = computed<string>(
      () => Math.round(props.price * 100) + ''
    );

    const scriptData = root.$getEnvValueByCurrentLocale<{
      clientId: string;
      environment: string;
    }>('KLARNA_ON_SITE_MESSAGING');

    onMounted(() => watch(purchaseAmount, refreshPlacements));

    return {
      scriptReady,
      scriptData,
      purchaseAmount,
      getIetfLocale,
      refreshPlacements,
    };
  },
  head(): unknown {
    if (!this.scriptData.clientId) {
      return {};
    }

    return {
      script: [
        {
          hid: 'klarnaonsitemessaging',
          src: 'https://js.klarna.com/web-sdk/v1/klarna.js',
          'data-client-id': this.scriptData.clientId,
          'data-environment': this.scriptData.environment,
          callback: () => {
            this.scriptReady = true;
            this.refreshPlacements();
          },
        },
      ],
    };
  },
});
