var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VfContainer',{staticClass:"article-grid nested",attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id'])}},[(_vm.title)?_c('VfRow',{staticClass:"article-grid__header"},[_c('VfColumn',{attrs:{"small":"12","large":"12","medium":"12"}},[_c('VfText',{staticClass:"article-grid__subheading vf-text--center",style:({
          'font-size': _vm.styles.subtitleFontSize,
          'font-family': _vm.styles.subtitleFontFamily,
          'font-weight': _vm.styles.subtitleFontFamily,
        }),attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id'], 'teaserSubtitle'),"background-color":_vm.styles.subtitleBackgroundColor,"text-color":_vm.styles.subtitleColor,"content":_vm.subtitle}}),_vm._v(" "),_c('VfHeading',{staticClass:"vf-text--center",attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id'], 'teaserTitle'),"styles":_vm.styles,"title-modifier":"title-3","title":_vm.title}})],1)],1):_vm._e(),_vm._v(" "),_c('VfRow',[_c('client-only',_vm._l((_vm.articlesToDisplay),function(article,index){return _c('VfColumn',{key:("article-" + (article.title) + "-" + index),attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id'], 'componentConfig'),"small":Math.floor(12 / _vm.configuration['articles-per-row-small-screen']),"medium":Math.floor(12 / _vm.configuration['articles-per-row-medium-screen']),"large":Math.floor(12 / _vm.configuration['articles-per-row-large-screen'])}},[_c('VfTeaserTile',{attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id'], 'componentConfig'),"image":article.image.large,"image-width":_vm.imageWidth,"image-height":_vm.imageHeight,"title":_vm.configuration['tile-show-title'] ? article.title : null,"default-read-more-button-text":_vm.defaultReadMoreButtonText,"text":(!_vm.$isServer && _vm.$viewport.isSmall) ||
            _vm.configuration['tile-show-body-text']
              ? article.text
              : null,"link-cta":_vm.configuration['tile-show-read-more-cta']
              ? article.readMoreText
              : null,"link":article.link,"tag":_vm.getTag(article),"open-in-new-tab":_vm.openInNewTab,"open-in-new-modal":_vm.openInNewModal,"data-navigation":article.dataNavigation,"data-dom-location":_vm.AnalyticsDataDomLocation.Article,"data-date":_vm.getPublicationDate(article),"show-tag-below-image":_vm.showTagBelowImage,"square-images":""}})],1)}),1)],1),_vm._v(" "),_c('VfRow',{staticClass:"article-grid__footer"},[_c('VfColumn',{staticClass:"article-grid__button-wrapper",attrs:{"small":"12","medium":"12","large":"12"}},[_c('ThemeButton',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.articlesToDisplay.length < _vm.pagination.total &&
          _vm.articlesToDisplay.length != 0
        ),expression:"\n          articlesToDisplay.length < pagination.total &&\n          articlesToDisplay.length != 0\n        "}],attrs:{"size":"md","color":"secondary","data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id'], 'resourceBundle'),"aria-label":_vm.showMoreButtonText || _vm.defaultShowMoreButtonText},on:{"click":_vm.showMore}},[_vm._v("\n        "+_vm._s(_vm.showMoreButtonText || _vm.defaultShowMoreButtonText)+"\n      ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }