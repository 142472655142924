











import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'OrderSidebarProperty',
  props: {
    name: String,
    value: [String, Number],
  },
});
