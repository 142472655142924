
















import { defineComponent } from '@vue/composition-api';
import type { CheckoutOrder } from '@vf/api-client';
import type { PropType } from 'vue';

export default defineComponent({
  name: 'OrderConfirmation',
  props: {
    order: {
      type: Object as PropType<CheckoutOrder>,
      required: true,
    },
  },

  getFormattedDate() {
    return new Date().toLocaleDateString('en-us', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });
  },
});
