











































































































































































































































































import { computed, defineComponent, ref, watch } from '@nuxtjs/composition-api';
import { BreadcrumbList, WithContext } from 'schema-dts';
import useRootInstance from '@/shared/useRootInstance';
import { useJsonLinkedData } from '@vf/composables';

export default defineComponent({
  name: 'ExtendedStoreContent',
  props: {
    /**
     * Store picture alt
     */
    alt: {
      type: String,
      default: '',
    },
    /**
     * iframe url
     */
    iframeUrl: {
      type: String,
      default: '',
    },
    /**
     * Object contains store data
     */
    storeCollection: {
      type: Object,
      default: () => {
        /**/
      },
    },
    /**
     * store carries
     */
    storeCarries: {
      type: Array,
      default: () => [],
    },
    /**
     * Store image path from Scene7
     */
    storeImage: {
      type: String,
      default: '',
    },
    /** map url */
    mapUrl: {
      type: String,
      default: null,
    },
    /** Array contains special days with special hours */
    specialHours: {
      type: Array,
      default: () => [],
    },
    locale: {
      type: String,
      default: 'en-us',
    },
  },
  jsonld(): WithContext<BreadcrumbList> {
    return this.transformBreadcrumbsDataToJsonld(this.breadcrumbs);
  },
  setup(props) {
    const { root } = useRootInstance();
    const { transformBreadcrumbsDataToJsonld } = useJsonLinkedData(root);

    const data = root.$themeConfig.storeData[props.locale].store;
    const appData = data.appData;
    const daysOfWeek = data.daysOfWeek;
    const date = new Date();
    const indexOfDay = date.getDay() - 1;
    const showNotification = ref(false);
    const notificationMsg = ref('');

    const breadcrumbs = computed(() => {
      return [
        {
          text: appData.find_store_title,
          link: `/${props.locale}/more/store-locator`,
        },
        {
          text: props.storeCollection.name
            ? props.storeCollection.name.toLowerCase()
            : '',
          link: root.$route.path,
        },
      ];
    });

    watch(
      () => props.storeCollection,
      (storeCollection) => {
        if (storeCollection.reopen_date) {
          if (storeCollection.temp_closed === '1') {
            notificationMsg.value =
              appData.temp_closed_info + storeCollection.reopen_date;
          } else {
            notificationMsg.value =
              appData.reopen_date_info + storeCollection.reopen_date;
          }
          showNotification.value = true;
        }
      },
      { immediate: true }
    );

    return {
      appData,
      breadcrumbs,
      daysOfWeek,
      indexOfDay,
      notificationMsg,
      showNotification,
      transformBreadcrumbsDataToJsonld,
    };
  },
});
