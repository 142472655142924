













import { defineComponent, PropType } from '@vue/composition-api';
import { useChat } from '../composables/useChat';

export default defineComponent({
  name: 'OpenChat',
  props: {
    title: {
      type: String as PropType<string>,
    },
    linkType: {
      type: String as PropType<string>,
      default: 'theme-button',
    },
  },
  setup() {
    const { loaded, dispatchChat } = useChat();

    return {
      loaded,
      dispatchChat,
    };
  },
});
