import { render, staticRenderFns } from "./QuickshopSizeChart.vue?vue&type=template&id=399b4099&scoped=true&"
import script from "./QuickshopSizeChart.vue?vue&type=script&lang=ts&"
export * from "./QuickshopSizeChart.vue?vue&type=script&lang=ts&"
import style0 from "./QuickshopSizeChart.vue?vue&type=style&index=0&id=399b4099&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "399b4099",
  null
  
)

export default component.exports