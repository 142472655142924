


















import type { PropType } from 'vue';
import { defineComponent, computed } from '@vue/composition-api';
import type { ApplePayTranslations } from '@vf/api-contract';
import { ApplePayContext, useApplePay } from '@vf/composables';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import useRootInstance from '@/shared/useRootInstance';
import useLoader from '@/shared/useLoader';

export default defineComponent({
  name: 'ApplePay',
  props: {
    /** Shows/Hides header title above the AP button */
    showHeader: {
      type: Boolean,
      default: true,
    },
    applePayTranslations: {
      type: Object as PropType<ApplePayTranslations>,
      required: true,
    },
    /** CSS classes to apply to AP button */
    buttonClass: {
      type: [String, Array],
      default: '',
    },
    context: {
      type: String as PropType<ApplePayContext>,
      default: '',
    },
  },
  setup(props, { emit }) {
    const { root } = useRootInstance();
    const { showSpinner, hideSpinner } = useLoader();
    const { isCheckoutRedesignEnabled } = useFeatureFlagsStore(); // TODO: GLOBAL15-56318 clean up
    const { initApplePaySession } = useApplePay(root, props.context);

    const shouldEmitClickEvent = computed(() => {
      return (
        !isCheckoutRedesignEnabled ||
        ![ApplePayContext.CART, ApplePayContext.CHECKOUT].includes(
          props.context
        )
      );
    });

    const showApplePayPaymentSheet = () => {
      // If isCheckoutRedesignEnabled flag is disabled or it is PDP page, it should emit 'click' event instead of initializing Apple Pay session
      if (shouldEmitClickEvent.value) {
        emit('click');
        return;
      }
      showSpinner();
      // Set overrideAddress parameter (third one) to true, only on checkout billing page
      initApplePaySession(
        props.applePayTranslations,
        hideSpinner,
        props.context === ApplePayContext.CHECKOUT
      );
    };

    return {
      isCheckoutRedesignEnabled,
      showApplePayPaymentSheet,
    };
  },
});
