var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"store-cities container"},[_c('h1',[_vm._v(_vm._s(_vm.fullTitle))]),_vm._v(" "),_c('VfBreadcrumbs',{staticClass:"breadcrumbs",attrs:{"breadcrumbs":_vm.breadcrumbs}}),_vm._v(" "),_c('h3',[_vm._v(_vm._s(_vm.cityCollection.city))]),_vm._v(" "),_c('StoreSplitDealers',{attrs:{"enable-split":_vm.splitDealers,"stores":_vm.cityCollection.stores,"title-vf-store":_vm.titleVfStore,"title-dealer-store":_vm.titleDealerStore,"is-dealer":_vm.isDealer},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var store = ref.store;
return [_c('div',{staticClass:"itemlist"},[_c('StoreSchema',{attrs:{"store":store}}),_vm._v(" "),_c('ThemeLink',{attrs:{"to":_vm.urlGenerator(
              '/stores',
              _vm.cityCollection.state,
              store.city,
              store.clientkey
            )}},[_vm._v("\n          "+_vm._s(_vm.getFormattedLink(store, _vm.cityCollection))+"\n        ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }