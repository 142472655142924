




















































































































































































































import type { PropType } from 'vue';
import { computed, defineComponent } from '@vue/composition-api';
import type {
  OrderDetailsSidebarTranslations,
  Shipment,
} from '@vf/api-contract';
import { PaymentMethodCode } from '@vf/api-client';
import { useOrders, useReturns } from '@vf/composables';
import { getOrderGiftOrRewardCardAmount } from '@vf/composables/src/useOrders/helpers';
import { ReturnStepsType } from '@vf/composables/src/useReturns';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import { getCacheKeyFromProps } from '@vf/shared/src/utils/helpers';
import { getUniqueStatuses, resolveTwoConditions } from '@/helpers';
import useRootInstance from '@/shared/useRootInstance';

import OrderPickupDetails from '../../order/OrderPickupDetails.vue';
import OrderSidebarProperty from '../../order/OrderSidebarProperty.vue';
import OrderReturnsProperties from '../../order/OrderReturnsProperties.vue';
import OrderReturnsCTA from '../../order/OrderReturnsCTA.vue';

export default defineComponent({
  name: 'OrderDetailsSidebar',
  serverCacheKey: getCacheKeyFromProps,
  components: {
    OrderPickupDetails,
    OrderSidebarProperty,
    OrderReturnsProperties,
    OrderReturnsCTA,
  },
  props: {
    translations: {
      type: Object as PropType<OrderDetailsSidebarTranslations>,
      required: true,
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const theme = root.$themeConfig.orderDetails;

    const { order, getOrderStatusLabel } = useOrders(root);
    const { returnStep, returnPreviewObject } = useReturns(root);
    const {
      areOnlineReturnsEnabled,
      areNarvarReturnsEnabled,
    } = useFeatureFlagsStore();

    const orderShipments = computed<Shipment[]>(() => {
      return order.value?.shipments || [];
    });

    const shippingAddressProvince = computed(() => {
      return orderShipments.value[0]?.shippingAddress?.province;
    });

    const hasPickupLocations = computed(() => {
      return orderShipments.value.some((shipment) => shipment.storeName);
    });

    const formatPrice = (amount: number): string =>
      root.$formatPrice(amount, order.value?.totals.currency);

    const formatShippingPrice = ({ shipping, currency }) =>
      theme.showFreeInsteadZero && shipping === 0
        ? props.translations.free
        : root.$formatPrice(shipping, currency);

    const containTrackingNumber = computed(() => {
      return orderShipments.value.some(
        ({ shippingMethod }) => shippingMethod?.trackingNumber?.length
      );
    });

    const trackableShipments = computed<
      {
        trackingNumber: string;
        trackingUrl: string;
      }[]
    >(() => {
      const tracks = orderShipments.value.reduce((acc, shipment) => {
        shipment.shippingMethod.trackingNumber.forEach((number, i) => {
          acc[number] = {
            trackingNumber: number,
            trackingUrl: shipment.shippingMethod.trackingUrl[i],
          };
        });
        return acc;
      }, {});
      return Object.values(tracks);
    });

    const hasInStoreEligibleItems = computed(
      () => !!order.value?.items?.some((item) => item.isInstoreEligible)
    );
    const hasCurbsideEligibleItems = computed(
      () => !!order.value?.items?.some((item) => item.isCurbsideEligible)
    );

    const pickupDetailsTranslations = computed(() => {
      const {
        detailsInfoLabel,
        detailsIntroText,
        detailsQuestionText,
        detailsInStoreStepsTexts,
        detailsCurbsideStepsTexts,
        detailsInStoreAndCurbsideStepsTexts,
      } = props.translations;
      return {
        detailsInfoLabel,
        detailsIntroText,
        detailsQuestionText,
        detailsStepsTexts: resolveTwoConditions(
          hasInStoreEligibleItems.value,
          hasCurbsideEligibleItems.value,
          detailsInStoreAndCurbsideStepsTexts,
          detailsInStoreStepsTexts,
          detailsCurbsideStepsTexts,
          detailsInStoreStepsTexts
        ),
      };
    });

    const giftCardAmount = computed(() =>
      getOrderGiftOrRewardCardAmount(order.value, PaymentMethodCode.GIFT_CARD)
    );
    const rewardCardAmount = computed(() =>
      getOrderGiftOrRewardCardAmount(order.value, PaymentMethodCode.REWARD_CARD)
    );

    const isReturns = computed(() => {
      return (
        returnStep.value === ReturnStepsType.HISTORY_DETAILS &&
        areOnlineReturnsEnabled
      );
    });

    const orderStatus = computed<string>(() => {
      const orderData = isReturns.value
        ? returnPreviewObject.value
        : order.value;

      return orderData?.status
        ? getOrderStatusLabel(
            orderData.status,
            getUniqueStatuses(orderData?.items)
          )
        : '';
    });

    return {
      theme,
      order,
      orderStatus,
      trackableShipments,
      containTrackingNumber,
      shippingAddressProvince,
      hasPickupLocations,
      pickupDetailsTranslations,
      giftCardAmount,
      rewardCardAmount,
      formatPrice,
      formatShippingPrice,
      areOnlineReturnsEnabled,
      areNarvarReturnsEnabled,
      returnStep,
      ReturnStepsType,
      returnPreviewObject,
      returnableOrderId: computed(() => {
        // TODO: get rid of returnableOrderId prop and references once Narvar Story will be delivered
        if (returnStep.value) return '';
        return order.value?.hasReturnableItems ? order.value.orderId : '';
      }),
    };
  },
});
