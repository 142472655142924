








import type { PropType } from 'vue';
import { computed, ComputedRef, defineComponent } from '@vue/composition-api';
import type { BreadcrumbsTranslations } from '@vf/api-contract';
import { ROUTES, useI18n } from '@vf/composables';
import { PageTypeName, CmsBreadcrumb } from '@vf/composables/src/useCms/types';
import useRootInstance from '@/shared/useRootInstance';
import { useCmsRefStore } from '@vf/composables/src/store/cmsRef';

export default defineComponent({
  name: 'SmartBreadcrumbs',
  props: {
    translations: {
      type: Object as PropType<BreadcrumbsTranslations>,
      default: () => ({}),
    },
    contextKey: {
      type: String as PropType<string>,
      default: 'page-content',
    },
  },
  setup(props) {
    const { root } = useRootInstance();

    const { localePath } = useI18n(root);
    const cmsRefStore = useCmsRefStore(root.$pinia);

    const isCommercePage: ComputedRef<boolean> = computed(() =>
      [PageTypeName.PLP, PageTypeName.PDP].includes(cmsRefStore.pageTypeName)
    );
    const isPdpPage = computed(
      () => cmsRefStore.pageTypeName === PageTypeName.PDP
    );
    const clickableLastItem = computed(
      () =>
        isPdpPage.value && root.$themeConfig.breadcrumbs?.pdpLastItemClickable
    );

    const setBreadcrumbsBase = () => {
      if (
        root.$themeConfig.breadcrumbs?.shopAllCustomPath &&
        root.$themeConfig.breadcrumbs?.shopAllCustomDataNavigation
      ) {
        return {
          link: localePath(root.$themeConfig.breadcrumbs.shopAllCustomPath),
          text: props.translations.shopAll,
          dataNavigation:
            root.$themeConfig.breadcrumbs.shopAllCustomDataNavigation,
        };
      } else {
        return {
          link: localePath(ROUTES.HOME()),
          text: props.translations.shopAll,
          dataNavigation: 'homepage',
        };
      }
    };

    const breadcrumbsToRender: ComputedRef<CmsBreadcrumb[]> = computed(() =>
      isCommercePage
        ? [setBreadcrumbsBase(), ...cmsRefStore.breadcrumbs]
        : cmsRefStore.breadcrumbs
    );
    const showBreadcrumbs: ComputedRef<boolean> = computed(() => {
      if (
        root.$themeConfig.breadcrumbs?.minBreadcrumbDisplayIndex ||
        root.$themeConfig.breadcrumbs?.minBreadcrumbDisplayIndex === 0 // prevent valid zero value from returning falsy
      ) {
        return (
          breadcrumbsToRender.value.length - 3 >=
          root.$themeConfig.breadcrumbs.minBreadcrumbDisplayIndex
        );
      } else {
        // by default show
        return true;
      }
    });

    return {
      breadcrumbsToRender,
      showBreadcrumbs,
      clickableLastItem,
    };
  },
});
