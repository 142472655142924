var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VfSticky',{attrs:{"trigger-element-id":_vm.triggerStickyOnElementId,"trigger-defined-number":_vm.triggerStickyOnDefinedNumber,"data-cm-metadata":_vm.$previewMetaDataLocation(_vm.$attrs['data-id'])}},[_c('div',{class:_vm.headerClasses},[_c('div',{staticClass:"vf-header__wrapper"},[_c('header',{ref:"header",staticClass:"header"},[_c(_vm.linkTag,_vm._b({tag:"component",attrs:{"title":_vm.title,"aria-label":_vm.logo.ariaLabel}},'component',_vm.bindUrlAttribute(_vm.logo.link),false),[_c('VfImage',{staticClass:"vf-header__logo",attrs:{"data-cm-metadata":_vm.$previewMetaDataLocation(
                _vm.$attrs['data-id'],
                'localSettingsExpanded'
              ),"src":_vm.logo.url,"aria-label":_vm.logo.ariaLabel,"alt":_vm.logo.ariaLabel,"width":_vm.logoWidth,"height":_vm.logoHeight}})],1),_vm._v(" "),_c('div',{staticClass:"vf-header__actions"},[_vm._t("actions",function(){return [_c('nav',{staticClass:"vf-header__navigation"},_vm._l((_vm.steps),function(step){return _c('div',{key:step.id,staticClass:"vf-header-navigation-item",class:{
                  'vf-header-navigation-item--active':
                    _vm.currentStep === step.id,
                  'vf-header-navigation-item--done': step.id < _vm.currentStep,
                  'vf-header-navigation-item--redesign-core': _vm.isCoreRedesignEnabled,
                }},[_c(_vm.getComponentType(step),{tag:"component",attrs:{"link":step.link}},[_c('span',{staticClass:"vf-header-navigation-item__step"},[_vm._v(_vm._s(step.id)),_c('span',{staticClass:"dot"},[_vm._v(".")])]),_c('span',{staticClass:"vf-header-navigation-item__step-name"},[_vm._v(_vm._s(step.label))])])],1)}),0)]})],2)],1),_vm._v(" "),(_vm.clearSession)?_c('div',{staticClass:"vf-header__clear-session"},[_c('ThemeButton',{attrs:{"color":"secondary","size":"sm"},on:{"click":function($event){return _vm.onClearSession(_vm.clearSession.link)}}},[_vm._v("\n          "+_vm._s(_vm.clearSession.title)+"\n        ")])],1):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }