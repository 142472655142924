


























































































import {
  defineComponent,
  PropType,
  watch,
  Ref,
  ref,
  ComputedRef,
  computed,
  onBeforeUnmount,
  inject,
} from '@vue/composition-api';
import {
  ProductReviewSortingParameter,
  ProductReviewVoteType,
} from '@vf/api-contract';
import {
  useI18n,
  useProduct,
  useProductInventory,
  useRequestTracker,
  useReviews,
} from '@vf/composables';
import { getWriteReviewUrl } from '@vf/composables/src/useReviews/utils';
import VfReviews from '@vf/ui/components/Organism.Reviews.vue';
import WriteReviewModal from '@vf/theme/components/modals/WriteReviewModal.vue';
import {
  scrollTo,
  isClient,
  computeStickyHeaderHeight,
} from '@vf/shared/src/utils/helpers';
import { useFeatureFlagsStore } from '@vf/composables/src/store/featureFlags';
import useRootInstance from '@/shared/useRootInstance';

const REDUCED_LIST_COUNT = 5;

export default defineComponent({
  name: 'ProductReviews',
  components: {
    VfReviews,
    WriteReviewModal,
  },
  props: {
    /** Select with page type */
    contextKey: {
      type: String as PropType<string>,
      default: 'product',
    },
  },
  setup(props) {
    const { root } = useRootInstance();
    const { localePath } = useI18n(root);
    const { product, shouldShowPdpStickyHeader } = useProduct(
      root,
      props.contextKey
    );
    const { inventoryLoading } = useProductInventory(root, props.contextKey);
    const {
      setProductId,
      getProductReviews,
      getProductReviewsConf,
      reviews,
      rollup,
      translations,
      properties,
      sorting,
      paging,
      filters,
      setSorting,
      changePage,
      addFilter,
      removeFilter,
      productReview,
      flagReview,
      addReviewVote,
    } = useReviews(root, props.contextKey);
    const { isWriteReviewPage } = useFeatureFlagsStore();

    const isLoading: Ref<boolean> = ref(true);
    const isModalVisable: Ref<boolean> = ref(false);
    const prContainer = 'pr-container';

    const isColorVariantId =
      root.$themeConfig?.reviews?.isColorVariantId ?? false;
    const useWriteAReviewButton =
      root.$themeConfig?.reviews?.useWriteAReviewButton ?? false;

    const handleFlagReviewSubmit = (eventObj) => {
      flagReview(
        eventObj.reviewId,
        eventObj.comments,
        eventObj.flagType,
        eventObj.email,
        eventObj.mediaId
      );
    };

    const getReviews = async (scrollToTop = false) => {
      setProductId(product.value.id);
      await getProductReviews();
      scrollToTop && scrollToReviews();
    };

    const scrollToReviews = () => {
      const targetElement = document.getElementById(prContainer);

      if (targetElement) {
        const headerOffset = computeStickyHeaderHeight(
          root.$viewport?.isSmall,
          shouldShowPdpStickyHeader.value
        );

        // buffer of 1 second to allow third party components to load
        setTimeout(() => {
          scrollTo({ top: targetElement.offsetTop - headerOffset });
        }, 1000);
      }
    };

    onBeforeUnmount(() => {
      removeFilter();
    });

    if (isClient) {
      watch(
        inventoryLoading,
        async (fetchingProduct) => {
          if (fetchingProduct) {
            return;
          }
          useRequestTracker(root).onAllDone(async () => {
            isLoading.value = true;
            await getProductReviewsConf();
            setSorting(
              (properties.value
                ?.DEFAULT_REVIEW_SORT_ORDER as ProductReviewSortingParameter) ||
                ProductReviewSortingParameter.MostHelpful
            );
            const scrollToReviews = window.location.hash.includes(prContainer);
            await getReviews(scrollToReviews);
            isLoading.value = false;
          });
        },
        { immediate: true }
      );
    }

    const variantId: ComputedRef<string> = computed(() =>
      isColorVariantId ? product.value.color?.slug : product.value.id
    );

    const writeReviewUrl = () =>
      localePath(
        getWriteReviewUrl(
          {
            productId: product.value.id,
            variantId: variantId.value,
          },
          root.$options
        )
      );

    const handleWriteReview = () => {
      if (isWriteReviewPage) {
        root.$router.push(writeReviewUrl());
      } else {
        isModalVisable.value = true;
      }
    };

    const onModalClose = () => {
      isModalVisable.value = false;
    };

    const productId = computed(() =>
      root.$themeConfig.reviews?.isTrimProductIdAmount
        ? product.value.id.slice(0, -3)
        : product.value.id
    );

    // TODO: GLOBAL15-61059 remove after redesign core
    const isCoreRedesignEnabled = inject('isCoreRedesignEnabled');

    const hasReviews = computed(() => productReview.value?.reviewsCount > 0);
    return {
      isLoading,
      getReviews,
      reviews,
      rollup,
      translations,
      sorting,
      paging,
      filters,
      addFilter,
      removeFilter,
      handleFlagReviewSubmit,
      setSorting,
      changePage,
      productReview,
      addReviewVote,
      ProductReviewVoteType,
      handleWriteReview,
      reducedListCount: REDUCED_LIST_COUNT,
      useWriteAReviewButton,
      isWriteReviewPage,
      isModalVisable,
      onModalClose,
      variantId,
      productId,
      isCoreRedesignEnabled,
      hasReviews,
    };
  },
});
